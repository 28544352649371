import "./main-page.scss";
import { FC, ReactNode } from "react";
import { ILSHeaderComponent } from "@/common/components/layout/header";
import { ILSContent, ILSLayout } from "@/common/components/layout";

type IProps = {
  children: ReactNode;
};

export const MainPageContainer: FC<IProps> = ({ children }) => {
  return (
    <ILSLayout className="ils-wrapper">
      <ILSHeaderComponent />
      <ILSContent className="ils-main">{children}</ILSContent>
    </ILSLayout>
  );
};
