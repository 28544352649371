import { Empty } from "antd";
import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSFileCard } from "@/common/components/custom/file-card";
import { ILSDivider } from "@/common/components/layout/divider";
import { QuestionnaireResultType } from "@/common/api/types/questionnaire";
import { useAdminQuestionnaireResultsCard } from "@/modules/admin-panel/children/admin-applications/hooks/results/use-result-card";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { ILSImage } from "@/common/components/data-display/image";
import { ILSBadgeRibbon } from "@/common/components/data-display/badge-ribbon";

type IProps = {
  result: QuestionnaireResultType;
};

export const AdminQuestionnaireResultsCard: FC<IProps> = ({ result }) => {
  const { avatar, title, INN, createdText } = useAdminQuestionnaireResultsCard({
    result,
  });

  return (
    <div className="admin-questionnaire__card">
      <div className="admin-questionnaire__card-avatar">
        {avatar ? (
          <ILSImage
            alt=""
            height={168}
            width={225}
            style={{ objectFit: "cover" }}
            src={avatar}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Нет данных"
          />
        )}
      </div>
      <div className="admin-questionnaire__card-info">
        <ILSTitle
          ellipsis={{ rows: 3 }}
          level={3}
          className="admin-questionnaire__card-info-title"
          children={title}
        />
        <ILSTitle
          ellipsis
          level={4}
          className="admin-questionnaire__card-info-subtitle"
          children={`ИНН: ${INN}`}
        />
        <ILSDivider className="admin-questionnaire__card-divider" />
        <ILSTitle
          ellipsis
          level={5}
          className="admin-questionnaire__card-info-subtitle"
          children="Заполненная анкета:"
        />
        <DetailDescription
          caption="Дата и время отправки анкеты:"
          value={createdText}
          size="small"
        />
        <ILSFileCard
          fileID={result?.File?.ID}
          fileName={result?.File?.OriginalName}
          fileUrl={result?.File?.FileUrl}
          disableDelete
          className="fixed-width"
        />
      </div>
      {result?.ID && (
        <div className="admin-questionnaire__card-ribbon">
          <ILSBadgeRibbon text={`ID:${result?.ID}`} placement="start" />
        </div>
      )}
    </div>
  );
};
