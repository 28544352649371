import { FC } from "react";
import { Driver } from "@/common/types/models/user/driver";
import { ILSDivider } from "@/common/components/layout/divider";
import { DriverDetailsSalary } from "@/common/features/driver/components/salary";
import { DriverDetailsPhone } from "@/common/features/driver/components/phone";
import { DriverDetailsJobSearch } from "@/common/features/driver/components/job-search";

type IProps = {
  driver: Driver | undefined | null;
};

export const DriverDetailsSidebar: FC<IProps> = ({ driver }) => {
  return (
    <div className="driver-details__sidebar">
      <DriverDetailsSalary driver={driver} />
      <ILSDivider />
      <DriverDetailsPhone phone={driver?.Phone} />
      <ILSDivider />
      <DriverDetailsJobSearch driver={driver} />
    </div>
  );
};
