import { head, isArray } from "lodash";
import { useAbac } from "react-abac";
import { AbacPermission } from "@/common/types/core/abac";
import { EMPTY_STRING } from "@/common/constants/general";
import { SOCIAL_RATING_TITLE } from "@/common/constants/config/rating";
import { useAccount } from "@/common/hooks/use-account";
import { getUserFullName } from "@/common/utils/helpers/string/get-driver-full-name";
import { getWidgetRating } from "@/common/helpers/rating/get-widget-rating";

export const useHeaderWidget = () => {
  const { user, avatar } = useAccount();
  const { userHasPermissions } = useAbac();
  const isDriver = userHasPermissions(AbacPermission.driver);

  const fullName = {
    surname: !isDriver ? user?.Surname : user?.Driver?.Surname,
    name: !isDriver ? user?.Name : user?.Driver?.Name,
    patronymic: !isDriver ? user?.Patronymic : user?.Driver?.Patronymic,
  };

  const widgetUserName = getUserFullName(fullName);
  const widgetOrganizationName = isArray(user?.Account?.Organization)
    ? head(user?.Account?.Organization)?.Name || null
    : null;

  const widgetAvatar = avatar?.FileUrl;

  const widgetRating = getWidgetRating(user);

  const widgetRatingTitle = SOCIAL_RATING_TITLE;

  const widgetTooltipTitle = `Пользователь: ${widgetUserName}${
    widgetOrganizationName
      ? `; Организация: ${widgetOrganizationName}`
      : EMPTY_STRING
  }`;

  return {
    widgetUserName,
    widgetOrganizationName,
    widgetAvatar,
    widgetRating,
    widgetRatingTitle,
    widgetTooltipTitle,
  };
};
