import { EMPTY_STRING } from "@/common/constants/general";
import { VacancyStatus } from "@/common/types/models/vacancies/status";

type IProps = {
  statusAlias: VacancyStatus | null | undefined;
  adminPanel: boolean;
};

/**
 * Функция возвращает пропсы для управления кнопками вакансий,
 * не включая заголовок, стили, иконки, колбэки.
 * чтобы управлять заблокированным состоянием кнопок и уведомлениями при клике
 * на кнопку в одном месте. Напр. в списке и в подробной информации о заявке.
 */
export const getVacancyButtonProps = ({ statusAlias, adminPanel }: IProps) => {
  const isInModeration = statusAlias === VacancyStatus.InModeration;
  const isPublished = statusAlias === VacancyStatus.Published;
  const isRejected = statusAlias === VacancyStatus.Rejected;

  const editButtonProps = {
    title:
      isInModeration && !adminPanel
        ? "Вакансия на модерации, пожалуйста, подождите"
        : EMPTY_STRING,
    disabled: isInModeration && !adminPanel,
    confirmDescription:
      (isPublished || isRejected) && !adminPanel
        ? 'Вакансия будет переведена в статус "Черновик" после редактирования'
        : EMPTY_STRING,
  };

  const deleteButtonProps = {
    title:
      isInModeration && !adminPanel
        ? "Вакансия на модерации, пожалуйста, подождите"
        : EMPTY_STRING,
    disabled: isInModeration && !adminPanel,
    confirmDescription: "Удалить выбранную вакансию?",
  };

  const moderationButtonProps = {
    title: isInModeration
      ? "Вакансия на модерации, пожалуйста, подождите"
      : EMPTY_STRING,
    disabled: isInModeration,
    confirmDescription: "Отправить вакансию на модерацию?",
    hidden: adminPanel || isPublished || isRejected,
  };

  return { editButtonProps, deleteButtonProps, moderationButtonProps };
};
