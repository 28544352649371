import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ModulePath } from "@/common/types/core/routes";

export const useRedirect = (to: ModulePath) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
