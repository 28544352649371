import { isEmpty } from "lodash";
import { DriverInfo } from "@/common/types/models/user/driver";
import { useDriverCardDetails } from "@/common/features/drivers-common/hooks/use-driver-card-details";

type IProps = {
  driver: DriverInfo;
  onEditDriver: (driverID: number) => void;
};

export const useAdminDriverCard = ({ driver, onEditDriver }: IProps) => {
  const { avatar, statuses, info, redirectID, profile } =
    useDriverCardDetails(driver);

  const handleEditDriver = () => {
    if (!isEmpty(driver) && driver.ID) {
      onEditDriver(driver.ID);
    }
  };

  const actions = [
    {
      children: "Редактировать",
      onClick: handleEditDriver,
    },
  ];

  return {
    avatar,
    statuses,
    info,
    redirectID,
    actions,
    profile,
  };
};
