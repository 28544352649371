import { User, UserRole, UserRoleLabel } from "@/common/types/models/user/user";
import { AccountRole } from "@/common/types/models/account";
import { EMPTY_STRING } from "@/common/constants/general";

export const USERS_MOCK: Record<string, User> = {
  [UserRole.guest]: {
    ID: -1,
    Account: {
      ID: -1,
      ParentID: null,
      AccountRoles: [
        { Alias: AccountRole.guest, ID: -1, Name: UserRoleLabel.guest },
      ],
      Blocked: null,
      Deleted: null,
      DeletedBy: null,
      Organization: {},
      Description: null,
      LastUpdate: 1705745460,
      Name: UserRoleLabel.guest,
      Created: 1705745460,
      AccountCode: EMPTY_STRING,
      ManagerID: null,
      Rating: {
        Social: 0,
      },
    },
    AccountID: -1,
    Blocked: null,
    Created: 1705745460,
    Deleted: null,
    DeletedBy: null,
    LastLogin: null,
    Driver: null,
    Email: EMPTY_STRING,
    Login: EMPTY_STRING,
    Name: "Посетитель",
    Surname: EMPTY_STRING,
    Patronymic: EMPTY_STRING,
    Phone: null,
    UserRoles: [{ Alias: UserRole.guest, ID: -1, Name: UserRoleLabel.guest }],
    Rating: {
      Social: 0,
    },
    Avatar: undefined,
    PhoneVerified: false,
  },
};
