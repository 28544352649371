import "./index.scss";
import { FC } from "react";
import { ILSStepsForm } from "@/common/ui-components/data-entry/steps-form/form";
import { ILSStepsFormStep } from "@/common/ui-components/data-entry/steps-form/step";
import { ILSText } from "@/common/ui-components/general/typography/text";
import { useVacancyForm } from "@/common/features/vacancies-common/hooks/use-vacancy-form";
import { VacancyFormType } from "@/common/features/vacancies-common/types/form";
import { VacanciesFormDescriptionSubform } from "@/common/features/vacancies-common/components/form/subforms/description";
import { VacanciesFormVehicleSubform } from "@/common/features/vacancies-common/components/form/subforms/vehicle";
import { VacanciesFormScheduleSubform } from "@/common/features/vacancies-common/components/form/subforms/schedule";

interface IProps {
  type: VacancyFormType;
  vacancyId?: number | undefined;
  showModal: VoidFunction;
}

export const VacanciesForm: FC<IProps> = ({ type, vacancyId, showModal }) => {
  const { formRef, refData, handleSubmit, onStepChange, onRemoveFile } =
    useVacancyForm({
      vacancyId,
      type,
      showModal,
    });

  return (
    <div className="vacancies-form">
      <ILSStepsForm
        name="vacancy"
        onFinish={handleSubmit}
        formRef={formRef}
        onCurrentChange={onStepChange}
      >
        <ILSStepsFormStep
          name="vacancyDescription"
          title="Вакансия"
          size="small"
          stepProps={{
            description: (
              <ILSText className="vacancies-form__step-subtitle">
                Общее описание вакансии
              </ILSText>
            ),
          }}
        >
          <VacanciesFormDescriptionSubform refData={refData} />
        </ILSStepsFormStep>

        <ILSStepsFormStep
          name="vehicle"
          title="ТС"
          stepProps={{
            description: (
              <ILSText className="vacancies-form__step-subtitle">
                Описание транспортного средства
              </ILSText>
            ),
          }}
        >
          <VacanciesFormVehicleSubform
            refData={refData}
            onRemoveFile={onRemoveFile}
          />
        </ILSStepsFormStep>

        <ILSStepsFormStep
          name="workConditions"
          title="Условия работы"
          stepProps={{
            description: (
              <ILSText className="vacancies-form__step-subtitle">
                Описание условий работы
              </ILSText>
            ),
          }}
        >
          <VacanciesFormScheduleSubform refData={refData} />
        </ILSStepsFormStep>
      </ILSStepsForm>
    </div>
  );
};
