import "./index.scss";
import { FC, RefAttributes } from "react";
import { Avatar } from "antd";
import { AvatarProps } from "antd/es/avatar/avatar";
import classNames from "classnames";
import { UserOutlined } from "@ant-design/icons";

interface IProps extends AvatarProps, RefAttributes<HTMLSpanElement> {}

export const ILSAvatar: FC<IProps> = ({ className, ...props }) => {
  return (
    <Avatar
      size={{ xs: 48, sm: 64, md: 80, lg: 128, xl: 160, xxl: 200 }}
      {...props}
      className={classNames("ils-avatar", className)}
    >
      <UserOutlined />
    </Avatar>
  );
};
