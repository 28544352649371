import { isEmpty } from "lodash";
import { createReducer } from "@reduxjs/toolkit";
import { AUTH_INITIAL_STATE } from "@modules/auth/store";
import { AuthUserSetAction } from "@modules/auth/sagas/auth-user-set";
import { authUserSetActions } from "@modules/auth/actions";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";

export const authUserSetReducer = createReducer(AUTH_INITIAL_STATE, {
  [authUserSetActions.FAILURE]: onFailure,
  [authUserSetActions.FULFILL]: onFulfill,
  [authUserSetActions.SUCCESS]: (state, action: AuthUserSetAction) => {
    const { user } = action.payload;
    // TODO: user update не возвращает весь объект User целиком: (не возвращается Account)
    // попросить вернуть весь объект.
    if (!isEmpty(state.user)) {
      state.user = { ...state.user, ...user };
    } else {
      state.user = user;
    }
    return state;
  },
});
