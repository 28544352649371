import reduceReducers from "reduce-reducers";
import { VACANCIES_INITIAL_STATE } from "@/common/features/vacancies-common/store";
import { vacanciesReducer } from "@/common/features/vacancies-common/reducers/vacancies";
import { vacanciesFiltersReducer } from "@/common/features/vacancies-common/reducers/filters";

export const vacanciesRootReducer = reduceReducers(
  VACANCIES_INITIAL_STATE,
  vacanciesReducer,
  vacanciesFiltersReducer
);
