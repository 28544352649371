import { VacanciesSidebarFilterValues } from "@/common/features/vacancies-common/types/filters";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";

export const sidebarFiltersToValues = (
  filters: VacanciesSidebarFilterValues
): VacanciesFilters => {
  return {
    LicenseCategory: filters?.LicenseCategory,
    WorkSchedule: filters?.WorkSchedule,
    PaymentFrequency: filters?.PaymentFrequency,
    JobRegion: filters?.JobRegion,
    // TODO: фильтры в работе
    // SalaryFrom: filters?.Salary?.From,
    // SalaryTo: filters?.Salary?.To,
    // OrganizationIsVerified?: number;
  };
};
