import { ShoppingOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { OrganizationContainer } from "./containers/organization-container";

const module: IModuleModel = {
  title: ModuleTitle.organization,
  name: ModuleName.organization,
  path: ModulePath.organization,
  icon: <ShoppingOutlined />,
  element: <OrganizationContainer />,
  persist: false,
};

export const OrganizationModule = defineModule(module);
