import { useDispatch } from "react-redux";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { QuestionnaireProps } from "@/common/features/questionnaire-common/types/store";
import { questionnaireResultsRoutine } from "@/common/features/questionnaire-common/actions";
import {
  QuestionnaireOperation,
  SaveQuestionnaireType,
} from "@/common/api/types/questionnaire";

type IProps = QuestionnaireProps & {
  onSuccess?: VoidFunction;
  onFinally?: VoidFunction;
};

export const useQuestionnaireResultsOperations = () => {
  const dispatch = useDispatch();

  const onQuestionnaireResultsOperation = ({
    operation,
    body,
    resultID,
    successMessage,
    onSuccess,
    onFinally,
  }: IProps) => {
    try {
      dispatch(
        questionnaireResultsRoutine({
          operation,
          body,
          resultID,
          successMessage,
        })
      );
      onSuccess?.();
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      onFinally?.();
    }
  };

  /**
   * Получить список с результатами анкет:
   * для администратора вернет результаты всех организаций.
   * для компании вернет только свой результат
   */
  const handleGetQuestionnaireResults = () => {
    return onQuestionnaireResultsOperation({
      operation: QuestionnaireOperation.getResults,
    });
  };

  /**
   * Сохранить результаты анкеты
   */
  const handleSaveQuestionnaireResult = (
    body: SaveQuestionnaireType | undefined
  ) => {
    return onQuestionnaireResultsOperation({
      operation: QuestionnaireOperation.saveResult,
      body,
      successMessage: "Заполненная анкета успешно добавлена",
    });
  };

  /**
   * Удалить результат анкеты
   */
  const handleDeleteQuestionnaireResult = (resultID: number) => {
    return onQuestionnaireResultsOperation({
      operation: QuestionnaireOperation.deleteResult,
      resultID,
    });
  };

  return {
    handleGetQuestionnaireResults,
    handleSaveQuestionnaireResult,
    handleDeleteQuestionnaireResult,
  };
};
