import { call, put, takeEvery } from "redux-saga/effects";
import { QUESTIONNAIRE_API } from "@/common/api/endpoints/questionnaire";
import { QuestionnairePayload } from "@/common/features/questionnaire-common/types/store";
import { questionnaireResultsRoutine } from "@/common/features/questionnaire-common/actions";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";

export function* QuestionnaireResultsWorker(action: QuestionnairePayload) {
  const { request, success, failure, fulfill } = questionnaireResultsRoutine;
  const { operation, body, resultID, successMessage } = action.payload;

  try {
    yield put(request());
    const { data } = yield call(QUESTIONNAIRE_API.questionnaireOperation, {
      operation,
      body,
      resultID,
    });
    yield put(success({ operation, data, resultID }));
    if (successMessage) {
      yield call(showSuccessNotification, successMessage);
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* questionnaireResultsWatcher() {
  yield takeEvery(
    questionnaireResultsRoutine.trigger,
    QuestionnaireResultsWorker
  );
}
