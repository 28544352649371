import { FC } from "react";
import { Empty } from "antd";
import { isUndefined } from "lodash";
import { ILSImage } from "@/common/components/data-display/image";

interface IProps {
  cover: string | null | undefined;
}

export const WideCardCover: FC<IProps> = ({ cover }) => {
  if (isUndefined(cover)) {
    return null;
  }

  if (!cover) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <ILSImage
      preview={Boolean(cover)}
      alt=""
      src={cover}
      height={cover ? 168 : 225}
      width={225}
      style={{ objectFit: "cover" }}
    />
  );
};
