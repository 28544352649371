import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { useFormAvatar } from "@/common/hooks/use-form-avatar";
import { useDisableSubmitBeforeTouched } from "@/common/ui-components/data-entry/form/hooks/use-disable-submit-before-touched";
import { AccountFormValues } from "@/modules/social-services/children/my-info/types/form";
import { accountToFormValues } from "@/modules/social-services/children/my-info/helpers/decorators/account-to-form-values";
import { User } from "@/common/types/models/user/user";
import { UpdateRequestBody } from "@/common/api/types/api";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { updateUserActions } from "@/modules/social-services/children/my-info/actions";
import { getPayloadFromFormValues } from "@/common/ui-components/data-entry/form/helpers/get-payload-from-form-values";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";
import { getFormImage } from "@/common/helpers/get-account-avatar";

type IProps = {
  selectedUser: User | null;
  handleResetActionAndUser: VoidFunction;
};

export const useMyInfoEmployeeEditForm = ({
  selectedUser,
  handleResetActionAndUser,
}: IProps) => {
  const dispatch = useDispatch();
  const [form] = useForm<AccountFormValues>();
  const { disabled, enableSubmit } = useDisableSubmitBeforeTouched();

  const initialValues = accountToFormValues({
    user: selectedUser,
  });

  const {
    fileList,
    removedSavedFiles,
    handleAvatarChange,
    handleAvatarRemove,
    handleAvatarReset,
  } = useFormAvatar({
    avatar: initialValues?.Avatar,
    enableSubmit,
  });

  const handleSubmit = async (formValues: AccountFormValues) => {
    try {
      const Avatar = await getFormImage(fileList, removedSavedFiles);

      const payload =
        getPayloadFromFormValues<UpdateRequestBody<Omit<User, "Avatar">>>(
          formValues
        );

      dispatch(
        updateUserActions({
          body: {
            ...payload,
            Phone: getUnmaskedPhone(payload?.Phone),
            Avatar,
          },
          userID: selectedUser?.ID,
        })
      );
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      handleResetActionAndUser();
    }
  };

  const handleSetInitialValues = () => {
    form.resetFields();
    form.setFieldsValue(initialValues || EMPTY_OBJECT_DATA);
    handleAvatarReset();
  };

  const handleCancel = () => handleResetActionAndUser();

  useEffect(() => {
    handleSetInitialValues();
  }, [selectedUser, form]);

  return {
    form,
    fileList,
    disabled,
    handleCancel,
    enableSubmit,
    handleSubmit,
    handleAvatarChange,
    handleAvatarRemove,
  };
};
