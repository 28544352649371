import { FC } from "react";
import { SocialServicesToolbar } from "@modules/social-services/containers/toolbar";
import { Drawer, FormInstance } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { RefDataType } from "@/common/features/dictionaries/types";
import { VacanciesFilters } from "@/modules/social-services/children/vacancies/containers/filters";
import {
  VacanciesSidebarFilterValues,
  VacanciesToolbarFilterValues,
} from "@/common/features/vacancies-common/types/filters";
import { useDrawer } from "@/common/hooks/use-drawer";
import { VacancyFormType } from "@/common/features/vacancies-common/types/form";
import { ILSButton } from "@/common/components/data-display/buttons";
import { VacanciesToolbarForm } from "@/modules/social-services/children/vacancies/components/vacancies-toolbar-form";
import { VacancyFormModalButton } from "@/modules/social-services/children/vacancies/components/vacancy-form-modal-button";
import { VacanciesSortPopover } from "@/common/features/vacancies-common/components/sort-form/vacancies-sort-popover";

interface IProps {
  toolbarForm: FormInstance<VacanciesToolbarFilterValues>;
  sidebarForm: FormInstance<VacanciesSidebarFilterValues>;
  sidebarRefData: RefDataType;
  showStatusFormItem: boolean;
  activeSortKey: string | undefined;
  onToolbarFormChange: (
    changedValues: VacanciesToolbarFilterValues,
    values: VacanciesToolbarFilterValues
  ) => void;
  onSidebarFormChange: (
    changedValues: VacanciesSidebarFilterValues,
    values: VacanciesSidebarFilterValues
  ) => void;
  onClickResetSidebarForm: VoidFunction;
  onSortClick: ({ key }: { key: string }) => void;
}

export const VacanciesToolbar: FC<IProps> = ({
  toolbarForm,
  sidebarForm,
  sidebarRefData,
  showStatusFormItem,
  activeSortKey,
  onToolbarFormChange,
  onSidebarFormChange,
  onClickResetSidebarForm,
  onSortClick,
}) => {
  const { open, onOpen, onClose } = useDrawer();

  return (
    <SocialServicesToolbar>
      <ILSAllowedTo perform={AbacPermission.organizationAndEmployee}>
        <VacanciesToolbarForm
          toolbarForm={toolbarForm}
          showStatusFormItem={showStatusFormItem}
          onToolbarFormChange={onToolbarFormChange}
        />
        <VacancyFormModalButton type={VacancyFormType.Create} />
      </ILSAllowedTo>
      <VacanciesSortPopover
        activeSortKey={activeSortKey}
        onSortClick={onSortClick}
      />
      <ILSButton
        children="Фильтры"
        type="primary"
        icon={<FilterOutlined />}
        onClick={onOpen}
        className="filters"
      />
      <Drawer
        title="Фильтры поиска вакансий"
        size="default"
        open={open}
        onClose={onClose}
        placement="left"
      >
        <VacanciesFilters
          sidebarForm={sidebarForm}
          sidebarRefData={sidebarRefData}
          onSidebarFormChange={onSidebarFormChange}
          onClickResetSidebarForm={onClickResetSidebarForm}
          hideMedium={false}
        />
      </Drawer>
    </SocialServicesToolbar>
  );
};
