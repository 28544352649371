import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { IILSButtonConfirmTooltipProps } from "@/common/components/data-display/buttons/confirm-tooltip";
import { Vacancy } from "@/common/types/models/vacancies/model";
import {
  VacancyStatus,
  VacancyStatusID,
} from "@/common/types/models/vacancies/status";
import { EMPTY_STRING } from "@/common/constants/general";
import { useModal } from "@/common/hooks/use-modal";
import { useVacancyOperations } from "@/common/features/vacancies-common/hooks/use-vacancy-operations";

export const useAdminVacancyActions = (vacancy?: Vacancy) => {
  const statusAlias = vacancy?.VacancyStatus?.Alias as VacancyStatus;

  const isPublished = statusAlias === VacancyStatus.Published;

  const { handleChangeVacancyStatus } = useVacancyOperations();

  const { isModalOpen, showModal } = useModal();

  const onAccept = () => {
    handleChangeVacancyStatus(vacancy?.ID, VacancyStatusID.Published);
  };

  const adminActionButtons: IILSButtonConfirmTooltipProps[] = [
    {
      children: "Опубликовать вакансию",
      block: true,
      type: "primary",
      icon: <PlusOutlined />,
      title: isPublished ? "Вакансия опубликована" : EMPTY_STRING,
      disabled: isPublished,
      onConfirm: onAccept,
      confirmDescription: "Опубликовать вакансию?",
    },
    {
      children: "Отклонить",
      block: true,
      icon: <CloseOutlined />,
      onConfirm: showModal,
      confirmDescription: "Отклонить вакансию?",
    },
  ];

  return {
    adminActionButtons,
    isCommentModalOpen: isModalOpen,
    setCommentModalOpen: showModal,
  };
};
