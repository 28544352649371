import { FolderOpenOutlined } from "@ant-design/icons";
import { TreeSelect } from "antd";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { normalizeFile } from "@/common/utils/helpers/components/upload/normalize-file";
import { ILSSelect } from "@/common/ui-components/data-entry/select";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { EMPTY_STRING } from "@/common/constants/general";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const ARTICLE_CATEGORY_TREE_DATA = [
  {
    value: "All",
    title: "Все статьи",
    icon: <FolderOpenOutlined />,
    children: [
      {
        value: 1,
        title: "Новости дня",
        icon: <FolderOpenOutlined />,
      },
      {
        value: 2,
        title: "Про автомобили",
        icon: <FolderOpenOutlined />,
      },
    ],
  },
];

export const ALL_NEWS_ARTICLE_CATEGORY_TREE_DATA =
  ARTICLE_CATEGORY_TREE_DATA[0].children;

export const SELECT_STYLE = { width: "300px" };
export const ARTICLE_FORM_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.CategoryID,
      label: EMPTY_STRING,
    },
    inputProps: {
      size: "default",
      name: FormFieldName.CategoryID,
      showSearch: true,
      allowClear: true,
      treeIcon: true,
      treeDefaultExpandAll: true,
      style: SELECT_STYLE,
      dropdownStyle: { maxHeight: 400, overflow: "auto" },
      placeholder: "Выберите категорию статьи",
      treeData: ARTICLE_CATEGORY_TREE_DATA,
    },
    Component: TreeSelect,
  },
  {
    fieldProps: {
      field: FormFieldName.Tags,
      getValueFromEvent: normalizeFile,
    },
    inputProps: {
      size: "default",
      name: FormFieldName.Tags,
      style: SELECT_STYLE,
      placeholder: "Добавьте теги статье",
      mode: "tags",
      tokenSeparators: [","],
      options: [],
    },
    Component: ILSSelect,
  },
  {
    fieldProps: {
      field: FormFieldName.Title,
      label: EMPTY_STRING,
    },
    inputProps: {
      size: "default",
      name: FormFieldName.Title,
      placeholder: "Введите заголовок статьи",
      className: "admin-articles-title-article",
    },
    Component: ILSInput,
  },
] as FormItemsFields;
