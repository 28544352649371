import { ShoppingOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { OrganizationModule } from "@/modules/social-services/children/organizations/children";
import { OrganizationsContainer } from "./containers/organizations-container";

const module: IModuleModel = {
  title: ModuleTitle.organizations,
  name: ModuleName.organizations,
  path: ModulePath.organizations,
  icon: <ShoppingOutlined />,
  element: <OrganizationsContainer />,
  children: { OrganizationModule },
  persist: false,
};

export const OrganizationsModule = defineModule(module);
