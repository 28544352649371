import { createRoutine } from "redux-saga-routines";

export enum UserAction {
  create = "USER/CREATE",
  getUsers = "USER/GET_USERS",
  update = "USER/UPDATE",
  deleteUser = "USER/DELETE",
  updateOrganization = "USER/ORGANIZATION/UPDATE",
  getOrganization = "USER/ORGANIZATION/GET",
  sendCode = "USER/SEND_CODE",
  checkCode = "USER/CHECK_CODE",
}

export const createUserActions = createRoutine(UserAction.create);
export const getUsersActions = createRoutine(UserAction.getUsers);
export const updateUserActions = createRoutine(UserAction.update);
export const deleteUserActions = createRoutine(UserAction.deleteUser);
export const getOrganizationActions = createRoutine(UserAction.getOrganization);
export const updateOrganizationActions = createRoutine(
  UserAction.updateOrganization
);
export const sendCodeActions = createRoutine(UserAction.sendCode);
export const checkCodeActions = createRoutine(UserAction.checkCode);
