import {
  EditOutlined,
  DeleteOutlined,
  UnlockOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { User } from "@/common/types/models/user/user";
import {
  UserCardActions,
  UserCardActionsType,
} from "@/common/types/models/user/card-actions";

type IProps = {
  selectedUser: User | null;
  onEdit(userId: number): void;
  onDelete(userId: number): void;
  onBlock(userId: number): void;
};

export const useUserCardActions = ({
  selectedUser,
  onEdit,
  onDelete,
  onBlock,
}: IProps) => {
  const userIsBlocked = Boolean(selectedUser?.Account?.Blocked);
  const blockedTitle = userIsBlocked
    ? "Разблокировать пользователя"
    : "Заблокировать пользователя";

  const userCardActions: UserCardActionsType = {
    [UserCardActions.Edit]: {
      title: "Редактирование пользователя",
      icon: <EditOutlined />,
      onClick: onEdit,
    },
    [UserCardActions.Delete]: {
      title: "Удаление пользователя",
      icon: <DeleteOutlined />,
      onClick: onDelete,
    },
    [UserCardActions.Block]: {
      title: blockedTitle,
      icon: userIsBlocked ? <UnlockOutlined /> : <LockOutlined />,
      onClick: onBlock,
    },
  };

  return userCardActions;
};
