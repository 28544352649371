import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { SideBarMode } from "@/modules/admin-panel/children/admin-drivers/types/general";
import { Dictionaries } from "@/common/types/models/dictionary";
import { driverFiltersSelector } from "@/common/features/dictionaries/selectors";
import {
  driversFetchingSelector,
  driversSelector,
} from "@/common/features/drivers-common/selectors";
import { useDriversOperations } from "@/common/features/drivers-common/hooks/use-drivers-operations";
import { useGetDictionary } from "@/common/features/dictionaries/hooks/use-get-dictionary";
import { useAdminDriversOperations } from "@/modules/admin-panel/children/admin-drivers/hooks/use-admin-driver-operations";
import { useAdminDriversFilters } from "@/modules/admin-panel/children/admin-drivers/hooks/use-admin-drivers-filters";
import { getStatusRefDataWithAll } from "@/modules/admin-panel/children/admin-drivers/helpers/get-status-ref-data-with-all";

type IProps = {
  showSidebar: (mode: SideBarMode) => void;
};

export const useAdminDriversList = ({ showSidebar }: IProps) => {
  const drivers = useSelector(driversSelector);
  const loading = useSelector(driversFetchingSelector);
  const { refData } = useSelector(driverFiltersSelector);

  const { handleGetDrivers } = useDriversOperations();
  const { onSelectDriver } = useAdminDriversOperations();

  const { form, filters, onValuesChange, resetFilters } =
    useAdminDriversFilters();

  useEffect(() => {
    handleGetDrivers(filters);
  }, [filters]);

  const onEditDriver = (driverID: number) => {
    onSelectDriver(driverID);
    showSidebar(SideBarMode.EditDriver);
  };

  const refDataWithAllStatus = getStatusRefDataWithAll(refData);

  const { handleGetDictionary } = useGetDictionary();
  useEffect(() => {
    if (isEmpty(refData.LicenseCategory)) {
      handleGetDictionary({ dictionary: Dictionaries.LicenseCategory });
    }
  }, []);

  return {
    drivers,
    loading,
    form,
    refData: refDataWithAllStatus,
    onValuesChange,
    resetFilters,
    onEditDriver,
  };
};
