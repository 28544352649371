import { Vacancy } from "@/common/types/models/vacancies/model";
import { useModal } from "@/common/hooks/use-modal";
import { useVacancyCardDetails } from "@/common/features/vacancies-common/hooks/use-vacancy-card-details";
import { useVacancyCardActions } from "@/common/features/vacancies-common/hooks/use-vacancy-card-actions";
import { useVacancyOperations } from "@/common/features/vacancies-common/hooks/use-vacancy-operations";

export const useAdminVacancyCard = (vacancy: Vacancy) => {
  const { vacancyID, info, cover, profile, ratings, statuses, statusAlias } =
    useVacancyCardDetails(vacancy);

  const { handleDelete } = useVacancyOperations();

  const onDelete = () => handleDelete(vacancy?.ID);

  const { isModalOpen, showModal } = useModal();

  const actions = useVacancyCardActions({
    vacancy,
    statusAlias,
    onDelete,
    onEdit: showModal,
  });

  return {
    vacancyID,
    actions,
    info,
    cover,
    profile,
    ratings,
    statuses,
    isModalOpen,
    showModal,
  };
};
