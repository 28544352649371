import { FC } from "react";
import {
  IILSButtonProps,
  ILSButton,
} from "@/common/components/data-display/buttons";
import { useAuthButton } from "@/modules/auth/children/register/hooks/use-auth-button";

interface IProps extends IILSButtonProps {}

export const AuthButton: FC<IProps> = ({ form, ...props }) => {
  const { disabled } = useAuthButton(form);

  return (
    <ILSButton
      size="large"
      type="primary"
      htmlType="submit"
      className="auth-form__button"
      disabled={disabled}
      {...props}
    />
  );
};
