import { PROTOCOL_LINK_REGEX } from "@/common/constants/url";
import { ILSLink } from "@/common/ui-components/general/typography/link";

export const getVacancyVideoLink = (video: string | undefined) => {
  if (!video || !video.match(PROTOCOL_LINK_REGEX)) {
    return video;
  }
  return (
    <ILSLink to={video} target="_blank" style={{ fontSize: "18px" }}>
      {video}
    </ILSLink>
  );
};
