import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authIsFetchingSelector } from "@modules/auth/selectors/auth-fetching";
import { registerActions } from "@modules/auth/actions";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { RegisterRequest } from "@/common/api/types/auth";
import { getRegisterValues } from "@/modules/auth/children/register/helpers/get-register-values";

export const useRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(authIsFetchingSelector);

  const onFinish = (values: RegisterRequest) => {
    const registerValues = getRegisterValues(values);

    try {
      dispatch(
        registerActions({
          ...registerValues,
          navigate,
        })
      );
    } catch (e) {
      showUserDevErrors({ e });
    }
  };
  return {
    loading,
    onFinish,
  };
};
