import { createReducer } from "@reduxjs/toolkit";
import { articleCurrentResetRoutine } from "@modules/admin-panel/children/admin-articles/actions";
import { ADMIN_ARTICLES_INITIAL_STATE } from "@modules/admin-panel/children/admin-articles/store";

export const articleCurrentResetReducer = createReducer(
  ADMIN_ARTICLES_INITIAL_STATE,
  {
    [articleCurrentResetRoutine.SUCCESS]: (state) => {
      state.currentArticle = null;
      return state;
    },
  }
);
