import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useShowIndexModule } from "@/common/hooks/use-show-index-module";
import { ModulePath } from "@/common/types/core/routes";
import { useAdminOrganizationsOperations } from "@/modules/admin-panel/children/admin-organizations/hooks/use-admin-organizations-operations";
import { adminOrganizationSelector } from "@/modules/admin-panel/children/admin-organizations/selectors/organizations";
import { usePreviousVacancyLocation } from "@/modules/social-services/children/organizations/hooks/use-previous-vacancy-location";

export const useOrganization = () => {
  const { dynamicElementID } = useShowIndexModule(ModulePath.organization);

  const organizationID = Number(dynamicElementID);
  const { handleGetOrganization } = useAdminOrganizationsOperations();

  const organization = useSelector(adminOrganizationSelector(organizationID));

  useEffect(() => {
    handleGetOrganization({ id: organizationID });
  }, []);

  const { prevVacancyLocation } = usePreviousVacancyLocation();

  return { organization, prevVacancyLocation };
};
