import { useEffect } from "react";
import { useSelector } from "react-redux";
import { VacanciesDriverTabs } from "@/modules/social-services/children/vacancies/types/tabs";
import {
  appliesListSelector,
  vacanciesFetchingSelector,
} from "@/common/features/vacancies-common/selectors";
import { useVacancyOperations } from "@/common/features/vacancies-common/hooks/use-vacancy-operations";

export const useDriverApplies = (vacanciesDriverTab: string) => {
  const appliesList = useSelector(appliesListSelector);

  const loading = useSelector(vacanciesFetchingSelector);

  const { handleGetDriverApplyList, handleApplyCancel } =
    useVacancyOperations();

  useEffect(() => {
    if (vacanciesDriverTab === VacanciesDriverTabs.Applies) {
      handleGetDriverApplyList();
    }
  }, [vacanciesDriverTab]);

  const onApplyCancel = (applyId: number | undefined) =>
    handleApplyCancel(applyId);

  return { appliesList, loading, onApplyCancel };
};
