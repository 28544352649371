import { RouteObject } from "react-router-dom";
import { ModuleName, TModulesObj } from "@/common/types/core/module";
import { getRoute } from "@/core/routes/get-route";

export const getRoutes = (
  modules: TModulesObj
): Record<ModuleName, RouteObject> => {
  return Object.entries(modules).reduce(
    (config: Record<ModuleName, RouteObject>, [, module]) => {
      if (module.name) {
        config[module.name] = getRoute(module);
      }
      return config;
    },
    {} as Record<ModuleName, RouteObject>
  );
};
