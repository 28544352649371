import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";

export const DriverExperienceHeader = () => {
  return (
    <div className="driver-experience-header">
      {[
        FORM_LABEL.VehicleType,
        FORM_LABEL.ExperienceYears,
        FORM_LABEL.Organization,
      ].map((label) => {
        if (typeof label === "string") {
          return <p key={label}>{label}</p>;
        }
        return null;
      })}
    </div>
  );
};
