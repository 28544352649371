import {
  MobileOutlined,
  MailOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useIcons } from "@/common/hooks/use-icons";

export const useContactsMain = () => {
  const { telegram: Telegram } = useIcons();
  const cardsContent = [
    {
      icon: <MobileOutlined />,
      title: "Единый телефон для справок",
      value: "8-800-555-97-87",
      href: "tel:+88005559787",
    },
    {
      icon: <MailOutlined />,
      title: "Письменные обращения принимаются на электронную почту",
      value: "info@driverrussia.ru",
      href: "mailto:info@driverrussia.ru",
    },
    {
      icon: <GlobalOutlined />,
      title: "Фактический адрес",
      value: "Россия, город Москва, Новодмитровская улица, 5АС1, офис 1314",
    },
    {
      icon: <Telegram />,
      title: 'Техническая поддержка "Водители России"',
      value: "t.me/driversrussia_support",
      href: "https://t.me/driversrussia_support",
    },
  ];

  return { cardsContent };
};
