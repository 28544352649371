import { FC } from "react";
import { AbacPermission } from "@/common/types/core/abac";
import { useMyInfoUsers } from "@/modules/social-services/children/my-info/hooks/use-my-info-users";
import { ProtectedPage } from "@/pages/protected-page";
import { MyInfoUsersList } from "@/modules/social-services/children/my-info/components/users/list";
import { MyInfoUsersForm } from "@/modules/social-services/children/my-info/components/forms/users";
import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";
import { ILSConfirmModal } from "@/common/components/custom/confirm-modal/confirm-modal";

type IProps = {
  activeTab: MyInfoTabs;
};

/** Доступ к вкладке Пользователи только для Руководителя */
export const MyInfoUsersContainer: FC<IProps> = ({ activeTab }) => {
  const {
    users,
    isFetching,
    form,
    fileList,
    isEditForm,
    userCardActions,
    disabled,
    modalProps,
    enableSubmit,
    handleSubmit,
    handleCancel,
    handleAvatarChange,
    handleAvatarRemove,
  } = useMyInfoUsers({ activeTab });

  return (
    <ProtectedPage perform={AbacPermission.organization}>
      <section className="my-info">
        <div className="my-info-users">
          {isEditForm && (
            <MyInfoUsersForm
              form={form}
              fileList={fileList}
              disabled={disabled}
              enableSubmit={enableSubmit}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              handleAvatarChange={handleAvatarChange}
              handleAvatarRemove={handleAvatarRemove}
            />
          )}
          <MyInfoUsersList
            users={users}
            isFetching={isFetching}
            userCardActions={userCardActions}
          />
          <ILSConfirmModal {...modalProps} />
        </div>
      </section>
    </ProtectedPage>
  );
};
