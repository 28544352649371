import "../index.scss";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { AdminDriversMain } from "@/modules/admin-panel/children/admin-drivers/containers/main";
import { ModulePath } from "@/common/types/core/routes";
import { useShowIndexModule } from "@/common/hooks/use-show-index-module";

interface IProps {}

export const AdminDrivers: FC<IProps> = () => {
  const { showIndexModule } = useShowIndexModule(ModulePath.driverDetails);

  return showIndexModule ? <AdminDriversMain /> : <Outlet />;
};
