import { FC } from "react";
import { isEmpty } from "lodash";
import { Empty } from "antd";
import { ILSSpin } from "@/common/components/data-display/spin";
import { AdminVacancyCard } from "@/modules/admin-panel/children/admin-vacancies/components/card";
import { Vacancies } from "@/common/types/models/vacancies/model";

interface IProps {
  vacancies: Vacancies;
  loading?: boolean;
}

export const AdminVacanciesList: FC<IProps> = ({ vacancies, loading }) => {
  return (
    <ILSSpin spinning={loading} tip="Загрузка" size="large">
      <ul className="admin-vacancies__list">
        {isEmpty(vacancies) ? (
          <Empty description="Нет активных вакансий" />
        ) : (
          vacancies.map((vacancy) => {
            return <AdminVacancyCard key={vacancy.ID} vacancy={vacancy} />;
          })
        )}
      </ul>
    </ILSSpin>
  );
};
