import { Radio } from "antd";
import { noop } from "lodash";

export const DriverSort = () => {
  return (
    <div className="admin-drivers-sort">
      <div className="admin-drivers-sort-title">Сортировать по:</div>
      <Radio.Group onChange={noop} defaultValue="a" buttonStyle="solid">
        <Radio.Button value="a">Дате рождения</Radio.Button>
        <Radio.Button value="b">Алфавиту</Radio.Button>
        <Radio.Button value="c">Рейтингу</Radio.Button>
      </Radio.Group>
    </div>
  );
};
