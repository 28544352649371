import { FC } from "react";
import { FormInstance } from "antd";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import {
  VACANCIES_SIDEBAR_INITIAL_VALUES,
  VACANCY_SIDEBAR_FILTER_FIELDS,
} from "@/common/features/vacancies-common/constants/filters";
import { VacanciesSidebarFilterValues } from "@/common/features/vacancies-common/types/filters";
import { SocialServicesFilters } from "@/modules/social-services/containers/filters";
import { RefDataType } from "@/common/features/dictionaries/types";
import { ILSButton } from "@/common/components/data-display/buttons";
import { Salary } from "@/common/components/data-entry/form-item/salary";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

interface IProps {
  sidebarForm: FormInstance<VacanciesSidebarFilterValues>;
  sidebarRefData: RefDataType;
  onSidebarFormChange: (
    changedValues: VacanciesSidebarFilterValues,
    values: VacanciesSidebarFilterValues
  ) => void;
  onClickResetSidebarForm: VoidFunction;
  /** Отвечает за адаптивность,
   * если передан false, добавится класс, отвечающий за сокрытие
   * компонента на medium/small экранах */
  hideMedium?: boolean;
}

export const VacanciesFilters: FC<IProps> = ({
  sidebarForm,
  sidebarRefData,
  onSidebarFormChange,
  onClickResetSidebarForm,
  hideMedium = true,
}) => {
  return (
    <SocialServicesFilters
      form={sidebarForm}
      onValuesChange={onSidebarFormChange}
      hide={hideMedium as boolean}
      initialValues={VACANCIES_SIDEBAR_INITIAL_VALUES}
    >
      <ILSButton
        danger
        className="filters-reset-button"
        onClick={onClickResetSidebarForm}
      >
        Сбросить фильтры
      </ILSButton>
      {renderFormItems({
        fields: VACANCY_SIDEBAR_FILTER_FIELDS,
        refData: sidebarRefData,
      })}
      <Salary rules={EMPTY_ARRAY_DATA} disabled />

      {/* Опыт работы */}
    </SocialServicesFilters>
  );
};
