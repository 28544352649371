import { FC } from "react";
import { MyInfoFormLicenseCategoriesButton } from "@modules/social-services/children/my-info/components/license-categories/button";
import { SelectApiOption } from "@/common/types/general/common";

type IProps = {
  categoriesOptions: SelectApiOption[];
  selectedCategories: Array<number>;
  handleCategoriesChange: (category: number) => void;
};

export const MyInfoFormLicenseCategoriesList: FC<IProps> = ({
  categoriesOptions,
  selectedCategories,
  handleCategoriesChange,
}) => {
  return (
    <ul className="my-info-form__categories-list">
      {categoriesOptions.map((category) => (
        <li key={category?.value}>
          <MyInfoFormLicenseCategoriesButton
            category={category}
            selectedCategories={selectedCategories}
            handleCategoriesChange={handleCategoriesChange}
          />
        </li>
      ))}
    </ul>
  );
};
