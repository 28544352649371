import { FC } from "react";
// eslint-disable-next-line max-len
import { useAdminOrganizationUsers } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/use-admin-organization-users";
import { AdminOrganizationUsersList } from "@/modules/admin-panel/children/admin-organizations/children/organization/components/users-list";
import { AdminUserForm } from "@/modules/admin-panel/children/admin-organizations/children/organization/components/forms/user-form";
import { ILSConfirmModal } from "@/common/components/custom/confirm-modal/confirm-modal";

type IProps = {
  organizationID: number | undefined;
};

export const AdminOrganizationUsers: FC<IProps> = ({ organizationID }) => {
  const {
    users,
    isFetching,
    userCardActions,
    modalProps,
    isEditForm,
    form,
    fileList,
    handleSubmit,
    handleCancel,
    handleAvatarChange,
    handleAvatarRemove,
  } = useAdminOrganizationUsers(organizationID);

  return (
    <div className="admin-organization-users">
      {isEditForm && (
        <AdminUserForm
          form={form}
          fileList={fileList}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
      )}
      <AdminOrganizationUsersList
        users={users}
        userCardActions={userCardActions}
        isFetching={isFetching}
      />
      <ILSConfirmModal {...modalProps} />
    </div>
  );
};
