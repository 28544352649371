import {
  DriverInfo,
  isDriverInfoBase,
} from "@/common/types/models/user/driver";
import { NO_DRIVER_LICENSE } from "@/common/features/drivers-common/constants";
import { getDurationString } from "@/common/utils/helpers/date-time/get-duration-string";

/**
 * для неавторизованных пользователей не показываем опыт
 * Если у водителя нет водительского удостоверения,
 * то вместо опыта - "Нет водительского удостоверения",
 * если есть, то указываем опыт.
 */
export const getDriverExperienceSubtitle = (
  driver: DriverInfo
): string | null => {
  const isDriverBase = isDriverInfoBase(driver);

  /** неавторизованные пользователи */
  if (!isDriverBase) {
    return null;
  }

  /** авторизованные пользователи */
  // - нет водительского удостоверения
  if (!driver.HaveDriverLicense) {
    return NO_DRIVER_LICENSE;
  }

  // - есть водительское удостоверение
  const drivingExperience = getDurationString(
    "years",
    Number(driver.FullExperienceYears)
  );

  return drivingExperience ? `Опыт: ${drivingExperience}` : "Опыт не указан";
};
