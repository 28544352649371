import { FC } from "react";
import { RefDataType } from "@/common/features/dictionaries/types";
import { DriverExperience } from "@/common/components/data-entry/driver-experience";
import { AdminEditDriverJobSubform } from "@/modules/admin-panel/children/admin-drivers/components/subforms/job";
import { AdminEditDriverPersonalSubform } from "@/modules/admin-panel/children/admin-drivers/components/subforms/personal";
import { AdminEditDriverMembershipSubform } from "@/modules/admin-panel/children/admin-drivers/components/subforms/membership";
import { AdminEditDriverLicenseSubform } from "@/modules/admin-panel/children/admin-drivers/components/subforms/license";
import { AdminEditDriverPhoneVerified } from "@/modules/admin-panel/children/admin-drivers/components/subforms/phone-verified";

type IProps = {
  showReadyToStartTomorrow: boolean;
  showDriverLicenseField: boolean;
  phoneVerified: boolean;
  refData: RefDataType;
};

export const AdminEditDriverFormFields: FC<IProps> = ({
  showReadyToStartTomorrow,
  showDriverLicenseField,
  phoneVerified,
  refData,
}) => {
  return (
    <div className="admin-edit-driver__fields">
      <div className="admin-edit-driver__fields_main">
        <AdminEditDriverPhoneVerified phoneVerified={phoneVerified} />
        <AdminEditDriverJobSubform
          showReadyToStartTomorrow={showReadyToStartTomorrow}
          refData={refData}
        />
        <AdminEditDriverMembershipSubform />
        <AdminEditDriverPersonalSubform refData={refData} />
        <AdminEditDriverLicenseSubform
          refData={refData}
          showDriverLicenseField={showDriverLicenseField}
        />
      </div>
      <DriverExperience refData={refData} />
    </div>
  );
};
