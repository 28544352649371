import "./index.scss";
import { NavigateBackButton } from "@/common/components/data-display/buttons/navigate-back";
import { ILSUnderConstruct } from "@/common/ui-components/templates/under-construct/under-construct";

export const UnderConstructionPage = () => {
  return (
    <div className="under-construction-page">
      <div className="under-construction-page__content">
        <div className="under-construction-page__header">
          <NavigateBackButton className="under-construction-page__go-back" />
          <div className="under-construction-page__group">
            <ILSUnderConstruct />
          </div>
        </div>
      </div>
    </div>
  );
};
