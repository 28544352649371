import { AvailableRegisterRole } from "@modules/auth/children/register/constants/form-fields";
import { Login, Password, User } from "@/common/types/models/user/user";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

export type CodeMessageType = "mail" | "sms";

export type LoginRequest = {
  Login: Login;
  Password: Password;
};

export type RegisterRequestOrganization = {
  AccountName?: string;
  OrganizationTypeID?: number;
  INN: string;
};

export type RegisterRequestEmployee = {
  Name?: string;
  Surname?: string;
  AccountCode: string;
};

export type RegisterRequestDriver = {
  Name?: string;
  Surname?: string;
  DriverLicense: string;
  HaveDriverLicense: boolean;
};

export type RegisterRequest = {
  Email: string;
  Phone: string;
  Password: Password;
  AccountRole: AvailableRegisterRole;
} & (
  | RegisterRequestOrganization
  | RegisterRequestEmployee
  | RegisterRequestDriver
);

export type RecoveryProcess = "recovery" | "code" | "password";

export type RecoveryRequest = {
  Login: Login;
};

export type CheckPasswordRecoveryCodeRequest = RecoveryRequest & {
  MessageType: CodeMessageType;
  Code: string;
};

export type ResetPasswordRequest = CheckPasswordRecoveryCodeRequest & {
  UserID?: User["ID"];
  Password: Password;
};

export type RecoveryFormFieldsRecoveryPassword = {
  [FormFieldName.Login]: string;
};
export type RecoveryFormFieldsCheckCode = {
  [FormFieldName.Login]: string;
  [FormFieldName.Code]: string;
};
export type RecoveryFormFieldsResetPassword = {
  [FormFieldName.Login]: string;
  [FormFieldName.Code]: string;
  [FormFieldName.Password]: string;
  [FormFieldName.UserID]?: User["ID"];
};

export type RecoveryFormFields =
  | RecoveryFormFieldsRecoveryPassword
  | RecoveryFormFieldsCheckCode
  | RecoveryFormFieldsResetPassword;

// Guard function for RecoveryFormFieldsRecoveryPassword
export function isRecoveryFormFieldsRecoveryPassword(
  fields: RecoveryFormFields
): fields is RecoveryFormFieldsRecoveryPassword {
  return (
    !("code" in fields) &&
    !("password" in fields) &&
    typeof fields[FormFieldName.Login] === "string" &&
    Object.keys(fields).length === 1
  );
}

// Guard function for RecoveryFormFieldsCheckCode
export function isRecoveryFormFieldsCheckCode(
  fields: RecoveryFormFields
): fields is RecoveryFormFieldsCheckCode {
  return (
    !("Password" in fields) &&
    typeof fields[FormFieldName.Login] === "string" &&
    "Code" in fields &&
    typeof fields[FormFieldName.Code] === "string" &&
    Object.keys(fields).length === 2
  );
}

// Guard function for RecoveryFormFieldsResetPassword
export function isRecoveryFormFieldsResetPassword(
  fields: RecoveryFormFields
): fields is RecoveryFormFieldsResetPassword {
  return (
    typeof fields[FormFieldName.Login] === "string" &&
    "Code" in fields &&
    typeof fields[FormFieldName.Code] === "string" &&
    "Password" in fields &&
    typeof fields[FormFieldName.Password] === "string"
  );
}

export type ChangePasswordRequest = {
  OldPassword: string;
  NewPassword: string;
};
