import { Form, Tooltip } from "antd";
import { ILSDivider } from "@/common/components/layout/divider";
import { ILSButton } from "@/common/components/data-display/buttons";
import { RatingWithForm } from "@/common/types/models/rating";
import { useMyRatingForm } from "@/modules/social-services/children/my-info/children/application/hooks/use-my-rating-form";
import { MyRatingFormFields } from "@/modules/social-services/children/my-info/children/application/components/form/fields";
import { MyRatingBlockHeader } from "@/modules/social-services/children/my-info/children/application/components/header";

type IProps = {
  ratingType: RatingWithForm;
};

export const MyRatingForm = ({ ratingType }: IProps) => {
  const { onSubmit } = useMyRatingForm();

  return (
    <Form
      layout="vertical"
      className="rating-form"
      onFinish={onSubmit}
      disabled
    >
      <MyRatingBlockHeader ratingType={ratingType} />
      <ILSDivider className="my-rating-application__divider" />
      <MyRatingFormFields ratingType={ratingType} />
      <Tooltip title="В разработке">
        <ILSButton
          type="primary"
          size="large"
          className="rating-form__submit-btn"
          htmlType="submit"
        >
          Отправить на модерацию
        </ILSButton>
      </Tooltip>
    </Form>
  );
};
