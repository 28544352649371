import { useDispatch } from "react-redux";
import { ADMIN_ORGANIZATIONS_STATUSES } from "@/modules/admin-panel/children/admin-organizations/sagas/notifier";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import {
  OrganizationBaseRequest,
  OrganizationUpdateRequest,
  OrganizationsFiltersRequest,
} from "@/common/api/types/organization";
import { adminOrganizationsRoutine } from "@/modules/admin-panel/children/admin-organizations/actions";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";

type IProps = OrganizationBaseRequest & {
  onSuccess?: VoidFunction;
  onFinally?: VoidFunction;
};

export const useAdminOrganizationsOperations = () => {
  const dispatch = useDispatch();

  const onOrganizationsOperation = ({
    body,
    id,
    operation,
    filters,
    onSuccess,
    onFinally,
  }: IProps) => {
    try {
      dispatch(
        adminOrganizationsRoutine({
          operation,
          id,
          body,
          filters,
          customNotificationMessage: ADMIN_ORGANIZATIONS_STATUSES,
        })
      );
      onSuccess?.();
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      onFinally?.();
    }
  };

  const handleGetAllOrganizations = ({
    filters,
  }: {
    filters?: OrganizationsFiltersRequest;
  }) => {
    return onOrganizationsOperation({
      operation: OperationsEndpoint.GetAll,
      filters,
    });
  };

  const handleGetOrganization = ({ id }: { id: number | undefined }) => {
    return onOrganizationsOperation({
      operation: OperationsEndpoint.Get,
      id,
    });
  };

  const handleUpdateOrganization = ({
    id,
    body,
  }: {
    id: number | undefined;
    body?: OrganizationUpdateRequest;
  }) => {
    return onOrganizationsOperation({
      operation: OperationsEndpoint.Update,
      id,
      body,
    });
  };

  return {
    handleGetAllOrganizations,
    handleGetOrganization,
    handleUpdateOrganization,
  };
};
