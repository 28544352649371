import { useSelector, useDispatch } from "react-redux";
import { vacanciesChangeFiltersRoutine } from "@/common/features/vacancies-common/actions";
import { vacanciesFiltersSelector } from "@/common/features/vacancies-common/selectors";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";
import { getVacanciesDefaultFilters } from "@/common/features/vacancies-common/helpers/get-vacancies-default-filters";

export const useChangeVacanciesFilters = () => {
  const filters = useSelector(vacanciesFiltersSelector);
  const dispatch = useDispatch();

  const changeFilters = (currentFilters: VacanciesFilters | undefined) => {
    const commonFilters = { ...filters, ...currentFilters };
    dispatch(vacanciesChangeFiltersRoutine.success({ filters: commonFilters }));
  };

  const resetFilters = ({
    isAdminPanel = false,
    isOrganizationEmployee = false,
    accountID = null,
  }: {
    isAdminPanel?: boolean;
    isOrganizationEmployee?: boolean;
    accountID?: number | null;
  }) => {
    const defaultFilters = getVacanciesDefaultFilters({
      isAdminPanel,
      isOrganizationEmployee,
      accountID,
    });
    dispatch(
      vacanciesChangeFiltersRoutine.success({ filters: defaultFilters })
    );
  };

  return { filters, changeFilters, resetFilters };
};
