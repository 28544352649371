import { takeLatest } from "redux-saga/effects";
import { Notify } from "@/common/types/notifications/enum";
import { userNotificationHook } from "@/common/hooks/user-notification-hook";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import {
  AdminOrganizationsActions,
  AdminOrganizationsActionsForNotifications,
} from "@/modules/admin-panel/children/admin-organizations/actions";

export type AdminOrganizationsStatusesType = {
  [key in AdminOrganizationsActions]: {
    loading: Record<string, string> | string;
    success: Record<string, string> | string;
    successPlural?: string;
    error?: string;
  };
};

export const ADMIN_ORGANIZATIONS_STATUSES: AdminOrganizationsStatusesType = {
  [AdminOrganizationsActions.DICTIONARY_OPERATION]: {
    [Notify.Loading]: {
      [OperationsEndpoint.GetAll]: "Организации загружаются",
      [OperationsEndpoint.Get]: "Организация загружается",
      [OperationsEndpoint.Update]: "Организация обновляется",
    },
    [Notify.Success]: {
      [OperationsEndpoint.GetAll]: "Организации получены",
      [OperationsEndpoint.Get]: "Организация получена",
      [OperationsEndpoint.Update]: "Данные успешно сохранены",
    },
  },
  [AdminOrganizationsActions.USERS_OPERATION]: {
    [Notify.Loading]: {
      [OperationsEndpoint.GetAll]: "Данные сотрудников загружаются",
      [OperationsEndpoint.Get]: "Данные сотрудника загружаются",
    },
    [Notify.Success]: {
      [OperationsEndpoint.GetAll]: "Данные сотрудников получены",
      [OperationsEndpoint.Get]: "Данные сотрудника получены",
    },
  },
};

const worker = userNotificationHook({
  defaultStatuses: ADMIN_ORGANIZATIONS_STATUSES,
  moduleKey: "ADMIN_ORGANIZATIONS",
});

export function* adminOrganizationsNotifierWatcher() {
  // eslint-disable-next-line no-restricted-syntax
  for (const routine of AdminOrganizationsActionsForNotifications) {
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.SUCCESS, worker);
  }
}
