import { FC } from "react";
import { RatingContentType, RatingBlocks } from "@/common/types/models/rating";
import { MyRatingTotalList } from "@/modules/social-services/children/my-info/children/application/components/list";
import { MyRatingBlockHeader } from "@/modules/social-services/children/my-info/children/application/components/header";

type IProps = {
  ratingValues: RatingBlocks;
};

export const MyRatingTotal: FC<IProps> = ({ ratingValues }) => {
  return (
    <div className="my-rating-application__total">
      <MyRatingBlockHeader ratingType={RatingContentType.RatingTotal} />
      <MyRatingTotalList ratingValues={ratingValues} />
    </div>
  );
};
