import { FC } from "react";
import { OrganizationInfo } from "@/common/types/models/account";
import { AdminOrganizationForm } from "@/modules/admin-panel/children/admin-organizations/children/organization/components/forms/organization-form";
import { useAdminOrganizationForm } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/use-admin-organization-form";

type IProps = {
  organization: OrganizationInfo | null;
};

export const AdminOrganizationFormContainer: FC<IProps> = ({
  organization,
}) => {
  const {
    form,
    initialValues,
    fileList,
    handleSubmit,
    handleCancelChange,
    handleAvatarChange,
    handleAvatarRemove,
  } = useAdminOrganizationForm({ organization });

  return (
    <AdminOrganizationForm
      form={form}
      initialValues={initialValues}
      fileList={fileList}
      handleSubmit={handleSubmit}
      handleCancelChange={handleCancelChange}
      handleAvatarChange={handleAvatarChange}
      handleAvatarRemove={handleAvatarRemove}
    />
  );
};
