import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { ILSButton } from "@/common/components/data-display/buttons";
import {
  UserCardActions,
  UserCardActionsType,
} from "@/common/types/models/user/card-actions";
import { getObjectKeysWithType } from "@/common/utils/helpers/types";

type IProps = {
  userId: number;
  userCardActions: UserCardActionsType;
  extraDisabledConditions?: Partial<Record<UserCardActions, boolean>>;
  userIsBlocked: boolean;
};

export const renderUserCardActions = ({
  userId,
  userCardActions,
  extraDisabledConditions,
  userIsBlocked,
}: IProps) => {
  return getObjectKeysWithType<UserCardActions>(UserCardActions).map(
    (actionType) => {
      const action = userCardActions[actionType];

      const disabled =
        action?.disabled || extraDisabledConditions?.[actionType] || false;

      const blockIcon = userIsBlocked ? <UnlockOutlined /> : <LockOutlined />;

      return (
        <ILSButton
          title={action.title}
          key={action.title}
          icon={actionType === UserCardActions.Block ? blockIcon : action.icon}
          onClick={() => action.onClick(userId)}
          disabled={disabled}
          shape="circle"
          type="default"
        />
      );
    }
  );
};
