import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { MyRatingApplicationContainer } from "@/modules/social-services/children/my-info/children/application/containers";

const module: IModuleModel = {
  title: ModuleTitle.myRatingApplication,
  name: ModuleName.myRatingApplication,
  path: ModulePath.myRatingApplication,
  element: <MyRatingApplicationContainer />,
  persist: false,
};

export const MyRatingApplicationModule = defineModule(module);
