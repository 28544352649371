import { isEmpty } from "lodash";
import { JoinChar } from "@/common/constants/general";
import { DictionaryType } from "@/common/types/models/dictionary";

export const getDictionaryArrayName = (
  values: DictionaryType[] | null | undefined,
  aliasAsName: boolean = false
) => {
  if (isEmpty(values)) return null;
  const arrValues = values?.map((value) => {
    return !aliasAsName ? value?.Name : value?.Alias;
  });

  return arrValues?.join(JoinChar.Comma);
};
