// TODO: временно тут
import { PartnershipCheckStatus } from "@/common/types/models/account";
import { MembershipCheckStatus } from "@/common/types/models/user/driver";

export const ORGANIZATION_TYPE_ID_OPTIONS = [
  { value: 1, label: "ИП" },
  { value: 2, label: "ООО" },
  { value: 3, label: "АО" },
  { value: 4, label: "ЗАО" },
  { value: 5, label: "ПАО" },
  { value: 6, label: "НПП" },
  { value: 7, label: "не указано" },
];

export const SEX_OPTIONS = [
  {
    value: 0,
    label: "Мужской",
  },
  {
    value: 1,
    label: "Женский",
  },
];

export const MEDICAL_CERTIFICATE_OPTIONS = [
  {
    value: 0,
    label: "Неважно",
  },
  {
    value: 1,
    label: "Есть",
  },
];

export enum WorkScheduleType {
  FullDay = "FullDay",
  PartTime = "PartTime",
  Free = "Free",
  Shift = "Shift",
  Rotational = "Rotational",
  SixOne = "SixOne",
  FiveTwo = "FiveTwo",
  Remote = "Remote",
}

export const WorkScheduleLabel = {
  [WorkScheduleType.FullDay]: "Полный день",
  [WorkScheduleType.PartTime]: "Неполный день",
  [WorkScheduleType.Free]: "Свободный",
  [WorkScheduleType.Shift]: "Сменный",
  [WorkScheduleType.Rotational]: "Вахтовый",
  [WorkScheduleType.FiveTwo]: "5/2 (пн. - пт.)",
  [WorkScheduleType.SixOne]: "6/1 (пн. - сб.)",
  [WorkScheduleType.Remote]: "Удалённая",
};

export const DRIVER_LICENSE_MORE_THAN_THREE_OPTIONS = [
  {
    value: 0,
    label: "Неважно",
  },
  {
    value: 1,
    label: "От трех лет",
  },
];

export const READY_START_TOMORROW_OPTIONS = [
  {
    value: 0,
    label: "Неважно",
  },
  {
    value: 1,
    label: "Да",
  },
];

export const ALL_STATUSES_VALUE = "All";

export const JOB_SEARCH_STATUS_ALL_OPTION = {
  value: ALL_STATUSES_VALUE,
  label: "Все",
};
/** ORGANIZATION_TYPE_ID_OPTIONS: 7 === "нет" */
export const DEFAULT_ORGANIZATION_TYPE_ID_VALUE = 7;

export const PUBLIC_ORGANIZATION_MEMBERSHIP_CHECKED_OPTIONS = [
  {
    value: MembershipCheckStatus.Cancelled,
    label: "Отклонить",
  },
  {
    value: MembershipCheckStatus.Confirmed,
    label: "Подтвердить",
  },
];

export const PUBLIC_ORGANIZATION_PARTNERSHIP_CHECK_OPTIONS = [
  {
    value: PartnershipCheckStatus.Cancelled,
    label: "Отклонить",
  },
  {
    value: PartnershipCheckStatus.Confirmed,
    label: "Подтвердить",
  },
];
