import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { ADMIN_ORGANIZATIONS_INITIAL_STATE } from "@/modules/admin-panel/children/admin-organizations/store";
import { IndexedCollection } from "@/common/types/general/common";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { User } from "@/common/types/models/user/user";
import { adminOrganizationsUsersRoutine } from "@/modules/admin-panel/children/admin-organizations/actions";

export const adminOrganizationUsersReducer = createReducer(
  ADMIN_ORGANIZATIONS_INITIAL_STATE,
  {
    [adminOrganizationsUsersRoutine.REQUEST]: onRequest,
    [adminOrganizationsUsersRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{
        operation: OperationsEndpoint;
        data: IndexedCollection<User> | User;
        userID: number;
      }>
    ) => {
      const { operation, data, userID } = action.payload;

      switch (operation) {
        case OperationsEndpoint.GetAll:
          state.users = data as IndexedCollection<User>;
          break;

        case OperationsEndpoint.Get:
        case OperationsEndpoint.Update:
          if (userID) {
            state.users[userID] = data as User;
          }
          break;

        case OperationsEndpoint.Delete:
          if (userID) {
            state.users[userID] = { ...state.users[userID], Deleted: 1 };
          }
          break;

        default:
          break;
      }
      return state;
    },
    [adminOrganizationsUsersRoutine.FAILURE]: onFailure,
    [adminOrganizationsUsersRoutine.FULFILL]: onFulfill,
  }
);
