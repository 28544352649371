import { FC } from "react";
import { RatingBlocks } from "@/common/types/models/rating";
import { MyRatingTotalListHeader } from "@/modules/social-services/children/my-info/children/application/components/list/header";
import { MyRatingTotalListItem } from "@/modules/social-services/children/my-info/children/application/components/list/item";
import { RATING_BLOCKS_LIST } from "@/modules/social-services/children/my-info/constants/rating";

export type IProps = {
  ratingValues: RatingBlocks;
};

export const MyRatingTotalList: FC<IProps> = ({ ratingValues }) => {
  return (
    <div className="rating-list">
      <MyRatingTotalListHeader />

      <ul className="rating-list__content">
        {RATING_BLOCKS_LIST.map((ratingType) => (
          <MyRatingTotalListItem
            ratingType={ratingType}
            ratingValues={ratingValues[ratingType]}
            key={ratingType}
          />
        ))}
      </ul>
    </div>
  );
};
