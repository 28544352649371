import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";
import { VerificationFormType } from "@/modules/social-services/children/my-info/types/verification";

export const MY_INFO_TAB_NAMES = {
  [MyInfoTabs.Account]: "Учетная запись",
  [MyInfoTabs.MyOrganization]: "Моя организация",
  [MyInfoTabs.Users]: "Пользователи",
  [MyInfoTabs.MyRating]: "Мой рейтинг",
  [MyInfoTabs.Questionnaire]: "Анкета",
};

export const VERIFICATION_MODAL_TITLE = "Подтверждение номера телефона";

export const VERIFICATION_FORM_TITLE = {
  [VerificationFormType.getCode]:
    'Для работы с сервисами "Водители России" необходимо подтвердить номер телефона',
  [VerificationFormType.checkCode]:
    "Мы отправили смс-код на указанный номер, чтобы убедиться, что это именно ваш телефон. Введите смс-код, пожалуйста",
};

export const VERIFICATION_SUBMIT_BUTTON = {
  [VerificationFormType.getCode]: "Получить код по СМС",
  [VerificationFormType.checkCode]: "Подтвердить код",
};

export const VERIFICATION_SUCCESS_TITLE = "Телефон успешно подтвержден";
export const VERIFICATION_SUCCESS_SUBTITLE =
  "Теперь Вы можете пользоваться всеми сервисами и откликаться на вакансии!";

export const VERIFY_PHONE_BUTTON = "Подтвердить номер";

export const PROFILE_COMPLETE = "Все обязательные данные заполнены!";
export const PROFILE_INCOMPLETE = "Обязательные поля не заполнены:";

export const DRIVER_SUCCESS_ALERT = {
  title: "Вы можете откликаться на вакансии",
  text: "Телефон подтвержден, обязательные данные профиля заполнены",
};
export const DRIVER_INFO_ALERT = {
  title: "Обратите внимание!",
  text: `Чтобы откликаться на вакансии, необходимо 
  подтвердить номер телефона и заполнить обязательные поля в личном кабинете`,
};
