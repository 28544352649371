import { FC } from "react";
import { ILSImage } from "@/common/components/data-display/image";
import { NewsStatus } from "@/common/types/models/news";
import {
  NewsStatusTitles,
  NEWS_CARD_IMAGE_HEIGHT,
  NEWS_CARD_IMAGE_WIDTH,
} from "@/modules/start/constants/news";

type IProps = {
  imageSrc: string;
  status: NewsStatus;
};

export const NewsCardCover: FC<IProps> = ({ imageSrc, status }) => {
  return (
    <div className="news-card__cover">
      <ILSImage
        src={imageSrc}
        height={NEWS_CARD_IMAGE_HEIGHT}
        width={NEWS_CARD_IMAGE_WIDTH}
        preview={false}
        loading="eager"
      />
      <div className="news-card__status">
        <div className="news-card__status-dot" />
        <p>{NewsStatusTitles[status]}</p>
      </div>
    </div>
  );
};
