import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { PRESIDENT_HEADER_TITLE } from "@/modules/social-services/children/contacts/constants";
import { SendQuestionType } from "@/common/api/types/send-question";
import { ContactsPresidentPhoto } from "@/modules/social-services/children/contacts/components/president-photo";
import { ContactsSendQuestionForm } from "@/modules/social-services/children/contacts/components/form";
import { ContactsPresidentBiography } from "@/modules/social-services/children/contacts/components/president-biography";

export const ContactsPresident = () => {
  return (
    <div className="contacts__section">
      <div className="contacts__section-header">
        <ILSTitle
          children={PRESIDENT_HEADER_TITLE}
          className="contacts__section-title"
          ellipsis={false}
        />
      </div>
      <div className="contacts__section-content">
        <ContactsPresidentBiography />
        <div className="contacts__president-photo-form">
          <ContactsPresidentPhoto hideMedium />
          <ContactsSendQuestionForm type={SendQuestionType.PresidentQuestion} />
        </div>
      </div>
    </div>
  );
};
