import { FC } from "react";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Space } from "antd";
import {
  PROFILE_COMPLETE,
  PROFILE_INCOMPLETE,
} from "@/modules/social-services/children/my-info/constants";

type IProps = {
  hasBlankRequiredFields: boolean;
  driverRequiredFieldsMessages?: string[];
};

export const MyInfoProfileCompleteness: FC<IProps> = ({
  hasBlankRequiredFields,
  driverRequiredFieldsMessages,
}) => {
  return (
    <div className="my-info__profile-completeness">
      {!hasBlankRequiredFields ? (
        <Space>
          <CheckCircleOutlined />
          <p className="my-info__profile-completeness-title">
            {PROFILE_COMPLETE}
          </p>
        </Space>
      ) : (
        <div className="my-info__profile-completeness-titles">
          <Space>
            <ExclamationCircleOutlined className="error" />
            <p className="my-info__profile-completeness-title">
              {PROFILE_INCOMPLETE}
            </p>
          </Space>
          <ul className="my-info__profile-completeness-list">
            {driverRequiredFieldsMessages?.map((message) => (
              <li key={message}>
                <p>{message}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
