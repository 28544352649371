import { FileDoneOutlined } from "@ant-design/icons";

export const PROJECTS_CARDS_CONTENT = [
  {
    icon: <FileDoneOutlined />,
    title: "Проекты",
    value: "https://driversrussia.ru/projects/",
    href: "https://driversrussia.ru/projects/",
  },
];
