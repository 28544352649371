import { PayloadAction } from "@reduxjs/toolkit";

export enum CodeTargetType {
  mail = "email",
  sms = "sms",
}

export type CodeSend = {
  Target: string;
};

export type CodeCheck = {
  Code: string;
};

export type SendCodePayloadAction = PayloadAction<CodeSend>;
export type CheckCodePayloadAction = PayloadAction<CodeCheck>;
