import { FC } from "react";
import { SOCIAL_RATING_TITLE } from "@/common/constants/config/rating";
import { ILSRating } from "@/common/ui-components/data-display/rating";
import { ILSText } from "@/common/ui-components/general/typography/text";

type IProps = {
  rating: number;
};

export const MyInfoFormRating: FC<IProps> = ({ rating }) => {
  return (
    <div className="my-info-form__rating-group">
      <ILSText>{SOCIAL_RATING_TITLE}</ILSText>
      <ILSRating rating={rating} />
    </div>
  );
};
