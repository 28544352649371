import { logoutActions } from "@modules/auth/actions";
import { APIAxiosError } from "@/common/api/types/error";
import { isErrorsAPI } from "@/common/api/helpers/error/is-errors-api";
import { isErrorWithMessages } from "@/common/api/helpers/error/is-error-with-message";
import { normalizeErrorWithMessages } from "@/common/api/helpers/error/normalize-error-with-messages";
import { API_ERROR_MESSAGES } from "@/common/api/constants/error";
import { showDevelopError } from "@/common/helpers/errors/show-develop-error";
import { showNotificationError } from "@/common/helpers/errors/show-notification-error";
import { DRIVER_RF_REGISRTY } from "@/index";

export const handleApiError = (error: APIAxiosError) => {
  showDevelopError(error);

  const status = error?.response?.status;
  let errorMessage;

  if (isErrorsAPI(error) && isErrorWithMessages(error)) {
    errorMessage = normalizeErrorWithMessages(error);
  }

  const errorMessagesMap: Record<number, string> = {
    0: errorMessage || API_ERROR_MESSAGES.Error_Network,
    400: errorMessage || API_ERROR_MESSAGES.Error_400,
    401: API_ERROR_MESSAGES.Error_401,
    403: API_ERROR_MESSAGES.Error_403,
    404: API_ERROR_MESSAGES.Error_Network,
    500: API_ERROR_MESSAGES.Error_500,
  };

  if (status === 401) {
    DRIVER_RF_REGISRTY?.store?.dispatch(logoutActions.trigger());
  } else {
    showNotificationError(errorMessagesMap[status || 0]);
  }
};
