import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { authUserSetActions, loginActions } from "@modules/auth/actions";
import { NavigateFunction } from "react-router-dom";
import { ModulePath } from "@/common/types/core/routes";
import { AUTH_API } from "@/common/api/endpoints/auth";
import { User } from "@/common/types/models/user/user";
import { LoginRequest } from "@/common/api/types/auth";

export type LoginPayload = LoginRequest & {
  navigate: NavigateFunction;
};
export type LoginAction = PayloadAction<LoginPayload>;

export function* loginWorker(action: LoginAction) {
  const { request, failure, fulfill } = loginActions;
  const { Login, Password, navigate } = action.payload;
  try {
    yield put(request());
    const { data: user }: { data: User } = yield call(AUTH_API.login, {
      Login,
      Password,
    });
    yield put(authUserSetActions.success({ user }));
    navigate(ModulePath.startPage);
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* loginWatcher() {
  yield takeLatest(loginActions.trigger, loginWorker);
}
