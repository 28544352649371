import classNames from "classnames";
import { FC } from "react";
import { PRESIDENT_PHOTO_SRC } from "@/modules/social-services/children/contacts/constants";
import { ILSImage } from "@/common/components/data-display/image";

type IProps = {
  hideMedium?: boolean;
};

export const ContactsPresidentPhoto: FC<IProps> = ({ hideMedium = false }) => {
  return (
    <div
      className={classNames("contacts__president-photo", {
        hide: hideMedium,
      })}
    >
      <ILSImage src={PRESIDENT_PHOTO_SRC} preview={false} />
    </div>
  );
};
