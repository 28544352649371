import { User } from "@/common/types/models/user/user";
import { RegisterRequest } from "@/common/api/types/auth";
import { IndexedCollection } from "@/common/types/general/common";
import { UpdateRequestBody } from "@/common/api/types/api";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { ImageBaseRequest } from "@/common/types/models/image";

export type UserBaseRequest = {
  body?: UserUpdateBody;
  userID?: number;
  adminPanel?: boolean;
  operation: OperationsEndpoint;
  filters?: UsersFilters;
};

export type UserUpdateBody = UpdateRequestBody<
  Omit<User, "Avatar"> & { Avatar: ImageBaseRequest | undefined }
>;
export type UserCreateRequest = RegisterRequest & UserBaseRequest;

export type UsersFilters = {
  OrganizationID: number | undefined;
  Source?: UsersSourceType | null;
};

export type GetUsersResponse = IndexedCollection<User>;

export enum UsersSourceType {
  Organization = "Organization",
}
