import { FC } from "react";
import { TreeSelect } from "antd";
import { ARTICLE_CATEGORY_TREE_DATA } from "@modules/admin-panel/children/admin-articles/constants/form";

interface IProps {
  crudMode?: boolean;
}

export const ArticleCategories: FC<IProps> = ({ crudMode = false }) => {
  return (
    <TreeSelect
      multiple={!crudMode}
      showSearch
      style={{ width: "300px" }}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder="Выберите категорию статьи"
      allowClear
      treeDefaultExpandAll
      treeData={ARTICLE_CATEGORY_TREE_DATA}
      treeIcon
    />
  );
};
