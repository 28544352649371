import { flatModules } from "../modules/flat-modules";
import { TModulesObj, TSagasObj } from "@/common/types/core/module";

export const getModuleSagas = (
  modules: TModulesObj,
  sagas: TSagasObj = {}
): TSagasObj => {
  const flat = flatModules(modules);

  for (let i = 0; i < flat.length; i++) {
    const saga = (
      Array.isArray(flat[i].sagas) ? flat[i].sagas : [flat[i].sagas]
    ) as Generator[];
    const moduleSagas: Generator[] = [];

    for (let j = 0; j < saga.length; j++) {
      moduleSagas.push(saga[j]);
    }

    if (moduleSagas.length) {
      if (!sagas[flat[i].MODULE!]) {
        sagas[flat[i].MODULE!] = moduleSagas;
      } else {
        sagas[flat[i].MODULE!] = sagas[flat[i].MODULE!].concat(moduleSagas);
      }
    }

    if (
      typeof flat[i].children === "object" &&
      Object.keys(flat[i].children!).length > 0
    ) {
      getModuleSagas(flat[i].children!, sagas);
    }
  }

  return sagas;
};
