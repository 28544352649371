import { RATING_BADGE_COLORS } from "@/common/constants/rating/color";
import { BackgroundColor } from "@/common/ui-components/data-display/rating";

export const getRatingStrokeColor = (rating: number): BackgroundColor => {
  if (rating < 30) return RATING_BADGE_COLORS.low;
  if (rating < 50) return RATING_BADGE_COLORS.lessThanHalf;
  if (rating < 80) return RATING_BADGE_COLORS.moreThanHalf;
  if (rating < 100) return RATING_BADGE_COLORS.high;
  return RATING_BADGE_COLORS.full;
};
