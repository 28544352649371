import IMask from "imask";
import { PHONE_MASK } from "@/common/constants/config/masks";
import { EMPTY_STRING } from "@/common/constants/general";

/** форматирование телефона 71234567890 => +7(123)456-78-90 */
export const getFormattedPhone = (phone?: string | null) => {
  if (!phone) return EMPTY_STRING;

  const masked = IMask.createMask({
    mask: PHONE_MASK,
  });
  return masked.resolve(phone);
};
