import { useAdminApplications } from "@/modules/admin-panel/children/admin-applications/hooks/use-admin-applications";
import { AdminApplicationsSidebar } from "@/modules/admin-panel/children/admin-applications/containers/sidebar";
import { AdminQuestionnaireResultsList } from "@/modules/admin-panel/children/admin-applications/components/list";

export const AdminApplicationsMain = () => {
  const {
    results,
    isFetching,
    documents,
    form,
    questionnaireIsFetching,
    disableUploadDelete,
    availabilityStatus,
    onQuestionnaireAvailabilityChange,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  } = useAdminApplications();

  return (
    <div className="admin-questionnaire">
      <div className="admin-questionnaire__content">
        <AdminApplicationsSidebar
          documents={documents}
          form={form}
          questionnaireIsFetching={questionnaireIsFetching}
          disableUploadDelete={disableUploadDelete}
          availabilityStatus={availabilityStatus}
          onQuestionnaireAvailabilityChange={onQuestionnaireAvailabilityChange}
          handleCheckBeforeUpload={handleCheckBeforeUpload}
          handleDeleteDocument={handleDeleteDocument}
        />
        <AdminQuestionnaireResultsList
          results={results}
          isFetching={isFetching}
        />
      </div>
    </div>
  );
};
