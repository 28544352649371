import { FC, ReactNode } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSParagraph } from "@/common/ui-components/general/typography/paragraph";

export interface IWideCardInfoProps {
  title: string | undefined;
  titleBadge?: ReactNode;
  subtitle?: string | null | undefined;
  mainSubtitle?: string | null | ReactNode | undefined;
  description?: string | null | undefined;
}

export const WideCardInfo: FC<IWideCardInfoProps> = ({
  title,
  titleBadge,
  subtitle,
  mainSubtitle,
  description,
}) => {
  return (
    <div className="ils-card__info">
      {title && (
        <div className="ils-card__info-title-wrapper">
          <ILSTitle
            ellipsis={{ rows: 3 }}
            level={3}
            className="ils-card__info-title"
            children={title}
          />
          {titleBadge && titleBadge}
        </div>
      )}

      {mainSubtitle && (
        <ILSTitle ellipsis level={4} className="ils-card__info-main-subtitle">
          {mainSubtitle}
        </ILSTitle>
      )}

      {subtitle && (
        <ILSTitle
          level={4}
          className="ils-card__info-subtitle"
          children={subtitle}
        />
      )}
      {description && (
        <ILSParagraph
          ellipsis={false}
          className="ils-card__info-description"
          children={description}
        />
      )}
    </div>
  );
};
