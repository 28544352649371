import { FC, PropsWithChildren } from "react";
import { Persistor } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

export type PersistWrapper = (
  persistor: Persistor
) => FC<PropsWithChildren<{}>>;

export const renderPersistWrapper: PersistWrapper = (persistor) => {
  return ({ children }: PropsWithChildren<{}>) => {
    return (
      <PersistGate
        // loading={<GlobalSpin />}
        persistor={persistor}
      >
        {children}
      </PersistGate>
    );
  };
};
