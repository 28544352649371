import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  deleteUserActions,
  getUsersActions,
} from "@modules/social-services/children/my-info/actions";
import { currentUserIDSelector } from "@modules/auth/selectors/current-user";
import { logoutActions } from "@modules/auth/actions";
import { USER_API } from "@/common/api/endpoints/user";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";

export type DeleteUserAction = PayloadAction<{ userID: number | undefined }>;

export function* deleteUserWorker(action: DeleteUserAction) {
  const { request, failure, fulfill } = deleteUserActions;

  const { userID } = action.payload;
  try {
    yield put(request());
    yield call(USER_API.delete, {
      userID,
    });

    const currentUserID: string | number = yield select(currentUserIDSelector);

    if (String(userID) === String(currentUserID)) {
      yield put(logoutActions.trigger());
    }
    yield put(getUsersActions.trigger());
    yield call(showSuccessNotification, "Данные успешно сохранены");
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* deleteUserWatcher() {
  yield takeLatest(deleteUserActions.trigger, deleteUserWorker);
}
