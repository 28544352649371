import "./news-header.scss";
import { RightOutlined } from "@ant-design/icons";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ModulePath } from "@/common/types/core/routes";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { StartPageHeader } from "@/modules/start/components/header/start-page-header";

export const StartPageNewsHeader = () => {
  return (
    <div className="news-header">
      <StartPageHeader>Новости</StartPageHeader>
      <ILSLink to={ModulePath.underConstruction}>
        <ILSButton danger>
          Больше новостей
          <RightOutlined />
        </ILSButton>
      </ILSLink>
    </div>
  );
};
