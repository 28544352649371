import { useForm } from "antd/es/form/Form";
import { SendQuestionType } from "@/common/api/types/send-question";
import { SendQuestionFormValues } from "@/modules/social-services/children/contacts/types/form";
import { useAuth } from "@/common/hooks/use-auth";
import { useSendQuestion } from "@/modules/social-services/children/contacts/hooks/use-send-question";
import { showNotificationError } from "@/common/helpers/errors/show-notification-error";

export const useContactsPresidentForm = () => {
  const [form] = useForm<SendQuestionFormValues>();
  const { authenticated } = useAuth();
  const { handleSendQuestion } = useSendQuestion(
    SendQuestionType.PresidentQuestion
  );

  const resetForm = () => {
    form.resetFields();
  };

  const handleSubmit = (formValues: SendQuestionFormValues) => {
    const emptyFields = Object.values(formValues).some(
      (value) => !value?.trim()
    );
    if (!emptyFields) {
      return handleSendQuestion(formValues.Question, resetForm);
    }
    return showNotificationError("Пожалуйста, заполните форму");
  };

  const submitDisabled = !authenticated;
  const formDisabled = !authenticated;

  return { form, submitDisabled, formDisabled, handleSubmit };
};
