import { FC } from "react";
import { FormInstance } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { ILSButton } from "@/common/components/data-display/buttons";
import { usePopover } from "@/common/hooks/use-popover";
import { ILSPopover } from "@/common/components/data-display/popover";
import { AdminVacanciesFilters } from "@/modules/admin-panel/children/admin-vacancies/containers/filters";
import { RefDataType } from "@/common/features/dictionaries/types";
import {
  VacanciesSidebarFilterValues,
  VacanciesToolbarFilterValues,
} from "@/common/features/vacancies-common/types/filters";
import { AdminVacanciesToolbarForm } from "@/modules/admin-panel/children/admin-vacancies/components/admin-vacancies-toolbar-form";
import { VacanciesSortPopover } from "@/common/features/vacancies-common/components/sort-form/vacancies-sort-popover";

interface IProps {
  toolbarForm: FormInstance<VacanciesToolbarFilterValues>;
  sidebarForm: FormInstance<VacanciesSidebarFilterValues>;
  sidebarRefData: RefDataType;
  activeSortKey: string | undefined;
  onToolbarFormChange: (
    changedValues: VacanciesToolbarFilterValues,
    values: VacanciesToolbarFilterValues
  ) => void;
  onSidebarFormChange: (
    changedValues: VacanciesSidebarFilterValues,
    values: VacanciesSidebarFilterValues
  ) => void;
  onClickResetSidebarForm: VoidFunction;
  onSortClick: ({ key }: { key: string }) => void;
}

export const AdminVacanciesToolbar: FC<IProps> = ({
  toolbarForm,
  sidebarForm,
  sidebarRefData,
  activeSortKey,
  onToolbarFormChange,
  onSidebarFormChange,
  onClickResetSidebarForm,
  onSortClick,
}) => {
  const { open, handleOpenChange } = usePopover();

  return (
    <div className="admin-vacancies__toolbar">
      <AdminVacanciesToolbarForm
        toolbarForm={toolbarForm}
        onToolbarFormChange={onToolbarFormChange}
      />
      <VacanciesSortPopover
        activeSortKey={activeSortKey}
        onSortClick={onSortClick}
      />
      <ILSPopover
        trigger="click"
        content={
          <AdminVacanciesFilters
            sidebarForm={sidebarForm}
            sidebarRefData={sidebarRefData}
            onSidebarFormChange={onSidebarFormChange}
            onClickResetSidebarForm={onClickResetSidebarForm}
            hideLarge={false}
          />
        }
        open={open}
        onOpenChange={handleOpenChange}
      >
        <ILSButton
          children="Фильтры"
          type="primary"
          className="filters-button"
          icon={<FilterOutlined />}
        />
      </ILSPopover>
    </div>
  );
};
