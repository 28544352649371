import { isObject } from "lodash";
import { UploadFile } from "antd";
import { EMPTY_STRING } from "@/common/constants/general";
import {
  DeletedImageRequest,
  ImageBaseRequest,
} from "@/common/types/models/image";
import { getDataStringFromBase64 } from "@/common/utils/helpers/regexp/get-data-string-from-base64";

export const getVacancyImages = (
  files?: (string | DeletedImageRequest)[],
  photosFiles?: UploadFile[]
): ImageBaseRequest[] | undefined => {
  return files?.map((file, i) => {
    if (isObject(file) && "DeleteID" in file) {
      return file;
    }

    return {
      Name: photosFiles?.[i]?.name || EMPTY_STRING,
      Data: getDataStringFromBase64(file),
    };
  });
};
