import { useDispatch } from "react-redux";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import {
  DriverBaseRequest,
  DriversFiltersRequestType,
} from "@/common/api/types/driver";
import { DRIVERS_STATUSES } from "@/common/features/drivers-common/sagas/notifier";
import { driversRoutine } from "@/common/features/drivers-common/actions";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";

type IProps = DriverBaseRequest & {
  onSuccess?: VoidFunction;
  onFinally?: VoidFunction;
};

export const useDriversOperations = () => {
  const dispatch = useDispatch();

  const onDriversOperation = ({
    id,
    operation,
    filters,
    onSuccess,
    onFinally,
  }: IProps) => {
    try {
      dispatch(
        driversRoutine({
          operation,
          id,
          filters,
          customNotificationMessage: DRIVERS_STATUSES,
        })
      );
      onSuccess?.();
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      onFinally?.();
    }
  };

  /**
   * Получить всех водителей
   * @param filters
   */
  const handleGetDrivers = (filters: DriversFiltersRequestType) =>
    onDriversOperation({
      operation: OperationsEndpoint.GetAll,
      filters,
    });

  /**
   * Получить водителя по ID
   * @param driverID
   */
  const handleGetDriver = (driverID: number | undefined) =>
    onDriversOperation({
      operation: OperationsEndpoint.Get,
      id: driverID,
    });

  return {
    handleGetDrivers,
    handleGetDriver,
  };
};
