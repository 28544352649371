import reduceReducers from "reduce-reducers";
import { ADMIN_ORGANIZATIONS_INITIAL_STATE } from "@/modules/admin-panel/children/admin-organizations/store";
import { adminOrganizationsReducer } from "@/modules/admin-panel/children/admin-organizations/reducers/organizations";
import { adminOrganizationUsersReducer } from "@/modules/admin-panel/children/admin-organizations/reducers/users";

export const adminOrganizationsRootReducer = reduceReducers(
  ADMIN_ORGANIZATIONS_INITIAL_STATE,
  adminOrganizationsReducer,
  adminOrganizationUsersReducer
);
