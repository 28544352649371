import { IModuleModel } from "@/common/types/core/module";

export const composeModuleDefinition = (
  module: IModuleModel
): IModuleModel => ({
  name: module.name,
  title: module.title,
  path: module.path ?? null,
  element: module.element ?? null,
  sagas: module.sagas ?? [],
  reducer: module.reducer ?? null,
  children: module.children ?? {},
  inScope: module.inScope ?? [],
  useScope: module.useScope ?? [],
  icon: module.icon ?? null,
  api: module.api ?? {},
  lang: module.lang ?? null,
  deps: module.deps ?? null,
  permission: module.permission ?? null,
  // access: module.access ?? null,
  persist: module.persist ?? null,
  isDefault: module.isDefault ?? false,
  isIndex: module.isIndex ?? false,
  availableRoles: module.availableRoles,
  availableAccountRoles: module.availableAccountRoles,
});
