import { SendQuestionType } from "@/common/api/types/send-question";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSTextArea } from "@/common/ui-components/data-entry/input/text-area";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const SEND_QUESTION_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.Question,
      required: true,
    },
    inputProps: {
      size: "large",
    },
    Component: ILSTextArea,
  },
] as FormItemsFields;

export const SEND_QUESTION_FORM_TITLE = {
  [SendQuestionType.PresidentQuestion]: "Задать вопрос президенту",
  [SendQuestionType.SupportQuestion]: "Обращение в службу поддержки",
  [SendQuestionType.LegalConsultation]:
    "Обращение за юридической консультацией",
};

export const SEND_QUESTION_FORM_SUBTITLE = {
  [SendQuestionType.PresidentQuestion]: "Вы можете отправить вопрос президенту",
  [SendQuestionType.SupportQuestion]:
    "Вы можете отправить вопрос в службу поддержки",
  [SendQuestionType.LegalConsultation]:
    "Вы можете отправить Ваше обращение в службу поддержки",
};

export const SEND_QUESTION_FORM_HELP_TEXT =
  "Ответ придет на Вашу почту, указанную в личном кабинете";
