import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkUserActions } from "@/modules/auth/actions";

export const useCheckUser = (authenticated: boolean) => {
  const dispatch = useDispatch();

  const checkUserData = () => {
    dispatch(checkUserActions());
  };

  useEffect(() => {
    if (authenticated) {
      checkUserData();
    }
  }, [authenticated]);
};
