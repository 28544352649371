import { useAdminOrganizations } from "@/modules/admin-panel/children/admin-organizations/hooks/use-admin-organizations";
import { AdminOrganizationsToolbar } from "@/modules/admin-panel/children/admin-organizations/containers/toolbar";
import { AdminOrganizationsList } from "@/modules/admin-panel/children/admin-organizations/containers/list";
import { AdminOrganizationsFilters } from "@/modules/admin-panel/children/admin-organizations/components/forms/organizations-filters";

export const AdminOrganizationsMain = () => {
  const { organizations, loading, form, onValuesChange, resetFilters } =
    useAdminOrganizations();

  return (
    <div className="admin-organizations">
      <AdminOrganizationsToolbar
        form={form}
        onValuesChange={onValuesChange}
        resetFilters={resetFilters}
      />
      <div className="admin-organizations__content">
        <AdminOrganizationsFilters
          form={form}
          onValuesChange={onValuesChange}
          resetFilters={resetFilters}
        />
        <AdminOrganizationsList
          organizations={organizations}
          loading={loading}
        />
      </div>
    </div>
  );
};
