import { FC } from "react";
import { START_PAGE_CONTESTS } from "@/modules/start/constants/contests";
import { StartPageContestCard } from "@/modules/start/components/contests/card";

type IProps = {
  onParticipateClick: (contestID: number) => () => void;
};

export const StartPageContentsList: FC<IProps> = ({ onParticipateClick }) => {
  return (
    <ul className="start-page__contests-list">
      {START_PAGE_CONTESTS.map((contest) => (
        <StartPageContestCard
          {...contest}
          key={contest.ID}
          onParticipateClick={onParticipateClick}
        />
      ))}
    </ul>
  );
};
