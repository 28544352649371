import "./index.scss";
import { FC } from "react";
import { DriverAppliesToolbar } from "@/modules/social-services/children/vacancies/components/driver-applies/toolbar";
import { DriverAppliesList } from "@/modules/social-services/children/vacancies/components/driver-applies/list";
import { useDriverApplies } from "@/modules/social-services/children/vacancies/hooks/use-driver-applies";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { AbacPermission } from "@/common/types/core/abac";

type IProps = {
  vacanciesDriverTab: string;
};

export const DriverApplies: FC<IProps> = ({ vacanciesDriverTab }) => {
  const { appliesList, loading, onApplyCancel } =
    useDriverApplies(vacanciesDriverTab);

  return (
    <ILSAllowedTo perform={AbacPermission.driver}>
      <div className="driver-applies">
        <DriverAppliesToolbar />
        <DriverAppliesList
          appliesList={appliesList}
          loading={loading}
          onApplyCancel={onApplyCancel}
        />
      </div>
    </ILSAllowedTo>
  );
};
