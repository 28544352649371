export enum VacancyStatus {
  Draft = "Draft",
  InModeration = "InModeration",
  Rejected = "Rejected",
  Published = "Published",
}

export const VacancyStatusID = {
  [VacancyStatus.Draft]: 1,
  [VacancyStatus.InModeration]: 2,
  [VacancyStatus.Rejected]: 3,
  [VacancyStatus.Published]: 4,
};

export enum VacanciesTabStatus {
  All = "0",
  Draft = "1",
  InModeration = "2",
  Rejected = "3",
  Published = "4",
}
