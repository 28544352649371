import { FC } from "react";
import { UploadFile } from "antd";
import { EXCEL_PERMITTED_EXTENSIONS } from "@/common/constants/files";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSDivider } from "@/common/components/layout/divider";
import { ILSFileDraggerUpload } from "@/common/components/custom/file-dragger-upload/ils-file-dragger-upload";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

type IProps = {
  documents: UploadFile[] | undefined;
  disableUpload: boolean;
  handleCheckBeforeUpload: (file?: UploadFile) => void;
  handleDeleteDocument: (ID: string | number | undefined) => void;
};

export const MyInfoQuestionnaireResultForm: FC<IProps> = ({
  documents,
  disableUpload,
  handleCheckBeforeUpload,
  handleDeleteDocument,
}) => {
  return (
    <div className="my-info__group" style={{ alignItems: "center" }}>
      <ILSTitle level={3}>Заполненная анкета</ILSTitle>
      <ILSDivider className="my-info__group-divider" />
      <div>
        <p>
          В этом разделе Вы можете загрузить заполненную анкету и посмотреть
          ранее загруженные результаты
        </p>
        <ILSFileDraggerUpload
          documents={documents}
          fieldName={FormFieldName.Questionnaire}
          permittedExtensions={EXCEL_PERMITTED_EXTENSIONS}
          dropzoneTitle="Загрузить заполненную анкету"
          disableUpload={disableUpload}
          handleCheckBeforeUpload={handleCheckBeforeUpload}
          handleDeleteDocument={handleDeleteDocument}
          disableDelete={false}
          showDeleteButton
        />
      </div>
    </div>
  );
};
