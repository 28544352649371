import { createSelector } from "reselect";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { IndexedCollection } from "@/common/types/general/common";
import { AdminOrganizationsModuleStore } from "@/modules/admin-panel/children/admin-organizations/types/store";
import { OrganizationInfo } from "@/common/types/models/account";
import { getObjectValuesWithType } from "@/common/utils/helpers/types";

export const adminOrganizationsSelector = createSelector(
  (state: AdminOrganizationsModuleStore) => {
    return state.AdminOrganizationsModule?.organizations;
  },
  (organizations: IndexedCollection<OrganizationInfo>) =>
    getObjectValuesWithType<OrganizationInfo>(organizations) ||
    (EMPTY_ARRAY_DATA as OrganizationInfo[])
);

export const adminOrganizationSelector = (id: number | undefined) =>
  createSelector(
    (state: AdminOrganizationsModuleStore) => {
      return state.AdminOrganizationsModule?.organizations;
    },
    (organizations: IndexedCollection<OrganizationInfo>) => {
      if (id) {
        return organizations[id];
      }
      return null;
    }
  );

export const adminOrganizationsFetchingSelector = createSelector(
  (state: AdminOrganizationsModuleStore) => {
    return state.AdminOrganizationsModule?.isFetching;
  },
  (isFetching: boolean) => isFetching
);
