import {
  RatingBlocks,
  RatingContentType,
  ApplicationModerationStatus,
} from "@/common/types/models/rating";

export const DEFAULT_ZERO_RATING = 0;

/** Заголовок блока рейтинга */
export const RATING_TITLES = {
  [RatingContentType.EducationAndEmploymentModule]:
    "Блок образования и трудоустройства",
  [RatingContentType.LabourSafety]: "Блок охраны труда",
  [RatingContentType.Social]: "Блок социального обеспечения",
  [RatingContentType.Religion]: "Блок содействия религии",
  [RatingContentType.RatingTotal]: "Итоговый социальный рейтинг компании",
  [RatingContentType.HealthyLifeStyle]: "Блок содействия ЗОЖ",
  [RatingContentType.Patriotism]: "Блок патриотического воспитания",
  [RatingContentType.Entertainment]: "Блок досуга и просвещения",
  [RatingContentType.CorporateCulture]: "Блок корпоративной культуры",
};

/** Путь в адресной строке */
export const RATING_PATHS = {
  [RatingContentType.EducationAndEmploymentModule]: "education-and-employment",
  [RatingContentType.LabourSafety]: "labour-safety",
  [RatingContentType.Social]: "social-security",
  [RatingContentType.Religion]: "religion",
  [RatingContentType.RatingTotal]: "rating-total",
  [RatingContentType.HealthyLifeStyle]: "healthy-lifestyle",
  [RatingContentType.Patriotism]: "patriotism",
  [RatingContentType.Entertainment]: "entertainment",
  [RatingContentType.CorporateCulture]: "corporate-culture",
};

/** Статус проверки результатов анкеты */
export const APPLICATION_MODERATION_STATUS_TITLES = {
  [ApplicationModerationStatus.Confirm]: "Подтверждено",
  [ApplicationModerationStatus.Refuse]: "Отклонено",
  [ApplicationModerationStatus.Sent]: "Отправлено",
};

export const NOT_SENT = "Не отправлено";

export const MY_RATING_MOCKS: RatingBlocks = {
  [RatingContentType.EducationAndEmploymentModule]: {
    value: 0,
    status: null,
  },
  [RatingContentType.LabourSafety]: {
    value: 0,
    status: null,
  },
  [RatingContentType.Social]: {
    value: 0,
    status: null,
  },
  [RatingContentType.CorporateCulture]: {
    value: 0,
    status: null,
  },
  [RatingContentType.Entertainment]: {
    value: 0,
    status: null,
  },
  [RatingContentType.HealthyLifeStyle]: {
    value: 0,
    status: null,
  },
  [RatingContentType.Patriotism]: {
    value: 0,
    status: null,
  },
  [RatingContentType.Religion]: {
    value: 0,
    status: null,
  },
};

export const SOCIAL_RATING_TITLE = "Социальный рейтинг";
