import "../index.scss";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { ModulePath } from "@/common/types/core/routes";
import { AdminOrganizationsMain } from "@/modules/admin-panel/children/admin-organizations/containers/main";
import { useShowIndexModule } from "@/common/hooks/use-show-index-module";

interface IProps {}

export const AdminOrganizations: FC<IProps> = () => {
  const { showIndexModule } = useShowIndexModule(ModulePath.adminOrganization);

  return showIndexModule ? <AdminOrganizationsMain /> : <Outlet />;
};
