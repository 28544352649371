import { AccountRole } from "@/common/types/models/account";
import {
  AvailableRegisterRole,
  REGISTER_DRIVER_FIELDS,
  REGISTER_FIELDS_EMPLOYEE,
  REGISTER_FIELDS_ORGANIZATION,
} from "@/modules/auth/children/register/constants/form-fields";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const getRegisterFields = ({
  checkedAccountRole,
  showEmployeeFields,
}: {
  checkedAccountRole: AvailableRegisterRole;
  showEmployeeFields: boolean;
}): FormItemsFields => {
  const isOrganization = checkedAccountRole === AccountRole.organization;

  if (isOrganization) {
    return showEmployeeFields
      ? REGISTER_FIELDS_EMPLOYEE
      : REGISTER_FIELDS_ORGANIZATION;
  }

  return REGISTER_DRIVER_FIELDS;
};
