import "./driver-apply-alert-modal.scss";
import { FC } from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  DRIVER_INFO_ALERT,
  PROFILE_INCOMPLETE,
} from "@/modules/social-services/children/my-info/constants";
import { PHONE_IS_NOT_VERIFIED } from "@/common/features/drivers-common/constants";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSDivider } from "@/common/components/layout/divider";
import { ModulePath } from "@/common/types/core/routes";
import { useDriverIncompleteProfile } from "@/common/features/drivers-common/hooks/use-driver-incomplete-profile";

type IProps = {
  isModalOpen?: boolean;
  showModal: VoidFunction;
};

export const DriverAlertApplyModal: FC<IProps> = ({
  isModalOpen,
  showModal,
}) => {
  const {
    driverRequiredFieldsMessages,
    phoneIsVerified,
    hasBlankRequiredFields,
  } = useDriverIncompleteProfile();

  return (
    <Modal
      open={isModalOpen}
      onOk={showModal}
      onCancel={showModal}
      title={DRIVER_INFO_ALERT.title}
      footer={
        <ILSLink
          to={`/${ModulePath.socialServices}/${ModulePath.myInfo}`}
          relative="path"
        >
          <ILSButton>Перейти в личный кабинет</ILSButton>
        </ILSLink>
      }
      centered
    >
      <div className="driver-apply-alert-modal">
        <p className="driver-apply-alert-modal__text">
          {DRIVER_INFO_ALERT.text}
        </p>
        <ILSDivider className="driver-apply-alert-modal__divider" />
        {!phoneIsVerified && (
          <div className="driver-apply-alert-modal__group driver-apply-alert-modal__group_phone">
            <ExclamationCircleOutlined />
            <p className="driver-apply-alert-modal__subtitle">
              {PHONE_IS_NOT_VERIFIED}
            </p>
          </div>
        )}
        {hasBlankRequiredFields && (
          <div className="driver-apply-alert-modal__group">
            <ExclamationCircleOutlined />
            <div>
              <p className="driver-apply-alert-modal__subtitle">
                {PROFILE_INCOMPLETE}
              </p>
              <ul className="driver-apply-alert-modal__list">
                {driverRequiredFieldsMessages?.map((message) => (
                  <li key={message}>
                    <p>{message}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
