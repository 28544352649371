import { Form, FormInstance } from "antd/lib";
import { VACANCIES_STATUS_OPTIONS } from "@/common/constants/statuses/vacancy-statuses";
import { VACANCIES_TOOLBAR_INITIAL_VALUES } from "@/common/features/vacancies-common/constants/filters";
import { ILSSelect } from "@/common/ui-components/data-entry/select";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { VacanciesToolbarFilterValues } from "@/common/features/vacancies-common/types/filters";

interface IProps {
  toolbarForm: FormInstance<VacanciesToolbarFilterValues>;
  onToolbarFormChange: (
    changedValues: VacanciesToolbarFilterValues,
    values: VacanciesToolbarFilterValues
  ) => void;
}

export const AdminVacanciesToolbarForm = ({
  toolbarForm,
  onToolbarFormChange,
}: IProps) => {
  return (
    <Form
      form={toolbarForm}
      layout="horizontal"
      onValuesChange={onToolbarFormChange}
      initialValues={VACANCIES_TOOLBAR_INITIAL_VALUES}
    >
      <ILSFormItem
        name={FormFieldName.VacanciesStatus}
        className="admin-vacancies__toolbar-filter"
      >
        <ILSSelect options={VACANCIES_STATUS_OPTIONS} />
      </ILSFormItem>
    </Form>
  );
};
