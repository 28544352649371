import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { VACANCIES_INITIAL_STATE } from "@/common/features/vacancies-common/store";
import { vacanciesChangeFiltersRoutine } from "@/common/features/vacancies-common/actions";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";

export const vacanciesFiltersReducer = createReducer(VACANCIES_INITIAL_STATE, {
  [vacanciesChangeFiltersRoutine.REQUEST]: onRequest,
  [vacanciesChangeFiltersRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      filters: VacanciesFilters | undefined;
    }>
  ) => {
    const { filters } = action.payload;
    state.filters = filters;
    return state;
  },
  [vacanciesChangeFiltersRoutine.FAILURE]: onFailure,
  [vacanciesChangeFiltersRoutine.FULFILL]: onFulfill,
});
