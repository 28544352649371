import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { AccountRole } from "@/common/types/models/account";

export const REGISTER_INITIAL_VALUES: Partial<Record<FormFieldName, unknown>> =
  {
    [FormFieldName.AccountRole]: AccountRole.driver,
  };

export const REGISTER_CONTENT = "Регистрация";
export const REGISTER_FORM_NAME = "auth-form_register";
