import { FC, useEffect } from "react";
import { CheckboxGroupProps } from "antd/es/checkbox";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { ILSCheckboxGroup } from "@/common/ui-components/data-entry/checkbox-group";
import { Dictionaries } from "@/common/types/models/dictionary";
import { useGetDictionary } from "@/common/features/dictionaries/hooks/use-get-dictionary";
import { SelectApiOption } from "@/common/types/general/common";

export interface ILSCheckboxGroupApiProps extends CheckboxGroupProps {
  dictionary?: Dictionaries;
  options?: Array<SelectApiOption>;
}

export const ILSCheckboxGroupApi: FC<ILSCheckboxGroupApiProps> = ({
  dictionary,
  options = EMPTY_ARRAY_DATA,
  ...props
}) => {
  const { handleGetDictionary } = useGetDictionary();

  useEffect(() => {
    handleGetDictionary({ dictionary });
  }, []);

  return <ILSCheckboxGroup options={options} {...props} />;
};
