import { adminArticlesRootReducer } from "@modules/admin-panel/children/admin-articles/reducers";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { AdminArticles } from "./containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import * as adminArticlesSagas from "./sagas";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

const module: IModuleModel = {
  title: ModuleTitle.adminArticles,
  name: ModuleName.adminArticles,
  path: ModulePath.adminArticles,
  element: <AdminArticles />,
  sagas: getAllModuleSagas(adminArticlesSagas),
  reducer: adminArticlesRootReducer,
  persist: false,
};

export const AdminArticlesModule = defineModule(module);
