import { recoveryProcessActions } from "@modules/auth/actions";
import { createReducer } from "@reduxjs/toolkit";
import { AUTH_INITIAL_STATE } from "@modules/auth/store";
import { RecoveryProcessAction } from "@modules/auth/sagas/recovery-process";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";

export const recoveryProcessReducer = createReducer(AUTH_INITIAL_STATE, {
  [recoveryProcessActions.FAILURE]: onFailure,
  [recoveryProcessActions.FULFILL]: onFulfill,
  [recoveryProcessActions.SUCCESS]: (state, action: RecoveryProcessAction) => {
    const { recoveryProcess } = action.payload;
    state.recoveryProcess = recoveryProcess;
    return state;
  },
});
