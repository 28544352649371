import { useParams } from "react-router-dom";
import { MY_RATING_MOCKS } from "@/common/constants/config/rating";
import { getRatingBlockContentType } from "@/modules/social-services/children/my-info/helpers/utils/get-rating-block-content-type";
import { isRatingWithForm } from "@/common/types/models/rating";
import { useOrganizationAccount } from "@/common/hooks/use-organization-account";

export const useMyRatingApplicationModule = () => {
  const params = useParams();
  const ratingType = getRatingBlockContentType(
    params?.["my-rating-application"]
  );

  const { organizationSocialRating } = useOrganizationAccount({});

  const ratingValue =
    ratingType && isRatingWithForm(ratingType)
      ? MY_RATING_MOCKS[ratingType] || null
      : null;

  const ratingTotalValue = organizationSocialRating;
  const ratingValues = MY_RATING_MOCKS;

  return {
    ratingType,
    ratingValue,
    ratingValues,
    ratingTotalValue,
  };
};
