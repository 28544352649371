import { useEffect, useRef } from "react";
import { isEmpty, isNil, omitBy } from "lodash";
import { useSelector } from "react-redux";
import { ProFormInstance } from "@ant-design/pro-components";
import { useFormSteps } from "@/common/hooks/use-form-steps";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { VacanciesRequestValues } from "@/common/features/vacancies-common/types/api";
import {
  VacancyFormType,
  VacancyFormValues,
} from "@/common/features/vacancies-common/types/form";
import { vacancyOptionsSelector } from "@/common/features/dictionaries/selectors";
import { formValuesToVacancy } from "@/common/features/vacancies-common/helpers/form-values-to-vacancy";
import { useVacancyOperation } from "@/common/features/vacancies-common/hooks/use-vacancy-operation";
import { useVacancyInitialValues } from "@/common/features/vacancies-common/hooks/use-vacancy-initial-values";
import { useRemoveFile } from "@/common/hooks/use-remove-file";
import { filesToBase64 } from "@/common/utils/helpers/components/upload/get-files-to-base-64";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";

type IProps = {
  type: VacancyFormType;
  vacancyId?: number;
  showModal: VoidFunction;
};

export const useVacancyForm = ({ type, vacancyId, showModal }: IProps) => {
  const formRef = useRef<ProFormInstance<VacancyFormValues>>();
  const isEditForm = type === VacancyFormType.Edit;
  const { onVacancyOperation } = useVacancyOperation();
  const { refData } = useSelector(vacancyOptionsSelector);
  const { removedSavedFiles, onRemoveFile } = useRemoveFile();

  const handleSubmit = async (formValues: Record<string, any>) => {
    const filesPromises = filesToBase64(formValues.VehiclePhotos);

    try {
      Promise.all(filesPromises).then((files: string[]) => {
        const filesWithDeleted = [...files, ...removedSavedFiles];

        const vacancyData = formValuesToVacancy(
          formValues as VacancyFormValues,
          filesWithDeleted
        );

        const notEmptyFields = omitBy(vacancyData, isNil);

        onVacancyOperation({
          operation: !isEditForm
            ? OperationsEndpoint.Create
            : OperationsEndpoint.Update,
          body: notEmptyFields as VacanciesRequestValues,
          id: vacancyId,
          onFinally: showModal,
        });
      });
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  const { initialValues } = useVacancyInitialValues({ vacancyId });

  const { currentStep, onStepChange } = useFormSteps();

  useEffect(() => {
    if (isEditForm && !isEmpty(initialValues)) {
      formRef?.current?.setFieldsValue(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return { formRef, refData, handleSubmit, onStepChange, onRemoveFile };
};
