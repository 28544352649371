import { UploadFile } from "antd/lib";
import { notification } from "antd";
import { convertToMB } from "@/common/utils/helpers/files/convert";

export const handleCheckSize = (
  file: File | UploadFile,
  fileMaxSize?: number
) => {
  if (file && fileMaxSize && (file?.size || 0) > fileMaxSize) {
    notification.error({
      message: `Максимальный размер файла ${convertToMB(fileMaxSize)}МБ`,
    });
    return false;
  }
  if (!file.size) {
    notification.error({ message: "Нельзя загружать пустые файлы" });
    return false;
  }
  return true;
};

export const handleCheckBeforeCrop = (fileMaxSize?: number) => (file: File) => {
  return handleCheckSize(file, fileMaxSize);
};
