import { CONTACTS_NOT_SET } from "@/common/constants/general";
import { getFormattedPhone } from "@/common/utils/helpers/string/get-formatted-phone";

export const renderPhoneButtonContent = (
  phone: string | undefined | null,
  authenticated: boolean
) => {
  if (!phone) {
    return CONTACTS_NOT_SET;
  }

  if (!authenticated) {
    return (
      <>
        <p>Показать телефон</p>
        <p>7 XXX XXX-XX-XX</p>
      </>
    );
  }

  return getFormattedPhone(phone);
};
