import { useState } from "react";
import { useSelector } from "react-redux";
import { adminOrganizationUserSelector } from "@/modules/admin-panel/children/admin-organizations/selectors/users";

export const useAdminSelectUser = () => {
  const [selectedUserID, setSelectedUserID] = useState<number | null>(null);
  const handleSelectUser = (userId: number | null) => setSelectedUserID(userId);
  const selectedUser = useSelector(
    adminOrganizationUserSelector(selectedUserID)
  );

  return { selectedUser, handleSelectUser };
};
