import { useDispatch } from "react-redux";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { QuestionnaireProps } from "@/common/features/questionnaire-common/types/store";
import { questionnaireActionRoutine } from "@/common/features/questionnaire-common/actions";
import {
  QuestionnaireOperation,
  SaveQuestionnaireType,
} from "@/common/api/types/questionnaire";

type IProps = QuestionnaireProps & {
  onSuccess?: VoidFunction;
  onFinally?: VoidFunction;
};

export const useQuestionnaireOperations = () => {
  const dispatch = useDispatch();

  const onQuestionnaireOperation = ({
    body,
    operation,
    successMessage,
    onSuccess,
    onFinally,
  }: IProps) => {
    try {
      dispatch(
        questionnaireActionRoutine({
          operation,
          body,
          successMessage,
        })
      );
      onSuccess?.();
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      onFinally?.();
    }
  };

  /**
   * Получить шаблон анкеты
   */
  const handleGetQuestionnaire = () => {
    return onQuestionnaireOperation({ operation: QuestionnaireOperation.get });
  };

  /**
   * Сохранить шаблон анкеты
   */
  const handleSaveQuestionnaire = (body: SaveQuestionnaireType | undefined) => {
    return onQuestionnaireOperation({
      operation: QuestionnaireOperation.save,
      body,
      successMessage: "Шаблон успешно добавлен",
    });
  };

  /**
   * Удалить шаблон анкеты
   */
  const handleDeleteQuestionnaire = () => {
    return onQuestionnaireOperation({
      operation: QuestionnaireOperation.delete,
    });
  };

  /**
   * Сделать анкету доступной или недоступной для скачивания
   */
  const handleChangeQuestionnaireStatus = (newAvailablilityStatus: boolean) => {
    const operation = newAvailablilityStatus
      ? QuestionnaireOperation.makeAvailable
      : QuestionnaireOperation.makeUnavailable;
    return onQuestionnaireOperation({
      operation,
    });
  };

  return {
    handleGetQuestionnaire,
    handleSaveQuestionnaire,
    handleDeleteQuestionnaire,
    handleChangeQuestionnaireStatus,
  };
};
