import { createRoutine } from "redux-saga-routines";

export enum AuthAction {
  login = "AUTH/LOGIN",
  logout = "AUTH/LOGOUT",
  register = "AUTH/REGISTER",
  recoveryProcess = "AUTH/RECOVERY_PROCESS",
  recovery = "AUTH/RECOVERY",
  authUserSet = "AUTH/USER/SET",
  checkUser = "AUTH/USER/CHECK",
  changePassword = "AUTH/CHANGE_PASSWORD",
  resetPassword = "AUTH/RESET_PASSWORD",
  checkPasswordRecoveryCode = "AUTH/CHECK_PASSWORD_RECOVERY_CODE",
  setUserOptions = "AUTH/SET_USER_OPTIONS",
}

export const loginActions = createRoutine(AuthAction.login);
export const logoutActions = createRoutine(AuthAction.logout);
export const registerActions = createRoutine(AuthAction.register);

export const setUserOptionsActions = createRoutine(AuthAction.setUserOptions);

export const recoveryProcessActions = createRoutine(AuthAction.recoveryProcess);
export const recoveryActions = createRoutine(AuthAction.recovery);
export const resetPasswordActions = createRoutine(AuthAction.resetPassword);
export const checkPasswordRecoveryCodeActions = createRoutine(
  AuthAction.checkPasswordRecoveryCode
);
export const changePasswordActions = createRoutine(AuthAction.changePassword);

export const authUserSetActions = createRoutine(AuthAction.authUserSet);
export const checkUserActions = createRoutine(AuthAction.checkUser);

export const authActionsForNotifications = [authUserSetActions];
