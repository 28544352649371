import "./start-page-header.scss";
import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";

type IProps = PropsWithChildren & {
  className?: string;
};

export const StartPageHeader: FC<IProps> = ({ children, className }) => {
  return (
    <ILSTitle level={2} className={classNames("start-page-header", className)}>
      {children}
    </ILSTitle>
  );
};
