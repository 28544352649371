import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  questionnaireFetchingSelector,
  questionnaireSelector,
} from "@/common/features/questionnaire-common/selectors";
import { useQuestionnaireOperations } from "@/common/features/questionnaire-common/hooks/use-questionnaire-operations";

export const useMyInfoQuestionnaire = () => {
  const questionnaire = useSelector(questionnaireSelector);
  const questionnaireIsFetching = useSelector(questionnaireFetchingSelector);

  const template = questionnaire;

  const { handleGetQuestionnaire } = useQuestionnaireOperations();

  useEffect(() => {
    if (!questionnaire) {
      handleGetQuestionnaire();
    }
  }, []);

  return { template, questionnaireIsFetching };
};
