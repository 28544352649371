import "./index.scss";
import { ILSHeaderLogo } from "@/common/components/layout/header/logo/header-logo";
import { ILSHeaderBurger } from "@/common/components/layout/header/menu/header-burger";
import { ILSAvailableServices } from "@/common/components/layout/header/services/avaliable-services";
import { ILSHeaderActions } from "@/common/components/layout/header/actions/header-actions";
import { ILSHeader } from "@/common/components/layout";
import { HeaderAccount } from "@/common/components/layout/header/account";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { ILSAuth } from "@/common/components/layout/header/auth/auth";

export const ILSHeaderComponent = () => {
  return (
    <ILSHeader>
      <div className="header-component">
        <div className="header-component__left">
          {!navigator.userAgent.includes("driverRFflutterApp") && (
            <ILSAllowedTo perform={AbacPermission.exceptAdmin}>
              <ILSHeaderBurger />
            </ILSAllowedTo>
          )}
          <ILSHeaderLogo />
        </div>
        <HeaderAccount />
        <div className="header-component__right">
          <ILSAvailableServices />
          <ILSAllowedTo perform={AbacPermission.auth}>
            <ILSHeaderActions />
          </ILSAllowedTo>
        </div>
      </div>
      <ILSAllowedTo perform={AbacPermission.nonAuth}>
        <ILSAuth />
      </ILSAllowedTo>
    </ILSHeader>
  );
};
