import { COLOR_PALETTE } from "@/styles/color-palette";

export const APP_THEME = {
  MAIN: {
    token: {
      colorPrimary: COLOR_PALETTE.primary,
      fontFamily: "Bitter,sans-serif",
    },
    components: {
      Typography: {
        titleMarginBottom: 0,
        titleMarginTop: 0,
      },
      Button: {
        defaultBg: COLOR_PALETTE.whitePrimary,
        primaryColor: COLOR_PALETTE.whitePrimary,
        colorBgContainer: COLOR_PALETTE.primary,
        defaultHoverColor: COLOR_PALETTE.whitePrimary,
        defaultHoverBorderColor: COLOR_PALETTE.whitePrimary,
        borderRadius: 8,
        paddingBlockLG: 16,
        paddingInlineLG: 10,
        controlHeightLG: 52,
        contentFontSizeLG: 18,
        contentLineHeightLG: 1,
      },
      Input: {
        colorBgContainer: COLOR_PALETTE.whitePrimary,
        activeBg: COLOR_PALETTE.whitePrimary,
        hoverBg: COLOR_PALETTE.whitePrimary,
        controlHeightLG: 18,
        inputFontSizeLG: 18,
        paddingBlockLG: 16,
        paddingInlineLG: 5,
        borderRadiusLG: 8,
      },
      InputNumber: {
        colorBgContainer: COLOR_PALETTE.whitePrimary,
        activeBg: COLOR_PALETTE.whitePrimary,
        hoverBg: COLOR_PALETTE.whitePrimary,
        controlHeightLG: 18,
        inputFontSizeLG: 18,
        paddingBlockLG: 16,
        paddingInlineLG: 5,
        borderRadiusLG: 8,
      },
      Select: {
        colorBgContainer: COLOR_PALETTE.whitePrimary,
        activeBg: COLOR_PALETTE.whitePrimary,
        hoverBg: COLOR_PALETTE.whitePrimary,
        multipleItemHeightLG: 52,
        singleItemHeightLG: 52,
        controlHeightLG: 18,
        inputFontSizeLG: 18,
        paddingBlockLG: 16,
        paddingInlineLG: 5,
        borderRadiusLG: 8,
      },
      DatePicker: {
        colorBgContainer: COLOR_PALETTE.whitePrimary,
        activeBg: COLOR_PALETTE.whitePrimary,
      },
      Menu: {
        // ----> default (light)
        iconSize: 30,
        itemPaddingInline: 16,
        itemBorderRadius: 8,
        colorPrimary: COLOR_PALETTE.whitePrimary,
        itemBg: COLOR_PALETTE.primary,
        itemColor: COLOR_PALETTE.whitePrimary,
        // ----> horizontal
        horizontalItemBorderRadius: 8,
        horizontalItemSelectedBg: COLOR_PALETTE.whitePrimary,
        horizontalItemSelectedColor: COLOR_PALETTE.primary,
        // ----> selected
        itemSelectedBg: COLOR_PALETTE.whitePrimary,
        itemSelectedColor: COLOR_PALETTE.primary,
        // ----> popup
        popupBg: COLOR_PALETTE.primary,
        // ----> hovered
        horizontalItemHoverBg: COLOR_PALETTE.whitePrimary,
      },
      Layout: {
        footerBg: COLOR_PALETTE.whitePrimary,
        footerPadding: "0 0",
        headerHeight: 81,
        headerColor: COLOR_PALETTE.whitePrimary,
      },
      Carousel: {
        dotHeight: 8,
      },
    },
  },
};
