import { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { User } from "@/common/types/models/user/user";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { UpdateRequestBody } from "@/common/api/types/api";
import { AdminUserFormValues } from "@/modules/admin-panel/children/admin-organizations/children/organization/types/user-form";
import { useAdminUsersOperations } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/use-admin-users-operations";
import { useFormAvatar } from "@/common/hooks/use-form-avatar";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { getPayloadFromFormValues } from "@/common/ui-components/data-entry/form/helpers/get-payload-from-form-values";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";
import { getFormImage } from "@/common/helpers/get-account-avatar";
import { accountToFormValues } from "@/modules/social-services/children/my-info/helpers/decorators/account-to-form-values";

type IProps = {
  user: User | null;
  handleResetActionAndUser: VoidFunction;
};

export const useAdminUserForm = ({
  user,
  handleResetActionAndUser,
}: IProps) => {
  const [form] = useForm<AdminUserFormValues>();
  const { handleUpdateUser } = useAdminUsersOperations();

  const initialValues = accountToFormValues({
    user,
  });

  const {
    fileList,
    removedSavedFiles,
    handleAvatarChange,
    handleAvatarRemove,
    handleAvatarReset,
  } = useFormAvatar({
    avatar: initialValues?.Avatar,
  });

  const handleSubmit = async (formValues: AdminUserFormValues) => {
    try {
      const Avatar = await getFormImage(fileList, removedSavedFiles);

      const payload =
        getPayloadFromFormValues<UpdateRequestBody<Omit<User, "Avatar">>>(
          formValues
        );

      handleUpdateUser({
        body: {
          ...payload,
          Phone: getUnmaskedPhone(payload?.Phone),
          Avatar,
        },
        userID: user?.ID,
      });
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      handleResetActionAndUser();
    }
  };

  const handleSetInitialValues = () => {
    form.resetFields();
    form.setFieldsValue(initialValues || EMPTY_OBJECT_DATA);
    handleAvatarReset();
  };

  const handleCancel = () => handleResetActionAndUser();

  useEffect(() => {
    handleSetInitialValues();
  }, [user]);

  return {
    form,
    fileList,
    handleSubmit,
    handleCancel,
    handleAvatarChange,
    handleAvatarRemove,
  };
};
