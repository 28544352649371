import { useEffect } from "react";
import { useSelector } from "react-redux";
import { adminOrganizationUsersSelector } from "@/modules/admin-panel/children/admin-organizations/selectors/users";
import { adminOrganizationsFetchingSelector } from "@/modules/admin-panel/children/admin-organizations/selectors/organizations";
import { useAdminUsersOperations } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/use-admin-users-operations";
import { UsersSourceType } from "@/common/api/types/user";

export const useAdminGetUsers = (organizationID: number | undefined) => {
  const { handleGetUsers } = useAdminUsersOperations();
  const users = useSelector(adminOrganizationUsersSelector);
  const isFetching = useSelector(adminOrganizationsFetchingSelector);

  useEffect(() => {
    handleGetUsers({
      OrganizationID: organizationID,
      Source: UsersSourceType.Organization,
    });
  }, []);

  return { users, isFetching };
};
