import { FC } from "react";
import { isEmpty } from "lodash";
import { Empty } from "antd";
import { ILSSpin } from "@/common/components/data-display/spin";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { useVacancyApplies } from "@/modules/social-services/children/vacancies/hooks/use-vacancy-applies";
import { VacancyOrganizationApply } from "@/modules/social-services/children/vacancies/children/components/organization-apply";

type IProps = {
  vacancy?: Vacancy;
};

export const VacancyOrganizationApplies: FC<IProps> = ({ vacancy }) => {
  const { appliesList, loading, onApplyConfirm, onApplyRefuse } =
    useVacancyApplies({
      vacancyId: vacancy?.ID,
    });

  return (
    <section className="vacancy__section">
      <ILSSpin spinning={loading} tip="Загрузка" size="large">
        <div className="vacancy__section-content vacancy__applies">
          {isEmpty(appliesList) ? (
            <Empty description="Нет откликов" />
          ) : (
            appliesList.map((apply) => (
              <VacancyOrganizationApply
                apply={apply}
                key={apply?.ID}
                onApplyConfirm={onApplyConfirm}
                onApplyRefuse={onApplyRefuse}
              />
            ))
          )}
        </div>
      </ILSSpin>
    </section>
  );
};
