import { ReactNode } from "react";
import { FormFieldName } from "./field-name";
import { EMPTY_STRING } from "@/common/constants/general";

export const FORM_LABEL: Record<FormFieldName, string | ReactNode> = {
  [FormFieldName.UserID]: "Пользователь",
  [FormFieldName.Code]: "Проверочный код",
  [FormFieldName.Login]: "Логин",
  [FormFieldName.Password]: "Пароль",
  [FormFieldName.OldPassword]: "Старый пароль",
  [FormFieldName.NewPassword]: "Новый пароль",
  [FormFieldName.ConfirmPassword]: "Подтвердите пароль",
  [FormFieldName.Default]: "",
  [FormFieldName.AccountName]: "Название организации",
  [FormFieldName.AccountCode]: "Код организации в системе",
  [FormFieldName.AccountRole]: "Роль организации",
  [FormFieldName.INN]: "ИНН",
  [FormFieldName.FullName]: "Фамилия Имя Отчество",
  [FormFieldName.Name]: "Имя",
  [FormFieldName.Surname]: "Фамилия",
  [FormFieldName.Phone]: "Телефон",
  [FormFieldName.Email]: "Электронная почта",
  [FormFieldName.UserRole]: "Роль",
  [FormFieldName.ConsentToDataProcessing]: "Согласен на",
  [FormFieldName.Rating]: "Рейтинг",
  [FormFieldName.SocialRating]: "Социальный рейтинг",
  [FormFieldName.IsEmployee]:
    "Являюсь сотрудником зарегистрированной организации",

  /** Водитель */
  [FormFieldName.DriverLicense]: "Номер водительского удостоверения",
  [FormFieldName.HaveDriverLicense]: "Есть водительское удостоверение",
  [FormFieldName.Avatar]: "",
  [FormFieldName.Patronymic]: "Отчество",
  [FormFieldName.Birthday]: "Дата рождения",
  [FormFieldName.Sex]: "Пол",
  [FormFieldName.Citizens]: "Гражданство",
  [FormFieldName.EmploymentType]: "Тип занятости",
  [FormFieldName.EducationLevel]: "Образование",
  [FormFieldName.OwnVehicleType]: "Свой транспорт",
  [FormFieldName.MedicalCertificate]: "Мед. книжка",
  [FormFieldName.DriverLicenseDate]: "Дата выдачи водительского удостоверения",
  [FormFieldName.VehicleType]: "Тип ТС",
  [FormFieldName.ExperienceYears]: "Кол-во лет",
  [FormFieldName.Organization]: "Организация",
  [FormFieldName.LicenseCategory]: "Категории прав",
  [FormFieldName.LicenseCategories]: "Категории прав",
  [FormFieldName.JobSearchStatus]: "Статус поиска работы",
  [FormFieldName.ReadyToStartTomorrow]: "Готов выйти завтра",
  [FormFieldName.DriverLicenseMoreThanThree]: "Водительский стаж",
  [FormFieldName.Age]: "Возраст",
  [FormFieldName.ActivateAgeField]: "Указать возраст",
  [FormFieldName.ShowAvatar]: "Показывать фотографию в вакансиях",
  [FormFieldName.PublicOrganizationMembership]:
    'Участник общественной организации "Водители России"',
  [FormFieldName.MembershipChecked]: "Результат проверки:",
  [FormFieldName.ReadyForTrips]: "Готов к командировкам",
  [FormFieldName.ReadyForRelocation]: "Готов к переезду",
  [FormFieldName.JobSearchRegion]: "Города поиска работы",
  [FormFieldName.WaitingForMembershipCheck]:
    'Ожидает проверку статуса "Участник общественной организации "Водители России""',

  /** Организация */
  [FormFieldName.OrganizationName]: "Название организации",
  [FormFieldName.OrganizationTypeID]: "Организационно-правовая форма",
  [FormFieldName.KPP]: "КПП",
  [FormFieldName.OGRN]: "ОГРН",
  [FormFieldName.OKPO]: "ОКПО",
  [FormFieldName.LegalAddress]: "Юридический адрес",
  [FormFieldName.ActualAddress]: "Почтовый адрес",
  [FormFieldName.PublicOrganizationPartnership]:
    'Партнер общественной организации "Водители России"',
  [FormFieldName.PartnershipChecked]: "Результат проверки",
  [FormFieldName.WaitingForPartnershipCheck]:
    'Ожидают проверку статуса "Партнер общественной организации "Водители России""',

  /** Аккаунт */
  [FormFieldName.WorkingPosition]: "Должность",

  /** Вакансия */
  // todo Title Description может быть не только у вакансий, дать общее наименование с возможностью расширения
  [FormFieldName.Title]: "Название объявления",
  [FormFieldName.Description]: "Описание",
  [FormFieldName.Profession]: "Профессия",
  [FormFieldName.DrivingExperience]: "Опыт работы",
  [FormFieldName.Years]: "Лет",
  [FormFieldName.Months]: "Месяцев",
  [FormFieldName.CompanyHasVehicle]: "Компания предоставляет автомобиль",
  [FormFieldName.Brand]: "Марка",
  [FormFieldName.ProductionYear]: "Год выпуска",
  [FormFieldName.AllowedMaxMass]: "Разрешенная максимальная масса",
  [FormFieldName.LoadCapacity]: "Грузоподъемность",
  [FormFieldName.WheelArrangement]: "Колесная формула",
  [FormFieldName.EngineType]: "Тип двигателя",
  [FormFieldName.EngineCapacity]: "Мощность",
  [FormFieldName.EngineVolume]: "Объем двигателя",
  [FormFieldName.EcologyClass]: "Экологический класс",
  [FormFieldName.Gearbox]: "Коробка передач",
  [FormFieldName.CabinType]: "Тип кабины",
  [FormFieldName.VehicleCondition]: "Состояние ТС",
  [FormFieldName.VehiclePhotos]: "Фотографии",
  [FormFieldName.Video]: "Видео",
  [FormFieldName.WorkSchedule]: "График работы",
  [FormFieldName.RegistrationMethod]: "Способ оформления",
  [FormFieldName.PaymentTerms]: "Условия оплаты",
  [FormFieldName.PaymentFrequency]: "Частота выплат",
  [FormFieldName.Extra]: "Что получают работники",
  [FormFieldName.Address]: "Адрес офиса",
  [FormFieldName.VacancyPhone]: "Телефон",
  [FormFieldName.Salary]: "Зарплата, руб.",
  [FormFieldName.DesiredSalary]: "Желаемая зарплата, руб.",
  [FormFieldName.From]: EMPTY_STRING,
  [FormFieldName.To]: EMPTY_STRING,
  [FormFieldName.VacanciesStatus]: EMPTY_STRING,
  [FormFieldName.VacanciesSource]: EMPTY_STRING,
  [FormFieldName.OrganizationIsVerified]: "Компания проверена",
  [FormFieldName.JobRegion]: "Города",

  /** Статьи */
  [FormFieldName.Text]: "",
  [FormFieldName.CategoryID]: "Категория",
  [FormFieldName.Image]: "",
  [FormFieldName.Tags]: "",
  [FormFieldName.Comment]: EMPTY_STRING,

  /** Анкеты */
  [FormFieldName.ApplicationStatus]: "Статус проверки анкет",

  /** Опрос */
  [FormFieldName.ResidenceRegion]: "Регион проживания",
  [FormFieldName.SalaryLevel]: "Уровень заработной платы",

  /** Задать вопрос - "Вопрос президенту", "Обращение в службу поддержки" */
  [FormFieldName.Question]: "Вопрос",
  /** Подтверждение номера телефона */
  [FormFieldName.VerificationCode]: "Код подтверждения",
  /** Анкета администратора */
  [FormFieldName.Questionnaire]: "Шаблон анкеты",
  [FormFieldName.QuestionnaireStatus]: "Поменять статус доступности шаблона",
} as const;
