import { useState } from "react";
import { EMPTY_STRING, JoinChar } from "@/common/constants/general";
import { ALL_NEWS_ARTICLE_CATEGORY_TREE_DATA } from "@/modules/admin-panel/children/admin-articles/constants/form";
import { compareAsString } from "@/common/helpers/compare-as-string";
import { Article } from "@/common/api/types/article";

type IProps = {
  article: Article;
  showArticle(id: Article["ID"]): void;
};

export const useNewsCard = ({ article, showArticle }: IProps) => {
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const onClickLike = () => setIsLiked(!isLiked);
  const onCardClick = () => showArticle(article.ID);

  const { Image, Created, Title, Tags, CategoryID } = article;
  const tagsText = Tags.join(JoinChar.Comma);
  const category =
    ALL_NEWS_ARTICLE_CATEGORY_TREE_DATA.find(({ value }) =>
      compareAsString(value, CategoryID)
    )?.title || "Без категории";

  const cover = Image?.FileUrl || EMPTY_STRING;

  return {
    tagsText,
    cover,
    category,
    Title,
    Created,
    isLiked,
    onClickLike,
    onCardClick,
  };
};
