import { EMPTY_STRING } from "@/common/constants/general";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSTextArea } from "@/common/ui-components/data-entry/input/text-area";
import { ILSInputNumber } from "@/common/ui-components/data-entry/input/number";
import { ILSDatePicker } from "@/common/ui-components/data-entry/date-picker";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { ILSSelectApi } from "@/common/ui-components/data-entry/select/api";
import { ILSRadioGroupApi } from "@/common/ui-components/data-entry/radio/api";
import { ILSCheckboxGroupApi } from "@/common/ui-components/data-entry/checkbox-group/api";
import { ILSInputMask } from "@/common/components/data-entry/masked-input";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FORM_SUFFIX } from "@/common/ui-components/data-entry/form/constants/suffix";
import { disableDateAfterToday } from "@/common/utils/helpers/components/date-picker/disable-date";
import { PHONE_MASK } from "@/common/constants/config/masks";
import { VacancyFormType } from "@/common/features/vacancies-common/types/form";
import { DateFormat } from "@/common/types/general/date-time";
import { Dictionaries } from "@/common/types/models/dictionary";
import { filterOption } from "@/common/utils/helpers/components/select/filter-option";

export const VACANCY_DESCRIPTION_FIELDS = [
  {
    fieldProps: { field: FormFieldName.Title },
    inputProps: { size: "default" },
    Component: ILSInput,
  },
  {
    fieldProps: { field: FormFieldName.Description },
    inputProps: { size: "default" },
    Component: ILSTextArea,
  },
  {
    fieldProps: { field: FormFieldName.Profession },
    inputProps: { size: "default" },
    Component: ILSInput,
  },
  {
    fieldProps: { field: FormFieldName.LicenseCategory },
    inputProps: {
      size: "default",
      mode: "multiple",
      dictionary: Dictionaries.LicenseCategory,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.CompanyHasVehicle,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: { size: "default", label: FORM_LABEL.CompanyHasVehicle },
    Component: ILSCheckbox,
  },
] as FormItemsFields;

export const VACANCY_VEHICLE_FIELDS = [
  {
    fieldProps: { field: FormFieldName.Brand },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.VehicleBrand,
      allowClear: true,
      showSearch: true,
      filterOption,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.VehicleType,
    },
    inputProps: {
      size: "default",
      allowClear: true,
      showSearch: true,
      filterOption,
      dictionary: Dictionaries.VehicleType,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: { field: FormFieldName.ProductionYear },
    inputProps: {
      size: "default",
      picker: "year",
      format: DateFormat.YYYY,
      disabledDate: disableDateAfterToday,
    },
    Component: ILSDatePicker,
  },
  {
    fieldProps: { field: FormFieldName.AllowedMaxMass },
    inputProps: {
      size: "middle",
      min: 0,
      addonAfter: FORM_SUFFIX.KG,
    },
    Component: ILSInputNumber,
  },
  {
    fieldProps: { field: FormFieldName.LoadCapacity },
    inputProps: {
      size: "middle",
      min: 0,
      addonAfter: FORM_SUFFIX.KG,
    },
    Component: ILSInputNumber,
  },
  {
    fieldProps: { field: FormFieldName.WheelArrangement },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.WheelArrangement,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: { field: FormFieldName.EngineType },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.EngineType,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: { field: FormFieldName.EngineCapacity },
    inputProps: { size: "middle", min: 0, addonAfter: FORM_SUFFIX.HP },
    Component: ILSInputNumber,
  },
  {
    fieldProps: { field: FormFieldName.EngineVolume },
    inputProps: {
      size: "middle",
      min: 0,
      addonAfter: (
        <p style={{ margin: 0 }}>
          {FORM_SUFFIX.CM}
          <sup>{FORM_SUFFIX.CUBIC}</sup>
        </p>
      ),
    },
    Component: ILSInputNumber,
  },
  {
    fieldProps: { field: FormFieldName.EcologyClass },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.EcologyClass,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: { field: FormFieldName.Gearbox },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.Gearbox,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: { field: FormFieldName.CabinType },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.CabinType,
    },
    Component: ILSSelectApi,
  },
] as FormItemsFields;

export const VACANCY_VEHICLE_CONDITIONS_FIELDS = [
  {
    fieldProps: { field: FormFieldName.VehicleCondition },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.VehicleCondition,
    },
    Component: ILSRadioGroupApi,
  },
] as FormItemsFields;

export const VACANCY_VEHICLE_VIDEO_FIELDS = [
  {
    fieldProps: { field: FormFieldName.Video },
    inputProps: { size: "default" },
    Component: ILSInput,
  },
] as FormItemsFields;

export const VACANCY_PAYMENT_FIELDS = [
  {
    fieldProps: { field: FormFieldName.WorkSchedule },
    inputProps: {
      size: "default",
      style: { display: "flex", flexDirection: "column" },
      dictionary: Dictionaries.WorkSchedule,
    },
    Component: ILSCheckboxGroupApi,
  },
  {
    fieldProps: { field: FormFieldName.RegistrationMethod },
    inputProps: {
      size: "default",
      style: { display: "flex", flexDirection: "column" },
      dictionary: Dictionaries.RegistrationMethod,
    },
    Component: ILSCheckboxGroupApi,
  },
  {
    fieldProps: { field: FormFieldName.PaymentTerms },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.PaymentTerm,
    },
    Component: ILSSelectApi,
  },
] as FormItemsFields;

export const VACANCY_EXTRA_CONDITIONS_FIELDS = [
  {
    fieldProps: { field: FormFieldName.PaymentFrequency },
    inputProps: {
      size: "default",

      dictionary: Dictionaries.PaymentFrequency,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: { field: FormFieldName.Extra },
    inputProps: {
      size: "default",
      mode: "multiple",
      dictionary: Dictionaries.ExtraConditions,
    },
    Component: ILSSelectApi,
  },
] as FormItemsFields;

export const VACANCY_ADDRESS_FIELDS = [
  {
    fieldProps: { field: FormFieldName.Address },
    inputProps: { size: "default" },
    Component: ILSInput,
  },
  {
    fieldProps: { field: FormFieldName.JobRegion },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.JobRegion,
      showSearch: true,
      filterOption,
    },
    Component: ILSSelectApi,
  },
] as FormItemsFields;

export const VACANCY_CONTACTS_FIELDS = [
  {
    fieldProps: { field: FormFieldName.VacancyPhone },
    inputProps: { size: "default", mask: PHONE_MASK },
    Component: ILSInputMask,
  },
] as FormItemsFields;

export const VACANCIES_OPEN_FORM_BUTTON = {
  [VacancyFormType.Create]: "+ Создать",
  [VacancyFormType.Edit]: "Редактировать",
};
