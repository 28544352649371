import { useForm } from "antd/es/form/Form";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { OrganizationInfo } from "@/common/types/models/account";
import { AdminOrganizationFormValues } from "@/modules/admin-panel/children/admin-organizations/children/organization/types/organization-form";
import { useFormAvatar } from "@/common/hooks/use-form-avatar";
// eslint-disable-next-line max-len
import { adminOrganizationToFormValues } from "@/modules/admin-panel/children/admin-organizations/children/organization/helpers/admin-organization-to-form-values";
import { useAdminOrganizationsOperations } from "@/modules/admin-panel/children/admin-organizations/hooks/use-admin-organizations-operations";
// eslint-disable-next-line max-len
import { formValuesToAdminOrganization } from "@/modules/admin-panel/children/admin-organizations/children/organization/helpers/form-values-to-admin-organization";

type IProps = {
  organization: OrganizationInfo | null;
};

export const useAdminOrganizationForm = ({ organization }: IProps) => {
  const { handleUpdateOrganization } = useAdminOrganizationsOperations();

  const initialValues = adminOrganizationToFormValues({
    organizationInfo: organization,
  });

  const [form] = useForm<AdminOrganizationFormValues>();

  const {
    fileList,
    removedSavedFiles,
    handleAvatarChange,
    handleAvatarRemove,
    handleAvatarReset,
  } = useFormAvatar({
    avatar: initialValues?.Avatar,
  });

  const handleSubmit = async (formValues: AdminOrganizationFormValues) => {
    try {
      const payload = await formValuesToAdminOrganization({
        formValues,
        fileList,
        removedSavedFiles,
      });

      handleUpdateOrganization({ id: organization?.ID, body: payload });
    } catch (e) {
      showUserDevErrors({ e });
    }
    return formValues;
  };

  const handleCancelChange = () => {
    /** сбрасываем значения формы до начальных при клике на "Отмена" */
    form.resetFields();
    form.setFieldsValue(initialValues || EMPTY_OBJECT_DATA);

    /** аватар сбрасывается через свой state */
    handleAvatarReset();
  };

  return {
    form,
    initialValues,
    fileList,
    handleSubmit,
    handleCancelChange,
    handleAvatarChange,
    handleAvatarRemove,
  };
};
