import { InfoDate, TimeStamp } from "@/common/types/general/date-time";
import { JobSearchStatusType } from "@/common/types/models/user/job-search-status";
import { Account, RatingType } from "@/common/types/models/account";
import {
  Driver,
  DriverWorkExperience,
} from "@/common/types/models/user/driver";
import { IEnum } from "@/common/types/general/enum";
import { ImageType } from "@/common/types/models/image";

export type Phone = string;
export type Email = string;
export type NickName = string;
export type Password = string;
export type Login = Phone | Email | NickName;

export enum UserRole {
  organization = "organization",
  employee = "employee",
  driver = "driver",
  administrator = "administrator",
  guest = "guest",
}

export enum UserRoleLabel {
  organization = "Организация",
  employee = "Сотрудник",
  driver = "Водитель",
  administrator = "Администратор",
  guest = "Посетитель",
}

export type Role<A> = {
  ID: number;
  Name: string;
  Alias: A;
};

export type RoleUser = Role<UserRole>;

export type UserInfo = {
  Name: string;
  Surname: string;
  Patronymic?: string;
  OrganizationName?: string;
  Birthday: TimeStamp;
  DrivingExperience?: DriverWorkExperience[];
  LicenseCategories?: string[];
  JobSearchStatus: JobSearchStatusType;
  ReadyToStartTomorrow: boolean;
  WorkingPosition?: string;
  /* ---Тип занятости---*/
  // Все
  // Основная работа
  // Подработка
  EmploymentType: IEnum<string>;
  /* ---График работы---*/
  // Полный день
  // Неполный день
  // Свободный
  // Сменный
  // Вахтовый
  // 5/2 (пн. - пт.)
  // 6/1 (пн. - сб.)
  // Удалённая работа
  WorkSchedule: IEnum<string>;
  /* ---Образование---*/
  // Высшее
  // Незаконченное высшее
  // Среднее
  // Специальное
  EducationLevel: IEnum<string>;
  /* ---Пол ---*/
  // Мужской
  // Женский
  Sex: IEnum<string>;
  /* ---Гражданство ---*/
  Citizens: string;
  Salary: {
    From: number;
    To: number;
  };
  /* --- Водительское удостоверение ---*/
  License: {
    Number: number;
    Date: TimeStamp;
    Categories?: string[];
  };
  /* ---Свой транспорт ---*/
  // Легковой автомобиль
  // Грузовой автомобиль
  // Мотоцикл
  // Мопед
  OwnVehicle: IEnum<string>;
  /* ---Мед. книжка ---*/
  // Неважно
  // Есть
  MedicalCertificate: IEnum<string>;
};

export type User = {
  ID: number;
  Account: Account;
  AccountID: Account["ID"];
  Blocked: number | null; // 0 | 1 | null
  // for backend: приходит null, хотя надо бы заполнять
  Created: InfoDate;
  Deleted: InfoDate;
  DeletedBy: User["ID"] | null;
  /** Сущность Водителя один к одному: один пользователь один Водитель */
  Driver: Driver | null;
  Email: Email;
  Login: Login;
  LastLogin: null;
  Name: string;
  Surname: string | null;
  Patronymic: string | null;
  Phone: Phone | null;
  UserRoles: Array<RoleUser>;
  WorkingPosition?: string | null;
  // todo такого поля нет и вероятно не будет (backend в разработке)
  UserInfo?: UserInfo;
  // todo backend в разработке
  Rating?: RatingType;
  Avatar: ImageType | null | undefined;
  PhoneVerified: boolean;
};
