import "./index.scss";
import { FC } from "react";
import classNames from "classnames";
import { isNil } from "lodash";
import { NavLink } from "react-router-dom";
import {
  IWideCardInfoProps,
  WideCardInfo,
} from "@/common/ui-components/data-display/wide-card/info";
import { IILSButtonConfirmTooltipProps } from "@/common/components/data-display/buttons/confirm-tooltip";
import { WideCardRatings } from "@/common/ui-components/data-display/wide-card/ratings";
import {
  IWideCardProfileProps,
  WideCardProfile,
} from "@/common/ui-components/data-display/wide-card/profile";
import { WideCardActions } from "@/common/ui-components/data-display/wide-card/actions";
import { WideCardImageStatuses } from "@/common/ui-components/data-display/wide-card/image-statuses";
import { IILSChipsProps } from "@/common/ui-components/data-display/chips";
import { WideCardRibbon } from "@/common/ui-components/data-display/wide-card/ribbon";
import { WideCardProfileRating } from "@/common/ui-components/data-display/wide-card/profile-rating-mobile";

interface IProps {
  avatar?: string | null;
  cover?: string | null;
  info?: IWideCardInfoProps;
  ratings?: Array<number>;
  profile?: IWideCardProfileProps;
  actions?: IILSButtonConfirmTooltipProps[] | undefined;
  statuses?: Array<IILSChipsProps & { text: string; hide?: boolean }>;
  redirectID?: number | null;
  membership?: string | null | undefined;
}

export const ILSWideCard: FC<IProps> = ({
  statuses,
  cover,
  info = {} as IWideCardInfoProps,
  ratings,
  profile,
  avatar,
  actions,
  redirectID,
}) => {
  return (
    <li className={classNames("ils-card", { rows: actions?.length })}>
      <WideCardImageStatuses
        cover={cover}
        statuses={statuses}
        avatar={avatar}
      />
      <WideCardInfo
        title={info?.title}
        titleBadge={info?.titleBadge}
        mainSubtitle={info?.mainSubtitle}
        subtitle={info?.subtitle}
        description={info?.description}
      />
      <WideCardRatings ratings={ratings} profile={profile} />
      <WideCardProfileRating ratings={ratings} profile={profile} />
      <WideCardProfile {...profile} />
      {!isNil(redirectID) && (
        <NavLink to={`${redirectID}`} className="ils-card__link" />
      )}
      {actions && <WideCardActions actions={actions} />}
      <WideCardRibbon id={redirectID} />
    </li>
  );
};
