import {
  loginActions,
  recoveryProcessActions,
  resetPasswordActions,
  setUserOptionsActions,
} from "@modules/auth/actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { LoginPayload } from "@modules/auth/sagas/login";
import { NavigateFunction } from "react-router-dom";
import { AUTH_API } from "@/common/api/endpoints/auth";
import { ResetPasswordRequest } from "@/common/api/types/auth";

export type ResetPasswordAction = PayloadAction<
  ResetPasswordRequest & {
    navigate: NavigateFunction;
  }
>;

export function* resetPasswordWorker(action: ResetPasswordAction) {
  const { request, failure, fulfill } = resetPasswordActions;

  try {
    const { Password, Login, navigate } = action.payload;
    yield put(request());
    yield call(AUTH_API.resetPassword, action.payload);
    const loginPayload: LoginPayload = {
      navigate,
      Password,
      Login,
    };
    yield put(loginActions.trigger(loginPayload));
    yield put(setUserOptionsActions.success({ data: [] }));
    yield put(recoveryProcessActions({ recoveryProcess: null }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* resetPasswordWatcher() {
  yield takeLatest(resetPasswordActions.trigger, resetPasswordWorker);
}
