export enum MyInfoContentType {
  Driver = "Driver",
  Organization = "Organization",
  Account = "Account",
}

export enum MyInfoTabs {
  Account = "Account",
  MyOrganization = "MyOrganization",
  Users = "Users",
  MyRating = "MyRating",
  Questionnaire = "Questionnaire",
}
