import { useState } from "react";
import { UploadFile } from "antd";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { AVATAR_MAX_SIZE_TEN_MB } from "@/common/constants/files";
import { useRemoveFile } from "@/common/hooks/use-remove-file";

type IProps = {
  avatar: UploadFile[] | undefined;
  enableSubmit?: VoidFunction;
};

export const useFormAvatar = ({ avatar, enableSubmit }: IProps) => {
  const { removedSavedFiles, onRemoveFile } = useRemoveFile();

  const [fileList, setFileList] = useState<Array<UploadFile> | undefined>(
    avatar
  );

  const handleAvatarChange = ({
    fileList: newFileList,
  }: {
    fileList: Array<UploadFile>;
  }) => {
    if (
      newFileList?.[0]?.size &&
      newFileList?.[0]?.size < AVATAR_MAX_SIZE_TEN_MB
    ) {
      setFileList(newFileList);
    }
    enableSubmit?.();
  };

  const handleAvatarRemove = (file: UploadFile) => {
    onRemoveFile(file);
    setFileList(EMPTY_ARRAY_DATA);
  };

  const handleAvatarReset = () => {
    setFileList(avatar);
  };

  return {
    fileList,
    removedSavedFiles,
    handleAvatarChange,
    handleAvatarRemove,
    handleAvatarReset,
  };
};
