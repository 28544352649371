import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { VacanciesStore } from "@/common/features/vacancies-common/types/store";

export const VACANCIES_INITIAL_STATE: VacanciesStore = {
  error: EMPTY_ARRAY_DATA,
  isFetching: false,
  vacancies: EMPTY_ARRAY_DATA,
  appliesList: EMPTY_ARRAY_DATA,
  filters: undefined,
};
