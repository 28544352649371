import { FC, RefObject } from "react";
import { Carousel, CarouselProps } from "antd";
import { CarouselRef } from "antd/es/carousel";

type IProps = CarouselProps & {
  carouselRef?: RefObject<CarouselRef>;
};

export const ILSCarousel: FC<IProps> = ({ carouselRef, ...props }) => {
  return <Carousel {...props} ref={carouselRef} />;
};
