import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useWatch } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { MembershipCheckStatus } from "@/common/types/models/user/driver";
import { checkDriverWaitingMembership } from "@/common/features/drivers-common/helpers/check-driver-waiting-membership";

export const useAdminEditMembership = () => {
  const form = useFormInstance();
  const publicOrganizationMembership = useWatch(
    FormFieldName.PublicOrganizationMembership,
    form
  );
  const membershipChecked = useWatch(FormFieldName.MembershipChecked, form);

  /** ожидает ли водитель проверку */
  const waitingMembershipCheck = checkDriverWaitingMembership({
    publicOrganizationMembership,
    membershipChecked,
  });

  const [showWaitingCheckAlert, setShowWaitingCheckAlert] = useState(
    waitingMembershipCheck
  );

  useEffect(() => {
    /** Если администратор отклонил статус, то сбрасываем поле "Участник" */
    if (
      publicOrganizationMembership &&
      membershipChecked === MembershipCheckStatus.Cancelled
    ) {
      form.setFieldValue(FormFieldName.PublicOrganizationMembership, false);
    }

    /** Если администратор принял статус, то устанавливаем поле "Участник", если пустое */
    if (
      !publicOrganizationMembership &&
      membershipChecked === MembershipCheckStatus.Confirmed
    ) {
      form.setFieldValue(FormFieldName.PublicOrganizationMembership, true);
    }

    /** Если устанавливается чекбокс "Участник организации",
     * и нет проверки, показываем уведомление, что необходима проверка */
    setShowWaitingCheckAlert(
      checkDriverWaitingMembership({
        publicOrganizationMembership,
        membershipChecked,
      })
    );
  }, [membershipChecked, publicOrganizationMembership]);

  return { showWaitingCheckAlert };
};
