import { isEmpty } from "lodash";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { DictionaryType } from "@/common/types/models/dictionary";
import { SelectApiOption } from "@/common/types/general/common";

export const getSelectApiOptions = (
  dictionaryValues: DictionaryType[],
  aliasAsLabel: boolean = false
): SelectApiOption[] => {
  return !isEmpty(dictionaryValues) && dictionaryValues
    ? dictionaryValues?.map((option) => {
        return {
          value: option?.ID,
          label: !aliasAsLabel ? option?.Name : option?.Alias,
          alias: option?.Alias,
        };
      })
    : EMPTY_ARRAY_DATA;
};
