import { useIcons } from "@/common/hooks/use-icons";
import {
  FOOTER_LINKS_URL,
  FOOTER_LINK_ICON_SIZE,
} from "@/modules/start/constants/useful-links";

export const useFooterLinks = () => {
  const {
    telegram: Telegram,
    Vk,
    Youtube,
    Website,
  } = useIcons(FOOTER_LINK_ICON_SIZE);

  const footerLinks = [
    {
      icon: <Youtube />,
      url: FOOTER_LINKS_URL.youtube,
    },
    {
      icon: <Telegram />,
      url: FOOTER_LINKS_URL.telegram,
    },
    {
      icon: <Vk />,
      url: FOOTER_LINKS_URL.vk,
    },
    {
      icon: <Website />,
      url: FOOTER_LINKS_URL.website,
    },
  ];

  return { footerLinks };
};
