import "./sider-banner-survey.scss";
import { FC } from "react";
import { Form } from "antd";
import { FormInstance } from "antd/lib";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSDivider } from "@/common/components/layout/divider";
import { SURVEY_FIELDS } from "@/modules/start/constants/survey";
import { SurveyFormValues } from "@/modules/start/types/survey";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  form: FormInstance<SurveyFormValues>;
  handleSubmit: (formValues: SurveyFormValues) => void;
};

export const SiderBannerSurvey: FC<IProps> = ({ form, handleSubmit }) => {
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      className="sider-banner-survey"
    >
      <ILSDivider className="sider-banner-survey__divider" />
      {renderFormItems({ fields: SURVEY_FIELDS })}
      <ILSButton
        block
        type="primary"
        htmlType="submit"
        className="sider-banner-survey__submit-btn"
      >
        Отправить результаты опроса
      </ILSButton>
    </Form>
  );
};
