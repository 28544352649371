import { Account, OrganizationInfo } from "@/common/types/models/account";
import { User } from "@/common/types/models/user/user";
import { Currency } from "@/common/constants/general/currencies";
import { OrganizationApply } from "@/common/types/models/vacancies/apply";
import { ImageType } from "@/common/types/models/image";
import { DictionaryType } from "@/common/types/models/dictionary";

export type VacancyPayment = {
  Price: VacancyPrice; // required
  // варианты
  // "каждый день"
  // "дважды в месяц"
  // "раз в неделю"
  // "три раза в месяц"
  // "раз в месяц"
  Frequency?: DictionaryType | null;
  // варианты
  // "в месяц"
  // "в неделю"
  // "за час"
  // "в год"
  // "за смену"
  // "сдельная оплата"
  Term?: DictionaryType | null;
  // "Полный день"
  // "Неполный день"
  // "Свободный"
  //  "Сменный"
  //  "Вахтовый"
  //  "5/2 (пн. - пт.)"
  //  "6/1 (пн. - сб.)"
  //  "Удалённая работа" // todo задача на бек - всегда должен быть Array
  WorkSchedule?: DictionaryType[] | null;
};

export type Vacancy = {
  ID: number;
  Title?: string; // required
  Description?: string;
  // все данные подтягиваются от создателя (create) - аккаунт + пользователь
  Owner?: {
    AccountID: Account["ID"];
    UserID: User["ID"];
    OrganizationID: OrganizationInfo["ID"];
    // рейтинг компании, которая выложила вакансию
    Rating: number;
    Avatar?: ImageType | null;
    Name: User["Name"];
    CompletedVacancies: number | null;
    HasFeedback: boolean;
    IsVerified: boolean;
    PublicOrganizationPartnership: boolean;
    PartnershipChecked: boolean | null;
    SocialRating: number;
  };
  PublishedVacancies?: number;
  Image?: { Count: number; Data: { Url: string }[] };
  Details?: {
    Profession?: string; // ?
    // A: "Мотоциклы",
    // A1: "Легкие мотоциклы",
    // B: "Легковые автомобили, небольшие грузовики (до 3,5 тонн)",
    // BE: "Легковые автомобили с прицепом",
    // B1: "Трициклы",
    // C: "Грузовые автомобили (от 3,5 тонн)",
    // CE: "Грузовые автомобили с прицепом",
    // C1: "Средние грузовики (от 3,5 до 7,5 тонн)",
    // C1E: "Средние грузовики с прицепом",
    // D: "Автобусы",
    // DE: "Автобусы с прицепом",
    // D1: "Небольшие автобусы",
    // D1E: "Небольшие автобусы с прицепом",
    // M: "Мопеды",
    // Tm: "Трамваи",
    // Tb: "Троллейбусы",
    LicenseCategory?: DictionaryType[] | null;
    DrivingExperience?: {
      Year?: number | null; // кол-во лет
      Month?: number | null; // кол-во месяцев
    };
  };
  Payment?: VacancyPayment;
  Info?: {
    Address?: string;
    Phone?: string;
  };
  // варианты
  // "Униформа"
  // "Проживание"
  // "Медицинская страховка"
  // "Питание" },
  // "Оплата бензина"
  // "Парковка"
  // "Зона отдыха"
  // "Транспорт до работы"
  // "Скидки в компании"
  // "Подарки детям а праздники"
  // "Оплата мобильной связи"
  Extra?: DictionaryType[] | null;
  Vehicle?: VacancyVehicle;
  RegistrationMethod?: DictionaryType[];
  Applies?: OrganizationApply[];
  VacancyStatus?: DictionaryType | null;
  Comment?: string | undefined;
  VacancyApplyID?: number | undefined; // идентификатор отклика водителя
  JobRegion?: DictionaryType | null;
};

export type Vacancies = Array<Vacancy>;

// 0 - не предоставляет авто (в таком случае остальные поля null),
// 1 - предоставляет авто
export enum VacancyVehiclePossession {
  NotProvided,
  Provided,
}

export type VacancyPrice = {
  From?: number | null;
  To?: number | null;
  Unit?: Currency | null;
};

export type VacancyVehicle = {
  // 0 - не предоставляет авто (в таком случае остальные поля null),
  // 1 - предоставляет авто
  Own?: VacancyVehiclePossession;
  // будут варианты брендов (согласовать/взять с авто ру)
  Brand?: DictionaryType | null;
  // как в цэ/тмс
  Type?: DictionaryType | null;
  // год
  ProductionYear?: number | null;
  Profile?: VehicleProfile;
  // варианты
  // "Трудовой договор"
  // "Договор ГПХ с ИП"
  // "Договор ГПХ с самозанятым"
  // "Договор ГПХ с физическим лицом"
};

export type VehicleProfile = {
  MaxMass?: number | null;
  LoadCapacity?: number | null;
  // колесная формула [общее число колёс] × [число ведущих колёс]
  // варианты 24×24 16×16 10×10 10×6 8×8 8×6 8×4 8×2 6×4 6×6 4×4 4×2
  WheelArrangement?: DictionaryType | null;
  // варианты Бензин Дизель Газ Гибрид Электро Бензин+газ Дизель+газ
  EngineType?: DictionaryType | null;
  EngineCapacity?: number | null; // "л.с."
  EngineVolume?: number | null; // cm3
  // варианты Евро-1 Евро-2 Евро-3 Евро-4 Евро-5 Евро-6
  EcologyClass?: DictionaryType | null;
  // варианты механическая автоматическая полуавтомат робот
  Gearbox?: DictionaryType | null;
  // варианты
  // 2-х местная без спального
  // 2-х местная с 1 спальным
  // 2-х местная с 2 спальными
  // 3-х местная без спального
  // 3-х местная с 1 спальным
  // 6-и местная, двухрядная
  // 7-и местная, двухрядная
  CabinType?: DictionaryType | null;
  // варианты
  // "Новое"
  // "Б/У"
  Condition?: DictionaryType | null;
  Image?: {
    Count: number;
    Data: ImageType[];
  };
  Video?: string;
};
