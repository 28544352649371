import { Slider, SliderSingleProps } from "antd";
import { EMPTY_STRING } from "@/common/constants/general";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { COLOR_PALETTE } from "@/styles/color-palette";
import {
  ALL_STATUSES_VALUE,
  DRIVER_LICENSE_MORE_THAN_THREE_OPTIONS,
  MEDICAL_CERTIFICATE_OPTIONS,
  READY_START_TOMORROW_OPTIONS,
  SEX_OPTIONS,
} from "@/common/constants/config/options";
import { Dictionaries } from "@/common/types/models/dictionary";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";
import { DriversFiltersFormValues } from "@/common/features/drivers-common/types/drivers-filters";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSSelectApi } from "@/common/ui-components/data-entry/select/api";
import { ILSRadioGroup } from "@/common/ui-components/data-entry/radio/group";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { JobSearchStatusType } from "@/common/types/models/user/job-search-status";
import { filterOption } from "@/common/utils/helpers/components/select/filter-option";

export const AGE_VALUES = {
  MIN: 14,
  MAX: 99,
};

const ageMarks: SliderSingleProps["marks"] = {
  [AGE_VALUES.MIN]: "14",
  [AGE_VALUES.MAX]: {
    style: {
      color: COLOR_PALETTE.sliderDefault,
    },
    label: <strong>99+</strong>,
  },
};

export const DRIVING_EXPERIENCE_VALUES = {
  MIN: 0,
  MAX: 50,
};

const experienceMarks: SliderSingleProps["marks"] = {
  [DRIVING_EXPERIENCE_VALUES.MIN]: "0",
  [DRIVING_EXPERIENCE_VALUES.MAX]: {
    style: {
      color: COLOR_PALETTE.sliderDefault,
    },
    label: <strong>50+</strong>,
  },
};

export const DRIVERS_WAITING_FOR_MEMBERSHIP_CHECK = [
  {
    fieldProps: {
      field: FormFieldName.WaitingForMembershipCheck,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.WaitingForMembershipCheck],
    },
    Component: ILSCheckbox,
  },
] as FormItemsFields;

export const DRIVERS_STATUS_FILTER = [
  {
    fieldProps: { field: FormFieldName.JobSearchStatus },
    inputProps: { size: "default", dictionary: Dictionaries.JobSearchStatus },
    Component: ILSSelectApi,
  },
] as FormItemsFields;

export const DRIVERS_MAIN_FILTERS_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.JobSearchRegion,
    },
    inputProps: {
      dictionary: Dictionaries.JobRegion,
      showSearch: true,
      mode: "multiple",
      filterOption,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.ReadyForTrips,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.ReadyForTrips],
    },
    Component: ILSCheckbox,
  },
  {
    fieldProps: {
      field: FormFieldName.ReadyForRelocation,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.ReadyForRelocation],
    },
    Component: ILSCheckbox,
  },
  {
    fieldProps: {
      field: FormFieldName.EmploymentType,
    },
    inputProps: {
      dictionary: Dictionaries.EmploymentType,
      showSearch: true,
      allowClear: true,
      filterOption,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.WorkSchedule,
    },
    inputProps: {
      dictionary: Dictionaries.WorkSchedule,
      showSearch: true,
      mode: "multiple",
      allowClear: true,
      filterOption,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: { field: FormFieldName.ReadyToStartTomorrow },
    inputProps: {
      size: "default",
      optionType: "button",
      buttonStyle: "solid",
      options: READY_START_TOMORROW_OPTIONS,
    },
    Component: ILSRadioGroup,
  },
  {
    fieldProps: { field: FormFieldName.DrivingExperience },
    inputProps: {
      size: "default",
      marks: experienceMarks,
      step: 1,
      max: 50,
      range: true,
    },
    Component: Slider,
  },
  {
    fieldProps: { field: FormFieldName.EducationLevel },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.EducationLevel,
      allowClear: true,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: { field: FormFieldName.Sex },
    inputProps: {
      optionType: "button",
      buttonStyle: "solid",
      options: SEX_OPTIONS,
    },
    Component: ILSRadioGroup,
  },
  {
    fieldProps: {
      field: FormFieldName.ActivateAgeField,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL.ActivateAgeField,
    },
    Component: ILSCheckbox,
  },
  {
    fieldProps: { field: FormFieldName.Age },
    inputProps: {
      size: "default",
      marks: ageMarks,
      step: 1,
      min: 14,
      range: true,
    },
    Component: Slider,
  },
  {
    fieldProps: { field: FormFieldName.Citizens },
    inputProps: { size: "default" },
    Component: ILSInput,
  },
] as FormItemsFields;

export const DRIVERS_DRIVER_FILTERS_FIELDS = [
  {
    fieldProps: { field: FormFieldName.DriverLicenseMoreThanThree },
    inputProps: {
      size: "default",
      optionType: "button",
      buttonStyle: "solid",
      options: DRIVER_LICENSE_MORE_THAN_THREE_OPTIONS,
    },
    Component: ILSRadioGroup,
  },
  {
    fieldProps: {
      field: FormFieldName.LicenseCategory,
      label: "Открытые категории (ВУ) водительского удостоверения",
    },
    inputProps: {
      size: "default",
      mode: "multiple",
      dictionary: Dictionaries.LicenseCategory,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.OwnVehicleType,
    },
    inputProps: {
      dictionary: Dictionaries.OwnVehicleType,
      showSearch: true,
      mode: "multiple",
      filterOption,
      allowClear: true,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.MedicalCertificate,
    },
    inputProps: {
      optionType: "button",
      buttonStyle: "solid",
      options: MEDICAL_CERTIFICATE_OPTIONS,
    },
    Component: ILSRadioGroup,
  },
] as FormItemsFields;

export const DRIVERS_FILTERS_INITIAL_VALUES: Partial<DriversFiltersFormValues> =
  {
    [FormFieldName.DrivingExperience]: [
      DRIVING_EXPERIENCE_VALUES.MIN,
      DRIVING_EXPERIENCE_VALUES.MAX,
    ],
    [FormFieldName.Age]: [AGE_VALUES.MIN, AGE_VALUES.MAX],
    [FormFieldName.ReadyToStartTomorrow]: 0,
    [FormFieldName.DriverLicenseMoreThanThree]: 0,
    [FormFieldName.MedicalCertificate]: 0,
  };

/** В модуле "Поиск водителей" всегда поиск только активных водителей */
export const DRIVERS_SEARCH_FILTERS_INITIAL_VALUES: Partial<DriversFiltersFormValues> =
  {
    ...DRIVERS_FILTERS_INITIAL_VALUES,
    [FormFieldName.JobSearchStatus]: JobSearchStatusType.ActiveSearch,
  };

/** В модуле "Управление водителями" добавлен фильтр по статусам, по умолчанию "Все" */
export const ADMIN_DRIVERS_FILTERS_INITIAL_VALUES: Partial<DriversFiltersFormValues> =
  {
    ...DRIVERS_FILTERS_INITIAL_VALUES,
    [FormFieldName.JobSearchStatus]: ALL_STATUSES_VALUE,
  };
