import { FC } from "react";
import { Badge } from "antd";
import dayjs from "dayjs";
import { Article } from "@/common/api/types/article";
import { ILSWideCard } from "@/common/ui-components/data-display/wide-card";
import { ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT } from "@/common/types/general/date-time";
import { EMPTY_STRING, JoinChar } from "@/common/constants/general";
import { IILSButtonConfirmTooltipProps } from "@/common/components/data-display/buttons/confirm-tooltip";

interface IProps {
  article: Article;

  onEdit(id: Article["ID"]): void;

  onDelete(id: Article["ID"]): void;
}

export const ArticleCard: FC<IProps> = ({ article, onEdit, onDelete }) => {
  const { Image, Text, Created, Title, Tags } = article;
  const info = {
    title: Title || "Нет названия",
    subtitle: dayjs.unix(Created).format(ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT),
    description: Text || "Нет превью",
  };
  const cover = Image?.FileUrl || EMPTY_STRING;

  const handleArticleCardAction = (cb: (id: Article["ID"]) => void) => () =>
    cb(article.ID);

  const actions: IILSButtonConfirmTooltipProps[] = [
    {
      children: "Редактировать",
      onConfirm: handleArticleCardAction(onEdit),
    },
    {
      children: "Удалить",
      danger: true,
      onConfirm: handleArticleCardAction(onDelete),
    },
  ];

  return (
    <Badge.Ribbon text={Tags.join(JoinChar.Comma)}>
      <ILSWideCard cover={cover} actions={actions} info={info} />
    </Badge.Ribbon>
  );
};
