import { Vacancy } from "@/common/types/models/vacancies/model";
import { useModal } from "@/common/hooks/use-modal";
import { useVacancyCardDetails } from "@/common/features/vacancies-common/hooks/use-vacancy-card-details";
import { useVacancyCardActions } from "@/common/features/vacancies-common/hooks/use-vacancy-card-actions";
import { useVacancyOperations } from "@/common/features/vacancies-common/hooks/use-vacancy-operations";
import { useDriverIncompleteProfile } from "@/common/features/drivers-common/hooks/use-driver-incomplete-profile";

export const useVacancyCard = (vacancy: Vacancy) => {
  const { vacancyID, info, cover, profile, ratings, statuses, statusAlias } =
    useVacancyCardDetails(vacancy);

  const {
    isModalOpen: isDriverAlertApplyModalOpen,
    showModal: showDriverAlertApplyModal,
  } = useModal();

  const { driverProfileIsIncomplete } = useDriverIncompleteProfile();
  const driverHasApply = Boolean(vacancy?.VacancyApplyID);
  const disableApply = driverHasApply || driverProfileIsIncomplete;

  const { handleDelete, handleApply } = useVacancyOperations();

  const onDelete = () => handleDelete(vacancy?.ID);
  const onApply = () => {
    // Блокируем отклик, если есть отклик или если профиль водителя не заполнен;
    if (disableApply) {
      return showDriverAlertApplyModal();
    }
    return handleApply(vacancy?.ID);
  };

  const { isModalOpen, showModal } = useModal();

  const actions = useVacancyCardActions({
    vacancy,
    onDelete,
    onEdit: showModal,
    statusAlias,
    onApply,
  });

  return {
    vacancyID,
    actions,
    info,
    cover,
    profile,
    ratings,
    statuses,
    isModalOpen,
    showModal,
    isDriverAlertApplyModalOpen,
    showDriverAlertApplyModal,
  };
};
