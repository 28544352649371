import { EMPTY_ARRAY_DATA, EMPTY_STRING } from "@/common/constants/general";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { AdminOrganizationsFiltersType } from "@/modules/admin-panel/children/admin-organizations/types/organizations-filters";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const ORGANIZATIONS_FILTERS_FIELDS = [
  {
    fieldProps: { field: FormFieldName.OrganizationName },
    inputProps: { size: "default", disabled: true },
    Component: ILSInput,
  },
  {
    fieldProps: { field: FormFieldName.INN, rules: EMPTY_ARRAY_DATA },
    inputProps: { size: "default", disabled: true },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.WaitingForPartnershipCheck,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.WaitingForPartnershipCheck],
    },
    Component: ILSCheckbox,
  },
] as FormItemsFields;

export const ORGANIZATIONS_FILTERS_INITIAL_VALUES: AdminOrganizationsFiltersType =
  {
    [FormFieldName.OrganizationName]: EMPTY_STRING,
    [FormFieldName.INN]: EMPTY_STRING,
    [FormFieldName.WaitingForPartnershipCheck]: false,
  };
