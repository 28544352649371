import { createSelector } from "reselect";
import { QuestionnaireCommonModuleStore } from "@/common/features/questionnaire-common/types/store";

export const questionnaireSelector = createSelector(
  (state: QuestionnaireCommonModuleStore) => {
    return state.QuestionnaireCommonModule.questionnaire;
  },
  (questionnaire) => questionnaire.file
);

export const questionnaireFetchingSelector = createSelector(
  (state: QuestionnaireCommonModuleStore) => {
    return state.QuestionnaireCommonModule.questionnaire;
  },
  (questionnaire) => questionnaire.isFetching
);

export const questionnaireResultsSelector = createSelector(
  (state: QuestionnaireCommonModuleStore) => {
    return state.QuestionnaireCommonModule.results;
  },
  (results) => results || []
);

export const questionnaireResultsFetchingSelector = createSelector(
  (state: QuestionnaireCommonModuleStore) => {
    return state.QuestionnaireCommonModule.isFetching;
  },
  (isFetching) => isFetching
);
