import { UsefulResourcesTypes } from "@/modules/social-services/children/resources/types/tabs";
import { ResourcesEducation } from "@/modules/social-services/children/resources/containers/resources/education";
import { ResourcesLogisticServices } from "@/modules/social-services/children/resources/containers/resources/logistic-services";
import { ResourcesInsurance } from "@/modules/social-services/children/resources/containers/resources/insurance";
import { ResourcesContests } from "@/modules/social-services/children/resources/containers/resources/contests";

export const USEFUL_RESOURCES_TITLES = {
  [UsefulResourcesTypes.LogisticServices]: "Логистические сервисы",
  [UsefulResourcesTypes.Education]: "Образование",
  [UsefulResourcesTypes.Insurance]: "Возможности страхования",
  [UsefulResourcesTypes.Contests]: "Конкурсы",
};

export const USEFUL_RESOURCES_TABS = [
  {
    key: UsefulResourcesTypes.LogisticServices,
    Component: ResourcesLogisticServices,
  },
  {
    key: UsefulResourcesTypes.Education,
    Component: ResourcesEducation,
  },
  {
    key: UsefulResourcesTypes.Insurance,
    Component: ResourcesInsurance,
  },
  {
    key: UsefulResourcesTypes.Contests,
    Component: ResourcesContests,
  },
];
