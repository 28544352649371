import reduceReducers from "reduce-reducers";
import { ADMIN_ARTICLES_INITIAL_STATE } from "@modules/admin-panel/children/admin-articles/store";
import { articlesReducer } from "@modules/admin-panel/children/admin-articles/reducers/operations";
import { articleCurrentResetReducer } from "@modules/admin-panel/children/admin-articles/reducers/reset-article";

export const adminArticlesRootReducer = reduceReducers(
  ADMIN_ARTICLES_INITIAL_STATE,
  articlesReducer,
  articleCurrentResetReducer
);
