import { FC } from "react";
import { DriverInfo } from "@/common/types/models/user/driver";
import { ILSWideCard } from "@/common/ui-components/data-display/wide-card";
import { useAdminDriverCard } from "@/modules/admin-panel/children/admin-drivers/hooks/use-admin-driver-card";

interface IProps {
  driver: DriverInfo;
  onEditDriver: (driverID: number) => void;
}

export const AdminDriverCard: FC<IProps> = ({ driver, onEditDriver }) => {
  const { info, statuses, actions, avatar, redirectID, profile } =
    useAdminDriverCard({ driver, onEditDriver });

  return (
    <ILSWideCard
      avatar={avatar}
      statuses={statuses}
      info={info}
      actions={actions}
      redirectID={redirectID}
      profile={profile}
    />
  );
};
