import { useAdminQuestionnaire } from "@/modules/admin-panel/children/admin-applications/hooks/questionnaire/use-admin-questionnaire";
import { useAdminQuestionnaireResults } from "@/modules/admin-panel/children/admin-applications/hooks/results/use-admin-questionnaire-results";

export const useAdminApplications = () => {
  const { results, isFetching } = useAdminQuestionnaireResults();

  const {
    documents,
    disableUploadDelete,
    availabilityStatus,
    form,
    questionnaireIsFetching,
    onQuestionnaireAvailabilityChange,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  } = useAdminQuestionnaire();

  return {
    results,
    isFetching,
    documents,
    disableUploadDelete,
    availabilityStatus,
    form,
    questionnaireIsFetching,
    onQuestionnaireAvailabilityChange,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  };
};
