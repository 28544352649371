import "../index.scss";
import { InputNumber, InputNumberProps } from "antd";
import { FC } from "react";
import classNames from "classnames";

export interface IILSInputNumberProps extends InputNumberProps {}

export const ILSInputNumber: FC<IILSInputNumberProps> = ({
  className,
  ...props
}) => {
  return (
    <InputNumber
      type="number"
      {...props}
      className={classNames("ils-input ils-input_number", className)}
    />
  );
};
