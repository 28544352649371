import "./index.scss";
import { FC, PropsWithChildren, RefAttributes } from "react";
import classNames from "classnames";

interface IProps extends PropsWithChildren, RefAttributes<HTMLFieldSetElement> {
  legend: string;
  fieldsetClassName?: string;
  legendClassName?: string;
}

export const ILSFormFieldset: FC<IProps> = ({
  legend,
  children,
  fieldsetClassName,
  legendClassName,
  ...props
}) => {
  return (
    <fieldset
      {...props}
      className={classNames("ils-fieldset", fieldsetClassName)}
    >
      <legend className={classNames("ils-legend", legendClassName)}>
        {legend}
      </legend>
      {children}
    </fieldset>
  );
};
