import { IModuleModel, TModulesObj } from "@/common/types/core/module";

export const flatModules = (modules: TModulesObj): IModuleModel[] => {
  return Object.entries(modules)
    .map(([key, value]) => {
      const res = Array.isArray(value) ? value : [value];
      res.forEach((module) => {
        module.MODULE = key;
      });
      return res;
    })
    .reduce((acc, moduleArr) => acc.concat(moduleArr), []);
};
