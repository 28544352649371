export enum FormFieldName {
  Default = "Default",
  UserID = "UserID",
  /** Наименование организации (для роли organization) */
  AccountName = "AccountName",
  /** Роль создаваемого аккаунта в системе (organization / driver / employee) */
  AccountRole = "AccountRole",
  AccountCode = "AccountCode",
  UserRole = "UserRole",
  Login = "Login",
  Phone = "Phone",
  Email = "Email",
  FullName = "FullName",
  Name = "Name",
  Surname = "Surname",
  Rating = "Rating", // для водителя - просто рейтинг
  SocialRating = "SocialRating", // для организации - социальный рейтинг
  /** Password */
  OldPassword = "OldPassword",
  NewPassword = "NewPassword",
  ConfirmPassword = "Confirm",
  Password = "Password",
  Patronymic = "Patronymic",
  Avatar = "Avatar",
  ConsentToDataProcessing = "ConsentToDataProcessing",
  IsEmployee = "IsEmployee",
  /** ---- Водитель ---- */
  Birthday = "Birthday",
  Sex = "Sex",
  Citizens = "Citizens",
  Age = "Age",
  ActivateAgeField = "ActivateAgeField",
  ShowAvatar = "ShowAvatar",
  PublicOrganizationMembership = "PublicOrganizationMembership",
  MembershipChecked = "MembershipChecked",
  ReadyForTrips = "ReadyForTrips",
  ReadyForRelocation = "ReadyForRelocation",
  JobSearchRegion = "JobSearchRegion",
  WaitingForMembershipCheck = "WaitingForMembershipCheck",
  /** ----- Водительcкие права ----- */
  HaveDriverLicense = "HaveDriverLicense",
  DriverLicense = "DriverLicense",
  DriverLicenseMoreThanThree = "DriverLicenseMoreThanThree",
  DriverLicenseDate = "DriverLicenseDate",
  LicenseCategory = "LicenseCategory",
  EmploymentType = "EmploymentType",
  EducationLevel = "EducationLevel",
  OwnVehicleType = "OwnVehicleType",
  MedicalCertificate = "MedicalCertificate",
  VehicleType = "VehicleType",
  ExperienceYears = "ExperienceYears",
  Organization = "Organization",
  JobSearchStatus = "JobSearchStatus",
  ReadyToStartTomorrow = "ReadyToStartTomorrow",
  /** Организация */
  OrganizationName = "OrganizationName", // todo AccountName
  OrganizationTypeID = "OrganizationTypeID",
  INN = "INN",
  KPP = "KPP",
  OGRN = "OGRN",
  OKPO = "OKPO",
  LegalAddress = "LegalAddress",
  ActualAddress = "ActualAddress",
  PublicOrganizationPartnership = "PublicOrganizationPartnership",
  PartnershipChecked = "PartnershipChecked",
  WaitingForPartnershipCheck = "WaitingForPartnershipCheck",
  /** Учетная запись пользователя */
  WorkingPosition = "WorkingPosition",
  /** Статьи */
  Title = "Title",
  Text = "Text",
  Tags = "Tags",
  CategoryID = "CategoryID",
  Description = "Description",
  Image = "Image",
  /** Вакансия */
  Profession = "Profession",
  LicenseCategories = "LicenseCategories",
  DrivingExperience = "DrivingExperience",
  Years = "Years",
  Months = "Months",
  CompanyHasVehicle = "CompanyHasVehicle",
  Brand = "Brand",
  ProductionYear = "ProductionYear",
  AllowedMaxMass = "AllowedMaxMass",
  LoadCapacity = "LoadCapacity",
  WheelArrangement = "WheelArrangement",
  EngineType = "EngineType",
  EngineCapacity = "EngineCapacity",
  EngineVolume = "EngineVolume",
  EcologyClass = "EcologyClass",
  Gearbox = "Gearbox",
  CabinType = "CabinType",
  VehicleCondition = "VehicleCondition",
  VehiclePhotos = "VehiclePhotos",
  Video = "Video",
  WorkSchedule = "WorkSchedule",
  RegistrationMethod = "RegistrationMethod",
  PaymentTerms = "PaymentTerms",
  PaymentFrequency = "PaymentFrequency",
  Extra = "Extra",
  Address = "Address",
  VacancyPhone = "VacancyPhone",
  Salary = "Salary",
  DesiredSalary = "DesiredSalary",
  From = "From",
  To = "To",
  VacanciesStatus = "VacanciesStatus",
  VacanciesSource = "VacanciesSource",
  Comment = "Comment",
  OrganizationIsVerified = "OrganizationIsVerified",
  JobRegion = "JobRegion",
  /** Анкеты */
  ApplicationStatus = "ApplicationStatus",
  /** Опрос */
  ResidenceRegion = "ResidenceRegion",
  SalaryLevel = "SalaryLevel",
  /** Задать вопрос - "Вопрос президенту", "Обращение в службу поддержки" */
  Question = "Question",
  /** Подтверждение номера телефона */
  VerificationCode = "VerificationCode",
  Code = "Code",
  /** Анкета администратора */
  Questionnaire = "Questionnaire",
  QuestionnaireStatus = "QuestionnaireStatus",
}
