import { FC } from "react";
import { ILSDivider } from "@/common/components/layout/divider";
import {
  DRIVER_DRIVING_FIELDS,
  DRIVER_HAS_DRIVER_LICENSE_FIELD,
  DRIVER_LICENSE_FIELD,
} from "@/common/features/drivers-common/constants/driver-form";
import { RefDataType } from "@/common/features/dictionaries/types";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  refData: RefDataType;
  showDriverLicenseField: boolean;
};

export const AdminEditDriverLicenseSubform: FC<IProps> = ({
  refData,
  showDriverLicenseField,
}) => {
  return (
    <>
      <ILSDivider plain className="admin-edit-driver__divider">
        Водительское удостоверение
      </ILSDivider>
      {renderFormItems({ fields: DRIVER_HAS_DRIVER_LICENSE_FIELD })}
      {showDriverLicenseField &&
        renderFormItems({
          fields: [...DRIVER_DRIVING_FIELDS, DRIVER_LICENSE_FIELD],
          refData,
        })}
    </>
  );
};
