import { isEmpty } from "lodash";
import classNames from "classnames";
import { FC } from "react";
import { ILSRating } from "@/common/ui-components/data-display/rating";
import { IWideCardProfileProps } from "@/common/ui-components/data-display/wide-card/profile";

interface IProps {
  ratings?: Array<number> | undefined;
  profile?: IWideCardProfileProps;
}

export const WideCardRatings: FC<IProps> = ({ ratings = [], profile }) => {
  return (
    <div
      className={classNames("ils-card__ratings", {
        "hide-medium": !isEmpty(profile),
      })}
    >
      {ratings?.map((rating, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <ILSRating key={index} rating={rating} />;
      })}
    </div>
  );
};
