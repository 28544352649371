import { FC } from "react";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import { ILSTypographyLink } from "@/common/components/data-display/link";
import { LogisticServiceType } from "@/modules/social-services/children/resources/types/logistic-services";
import { LOGISTIC_SERVICE_FAST_DELIVERY_TITLE } from "@/modules/social-services/children/resources/constants/logistic-servises";
import { getLinkHref } from "@/modules/social-services/children/resources/helpers/get-link-href";
import { getAnchorBrowserContext } from "@/common/helpers/get-anchor-browser-context";
import { renderLogisticServiceBadge } from "@/modules/social-services/children/resources/helpers/render-logistic-service-badge";

type IProps = {
  service: LogisticServiceType;
};

export const LogisticServicesCard: FC<IProps> = ({ service }) => {
  const isActive = Boolean(service?.link);
  const isFastDelivery = service.title === LOGISTIC_SERVICE_FAST_DELIVERY_TITLE;
  const anchorBrowserContext = getAnchorBrowserContext(service.link);

  return (
    <li key={service.title}>
      <ILSTypographyLink
        disabled={!isActive}
        href={getLinkHref(service.link)}
        target={anchorBrowserContext}
      >
        <div
          className={classNames("logistic-services__card", {
            active: isActive,
            "fast-delivery": isFastDelivery,
          })}
        >
          <div className="logistic-services__card-icon">
            {renderLogisticServiceBadge({ isActive, isFastDelivery })}
            <ReactSVG className="logistic-services__icon" src={service.icon} />
          </div>

          <p>{service.title}</p>
        </div>
      </ILSTypographyLink>
    </li>
  );
};
