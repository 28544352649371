import { FC } from "react";
import { ArticleCard } from "@/modules/admin-panel/children/admin-articles/containers/content/article-card";
import { useAdminArticleList } from "@/common/hooks/articles/use-admin-article-list";

interface IProps {
  showArticleEditorModal: VoidFunction;
}

export const AdminArticlesList: FC<IProps> = ({ showArticleEditorModal }) => {
  const { articles, DeleteArticleModal, onDelete, ObservableElement, onEdit } =
    useAdminArticleList(showArticleEditorModal);

  return (
    <>
      {articles.map((article) => (
        <ArticleCard
          article={article}
          key={article.ID}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
      {ObservableElement}
      {DeleteArticleModal}
    </>
  );
};
