import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { QUESTIONNAIRE_STORE_INITIAL_STATE } from "@/common/features/questionnaire-common/store";
import {
  QuestionnaireOperation,
  QuestionnaireType,
} from "@/common/api/types/questionnaire";
import { questionnaireActionRoutine } from "@/common/features/questionnaire-common/actions";
import { QuestionnaireStore } from "@/common/features/questionnaire-common/types/store";

export const questionnaireReducer = createReducer(
  QUESTIONNAIRE_STORE_INITIAL_STATE,
  {
    [questionnaireActionRoutine.REQUEST]: (state: QuestionnaireStore) => {
      state.questionnaire.isFetching = true;
      return state;
    },
    [questionnaireActionRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{
        operation: QuestionnaireOperation;
        data: QuestionnaireType;
      }>
    ) => {
      const { operation, data } = action.payload;

      switch (operation) {
        case QuestionnaireOperation.get:
        case QuestionnaireOperation.save:
          state.questionnaire.file = data;
          break;

        case QuestionnaireOperation.delete:
          state.questionnaire.file = undefined;
          break;

        case QuestionnaireOperation.makeAvailable:
          if (state.questionnaire.file) {
            state.questionnaire.file.Available = true;
          }
          break;
        case QuestionnaireOperation.makeUnavailable:
          if (state.questionnaire.file) {
            state.questionnaire.file.Available = false;
          }
          break;

        default:
          break;
      }
      return state;
    },
    [questionnaireActionRoutine.FAILURE]: onFailure,
    [questionnaireActionRoutine.FULFILL]: (state: QuestionnaireStore) => {
      state.questionnaire.isFetching = false;
      return state;
    },
  }
);
