import { createSelector } from "reselect";
import { AuthModuleStore } from "@modules/auth/store";
import { SelectApiOption } from "@/common/types/general/common";

export const resetOptionsSelector = createSelector(
  (state: AuthModuleStore) => {
    return state.AuthModule.resetOptions;
  },
  (resetOptions: SelectApiOption[] | []) => resetOptions
);
