import { getMyInfoContentType } from "@/modules/social-services/children/my-info/helpers/utils/get-my-info-content-type";
import { useAuth } from "@/common/hooks/use-auth";

export const useMyInfo = () => {
  const { accountRole } = useAuth();
  const myInfoContentType = getMyInfoContentType(accountRole);

  return {
    myInfoContentType,
  };
};
