import { useMyInfoQuestionnaireContainer } from "@/modules/social-services/children/my-info/hooks/questionnaire/use-my-info-questionnaire-container";
import { ILSSpin } from "@/common/components/data-display/spin";
import { MyInfoQuestionnaireTemplate } from "@/modules/social-services/children/my-info/components/questionnaire/template";
import { MyInfoQuestionnaireResultForm } from "@/modules/social-services/children/my-info/components/questionnaire/result-form";

export const MyInfoQuestionnaireContainer = () => {
  const {
    template,
    isFetching,
    resultDocuments,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  } = useMyInfoQuestionnaireContainer();

  return (
    <ILSSpin spinning={isFetching} tip="Загрузка">
      <div className="my-info__questionnaire">
        <MyInfoQuestionnaireTemplate template={template} />
        <MyInfoQuestionnaireResultForm
          documents={resultDocuments}
          disableUpload={!template?.Available}
          handleCheckBeforeUpload={handleCheckBeforeUpload}
          handleDeleteDocument={handleDeleteDocument}
        />
      </div>
    </ILSSpin>
  );
};
