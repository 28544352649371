import { useNavigate } from "react-router-dom";
import { DeleteFilled, EditFilled, PlusCircleFilled } from "@ant-design/icons";
import { useAbac } from "react-abac";
import { AbacPermission } from "@/common/types/core/abac";
import { UPPER_ROUTE_LEVEL_PATH } from "@/common/constants/general";
import { useModal } from "@/common/hooks/use-modal";
import { Vacancy } from "@/common/types/models/vacancies/model";
import {
  VacancyStatus,
  VacancyStatusID,
} from "@/common/types/models/vacancies/status";
import { IILSButtonConfirmTooltipProps } from "@/common/components/data-display/buttons/confirm-tooltip";
import { useVacancyOperations } from "@/common/features/vacancies-common/hooks/use-vacancy-operations";
import { getVacancyButtonProps } from "@/common/features/vacancies-common/helpers/get-vacancy-button-props";

export const useUserVacancyActions = (vacancy?: Vacancy) => {
  const statusAlias = vacancy?.VacancyStatus?.Alias as VacancyStatus;

  const { userHasPermissions } = useAbac();
  const adminPanel = userHasPermissions(AbacPermission.adminPanel);

  const { handleChangeVacancyStatus, handleDelete } = useVacancyOperations();

  const navigate = useNavigate();
  const navigateToAllVacancies = () => {
    navigate(UPPER_ROUTE_LEVEL_PATH);
  };

  const onSendToModeration = () => {
    handleChangeVacancyStatus(vacancy?.ID, VacancyStatusID.InModeration);
  };

  const onDeleteVacancy = () =>
    handleDelete(vacancy?.ID, navigateToAllVacancies);

  const { isModalOpen, showModal } = useModal();

  const { editButtonProps, deleteButtonProps, moderationButtonProps } =
    getVacancyButtonProps({
      statusAlias,
      adminPanel,
    });

  const userActionButtons: IILSButtonConfirmTooltipProps[] = [
    {
      ...moderationButtonProps,
      children: "Отправить на модерацию",
      block: true,
      danger: true,
      type: "primary",
      icon: <PlusCircleFilled />,
      onConfirm: onSendToModeration,
    },
    {
      ...editButtonProps,
      children: "Редактировать",
      block: true,
      type: "primary",
      icon: <EditFilled />,
      onConfirm: showModal,
    },
    {
      ...deleteButtonProps,
      children: "Удалить",
      block: true,
      icon: <DeleteFilled />,
      onConfirm: onDeleteVacancy,
    },
  ];

  return {
    userActionButtons,
    isVacancyFormModalOpen: isModalOpen,
    setVacancyFormModalOpen: showModal,
  };
};
