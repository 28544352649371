import { FC } from "react";
import { Result } from "antd";
import { ILSButton } from "@/common/components/data-display/buttons";
import {
  VERIFICATION_SUCCESS_TITLE,
  VERIFICATION_SUCCESS_SUBTITLE,
} from "@/modules/social-services/children/my-info/constants";

type IProps = {
  onClose: VoidFunction;
};

export const MyInfoVerificationSuccess: FC<IProps> = ({ onClose }) => {
  return (
    <Result
      status="success"
      title={VERIFICATION_SUCCESS_TITLE}
      subTitle={VERIFICATION_SUCCESS_SUBTITLE}
      extra={[
        <ILSButton type="primary" key="console" onClick={onClose}>
          Закрыть
        </ILSButton>,
      ]}
    />
  );
};
