import { FC } from "react";
import { ILSCard } from "@/common/components/data-display/card";
import { DriverWorkExperience } from "@/common/types/models/user/driver";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { getDurationString } from "@/common/utils/helpers/date-time/get-duration-string";
import { getDictionaryArrayName } from "@/common/helpers/get-dictionary-array-name";

type IProps = {
  experience?: DriverWorkExperience;
  experienceNumber: number;
};

export const DriverDetailsExperienceItem: FC<IProps> = ({
  experience,
  experienceNumber,
}) => {
  return (
    <ILSCard
      title={`${experienceNumber}. Организация: ${experience?.Organization}`}
      style={{ width: "100%" }}
    >
      <DetailDescription
        caption="Тип ТС"
        value={getDictionaryArrayName(experience?.VehicleType)}
      />
      <DetailDescription
        caption="Кол-во лет"
        value={getDurationString("years", experience?.ExperienceYears)}
      />
    </ILSCard>
  );
};
