import { Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { articlesRoutine } from "@modules/admin-panel/children/admin-articles/actions";
import { useModal } from "@/common/hooks/use-modal";
import { Article, ArticleBaseRequest } from "@/common/api/types/article";
import { OperationsEndpoint } from "@/common/api/constants/operations";

export const useDeleteArticle = () => {
  const [articleId, setArticleId] = useState<Article["ID"] | null>(null);
  const dispatch = useDispatch();
  const { showModal, isModalOpen } = useModal();

  const deleteArticle = (id: Article["ID"]) => {
    const payload: ArticleBaseRequest = {
      id,
      operation: OperationsEndpoint.Delete,
    };
    dispatch(articlesRoutine(payload));
  };

  const onDelete = (id: Article["ID"] | null) => {
    setArticleId(id);
    showModal();
  };

  const onConfirmDelete = () => {
    if (articleId) {
      deleteArticle(articleId);
      onDelete(null);
    }
  };

  const DeleteArticleModal = (
    <Modal
      open={isModalOpen}
      title="Данное действие необратимо"
      okButtonProps={{ danger: true }}
      onOk={onConfirmDelete}
      onCancel={showModal}
      centered
      children="Вы уверены, что хотите удалить статью?"
    />
  );

  return {
    onDelete,
    DeleteArticleModal,
  };
};
