import { VacanciesModule } from "@/modules/social-services/children/vacancies";
import { DriverSearchModule } from "@/modules/social-services/children/driver-search";
import { MyInfoModule } from "@/modules/social-services/children/my-info";
import { ContactsModule } from "@/modules/social-services/children/contacts";
import { ProjectsModule } from "@/modules/social-services/children/projects";
import { ResourcesModule } from "@/modules/social-services/children/resources";
import { StoreModule } from "@/modules/social-services/children/store";
import { OrganizationsModule } from "@/modules/social-services/children/organizations";
import { LegalConsultationModule } from "@/modules/social-services/children/legal-consultation";

export const SOCIAL_SERVICES_MODULE_CHILDREN = {
  VacanciesModule,
  DriverSearchModule,
  ResourcesModule,
  ProjectsModule,
  StoreModule,
  ContactsModule,
  MyInfoModule,
  OrganizationsModule,
  LegalConsultationModule,
};
