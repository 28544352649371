import { Persistor } from "redux-persist";
import { PersistConfigModified } from "@/common/types/core/persist";

export const serializeOnModuleChange =
  <S>({
    persistConfig,
    persistor,
  }: {
    persistConfig: PersistConfigModified<S>;
    persistor: Persistor;
  }) =>
  (currentReducersKeys: string[]) => {
    persistConfig.keys = currentReducersKeys;
    persistor.persist();
  };
