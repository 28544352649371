import { MyRatingBlock } from "@/modules/social-services/children/my-info/components/rating/block";
import { useRatingBlocks } from "@/modules/social-services/children/my-info/hooks/rating/use-rating-block";
import { RATING_BLOCKS_LIST } from "@/modules/social-services/children/my-info/constants/rating";

export const MyRatingList = () => {
  const { totalRating, ratingValues } = useRatingBlocks();

  return (
    <li className="my-rating__list">
      {RATING_BLOCKS_LIST.map((ratingType) => (
        <MyRatingBlock
          ratingContentType={ratingType}
          ratingBlockValue={ratingValues[ratingType]?.value}
          ratingTotalValue={totalRating}
          key={ratingType}
        />
      ))}
    </li>
  );
};
