import { EMPTY_ARRAY_DATA, EMPTY_STRING } from "@/common/constants/general";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FORBIDDEN_KEYS } from "@/common/ui-components/data-entry/form/constants/forbidden-keys";
import { PHONE_MASK } from "@/common/constants/config/masks";
import { ORGANIZATION_TYPE_ID_OPTIONS } from "@/common/constants/config/options";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";
import { AccountRole, AccountRoleLabel } from "@/common/types/models/account";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSInputPassword } from "@/common/ui-components/data-entry/input/password";
import { ILSInputMask } from "@/common/components/data-entry/masked-input";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { ILSSelect } from "@/common/ui-components/data-entry/select";

export type AvailableRegisterRole =
  | AccountRole.organization
  | AccountRole.employee
  | AccountRole.driver;

export const COMPANY_VALUE: AvailableRegisterRole[] = [
  AccountRole.driver,
  AccountRole.organization,
];

export const COMPANY_OPTIONS = COMPANY_VALUE.reduce(
  (acc: { value: string; label: string }[], value) => {
    acc.push({
      value,
      label: AccountRoleLabel[value] as string,
    });
    return acc;
  },
  []
);

const inputProps = {
  size: "large",
  placeholder: "",
};

/** поле "Телефон" */
export const REGISTER_PHONE_FIELD = {
  fieldProps: {
    field: FormFieldName.Phone,
    required: true,
  },
  inputProps: { ...inputProps, mask: PHONE_MASK },
  Component: ILSInputMask,
};

/** поле "e-mail" необязательное (для водителя) */
export const REGISTER_EMAIL_FIELD = {
  fieldProps: {
    field: FormFieldName.Email,
    rules: EMPTY_ARRAY_DATA,
  },
  inputProps,
  Component: ILSInput,
};

/** поле "e-mail" обязательное */
export const REGISTER_EMAIL_FIELD_REQUIRED = {
  ...REGISTER_EMAIL_FIELD,
  inputProps: { ...inputProps, forbiddenKeys: FORBIDDEN_KEYS.SPACE },
  fieldProps: {
    required: true,
    field: FormFieldName.Email,
  },
};

/** поле "пароль" */
export const REGISTER_PASSWORD_FIELD = {
  fieldProps: { field: FormFieldName.Password, required: true },
  inputProps: { ...inputProps, forbiddenKeys: FORBIDDEN_KEYS.SPACE },
  Component: ILSInputPassword,
};

/** поле "Являюсь сотрудником зарегистрированной организации" */
export const REGISTER_IS_EMPLOYEE_FIELD = {
  fieldProps: {
    field: FormFieldName.IsEmployee,
    label: EMPTY_STRING,
    valuePropName: "checked",
  },
  inputProps: {
    label: FORM_LABEL.IsEmployee,
    defaultChecked: false,
  },
  Component: ILSCheckbox,
};

/** Поля для роли "Сотрудник" */
export const REGISTER_FIELDS_EMPLOYEE = [
  REGISTER_IS_EMPLOYEE_FIELD,
  REGISTER_PHONE_FIELD,
  REGISTER_EMAIL_FIELD_REQUIRED,
  REGISTER_PASSWORD_FIELD,
  {
    fieldProps: {
      field: FormFieldName.AccountCode,
      required: true,
    },
    inputProps: { ...inputProps, forbiddenKeys: FORBIDDEN_KEYS.SPACE },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.Name,
      required: true,
    },
    inputProps,
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.Surname,
      required: true,
    },
    inputProps,
    Component: ILSInput,
  },
  // todo
] as FormItemsFields;

/** Поля для роли "Организация" */
export const REGISTER_FIELDS_ORGANIZATION = [
  REGISTER_IS_EMPLOYEE_FIELD,
  REGISTER_PHONE_FIELD,
  REGISTER_EMAIL_FIELD_REQUIRED,
  REGISTER_PASSWORD_FIELD,
  {
    fieldProps: {
      field: FormFieldName.OrganizationTypeID,
    },
    inputProps: {
      ...inputProps,
      options: ORGANIZATION_TYPE_ID_OPTIONS,
    },
    Component: ILSSelect,
  },
  {
    fieldProps: { field: FormFieldName.AccountName },
    inputProps,
    Component: ILSInput,
  },
  {
    fieldProps: { field: FormFieldName.INN },
    inputProps: { ...inputProps, forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  // todo
] as FormItemsFields;

/** Поля для роли "Водитель" */
export const REGISTER_DRIVER_FIELDS = [
  REGISTER_PHONE_FIELD,
  REGISTER_EMAIL_FIELD,
  REGISTER_PASSWORD_FIELD,
] as FormItemsFields;
