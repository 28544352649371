import { FC } from "react";
import { ILSCard } from "@/common/components/data-display/card";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { RefDataType } from "@/common/features/dictionaries/types";
import { Salary } from "@/common/components/data-entry/form-item/salary";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import {
  VACANCY_ADDRESS_FIELDS,
  VACANCY_CONTACTS_FIELDS,
  VACANCY_EXTRA_CONDITIONS_FIELDS,
  VACANCY_PAYMENT_FIELDS,
} from "@/common/features/vacancies-common/constants/form";

type IProps = {
  refData: RefDataType;
};

export const VacanciesFormScheduleSubform: FC<IProps> = ({ refData }) => {
  return (
    <div className="vacancies-form__content">
      <ILSCard
        title={
          <div className="vacancies-form__header">
            <ILSTitle level={4}>Условия работы</ILSTitle>
          </div>
        }
        className="vacancies-form__card"
      >
        <div className="vacancies-form__card-body">
          {renderFormItems({ fields: VACANCY_PAYMENT_FIELDS, refData })}
          <Salary />
          {renderFormItems({
            fields: VACANCY_EXTRA_CONDITIONS_FIELDS,
            refData,
          })}
        </div>
      </ILSCard>

      <ILSCard
        title={
          <div className="vacancies-form__header">
            <ILSTitle level={4}>Место работы</ILSTitle>
          </div>
        }
        className="vacancies-form__card"
      >
        <div className="vacancies-form__card-body">
          {renderFormItems({ fields: VACANCY_ADDRESS_FIELDS, refData })}
        </div>
      </ILSCard>

      <ILSCard
        title={
          <div className="vacancies-form__header">
            <ILSTitle level={4}>Контакты</ILSTitle>
          </div>
        }
        className="vacancies-form__card"
      >
        <div className="vacancies-form__card-body">
          {renderFormItems({ fields: VACANCY_CONTACTS_FIELDS })}
        </div>
      </ILSCard>
    </div>
  );
};
