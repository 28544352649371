import { useDispatch } from "react-redux";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import {
  UserBaseRequest,
  UserUpdateBody,
  UsersFilters,
} from "@/common/api/types/user";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { adminOrganizationsUsersRoutine } from "@/modules/admin-panel/children/admin-organizations/actions";

type IProps = UserBaseRequest & {};

export const useAdminUsersOperations = () => {
  const dispatch = useDispatch();

  const onUsersOperation = ({ body, userID, operation, filters }: IProps) => {
    try {
      dispatch(
        adminOrganizationsUsersRoutine({
          body,
          userID,
          operation,
          filters,
        })
      );
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  /**
   * Получить всех пользователей организации
   * @param filters
   */
  const handleGetUsers = (filters: UsersFilters) => {
    return onUsersOperation({
      operation: OperationsEndpoint.GetAll,
      filters,
    });
  };

  /**
   * Обновить пользователя организации
   * @param body
   * @param userID
   */
  const handleUpdateUser = ({
    body,
    userID,
  }: {
    body: UserUpdateBody;
    userID: number | undefined;
  }) => {
    return onUsersOperation({
      operation: OperationsEndpoint.Update,
      userID,
      body,
    });
  };

  /**
   * Удалить пользователя организации
   * @param userID
   */
  const handleDeleteUser = ({ userID }: { userID: number | undefined }) => {
    return onUsersOperation({
      operation: OperationsEndpoint.Delete,
      userID,
    });
  };

  return { handleGetUsers, handleUpdateUser, handleDeleteUser };
};
