import { VacancyCommentFormValues } from "@/common/features/vacancy/types";
import { VacancyStatusID } from "@/common/types/models/vacancies/status";
import { useVacancyOperations } from "@/common/features/vacancies-common/hooks/use-vacancy-operations";

export const useVacancyCommentForm = (
  vacancyId: number | undefined,
  showModal: VoidFunction
) => {
  const { handleChangeVacancyStatus } = useVacancyOperations();

  const onSubmit = (values: VacancyCommentFormValues) => {
    handleChangeVacancyStatus(
      vacancyId,
      VacancyStatusID.Rejected,
      values.Comment
    );
    showModal();
  };

  return { onSubmit };
};
