import "./index.scss";
import { FC } from "react";
import { UserOutlined } from "@ant-design/icons";
import { COLOR_PALETTE } from "@/styles/color-palette";
import { EMPTY_ARRAY_DATA, EMPTY_STRING } from "@/common/constants/general";
import { User } from "@/common/types/models/user/user";
import { ILSCard } from "@/common/components/data-display/card";
import { ILSAvatar } from "@/common/ui-components/data-display/avatar";
import { ILSDivider } from "@/common/components/layout/divider";
import { UserCardHeader } from "@/common/components/data-display/user-card/header";
import {
  UserCardActions,
  UserCardActionsType,
} from "@/common/types/models/user/card-actions";
import { renderUserCardActions } from "@/common/utils/helpers/components/user-card/render-user-card-actions";

type IProps = {
  user: User;
  userCardActions: UserCardActionsType;
  isCurrentUserCard: boolean;
};

export const UserCard: FC<IProps> = ({
  user,
  userCardActions,
  isCurrentUserCard = false,
}) => {
  const avatar = user?.Avatar?.FileUrl;

  /* TODO: сейчас удаленные пользователи возвращаются в списке пользователей; */
  const userIsDeleted = Boolean(user?.Deleted);
  const userIsBlocked = Boolean(user?.Blocked);

  const actions = renderUserCardActions({
    userId: user.ID,
    userCardActions,
    extraDisabledConditions: {
      [UserCardActions.Delete]: userIsDeleted,
      [UserCardActions.Edit]: userIsDeleted || userIsBlocked,
    },
    userIsBlocked,
  });

  return (
    <li className="user-card">
      <ILSCard
        title={<UserCardHeader user={user} />}
        actions={isCurrentUserCard ? EMPTY_ARRAY_DATA : actions}
        size="default"
      >
        <div className="user-card-content">
          <ILSAvatar
            icon={!avatar ? <UserOutlined /> : EMPTY_STRING}
            src={avatar}
            size={50}
          />
          <div className="user-card-details">
            <div className="user-card-details-group">
              <p>Телефон:</p>
              <p>{user.Phone}</p>
            </div>
            <ILSDivider className="user-card-divider" />
            <div className="user-card-details-group">
              <p>Email:</p>
              <p>{user.Email}</p>
            </div>

            {/* TODO: сейчас удаленные пользователи возвращаются в списке пользователей; */}
            {userIsDeleted && (
              <>
                <ILSDivider className="user-card-divider" />
                <p style={{ color: COLOR_PALETTE.accent }}>
                  Пользователь удален
                </p>
              </>
            )}

            {userIsBlocked && (
              <>
                <ILSDivider className="user-card-divider" />
                <p style={{ color: COLOR_PALETTE.accent }}>
                  Пользователь заблокирован
                </p>
              </>
            )}
          </div>
        </div>
      </ILSCard>
    </li>
  );
};
