import { FC } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ModulePath } from "@/common/types/core/routes";
import { ILSLink } from "@/common/ui-components/general/typography/link";

type IProps = {
  prevVacancyLocation: string | undefined;
};

export const OrganizationBackLink: FC<IProps> = ({ prevVacancyLocation }) => {
  const text = prevVacancyLocation
    ? "Вернуться к вакансии"
    : "Перейти ко всем вакансиям";

  const path = prevVacancyLocation
    ? `${prevVacancyLocation}`
    : `/${ModulePath.socialServices}/${ModulePath.vacancies}`;

  return (
    <ILSLink to={path} relative="path" className="organization__back-link">
      <ArrowLeftOutlined />
      {text}
    </ILSLink>
  );
};
