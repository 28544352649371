import {
  EMPTY_ARRAY_DATA,
  EMPTY_OBJECT_DATA,
} from "@/common/constants/general";
import { DriversStore } from "@/common/features/drivers-common/types/store";

export const DRIVERS_INITIAL_STATE: DriversStore = {
  error: EMPTY_ARRAY_DATA,
  isFetching: false,
  drivers: EMPTY_OBJECT_DATA,
};
