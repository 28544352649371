import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PUBLIC_ORGANIZATION_CHECK_REQUIRED_ICON } from "@/common/features/drivers-common/constants";
import { ILSMembershipBadge } from "@/common/components/custom/membership-badge";

type IProps = {
  hasConfirmedMembership: boolean;
  waitingMembershipCheck: boolean;
  adminPanel: boolean;
};

/**
 * функция проверяет, какую иконку или компонент разместить
 * в заголовке карточки водителя;
 */
export const getDriverMembershipBadge = ({
  hasConfirmedMembership,
  waitingMembershipCheck,
  adminPanel,
}: IProps) => {
  switch (true) {
    case hasConfirmedMembership:
      return <ILSMembershipBadge />;
    case waitingMembershipCheck && adminPanel:
      return (
        <ExclamationCircleOutlined
          style={PUBLIC_ORGANIZATION_CHECK_REQUIRED_ICON}
        />
      );
    default:
      return null;
  }
};
