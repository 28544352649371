import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { FORBIDDEN_KEYS } from "@/common/ui-components/data-entry/form/constants/forbidden-keys";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";
import { ILSInputPassword } from "@/common/ui-components/data-entry/input/password";
import { RecoveryProcess } from "@/common/api/types/auth";
import { PHONE_MASK } from "@/common/constants/config/masks";
import { ILSInputMask } from "@/common/components/data-entry/masked-input";

export const RECOVERY_FORM_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.Login,
    },
    inputProps: {
      mask: PHONE_MASK,
    },
    Component: ILSInputMask,
  },
] as FormItemsFields;

export const CHECK_RECOVERY_PASSWORD_FORM_FIELDS = [
  ...RECOVERY_FORM_FIELDS,
  {
    fieldProps: {
      field: FormFieldName.Code,
    },
    inputProps: {
      forbiddenKeys: FORBIDDEN_KEYS.SPACE,
    },
    Component: ILSInput,
  },
] as FormItemsFields;

export const RESET_PASSWORD_FORM_FIELDS = [
  ...CHECK_RECOVERY_PASSWORD_FORM_FIELDS,
  {
    fieldProps: {
      field: FormFieldName.Password,
    },
    inputProps: {
      forbiddenKeys: FORBIDDEN_KEYS.SPACE,
    },
    Component: ILSInputPassword,
  },
] as FormItemsFields;

export const RECOVERY_FORMS: Record<
  RecoveryProcess,
  {
    formName: string;
    formTitle: string;
    formFields: FormItemsFields;
    content: string;
  }
> = {
  password: {
    formName: "auth-form_reset-password",
    formTitle: "Восстановить пароль",
    formFields: RESET_PASSWORD_FORM_FIELDS,
    content: "Задайте новый пароль для входа в аккаунт",
  },
  recovery: {
    formName: "auth-form_recovery-password",
    formTitle: "Восстановить пароль",
    formFields: RECOVERY_FORM_FIELDS,
    content: `Укажите свой логин (телефонный номер), под которым
        вы&nbsp;зарегистрированы на&nbsp;сайте и&nbsp;на&nbsp;него будет
        отправлена информация о&nbsp;восстановлении пароля.`,
  },
  code: {
    formName: "auth-form_check-recovery-password",
    formTitle: "Восстановить пароль",
    formFields: CHECK_RECOVERY_PASSWORD_FORM_FIELDS,
    content: "Введите код из смс",
  },
};
