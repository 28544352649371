import { isNil } from "lodash";
import { UploadFile } from "antd";
import { DEFAULT_ORGANIZATION_TYPE_ID_VALUE } from "@/common/constants/config/options";
import { DeletedImageRequest } from "@/common/types/models/image";
import { OrganizationUpdateRequest } from "@/common/api/types/organization";
import { AdminOrganizationFormValues } from "@/modules/admin-panel/children/admin-organizations/children/organization/types/organization-form";
import { getFormImage } from "@/common/helpers/get-account-avatar";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";

type IProps = {
  formValues: AdminOrganizationFormValues;
  fileList: UploadFile[] | undefined;
  removedSavedFiles: DeletedImageRequest[];
};

export const formValuesToAdminOrganization = async ({
  formValues,
  fileList,
  removedSavedFiles,
}: IProps): Promise<OrganizationUpdateRequest> => {
  const Avatar = await getFormImage(fileList, removedSavedFiles);

  const PartnershipChecked = !isNil(formValues?.PartnershipChecked)
    ? Boolean(formValues?.PartnershipChecked)
    : null;

  return {
    ...formValues,
    Name: formValues.OrganizationName,
    OrganizationTypeID:
      formValues.OrganizationTypeID || DEFAULT_ORGANIZATION_TYPE_ID_VALUE,
    Phone: getUnmaskedPhone(formValues?.Phone),
    PartnershipChecked,
    Avatar,
  };
};
