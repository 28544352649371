import { FC } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  IILSInputProps,
  ILSInput,
} from "@/common/ui-components/data-entry/input/text";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { showCopiedWithSuccessNotification } from "@/common/ui-components/data-entry/input/copy/helpers/show-copied-with-success-notification";

export const ILSInputCopy: FC<
  IILSInputProps & { copyText: string; field: FormFieldName }
> = ({ copyText, field }) => {
  return (
    <ILSFormItem field={field} name={field as never}>
      <CopyToClipboard
        text={copyText}
        onCopy={showCopiedWithSuccessNotification}
      >
        <ILSInput />
      </CopyToClipboard>
    </ILSFormItem>
  );
};
