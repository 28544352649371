import { ILSDivider } from "@/common/components/layout/divider";
import { StartPageFooterList } from "@/modules/start/components/footer/list";
import { StartPageSiderBanner } from "@/modules/start/components/sider-banners/sider-banner/sider-banner";
import { SiderBannerTitle } from "@/modules/start/components/sider-banners/sider-banner-title/sider-banner-title";

export const StartPageFooterMobile = () => {
  return (
    <StartPageSiderBanner className="sider-banner_footer-mobile">
      <div className="start-page__footer start-page__footer_mobile">
        <SiderBannerTitle>Следите за новостями</SiderBannerTitle>
        <ILSDivider className="start-page__footer-divider_mobile" />
        <StartPageFooterList />
      </div>
    </StartPageSiderBanner>
  );
};
