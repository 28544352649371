import { FC } from "react";
import { Form } from "antd";
import Title from "antd/es/typography/Title";
import { AuthButton } from "@modules/auth/components/button";
import { RECOVERY_FORMS } from "@modules/auth/children/recovery/constants";
import { isEmpty } from "lodash";
import { useRecovery } from "@modules/auth/children/recovery/hooks/use-recovery";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSSelect } from "@/common/ui-components/data-entry/select";
import { renderContentWithNbsp } from "@/common/utils/helpers/string/render-content-with-nbsp";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const Recovery: FC = () => {
  const { onFinish, recoveryProcess, resetOptions } = useRecovery();

  if (!recoveryProcess) return null;

  const { formName, formTitle, formFields, content } =
    RECOVERY_FORMS[recoveryProcess];

  return (
    <Form
      name={formName}
      onFinish={onFinish}
      layout="vertical"
      className={`auth-form ${formName}`}
    >
      <Title className="auth-title" children={formTitle} />
      <p>{renderContentWithNbsp(content)}</p>
      {renderFormItems({
        fields: isEmpty(resetOptions)
          ? formFields
          : ([
              ...formFields,
              {
                fieldProps: {
                  field: FormFieldName.UserID,
                },
                inputProps: {
                  options: resetOptions,
                },
                Component: ILSSelect,
              },
            ] as FormItemsFields),
      })}
      <AuthButton form={formName}>Продолжить</AuthButton>
      {recoveryProcess === "code" && "Код можно запрашивать раз в 10 минут"}
    </Form>
  );
};
