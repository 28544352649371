import { UploadFileStatus } from "antd/es/upload/interface";
import { IFileType } from "@/common/types/models/file";

export const getQuestionnaireToForm = (file: IFileType) => {
  return {
    uid: file?.ID?.toString(),
    name: file.OriginalName,
    status: "done" as UploadFileStatus,
    url: file.FileUrl,
  };
};
