import { User } from "@/common/types/models/user/user";
import { GeneralState } from "@/common/types/core/store";
import { IndexedCollection } from "@/common/types/general/common";

export type MyInfoStore = GeneralState & {
  users: IndexedCollection<User>;
  verification: {
    target: string | null;
    isFetching: boolean;
  };
};

export type MyInfoModuleStore = { MyInfoModule: MyInfoStore };

export const MY_INFO_INITIAL_STATE: MyInfoStore = {
  error: [],
  isFetching: false,
  users: {},
  verification: {
    target: null,
    isFetching: false,
  },
};
