import { EnhancedStore } from "@reduxjs/toolkit";
import { Reducer } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { createPersistedReducer } from "./create-persisted-reducer";
import { createPersistor } from "./create-persistor";
import { renderPersistWrapper } from "./render-persist-wrapper";
import { PersistConfigModified } from "@/common/types/core/persist";
import { GetDefaultMiddlewareOptions } from "@/common/types/core/redux";
import { serializeOnModuleChange } from "./serialize-on-module-change";

export const PERSIST_MIDDLEWARE_OPTIONS: GetDefaultMiddlewareOptions = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
};

export const createPersistedStore = <S>(
  reducer: Reducer,
  createStore: (
    reducer: Reducer,
    options: GetDefaultMiddlewareOptions
  ) => EnhancedStore,
  persistConfig: PersistConfigModified<S>
) => {
  const persistedReducer = createPersistedReducer<S>(persistConfig, reducer);
  const store = createStore(persistedReducer, PERSIST_MIDDLEWARE_OPTIONS);
  const persistor = createPersistor<S>(store);

  serializeOnModuleChange({ persistConfig, persistor });
  const Wrapper = renderPersistWrapper(persistor);

  return { store, onModuleChange: serializeOnModuleChange, Wrapper, persistor };
};
