import { head, isArray } from "lodash";
import { useEffect } from "react";
import { useAbac } from "react-abac";
import { useDispatch } from "react-redux";
import { useAccount } from "@/common/hooks/use-account";
import { DEFAULT_ZERO_RATING } from "@/common/constants/config/rating";
import { OrganizationInfo } from "@/common/types/models/account";
import { AbacPermission } from "@/common/types/core/abac";
import { getOrganizationActions } from "@/modules/social-services/children/my-info/actions";
import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";

export const useOrganizationPermissions = () => {
  const { userHasPermissions } = useAbac();
  const isOrganization = userHasPermissions(AbacPermission.organization);
  return { isOrganization };
};

type IProps = {
  activeTab?: MyInfoTabs;
};

export const useOrganizationAccount = ({ activeTab }: IProps) => {
  const { accountName, user } = useAccount();
  const { isOrganization } = useOrganizationPermissions();
  const { Organization } = user.Account;
  const organizationInfo: Partial<OrganizationInfo> = isArray(Organization)
    ? head(Organization)
    : Organization;
  const accountCode = user.Account.AccountCode;
  const organizationSocialRating =
    organizationInfo?.SocialRating || DEFAULT_ZERO_RATING;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isOrganization && activeTab === MyInfoTabs.MyOrganization) {
      dispatch(
        getOrganizationActions({ organizationID: organizationInfo?.ID })
      );
    }
  }, [activeTab]);

  const profileInfo = {
    accountName,
    phone: isOrganization ? user.Phone : organizationInfo?.Phone,
    accountCode,
  };

  return {
    isOrganization,
    organizationInfo,
    organizationSocialRating,
    profileInfo,
  };
};
