import { createSelector } from "reselect";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { IndexedCollection } from "@/common/types/general/common";
import { AdminOrganizationsModuleStore } from "@/modules/admin-panel/children/admin-organizations/types/store";
import { User } from "@/common/types/models/user/user";
import { getObjectValuesWithType } from "@/common/utils/helpers/types";

export const adminOrganizationUsersSelector = createSelector(
  (state: AdminOrganizationsModuleStore) => {
    return state.AdminOrganizationsModule?.users;
  },
  (employees: IndexedCollection<User>) =>
    getObjectValuesWithType<User>(employees) || (EMPTY_ARRAY_DATA as User[])
);

export const adminOrganizationUserSelector = (userID: number | null) =>
  createSelector(
    (state: AdminOrganizationsModuleStore) => {
      return state.AdminOrganizationsModule?.users;
    },
    (employees: IndexedCollection<User>) => {
      if (userID) {
        return employees[userID];
      }
      return null;
    }
  );
