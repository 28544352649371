import { UploadFile } from "antd";
import { isEmpty } from "lodash";
import {
  QuestionnaireResultType,
  QuestionnaireType,
  isQuestionnaireResult,
} from "@/common/api/types/questionnaire";
import { getQuestionnaireToForm } from "@/common/features/questionnaire-common/helpers/get-questionnaire-to-form";

export const getQuestionnaireResultsUploadFiles = (
  results: (QuestionnaireResultType | QuestionnaireType)[]
): UploadFile[] | undefined => {
  if (isEmpty(results) || !results) {
    return undefined;
  }

  return results.map((result) => {
    const isResult = isQuestionnaireResult(result);
    return getQuestionnaireToForm({
      ...result?.File,
      ID: isResult ? result?.ID : result?.File?.ID,
    });
  });
};
