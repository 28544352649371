import { ModulePath } from "@/common/types/core/routes";
import { getPhoneHref } from "@/common/utils/helpers/string/get-phone-href";
import { StartPageUsefulLinkProps } from "@/modules/start/types";

export const startPageUsefulLinks: Array<StartPageUsefulLinkProps> = [
  {
    title: "Пожаловаться / предложить",
    pic: `${process.env.PUBLIC_URL}/assets/images/start-page/useful-links/useful_link_0.png`,
    url: `${ModulePath.socialServices}/${ModulePath.contacts}`,
  },
  {
    title: "Наш ТГ",
    pic: `${process.env.PUBLIC_URL}/assets/images/start-page/useful-links/useful_link_1.png`,
    url: "https://t.me/driversrussia",
  },
  {
    title: "Помощь на дороге",
    pic: `${process.env.PUBLIC_URL}/assets/images/start-page/useful-links/useful_link_2.png`,
    url: getPhoneHref("112") || "https://t.me/driversrussia",
  },
  {
    title: "Юридическая консультация",
    pic: `${process.env.PUBLIC_URL}/assets/images/start-page/useful-links/useful_link_3.png`,
    url: `${ModulePath.socialServices}/${ModulePath.legalConsultation}`,
  },
  {
    title: "Памятка по ДМС",
    pic: `${process.env.PUBLIC_URL}/assets/images/start-page/useful-links/useful_link_4.png`,
    url: ModulePath.underConstruction,
  },
  {
    title: "Актуальные вакансии",
    pic: `${process.env.PUBLIC_URL}/assets/images/start-page/useful-links/useful_link_5.png`,
    url: `${ModulePath.socialServices}/${ModulePath.vacancies}`,
  },
];

export const USEFUL_LINK_PIC_SIZE = 66;

export const FOOTER_LINKS_URL = {
  youtube: "https://www.youtube.com/@truckfestrussia",
  telegram: "https://t.me/driversrussia/",
  vk: "https://vk.com/driversrussia",
  website: "https://driversrussia.ru/",
};

export const FOOTER_LINK_ICON_SIZE = {
  width: "30",
  height: "30",
};
