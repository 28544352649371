import "./index.scss";
import { CSSProperties, FC } from "react";
import classNames from "classnames";
import { DEFAULT_ZERO_RATING } from "@/common/constants/config/rating";
import { getRatingStrokeColor } from "@/common/helpers/get-rating-stroke-color";

export type Color = CSSProperties["color"];
export type BackgroundColor = CSSProperties["backgroundColor"];

interface IProps {
  className?: string;
  rating?: number | null;

  getRatingColor?(rating: number): BackgroundColor;
}

export const ILSRating: FC<IProps> = ({
  rating = DEFAULT_ZERO_RATING,
  className,
  getRatingColor = getRatingStrokeColor,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames("ils-rating", className)}
      style={{ background: getRatingColor?.(rating || DEFAULT_ZERO_RATING) }}
    >
      {rating}
    </div>
  );
};
