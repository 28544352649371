import { useState } from "react";
import { UserCardActions } from "@/common/types/models/user/card-actions";

/**
 * Хук управляет действиями в карточке пользователя:
 * - редактирование;
 * - удаление;
 * - блокировка;
 */
export const useUserCardActionType = () => {
  const [actionType, setActionType] = useState<UserCardActions | null>(null);

  const handleChangeActionType = (action: UserCardActions | null) => {
    setActionType(action);
  };

  const resetActionType = () => setActionType(null);

  return { actionType, handleChangeActionType, resetActionType };
};
