import { isEmpty } from "lodash";
import { MyInfoContentType } from "@/modules/social-services/children/my-info/types";
import { AccountRole } from "@/common/types/models/account";

export const getMyInfoContentType = (
  userRoles?: Array<AccountRole>
): MyInfoContentType | null => {
  if (!userRoles || isEmpty(userRoles)) return null;

  switch (true) {
    case userRoles.includes(AccountRole.driver):
      return MyInfoContentType.Driver;
    case userRoles.includes(AccountRole.organization):
      return MyInfoContentType.Organization;
    case userRoles.includes(AccountRole.employee):
      return MyInfoContentType.Account;
    default:
      return null;
  }
};
