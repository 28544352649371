import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import { articlesSelector } from "@modules/admin-panel/children/admin-articles/selectors/articles";
import { articlesRoutine } from "@modules/admin-panel/children/admin-articles/actions";
import { OperationsEndpoint } from "@/common/api/constants/operations";

export const useCommonArticles = () => {
  const articles = useSelector(articlesSelector);

  const dispatch = useDispatch();

  // Срабатывает при появлении блока ObservableElement       // todo
  const fetchArticles = useCallback((inView: boolean) => {
    /*
      if (inView) {
        // TODO fetch articles
            dispatch(articlesRoutine({ operation: OperationsEndpoint.GetAll }));
      }

    */
  }, []);

  const { ref: observableElement } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: fetchArticles,
  });

  // todo Type (node?: (Element | null)) => void is not assignable to type LegacyRef<HTMLDivElement> | undefined
  const ObservableElement = (
    <div ref={observableElement} style={{ width: "100%", height: "20px" }} />
  );

  useEffect(() => {
    dispatch(articlesRoutine({ operation: OperationsEndpoint.GetAll }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { articles, ObservableElement };
};
