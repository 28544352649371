import "../index.scss";
import { FC } from "react";
import { ILSTabs, ILSTabPane } from "@/common/components/data-display/tabs";
import {
  USEFUL_RESOURCES_TABS,
  USEFUL_RESOURCES_TITLES,
} from "@/modules/social-services/children/resources/constants/tabs";

interface IProps {}

export const Resources: FC<IProps> = () => {
  return (
    <div className="resources">
      <ILSTabs type="card" tabPosition="top" size="large">
        {USEFUL_RESOURCES_TABS.map((tab) => {
          const { Component, key } = tab;
          return (
            <ILSTabPane tab={USEFUL_RESOURCES_TITLES[key]} key={key}>
              <Component />
            </ILSTabPane>
          );
        })}
      </ILSTabs>
    </div>
  );
};
