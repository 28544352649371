import { useDispatch } from "react-redux";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { Dictionaries } from "@/common/types/models/dictionary";
import { getDictionaryRoutine } from "@/common/features/dictionaries/actions";

type IProps = {
  dictionary?: Dictionaries;
};

export const useGetDictionary = () => {
  const dispatch = useDispatch();

  const handleGetDictionary = ({ dictionary }: IProps) => {
    try {
      dispatch(getDictionaryRoutine(dictionary));
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  return { handleGetDictionary };
};
