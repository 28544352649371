import { Popover } from "antd";
import { ILSHeaderMenu } from "@/common/components/layout/header/menu/header-menu";
import { ILSBurger } from "@/common/components/custom/burger/burger";
import { useHeaderBurgerMenu } from "@/common/hooks/header/use-header-burger-menu";

export const ILSHeaderBurger = () => {
  const { open, menuItems, handleOpenChange, onClickClosePopover } =
    useHeaderBurgerMenu();

  return (
    <Popover
      placement="bottomLeft"
      content={
        <ILSHeaderMenu menuItems={menuItems} onClick={onClickClosePopover} />
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <div className="header-component__burger">
        <ILSBurger open={open} />
      </div>
    </Popover>
  );
};
