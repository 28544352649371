import { FC } from "react";
import { Form, FormInstance } from "antd";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { MY_INFO_FORM_NAMES } from "@/modules/social-services/children/my-info/constants/form";
import { ILSButton } from "@/common/components/data-display/buttons";
import {
  IILSFormAvatarProps,
  ILSFormAvatar,
} from "@/common/components/custom/form-avatar";
import { AccountFormValues } from "@/modules/social-services/children/my-info/types/form";
import { MyInfoFormAccount } from "@/modules/social-services/children/my-info/components/subforms/account";

type IProps = IILSFormAvatarProps & {
  form: FormInstance<AccountFormValues>;
  initialValues: AccountFormValues | null;
  disabled: boolean;
  enableSubmit: VoidFunction;
  handleSubmit: (values: AccountFormValues) => void;
  handleCancelChange: VoidFunction;
};

export const AccountInfoForm: FC<IProps> = ({
  form,
  initialValues,
  fileList,
  disabled,
  enableSubmit,
  handleSubmit,
  handleCancelChange,
  handleAvatarChange,
  handleAvatarRemove,
}) => {
  return (
    <Form
      disabled={disabled}
      onFieldsChange={enableSubmit}
      form={form}
      layout="vertical"
      className="my-info-form"
      name={MY_INFO_FORM_NAMES.ACCOUNT}
      initialValues={initialValues || EMPTY_OBJECT_DATA}
      onFinish={handleSubmit}
    >
      <div className="my-info-form__column">
        <ILSFormAvatar
          fileList={fileList}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
      </div>

      <div className="my-info-form__column my-info-form__column_wide">
        <MyInfoFormAccount />
        <div className="my-info-form__buttons">
          <ILSButton onClick={handleCancelChange} disabled={disabled}>
            Отмена
          </ILSButton>
          <ILSButton type="primary" htmlType="submit" disabled={disabled}>
            Сохранить
          </ILSButton>
        </div>
      </div>
    </Form>
  );
};
