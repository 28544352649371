import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { VacancyFormValues } from "@/common/features/vacancies-common/types/form";
import { getProductionYear } from "@/common/features/vacancies-common/helpers/get-production-year";
import { formatPriceString } from "@/common/utils/helpers/price/format-price-string";
import { RefDataType } from "@/common/features/dictionaries/types";
import { getImagesToForm } from "@/common/utils/helpers/components/avatar/get-images-to-form";
import { getDictionaryTypeIDs } from "@/common/features/vacancies-common/helpers/get-dictionary-type-ids";

interface IProps {
  vacancy?: Vacancy;
  refData?: RefDataType;
}

/**
 * TODO: GET запрос возвращает строки. Напр.
 * RegistrationMethod: ["трудовой договор", "договор гпх с ип"]
 * Для того, чтобы найти начальные значения нужно делать поиск по label в options,
 * чтобы в initial value передать ID.
 */

export const vacancyToFormValues = ({
  vacancy,
}: IProps): {} | VacancyFormValues => {
  if (!vacancy) {
    return {};
  }

  return {
    /** ---- Шаг 1: Описание вакансии ---- */
    [FormFieldName.Title]: vacancy[FormFieldName.Title],
    [FormFieldName.Description]: vacancy[FormFieldName.Description],
    [FormFieldName.Profession]: vacancy.Details?.[FormFieldName.Profession],
    [FormFieldName.LicenseCategory]: getDictionaryTypeIDs(
      vacancy?.Details?.LicenseCategory
    ),
    [FormFieldName.CompanyHasVehicle]: Boolean(vacancy?.Vehicle?.Own),
    [FormFieldName.DrivingExperience]: {
      [FormFieldName.Years]: vacancy?.Details?.DrivingExperience?.Year,
      [FormFieldName.Months]: vacancy?.Details?.DrivingExperience?.Month,
    },

    /** ---- Шаг 2: Транспортное средство ---- */
    [FormFieldName.Brand]: getDictionaryTypeIDs(
      vacancy?.Vehicle?.[FormFieldName.Brand]
    ),
    [FormFieldName.VehicleType]: getDictionaryTypeIDs(vacancy?.Vehicle?.Type),
    [FormFieldName.ProductionYear]: getProductionYear(
      vacancy?.Vehicle?.[FormFieldName.ProductionYear]
    ),
    [FormFieldName.AllowedMaxMass]: vacancy?.Vehicle?.Profile?.MaxMass,
    [FormFieldName.LoadCapacity]:
      vacancy?.Vehicle?.Profile?.[FormFieldName.LoadCapacity],
    [FormFieldName.WheelArrangement]: getDictionaryTypeIDs(
      vacancy?.Vehicle?.Profile?.[FormFieldName.WheelArrangement]
    ),
    [FormFieldName.EngineType]: getDictionaryTypeIDs(
      vacancy?.Vehicle?.Profile?.[FormFieldName.EngineType]
    ),
    [FormFieldName.EngineCapacity]:
      vacancy?.Vehicle?.Profile?.[FormFieldName.EngineCapacity],
    [FormFieldName.EngineVolume]:
      vacancy?.Vehicle?.Profile?.[FormFieldName.EngineVolume],
    [FormFieldName.EcologyClass]: getDictionaryTypeIDs(
      vacancy?.Vehicle?.Profile?.[FormFieldName.EcologyClass]
    ),
    [FormFieldName.Gearbox]: getDictionaryTypeIDs(
      vacancy?.Vehicle?.Profile?.[FormFieldName.Gearbox]
    ),
    [FormFieldName.CabinType]: getDictionaryTypeIDs(
      vacancy?.Vehicle?.Profile?.[FormFieldName.CabinType]
    ),
    [FormFieldName.VehicleCondition]: getDictionaryTypeIDs(
      vacancy?.Vehicle?.Profile?.Condition
    ),
    [FormFieldName.VehiclePhotos]: getImagesToForm(
      vacancy?.Vehicle?.Profile?.Image?.Data
    ),
    [FormFieldName.Video]: vacancy?.Vehicle?.Profile?.[FormFieldName.Video],

    /** ---- Шаг 3: Условия работы ---- */
    [FormFieldName.WorkSchedule]: getDictionaryTypeIDs(
      vacancy.Payment?.WorkSchedule
    ),
    [FormFieldName.RegistrationMethod]: getDictionaryTypeIDs(
      vacancy?.[FormFieldName.RegistrationMethod]
    ),
    [FormFieldName.PaymentTerms]: getDictionaryTypeIDs(vacancy?.Payment?.Term),
    [FormFieldName.Salary]: {
      [FormFieldName.From]: formatPriceString(
        vacancy?.Payment?.Price?.[FormFieldName.From]
      ),
      [FormFieldName.To]: formatPriceString(
        vacancy?.Payment?.Price?.[FormFieldName.To]
      ),
    },
    [FormFieldName.PaymentFrequency]: getDictionaryTypeIDs(
      vacancy?.Payment?.Frequency
    ),
    [FormFieldName.Extra]: getDictionaryTypeIDs(vacancy?.Extra),
    [FormFieldName.Address]: vacancy?.Info?.[FormFieldName.Address],
    [FormFieldName.JobRegion]: getDictionaryTypeIDs(vacancy.JobRegion),
    [FormFieldName.VacancyPhone]: vacancy?.Info?.Phone,
  };
};
