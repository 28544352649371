import { useState } from "react";
import { useAccount } from "@/common/hooks/use-account";
import { AccountRole } from "@/common/types/models/account";
import { VacanciesDriverTabs } from "@/modules/social-services/children/vacancies/types/tabs";
import { ModulePath } from "@/common/types/core/routes";
import { useShowIndexModule } from "@/common/hooks/use-show-index-module";

export const useVacanciesModule = () => {
  const [vacanciesDriverTab, setVacanciesDriverTab] =
    useState<VacanciesDriverTabs>(VacanciesDriverTabs.Vacancies);
  const handleChangeTab = (key: string) =>
    setVacanciesDriverTab(key as VacanciesDriverTabs);

  const { showIndexModule, dynamicElementID } = useShowIndexModule(
    ModulePath.vacancy
  );
  const vacancyId = dynamicElementID;

  const { accountRole } = useAccount();
  const isDriver = accountRole.includes(AccountRole.driver);

  return {
    showIndexModule,
    vacancyId,
    isDriver,
    vacanciesDriverTab,
    handleChangeTab,
  };
};
