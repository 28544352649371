import { FC } from "react";
import { ILSCard } from "@/common/components/data-display/card";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { RefDataType } from "@/common/features/dictionaries/types";
import { VACANCY_DESCRIPTION_FIELDS } from "@/common/features/vacancies-common/constants/form";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { DrivingExperience } from "@/common/components/data-entry/form-item/driving-experience";

type IProps = {
  refData: RefDataType;
};

export const VacanciesFormDescriptionSubform: FC<IProps> = ({ refData }) => {
  return (
    <div className="vacancies-form__content">
      <ILSCard
        title={
          <div className="vacancies-form__header">
            <ILSTitle level={4}>Общее описание вакансии</ILSTitle>
          </div>
        }
        className="vacancies-form__card"
      >
        <div className="vacancies-form__card-body">
          {renderFormItems({
            fields: VACANCY_DESCRIPTION_FIELDS,
            refData,
          })}
          <DrivingExperience />
        </div>
      </ILSCard>
    </div>
  );
};
