import "../index.scss";
import { Input } from "antd";
import { FC, RefAttributes } from "react";
import { InputProps, InputRef } from "antd/es/input/Input";
import classNames from "classnames";

export interface IILSInputProps extends InputProps, RefAttributes<InputRef> {
  className?: string;
}

export const ILSInput: FC<IILSInputProps> = ({ className, ...props }) => {
  return (
    <Input
      {...props}
      className={classNames("ils-input ils-input_text", className)}
      maxLength={200}
    />
  );
};
