import "./index.scss";
import { FC } from "react";
import classNames from "classnames";
import { MenuProps } from "antd";
import { ILSMenu } from "@/common/ui-components/navigation/menu";

interface IProps extends MenuProps {
  className?: string;
}

export const ILSSectionHeadHorizontalMenu: FC<IProps> = ({
  className,
  ...props
}) => {
  return (
    <ILSMenu
      {...props}
      className={classNames("menu menu_section-head-horizontal", className)}
      mode="horizontal"
    />
  );
};
