import { FC } from "react";
import {
  CONTESTS_CARD_HEIGHT,
  CONTESTS_CARD_WIDTH,
  PARTICIPATE_BUTTON_TEXT,
} from "@/modules/start/constants/contests";
import { ILSCard, ILSCardMeta } from "@/common/components/data-display/card";
import { ILSImage } from "@/common/components/data-display/image";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSButton } from "@/common/components/data-display/buttons";
import { StartPageContestType } from "@/modules/start/types";

type IProps = StartPageContestType & {
  onParticipateClick: (contestID: number) => () => void;
};

export const StartPageContestCard: FC<IProps> = ({
  ID,
  title,
  pic,
  onParticipateClick,
}) => {
  return (
    <li className="start-page__contests-card">
      <ILSCard
        cover={
          <div className="start-page__contests-card-cover">
            <ILSImage
              src={pic}
              height={CONTESTS_CARD_HEIGHT}
              width={CONTESTS_CARD_WIDTH}
              preview={false}
              loading="eager"
            />
          </div>
        }
      >
        <div className="start-page__contests-card-content">
          <ILSCardMeta
            title={
              <ILSTitle
                level={5}
                ellipsis={{ rows: 4 }}
                className="start-page__contests-card-title"
              >
                {title}
              </ILSTitle>
            }
          />
          <ILSButton
            onClick={onParticipateClick(ID)}
            type="primary"
            block
            className="start-page__contests-card-btn"
          >
            {PARTICIPATE_BUTTON_TEXT}
          </ILSButton>
        </div>
      </ILSCard>
    </li>
  );
};
