import { PartnershipCheckStatus } from "@/common/types/models/account";

export const getPartnershipCheckedValue = (
  partnershipChecked: PartnershipCheckStatus | null | undefined,
  publicOrganizationPartnership: boolean | undefined
) => {
  /** Если статус проверен и принят, и организация убирает значение "Партнер", то сбрасываем проверку */
  const resetConfirmedCheck =
    partnershipChecked === PartnershipCheckStatus.Confirmed &&
    publicOrganizationPartnership === false;

  /** Если статус проверен и отклонен, и организация вновь ставит чекбокс "Партнер", то сбрасываем проверку */
  const resetCancelledCheck =
    partnershipChecked === PartnershipCheckStatus.Cancelled &&
    publicOrganizationPartnership === true;

  return resetConfirmedCheck || resetCancelledCheck ? null : partnershipChecked;
};
