import { FC } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { ILSTag } from "@/common/components/data-display/tag";
import { Driver } from "@/common/types/models/user/driver";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { YES_TEXT } from "@/common/constants/general";

type IProps = {
  driver: Driver | undefined | null;
};

export const DriverDetailsJobSearch: FC<IProps> = ({ driver }) => {
  return (
    <div>
      <div className="driver-details__section-content">
        <DetailDescription
          caption="Статус поиска работы"
          value={driver?.JobSearchStatus?.Name}
        />
        <DetailDescription
          caption="Готов к командировкам"
          value={driver?.ReadyForTrips ? YES_TEXT : null}
        />
        <DetailDescription
          caption="Готов к переезду"
          value={driver?.ReadyForRelocation ? YES_TEXT : null}
        />
      </div>

      {Boolean(driver?.ReadyToStartTomorrow) && (
        <ILSTag
          icon={<CheckCircleOutlined />}
          className="driver-details__start-tag"
        >
          Готов выйти завтра
        </ILSTag>
      )}
    </div>
  );
};
