import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { getDrivingExperienceString } from "@/common/features/vacancy/helpers/get-driving-experience-string";
import { getDictionaryTypeNames } from "@/common/features/vacancies-common/helpers/get-dictionary-type-names";

type IProps = {
  vacancy: Vacancy;
};

export const VacancyMainDetails: FC<IProps> = ({ vacancy }) => {
  return (
    <section className="vacancy__section">
      <div className="vacancy__section-column">
        <ILSTitle level={2} className="vacancy__section-title">
          Общее описание вакансии
        </ILSTitle>
        <div className="vacancy__section-content">
          <DetailDescription
            caption="Профессия"
            value={vacancy?.Details?.Profession}
          />
          <DetailDescription
            caption="Категории прав"
            value={getDictionaryTypeNames(
              vacancy?.Details?.LicenseCategory,
              true
            )}
          />
          <DetailDescription
            caption="Опыт работы"
            value={getDrivingExperienceString(
              vacancy?.Details?.DrivingExperience?.Year,
              vacancy?.Details?.DrivingExperience?.Month
            )}
          />
          <DetailDescription
            caption="Описание вакансии"
            value={vacancy?.Description}
            direction="vertical"
          />
          {Boolean(vacancy?.Vehicle?.Own) && (
            <DetailDescription
              caption="Компания предоставляет"
              value="Автомобиль"
            />
          )}
        </div>
      </div>
    </section>
  );
};
