import { PayloadAction } from "@reduxjs/toolkit";
import { omit } from "lodash";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { authUserSetActions } from "@modules/auth/actions";
import { updateOrganizationActions } from "@modules/social-services/children/my-info/actions";
import { currentUserSelector } from "@modules/auth/selectors/current-user";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { OrganizationUpdateRequest } from "@/common/api/types/organization";
import { ORGANIZATIONS_API } from "@/common/api/endpoints/organization";
import { OrganizationInfo } from "@/common/types/models/account";
import { User } from "@/common/types/models/user/user";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

export type UpdateOrganizationPayload = {
  body: OrganizationUpdateRequest;
  organizationID: number;
};
export type UpdateOrganizationAction = PayloadAction<UpdateOrganizationPayload>;

export function* updateOrganizationWorker(action: UpdateOrganizationAction) {
  const { request, failure, fulfill } = updateOrganizationActions;

  try {
    const { body, organizationID } = action.payload;

    yield put(request());
    const { data: organization }: { data: OrganizationInfo } = yield call(
      ORGANIZATIONS_API.organizationsOperations,
      {
        operation: OperationsEndpoint.Update,
        body: omit(body, [FormFieldName.AccountCode]),
        id: organizationID,
      }
    );
    const currentUser: User = yield select(currentUserSelector);

    const updatedUser = {
      ...currentUser,
      Phone: organization.Phone,
      Account: {
        ...currentUser.Account,
        Organization: [organization],
      },
    };

    yield put(authUserSetActions.success({ user: updatedUser }));
    yield call(showSuccessNotification, "Данные успешно сохранены!");
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* updateOrganizationWatcher() {
  yield takeLatest(updateOrganizationActions.trigger, updateOrganizationWorker);
}
