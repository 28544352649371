import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateOrganizationActions } from "@modules/social-services/children/my-info/actions";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { useFormAvatar } from "@/common/hooks/use-form-avatar";
import { OrganizationFormValues } from "@/modules/social-services/children/my-info/types/form";
import { organizationToFormValues } from "@/modules/social-services/children/my-info/helpers/decorators/organization-to-initial-values";
import {
  useOrganizationAccount,
  useOrganizationPermissions,
} from "@/common/hooks/use-organization-account";
import { useDisableSubmitBeforeTouched } from "@/common/ui-components/data-entry/form/hooks/use-disable-submit-before-touched";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { formValuesToOrganizationData } from "@/modules/social-services/children/my-info/helpers/decorators/form-values-to-organization-data";
import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";

type IProps = {
  activeTab: MyInfoTabs;
};

export const useMyInfoOrganizationContainer = ({ activeTab }: IProps) => {
  const dispatch = useDispatch();

  const { organizationInfo, organizationSocialRating, profileInfo } =
    useOrganizationAccount({
      activeTab,
    });

  const { isOrganization } = useOrganizationPermissions();
  const { disabled: notTouched, enableSubmit } =
    useDisableSubmitBeforeTouched();
  const disabled = !isOrganization;

  const [form] = useForm<OrganizationFormValues>();

  const initialValues = organizationToFormValues({
    organizationInfo,
    profileInfo,
  });

  const {
    fileList,
    removedSavedFiles,
    handleAvatarChange,
    handleAvatarRemove,
    handleAvatarReset,
  } = useFormAvatar({
    avatar: initialValues?.Avatar,
    enableSubmit,
  });

  const handleSubmit = async (formValues: OrganizationFormValues) => {
    try {
      const payload = await formValuesToOrganizationData({
        formValues,
        partnershipChecked: initialValues?.PartnershipChecked,
        fileList,
        removedSavedFiles,
      });
      dispatch(
        updateOrganizationActions({
          body: payload,
          organizationID: organizationInfo.ID,
        })
      );
    } catch (e) {
      showUserDevErrors({ e });
    }
    return formValues;
  };

  const handleSetInitialValues = () => {
    form.resetFields();
    form.setFieldsValue(initialValues || EMPTY_OBJECT_DATA);
    handleAvatarReset();
  };

  const handleCancelChange = () => handleSetInitialValues();

  useEffect(() => {
    handleSetInitialValues();
  }, [organizationInfo]);

  return {
    form,
    initialValues,
    fileList,
    organizationSocialRating,
    disabled,
    notTouched,
    enableSubmit,
    handleSubmit,
    handleCancelChange,
    handleAvatarChange,
    handleAvatarRemove,
  };
};
