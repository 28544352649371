import { useSelector } from "react-redux";
import { compareAsString } from "@/common/helpers/compare-as-string";
import { vacanciesSelector } from "@/common/features/vacancies-common/selectors";
import { vacancyToFormValues } from "@/common/features/vacancies-common/helpers/vacancy-to-form-values";

type IProps = {
  vacancyId?: number;
};

export const useVacancyInitialValues = ({ vacancyId }: IProps) => {
  const vacancies = useSelector(vacanciesSelector);

  const vacancyToEdit = vacancies.find((vacancy) =>
    compareAsString(vacancy?.ID, vacancyId)
  );

  const initialValues = vacancyToFormValues({
    vacancy: vacancyToEdit,
  });

  return { initialValues };
};
