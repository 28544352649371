import { useUsers } from "@modules/social-services/children/my-info/hooks/api/use-users";
import { User } from "@/common/types/models/user/user";
import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";
import { UserCardActions } from "@/common/types/models/user/card-actions";
import { useMyInfoUsersActions } from "@/modules/social-services/children/my-info/hooks/users/users-actions";
import { useMyInfoEmployeeEditForm } from "@/modules/social-services/children/my-info/hooks/users/user-edit-form";
import { useMyInfoSelectUser } from "@/modules/social-services/children/my-info/hooks/users/select-user";

type IProps = {
  activeTab: MyInfoTabs;
};

export const useMyInfoUsers = ({ activeTab }: IProps) => {
  /** список сотрудников */
  const { allUsers, isFetching } = useUsers(activeTab);
  const users: Array<User> = Object.values(allUsers);

  /** выбранный сотрудник для редактирования / удаления / блокировки */
  const { selectedUser, handleSelectUser } = useMyInfoSelectUser();

  /** действия в карточке */
  const { userCardActions, actionType, modalProps, resetActionType } =
    useMyInfoUsersActions({ selectedUser, handleSelectUser });

  const handleResetActionAndUser = () => {
    handleSelectUser(null);
    resetActionType();
  };

  /** форма редактирования сотрудника */
  const {
    form,
    fileList,
    disabled,
    enableSubmit,
    handleSubmit,
    handleCancel,
    handleAvatarChange,
    handleAvatarRemove,
  } = useMyInfoEmployeeEditForm({ selectedUser, handleResetActionAndUser });

  const isEditForm = actionType === UserCardActions.Edit;

  return {
    users,
    isFetching,
    form,
    fileList,
    isEditForm,
    userCardActions,
    disabled,
    modalProps,
    enableSubmit,
    handleSubmit,
    handleCancel,
    handleAvatarChange,
    handleAvatarRemove,
  };
};
