import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { JOB_SEARCH_STATUS_ALL_OPTION } from "@/common/constants/config/options";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { RefDataType } from "@/common/features/dictionaries/types";

export const getStatusRefDataWithAll = (refData: RefDataType) => {
  const optionsWithAll = [
    JOB_SEARCH_STATUS_ALL_OPTION,
    ...(refData.JobSearchStatus?.options || EMPTY_ARRAY_DATA),
  ];

  return {
    ...refData,
    [FormFieldName.JobSearchStatus]: {
      options: optionsWithAll,
    },
  };
};
