import { RATING_PATHS, RATING_TITLES } from "@/common/constants/config/rating";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { ModulePath } from "@/common/types/core/routes";
import { RatingContentType } from "@/common/types/models/rating";

export const getRatingBreadcrumbs = (ratingType: RatingContentType) => {
  return [
    {
      title: (
        <ILSLink
          to={`/${ModulePath.socialServices}/${ModulePath.myInfo}`}
          relative="path"
        >
          Мой рейтинг
        </ILSLink>
      ),
    },
    {
      title: (
        <ILSLink
          to={`/${ModulePath.socialServices}/${ModulePath.myInfo}/${RATING_PATHS[ratingType]}`}
          relative="path"
        >
          {RATING_TITLES[ratingType]}
        </ILSLink>
      ),
    },
  ];
};
