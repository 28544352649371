import { USERS_MOCK } from "@/common/constants/config/users";
import { User, UserRole } from "@/common/types/models/user/user";
import { GeneralState } from "@/common/types/core/store";
import { RecoveryProcess } from "@/common/api/types/auth";
import { SelectApiOption } from "@/common/types/general/common";

export type AuthStore = GeneralState & {
  user: User;
  recoveryProcess: RecoveryProcess | null;
  resetOptions: SelectApiOption[] | [];
};
export type AuthModuleStore = { AuthModule: AuthStore };

export const AUTH_INITIAL_STATE: AuthStore = {
  error: [],
  isFetching: false,
  user: USERS_MOCK[UserRole.guest],
  recoveryProcess: null,
  resetOptions: [],
};
