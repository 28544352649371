import { Phone, Role, User } from "@/common/types/models/user/user";
import { InfoDate, TimeStamp } from "@/common/types/general/date-time";
import { ID } from "@/common/types/general/common";
import { ImageType } from "@/common/types/models/image";

export enum AccountRole {
  organization = "organization",
  employee = "employee",
  driver = "driver",
  platformAdmin = "platform_admin",
  guest = "guest",
}

export enum AccountRoleLabel {
  organization = "Организация",
  employee = "Сотрудник",
  driver = "Водитель",
  platformAdmin = "Администратор платформы",
  guest = "Посетитель",
}

export type RoleAccount = Role<AccountRole>;

export type Account = {
  ID: number;
  ParentID: Account["ID"] | null;
  AccountRoles: Array<RoleAccount>;
  Blocked: InfoDate;
  Deleted: InfoDate;
  DeletedBy: User["ID"] | null;
  /** Сущность Организации "один к многим": один аккаунт - потенциально может быть несколько организаций */
  Organization: Array<OrganizationInfo> | {};
  Description: string | null;
  // for backend: было бы логично, что если не обновлялся = created
  LastUpdate: InfoDate;
  Name: string;
  Created: TimeStamp;
  AccountCode: string;
  ManagerID: null;
  // todo backend в разработке
  Rating: RatingType;
};

export type RatingType = {
  Social: number;
  Safety?: number;
  ByUsers?: number;
};

export type OrganizationInfo = {
  // todo backend в разработке
  ID: ID;
  OrganizationTypeID: number;
  Name: string;
  // todo backend в разработке
  IsVerify: boolean; // (если организация подтверждена её вакансии будут помечены как от проверенной)
  INN: string;
  KPP: string;
  OGRN: string;
  OKPO: string;
  LegalAddress: string;
  ActualAddress: string;
  PublicOrganizationPartnership: boolean;
  // true - принято; false - отклонено; null - не проверено;
  PartnershipChecked: boolean | null;
  Phone?: Phone | null;
  AccountCode?: string;
  Avatar?: ImageType | null;
  SocialRating?: number;
};

export enum PartnershipCheckStatus {
  Cancelled = 0,
  Confirmed = 1,
}
