import { EMPTY_STRING, JoinChar } from "@/common/constants/general";
import { Notify } from "@/common/types/notifications/enum";

type GetDataNotificationMessage = {
  action: string;
  type: Partial<Notify> | null;
  defaultStatuses: Record<string, Record<Partial<Notify>, string>>;

  payload?: {
    message: string;
    hideSuccessMessage?: boolean;
    operation?: string;
    customNotificationMessage?: Record<
      string,
      Record<string, Record<string, string>>
    >;
  };
  keySuffix: string;
};

export const getDataNotificationMessage = ({
  action,
  type,
  defaultStatuses,
  payload,
  keySuffix,
}: GetDataNotificationMessage): string => {
  if (payload?.hideSuccessMessage) return EMPTY_STRING;
  return (
    payload?.message ??
    (type &&
      payload?.operation &&
      payload?.customNotificationMessage &&
      payload?.customNotificationMessage?.[
        keySuffix + JoinChar.ForwardSlash + action
      ]?.[type]?.[payload?.operation]) ??
    ((type &&
      defaultStatuses[keySuffix + JoinChar.ForwardSlash + action]?.[type]) ||
      EMPTY_STRING)
  );
};
