import "../index.scss";
import { NotFound } from "@/common/components/custom/not-found";
import { useOrganization } from "@/modules/social-services/children/organizations/children/hooks/use-organization";
import { OrganizationSidebar } from "@/modules/social-services/children/organizations/children/containers/sidebar/organization-sidebar";
import { OrganizationContent } from "@/modules/social-services/children/organizations/children/containers/content/organization-content";

export const OrganizationContainer = () => {
  const { organization, prevVacancyLocation } = useOrganization();

  if (!organization) {
    return <NotFound />;
  }
  return (
    <div className="organization">
      <OrganizationContent
        organization={organization}
        prevVacancyLocation={prevVacancyLocation}
      />
      <OrganizationSidebar organization={organization} />
    </div>
  );
};
