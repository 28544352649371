import { FC, ReactNode } from "react";
import {
  StepsForm,
  StepsFormProps,
} from "@ant-design/pro-form/es/layouts/StepsForm";
import { renderILSStepsFormSubmitter } from "@/common/ui-components/data-entry/steps-form/render-submitter";

interface IProps extends StepsFormProps {
  children: ReactNode;
  name: string;
}

export const ILSStepsForm: FC<IProps> = (props) => {
  return (
    <StepsForm
      submitter={{
        render: renderILSStepsFormSubmitter,
      }}
      {...props}
    />
  );
};
