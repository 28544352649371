import { all, call, put, takeLatest } from "redux-saga/effects";
import { authUserSetActions, logoutActions } from "@modules/auth/actions";
import { AUTH_INITIAL_STATE } from "@modules/auth/store";
import { AUTH_API } from "@/common/api/endpoints/auth";

function* logoutWorker() {
  const { request, failure, fulfill } = logoutActions;

  try {
    yield put(request());
    window.location.pathname.replace(window.location.pathname, "/");
    yield call(AUTH_API.logout);
    yield put(authUserSetActions.success({ user: AUTH_INITIAL_STATE.user }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* logoutWatcher() {
  yield all([takeLatest(logoutActions.trigger, logoutWorker)]);
}
