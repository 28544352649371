import { put, delay } from "redux-saga/effects";
import { isNumber } from "lodash";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  DEFAULT_APP_ERROR_MESSAGE,
  PARSING_ERROR_MESSAGE,
} from "@/common/constants/notifications/config";
import { splitActionType } from "@/common/utils/helpers/notifications/split-action-type";
import { getNotifyType } from "@/common/utils/helpers/notifications/get-notify-type";
import { getDataNotificationMessage } from "@/common/utils/helpers/notifications/get-notification-message";
import {
  Notify,
  NotifyDurationInSecond,
} from "@/common/types/notifications/enum";
import { getErrorMessage } from "@/common/utils/helpers/notifications/get-error-message";
import { userNotifyRoutine } from "@/core/actions/notification";
import { getNotificationKey } from "@/common/utils/helpers/notifications/get-notification-key";
import {
  ActionPayload,
  UseUserNotificationHookProps,
} from "@/common/types/notifications/props";

export const userNotificationHook = ({
  defaultStatuses,
  successDuration,
  moduleKey,
}: UseUserNotificationHookProps) => {
  const notifierWorkerCreator = () => {
    return function* (action: PayloadAction<ActionPayload>) {
      if (action?.payload?.withoutNotification) return;

      const closeDelay =
        action?.payload?.notificationCloseDelay === undefined
          ? Number(NotifyDurationInSecond.One)
          : action.payload.notificationCloseDelay;

      const {
        keySuffix,
        actionTypeOperation,
        actionTypeResult,
        actionTypeSplitted,
      } = splitActionType(action.type);

      if (
        actionTypeSplitted.length !== 3 ||
        !actionTypeResult ||
        !actionTypeOperation
      ) {
        console.error(PARSING_ERROR_MESSAGE);
        return;
      }

      const notifyType = getNotifyType(actionTypeResult);

      if (!notifyType) {
        console.error("Тип уведомления для пользователя не определен");
      }
      const notificationMessage = getDataNotificationMessage({
        action: actionTypeOperation,
        type: notifyType,
        payload: action.payload,
        defaultStatuses,
        keySuffix,
      });

      const { loadingKey, fulfillKey } = getNotificationKey({
        prefix: moduleKey,
        keySuffix,
        action: actionTypeOperation,
        notifyKey: action.payload?.notifyKey,
      });

      switch (notifyType) {
        case Notify.Loading:
        case Notify.Success:
          if (notificationMessage) {
            yield put(
              userNotifyRoutine.trigger({
                type: notifyType,
                status: notificationMessage,
                key: Notify.Success === notifyType ? fulfillKey : loadingKey,
                duration: successDuration || NotifyDurationInSecond.One,
              })
            );
          }

          break;
        case Notify.Error:
          {
            const error = getErrorMessage?.(action.payload);
            if (error) {
              console.error(error);
            }
            yield put(
              userNotifyRoutine.trigger({
                type: notifyType,
                key: action.payload?.errorNotifyKey,
                status: error || DEFAULT_APP_ERROR_MESSAGE,
              })
            );
          }
          break;
        default:
          break;
      }

      if (notifyType !== Notify.Loading) {
        if (isNumber(closeDelay)) yield delay(closeDelay * 1000);
        yield put(userNotifyRoutine.fulfill({ key: loadingKey }));
      }
    };
  };

  return notifierWorkerCreator();
};
