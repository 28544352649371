import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ILSMembershipBadge } from "@/common/components/custom/membership-badge";
import { PUBLIC_ORGANIZATION_CHECK_REQUIRED_ICON } from "@/common/features/drivers-common/constants";

type IProps = {
  hasConfirmedPartnership: boolean;
  waitingPartnershipCheck: boolean;
};

export const getOrganizationPartnershipBadge = ({
  hasConfirmedPartnership,
  waitingPartnershipCheck,
}: IProps) => {
  switch (true) {
    case hasConfirmedPartnership:
      return <ILSMembershipBadge />;
    case waitingPartnershipCheck:
      return (
        <ExclamationCircleOutlined
          style={PUBLIC_ORGANIZATION_CHECK_REQUIRED_ICON}
        />
      );
    default:
      return null;
  }
};
