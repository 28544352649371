import { createSelector } from "reselect";
import { MyInfoModuleStore } from "@modules/social-services/children/my-info/store";
import { User } from "@/common/types/models/user/user";
import { IndexedCollection } from "@/common/types/general/common";

export const usersSelector = createSelector(
  (state: MyInfoModuleStore) => {
    return state.MyInfoModule.users;
  },
  (users: IndexedCollection<User>) => users
);

export const userSelector = (userID: number | null) =>
  createSelector(
    (state: MyInfoModuleStore) => {
      return state.MyInfoModule.users;
    },
    (users: IndexedCollection<User>) => {
      if (userID) {
        return users[userID];
      }
      return null;
    }
  );

export const usersFetchingSelector = createSelector(
  (state: MyInfoModuleStore) => {
    return state.MyInfoModule.isFetching;
  },
  (isFetching: boolean) => isFetching
);
