import { FC } from "react";
import { Form, FormInstance } from "antd";
import classNames from "classnames";
import {
  ORGANIZATIONS_FILTERS_FIELDS,
  ORGANIZATIONS_FILTERS_INITIAL_VALUES,
} from "@/modules/admin-panel/children/admin-organizations/constants/organizations-filters";
import { AdminOrganizationsFiltersType } from "@/modules/admin-panel/children/admin-organizations/types/organizations-filters";
import { ILSButton } from "@/common/components/data-display/buttons";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  form: FormInstance<AdminOrganizationsFiltersType>;
  onValuesChange: (changedValues: AdminOrganizationsFiltersType) => void;
  resetFilters: VoidFunction;
  hideLarge?: boolean;
};

export const AdminOrganizationsFilters: FC<IProps> = ({
  form,
  hideLarge = true,
  onValuesChange,
  resetFilters,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      className={classNames("admin-organizations__filters", {
        "hide-large": hideLarge,
      })}
      initialValues={ORGANIZATIONS_FILTERS_INITIAL_VALUES}
      onValuesChange={onValuesChange}
    >
      <ILSButton onClick={resetFilters} danger style={{ marginBottom: "1em" }}>
        Сбросить фильтры
      </ILSButton>
      {renderFormItems({
        fields: ORGANIZATIONS_FILTERS_FIELDS,
      })}
    </Form>
  );
};
