import { User } from "@/common/types/models/user/user";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { compareAsString } from "@/common/helpers/compare-as-string";

export const isVacancyOwner = ({
  user,
  vacancy,
}: {
  user: User | undefined;
  vacancy: Vacancy;
}): boolean => {
  if (!vacancy || !user || !vacancy.Owner) return false;
  return compareAsString(vacancy.Owner.AccountID, user.AccountID);
};
