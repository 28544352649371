import { useSelector } from "react-redux";
import { currentUserSelector } from "@modules/auth/selectors/current-user";
import { authIsFetchingSelector } from "@modules/auth/selectors/auth-fetching";
import { RoleUser, UserRole } from "@/common/types/models/user/user";
import { AccountRole, RoleAccount } from "@/common/types/models/account";

export const getRolesAliases = <R extends RoleUser | RoleAccount>({
  Alias,
}: R) => Alias;
export const getRoles = <R extends RoleUser | RoleAccount>(roles: R[]) =>
  roles.map(getRolesAliases);

export const useAuth = () => {
  const user = useSelector(currentUserSelector);
  const authenticationPending = useSelector(authIsFetchingSelector);
  const userRole = getRoles<RoleUser>(user.UserRoles) as Array<UserRole>;
  const accountRole = getRoles<RoleAccount>(
    user.Account.AccountRoles
  ) as Array<AccountRole>;

  const authenticated = !userRole?.includes(UserRole.guest);

  return {
    user,
    userRole,
    accountRole,
    authenticationPending,
    authenticated,
  };
};
