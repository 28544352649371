import "./index.scss";
// eslint-disable-next-line max-len
import { useMyRatingApplicationModule } from "@/modules/social-services/children/my-info/children/application/hooks/use-my-rating-application-module";
import { isRatingWithForm } from "@/common/types/models/rating";
import { MyRatingWithForm } from "@/modules/social-services/children/my-info/children/application/containers/with-form";
import { MyRatingTotal } from "@/modules/social-services/children/my-info/children/application/containers/total";

export const MyRatingApplicationContainer = () => {
  const { ratingType, ratingValue, ratingValues, ratingTotalValue } =
    useMyRatingApplicationModule();

  if (!ratingType) {
    return null;
  }

  return (
    <div className="my-rating-application">
      {isRatingWithForm(ratingType) ? (
        <MyRatingWithForm
          ratingType={ratingType}
          ratingValue={ratingValue}
          ratingTotalValue={ratingTotalValue}
        />
      ) : (
        <MyRatingTotal ratingValues={ratingValues} />
      )}
    </div>
  );
};
