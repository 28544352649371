import { FC } from "react";
import { Modal } from "antd";
import { VacanciesForm } from "@/common/features/vacancies-common/components/form";
import { VacancyFormType } from "@/common/features/vacancies-common/types/form";

interface IProps {
  vacancyId?: number | undefined;
  type: VacancyFormType;
  isModalOpen?: boolean;
  showModal: VoidFunction;
}

export const VacancyFormModal: FC<IProps> = ({
  vacancyId,
  type,
  isModalOpen,
  showModal,
}) => {
  return (
    <Modal
      open={isModalOpen}
      onOk={showModal}
      onCancel={showModal}
      footer={null}
      centered
      destroyOnClose
      className="vacancies-form-modal"
    >
      <VacanciesForm showModal={showModal} type={type} vacancyId={vacancyId} />
    </Modal>
  );
};
