import { FC } from "react";
import { Form, Skeleton } from "antd";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { ILSEditor } from "@/common/ui-components/data-entry/editor";
import { useArticleActions } from "@/common/hooks/articles/use-article-actions";
import { ArticlePictureUpload } from "@/modules/admin-panel/children/admin-articles/components/article-picture-upload";

interface IProps {
  showArticleEditorModal: VoidFunction;
}

export const ArticleCrud: FC<IProps> = ({ showArticleEditorModal }) => {
  const {
    articleContent,
    setContent,
    onFinishArticle,
    initialValues,
    loading,
    formItems,
    onRemoveFile,
  } = useArticleActions({
    showArticleEditorModal,
  });

  return (
    <Skeleton active loading={Boolean(loading)}>
      <Form
        initialValues={initialValues}
        className="admin-articles-form-modal__form"
        name="article"
        layout="vertical"
        onFinish={onFinishArticle}
      >
        <ArticlePictureUpload onRemoveFile={onRemoveFile} />
        {renderFormItems(formItems)}
      </Form>
      <ILSEditor
        value={articleContent}
        onChange={setContent}
        className="admin-articles-form-modal__editor"
      />
    </Skeleton>
  );
};
