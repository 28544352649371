import { useEffect } from "react";
import { useSelector } from "react-redux";
import { driverSelector } from "@/common/features/drivers-common/selectors";
import { useDriversOperations } from "@/common/features/drivers-common/hooks/use-drivers-operations";
import { adminDriversSelectedDriverSelector } from "@/modules/admin-panel/children/admin-drivers/selectors";

export const useAdminGetFormDriver = () => {
  const selectedDriverID = useSelector(adminDriversSelectedDriverSelector);
  const selectedDriver = useSelector(
    driverSelector(selectedDriverID?.toString())
  );

  const { handleGetDriver } = useDriversOperations();

  useEffect(() => {
    if (selectedDriverID) {
      handleGetDriver(Number(selectedDriverID));
    }
  }, []);

  return { driver: selectedDriver };
};
