export type TimeStamp = number;
export type InfoDate = TimeStamp | null;

export enum FormatType {
  Datetime = "datetime",
  Time = "time",
  Date = "date",
}

export enum DateFormat {
  DDMMYYYYPeriod = "DD.MM.YYYY",
  DDMMYYYYSlash = "DD/MM/YYYY",
  YYYYMMDDSlash = "YYYY/MM/DD",
  Countdown = "H:m:s",
  MMYYYYPeriod = "MM.YYYY",
  YYYY = "YYYY",
}

export enum DateSeparator {
  Period = ".",
  Slash = "/",
  Dash = "-",
  Space = " ",
}

export enum TimeFormat {
  HHMMSSColon = "HH:mm",
  HHMMColon = "HH:mm",
  HHmmssColon = "HH:mm:ss",
  HHmmColon = "HH:mm",
  HHMMDash = "HH-mm",
  HHMMPeriod = "HH.mm",
  HHMMSpace = "HH mm",
  HHMMSlash = "HH-mm",
  HHMMColonSpaceZ = "HH:mm Z",
  H = "H",
  HH = "HH",
  S = "S",
  SS = "SS",
  mm = "mm",
  HHMM = "HHmm",
}

export enum DateTimeFormat {
  DDMMYYYYPeriodHHMMColonSpaceZ = "DD.MM.YYYY HH:mm Z",
  DDMMYYYYPeriodHHMM = "DD.MM.YYYY HH:mm",
  YYYYMMDDDashHHMM = "YYYY-MM-DD HH:mm",
  YYYYMMDDDashHHMMSS = "YYYY-MM-DD HH:mm:ss",
  YYYYMMDDDashHHMMS = "YYYY-MM-DD HH:mm:s",
  HHmmssDMMYY = "HH:mm:ss D.MM.YY",
  DMYPeriodHISColon = "d.m.Y H:i:s",
  HHMMColonDDMDot = "HH:MM DD.M",
  ISO8601 = "",
  YYYYMMDDDashHHMMColon = "YYYYMMDDDashHHMMColon",
  YYYYMMDDDashHHMMSSColon = "YYYYMMDDDashHHMMSSColon",
  YYYYMMDDHHMM = "YYYYMMDDHHMM",
  YYYYMMDDHHMMSS = "YYYYMMDDHHMMSS",
  UNIX = "X",
  UNIXMillisecond = "x",
}

export const ILS_MAIN_API_DATE_TIME_FORMAT = DateTimeFormat.YYYYMMDDDashHHMM;
export const ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT =
  DateTimeFormat.DDMMYYYYPeriodHHMM;

export const ILS_MAIN_API_DATE_FORMAT = DateFormat.YYYYMMDDSlash;
export const ILS_MAIN_INTERFFACE_DATE_FORMAT = DateFormat.DDMMYYYYPeriod;

export const ILS_MAIN_API_TIME_FORMAT = TimeFormat.HHMMColon;
export const ILS_MAIN_INTERFFACE_TIME_FORMAT = TimeFormat.HHMMColon;
