import { useState } from "react";

/** Disable form/buttons before some value touched
 * example:
 *    <Form
 *       disabled={disabled}
 *       onFieldsChange={enableSubmit}
 *       />
 */
export const useDisableSubmitBeforeTouched = () => {
  const [disabled, setDisabled] = useState(true);
  const enableSubmit = () => {
    if (disabled) {
      setDisabled(false);
    }
  };
  return { disabled, enableSubmit };
};
