import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_ZERO_RATING } from "@/common/constants/config/rating";
import { useAccount } from "@/common/hooks/use-account";
import { useDriversOperations } from "@/common/features/drivers-common/hooks/use-drivers-operations";
import { driverSelector } from "@/common/features/drivers-common/selectors";

export const useDriverAccount = () => {
  const { accountName, avatar, user } = useAccount();
  const driverInfo = user.Driver;
  const phone = user.Phone;
  const driverSocialRating = driverInfo?.SocialRating || DEFAULT_ZERO_RATING;

  const selectedDriver = useSelector(driverSelector(driverInfo?.ID));
  const { handleGetDriver } = useDriversOperations();

  useEffect(() => {
    if (driverInfo?.ID) {
      handleGetDriver(Number(driverInfo?.ID));
    }
  }, []);

  const profileInfo = {
    accountName,
    avatar,
    phone,
  };

  return {
    user,
    driverSocialRating,
    profileInfo,
    driverInfo: selectedDriver,
  };
};
