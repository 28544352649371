import React from "react";
import ReactDOM from "react-dom/client";
import ruRU from "antd/lib/locale/ru_RU";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { APP_THEME } from "./styles/theme";
import { Registry, run } from "./core/run";

export const DRIVER_RF_REGISRTY: Registry = {} as Registry;

run().then((result) => {
  Object.assign(DRIVER_RF_REGISRTY, result);
  ReactDOM.createRoot(document.getElementById("root")!).render(
    /** Не использовать <React.StrictMode>
     *  https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
     * https://legacy.reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects */
    <Provider store={result.store}>
      <ConfigProvider locale={ruRU} theme={APP_THEME.MAIN}>
        <RouterProvider router={result.router} />
      </ConfigProvider>
    </Provider>
  );
});
