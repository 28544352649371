import "./index.scss";
import { FC, PropsWithChildren } from "react";
import classNames from "classnames";
import {
  BackgroundColor,
  Color,
} from "@/common/ui-components/data-display/rating";
import { COLOR_PALETTE } from "@/styles/color-palette";

export interface IILSChipsProps extends PropsWithChildren {
  className?: string;
  color?: Color;
  backgroundColor?: BackgroundColor;
}

export const ILSChips: FC<IILSChipsProps> = ({
  className,
  children,
  color = COLOR_PALETTE.whitePrimary,
  backgroundColor = COLOR_PALETTE.chipDefault,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames("ils-chips", className)}
      style={{
        color,
        backgroundColor,
      }}
    >
      {children}
    </div>
  );
};
