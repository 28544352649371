import { FC } from "react";
import { Empty } from "antd";
import { isUndefined } from "lodash";
import { ILSAvatar } from "@/common/ui-components/data-display/avatar";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { AbacPermission } from "@/common/types/core/abac";

interface IProps {
  avatar: string | null | undefined;
}

export const NotAllowedImage = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description="Для просмотра необходимо войти в систему"
  />
);

export const WideCardAvatar: FC<IProps> = ({ avatar }) => {
  if (isUndefined(avatar)) {
    return null;
  }

  return (
    <ILSAllowedTo perform={AbacPermission.auth} no={NotAllowedImage}>
      <ILSAvatar src={avatar} />
    </ILSAllowedTo>
  );
};
