import { ReactNode } from "react";
import { notification, Modal } from "antd";
import { isArray, isObject } from "lodash";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Notify,
  NotifyDurationInSecond,
} from "@/common/types/notifications/enum";
import { PlacementType } from "@/common/types/general/position";
import { emptyFunction } from "@/common/helpers/empty-function";
import { UserNotify } from "@/common/types/notifications/props";
import { EROROR_MESSAGE_DURATION } from "@/common/constants/notifications/config";
import { getOutputNotify } from "./user-outer-notify";

const { confirm } = Modal;

export const ILSUserNotify = ({
  type,
  content,
  duration = NotifyDurationInSecond.Ten,
  key = undefined,
  icon,
  onClick,
  onClose,
  message,
  placement = PlacementType.topRight,
  onConfirm,
  okText,
  showFull = false,
}: UserNotify) => {
  let description = content as ReactNode;

  const displayFull = () =>
    ILSUserNotify({
      type,
      content,
      duration: Number(NotifyDurationInSecond.One),
      key,
      icon,
      onClick,
      onClose,
      message,
      placement,
      onConfirm,
      okText,
      showFull: true,
    });

  if (isArray(content) || isObject(content)) {
    description = getOutputNotify({ content, type, showFull, displayFull });
  }

  notification.config({
    maxCount: NotifyDurationInSecond.Three,
  });

  switch (type) {
    case Notify.Loading:
      {
        const loadingIcon = <LoadingOutlined style={{ color: "green" }} />;
        notification[Notify.Info]({
          message,
          description,
          duration,
          key,
          placement,
          icon: icon ?? loadingIcon,
          onClick: onClick ?? emptyFunction,
          onClose: onClose ?? emptyFunction,
        });
      }
      break;
    case Notify.Error:
      {
        const errorIcon = <InfoCircleOutlined style={{ color: "red" }} />;
        notification[type]({
          message,
          description,
          key,
          duration: duration ?? EROROR_MESSAGE_DURATION,
          placement,
          icon: errorIcon,
          onClick: onClick ?? emptyFunction,
          onClose: onClose ?? emptyFunction,
        });
      }
      break;
    case Notify.Confirm:
      confirm({
        okButtonProps: { id: "confirm-modal-yes" },
        cancelButtonProps: { id: "confirm-modal-cancel" },
        title: message,
        content: content as ReactNode,
        icon: icon ?? <ExclamationCircleOutlined />,
        onOk:
          (() => {
            onConfirm?.();
            onClose?.();
          }) ?? emptyFunction,
        onCancel: onClose ?? emptyFunction,
        cancelText: "Нет",
        okText: okText || "Да",
      });
      break;
    default:
      if (type in notification) {
        notification[type]({
          message,
          description,
          key,
          duration,
          placement,
          onClick: onClick ?? emptyFunction,
          onClose: onClose ?? emptyFunction,
        });
      }
      break;
  }
};
