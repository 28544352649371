import { isUndefined } from "lodash";
import { RatingType } from "@/common/types/models/account";
import { User } from "@/common/types/models/user/user";
import { TimeStamp } from "@/common/types/general/date-time";
import { ImageType } from "@/common/types/models/image";
import { DictionaryType } from "@/common/types/models/dictionary";

export type DrivingExperience = {
  VehicleType: DictionaryType[];
  ExperienceYears: number;
};

export type DriverWorkExperience = DrivingExperience & {
  Organization?: string;
};

/** Данные водителя для неавторизованных пользователей (в запросе на получение списка водителей) */
export type DriverInfoShort = {
  ID: number;
  ReadyToStartTomorrow?: number; // 0 | 1
  // todo уточнить
  Rating: RatingType;
};

/** Данные водителя для авторизованных пользователей (в запросе на получение списка водителей) */
export type DriverInfoBase = DriverInfoShort & {
  Email: string;
  Phone: string;
  Name: string;
  Surname: string;
  Patronymic: string;
  FullExperienceYears: number;
  Avatar: ImageType;
  ShowAvatar: boolean;
  HaveDriverLicense: number;
  PublicOrganizationMembership: boolean;
  MembershipChecked: boolean;
  SocialRating: number;
  PhoneVerified: boolean;
};
export type DriverInfo = DriverInfoBase | DriverInfoShort;

export const isDriverInfoBase = (
  driver: DriverInfo
): driver is DriverInfoBase => {
  return !isUndefined((driver as DriverInfoBase).Phone);
};

/** Все данные водителя для авторизованных пользователей (в запросе на получение конкретного водителя) */
export type Driver = {
  ID: number;
  License: string;
  Patronymic: string | null;
  Name: User["Name"];
  Surname: string | null;
  UserID: User["ID"];
  HaveDriverLicense: number; // 0 | 1
  Phone?: string;
  Rating?: RatingType;
} & Partial<{
  OrganizationName?: string;
  Birthday: TimeStamp;
  DrivingExperience?: DriverWorkExperience[];
  JobSearchStatus: DictionaryType | null;
  ReadyToStartTomorrow?: number; // 0 | 1
  WorkingPosition?: string;
  VehicleType: number[]; // на каких машиных какой категории ездил (категории машин есть в вакансиях как alias)
  /* --- Водительское удостоверение ---*/
  DriverLicense: string;
  DriverLicenseDate: TimeStamp | null;
  LicenseCategory?: DictionaryType[] | null;
  /* ---Тип занятости---*/
  // Все 0
  // Основная работа 1
  // Подработка 2
  EmploymentType: DictionaryType | null;
  /* ---График работы---*/
  // Полный день
  // Неполный день
  // Свободный
  // Сменный
  // Вахтовый
  // 5/2 (пн. - пт.)
  // 6/1 (пн. - сб.)
  // Удалённая работа
  WorkSchedule: DictionaryType[] | null;
  /* ---Образование---*/
  // Высшее
  // Незаконченное высшее
  // Среднее
  // Специальное
  EducationLevel: DictionaryType | null;
  /* ---Пол ---*/
  // Мужской
  // Женский
  Sex: number;
  /* ---Гражданство ---*/
  Citizens: string;
  SalaryFrom: number | null;
  SalaryTo: number | null;
  /* ---Свой транспорт ---*/
  // Легковой автомобиль
  // Грузовой автомобиль
  // Мотоцикл
  // Мопед
  OwnVehicleType: DictionaryType[] | null;
  /* ---Мед. книжка ---*/
  // Неважно
  // Есть
  MedicalCertificate: number;
  Avatar: ImageType | null;
  ShowAvatar: boolean;
  FullExperienceYears: number | null;
  PublicOrganizationMembership: boolean;
  MembershipChecked: boolean | null;
  ReadyForTrips: boolean;
  ReadyForRelocation: boolean;
  JobSearchRegion: DictionaryType[] | null;
  SocialRating?: number;
  Email?: string;
  PhoneVerified?: boolean;
}>;

export enum MembershipCheckStatus {
  Cancelled = 0,
  Confirmed = 1,
}
