import { isArray, isNil } from "lodash";
import { DictionaryType } from "@/common/types/models/dictionary";
import { JoinChar } from "@/common/constants/general";

export const getDictionaryTypeNames = (
  dictionaryValue: DictionaryType | DictionaryType[] | null | undefined,
  aliasAsName: boolean = false
) => {
  if (isNil(dictionaryValue)) {
    return null;
  }

  if (isArray(dictionaryValue)) {
    return dictionaryValue
      .map((value) => {
        return !aliasAsName ? value?.Name : value?.Alias;
      })
      ?.join(JoinChar.Comma);
  }

  return !aliasAsName ? dictionaryValue?.Name : dictionaryValue?.Alias;
};
