import { FC, ReactNode } from "react";
import { Space } from "antd";
import { ILSImage } from "@/common/components/data-display/image";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSParagraph } from "@/common/ui-components/general/typography/paragraph";
import {
  IILSChipsProps,
  ILSChips,
} from "@/common/ui-components/data-display/chips";

export interface IWideCardProfileProps {
  title?: string;
  description?: string | null;
  img?: string | null;
  chips?: Array<IILSChipsProps & { text: string; hide?: boolean }>;
  icon?: ReactNode;
}

export const WideCardProfile: FC<IWideCardProfileProps> = ({
  title,
  description,
  img,
  chips,
  icon,
}) => {
  return (
    <div className="ils-card__profile hide-xlarge">
      {img && (
        <ILSImage alt={title} src={img} className="ils-card__profile-img" />
      )}
      <Space>
        {title && (
          <ILSTitle level={5} children={title} ellipsis={{ rows: 2 }} />
        )}
        {icon && icon}
      </Space>
      {description && <ILSParagraph children={description} />}
      {chips?.map(({ text, hide = false, ...props }, i) => {
        if (hide) return null;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ILSChips {...props} key={i}>
            {text}
          </ILSChips>
        );
      })}
    </div>
  );
};
