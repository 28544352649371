import { Recovery } from "@modules/auth/children/recovery/containers";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { ModulePath } from "@/common/types/core/routes";
import { defineModule } from "@/core/modules/define-module";

const module: IModuleModel = {
  title: ModuleTitle.recovery,
  name: ModuleName.recovery,
  path: ModulePath.recovery,
  element: <Recovery />,
  persist: false,
  isIndex: true,
};

export const RecoveryModule = defineModule(module);
