import "./index.scss";
import { FC } from "react";
import locale_ru from "antd/es/date-picker/locale/ru_RU";
import classNames from "classnames";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import { ILS_MAIN_INTERFFACE_DATE_FORMAT } from "@/common/types/general/date-time";

export type IILSDatePickerProps = DatePickerProps & {
  className?: string;
};

export const ILSDatePicker: FC<IILSDatePickerProps> = ({
  className,
  format = ILS_MAIN_INTERFFACE_DATE_FORMAT,
  ...props
}) => {
  return (
    <DatePicker
      {...props}
      locale={locale_ru}
      format={format}
      className={classNames("date-picker", className)}
    />
  );
};
