import "../index.scss";
import { FC } from "react";
import { AdminPanelMenu } from "@modules/admin-panel/containers/menu";
import { ProtectedPage } from "@pages/protected-page";
import { Outlet } from "react-router-dom";
import { AbacPermission } from "@/common/types/core/abac";

interface IProps {}

export const AdminPanel: FC<IProps> = () => {
  return (
    <ProtectedPage perform={AbacPermission.adminPanel}>
      <AdminPanelMenu />
      <section className="admin-panel">
        <Outlet />
      </section>
    </ProtectedPage>
  );
};
