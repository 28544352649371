import { NewsList } from "@/modules/start/components/news/list/news-list";
import { StartPageNewsHeader } from "@/modules/start/components/news/header/news-header";
import { useCommonArticles } from "@/common/hooks/articles/use-common-articles";

export const StartPageNewsContainer = () => {
  const { articles } = useCommonArticles();

  return (
    <section>
      <StartPageNewsHeader />
      <NewsList articles={articles} />
    </section>
  );
};
