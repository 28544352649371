import { QuestionnaireResultType } from "@/common/api/types/questionnaire";
import { FormatType } from "@/common/types/general/date-time";
import { timestampToDateTimeString } from "@/common/utils/helpers/date-time/timestamp-to-date-time-string";

type IProps = {
  result: QuestionnaireResultType;
};

export const useAdminQuestionnaireResultsCard = ({ result }: IProps) => {
  const avatar = result?.Organization?.Avatar?.FileUrl;
  const title = result?.Organization?.Name;
  const INN = result?.Organization?.INN;
  const createdText = timestampToDateTimeString(
    result?.File?.Created,
    FormatType.Datetime
  );

  return { avatar, title, INN, createdText };
};
