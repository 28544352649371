import { createSelector } from "reselect";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { Dictionaries } from "@/common/types/models/dictionary";
import { DictionariesModuleStore } from "@/common/features/dictionaries/types";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { getSelectApiOptions } from "@/common/utils/helpers/components/select/get-select-api-options";

export const dictionaryDataSelector = (dictionary: Dictionaries) =>
  createSelector(
    (state: DictionariesModuleStore) => state.DictionariesModule.dictionaries,
    (dictionaries) => dictionaries?.[dictionary] || EMPTY_ARRAY_DATA
  );

export const vacancyOptionsSelector = createSelector(
  dictionaryDataSelector(Dictionaries.LicenseCategory),
  dictionaryDataSelector(Dictionaries.VehicleBrand),
  dictionaryDataSelector(Dictionaries.VehicleType),
  dictionaryDataSelector(Dictionaries.WheelArrangement),
  dictionaryDataSelector(Dictionaries.EngineType),
  dictionaryDataSelector(Dictionaries.EcologyClass),
  dictionaryDataSelector(Dictionaries.Gearbox),
  dictionaryDataSelector(Dictionaries.CabinType),
  dictionaryDataSelector(Dictionaries.VehicleCondition),
  dictionaryDataSelector(Dictionaries.WorkSchedule),
  dictionaryDataSelector(Dictionaries.RegistrationMethod),
  dictionaryDataSelector(Dictionaries.PaymentTerm),
  dictionaryDataSelector(Dictionaries.PaymentFrequency),
  dictionaryDataSelector(Dictionaries.ExtraConditions),
  dictionaryDataSelector(Dictionaries.JobRegion),
  (
    LicenseCategory,
    VehicleBrand,
    VehicleType,
    WheelArrangement,
    EngineType,
    EcologyClass,
    Gearbox,
    CabinType,
    VehicleCondition,
    WorkSchedule,
    RegistrationMethod,
    PaymentTerm,
    PaymentFrequency,
    ExtraConditions,
    JobRegion
  ) => {
    const refData = {
      [FormFieldName.LicenseCategory]: {
        options: getSelectApiOptions(LicenseCategory, true),
      },
      [FormFieldName.Brand]: {
        options: getSelectApiOptions(VehicleBrand),
      },
      [FormFieldName.VehicleType]: {
        options: getSelectApiOptions(VehicleType),
      },
      [FormFieldName.WheelArrangement]: {
        options: getSelectApiOptions(WheelArrangement),
      },
      [FormFieldName.EngineType]: {
        options: getSelectApiOptions(EngineType),
      },
      [FormFieldName.EcologyClass]: {
        options: getSelectApiOptions(EcologyClass),
      },
      [FormFieldName.Gearbox]: {
        options: getSelectApiOptions(Gearbox),
      },
      [FormFieldName.CabinType]: {
        options: getSelectApiOptions(CabinType),
      },
      [Dictionaries.VehicleCondition]: {
        options: getSelectApiOptions(VehicleCondition),
      },
      [FormFieldName.WorkSchedule]: {
        options: getSelectApiOptions(WorkSchedule),
      },
      [FormFieldName.RegistrationMethod]: {
        options: getSelectApiOptions(RegistrationMethod),
      },
      [FormFieldName.PaymentTerms]: {
        options: getSelectApiOptions(PaymentTerm),
      },
      [FormFieldName.PaymentFrequency]: {
        options: getSelectApiOptions(PaymentFrequency),
      },
      [FormFieldName.Extra]: {
        options: getSelectApiOptions(ExtraConditions),
      },
      [FormFieldName.JobRegion]: {
        options: getSelectApiOptions(JobRegion),
      },
    };
    return { refData };
  }
);

export const vacancyFiltersRefDataSelector = createSelector(
  dictionaryDataSelector(Dictionaries.LicenseCategory),
  dictionaryDataSelector(Dictionaries.WorkSchedule),
  dictionaryDataSelector(Dictionaries.PaymentFrequency),
  dictionaryDataSelector(Dictionaries.JobRegion),
  (LicenseCategory, WorkSchedule, PaymentFrequency, JobRegion) => {
    const refData = {
      [FormFieldName.LicenseCategory]: {
        options: getSelectApiOptions(LicenseCategory, true),
      },
      [FormFieldName.WorkSchedule]: {
        options: getSelectApiOptions(WorkSchedule),
      },
      [FormFieldName.PaymentFrequency]: {
        options: getSelectApiOptions(PaymentFrequency),
      },
      [FormFieldName.JobRegion]: {
        options: getSelectApiOptions(JobRegion),
      },
    };
    return { refData };
  }
);

export const driverOptionsSelector = createSelector(
  dictionaryDataSelector(Dictionaries.JobSearchStatus),
  dictionaryDataSelector(Dictionaries.EmploymentType),
  dictionaryDataSelector(Dictionaries.WorkSchedule),
  dictionaryDataSelector(Dictionaries.EducationLevel),
  dictionaryDataSelector(Dictionaries.OwnVehicleType),
  dictionaryDataSelector(Dictionaries.LicenseCategory),
  dictionaryDataSelector(Dictionaries.VehicleType),
  dictionaryDataSelector(Dictionaries.JobRegion),
  (
    JobSearchStatus,
    EmploymentType,
    WorkSchedule,
    EducationLevel,
    OwnVehicleType,
    LicenseCategory,
    VehicleType,
    JobRegion
  ) => {
    const refData = {
      [FormFieldName.JobSearchStatus]: {
        options: getSelectApiOptions(JobSearchStatus),
      },
      [FormFieldName.EmploymentType]: {
        options: getSelectApiOptions(EmploymentType),
      },
      [FormFieldName.WorkSchedule]: {
        options: getSelectApiOptions(WorkSchedule),
      },
      [FormFieldName.EducationLevel]: {
        options: getSelectApiOptions(EducationLevel),
      },
      [FormFieldName.OwnVehicleType]: {
        options: getSelectApiOptions(OwnVehicleType),
      },
      [FormFieldName.LicenseCategory]: {
        options: getSelectApiOptions(LicenseCategory, true),
      },
      [FormFieldName.VehicleType]: {
        options: getSelectApiOptions(VehicleType),
      },
      [FormFieldName.JobSearchRegion]: {
        options: getSelectApiOptions(JobRegion),
      },
    };
    return { refData };
  }
);

export const driverFiltersSelector = createSelector(
  dictionaryDataSelector(Dictionaries.JobSearchStatus),
  dictionaryDataSelector(Dictionaries.EmploymentType),
  dictionaryDataSelector(Dictionaries.WorkSchedule),
  dictionaryDataSelector(Dictionaries.EducationLevel),
  dictionaryDataSelector(Dictionaries.LicenseCategory),
  dictionaryDataSelector(Dictionaries.OwnVehicleType),
  dictionaryDataSelector(Dictionaries.JobRegion),
  (
    JobSearchStatus,
    EmploymentType,
    WorkSchedule,
    Education,
    LicenseCategory,
    OwnVehicleType,
    JobRegion
  ) => {
    const refData = {
      [FormFieldName.JobSearchStatus]: {
        options: getSelectApiOptions(JobSearchStatus),
      },
      [FormFieldName.EmploymentType]: {
        options: getSelectApiOptions(EmploymentType),
      },
      [FormFieldName.WorkSchedule]: {
        options: getSelectApiOptions(WorkSchedule),
      },
      [FormFieldName.EducationLevel]: {
        options: getSelectApiOptions(Education),
      },
      [FormFieldName.LicenseCategory]: {
        options: getSelectApiOptions(LicenseCategory, true),
      },
      [FormFieldName.OwnVehicleType]: {
        options: getSelectApiOptions(OwnVehicleType),
      },
      [FormFieldName.JobSearchRegion]: {
        options: getSelectApiOptions(JobRegion),
      },
    };
    return { refData };
  }
);

export const driverDetailsSelector = createSelector(
  dictionaryDataSelector(Dictionaries.EmploymentType),
  (EmploymentType) => {
    const refData = {
      [FormFieldName.EmploymentType]: {
        options: getSelectApiOptions(EmploymentType),
      },
    };
    return { refData };
  }
);
