import { ButtonProps } from "antd";
import { ReactSVG } from "react-svg";
import { getSRCIconSVG } from "@/common/utils/helpers/icons/get-src-icon-svg";

export const getButtonIcon = (icon?: string | ButtonProps["icon"]) => {
  switch (typeof icon) {
    case "string":
      return <ReactSVG className="ils-svg" src={getSRCIconSVG(icon)} />;
    case "object":
      return icon === null ? undefined : icon;
    case "undefined":
    default:
      return null;
  }
};
