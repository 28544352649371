import { FC } from "react";
import { ILSButton } from "@/common/components/data-display/buttons";
import { SelectApiOption } from "@/common/types/general/common";

type IProps = {
  category: SelectApiOption;
  selectedCategories: Array<number>;
  handleCategoriesChange: (category: number) => void;
};

export const MyInfoFormLicenseCategoriesButton: FC<IProps> = ({
  category,
  selectedCategories,
  handleCategoriesChange,
}) => {
  const onClickCheckCategory = () => {
    handleCategoriesChange(Number(category?.value));
  };
  const isActive = selectedCategories?.includes(Number(category?.value));
  return (
    <ILSButton
      className="my-info-form__categories-button"
      onClick={onClickCheckCategory}
      type={isActive ? "primary" : "default"}
    >
      {category.alias}
    </ILSButton>
  );
};
