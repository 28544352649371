import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { recoveryProcessSelector } from "@modules/auth/selectors/recovery-process";
import { resetOptionsSelector } from "@modules/auth/selectors/reset-options";
import { useEffect } from "react";
import {
  checkPasswordRecoveryCodeActions,
  recoveryActions,
  recoveryProcessActions,
  resetPasswordActions,
} from "@modules/auth/actions";
import {
  CheckPasswordRecoveryCodeRequest,
  isRecoveryFormFieldsCheckCode,
  isRecoveryFormFieldsRecoveryPassword,
  isRecoveryFormFieldsResetPassword,
  RecoveryFormFields,
  RecoveryRequest,
  ResetPasswordRequest,
} from "@/common/api/types/auth";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";

export const useRecovery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recoveryProcess = useSelector(recoveryProcessSelector);
  const resetOptions = useSelector(resetOptionsSelector);

  useEffect(() => {
    dispatch(recoveryProcessActions({ recoveryProcess: "recovery" }));
    return () => {
      dispatch(recoveryProcessActions({ recoveryProcess: null }));
    };
  }, [dispatch]);

  const onFinish = (values: RecoveryFormFields) => {
    switch (recoveryProcess) {
      case "recovery":
        if (isRecoveryFormFieldsRecoveryPassword(values)) {
          const recoveryRequest: RecoveryRequest = {
            Login: getUnmaskedPhone(values.Login),
          };
          dispatch(recoveryActions(recoveryRequest));
        }
        break;
      case "code":
        if (isRecoveryFormFieldsCheckCode(values)) {
          const payloadCheckCode: CheckPasswordRecoveryCodeRequest = {
            Login: getUnmaskedPhone(values.Login),
            MessageType: "sms",
            Code: values.Code,
          };
          dispatch(checkPasswordRecoveryCodeActions(payloadCheckCode));
        }
        break;
      case "password":
        if (isRecoveryFormFieldsResetPassword(values)) {
          const payload: ResetPasswordRequest = {
            Login: getUnmaskedPhone(values.Login),
            MessageType: "sms",
            Code: values.Code,
            Password: values.Password,
            UserID: values.UserID,
          };
          dispatch(resetPasswordActions({ ...payload, navigate }));
        }
        break;
      default:
        showUserDevErrors({ e: "not implemented" });
    }
  };

  return { onFinish, recoveryProcess, resetOptions };
};
