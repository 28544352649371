import {
  EMPTY_ARRAY_DATA,
  EMPTY_OBJECT_DATA,
} from "@/common/constants/general";
import { AdminOrganizationsStore } from "@/modules/admin-panel/children/admin-organizations/types/store";

export const ADMIN_ORGANIZATIONS_INITIAL_STATE: AdminOrganizationsStore = {
  error: EMPTY_ARRAY_DATA,
  isFetching: false,
  organizations: EMPTY_OBJECT_DATA,
  users: EMPTY_OBJECT_DATA,
};
