import { FC } from "react";
import { ILSAccountWidget } from "@/common/components/layout/header/account/company/company-widget";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";

interface IProps {}

export const HeaderAccount: FC<IProps> = () => {
  return (
    <div className="header-component__center">
      <ILSAllowedTo perform={AbacPermission.auth}>
        <ILSAccountWidget />
      </ILSAllowedTo>
    </div>
  );
};
