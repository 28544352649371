import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { AbacPermission } from "@/common/types/core/abac";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { VacancyHeader } from "@/common/features/vacancy/components/header";
import { VacancyMainDetails } from "@/common/features/vacancy/components/sections/main-details";
import { VacancyVehicle } from "@/common/features/vacancy/components/sections/vehicle";
import { VacancyWorkingConditions } from "@/common/features/vacancy/components/sections/working-conditions";
import { VacancyOrganizationApplies } from "@/modules/social-services/children/vacancies/children/components/sections/applies";

type IProps = {
  vacancy: Vacancy;
};

export const VacancyContent: FC<IProps> = ({ vacancy }) => {
  return (
    <div className="vacancy__content">
      <div className="vacancy__content-group">
        <VacancyHeader vacancy={vacancy} />
        <VacancyMainDetails vacancy={vacancy} />
        <VacancyVehicle vacancy={vacancy} />
        <VacancyWorkingConditions vacancy={vacancy} />
      </div>
      <ILSAllowedTo
        perform={AbacPermission.hasActionsWithVacancies}
        data={vacancy}
      >
        <div className="vacancy__content-group">
          <ILSTitle className="vacancy__title">Отклики на вакансию</ILSTitle>
          <VacancyOrganizationApplies vacancy={vacancy} />
        </div>
      </ILSAllowedTo>
    </div>
  );
};
