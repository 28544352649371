import { GeneralState, PayloadActionError } from "@/common/types/core/store";

export const onFailure = <S extends GeneralState>(
  state: S,
  action: PayloadActionError
) => {
  const { error } = action.payload;
  state.error = error;
  return state;
};
