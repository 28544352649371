import { FC } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSTextArea } from "@/common/ui-components/data-entry/input/text-area";
import { ILSButton } from "@/common/components/data-display/buttons";
import { VacancyCommentFormValues } from "@/common/features/vacancy/types";

type IProps = {
  onSubmit: (values: VacancyCommentFormValues) => void;
};

export const VacancyCommentForm: FC<IProps> = ({ onSubmit }) => {
  return (
    <Form
      onFinish={onSubmit}
      layout="vertical"
      className="vacancy__comment-form"
    >
      <ILSFormItem field={FormFieldName.Comment}>
        <ILSTextArea />
      </ILSFormItem>
      <ILSButton htmlType="submit" type="primary" icon={<CloseOutlined />}>
        Отклонить
      </ILSButton>
    </Form>
  );
};
