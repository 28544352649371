import { FC } from "react";
import { Layout, LayoutProps } from "antd";
import {
  ContentProps,
  FooterProps,
  HeaderProps,
} from "@/common/components/layout/types";

const { Header, Footer, Content } = Layout;

export const ILSLayout: FC<LayoutProps> = (props) => {
  return <Layout {...props} />;
};

export const ILSHeader: FC<HeaderProps> = (props) => {
  return <Header className="ils-header" {...props} />;
};

export const ILSContent: FC<ContentProps> = (props) => {
  return <Content {...props} />;
};

export const ILSFooter: FC<FooterProps> = (props) => {
  return <Footer {...props} />;
};
