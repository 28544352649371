import { useEffect } from "react";
import { useSelector } from "react-redux";
import { driverSelector } from "@/common/features/drivers-common/selectors";
import { useDriversOperations } from "@/common/features/drivers-common/hooks/use-drivers-operations";
import { useDriverDetailsParams } from "@/common/features/drivers-common/hooks/use-driver-details-params";
import { getDriverAvatar } from "@/common/features/drivers-common/helpers/get-driver-avatar";

export const useDriverDetails = () => {
  const { driverId } = useDriverDetailsParams();

  const selectedDriver = useSelector(driverSelector(driverId));
  const { handleGetDriver } = useDriversOperations();

  useEffect(() => {
    if (driverId) {
      handleGetDriver(Number(driverId));
    }
  }, []);

  const driverAvatar = getDriverAvatar(selectedDriver);

  return {
    driver: selectedDriver,
    driverAvatar,
  };
};
