import { useAbac } from "react-abac";
import { AbacPermission } from "@/common/types/core/abac";
import { ModulePath } from "@/common/types/core/routes";

export const useStartPageVacancies = () => {
  const { userHasPermissions } = useAbac();
  const adminPanel = userHasPermissions(AbacPermission.adminPanel);

  const vacanciesPath = adminPanel
    ? `/${ModulePath.adminPanel}/${ModulePath.adminVacancies}`
    : `/${ModulePath.socialServices}/${ModulePath.vacancies}`;

  return { vacanciesPath };
};
