import { FC } from "react";
import { ILSImage } from "@/common/components/data-display/image";
import { OrganizationInfo } from "@/common/types/models/account";
import { OrganizationMainInfo } from "@/modules/social-services/children/organizations/children/components/organization-main-info";
import { OrganizationBackLink } from "@/modules/social-services/children/organizations/children/components/organization-back-link";

type IProps = {
  organization: OrganizationInfo | null;
  prevVacancyLocation?: string | undefined;
};

export const OrganizationContent: FC<IProps> = ({
  organization,
  prevVacancyLocation,
}) => {
  return (
    <div className="organization__content">
      <div className="driver-details__content-group">
        <OrganizationBackLink prevVacancyLocation={prevVacancyLocation} />
        <div className="organization__row">
          <OrganizationMainInfo organization={organization} />
          {organization?.Avatar?.FileUrl && (
            <ILSImage
              src={organization?.Avatar?.FileUrl}
              className="organization__avatar"
            />
          )}
        </div>
      </div>
    </div>
  );
};
