import { ApiPost } from "@/common/api/helpers/post";
import { ApiGet } from "@/common/api/helpers/get";
import {
  ChangePasswordRequest,
  CheckPasswordRecoveryCodeRequest,
  LoginRequest,
  RecoveryRequest,
  RegisterRequest,
  ResetPasswordRequest,
} from "@/common/api/types/auth";
import { ApiPut } from "@/common/api/helpers/put";
import { User } from "@/common/types/models/user/user";

export const getAuthEndpoint = (method: string) => {
  return `/auth/${method}`;
};

export const AUTH_API = {
  register: (body: RegisterRequest) => {
    return ApiPost(getAuthEndpoint("register"), body);
  },
  login: (body: LoginRequest) => {
    return ApiPost(getAuthEndpoint("login"), body);
  },
  logout: () => {
    return ApiGet(getAuthEndpoint("logout"));
  },
  passwordRecovery: (body: RecoveryRequest) => {
    return ApiPut(getAuthEndpoint("passwordRecovery"), body);
  },
  resetPassword: (body: ResetPasswordRequest) => {
    return ApiPut(getAuthEndpoint("resetPassword"), body);
  },
  checkPasswordRecoveryCode: (body: CheckPasswordRecoveryCodeRequest) => {
    return ApiPut<
      Record<User["ID"], { UserID: User["ID"]; Name: User["Name"] | null }>
    >(getAuthEndpoint("checkPasswordRecoveryCode"), body);
  },
  changePassword: (body: ChangePasswordRequest) => {
    return ApiPut(getAuthEndpoint("changePassword"), body);
  },
  checkUser: () => {
    return ApiGet(getAuthEndpoint("me"));
  },
};
