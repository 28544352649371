import { FC } from "react";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSRadioGroup } from "@/common/ui-components/data-entry/radio/group";
import { COMPANY_OPTIONS } from "@/modules/auth/children/register/constants/form-fields";
import { useRegisterFields } from "@/modules/auth/children/register/hooks/use-register-fields";
import { ConsentToDataProcessingField } from "@/modules/auth/children/register/components/consent-to-data-processing-field";

interface IProps {}

export const RegisterFormFields: FC<IProps> = () => {
  const { accountRole, formItems } = useRegisterFields();
  return (
    <>
      <ILSFormItem name={accountRole}>
        <ILSRadioGroup
          optionType="button"
          options={COMPANY_OPTIONS}
          buttonStyle="solid"
        />
      </ILSFormItem>
      {renderFormItems({ fields: formItems })}
      <ConsentToDataProcessingField />
    </>
  );
};
