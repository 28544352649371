import { StartPageHeader } from "@/modules/start/components/header/start-page-header";
import { StartPageSiderBannersList } from "@/modules/start/components/sider-banners/sider-banners-list/sider-banners-list";
import { StartPageSurveyContainer } from "@/modules/start/containers/survey";

export const StartPageLeftSider = () => {
  return (
    <div className="left-sider">
      <StartPageSurveyContainer />
      <StartPageHeader>Проекты</StartPageHeader>
      <StartPageSiderBannersList />
    </div>
  );
};
