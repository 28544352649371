import { getMeanAccountRating } from "@/common/helpers/get-mean-account-rating";
import { useAuth } from "@/common/hooks/use-auth";

export const useAccount = () => {
  const { userRole, user, accountRole } = useAuth();
  const accountName = user.Account.Name;
  const avatar = user?.Avatar;
  const userRating = user.Rating;
  const accountRating = getMeanAccountRating(userRating);
  const { Account } = user;

  return {
    user,
    accountName,
    accountRating,
    avatar,
    userRating,
    account: Account,
    userRoles: userRole,
    accountRole,
  };
};
