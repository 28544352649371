import { ILSLink } from "@/common/ui-components/general/typography/link";
import { ModuleTitle } from "@/common/types/core/module";
import { ModulePath } from "@/common/types/core/routes";
import { OrganizationInfo } from "@/common/types/models/account";

export const getOrganizationBreadCrumbs = (
  organization: OrganizationInfo | null
) => {
  return [
    {
      title: (
        <ILSLink
          to={`/${ModulePath.adminPanel}/${ModulePath.adminOrganizations}`}
          relative="path"
        >
          {ModuleTitle.adminOrganizations}
        </ILSLink>
      ),
    },
    {
      title: (
        <ILSLink
          to={`/${ModulePath.adminPanel}/${ModulePath.adminOrganizations}/${organization?.ID}`}
          relative="path"
        >
          {organization?.Name}
        </ILSLink>
      ),
    },
  ];
};
