import "../index.scss";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { ILSTabPane, ILSTabs } from "@/common/components/data-display/tabs";
import { VACANCIES_DRIVER_TAB_TITLES } from "@/modules/social-services/children/vacancies/constants/tabs";
import { useVacanciesModule } from "@/common/features/vacancies-common/hooks/use-vacancies-module";
import { DriverApplies } from "@/modules/social-services/children/vacancies/containers/applies";
import { VacanciesMain } from "@/modules/social-services/children/vacancies/containers/main";
import { VacanciesDriverTabs } from "@/modules/social-services/children/vacancies/types/tabs";

interface IProps {}

export const Vacancies: FC<IProps> = () => {
  const { showIndexModule, isDriver, vacanciesDriverTab, handleChangeTab } =
    useVacanciesModule();

  if (showIndexModule) {
    return !isDriver ? (
      <VacanciesMain showIndexModule={showIndexModule} />
    ) : (
      <ILSTabs
        type="line"
        tabPosition="top"
        activeKey={vacanciesDriverTab}
        onTabClick={handleChangeTab}
      >
        <ILSTabPane
          tab={VACANCIES_DRIVER_TAB_TITLES[VacanciesDriverTabs.Vacancies]}
          key={VacanciesDriverTabs.Vacancies}
        >
          <VacanciesMain
            showIndexModule={showIndexModule}
            vacanciesDriverTab={vacanciesDriverTab}
          />
        </ILSTabPane>
        <ILSTabPane
          tab={VACANCIES_DRIVER_TAB_TITLES[VacanciesDriverTabs.Applies]}
          key={VacanciesDriverTabs.Applies}
        >
          <DriverApplies vacanciesDriverTab={vacanciesDriverTab} />
        </ILSTabPane>
      </ILSTabs>
    );
  }

  return <Outlet />;
};
