import { RouteObject } from "react-router-dom";
import { IModuleModel } from "@/common/types/core/module";

export const getRoute = (module: IModuleModel): RouteObject => {
  return {
    path: module.path,
    element: module.element,
    availableRoles: module.availableRoles,
    children: Object.values(module.children ?? {}).map(getRoute),
    isDefault: module.isDefault,
    availableAccountRoles: module.availableAccountRoles,
  };
};
