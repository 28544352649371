import { useRef } from "react";
import { UploadFile } from "antd";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { DeletedImageRequest } from "@/common/types/models/image";

/**
 * При клике на кнопку удаления, сохраняет ID удаляемых изображений в ref
 * и передаем вместе с новыми изображениями на сервер, чтобы удалить указанные изображения.
 */
export const useRemoveFile = () => {
  const removedSavedFiles = useRef<DeletedImageRequest[]>(EMPTY_ARRAY_DATA);

  const onRemoveFile = (file: UploadFile) => {
    const isPreviouslyUploadedFile = Boolean(!file.originFileObj);

    if (isPreviouslyUploadedFile) {
      const deletedFile = { DeleteID: file?.uid };
      removedSavedFiles.current = [...removedSavedFiles.current, deletedFile];
    }

    return true;
  };

  return { removedSavedFiles: removedSavedFiles.current, onRemoveFile };
};
