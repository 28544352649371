import "./index.scss";
import { isEmpty } from "lodash";
import { UploadFile } from "antd";
import { FC } from "react";
import { ILSImgCrop } from "@/common/ui-components/data-entry/image-crop";
import { ILSUpload } from "@/common/ui-components/data-entry/upload";
import {
  AVATAR_MAX_SIZE_TEN_MB,
  PHOTO_PERMITTED_EXTENSIONS,
} from "@/common/constants/files";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { preventAntdRequest } from "@/common/utils/helpers/components/avatar/prevent-ant-request";
import { handlePreviewFile } from "@/common/utils/helpers/components/avatar/handle-preview-file";

export type IILSFormAvatarProps = {
  fileList: UploadFile[] | undefined;
  disabled?: boolean;
  handleAvatarChange: ({ fileList }: { fileList: Array<UploadFile> }) => void;
  handleAvatarRemove: (file: UploadFile) => void;
};

export const ILSFormAvatar: FC<IILSFormAvatarProps> = ({
  fileList,
  disabled,
  handleAvatarChange,
  handleAvatarRemove,
}) => {
  const filesNotEmpty = isEmpty(fileList);
  return (
    <div className="form-avatar">
      <ILSImgCrop
        aspect={4 / 3}
        fileMaxSize={AVATAR_MAX_SIZE_TEN_MB}
        modalTitle="Отредактируйте изображение"
      >
        <ILSUpload
          multiple={false}
          listType="picture-card"
          fileList={fileList}
          accept={PHOTO_PERMITTED_EXTENSIONS}
          disabled={Boolean(disabled)}
          showUploadList={{ showPreviewIcon: false }}
          onChange={handleAvatarChange}
          onRemove={handleAvatarRemove}
          previewFile={handlePreviewFile}
          customRequest={preventAntdRequest}
          name={FormFieldName.Avatar}
        >
          {filesNotEmpty && "+ Загрузить"}
        </ILSUpload>
      </ILSImgCrop>
    </div>
  );
};
