import classNames from "classnames";
import { isNil } from "lodash";
import { NamePath } from "rc-field-form/lib/interface";
import { EMPTY_ARRAY_DATA, EMPTY_STRING } from "@/common/constants/general";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { RefDataType } from "@/common/features/dictionaries/types";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";
import { FormPlaceholder } from "@/common/ui-components/data-entry/form/constants/placeholder";

type IProps = {
  fields: FormItemsFields;
  fieldIndex?: number;
  disabled?: boolean;
  refData?: RefDataType;
};

export const renderFormItems = ({
  fields,
  fieldIndex,
  disabled = false,
  refData,
}: IProps) => {
  return fields.map(({ Component, inputProps = {}, fieldProps = {} }) => {
    const { field, name } = fieldProps;

    const formItemName = name ?? (field as NamePath);
    const fieldName = (
      isNil(fieldIndex) ? formItemName : [fieldIndex, formItemName]
    ) as NamePath;

    const placeholder = field ? FormPlaceholder?.[field] : EMPTY_STRING;

    const options = field ? refData?.[field]?.options : EMPTY_ARRAY_DATA;

    const { forbiddenKeys } = inputProps;

    const normalizeValue = (
      value: string | number | undefined,
      prevValue: string | number | undefined
    ) => {
      if (forbiddenKeys && value && forbiddenKeys.test(value)) {
        return prevValue;
      }
      return value;
    };

    return (
      <ILSFormItem
        key={field}
        name={fieldName}
        normalize={normalizeValue}
        style={{ margin: 0 }}
        {...fieldProps}
      >
        <Component
          placeholder={placeholder}
          disabled={disabled}
          options={options}
          {...inputProps}
          className={classNames(
            `ils-form__input ils-form__input_${field}`,
            inputProps?.className
          )}
        />
      </ILSFormItem>
    );
  });
};
