export enum ModulePath {
  startPage = "/",
  // auth
  auth = "auth",
  login = "login",
  register = "register",
  recovery = "recovery",
  // social-services
  socialServices = "social-services",
  vacancies = "vacancies",
  vacancy = ":vacancy-id",
  adminVacancy = ":vacancy-id",
  driverSearch = "driver-search",
  driversCommon = "drivers-common",
  driverDetails = ":driver-details",
  myRatingApplication = ":my-rating-application",
  myInfo = "my-info",
  contacts = "contacts",
  projects = "projects",
  resources = "resources",
  store = "store",
  underConstruction = "under-construction",
  organizations = "organizations",
  organization = ":organization",
  legalConsultation = "legal-consultation",
  // Admin panel
  adminPanel = "admin-panel",
  adminArticles = "admin-articles",
  adminOrganizations = "admin-organizations",
  adminOrganization = ":admin-organization",
  adminEmployee = "admin-employee",
  adminDrivers = "admin-drivers",
  adminVacancies = "admin-vacancies",
  adminApplications = "admin-applications",
  adminApplication = ":admin-application",
  // common
  dictionaries = "dictionaries",
  dataProcessingRules = "data-processing-rules",
  questionnaireCommon = "questionnaire-common",
}

export type Route = {
  path: ModulePath;
  element?: JSX.Element | null;
  availableRoles?: Array<any>;
  availableAccountRoles?: Array<any>;
  children?: Array<Route>;
  isDefault?: boolean;
};

export type RoutesRulesType = {
  permissions: Record<string, string>;
  rules: Record<string, any>;
};
