import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

export enum VerificationFormType {
  getCode = "getCode",
  checkCode = "checkCode",
}

/** Значения полей формы "Сотрудник" */
export type VerificationFormValues = {
  [FormFieldName.Phone]: string | undefined;
  [FormFieldName.VerificationCode]: string | undefined;
};
