import { FC } from "react";
import { Drawer, FormInstance } from "antd";
import { DriverSearchFilters } from "@modules/social-services/children/driver-search/containers/filters";
import { FilterOutlined } from "@ant-design/icons";
import { SocialServicesToolbar } from "@modules/social-services/containers/toolbar";
import { DriverReview } from "@modules/social-services/children/driver-search/components/driver-review";
import { DriversFiltersFormValues } from "@/common/features/drivers-common/types/drivers-filters";
import { RefDataType } from "@/common/features/dictionaries/types";
import { ILSButton } from "@/common/components/data-display/buttons";
import { useDrawer } from "@/common/hooks/use-drawer";

interface IProps {
  form: FormInstance<DriversFiltersFormValues>;
  refData: RefDataType;
  disableFilters: boolean;
  resetFilters: VoidFunction;
  onValuesChange: (
    _: DriversFiltersFormValues,
    values: DriversFiltersFormValues
  ) => void;
}

export const DriverSearchToolbar: FC<IProps> = ({
  form,
  refData,
  disableFilters,
  resetFilters,
  onValuesChange,
}) => {
  const { open, onOpen, onClose } = useDrawer();

  return (
    <SocialServicesToolbar>
      <DriverReview />
      <ILSButton
        children="Фильтры"
        type="primary"
        className="filters"
        icon={<FilterOutlined />}
        onClick={onOpen}
      />
      <Drawer
        title="Фильтры поиска вакансий"
        size="default"
        open={open}
        onClose={onClose}
        placement="left"
      >
        <DriverSearchFilters
          form={form}
          hide={false}
          refData={refData}
          resetFilters={resetFilters}
          onValuesChange={onValuesChange}
          disabled={disableFilters}
        />
      </Drawer>
    </SocialServicesToolbar>
  );
};
