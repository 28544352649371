import { StartPageFooterDesktop } from "@/modules/start/components/footer/desktop";
import { StartPageFooterMobile } from "@/modules/start/components/footer/mobile";

export const StartPageFooter = () => {
  return (
    <>
      <StartPageFooterDesktop />
      <StartPageFooterMobile />
    </>
  );
};
