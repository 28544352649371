import { isEmpty } from "lodash";
import { EMPTY_STRING, JoinChar } from "@/common/constants/general";
import { RoleAccount } from "@/common/types/models/account";
import { RoleUser } from "@/common/types/models/user/user";

export const getRolesName = (accountRoles: Array<RoleAccount | RoleUser>) => {
  if (isEmpty(accountRoles)) {
    return null;
  }
  return accountRoles.reduce((acc, role) => {
    if (acc) {
      return `${acc}${JoinChar.Comma}${role.Name}`;
    }
    return role.Name;
  }, EMPTY_STRING);
};
