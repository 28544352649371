import { useEffect } from "react";
import { useGetVacancies } from "@/common/features/vacancies-common/hooks/use-get-vacancies";
import { useChangeVacanciesFilters } from "@/common/features/vacancies-common/hooks/use-vacancies-filters";
import { useVacanciesSidebarForm } from "@/common/features/vacancies-common/hooks/use-vacancies-sidebar-form";
import { useVacanciesToolbarForm } from "@/common/features/vacancies-common/hooks/use-vacancies-toolbar-form";
import { useVacanciesSort } from "@/common/features/vacancies-common/hooks/use-vacancies-sort";

export const useAdminVacancies = (showIndexModule: boolean) => {
  const { filters, changeFilters, resetFilters } = useChangeVacanciesFilters();

  const { vacancies, loading } = useGetVacancies(filters);

  /** Фильтры в Toolbar */
  const { toolbarForm, onToolbarFormChange, resetToolbarForm } =
    useVacanciesToolbarForm({
      isAdminPanel: true,
      changeFilters,
    });

  /** Фильтры в Sidebar */
  const {
    sidebarForm,
    onSidebarFormChange,
    resetSidebarForm,
    onClickResetSidebarForm,
    sidebarRefData,
  } = useVacanciesSidebarForm({
    changeFilters,
  });

  /** Сортировка */
  const { activeSortKey, onSortClick } = useVacanciesSort({
    filters,
    changeFilters,
  });

  useEffect(() => {
    resetToolbarForm();
    resetSidebarForm();
    resetFilters({
      isAdminPanel: true,
    });
  }, [showIndexModule]);

  return {
    vacancies,
    loading,
    toolbarForm,
    sidebarForm,
    sidebarRefData,
    activeSortKey,
    onToolbarFormChange,
    onSidebarFormChange,
    onClickResetSidebarForm,
    onSortClick,
  };
};
