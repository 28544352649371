import { FC } from "react";
import { Driver } from "@/common/types/models/user/driver";
import { FormatType } from "@/common/types/general/date-time";
import { NO_DRIVER_LICENSE } from "@/common/features/drivers-common/constants";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { timestampToDateTimeString } from "@/common/utils/helpers/date-time/timestamp-to-date-time-string";
import { getDictionaryArrayName } from "@/common/helpers/get-dictionary-array-name";

type IProps = {
  driver: Driver | undefined | null;
};

export const DriverDetailsLicense: FC<IProps> = ({ driver }) => {
  return (
    <section className="driver-details__section">
      <div className="driver-details__section-column">
        <ILSTitle level={2} className="driver-details__section-title">
          Водительское удостоверение
        </ILSTitle>
        {driver?.HaveDriverLicense ? (
          <div className="driver-details__section-content">
            <DetailDescription caption="Номер" value={driver?.DriverLicense} />
            <DetailDescription
              caption="Дата выдачи"
              value={timestampToDateTimeString(
                driver?.DriverLicenseDate,
                FormatType.Date
              )}
            />
            <DetailDescription
              caption="Категории"
              value={getDictionaryArrayName(driver?.LicenseCategory, true)}
            />
          </div>
        ) : (
          <p className="driver-details__section-text">{NO_DRIVER_LICENSE}</p>
        )}
      </div>
    </section>
  );
};
