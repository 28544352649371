import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import {
  MY_INFO_INITIAL_STATE,
  MyInfoStore,
} from "@modules/social-services/children/my-info/store";
import { sendCodeActions } from "@modules/social-services/children/my-info/actions";
import { onFailure } from "@/common/helpers/reducers/on-failure";

export const sendCodeReducer = createReducer(MY_INFO_INITIAL_STATE, {
  [sendCodeActions.REQUEST]: (state: MyInfoStore) => {
    state.verification.isFetching = true;
    return state;
  },
  [sendCodeActions.FAILURE]: onFailure,
  [sendCodeActions.SUCCESS]: (
    state,
    action: PayloadAction<{ target: string | null }>
  ) => {
    const { target } = action.payload;
    state.verification.target = target || null;
    return state;
  },
  [sendCodeActions.FULFILL]: (state: MyInfoStore) => {
    state.verification.isFetching = false;
    return state;
  },
});
