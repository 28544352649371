import { isNil } from "lodash";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { QuestionnaireAvailabilityStatus } from "@/common/api/types/questionnaire";

export const getQuestionnaireStatusCheckboxLabel = (
  availabilityStatus: QuestionnaireAvailabilityStatus | null
) => {
  if (isNil(availabilityStatus)) {
    return FORM_LABEL[FormFieldName.QuestionnaireStatus];
  }

  const available =
    availabilityStatus === QuestionnaireAvailabilityStatus.available;

  return `Сделать шаблон анкеты ${
    available ? "недоступным" : "доступным"
  } для организаций`;
};
