import { GeneralState } from "@/common/types/core/store";
import { Article, Articles } from "@/common/api/types/article";

export type AdminArticlesStore = GeneralState & {
  articles: Articles;
  currentArticle: Article | null;
};

export type AdminArticlesModuleStore = {
  AdminArticlesModule: AdminArticlesStore;
};

export const ADMIN_ARTICLES_INITIAL_STATE: AdminArticlesStore = {
  error: [],
  isFetching: false,
  articles: [],
  currentArticle: null,
};
