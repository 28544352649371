import { call, put, takeLatest } from "redux-saga/effects";
import { driversRoutine } from "@/common/features/drivers-common/actions";
import { DriverSearchPayload } from "@/common/features/drivers-common/types/store";
import { DRIVERS_API } from "@/common/api/endpoints/drivers";

export function* DriversWorker(action: DriverSearchPayload) {
  const { request, success, failure, fulfill } = driversRoutine;
  const { operation, id, filters, customNotificationMessage } = action.payload;

  try {
    yield put(request({ operation, customNotificationMessage }));
    const { data } = yield call(DRIVERS_API.driversOperation, {
      operation,
      id,
      filters,
    });

    yield put(success({ operation, data, id, customNotificationMessage }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill({ operation, customNotificationMessage }));
  }
}

export function* driversWatcher() {
  yield takeLatest(driversRoutine.trigger, DriversWorker);
}
