import { AnyAction, Reducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { TReducersObj } from "@/common/types/core/module";
import { CoreState } from "../persist/config";

export function createReducerManager(initialReducers: TReducersObj) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };
  const initialKeys = Object.keys(initialReducers);

  // Create the initial combinedReducer
  let combinedReducer: Reducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove: string[] = [];

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state: CoreState, action: AnyAction) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        // eslint-disable-next-line no-param-reassign
        state = { ...state };
        // eslint-disable-next-line no-restricted-syntax
        for (const key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }

      // Delegate to the combined reducer
      return combinedReducer(state, action as never);
    },

    // Adds a new reducer with the specified key
    add: (key: string, reducer: Reducer) => {
      if (!key || reducers[key]) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    // Removes a reducer with the specified key
    remove: (key: string) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      keysToRemove.push(key);

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    replace: (keys: string[]) => {
      if (!keys) return;

      const newKeys = [...initialKeys, ...keys];
      keysToRemove = Object.keys(reducers).filter(
        (key) => !newKeys.includes(key)
      );

      keysToRemove.forEach((key: string) => {
        delete reducers[key];
      });

      combinedReducer = combineReducers(reducers);
    },
  };
}
