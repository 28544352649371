import "./index.scss";
import { FC } from "react";
import classNames from "classnames";
import { ILSImage } from "@/common/components/data-display/image";

type IProps = {
  className?: string;
};

export const ILSMembershipBadge: FC<IProps> = ({ className }) => {
  return (
    <ILSImage
      width={28}
      height={28}
      preview={false}
      className={classNames("membership-badge", className)}
      src={`${process.env.PUBLIC_URL}/assets/images/start-page/logo/logo_short.png`}
    />
  );
};
