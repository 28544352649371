import { useCallback, useState } from "react";
import { SideBarMode } from "@/modules/admin-panel/children/admin-drivers/types/general";

export const useAdminDrivers = () => {
  const [mode, setMode] = useState<SideBarMode | false>(false);

  const showSidebar = useCallback((type: SideBarMode) => {
    setMode(type);
  }, []);

  const onClose = useCallback(() => {
    setMode(false);
  }, []);

  return { showSidebar, onClose, mode };
};
