import "./index.scss";
import { ProtectedPage } from "@pages/protected-page";
import { AbacPermission } from "@/common/types/core/abac";
import { useMyInfo } from "@/modules/social-services/children/my-info/hooks/use-my-info";
import { renderMyInfoContent } from "@/modules/social-services/children/my-info/helpers/utils/render-my-info-content";

export const MyInfo = () => {
  const { myInfoContentType } = useMyInfo();

  return (
    <ProtectedPage perform={AbacPermission.authContent}>
      {renderMyInfoContent({ myInfoContentType })}
    </ProtectedPage>
  );
};
