import { FC } from "react";
import { Flex } from "antd";
import { ArticleCategories } from "./article-categories";
import { ArticleSort } from "./article-sort";

interface IProps {}

export const AdminArticlesFilter: FC<IProps> = () => {
  return (
    <Flex
      align="end"
      gap={8}
      wrap="wrap"
      justify="space-between"
      className="admin-articles-filter"
    >
      <ArticleCategories />
      <ArticleSort />
    </Flex>
  );
};
