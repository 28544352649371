import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";
import { VacanciesSidebarFilterValues } from "@/common/features/vacancies-common/types/filters";
import { VACANCIES_SIDEBAR_INITIAL_VALUES } from "@/common/features/vacancies-common/constants/filters";
import { vacancyFiltersRefDataSelector } from "@/common/features/dictionaries/selectors";
import { sidebarFiltersToValues } from "@/common/features/vacancies-common/helpers/sidebar-filters-to-values";

type IProps = {
  changeFilters: (currentFilters: VacanciesFilters | undefined) => void;
};

/**
 * Хук, управляющий формой с фильтрами в компоненте Toolbar
 * в разделе "Вакансии", "Управление вакансиями"
 */

export const useVacanciesSidebarForm = ({ changeFilters }: IProps) => {
  const [form] = useForm<VacanciesSidebarFilterValues>();
  const { refData } = useSelector(vacancyFiltersRefDataSelector);

  const onSidebarFormChange = (
    _: VacanciesSidebarFilterValues,
    values: VacanciesSidebarFilterValues
  ) => {
    changeFilters(sidebarFiltersToValues(values));
  };

  const resetSidebarForm = () => {
    form.resetFields();
    form.setFieldsValue(VACANCIES_SIDEBAR_INITIAL_VALUES);
  };

  const onClickResetSidebarForm = () => {
    resetSidebarForm();
    changeFilters(sidebarFiltersToValues(VACANCIES_SIDEBAR_INITIAL_VALUES));
  };

  return {
    sidebarForm: form,
    sidebarRefData: refData,
    onSidebarFormChange,
    resetSidebarForm,
    onClickResetSidebarForm,
  };
};
