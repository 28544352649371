import { SIDER_BANNER_BIRTHDAYS_TITLE } from "@/modules/start/constants/banners";
import { SiderBannerEmpty } from "@/modules/start/components/sider-banners/sider-banner-empty/sider-banner-empty";
import { StartPageUsefulLinks } from "@/modules/start/components/useful-links/list/useful-links-list";

export const StartPageRightSider = () => {
  return (
    <div className="right-sider">
      <SiderBannerEmpty title={SIDER_BANNER_BIRTHDAYS_TITLE} />
      <StartPageUsefulLinks />
    </div>
  );
};
