import { AxiosResponse } from "axios";
import { ApiPost } from "@/common/api/helpers/post";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { SurveyBaseRequest } from "@/common/api/types/survey";

export const getSurveyEndpoint = () => {
  return "/sendQuiz";
};

export function surveyOperation({
  operation,
  body,
}: SurveyBaseRequest): Promise<AxiosResponse> {
  const surveyEndpoint = getSurveyEndpoint();

  switch (operation) {
    case OperationsEndpoint.Create:
      return ApiPost(surveyEndpoint, body);
    default:
      return ApiPost(surveyEndpoint);
  }
}

export const SURVEY_API = {
  surveyOperation,
};
