import { isArray } from "lodash";
import {
  APIAxiosError,
  AxiosErrorWithMessagesType,
} from "@/common/api/types/error";

export const isErrorsAPI = (
  error: APIAxiosError
): error is AxiosErrorWithMessagesType => {
  return isArray(error?.response?.data?.data?.errors);
};
