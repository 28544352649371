import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import * as vacanciesRootSagas from "@/common/features/vacancies-common/sagas";
import { vacanciesRootReducer } from "@/common/features/vacancies-common/reducers";
import { DictionariesModule } from "@/common/features/dictionaries";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

const module: IModuleModel = {
  title: ModuleTitle.vacanciesCommon,
  name: ModuleName.vacanciesCommon,
  path: ModulePath.vacancies,
  children: { DictionariesModule },
  reducer: vacanciesRootReducer,
  sagas: getAllModuleSagas(vacanciesRootSagas),
};

export const VacanciesCommonModule = defineModule(module);
