import { normalizeLicenseCategories } from "@modules/social-services/children/my-info/helpers/decorators/normalize-license-categories";
import { isNil } from "lodash";
import { EMPTY_STRING } from "@/common/constants/general";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { Driver } from "@/common/types/models/user/driver";
import { DriverFormValues } from "@/common/features/drivers-common/types/driver-form";
import { getObjectValuesWithType } from "@/common/utils/helpers/types";
import { getDateStringFromUnix } from "@/common/helpers/dates/get-date-string-from-unix";
import { drivingExperienceToFormValues } from "@/common/features/drivers-common/helpers/driving-experience-to-form-values";
import { getImagesToForm } from "@/common/utils/helpers/components/avatar/get-images-to-form";
import { formatPriceString } from "@/common/utils/helpers/price/format-price-string";
import { getFormArrayIDValues } from "@/common/features/drivers-common/helpers/get-form-array-id-values";

type IProps = {
  driverInfo: Driver | null;
};

export const driverToFormValues = ({
  driverInfo,
}: IProps): DriverFormValues => {
  return getObjectValuesWithType<FormFieldName>(FormFieldName).reduce(
    (initialValues, formKey) => {
      if (!driverInfo) {
        return initialValues;
      }
      switch (formKey) {
        case FormFieldName.Name:
        case FormFieldName.Surname:
        case FormFieldName.Patronymic:
        case FormFieldName.Citizens:
        case FormFieldName.Email:
        case FormFieldName.Phone:
          initialValues[formKey] = driverInfo?.[formKey] || EMPTY_STRING;
          return initialValues;

        case FormFieldName.MedicalCertificate:
        case FormFieldName.Sex:
          initialValues[formKey] = !isNil(driverInfo?.[formKey])
            ? Number(driverInfo?.[formKey])
            : null;
          return initialValues;

        case FormFieldName.OwnVehicleType:
        case FormFieldName.WorkSchedule:
        case FormFieldName.JobSearchRegion:
          initialValues[formKey] = getFormArrayIDValues(driverInfo?.[formKey]);
          return initialValues;

        case FormFieldName.Birthday:
          initialValues[formKey] = getDateStringFromUnix(driverInfo?.[formKey]);
          return initialValues;

        case FormFieldName.DriverLicense:
          if (initialValues.HaveDriverLicense) {
            initialValues[formKey] = driverInfo.DriverLicense;
          }
          return initialValues;
        case FormFieldName.DriverLicenseDate:
          if (initialValues.HaveDriverLicense) {
            initialValues[formKey] = getDateStringFromUnix(
              driverInfo?.[formKey]
            );
          }
          return initialValues;

        case FormFieldName.LicenseCategory:
          initialValues[formKey] = normalizeLicenseCategories(
            driverInfo?.LicenseCategory
          );
          return initialValues;

        case FormFieldName.JobSearchStatus:
        case FormFieldName.EducationLevel:
        case FormFieldName.EmploymentType:
          initialValues[formKey] = driverInfo?.[formKey]?.ID ?? null;
          return initialValues;

        case FormFieldName.DrivingExperience:
          initialValues[formKey] = drivingExperienceToFormValues(
            driverInfo?.[formKey]
          );
          return initialValues;

        case FormFieldName.Avatar:
          initialValues[formKey] = getImagesToForm(driverInfo?.Avatar);
          return initialValues;

        case FormFieldName.ShowAvatar:
        case FormFieldName.ReadyToStartTomorrow:
        case FormFieldName.HaveDriverLicense:
        case FormFieldName.ReadyForRelocation:
        case FormFieldName.ReadyForTrips:
        case FormFieldName.PublicOrganizationMembership:
          initialValues[formKey] = Boolean(driverInfo?.[formKey]);
          return initialValues;

        case FormFieldName.MembershipChecked:
          initialValues[formKey] = !isNil(driverInfo?.[formKey])
            ? Number(driverInfo?.[formKey])
            : null;
          return initialValues;

        case FormFieldName.Salary:
          initialValues[formKey] = {
            [FormFieldName.From]:
              formatPriceString(driverInfo?.SalaryFrom) || null,
            [FormFieldName.To]: formatPriceString(driverInfo?.SalaryTo) || null,
          };
          return initialValues;

        default:
          return initialValues;
      }
    },
    {} as DriverFormValues
  );
};
