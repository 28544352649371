import { MyRatingBlockProps } from "@/modules/social-services/children/my-info/types/rating";
import { RatingBlockType } from "@/common/types/models/rating";
import { ILSRating } from "@/common/ui-components/data-display/rating";

type IProps = MyRatingBlockProps & {};

/**
 * В дефолтном блоке отображаем рейтинг каждого блока в углу карточки;
 * В блоке с общим рейтингом отображаем рейтинг по центру;
 */
export const renderBlockRating = ({
  ratingBlockType,
  ratingBlockValue,
  ratingTotalValue,
}: IProps) => {
  switch (ratingBlockType) {
    case RatingBlockType.Default:
      // TODO: отображение значение рейтинга для каждого блока в разработке
      return null;
    // return (
    //   <ILSTag
    //     className="my-rating__block-rating"
    //     color={COLOR_PALETTE.primary}
    //     bordered={false}
    //   >
    //     {`${ratingBlockValue} / 100`}
    //   </ILSTag>
    // );

    case RatingBlockType.Total:
      return <ILSRating rating={ratingTotalValue} />;

    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};
