import { FC } from "react";
import { Form } from "antd";
import { ILSAvatar } from "@/common/ui-components/data-display/avatar";
import { ILSFormFieldset } from "@/common/ui-components/data-entry/form-fieldset";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSInputNumber } from "@/common/ui-components/data-entry/input/number";
import { ILSTextArea } from "@/common/ui-components/data-entry/input/text-area";
import { ILSStarRating } from "@/common/components/data-display/star-rating";

interface IProps {}

export const AddReviewForm: FC<IProps> = () => {
  return (
    <Form
      className="add-review-form add-review-form_driver"
      layout="vertical"
      disabled
    >
      <div>
        <ILSAvatar />
      </div>

      <ILSFormFieldset legend="Водитель">
        <ILSFormItem name="name" label="Имя">
          <ILSInput />
        </ILSFormItem>
        <ILSFormItem name="secondName" label="Отчество">
          <ILSInput />
        </ILSFormItem>
        <ILSFormItem name="surname" label="Фамилия">
          <ILSInput />
        </ILSFormItem>
        <ILSFormItem
          name="DriverLicense"
          label="Номер водительского удостоверения"
        >
          <ILSInputNumber />
        </ILSFormItem>
        <ILSFormItem name="comment" label="Комментарий">
          <ILSTextArea />
        </ILSFormItem>
      </ILSFormFieldset>

      <ILSFormFieldset
        legend="Оценка"
        fieldsetClassName="add-review-form__rating"
      >
        <ILSFormItem name="rating">
          <ILSStarRating />
        </ILSFormItem>
      </ILSFormFieldset>
    </Form>
  );
};
