import { head } from "lodash";
import { useAbac } from "react-abac";
import { AbacPermission } from "@/common/types/core/abac";
import { STATUS_PALLETE } from "@/styles/color-palette";
import { VACANCY_STATUS_TITLES } from "@/common/constants/statuses/vacancy-statuses";
import { DEFAULT_ZERO_RATING } from "@/common/constants/config/rating";
import { ZERO_PUBLISHED_VACANCIES } from "@/common/features/vacancy/constants";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { getVacancyPrice } from "@/common/features/vacancies-common/helpers/get-vacancy-price";
import { VacancyStatus } from "@/common/types/models/vacancies/status";
import { useVacancyCardPartnership } from "@/common/features/vacancies-common/hooks/use-vacancy-card-partnership";

export const useVacancyCardDetails = (vacancy: Vacancy) => {
  const { userHasPermissions } = useAbac();
  const hasActionsWithVacancies = userHasPermissions(
    AbacPermission.hasActionsWithVacancies,
    vacancy
  );
  const adminPanel = userHasPermissions(AbacPermission.adminPanel);

  const { titleBadge, mainSubtitle } = useVacancyCardPartnership({
    vacancyOwner: vacancy?.Owner,
    adminPanel,
  });

  const subtitle = getVacancyPrice(vacancy.Payment);

  const info = {
    title: vacancy.Title || "Нет названия",
    subtitle,
    description: vacancy.Description || "Нет описания",
  };

  const cover = head(vacancy?.Vehicle?.Profile?.Image?.Data)?.FileUrl || null;
  const ownerLogo = vacancy?.Owner?.Avatar?.FileUrl || null;

  const profile = {
    title: vacancy.Owner?.Name,
    titleBadge,
    mainSubtitle,
    description: `активные вакансии: ${
      vacancy?.PublishedVacancies ?? ZERO_PUBLISHED_VACANCIES
    }`,
    img: ownerLogo,
    chips: [
      {
        text: "Есть мнения о работодателе",
        hide: !vacancy.Owner?.HasFeedback,
      },
      {
        text: "Компания проверена",
        hide: !vacancy.Owner?.IsVerified,
      },
    ],
  };

  const socialRating = vacancy.Owner?.SocialRating || DEFAULT_ZERO_RATING;
  const ratings = [socialRating];

  const statusAlias = vacancy?.VacancyStatus?.Alias as VacancyStatus;
  const statuses =
    statusAlias && hasActionsWithVacancies
      ? [
          {
            text: VACANCY_STATUS_TITLES[statusAlias],
            backgroundColor: STATUS_PALLETE[statusAlias].backgroundColor,
            color: STATUS_PALLETE[statusAlias].color,
          },
        ]
      : undefined;

  return {
    vacancyID: vacancy.ID,
    info,
    cover,
    profile,
    ratings,
    statuses,
    statusAlias,
  };
};
