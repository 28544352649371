export enum OperationsEndpoint {
  Get = "get",
  GetAll = "getAll",
  Create = "create",
  Delete = "delete",
  Update = "update",
  SetStatus = "setStatus",
  ApplyList = "applyList",
  Apply = "apply",
  Cancel = "cancel",
  Refuse = "refuse",
  Confirm = "confirm",
}
