import "./vacancies-sort.scss";
import { FC } from "react";
import { SortAscendingOutlined } from "@ant-design/icons";
import { usePopover } from "@/common/hooks/use-popover";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSPopover } from "@/common/components/data-display/popover";
import { VacanciesSortMenu } from "@/common/features/vacancies-common/components/sort-form/vacancies-sort-menu";

type IProps = {
  activeSortKey: string | undefined;
  onSortClick: ({ key }: { key: string }) => void;
};

export const VacanciesSortPopover: FC<IProps> = ({
  activeSortKey,
  onSortClick,
}) => {
  const { open, handleOpenChange } = usePopover();

  return (
    <ILSPopover
      placement="bottomLeft"
      content={
        <VacanciesSortMenu
          activeSortKey={activeSortKey}
          onSortClick={onSortClick}
        />
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      className="vacancies-sort"
    >
      <ILSButton icon={<SortAscendingOutlined />} children="Сортировка" />
    </ILSPopover>
  );
};
