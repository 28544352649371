import { DriverSearchFilters } from "@modules/social-services/children/driver-search/containers/filters";
import { SocialServicesContentWithAside } from "@modules/social-services/containers/layout-with-content";
import { DriverSearchToolbar } from "@modules/social-services/children/driver-search/containers/toolbar";
import { DriverSearchList } from "@/modules/social-services/children/driver-search/containers/list";
import { useDriverList } from "@/modules/social-services/children/driver-search/hooks/use-driver-list";

export const DriverSearchMain = () => {
  const {
    drivers,
    loading,
    refData,
    form,
    disableFilters,
    onValuesChange,
    resetFilters,
  } = useDriverList();

  return (
    <>
      <DriverSearchToolbar
        form={form}
        refData={refData}
        onValuesChange={onValuesChange}
        resetFilters={resetFilters}
        disableFilters={disableFilters}
      />
      <SocialServicesContentWithAside>
        <DriverSearchFilters
          form={form}
          refData={refData}
          resetFilters={resetFilters}
          onValuesChange={onValuesChange}
          disabled={disableFilters}
        />
        <DriverSearchList drivers={drivers} loading={loading} />
      </SocialServicesContentWithAside>
    </>
  );
};
