import { useState } from "react";

/** сохраняем шаги в state, чтобы начальные значения подставлялись в форму */
export const useFormSteps = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const onStepChange = (step: number) => {
    setCurrentStep(step);
  };

  return { currentStep, onStepChange };
};
