import { ModulePath } from "@/common/types/core/routes";
import { IconName, IconPathConfig } from "@/common/types/general/icon";
import {
  ASSETS_BASE_PATH,
  DEFAULT_ICON_CONFIG,
  ORIGIN_PATH,
} from "@/common/constants/icons";

export const getSRCIconSVG = (
  iconName: IconName,
  iconConfig: IconPathConfig = DEFAULT_ICON_CONFIG
) => {
  const {
    originPath = false,
    iconModule = ModulePath.startPage,
    iconDirectory = ASSETS_BASE_PATH,
  } = iconConfig;
  return originPath
    ? `${ORIGIN_PATH}${iconDirectory}${iconModule}${iconName}.svg`
    : `${iconDirectory}${iconModule}${iconName}.svg`;
};
