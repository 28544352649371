import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

interface IProps extends PropsWithChildren {
  className?: string;
}

export const SocialServicesContentWithAside: FC<IProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(
        "social-services__content social-services__content_with-aside",
        className
      )}
    >
      {children}
    </div>
  );
};
