import {
  Notify,
  RoutineActionOperation,
} from "@/common/types/notifications/enum";

export const getNotifyType = (
  actionType: RoutineActionOperation
): Partial<Notify> | null => {
  switch (actionType) {
    case RoutineActionOperation.FAILURE:
      return Notify.Error;
    case RoutineActionOperation.SUCCESS:
      return Notify.Success;
    case RoutineActionOperation.REQUEST:
      return Notify.Loading;
    default:
      return null;
  }
};
