import { isEmpty, isNil } from "lodash";
import { EMPTY_STRING } from "@/common/constants/general";
import { DEFAULT_ZERO_RATING } from "@/common/constants/config/rating";
import { DEFAULT_ORGANIZATION_TYPE_ID_VALUE } from "@/common/constants/config/options";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { OrganizationInfo } from "@/common/types/models/account";
import { AdminOrganizationFormValues } from "@/modules/admin-panel/children/admin-organizations/children/organization/types/organization-form";
import { getObjectValuesWithType } from "@/common/utils/helpers/types";
import { getImagesToForm } from "@/common/utils/helpers/components/avatar/get-images-to-form";

type IProps = {
  organizationInfo: Partial<OrganizationInfo> | null;
};

export const adminOrganizationToFormValues = ({
  organizationInfo,
}: IProps): AdminOrganizationFormValues => {
  return getObjectValuesWithType<FormFieldName>(FormFieldName).reduce(
    (initialValues, formKey) => {
      if (isEmpty(organizationInfo)) {
        return initialValues;
      }

      switch (formKey) {
        case FormFieldName.OrganizationName:
          initialValues[formKey] = organizationInfo?.Name || EMPTY_STRING;
          return initialValues;
        case FormFieldName.Phone:
          initialValues[formKey] = organizationInfo.Phone || EMPTY_STRING;
          return initialValues;

        case FormFieldName.INN:
        case FormFieldName.KPP:
        case FormFieldName.OGRN:
        case FormFieldName.OKPO:
        case FormFieldName.LegalAddress:
        case FormFieldName.ActualAddress:
          initialValues[formKey] = organizationInfo?.[formKey] || EMPTY_STRING;
          return initialValues;

        case FormFieldName.OrganizationTypeID:
          initialValues[formKey] =
            organizationInfo?.[formKey] || DEFAULT_ORGANIZATION_TYPE_ID_VALUE;
          return initialValues;

        case FormFieldName.Avatar:
          initialValues[formKey] = getImagesToForm(organizationInfo.Avatar);
          return initialValues;

        case FormFieldName.AccountCode:
          initialValues[formKey] = organizationInfo.AccountCode || EMPTY_STRING;
          return initialValues;

        case FormFieldName.PublicOrganizationPartnership:
          initialValues[formKey] = organizationInfo?.[formKey] || false;
          return initialValues;

        case FormFieldName.PartnershipChecked:
          initialValues[formKey] = !isNil(organizationInfo.PartnershipChecked)
            ? Number(organizationInfo.PartnershipChecked)
            : null;
          return initialValues;

        case FormFieldName.SocialRating:
          initialValues[formKey] = !isNil(organizationInfo?.[formKey])
            ? Number(organizationInfo?.[formKey])
            : DEFAULT_ZERO_RATING;
          return initialValues;

        default:
          return initialValues;
      }
    },
    {} as AdminOrganizationFormValues
  );
};
