import { useWatch } from "antd/es/form/Form";
import { NamePath } from "antd/es/form/interface";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { AccountRole } from "@/common/types/models/account";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { AvailableRegisterRole } from "@/modules/auth/children/register/constants/form-fields";
import { useShowEmployeeFields } from "@/common/hooks/form/use-show-employee-fields";
import { getRegisterFields } from "@/modules/auth/children/register/helpers/get-register-fields";

export const useRegisterFields = () => {
  const form = useFormInstance();
  const accountRole = FormFieldName.AccountRole as NamePath;
  const checkedAccountRole: AvailableRegisterRole =
    useWatch(accountRole, form) ?? AccountRole.driver;

  const { showEmployeeFields } = useShowEmployeeFields({ form });

  const formItems = getRegisterFields({
    checkedAccountRole,
    showEmployeeFields,
  });

  return { accountRole, formItems };
};
