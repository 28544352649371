import { Vacancy } from "@/common/types/models/vacancies/model";
import { checkOrganizationPartnership } from "@/common/features/organizations-common/helpers/check-organization-partnership";

import { getOrganizationPartnershipBadge } from "@/common/features/vacancies-common/helpers/get-organization-partnership-badge";
import { getOrganizationPartnershipTitle } from "@/common/features/vacancies-common/helpers/get-organization-partnership-title";

type IProps = {
  vacancyOwner: Vacancy["Owner"];
  adminPanel: boolean;
};

/**
 * Хук проверяет, есть ли подтвержденный статус Партнера организации "Водители России"
 * Возвращает данные для карточки вакансии: titleBadge, mainSubtitle
 */
export const useVacancyCardPartnership = ({ vacancyOwner }: IProps) => {
  /** имеет ли подтвержденный статус участника */
  const hasConfirmedPartnership = checkOrganizationPartnership(vacancyOwner);

  const titleBadge = getOrganizationPartnershipBadge({
    hasConfirmedPartnership,
  });

  const mainSubtitle = getOrganizationPartnershipTitle({
    hasConfirmedPartnership,
  });

  return { titleBadge, mainSubtitle };
};
