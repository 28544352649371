import { FC } from "react";
import ImgCrop, { ImgCropProps } from "antd-img-crop";
import { handleCheckBeforeCrop } from "@/common/utils/helpers/components/image-crop";

interface IProps extends ImgCropProps {
  fileMaxSize?: number;
}

export const ILSImgCrop: FC<IProps> = ({ fileMaxSize, children, ...props }) => {
  return (
    <ImgCrop
      children={children}
      beforeCrop={handleCheckBeforeCrop(fileMaxSize)}
      {...props}
    />
  );
};
