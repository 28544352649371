import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  questionnaireResultsSelector,
  questionnaireResultsFetchingSelector,
} from "@/common/features/questionnaire-common/selectors";
import { useQuestionnaireResultsOperations } from "@/common/features/questionnaire-common/hooks/use-questionnaire-results-operations";

export const useAdminQuestionnaireResults = () => {
  const results = useSelector(questionnaireResultsSelector);
  const isFetching = useSelector(questionnaireResultsFetchingSelector);

  const { handleGetQuestionnaireResults } = useQuestionnaireResultsOperations();

  useEffect(() => {
    handleGetQuestionnaireResults();
  }, []);

  return { results, isFetching };
};
