import { useDispatch } from "react-redux";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { vacanciesRoutine } from "@/common/features/vacancies-common/actions";
import { VacancyBaseRequest } from "@/common/features/vacancies-common/types/api";
import { VACANCIES_STATUSES } from "@/common/features/vacancies-common/sagas/notifier";

type IProps = VacancyBaseRequest & {
  vacancyIdForApply?: number | undefined;
  onSuccess?: VoidFunction;
  onFinally?: VoidFunction;
};

export const useVacancyOperation = () => {
  const dispatch = useDispatch();

  const onVacancyOperation = ({
    body,
    id,
    operation,
    filters,
    vacancyIdForApply,
    onSuccess,
    onFinally,
  }: IProps) => {
    try {
      dispatch(
        vacanciesRoutine({
          operation,
          id,
          body,
          filters,
          customNotificationMessage: VACANCIES_STATUSES,
          vacancyIdForApply,
        })
      );
      onSuccess?.();
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      onFinally?.();
    }
  };

  return { onVacancyOperation };
};
