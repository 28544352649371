import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { DRIVERS_INITIAL_STATE } from "@/common/features/drivers-common/store";
import { IndexedArray } from "@/common/types/general/enum";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { Driver, DriverInfo } from "@/common/types/models/user/driver";
import { DriverSingleResponse } from "@/common/api/types/driver";
import { driversRoutine } from "@/common/features/drivers-common/actions";

export const driversReducer = createReducer(DRIVERS_INITIAL_STATE, {
  [driversRoutine.REQUEST]: onRequest,
  [driversRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      operation: OperationsEndpoint;
      data: IndexedArray<DriverInfo> | DriverSingleResponse;
      id: number;
    }>
  ) => {
    const { operation, data, id } = action.payload;

    switch (operation) {
      case OperationsEndpoint.GetAll:
        state.drivers = data as IndexedArray<DriverInfo>;
        break;

      case OperationsEndpoint.Get:
        if (data) {
          state.drivers[id] = {
            ...data?.Driver,
            Phone: data?.Phone,
            PhoneVerified: data?.PhoneVerified,
            Email: data?.Email,
            Avatar: !isEmpty(data?.Avatar) ? data?.Avatar : EMPTY_ARRAY_DATA,
          } as Driver;
        }
        break;

      default:
        break;
    }
    return state;
  },
  [driversRoutine.FAILURE]: onFailure,
  [driversRoutine.FULFILL]: onFulfill,
});
