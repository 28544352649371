import { FormInstance } from "antd";
import { SubmitterProps } from "@ant-design/pro-components";
import { ILSButton } from "@/common/components/data-display/buttons";

export const renderILSStepsFormSubmitter = (
  currentProps: SubmitterProps<Record<string, any>> & {
    step: number;
    onPre: () => void;
    form?: FormInstance<any> | undefined;
  } & {
    submit: () => void;
    reset: () => void;
  }
) => {
  const isFirstStep = currentProps?.step === 0;
  const isLastStep = currentProps?.step === 2;

  const onSubmitStep = () => {
    currentProps?.onSubmit?.();
  };
  const onReturnToPrevStep = () => {
    currentProps?.onPre?.();
  };

  if (isFirstStep) {
    return [<ILSButton onClick={onSubmitStep}>Следующий шаг</ILSButton>];
  }

  return [
    <ILSButton onClick={onReturnToPrevStep}>Предыдущий шаг</ILSButton>,
    <ILSButton onClick={onSubmitStep}>
      {isLastStep ? "Сохранить" : "Следующий шаг"}
    </ILSButton>,
  ];
};
