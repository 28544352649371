import { FC } from "react";
import { EMPTY_STRING } from "@/common/constants/general";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { OrganizationFormValues } from "@/modules/social-services/children/my-info/types/form";
import { useMyOrganizationPartnershipField } from "@/modules/social-services/children/my-info/hooks/use-my-organization-partnership-field";

type IProps = {
  initialValues: OrganizationFormValues | null;
};

export const MyInfoOrganizationPartnershipField: FC<IProps> = ({
  initialValues,
}) => {
  const { helpText } = useMyOrganizationPartnershipField({
    partnershipChecked: initialValues?.PartnershipChecked,
  });

  return (
    <ILSFormItem
      field={FormFieldName.PublicOrganizationPartnership}
      label={EMPTY_STRING}
      valuePropName="checked"
      help={helpText}
    >
      <ILSCheckbox
        label={FORM_LABEL[FormFieldName.PublicOrganizationPartnership]}
      />
    </ILSFormItem>
  );
};
