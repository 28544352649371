import { FC } from "react";
import classNames from "classnames";
import { Form, Tooltip } from "antd";
import { ILSDivider } from "@/common/components/layout/divider";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import {
  SEND_QUESTION_FIELDS,
  SEND_QUESTION_FORM_HELP_TEXT,
  SEND_QUESTION_FORM_SUBTITLE,
  SEND_QUESTION_FORM_TITLE,
} from "@/modules/social-services/children/contacts/constants/forms";
import { useContactsPresidentForm } from "@/modules/social-services/children/contacts/hooks/use-contacts-president-form";
import { SendQuestionType } from "@/common/api/types/send-question";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  type: SendQuestionType;
  className?: string;
};

export const ContactsSendQuestionForm: FC<IProps> = ({ type, className }) => {
  const { form, submitDisabled, formDisabled, handleSubmit } =
    useContactsPresidentForm();

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      className={classNames("contacts__form", className)}
    >
      <ILSTitle level={4} ellipsis={false} className="contacts__form-title">
        {SEND_QUESTION_FORM_TITLE[type]}
      </ILSTitle>
      <p className="contacts__form-subtitle">
        {SEND_QUESTION_FORM_SUBTITLE[type]}
      </p>
      <ILSDivider className="contacts__form-divider" />
      {renderFormItems({
        fields: SEND_QUESTION_FIELDS,
        disabled: formDisabled,
      })}
      <p className="contacts__form-subtitle">{SEND_QUESTION_FORM_HELP_TEXT}</p>
      <Tooltip title={formDisabled ? "Необходимо зарегистрироваться" : null}>
        <ILSButton
          block
          type="primary"
          htmlType="submit"
          className="contacts__form-button"
          disabled={submitDisabled}
        >
          Отправить вопрос
        </ILSButton>
      </Tooltip>
    </Form>
  );
};
