import { setUserOptionsActions } from "@modules/auth/actions";
import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { AUTH_INITIAL_STATE } from "@modules/auth/store";

export const setUserOptionsReducer = createReducer(AUTH_INITIAL_STATE, {
  [setUserOptionsActions.SUCCESS]: (
    state,
    action: PayloadAction<{ data: object }>
  ) => {
    const { data } = action.payload;
    state.resetOptions = Object.entries(data).map(([value, { Name }]) => {
      return { value, label: Name || `Пользователь №${value}` };
    });
    return state;
  },
});
