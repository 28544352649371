import { isNil } from "lodash";
import { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { QuestionnaireStatusFormValues } from "@/modules/admin-panel/children/admin-applications/types/status-form";
import { QuestionnaireAvailabilityStatus } from "@/common/api/types/questionnaire";
import { useQuestionnaireOperations } from "@/common/features/questionnaire-common/hooks/use-questionnaire-operations";

export type IProps = {
  availabilityStatus: QuestionnaireAvailabilityStatus | null;
};

/**
 * Управление сменой статуса доступности шаблона анкеты
 * */
export const useAdminQuestionnaireStatusForm = ({
  availabilityStatus,
}: IProps) => {
  const { handleChangeQuestionnaireStatus } = useQuestionnaireOperations();

  const [form] = useForm<QuestionnaireStatusFormValues>();

  const onQuestionnaireAvailabilityChange = (
    _: QuestionnaireStatusFormValues,
    values: QuestionnaireStatusFormValues
  ) => {
    if (!isNil(availabilityStatus)) {
      handleChangeQuestionnaireStatus(values.QuestionnaireStatus);
    }
  };

  const handleSetInitialValues = () => {
    form.resetFields();
    form.setFieldsValue({
      [FormFieldName.QuestionnaireStatus]:
        availabilityStatus === QuestionnaireAvailabilityStatus.available,
    });
  };

  useEffect(() => {
    handleSetInitialValues();
  }, []);

  return { form, onQuestionnaireAvailabilityChange };
};
