import "./index.scss";
import { FC, ReactNode } from "react";
import classNames from "classnames";
import { isArray, isEmpty } from "lodash";
import { NOT_SET } from "@/common/constants/general";

type IProps = {
  caption: string;
  value?: number | string | null | ReactNode;
  direction?: "horizontal" | "vertical";
  additional?: string;
  className?: string;
  size?: "default" | "small";
};

export const DetailDescription: FC<IProps> = ({
  caption,
  value,
  additional,
  direction = "horizontal",
  className,
  size = "default",
}) => {
  const component = typeof value === "object" ? value : null;

  const valueIsProvided = !isArray(value) ? Boolean(value) : !isEmpty(value);

  return (
    <div
      className={classNames("detail-description", className, {
        vertical: direction === "vertical",
        [size]: size,
      })}
    >
      <p className="detail-description__caption">{`${caption}:`}</p>
      {!component ? (
        <p className="detail-description__value">
          {valueIsProvided ? value : NOT_SET}
          {valueIsProvided && additional && <span>{` ${additional}`}</span>}
        </p>
      ) : (
        component
      )}
    </div>
  );
};
