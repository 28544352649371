import { FC } from "react";
import { Button, Form } from "antd";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { RefDataType } from "@/common/features/dictionaries/types";
import { DriverExperienceItem } from "@/common/components/data-entry/driver-experience/item";

type IProps = {
  refData: RefDataType;
};

export const DriverExperienceList: FC<IProps> = ({ refData }) => {
  return (
    <Form.List name={FormFieldName.DrivingExperience}>
      {(subFields, subOpt) => (
        <div className="driver-experience-column">
          <ul className="driver-experience-list">
            {subFields.map((subField) => (
              <DriverExperienceItem
                refData={refData}
                subField={subField}
                subOpt={subOpt}
                key={subField.key}
              />
            ))}
          </ul>
          <Button type="dashed" onClick={subOpt.add}>
            + Добавить опыт
          </Button>
        </div>
      )}
    </Form.List>
  );
};
