import "./index.scss";
import { FC, useState } from "react";
import { Modal, UploadFile, UploadProps } from "antd";
import { useModal } from "@/common/hooks/use-modal";
import { ILSUpload } from "@/common/ui-components/data-entry/upload";
import {
  FileType,
  getBase64,
} from "@/common/utils/helpers/components/upload/get-base-64";

export const ILSUploadImage: FC<UploadProps> = ({ children, ...props }) => {
  const { isModalOpen, showModal } = useModal();
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    showModal();
  };

  return (
    <>
      <ILSUpload listType="picture-card" onPreview={handlePreview} {...props}>
        {children}
      </ILSUpload>
      <Modal
        width="fit-content"
        open={isModalOpen}
        okText=""
        onOk={showModal}
        onCancel={showModal}
        footer={null}
      >
        <img src={previewImage} alt="previewImage" className="preview-image" />
      </Modal>
    </>
  );
};
