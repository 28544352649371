import "./burger.scss";
import { FC } from "react";
import classNames from "classnames";

type IProps = {
  open: boolean;
};

export const ILSBurger: FC<IProps> = ({ open }) => {
  return (
    <div className={classNames("burger", { open })}>
      <span />
      <span />
      <span />
    </div>
  );
};
