export enum RoutineActionOperation {
  REQUEST = "REQUEST",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum Notify {
  Info = "info",
  Success = "success",
  Error = "error",
  Loading = "loading",
  Warning = "warning",
  Confirm = "confirm",
  Bad = "bad",
}

export const NotifyDurationInSecond = {
  Infinity: 0,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Seven: 7,
  Eight: 8,
  Nine: 9,
  Ten: 10,
};
