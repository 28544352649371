import { FC } from "react";
import { FormInstance } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSPopover } from "@/common/components/data-display/popover";
import { usePopover } from "@/common/hooks/use-popover";
import { AdminOrganizationsFilters } from "@/modules/admin-panel/children/admin-organizations/components/forms/organizations-filters";
import { AdminOrganizationsFiltersType } from "@/modules/admin-panel/children/admin-organizations/types/organizations-filters";

type IProps = {
  form: FormInstance<AdminOrganizationsFiltersType>;
  onValuesChange: (changedValues: AdminOrganizationsFiltersType) => void;
  resetFilters: VoidFunction;
};

export const AdminOrganizationsToolbar: FC<IProps> = ({
  form,
  onValuesChange,
  resetFilters,
}) => {
  const { open, handleOpenChange } = usePopover();

  return (
    <div className="admin-organizations__toolbar">
      <ILSPopover
        trigger="click"
        content={
          <AdminOrganizationsFilters
            form={form}
            hideLarge={false}
            resetFilters={resetFilters}
            onValuesChange={onValuesChange}
          />
        }
        open={open}
        onOpenChange={handleOpenChange}
      >
        <ILSButton
          children="Фильтры"
          type="primary"
          className="filters-button"
          icon={<FilterOutlined />}
        />
      </ILSPopover>
    </div>
  );
};
