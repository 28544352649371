import { AUTH_INITIAL_STATE } from "@modules/auth/store";
import reduceReducers from "reduce-reducers";
import { loginReducer } from "@modules/auth/reducers/login";
import { logoutReducer } from "@modules/auth/reducers/logout";
import { authUserSetReducer } from "@modules/auth/reducers/auth-user-set";
import { recoveryProcessReducer } from "@modules/auth/reducers/recovery-process";
import { setUserOptionsReducer } from "@modules/auth/reducers/set-user-options";

export const authRootReducer = reduceReducers(
  AUTH_INITIAL_STATE,
  loginReducer,
  logoutReducer,
  authUserSetReducer,
  recoveryProcessReducer,
  setUserOptionsReducer
);
