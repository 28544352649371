import { FC } from "react";
import { Form, FormInstance } from "antd";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { MY_INFO_FORM_NAMES } from "@/modules/social-services/children/my-info/constants/form";
import { ILSButton } from "@/common/components/data-display/buttons";
import {
  IILSFormAvatarProps,
  ILSFormAvatar,
} from "@/common/components/custom/form-avatar";
import { DriverExperience } from "@/common/components/data-entry/driver-experience";
import { MyInfoFormPersonalData } from "@/modules/social-services/children/my-info/components/subforms/personal-data";
import { MyInfoFormDriverData } from "@/modules/social-services/children/my-info/components/subforms/driver-data";
import { DriverFormValues } from "@/common/features/drivers-common/types/driver-form";
import { RefDataType } from "@/common/features/dictionaries/types";
import { MyInfoFormJobData } from "@/modules/social-services/children/my-info/components/subforms/job-data";
import { MyInfoDriverExtraDetails } from "@/modules/social-services/children/my-info/components/forms/driver-extra-details";

type IProps = IILSFormAvatarProps & {
  form: FormInstance<DriverFormValues>;
  initialValues: DriverFormValues | null;
  selectedCategories: Array<number>;
  refData: RefDataType;
  showReadyToStartTomorrow: boolean;
  showDriverLicenseField: boolean;
  membershipChecked: boolean | null | undefined;
  handleSubmit: (values: DriverFormValues) => void;
  handleCancelChange: VoidFunction;
  handleCategoriesChange: (category: number) => void;
};

export const DriverInfoForm: FC<IProps> = ({
  form,
  initialValues,
  selectedCategories,
  fileList,
  refData,
  showReadyToStartTomorrow,
  showDriverLicenseField,
  membershipChecked,
  handleSubmit,
  handleCancelChange,
  handleCategoriesChange,
  handleAvatarChange,
  handleAvatarRemove,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      className="my-info-form"
      name={MY_INFO_FORM_NAMES.DRIVER}
      initialValues={initialValues || EMPTY_OBJECT_DATA}
      onFinish={handleSubmit}
    >
      <div className="my-info-form__column">
        <ILSFormAvatar
          fileList={fileList}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
        <MyInfoFormJobData
          refData={refData}
          membershipChecked={membershipChecked}
          showReadyToStartTomorrow={showReadyToStartTomorrow}
        />
      </div>

      <div className="my-info-form__column my-info-form__column_wide">
        <div className="my-info-form__row">
          <MyInfoFormPersonalData refData={refData} />
          <MyInfoDriverExtraDetails />
        </div>
        <MyInfoFormDriverData
          refData={refData}
          showDriverLicenseField={showDriverLicenseField}
          selectedCategories={selectedCategories}
          handleCategoriesChange={handleCategoriesChange}
        />
        <DriverExperience refData={refData} />

        <div className="my-info-form__buttons">
          <ILSButton onClick={handleCancelChange}>Отмена</ILSButton>
          <ILSButton type="primary" htmlType="submit">
            Сохранить
          </ILSButton>
        </div>
      </div>
    </Form>
  );
};
