import { Vacancy } from "@/common/types/models/vacancies/model";
import { getFormattedPrice } from "@/common/utils/helpers/string/get-formatted-price";
import {
  Currency,
  CurrencySign,
  DEFAULT_CURRENCY_UNIT,
} from "@/common/constants/general/currencies";

export const NO_PRICE = `0 ${DEFAULT_CURRENCY_UNIT}`;
export const getCurrencyUnit = (
  unit: Currency | null = DEFAULT_CURRENCY_UNIT
) => {
  return CurrencySign[unit || DEFAULT_CURRENCY_UNIT];
};

export const getVacancyPrice = (payment: Vacancy["Payment"]) => {
  if (!payment) return NO_PRICE;
  const { Price } = payment;
  if (!Price) return NO_PRICE;
  const { From, Unit, To } = Price;
  const from = getFormattedPrice(From);
  const to = getFormattedPrice(To);
  const unit = getCurrencyUnit(Unit);

  if (from === to) {
    return `${from} ${unit}`;
  }
  return `${from} - ${to} ${unit}`;
};
