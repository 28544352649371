import { FC } from "react";
import { MyInfoFormLicenseCategoriesList } from "@modules/social-services/children/my-info/components/license-categories/list";
import { SelectApiOption } from "@/common/types/general/common";

type IProps = {
  selectedCategories: Array<number>;
  categoriesOptions: SelectApiOption[];
  handleCategoriesChange: (category: number) => void;
};

export const MyInfoFormLicenseCategories: FC<IProps> = ({
  categoriesOptions,
  selectedCategories,
  handleCategoriesChange,
}) => {
  return (
    <div className="my-info-form__categories">
      <p>Категории</p>
      <MyInfoFormLicenseCategoriesList
        categoriesOptions={categoriesOptions}
        selectedCategories={selectedCategories}
        handleCategoriesChange={handleCategoriesChange}
      />
    </div>
  );
};
