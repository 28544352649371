import { FC } from "react";
import { TabPaneProps, Tabs } from "antd";
import { TabsProps } from "antd/lib";

const { TabPane } = Tabs;

export const ILSTabs: FC<TabsProps> = (props) => {
  return <Tabs {...props} />;
};

export const ILSTabPane: FC<TabPaneProps> = (props) => {
  return <TabPane {...props} />;
};
