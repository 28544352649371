import "./sider-banners-list.scss";
import { siderBannersWithImages } from "@/modules/start/constants/banners";
import { StartPageSiderBannerWithImage } from "@/modules/start/components/sider-banners/sider-banner-with-image/sider-banner-with-image";

export const StartPageSiderBannersList = () => {
  return (
    <ul className="sider-banners-list">
      {siderBannersWithImages.map((banner) => (
        <StartPageSiderBannerWithImage {...banner} key={banner.title} />
      ))}
    </ul>
  );
};
