import { COLOR_PALETTE } from "@/styles/color-palette";
import { DriverRequiredFields } from "@/common/features/drivers-common/types/driver-required-fields";

export const NO_DRIVER_LICENSE =
  "Нет водительского удостоверения, готов пройти обучение";
export const PUBLIC_ORGANIZATION_MEMBER = "Участник общественной организации";
export const PUBLIC_ORGANIZATION_PARTNER = "Партнер общественной организации";
export const PUBLIC_ORGANIZATION_NAME = "Водители России";
export const PUBLIC_ORGANIZATION_TITLE_FULL = `${PUBLIC_ORGANIZATION_MEMBER} "${PUBLIC_ORGANIZATION_NAME}"`;
export const PUBLIC_ORGANIZATION_PARTNER_TITLE_FULL = `${PUBLIC_ORGANIZATION_PARTNER} "${PUBLIC_ORGANIZATION_NAME}"`;

export const PUBLIC_ORGANIZATION_CHECK_REQUIRED =
  "Необходимо проверить статус участника!";
export const PUBLIC_ORGANIZATION_PARTNER_CHECK_REQUIRED =
  "Необходимо проверить статус партнера!";

export const PUBLIC_ORGANIZATION_TITLE_ACCENT = (
  <>
    {PUBLIC_ORGANIZATION_MEMBER}
    <span>{` "${PUBLIC_ORGANIZATION_NAME}"`}</span>
  </>
);

export const PUBLIC_ORGANIZATION_PARTNERSHIP_TITLE_ACCENT = (
  <>
    {PUBLIC_ORGANIZATION_PARTNER}
    <span>{` "${PUBLIC_ORGANIZATION_NAME}"`}</span>
  </>
);

export const PUBLIC_ORGANIZATION_CHECK_REQUIRED_ICON = {
  fontSize: "25px",
  color: COLOR_PALETTE.accent,
};

export const PHONE_IS_VERIFIED = "Номер телефона подтвержден!";
export const PHONE_IS_VERIFIED_SHORT = "Телефон подтвержден";
export const PHONE_IS_NOT_VERIFIED = "Номер телефона не подтвержден";

export const DRIVER_REQUIRED_FIELDS_MESSAGES = {
  [DriverRequiredFields.name]: "фамилия, имя",
  [DriverRequiredFields.phone]: "номер телефона",
  [DriverRequiredFields.drivingLicense]: "водительское удостоверение",
};
