import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { DriverFormValues } from "@/common/features/drivers-common/types/driver-form";
import { JobSearchStatusType } from "@/common/types/models/user/job-search-status";

type IProps = {
  form: FormInstance<DriverFormValues>;
};

export const useShowReadyStartTomorrowField = ({ form }: IProps) => {
  const currentJobSearchStatus = useWatch(FormFieldName.JobSearchStatus, form);
  const showReadyToStartTomorrow =
    currentJobSearchStatus === JobSearchStatusType.ActiveSearch;

  return { showReadyToStartTomorrow };
};
