import { getObjectKeysWithType } from "@/common/utils/helpers/types";

export const getSelectOptions = <T extends string>(
  optionsObj: Record<T, string>
) => {
  return getObjectKeysWithType<T>(optionsObj).reduce((acc, key) => {
    acc.push({
      value: key,
      label: optionsObj[key],
    });
    return acc;
  }, [] as { value: T; label: string }[]);
};
