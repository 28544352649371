import { isNil } from "lodash";

export type IProps = {
  membershipChecked: boolean | null | undefined;
};
export const useMyInfoDriverMembership = ({ membershipChecked }: IProps) => {
  let helpText: string =
    "Работодатели увидят Ваш статус участника после проверки администратором";

  if (!isNil(membershipChecked)) {
    helpText = membershipChecked
      ? 'Статус успешно проверен администратором. Если убрать статус "Участник", статус проверки будет сброшен'
      : "Статус отклонен, Вы можете отправить повторно на проверку";
  }

  return { helpText };
};
