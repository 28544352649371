import { FC } from "react";
import { Space } from "antd";
import { ILSDivider } from "@/common/components/layout/divider";
import {
  ILSButtonConfirmTooltip,
  IILSButtonConfirmTooltipProps,
} from "@/common/components/data-display/buttons/confirm-tooltip";

type IProps = {
  actionButtons: IILSButtonConfirmTooltipProps[];
};

export const VacancyActionButtons: FC<IProps> = ({ actionButtons }) => {
  return (
    <>
      <ILSDivider />
      <Space direction="vertical">
        {actionButtons.map((buttonProps, i) => {
          if (buttonProps.hidden) return null;
          // eslint-disable-next-line react/no-array-index-key
          return <ILSButtonConfirmTooltip {...buttonProps} key={i} />;
        })}
      </Space>
    </>
  );
};
