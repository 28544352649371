import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { ModulePath } from "@/common/types/core/routes";
import { defineModule } from "@/core/modules/define-module";
import { SocialServices } from "./containers";
import { SOCIAL_SERVICES_MODULE_CHILDREN } from "./constants/children";

const module: IModuleModel = {
  title: ModuleTitle.socialServices,
  name: ModuleName.socialServices,
  path: ModulePath.socialServices,
  children: SOCIAL_SERVICES_MODULE_CHILDREN,
  element: <SocialServices />,
  persist: false,
  isIndex: true,
};

export const SocialServicesModule = defineModule(module);
