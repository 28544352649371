import { isArray, isEmpty } from "lodash";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { DictionaryType } from "@/common/types/models/dictionary";

export const getFormArrayIDValues = (
  values: DictionaryType[] | {} | null | undefined
) => {
  if (!isArray(values) || isEmpty(values)) {
    return EMPTY_ARRAY_DATA;
  }

  return values?.map((value) => value?.ID) || EMPTY_ARRAY_DATA;
};
