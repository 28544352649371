import { Form, FormInstance } from "antd";
import { VACANCIES_TOOLBAR_INITIAL_VALUES } from "@/common/features/vacancies-common/constants/filters";
import {
  VACANCIES_SOURCE_OPTIONS,
  VACANCIES_STATUS_OPTIONS,
} from "@/common/constants/statuses/vacancy-statuses";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSRadioGroup } from "@/common/ui-components/data-entry/radio/group";
import { ILSSelect } from "@/common/ui-components/data-entry/select";
import { VacanciesToolbarFilterValues } from "@/common/features/vacancies-common/types/filters";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

type IProps = {
  toolbarForm: FormInstance<VacanciesToolbarFilterValues>;
  onToolbarFormChange: (
    changedValues: VacanciesToolbarFilterValues,
    values: VacanciesToolbarFilterValues
  ) => void;
  showStatusFormItem: boolean;
};

export const VacanciesToolbarForm = ({
  toolbarForm,
  onToolbarFormChange,
  showStatusFormItem,
}: IProps) => {
  return (
    <Form
      form={toolbarForm}
      layout="horizontal"
      onValuesChange={onToolbarFormChange}
      initialValues={VACANCIES_TOOLBAR_INITIAL_VALUES}
    >
      <div className="social-services__toolbar-form">
        <ILSFormItem name={FormFieldName.VacanciesSource}>
          <ILSRadioGroup
            buttonStyle="solid"
            optionType="button"
            options={VACANCIES_SOURCE_OPTIONS}
          />
        </ILSFormItem>
        {showStatusFormItem && (
          <ILSFormItem
            name={FormFieldName.VacanciesStatus}
            className="social-services__toolbar-status"
            preserve={false}
          >
            <ILSSelect options={VACANCIES_STATUS_OPTIONS} />
          </ILSFormItem>
        )}
      </div>
    </Form>
  );
};
