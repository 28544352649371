import { Vacancy } from "@/common/types/models/vacancies/model";
import { IILSButtonConfirmTooltipProps } from "@/common/components/data-display/buttons/confirm-tooltip";
import { useModal } from "@/common/hooks/use-modal";
import { useVacancyOperations } from "@/common/features/vacancies-common/hooks/use-vacancy-operations";
import { useDriverIncompleteProfile } from "@/common/features/drivers-common/hooks/use-driver-incomplete-profile";

export const useDriverVacancyActions = (vacancy?: Vacancy) => {
  const { driverProfileIsIncomplete } = useDriverIncompleteProfile();
  const driverHasApply = Boolean(vacancy?.VacancyApplyID);
  const disableApply = driverHasApply || driverProfileIsIncomplete;

  const {
    isModalOpen: isDriverAlertApplyModalOpen,
    showModal: showDriverAlertApplyModal,
  } = useModal();

  const { handleApply } = useVacancyOperations();
  const onApply = () => {
    // Блокируем отклик, если есть отклик или если профиль водителя не заполнен;
    if (disableApply) {
      return showDriverAlertApplyModal();
    }
    return handleApply(vacancy?.ID);
  };

  const driverActionButtons: IILSButtonConfirmTooltipProps[] = [
    {
      children: "Откликнуться",
      block: true,
      type: "primary",
      onClick: onApply,
    },
  ];

  return {
    driverActionButtons,
    isDriverAlertApplyModalOpen,
    showDriverAlertApplyModal,
  };
};
