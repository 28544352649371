import { PayloadAction } from "@reduxjs/toolkit";

export enum SendQuestionType {
  PresidentQuestion = "PresidentQuestion",
  SupportQuestion = "SupportQuestion",
  LegalConsultation = "LegalConsultation",
}

export type SendQuestionBaseRequest = {
  body: {
    Question: string;
  };
  type: SendQuestionType;
};

export type ContactsSendQuestionPayload =
  PayloadAction<SendQuestionBaseRequest>;
