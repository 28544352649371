import { AxiosResponse } from "axios";
import { EMPTY_STRING } from "@/common/constants/general";
import { ApiGet } from "@/common/api/helpers/get";
import { ApiPatch } from "@/common/api/helpers/patch";
import {
  OrganizationBaseRequest,
  OrganizationsBaseResponse,
} from "@/common/api/types/organization";
import { getStringifierUrl } from "@/common/api/helpers/stringify-url";
import { OperationsEndpoint } from "@/common/api/constants/operations";

type IProps = OrganizationBaseRequest & {};

export const getOrganizationsEndpoint = ({ id, filters }: IProps) => {
  const endpoint = `/organization${id ? `/${id}` : EMPTY_STRING}`;

  return getStringifierUrl(endpoint, filters);
};

const organizationsOperations = ({
  operation,
  id,
  filters,
  body,
}: IProps): Promise<AxiosResponse<OrganizationsBaseResponse>> => {
  const organizationEndpoint = getOrganizationsEndpoint({
    id,
    filters,
    operation,
  });

  switch (operation) {
    case OperationsEndpoint.GetAll:
      return ApiGet(organizationEndpoint);
    case OperationsEndpoint.Get:
      return ApiGet(organizationEndpoint);
    case OperationsEndpoint.Update:
      return ApiPatch(organizationEndpoint, body);
    default:
      return ApiGet(organizationEndpoint);
  }
};

export const ORGANIZATIONS_API = {
  organizationsOperations,
};
