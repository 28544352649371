import { FC } from "react";
import { isNil } from "lodash";
import { Form, FormInstance } from "antd";
import { ILSDivider } from "@/common/components/layout/divider";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { QUESTIONNAIRE_STATUS_TITLE } from "@/common/constants/config/questionnaire";
import { QuestionnaireAvailabilityStatus } from "@/common/api/types/questionnaire";
import { QuestionnaireStatusFormValues } from "@/modules/admin-panel/children/admin-applications/types/status-form";
import { EMPTY_STRING } from "@/common/constants/general";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSTag } from "@/common/components/data-display/tag";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
// eslint-disable-next-line max-len
import { getQuestionnaireStatusCheckboxLabel } from "@/modules/admin-panel/children/admin-applications/helpers/get-questionnaire-status-checkbox-label";

type IProps = {
  form: FormInstance<QuestionnaireStatusFormValues>;
  availabilityStatus: QuestionnaireAvailabilityStatus | null;
  onQuestionnaireAvailabilityChange: (
    _: QuestionnaireStatusFormValues,
    values: QuestionnaireStatusFormValues
  ) => void;
};

export const AdminQuestionnaireStatusForm: FC<IProps> = ({
  form,
  availabilityStatus,
  onQuestionnaireAvailabilityChange,
}) => {
  const isAvailable =
    availabilityStatus === QuestionnaireAvailabilityStatus.available;
  return (
    <div>
      <DetailDescription
        caption="Статус доступности шаблона для организаций"
        size="small"
        className="admin-questionnaire__status"
        value={
          <ILSTag color={isAvailable ? "green" : "red"}>
            {availabilityStatus
              ? QUESTIONNAIRE_STATUS_TITLE[availabilityStatus]
              : "шаблон не загружен"}
          </ILSTag>
        }
      />
      <ILSDivider />
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onQuestionnaireAvailabilityChange}
      >
        <ILSFormItem
          field={FormFieldName.QuestionnaireStatus}
          label={EMPTY_STRING}
          valuePropName="checked"
        >
          <ILSCheckbox
            disabled={isNil(availabilityStatus)}
            label={getQuestionnaireStatusCheckboxLabel(availabilityStatus)}
          />
        </ILSFormItem>
      </Form>
    </div>
  );
};
