import { Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ACTION_HEADER_ICONS_STYLE_CONFIG } from "@/common/constants/config/header";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSHeaderProfileMenu } from "@/common/components/layout/header/profile/header-profile-menu";
import { useHeaderProfileMenu } from "@/common/hooks/header/use-header-profile-menu";

export const ILSHeaderProfile = () => {
  const {
    userProfileDetails,
    openMenu,
    myInfoTitle,
    handleOpenMenuChange,
    onLogoutButtonClick,
    onCloseMenu,
  } = useHeaderProfileMenu();

  return (
    <Popover
      placement="bottomLeft"
      content={
        <ILSHeaderProfileMenu
          userProfileDetails={userProfileDetails}
          myInfoTitle={myInfoTitle}
          onLogout={onLogoutButtonClick}
          onCloseMenu={onCloseMenu}
        />
      }
      trigger="click"
      open={openMenu}
      onOpenChange={handleOpenMenuChange}
    >
      <ILSButton
        type="primary"
        icon={<UserOutlined style={ACTION_HEADER_ICONS_STYLE_CONFIG} />}
      />
    </Popover>
  );
};
