import { logisticServicesContent } from "@/modules/social-services/children/resources/constants/logistic-servises";
import { LogisticServicesGroups } from "@/modules/social-services/children/resources/types/logistic-services";
import { LogisticServicesCard } from "@/modules/social-services/children/resources/components/logistic-services/card";

export const LogisticServicesColumn = ({
  serviceGroup,
}: {
  serviceGroup: LogisticServicesGroups;
}) => (
  <ul className="logistic-services__column">
    {logisticServicesContent[serviceGroup].map((service) => (
      <LogisticServicesCard key={service.title} service={service} />
    ))}
  </ul>
);
