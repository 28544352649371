import { useModal } from "@/common/hooks/use-modal";
import { User } from "@/common/types/models/user/user";
import { UserCardActions } from "@/common/types/models/user/card-actions";
import { useUserCardActionType } from "@/common/hooks/user-card/use-user-card-action-type";
import { useUserCardActions } from "@/common/hooks/user-card/use-user-card-actions";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { useAdminUsersOperations } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/use-admin-users-operations";

type IProps = {
  selectedUser: User | null;
  handleSelectUser: (userId: number) => void;
};

export const useAdminUsersActions = ({
  selectedUser,
  handleSelectUser,
}: IProps) => {
  const { isModalOpen, showModal } = useModal();
  const { handleUpdateUser, handleDeleteUser } = useAdminUsersOperations();

  const { actionType, handleChangeActionType, resetActionType } =
    useUserCardActionType();

  const onEdit = (userId: number) => {
    handleSelectUser(userId);
    handleChangeActionType(UserCardActions.Edit);
  };

  const onDelete = (userId: number) => {
    handleSelectUser(userId);
    showModal();
    handleChangeActionType(UserCardActions.Delete);
  };

  const onBlock = (userId: number) => {
    handleSelectUser(userId);
    showModal();
    handleChangeActionType(UserCardActions.Block);
  };

  const isBlocked = selectedUser?.Blocked;

  const userCardActions = useUserCardActions({
    selectedUser,
    onEdit,
    onBlock,
    onDelete,
  });

  const onOk = () => {
    try {
      if (actionType === UserCardActions.Block) {
        handleUpdateUser({
          body: {
            // @ts-ignore
            Blocked: !selectedUser?.Blocked,
          },
          userID: selectedUser?.ID,
        });
      }
      if (actionType === UserCardActions.Delete) {
        handleDeleteUser({
          userID: selectedUser?.ID,
        });
      }
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      showModal();
    }
  };

  const modalProps = {
    content:
      actionType === UserCardActions.Delete
        ? "Вы уверены, что хотите удалить водителя?"
        : `Вы уверены, что хотите ${
            isBlocked ? "разблокировать" : "заблокировать"
          } водителя?`,
    open: isModalOpen,
    onOk,
    onCancel: showModal,
  };

  return {
    actionType,
    userCardActions,
    modalProps,
    resetActionType,
  };
};
