import { FC } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { RATING_TITLES, RATING_PATHS } from "@/common/constants/config/rating";
import {
  RatingBlockType,
  RatingContentType,
} from "@/common/types/models/rating";
import { MyRatingBlockProps } from "@/modules/social-services/children/my-info/types/rating";
import { renderBlockRating } from "@/modules/social-services/children/my-info/helpers/utils/render-block-rating";

type IProps = MyRatingBlockProps & {};

export const MyRatingBlock: FC<IProps> = ({
  ratingContentType = RatingContentType.RatingTotal,
  ratingBlockValue,
  ratingTotalValue,
}) => {
  const ratingBlockType =
    ratingContentType === RatingContentType.RatingTotal
      ? RatingBlockType.Total
      : RatingBlockType.Default;

  return (
    <li
      className={classNames("my-rating__block", {
        total: ratingBlockType === RatingBlockType.Total,
      })}
    >
      {renderBlockRating({
        ratingBlockType,
        ratingBlockValue,
        ratingTotalValue,
      })}
      <h2 className="my-rating__block-title">
        {RATING_TITLES[ratingContentType]}
      </h2>
      <NavLink
        to={RATING_PATHS[ratingContentType]}
        className="my-rating__block-link"
      />
    </li>
  );
};
