import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { Dictionaries } from "@/common/types/models/dictionary";
import { getDictionaryRoutine } from "@/common/features/dictionaries/actions";

export const useMyInfoCategories = ({
  initialCategories,
}: {
  initialCategories?: Array<number>;
}) => {
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState<Array<number>>(
    initialCategories || EMPTY_ARRAY_DATA
  );

  const handleCategoriesChange = (newCategory: number) => {
    if (selectedCategories?.includes(newCategory)) {
      return setSelectedCategories(
        selectedCategories.filter((category) => category !== newCategory)
      );
    }
    return setSelectedCategories([
      ...(selectedCategories || EMPTY_ARRAY_DATA),
      newCategory,
    ]);
  };

  const handleCategoriesReset = () => {
    return setSelectedCategories(initialCategories || EMPTY_ARRAY_DATA);
  };

  useEffect(() => {
    dispatch(getDictionaryRoutine(Dictionaries.LicenseCategory));
  }, []);

  return { selectedCategories, handleCategoriesChange, handleCategoriesReset };
};
