import { FC, ReactNode } from "react";
import { Reducer } from "redux";
import { Route } from "./routes";

export type TPersistTransform<StateType> = {
  initialState: StateType;
  onPersist?: ((inboundState: StateType) => any) | null;
};

export enum Languages {
  ru,
  en,
}

export type LanguagesNames = keyof typeof Languages;

export type TTranslateModel = {
  [key in LanguagesNames]: {
    moduleTitle: string;
    [key: string]: string;
  };
};

export enum ModuleScope {}

export enum ModuleTitle {
  startPage = "Главная страница",
  underConstruction = "Модуль в разработке",
  // auth
  auth = "Авторизация",
  login = "Вход",
  register = "Регистрация",
  recovery = "Восстановление аккаунта",
  // social-services
  socialServices = "Социальные сервисы",
  vacancies = "Вакансии",
  vacanciesCommon = "Вакансии",
  vacancy = "Вакансия",
  driverSearch = "Поиск водителя",
  driversCommon = "Поиск водителя",
  driverDetails = "Подробная информация о водителе",
  myRatingApplication = "Анкета социального рейтинга",
  myInfo = "Мои данные",
  contacts = "Контакты",
  projects = "Проекты",
  store = "Корпоративный магазин",
  resources = "Полезные ресурсы",
  organizations = "Организации",
  organization = "Организация",
  legalConsultation = "Юридическая консультация",
  // Admin panel
  adminPanel = "adminPanel",
  adminArticles = "Управление статьями",
  adminOrganizations = "Управление организациями",
  adminOrganization = "Управление организацией",
  adminEmployee = "Управление сотрудниками организаций",
  adminDrivers = "Управление водителями",
  adminVacancies = "Управление вакансиями",
  adminApplications = "Управление анкетами",
  adminApplication = "Управление анкетой",
  // common
  dictionaries = "Словари",
  dataProcessingRules = "Правила обработки персональных данных",
  questionnaireCommon = "Анкеты",
}

export enum ModuleName {
  startPage = "start-page",
  underConstruction = "under-construction",
  // auth
  auth = "auth",
  login = "login",
  register = "register",
  recovery = "recovery",
  // social-services
  socialServices = "social-services",
  vacancies = "vacancies",
  vacanciesCommon = "vacancies-common",
  vacancy = ":vacancy-id",
  driverSearch = "driver-search",
  driversCommon = "drivers-common",
  driverDetails = ":driver-details",
  myRatingApplication = ":my-rating-application",
  myInfo = "my-info",
  contacts = "contacts",
  projects = "projects",
  store = "store",
  resources = "resources",
  organizations = "organizations",
  organization = "organization",
  legalConsultation = "legal-consultation",
  // Admin panel
  adminPanel = "admin-panel",
  adminArticles = "admin-articles",
  adminOrganizations = "admin-organizations",
  adminOrganization = ":admin-organization",
  adminEmployee = "admin-employee",
  adminDrivers = "admin-drivers",
  adminVacancies = "admin-vacancies",
  adminApplications = "admin-applications",
  adminApplication = ":admin-application",
  // common
  dictionaries = "dictionaries",
  dataProcessingRules = "data-processing-rules",
  questionnaireCommon = "questionnaire-common",
}

export enum ModuleIcon {}

export interface IModuleModel extends Omit<Route, "children"> {
  MODULE?: string;
  title?: ModuleTitle;
  name: ModuleName;
  fullPath?: string;
  fullTitle?: string;
  sagas?: Generator[];
  reducer?: Reducer | null;
  children?: Record<string, IModuleModel>;
  inScope?: ModuleScope[] | ModuleScope;
  useScope?: ModuleScope[] | ModuleScope;
  icon?: ModuleIcon | ReactNode;
  api?: Record<string, FC<any>>;
  permission?: string[] | string | null;
  lang?: TTranslateModel | null;
  deps?: string[] | null;
  // Если TPersistTransform, то определяем правила записи store, если false - то модуль не записывается
  persist?: TPersistTransform<any> | boolean | null;
  isIndex?: boolean;
}

export type TModulesObj = Record<string, IModuleModel>;
export type TReducersObj = Record<string, Reducer>;
export type TSagasObj = Record<string, Generator[]>;
