import { RegisterRequest } from "@/common/api/types/auth";
import { AccountRole } from "@/common/types/models/account";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";

export const getRegisterValues = (values: RegisterRequest) => {
  if ("IsEmployee" in values && values?.IsEmployee) {
    values.AccountRole = AccountRole.employee;
  }

  return {
    ...values,
    Phone: getUnmaskedPhone(values?.Phone),
  };
};
