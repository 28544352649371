import { Notify } from "@/common/types/notifications/enum";

type GetNotificationKey = {
  prefix: string;
  keySuffix: string;
  action: string;
  notifyKey: string | undefined;
};

export const getNotificationKey = ({
  prefix,
  keySuffix,
  action,
  notifyKey,
}: GetNotificationKey) => {
  const generateSpecificKey = (type: Notify) => `${notifyKey}-${type}`;
  const generateKey = (type: Notify) =>
    `${prefix}-${type}-${action}-${keySuffix}`;
  // NOTE у loadingKey и fulfillKey должен быть одинаковый ключ, чтобы закрывать и обновлять уведомления о загрузках
  if (notifyKey) {
    return {
      loadingKey: generateSpecificKey(Notify.Success),
      fulfillKey: generateSpecificKey(Notify.Success),
      errorKey: generateSpecificKey(Notify.Error),
    };
  }
  return {
    loadingKey: generateKey(Notify.Loading),
    fulfillKey: generateKey(Notify.Loading),
    errorKey: generateKey(Notify.Loading),
  };
};
