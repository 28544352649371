import { FC } from "react";
import { PUBLIC_ORGANIZATION_PARTNER_TITLE_FULL } from "@/common/features/drivers-common/constants";
import { OrganizationInfo } from "@/common/types/models/account";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSMembershipBadge } from "@/common/components/custom/membership-badge";
import { checkOrganizationPartnership } from "@/common/features/organizations-common/helpers/check-organization-partnership";

type IProps = {
  organization: OrganizationInfo | null;
};

export const OrganizationHeader: FC<IProps> = ({ organization }) => {
  const hasConfirmedPartnership = checkOrganizationPartnership(organization);

  return (
    <div className="organization__header">
      <ILSTitle className="organization__title" ellipsis={{ rows: 2 }}>
        {organization?.Name}
        {hasConfirmedPartnership && (
          <ILSMembershipBadge className="organization__partnership-badge" />
        )}
      </ILSTitle>
      {hasConfirmedPartnership && (
        <ILSTitle ellipsis level={4} className="vacancy__partnership-title">
          {PUBLIC_ORGANIZATION_PARTNER_TITLE_FULL}
        </ILSTitle>
      )}
    </div>
  );
};
