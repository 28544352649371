import { isUndefined } from "lodash";
import { TModulesObj } from "@/common/types/core/module";

export const getModules = (modules: TModulesObj): TModulesObj => {
  return Object.entries(modules).reduce((acc: TModulesObj, [key, value]) => {
    if (!isUndefined(value)) {
      acc[key] = modules[key];
    }
    return acc;
  }, {});
};
