import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { AdminEmployee } from "./containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";

const module: IModuleModel = {
  title: ModuleTitle.adminEmployee,
  name: ModuleName.adminEmployee,
  path: ModulePath.adminEmployee,
  element: <AdminEmployee />,
  persist: false,
};

export const AdminEmployeeModule = defineModule(module);
