import { FC } from "react";
import { ILSWideCard } from "@/common/ui-components/data-display/wide-card";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { useVacancyCard } from "@/modules/social-services/children/vacancies/hooks/use-vacancy-card";
import { VacancyFormType } from "@/common/features/vacancies-common/types/form";
import { VacancyFormModal } from "@/common/features/vacancies-common/components/form/modal";
import { DriverAlertApplyModal } from "@/common/features/vacancies-common/components/driver-apply-alert-modal/driver-apply-alert-modal";

interface IProps {
  vacancy: Vacancy;
}

export const VacancyCard: FC<IProps> = ({ vacancy }) => {
  const {
    actions,
    info,
    cover,
    profile,
    ratings,
    statuses,
    vacancyID,
    isModalOpen,
    showModal,
    isDriverAlertApplyModalOpen,
    showDriverAlertApplyModal,
  } = useVacancyCard(vacancy);

  return (
    <>
      <ILSWideCard
        actions={actions}
        info={info}
        cover={cover}
        profile={profile}
        ratings={ratings}
        statuses={statuses}
        redirectID={vacancyID}
      />
      <VacancyFormModal
        type={VacancyFormType.Edit}
        vacancyId={vacancyID}
        isModalOpen={isModalOpen}
        showModal={showModal}
      />
      <DriverAlertApplyModal
        isModalOpen={isDriverAlertApplyModalOpen}
        showModal={showDriverAlertApplyModal}
      />
    </>
  );
};
