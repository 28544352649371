import { FC } from "react";
import { Form, UploadFile } from "antd";
import { emptyFunction } from "@/common/helpers/empty-function";
import { EXCEL_PERMITTED_EXTENSIONS } from "@/common/constants/files";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSFileDraggerUpload } from "@/common/components/custom/file-dragger-upload/ils-file-dragger-upload";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

type IProps = {
  documents: UploadFile[] | undefined;
  disableUploadDelete: boolean;
  handleCheckBeforeUpload: (file?: UploadFile) => void;
  handleDeleteDocument: (fileID: string | number | undefined) => void;
};

export const AdminQuestionnaireUploadForm: FC<IProps> = ({
  documents,
  disableUploadDelete,
  handleCheckBeforeUpload,
  handleDeleteDocument,
}) => {
  return (
    <Form onFinish={emptyFunction} className="admin-questionnaire__upload-form">
      <ILSTitle level={3} className="admin-questionnaire__upload-form-title">
        Шаблон анкеты
      </ILSTitle>
      <ILSFileDraggerUpload
        documents={documents}
        fieldName={FormFieldName.Questionnaire}
        permittedExtensions={EXCEL_PERMITTED_EXTENSIONS}
        dropzoneTitle="Загрузить шаблон анкеты"
        disableDelete={disableUploadDelete}
        disableUpload={disableUploadDelete}
        handleCheckBeforeUpload={handleCheckBeforeUpload}
        handleDeleteDocument={handleDeleteDocument}
        showDeleteButton
      />
    </Form>
  );
};
