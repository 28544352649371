import { useDispatch } from "react-redux";
import { adminDriversSelectDriverRoutine } from "@/modules/admin-panel/children/admin-drivers/actions";

export const useAdminDriversOperations = () => {
  const dispatch = useDispatch();

  const onSelectDriver = (driverID: number | null) => {
    dispatch(adminDriversSelectDriverRoutine.success({ driverID }));
  };

  return { onSelectDriver };
};
