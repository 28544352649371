import { FC } from "react";
import { Modal, ModalProps } from "antd";

type IProps = ModalProps & {
  content?: string;
};

export const ILSConfirmModal: FC<IProps> = ({
  open,
  content,
  onOk,
  onCancel,
  ...props
}) => {
  return (
    <Modal
      open={open}
      title="Внимание!"
      onOk={onOk}
      onCancel={onCancel}
      {...props}
    >
      <p>{content}</p>
    </Modal>
  );
};
