import { FC } from "react";
import { ILSImage } from "@/common/components/data-display/image";
import { Driver } from "@/common/types/models/user/driver";
import { DriverDetailsMainInfo } from "@/common/features/driver/components/sections/main-info";
import { DriverDetailsExperience } from "@/common/features/driver/components/sections/experience";
import { DriverDetailsLicense } from "@/common/features/driver/components/sections/license";

type IProps = {
  driver: Driver | undefined | null;
  driverAvatar: string | null;
};

export const DriverDetailsContent: FC<IProps> = ({ driver, driverAvatar }) => {
  return (
    <div className="driver-details__content">
      <div className="driver-details__content-group">
        <div className="driver-details__row">
          <DriverDetailsMainInfo driver={driver} />
          {driverAvatar && (
            <ILSImage src={driverAvatar} className="driver-details__avatar" />
          )}
        </div>
        <DriverDetailsLicense driver={driver} />
        <DriverDetailsExperience experience={driver?.DrivingExperience} />
      </div>
    </div>
  );
};
