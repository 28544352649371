import { FC } from "react";
import { ILSDivider } from "@/common/components/layout/divider";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FORM_RULES } from "@/common/ui-components/data-entry/form/constants/rules";
import {
  DRIVER_AVATAR_JOB_SEARCH_FIELDS,
  DRIVER_READY_START_TOMORROW,
} from "@/common/features/drivers-common/constants/driver-form";
import { RefDataType } from "@/common/features/dictionaries/types";
import { Salary } from "@/common/components/data-entry/form-item/salary";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  showReadyToStartTomorrow: boolean;
  refData: RefDataType;
};

export const AdminEditDriverJobSubform: FC<IProps> = ({
  showReadyToStartTomorrow,
  refData,
}) => {
  return (
    <>
      <ILSDivider plain className="admin-edit-driver__divider">
        Информация о поиске работы
      </ILSDivider>
      {renderFormItems({ fields: DRIVER_AVATAR_JOB_SEARCH_FIELDS, refData })}
      {showReadyToStartTomorrow
        ? renderFormItems({ fields: DRIVER_READY_START_TOMORROW })
        : null}
      <Salary
        rules={FORM_RULES.DesiredSalary}
        label={FORM_LABEL.DesiredSalary}
      />
    </>
  );
};
