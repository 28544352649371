export const ORGANIZATION_FIELDS = [
  "Name",
  "INN",
  "OGRN",
  "OKPO",
  "KPP",
  "LegalAddress",
  "ActualAddress",
  "IsVerify",
  "OrganizationTypeID",
  "PublicOrganizationPartnership",
  "PartnershipChecked",
  "SocialRating",
];

export const USER_FIELDS = [
  "Name",
  "Surname",
  "Patronymic",
  "Email",
  "Phone",
  "Avatar",
  "WorkingPosition",
];

export const DRIVER_FIELDS = [
  "OwnVehicleType",
  "LicenseCategory",
  "WorkSchedule",
  "JobSearchStatus",
  "EducationLevel",
  "DrivingExperience",
  "EmploymentType",
  "JobSearchRegion",
  "Name",
  "Surname",
  "Patronymic",
  "Birthday",
  "DriverLicense",
  "DriverLicenseDate",
  "ReadyToStartTomorrow",
  "Sex",
  "Citizens",
  "FullExperienceYears",
  "MedicalCertificate",
  "ShowAvatar",
  "SalaryFrom",
  "SalaryTo",
  "HaveDriverLicense",
  "PublicOrganizationMembership",
  "MembershipChecked",
  "ReadyForTrips",
  "ReadyForRelocation",
  "SocialRating",
  "JobSearchStatus",
];

export const DRIVER_USER_FIELDS = ["Phone", "Email", "Avatar"];
