import { ILSTypographyLink } from "@/common/components/data-display/link";
import { useFooterLinks } from "@/modules/start/hooks/use-footer-links";

export const StartPageFooterList = () => {
  const { footerLinks } = useFooterLinks();
  return (
    <ul className="start-page__footer-list">
      {footerLinks.map((link) => {
        return (
          <li key={link.url} className="start-page__footer-list-item">
            <ILSTypographyLink href={link.url} target="_blank">
              {link.icon}
            </ILSTypographyLink>
          </li>
        );
      })}
    </ul>
  );
};
