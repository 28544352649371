import { EMPTY_STRING } from "@/common/constants/general";
import { User } from "@/common/types/models/user/user";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { AccountFormValues } from "@/modules/social-services/children/my-info/types/form";
import { getImagesToForm } from "@/common/utils/helpers/components/avatar/get-images-to-form";

type IProps = {
  user?: User | null;
};

export const accountToFormValues = ({ user }: IProps): AccountFormValues => {
  return {
    [FormFieldName.Name]: user?.Name || EMPTY_STRING,
    [FormFieldName.Patronymic]: user?.Patronymic || EMPTY_STRING,
    [FormFieldName.Surname]: user?.Surname || EMPTY_STRING,
    [FormFieldName.Phone]: user?.Phone || EMPTY_STRING,
    [FormFieldName.Email]: user?.Email || EMPTY_STRING,
    [FormFieldName.WorkingPosition]: user?.WorkingPosition || EMPTY_STRING,
    [FormFieldName.Avatar]: getImagesToForm(user?.Avatar),
  };
};
