import { useAbac } from "react-abac";
import { AbacPermission } from "@/common/types/core/abac";
import { IILSButtonProps } from "@/common/components/data-display/buttons";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { VacancyStatus } from "@/common/types/models/vacancies/status";
import { getVacancyButtonProps } from "@/common/features/vacancies-common/helpers/get-vacancy-button-props";

type IProps = {
  vacancy: Vacancy;
  statusAlias: VacancyStatus | null;
  onDelete: VoidFunction;
  onEdit: VoidFunction;
  onApply?: VoidFunction;
};

export const useVacancyCardActions = ({
  vacancy,
  statusAlias,
  onDelete,
  onEdit,
  onApply,
}: IProps): (IILSButtonProps & {
  hide?: boolean;
})[] => {
  const { userHasPermissions } = useAbac();
  const hasActionsWithVacancies = userHasPermissions(
    AbacPermission.hasActionsWithVacancies,
    vacancy
  );

  const adminPanel = userHasPermissions(AbacPermission.adminPanel);
  const isDriver = userHasPermissions(AbacPermission.driver);

  const { editButtonProps, deleteButtonProps } = getVacancyButtonProps({
    statusAlias,
    adminPanel,
  });

  const organizationAdminActions = hasActionsWithVacancies
    ? [
        {
          ...editButtonProps,
          children: "Редактировать",
          onConfirm: onEdit,
        },
        {
          ...deleteButtonProps,
          children: "Удалить",
          danger: true,
          onConfirm: onDelete,
        },
      ]
    : EMPTY_ARRAY_DATA;

  const driverActions = isDriver
    ? [
        {
          children: "Откликнуться",
          onClick: onApply,
        },
      ]
    : EMPTY_ARRAY_DATA;

  return [...organizationAdminActions, ...driverActions];
};
