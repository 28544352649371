import { isEmpty } from "lodash";
import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { Vacancies, Vacancy } from "@/common/types/models/vacancies/model";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { VACANCIES_INITIAL_STATE } from "@/common/features/vacancies-common/store";
import { vacanciesRoutine } from "@/common/features/vacancies-common/actions";
import { replaceUpdatedItem } from "@/common/utils/helpers/array/replace-updated-item";
import { OrganizationApply } from "@/common/types/models/vacancies/apply";

export const vacanciesReducer = createReducer(VACANCIES_INITIAL_STATE, {
  [vacanciesRoutine.REQUEST]: onRequest,
  [vacanciesRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      operation: OperationsEndpoint;
      data: Vacancy | Vacancies | OrganizationApply[];
      id: number;
    }>
  ) => {
    const { operation, data } = action.payload;

    switch (operation) {
      case OperationsEndpoint.GetAll:
        state.vacancies = data as Vacancies;
        break;

      case OperationsEndpoint.Update:
        if (data && !isEmpty(data)) {
          state.vacancies = replaceUpdatedItem(
            data as Vacancy,
            state.vacancies
          );
        }
        break;

      case OperationsEndpoint.ApplyList:
        state.appliesList = data as OrganizationApply[];
        break;

      default:
        break;
    }
    return state;
  },
  [vacanciesRoutine.FAILURE]: onFailure,
  [vacanciesRoutine.FULFILL]: onFulfill,
});
