import { FC } from "react";
import { Empty } from "antd";
import { isEmpty } from "lodash";
import { ILSSpin } from "@/common/components/data-display/spin";
import { DriverInfo } from "@/common/types/models/user/driver";
import { AdminDriverCard } from "@/modules/admin-panel/children/admin-drivers/containers/content/card";

interface IProps {
  drivers: DriverInfo[];
  loading: boolean;
  onEditDriver: (driverID: number) => void;
}

export const AdminDriversList: FC<IProps> = ({
  drivers,
  loading,
  onEditDriver,
}) => {
  return (
    <ul className="admin-drivers__list">
      <ILSSpin spinning={loading} tip="Загрузка" size="large">
        {isEmpty(drivers) ? (
          <Empty description="Не найдено ни одного водителя" />
        ) : (
          drivers.map((driver) => {
            return (
              <AdminDriverCard
                key={driver.ID}
                driver={driver}
                onEditDriver={onEditDriver}
              />
            );
          })
        )}
      </ILSSpin>
    </ul>
  );
};
