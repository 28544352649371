import { AdminPanelModule } from "@modules/admin-panel";
import { AuthModule } from "./auth";
import { StartPageModule } from "@/modules/start";
import { SocialServicesModule } from "./social-services";
import { UnderConstructionModule } from "@/modules/under-construction";
import { VacanciesCommonModule } from "@/common/features/vacancies-common";
import { DriversCommonModule } from "@/common/features/drivers-common";
import { DataProcessingRulesModule } from "@/modules/data-processing-rules";
import { QuestionnaireCommonModule } from "@/common/features/questionnaire-common";

export const Modules = {
  // -----> UTILITY MODULES
  UnderConstructionModule,
  // -----> ROOT MODULES
  AuthModule,
  StartPageModule,
  // -----> MODULES
  SocialServicesModule,
  AdminPanelModule,
  // -----> COMMON
  VacanciesCommonModule,
  DriversCommonModule,
  DataProcessingRulesModule,
  QuestionnaireCommonModule,
};
