import { FC } from "react";
import ReactQuill from "react-quill";
import classNames from "classnames";
import "react-quill/dist/quill.snow.css";

export const EDITOR_MODULES = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

export const EDITOR_THEME = "snow";

interface IProps extends ReactQuill.ReactQuillProps {
  className?: string;
}

export const ILSEditor: FC<IProps> = ({
  className,
  theme = EDITOR_THEME,
  modules = EDITOR_MODULES,
  ...props
}) => {
  return (
    <ReactQuill
      // todo
      {...props}
      theme={theme || EDITOR_THEME}
      modules={modules || EDITOR_MODULES}
      className={classNames("ils-editor", className)}
    />
  );
};
