import { FC } from "react";
import { isEmpty } from "lodash";
import { Empty } from "antd";
import { ILSSpin } from "@/common/components/data-display/spin";
import { DriverInfo } from "@/common/types/models/user/driver";
import { DriverCard } from "@/modules/social-services/children/driver-search/components/driver-card";

interface IProps {
  drivers: DriverInfo[];
  loading: boolean;
}

export const DriverSearchList: FC<IProps> = ({ drivers, loading }) => {
  return (
    <ul className="driver-search__list">
      <ILSSpin spinning={loading} tip="Загрузка" size="large">
        {isEmpty(drivers) ? (
          <Empty description="Не найдено ни одного водителя" />
        ) : (
          drivers.map((driver) => {
            return <DriverCard key={driver.ID} driver={driver} />;
          })
        )}
      </ILSSpin>
    </ul>
  );
};
