import { isEmpty } from "lodash";
import {
  QuestionnaireAvailabilityStatus,
  QuestionnaireType,
} from "@/common/api/types/questionnaire";

export const getQuestionnaireAvailabilityStatus = (
  questionnaire: QuestionnaireType | undefined
): QuestionnaireAvailabilityStatus | null => {
  if (!questionnaire || isEmpty(questionnaire)) return null;

  return questionnaire.Available
    ? QuestionnaireAvailabilityStatus.available
    : QuestionnaireAvailabilityStatus.unAvailable;
};
