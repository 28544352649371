import { checkIsOrganizationRole } from "@modules/social-services/children/my-info/helpers/utils/check-is-organization-role";
import { AbacPermission, UserAbacRules } from "@/common/types/core/abac";
import { AccountRole } from "@/common/types/models/account";
import { checkHasActionsWithVacancies } from "@/common/features/vacancies-common/helpers/check-has-actions-with-vacancies";

export const ABAC_RULES: UserAbacRules = {
  [AccountRole.organization]: {
    [AbacPermission.auth]: true,
    [AbacPermission.nonAuth]: false,
    [AbacPermission.authContent]: true,
    [AbacPermission.adminPanel]: false,
    [AbacPermission.driver]: false,
    [AbacPermission.organizationAndEmployee]: true,
    [AbacPermission.organization]: checkIsOrganizationRole,
    [AbacPermission.exceptAdmin]: true,
    [AbacPermission.exceptDriver]: true,
    [AbacPermission.hasActionsWithVacancies]: checkHasActionsWithVacancies,
  },
  [AccountRole.employee]: {
    [AbacPermission.auth]: true,
    [AbacPermission.nonAuth]: false,
    [AbacPermission.authContent]: true,
    [AbacPermission.adminPanel]: false,
    [AbacPermission.driver]: false,
    [AbacPermission.organizationAndEmployee]: true,
    [AbacPermission.organization]: false,
    [AbacPermission.exceptAdmin]: true,
    [AbacPermission.exceptDriver]: true,
    [AbacPermission.hasActionsWithVacancies]: checkHasActionsWithVacancies,
  },
  [AccountRole.driver]: {
    [AbacPermission.auth]: true,
    [AbacPermission.nonAuth]: false,
    [AbacPermission.authContent]: true,
    [AbacPermission.adminPanel]: false,
    [AbacPermission.driver]: true,
    [AbacPermission.organizationAndEmployee]: false,
    [AbacPermission.organization]: false,
    [AbacPermission.exceptAdmin]: true,
    [AbacPermission.exceptDriver]: false,
    [AbacPermission.hasActionsWithVacancies]: false,
  },
  [AccountRole.platformAdmin]: {
    [AbacPermission.auth]: true,
    [AbacPermission.nonAuth]: false,
    [AbacPermission.authContent]: false,
    [AbacPermission.adminPanel]: true,
    [AbacPermission.driver]: false,
    [AbacPermission.organizationAndEmployee]: false,
    [AbacPermission.organization]: false,
    [AbacPermission.exceptAdmin]: false,
    [AbacPermission.exceptDriver]: true,
    [AbacPermission.hasActionsWithVacancies]: true,
  },
  [AccountRole.guest]: {
    [AbacPermission.auth]: false,
    [AbacPermission.nonAuth]: true,
    [AbacPermission.authContent]: false,
    [AbacPermission.adminPanel]: false,
    [AbacPermission.exceptAdmin]: true,
    [AbacPermission.driver]: false,
    [AbacPermission.organizationAndEmployee]: false,
    [AbacPermission.organization]: false,
    [AbacPermission.exceptDriver]: true,
    [AbacPermission.hasActionsWithVacancies]: false,
  },
};
