import { head, isEmpty } from "lodash";
import { UploadFile } from "antd";
import { DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE } from "@/common/constants/files";
import {
  QuestionnaireAvailabilityStatus,
  QuestionnaireType,
} from "@/common/api/types/questionnaire";
import { useQuestionnaireOperations } from "@/common/features/questionnaire-common/hooks/use-questionnaire-operations";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { handleCheckSize } from "@/common/utils/helpers/components/image-crop";
import { getFileToBase64WithoutOriginFileObj } from "@/common/utils/helpers/components/upload/get-file-to-base-64";
import { getDataStringFromBase64 } from "@/common/utils/helpers/regexp/get-data-string-from-base64";
import { getQuestionnaireResultsUploadFiles } from "@/modules/admin-panel/children/admin-applications/helpers/get-questionnaire-results-upload-files";

type IProps = {
  questionnaire: QuestionnaireType | undefined;
  availabilityStatus: QuestionnaireAvailabilityStatus | null;
};

/**
 * Управление загрузкой, удалением, скачиванием шаблона анкеты
 * */
export const useAdminQuestionnaireUpload = ({
  questionnaire,
  availabilityStatus,
}: IProps) => {
  const { handleSaveQuestionnaire, handleDeleteQuestionnaire } =
    useQuestionnaireOperations();

  const documentUploadFiles = !isEmpty(questionnaire)
    ? getQuestionnaireResultsUploadFiles([questionnaire])
    : undefined;

  /* Блокируем загрузку и удаление анкеты, когда анкета доступна для скачивания пользователями */
  const disableUploadDelete =
    availabilityStatus === QuestionnaireAvailabilityStatus.available;

  const handleUploadDocuments = async (files?: Array<UploadFile>) => {
    const filesPromises = getFileToBase64WithoutOriginFileObj(files);
    try {
      Promise.all(filesPromises).then((base64files: string[]) => {
        const questionnaireFile = {
          Data: getDataStringFromBase64(head(base64files)),
          Name: head(files)?.name,
        };
        handleSaveQuestionnaire(questionnaireFile);
      });
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  const handleCheckBeforeUpload = (file?: UploadFile) => {
    if (file && handleCheckSize(file, DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE)) {
      handleUploadDocuments([file]);
    }
  };

  const handleDeleteDocument = () => {
    handleDeleteQuestionnaire();
  };

  return {
    documents: documentUploadFiles,
    disableUploadDelete,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  };
};
