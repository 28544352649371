import { useCommonArticles } from "@/common/hooks/articles/use-common-articles";
import { useDeleteArticle } from "@/common/hooks/articles/use-delete-article";
import { useGetArticle } from "@/common/hooks/articles/use-get-article";
import { Article } from "@/common/api/types/article";

export const useAdminArticleList = (showArticleEditorModal: VoidFunction) => {
  const { articles, ObservableElement } = useCommonArticles();
  const { onDelete, DeleteArticleModal } = useDeleteArticle();
  const { getArticle } = useGetArticle();

  const onEdit = (id: Article["ID"]) => {
    getArticle(id);
    showArticleEditorModal();
  };

  return {
    articles,
    ObservableElement,
    onDelete,
    DeleteArticleModal,
    onEdit,
  };
};
