import { FC } from "react";
import { Empty } from "antd";
import { isEmpty } from "lodash";
import { ILSSpin } from "@/common/components/data-display/spin";
import { DriverApplyCard } from "@/modules/social-services/children/vacancies/components/driver-applies/card";
import { DriverAppliesListHeader } from "@/modules/social-services/children/vacancies/components/driver-applies/list-header";
import { OrganizationApply } from "@/common/types/models/vacancies/apply";

type IProps = {
  appliesList: OrganizationApply[];
  loading: boolean;
  onApplyCancel: (applyId: number | undefined) => void;
};

export const DriverAppliesList: FC<IProps> = ({
  appliesList,
  loading,
  onApplyCancel,
}) => {
  return (
    <>
      <DriverAppliesListHeader />
      <ILSSpin spinning={loading} tip="Загрузка" size="large">
        <ul className="driver-applies__list">
          {isEmpty(appliesList) ? (
            <Empty description="Нет откликов на вакансии" />
          ) : (
            appliesList.map((apply) => {
              return (
                <DriverApplyCard
                  apply={apply}
                  key={apply.ID}
                  onApplyCancel={onApplyCancel}
                />
              );
            })
          )}
        </ul>
      </ILSSpin>
    </>
  );
};
