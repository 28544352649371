import { useRef } from "react";
import { CarouselRef } from "antd/es/carousel";
import { useModal } from "@/common/hooks/use-modal";
import { countRestPhotosCount } from "@/common/features/vacancy/helpers/count-rest-photos-count";

type IProps = {
  vehiclePhotos?: {
    Count: number;
    Data: {
      FileUrl: string;
    }[];
  };
};

export const useVehiclePhotos = ({ vehiclePhotos }: IProps) => {
  const { isModalOpen, showModal } = useModal();
  const handleOpenModal = () => showModal();

  const previewPhotos = vehiclePhotos?.Data?.slice(0, 4);

  const carouselRef = useRef<CarouselRef>(null);
  const modalCarouselRef = useRef<CarouselRef>(null);

  const handlePreviewImage = (slideIndex: number) => {
    if (carouselRef?.current) {
      carouselRef?.current.goTo(slideIndex);
    }
  };

  const restPhotosCount = countRestPhotosCount(
    vehiclePhotos?.Count,
    previewPhotos?.length
  );

  return {
    isModalOpen,
    restPhotosCount,
    carouselRef,
    modalCarouselRef,
    previewPhotos,
    showModal,
    handleOpenModal,
    handlePreviewImage,
  };
};
