import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { ModulePath } from "@/common/types/core/routes";
import { defineModule } from "@/core/modules/define-module";
import { Login } from "./containers";

const module: IModuleModel = {
  title: ModuleTitle.login,
  name: ModuleName.login,
  path: ModulePath.login,
  element: <Login />,
  persist: false,
};

export const LoginModule = defineModule(module);
