import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { useFormAvatar } from "@/common/hooks/use-form-avatar";
import { useAdminGetFormDriver } from "@/modules/admin-panel/children/admin-drivers/hooks/use-admin-get-form-driver";
import { useShowReadyStartTomorrowField } from "@/common/features/drivers-common/hooks/use-show-ready-start-tomorrow-field";
import { UpdateRequestBody } from "@/common/api/types/api";
import { User } from "@/common/types/models/user/user";
import { DriverFormValues } from "@/common/features/drivers-common/types/driver-form";
import { driverOptionsSelector } from "@/common/features/dictionaries/selectors";
import { updateUserActions } from "@/modules/social-services/children/my-info/actions";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { driverToFormValues } from "@/common/features/drivers-common/helpers/driver-to-form-values";
import { drivingExperienceToValue } from "@/common/features/drivers-common/helpers/driving-experience-to-value";
import { getPayloadFromFormValues } from "@/common/ui-components/data-entry/form/helpers/get-payload-from-form-values";
import { getFormImage } from "@/common/helpers/get-account-avatar";
import { getNumberPrice } from "@/common/utils/helpers/price/get-number-price";
import { useShowDriverLicenseField } from "@/common/hooks/form/use-show-driver-license-field";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";
import { getUnixFromDateString } from "@/common/helpers/dates/get-unix-from-date-string";

type IProps = {
  onClose: VoidFunction;
};

export const useAdminEditDriver = ({ onClose }: IProps) => {
  const { driver } = useAdminGetFormDriver();
  const dispatch = useDispatch();
  const { refData } = useSelector(driverOptionsSelector);

  const [form] = useForm<DriverFormValues>();

  const initialValues = driverToFormValues({
    driverInfo: driver,
  });

  const {
    fileList,
    removedSavedFiles,
    handleAvatarChange,
    handleAvatarRemove,
    handleAvatarReset,
  } = useFormAvatar({
    avatar: initialValues?.Avatar,
  });

  const handleSubmit = async (formValues: DriverFormValues) => {
    try {
      const Avatar = await getFormImage(fileList, removedSavedFiles);
      const DrivingExperience = drivingExperienceToValue(
        formValues?.DrivingExperience
      );
      const SalaryFrom = getNumberPrice(formValues?.Salary?.From) || null;
      const SalaryTo = getNumberPrice(formValues?.Salary?.To) || null;
      delete formValues.Salary;

      const Email = formValues?.Email || null;
      delete formValues.Email;

      const Birthday = getUnixFromDateString(formValues.Birthday);
      const DriverLicenseDate = getUnixFromDateString(
        formValues?.DriverLicenseDate
      );

      const driverValues =
        getPayloadFromFormValues<
          UpdateRequestBody<Omit<User, "Avatar" | "Rating">>
        >(formValues);

      const payload = {
        Driver: {
          ...driverValues,
          Birthday,
          DriverLicenseDate,
          DrivingExperience,
          SalaryFrom,
          SalaryTo,
        },
        Avatar,
        Email,
        Phone: getUnmaskedPhone(formValues?.Phone),
      };

      dispatch(
        updateUserActions({
          body: payload,
          userID: driver?.UserID,
          adminPanel: true,
        })
      );
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      onClose();
    }
  };

  const handleSetInitialValues = () => {
    form.resetFields();
    form.setFieldsValue(initialValues || EMPTY_OBJECT_DATA);
    handleAvatarReset();
  };

  const handleCancelChange = () => handleSetInitialValues();

  useEffect(() => {
    handleSetInitialValues();
  }, [driver]);

  const { showReadyToStartTomorrow } = useShowReadyStartTomorrowField({ form });
  const { showDriverLicenseField } = useShowDriverLicenseField({ form });

  const phoneVerified = Boolean(driver?.PhoneVerified);

  return {
    form,
    initialValues,
    fileList,
    showReadyToStartTomorrow,
    showDriverLicenseField,
    refData,
    phoneVerified,
    handleAvatarChange,
    handleAvatarRemove,
    handleSubmit,
    handleCancelChange,
  };
};
