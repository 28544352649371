import { FC } from "react";
import { isEmpty } from "lodash";
import { Empty } from "antd";
import { VacancyCard } from "@modules/social-services/children/vacancies/components/vacancy-card";
import { Vacancies } from "@/common/types/models/vacancies/model";
import { ILSSpin } from "@/common/components/data-display/spin";

interface IProps {
  vacancies: Vacancies;
  loading?: boolean;
}

export const VacanciesList: FC<IProps> = ({ vacancies, loading }) => {
  return (
    <ul className="vacancies__list">
      <ILSSpin spinning={loading} tip="Загрузка" size="large">
        {isEmpty(vacancies) ? (
          <Empty description="Нет активных вакансий" />
        ) : (
          vacancies.map((vacancy) => {
            return <VacancyCard key={vacancy.ID} vacancy={vacancy} />;
          })
        )}
      </ILSSpin>
    </ul>
  );
};
