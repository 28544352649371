import { FC } from "react";
import { User } from "@/common/types/models/user/user";
import { getUserFullName } from "@/common/utils/helpers/string/get-driver-full-name";
import { ILSTitle } from "@/common/ui-components/general/typography/title";

type IProps = {
  user: User;
};

export const UserCardHeader: FC<IProps> = ({ user }) => {
  const workingPosition = `(${
    user?.WorkingPosition || "Должность не указана"
  })`;

  const userFullName = getUserFullName({
    surname: user?.Surname,
    name: user?.Name,
    patronymic: user?.Patronymic,
  });

  return (
    <div className="user-card-header">
      <ILSTitle
        className="user-card-header-name"
        level={5}
        ellipsis={{ rows: 2 }}
      >
        {userFullName}
      </ILSTitle>
      <p className="user-card-header-position">{workingPosition}</p>
    </div>
  );
};
