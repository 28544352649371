import { createRoutine } from "redux-saga-routines";

export enum DriversActions {
  DICTIONARY_OPERATION = "DRIVERS/DICTIONARY_OPERATION",
}

export const driversRoutine = createRoutine(
  DriversActions.DICTIONARY_OPERATION
);

export const DriversActionsForNotifications = [driversRoutine];
