import "./index.scss";
import classNames from "classnames";
import { FC, ReactNode } from "react";
import { Tooltip } from "antd";
import {
  FileExcelOutlined,
  DownloadOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { NOT_SET } from "@/common/constants/general";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSButtonConfirmTooltip } from "@/common/components/data-display/buttons/confirm-tooltip";
import { downloadFileByRemoteURL } from "@/common/helpers/files/download-file-by-remote-url";

type IProps = {
  fileName: string | undefined;
  fileUrl?: string | undefined;
  fileID: string | number | undefined;
  disableDelete: boolean;
  fileIcon?: ReactNode;
  showDeleteButton?: boolean;
  showDownloadButton?: boolean;
  className?: string;
  handleDeleteDocument?: (ID: string | number | undefined) => void;
};

export const ILSFileCard: FC<IProps> = ({
  fileName,
  fileUrl,
  fileID,
  disableDelete,
  fileIcon = <FileExcelOutlined />,
  showDeleteButton = false,
  showDownloadButton = true,
  className,
  handleDeleteDocument,
}) => {
  const onClickDownloadDocument = () => {
    if (!fileUrl || !fileName) {
      return null;
    }
    return downloadFileByRemoteURL(fileUrl, fileName);
  };
  const onClickDeleteDocument = () => handleDeleteDocument?.(fileID);

  return (
    <div className={classNames("ils-file-card", className)}>
      <div>
        {fileIcon}
        <p>{fileName || NOT_SET}</p>
      </div>
      <div>
        {showDownloadButton && (
          <Tooltip title="Скачать шаблон">
            <ILSButton
              icon={<DownloadOutlined />}
              onClick={onClickDownloadDocument}
            />
          </Tooltip>
        )}
        {showDeleteButton && (
          <ILSButtonConfirmTooltip
            icon={<DeleteFilled />}
            title="Удалить шаблон"
            confirmDescription="Вы уверены, что хотите удалить шаблон?"
            onConfirm={onClickDeleteDocument}
            disabled={disableDelete}
          />
        )}
      </div>
    </div>
  );
};
