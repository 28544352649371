import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import * as questionnaireRootSagas from "@/common/features/questionnaire-common/sagas";
import { questionnaireRootReducer } from "@/common/features/questionnaire-common/reducers";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

const module: IModuleModel = {
  title: ModuleTitle.questionnaireCommon,
  name: ModuleName.questionnaireCommon,
  path: ModulePath.questionnaireCommon,
  reducer: questionnaireRootReducer,
  sagas: getAllModuleSagas(questionnaireRootSagas),
};

export const QuestionnaireCommonModule = defineModule(module);
