import { FC } from "react";
import { VacanciesFilters } from "@modules/social-services/children/vacancies/containers/filters";
import { VacanciesList } from "@modules/social-services/children/vacancies/containers/list";
import { VacanciesToolbar } from "@modules/social-services/children/vacancies/containers/toolbar";
import { SocialServicesContentWithAside } from "@/modules/social-services/containers/layout-with-content";
import { useVacancies } from "@/modules/social-services/children/vacancies/hooks/use-vacancies";
import { VacanciesDriverTabs } from "@/modules/social-services/children/vacancies/types/tabs";

type IProps = {
  showIndexModule: boolean;
  vacanciesDriverTab?: VacanciesDriverTabs;
};

export const VacanciesMain: FC<IProps> = ({
  showIndexModule,
  vacanciesDriverTab = VacanciesDriverTabs.Vacancies,
}) => {
  const {
    vacancies,
    loading,
    toolbarForm,
    sidebarForm,
    sidebarRefData,
    showStatusFormItem,
    activeSortKey,
    onToolbarFormChange,
    onSidebarFormChange,
    onClickResetSidebarForm,
    onSortClick,
  } = useVacancies({ showIndexModule, vacanciesDriverTab });

  return (
    <>
      <VacanciesToolbar
        toolbarForm={toolbarForm}
        sidebarForm={sidebarForm}
        sidebarRefData={sidebarRefData}
        showStatusFormItem={showStatusFormItem}
        activeSortKey={activeSortKey}
        onToolbarFormChange={onToolbarFormChange}
        onSidebarFormChange={onSidebarFormChange}
        onClickResetSidebarForm={onClickResetSidebarForm}
        onSortClick={onSortClick}
      />
      <SocialServicesContentWithAside>
        <VacanciesFilters
          sidebarForm={sidebarForm}
          sidebarRefData={sidebarRefData}
          onSidebarFormChange={onSidebarFormChange}
          onClickResetSidebarForm={onClickResetSidebarForm}
        />
        <VacanciesList vacancies={vacancies} loading={loading} />
      </SocialServicesContentWithAside>
    </>
  );
};
