import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";

interface IProps extends LinkProps {}

export const ILSLink: FC<IProps> = ({ ...props }) => {
  return (
    <Link {...props}>
      {/* <Typography.Link>{children}</Typography.Link> */}
    </Link>
  );
};
