import "../index.scss";
import { FC } from "react";
import { ProjectsMain } from "@/modules/social-services/children/projects/components/main";

interface IProps {}

export const Projects: FC<IProps> = () => {
  return (
    <div className="projects">
      <ProjectsMain />
    </div>
  );
};
