import { isEmpty, isNil } from "lodash";
import { DEFAULT_ORGANIZATION_TYPE_ID_VALUE } from "@/common/constants/config/options";
import { EMPTY_STRING } from "@/common/constants/general";
import { getObjectValuesWithType } from "@/common/utils/helpers/types";
import { Account, OrganizationInfo } from "@/common/types/models/account";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { OrganizationFormValues } from "@/modules/social-services/children/my-info/types/form";
import { User } from "@/common/types/models/user/user";
import { getImagesToForm } from "@/common/utils/helpers/components/avatar/get-images-to-form";

type IProps = {
  organizationInfo: Partial<OrganizationInfo>;
  profileInfo: {
    accountName: Account["Name"];
    phone: User["Phone"] | null | undefined;
    accountCode: Account["AccountCode"] | null;
  };
};

export const organizationToFormValues = ({
  profileInfo,
  organizationInfo,
}: IProps): OrganizationFormValues => {
  return getObjectValuesWithType<FormFieldName>(FormFieldName).reduce(
    (initialValues, formKey) => {
      // todo если нет organizationInfo, но есть avatar/accountName/phone/accountCode
      //  поля будут пустыми, редкий случай, но стоит обработать
      if (isEmpty(organizationInfo)) {
        return initialValues;
      }
      const { phone, accountCode } = profileInfo;

      switch (formKey) {
        case FormFieldName.OrganizationName:
          initialValues[formKey] = organizationInfo?.Name || EMPTY_STRING;
          return initialValues;
        case FormFieldName.Phone:
          initialValues[formKey] = phone || EMPTY_STRING;
          return initialValues;

        case FormFieldName.INN:
        case FormFieldName.KPP:
        case FormFieldName.OGRN:
        case FormFieldName.OKPO:
        case FormFieldName.LegalAddress:
        case FormFieldName.ActualAddress:
          initialValues[formKey] = organizationInfo?.[formKey] || EMPTY_STRING;
          return initialValues;

        case FormFieldName.OrganizationTypeID:
          initialValues[formKey] =
            organizationInfo?.[formKey] || DEFAULT_ORGANIZATION_TYPE_ID_VALUE;
          return initialValues;

        case FormFieldName.Avatar:
          initialValues[formKey] = getImagesToForm(organizationInfo?.Avatar);
          return initialValues;

        case FormFieldName.AccountCode:
          initialValues[formKey] = accountCode || EMPTY_STRING;
          return initialValues;

        case FormFieldName.PublicOrganizationPartnership:
          initialValues[formKey] = organizationInfo?.[formKey] || false;
          return initialValues;

        case FormFieldName.PartnershipChecked:
          initialValues[formKey] = !isNil(organizationInfo.PartnershipChecked)
            ? Number(organizationInfo.PartnershipChecked)
            : null;
          return initialValues;

        default:
          return initialValues;
      }
    },
    {} as OrganizationFormValues
  );
};
