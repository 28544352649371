import { recoveryActions, recoveryProcessActions } from "@modules/auth/actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { AUTH_API } from "@/common/api/endpoints/auth";
import { RecoveryRequest } from "@/common/api/types/auth";

export type RecoveryAction = PayloadAction<RecoveryRequest>;

export function* recoveryWorker(action: RecoveryAction) {
  const { request, failure, fulfill } = recoveryActions;

  try {
    yield put(request());
    yield call(AUTH_API.passwordRecovery, action.payload);
    yield put(recoveryProcessActions({ recoveryProcess: "code" }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* recoveryWatcher() {
  yield takeLatest(recoveryActions.trigger, recoveryWorker);
}
