import { call, put, select, takeLatest } from "redux-saga/effects";
import { AUTH_API } from "@/common/api/endpoints/auth";
import { User } from "@/common/types/models/user/user";
import { authUserSetActions, checkUserActions } from "@/modules/auth/actions";
import { currentUserSelector } from "@/modules/auth/selectors/current-user";
import { checkUserEqual } from "@/common/helpers/check-user-equal";

export function* checkUserWorker() {
  const { request, failure, fulfill } = checkUserActions;
  try {
    yield put(request());
    const currentUser: User = yield select(currentUserSelector);

    const { data: user }: { data: User } = yield call(AUTH_API.checkUser);

    if (!checkUserEqual(user, currentUser)) {
      yield put(
        authUserSetActions.success({
          user,
        })
      );
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* checkUserWatcher() {
  yield takeLatest(checkUserActions.trigger, checkUserWorker);
}
