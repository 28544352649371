import { FC } from "react";
import { FormInstance, UploadFile } from "antd";
import { ILSSpin } from "@/common/components/data-display/spin";
import { ILSDivider } from "@/common/components/layout/divider";
import { QuestionnaireAvailabilityStatus } from "@/common/api/types/questionnaire";
import { AdminQuestionnaireUploadForm } from "@/modules/admin-panel/children/admin-applications/components/forms/upload-form";
import { AdminQuestionnaireStatusForm } from "@/modules/admin-panel/children/admin-applications/components/forms/status-form";
import { QuestionnaireStatusFormValues } from "@/modules/admin-panel/children/admin-applications/types/status-form";

type IProps = {
  documents: UploadFile[] | undefined;
  disableUploadDelete: boolean;
  availabilityStatus: QuestionnaireAvailabilityStatus | null;
  form: FormInstance<QuestionnaireStatusFormValues>;
  questionnaireIsFetching: boolean;
  handleCheckBeforeUpload: (file?: UploadFile) => void;
  handleDeleteDocument: (fileID: string | number | undefined) => void;
  onQuestionnaireAvailabilityChange: (
    _: QuestionnaireStatusFormValues,
    values: QuestionnaireStatusFormValues
  ) => void;
};

export const AdminApplicationsSidebar: FC<IProps> = ({
  documents,
  disableUploadDelete,
  availabilityStatus,
  form,
  questionnaireIsFetching,
  handleCheckBeforeUpload,
  handleDeleteDocument,
  onQuestionnaireAvailabilityChange,
}) => {
  return (
    <div className="admin-questionnaire__sidebar">
      <ILSSpin spinning={questionnaireIsFetching} tip="Шаблон загружается">
        <AdminQuestionnaireUploadForm
          documents={documents}
          disableUploadDelete={disableUploadDelete}
          handleCheckBeforeUpload={handleCheckBeforeUpload}
          handleDeleteDocument={handleDeleteDocument}
        />
        <ILSDivider />
        <AdminQuestionnaireStatusForm
          form={form}
          availabilityStatus={availabilityStatus}
          onQuestionnaireAvailabilityChange={onQuestionnaireAvailabilityChange}
        />
      </ILSSpin>
    </div>
  );
};
