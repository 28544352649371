import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSInputNumber } from "@/common/ui-components/data-entry/input/number";
import { ILSInputMask } from "@/common/components/data-entry/masked-input";
import { ILSSelectApi } from "@/common/ui-components/data-entry/select/api";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { ILSCheckboxGroupApi } from "@/common/ui-components/data-entry/checkbox-group/api";
import { ILSRadioGroup } from "@/common/ui-components/data-entry/radio/group";
import { Dictionaries } from "@/common/types/models/dictionary";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { EMPTY_ARRAY_DATA, EMPTY_STRING } from "@/common/constants/general";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import {
  DRIVER_LICENSE_MASK,
  PHONE_MASK,
} from "@/common/constants/config/masks";
import {
  MEDICAL_CERTIFICATE_OPTIONS,
  PUBLIC_ORGANIZATION_MEMBERSHIP_CHECKED_OPTIONS,
  SEX_OPTIONS,
} from "@/common/constants/config/options";
import { filterOption } from "@/common/utils/helpers/components/select/filter-option";
import {
  FormItemsField,
  FormItemsFields,
} from "@/common/ui-components/data-entry/form-item/types";

export const DRIVER_AVATAR_JOB_SEARCH_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.ShowAvatar,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.ShowAvatar],
    },
    Component: ILSCheckbox,
  },
  {
    fieldProps: {
      field: FormFieldName.JobSearchStatus,
      dependencies: [FormFieldName.JobSearchRegion],
    },
    inputProps: {
      dictionary: Dictionaries.JobSearchStatus,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.JobSearchRegion,
      dependencies: [FormFieldName.JobSearchStatus],
    },
    inputProps: {
      dictionary: Dictionaries.JobRegion,
      showSearch: true,
      mode: "multiple",
      filterOption,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.ReadyForTrips,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.ReadyForTrips],
    },
    Component: ILSCheckbox,
  },
  {
    fieldProps: {
      field: FormFieldName.ReadyForRelocation,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.ReadyForRelocation],
    },
    Component: ILSCheckbox,
  },
] as FormItemsFields;

export const DRIVER_MEMBERSHIP_FIELD = [
  {
    fieldProps: {
      field: FormFieldName.PublicOrganizationMembership,
      label: EMPTY_STRING,
      valuePropName: "checked",
      help: "Статус участника доступен после проверки администратором",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.PublicOrganizationMembership],
    },
    Component: ILSCheckbox,
  },
] as FormItemsFields;

export const DRIVER_MEMBERSHIP_CHECK_FIELD = [
  {
    fieldProps: {
      field: FormFieldName.MembershipChecked,
    },
    inputProps: {
      optionType: "button",
      buttonStyle: "solid",
      options: PUBLIC_ORGANIZATION_MEMBERSHIP_CHECKED_OPTIONS,
    },
    Component: ILSRadioGroup,
  },
] as FormItemsFields;

export const DRIVER_READY_START_TOMORROW = [
  {
    fieldProps: {
      field: FormFieldName.ReadyToStartTomorrow,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.ReadyToStartTomorrow],
    },
    Component: ILSCheckbox,
  },
] as FormItemsFields;

export const DRIVER_PERSONAL_FIELDS = [
  {
    fieldProps: { field: FormFieldName.Surname },
    Component: ILSInput,
  },
  {
    fieldProps: { field: FormFieldName.Name },
    Component: ILSInput,
  },
  {
    fieldProps: { field: FormFieldName.Patronymic },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.Email,
      rules: EMPTY_ARRAY_DATA,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.Phone,
    },
    inputProps: {
      mask: PHONE_MASK,
    },
    Component: ILSInputMask,
  },
  {
    fieldProps: {
      field: FormFieldName.Birthday,
    },
    inputProps: {
      mask: Date,
    },
    Component: ILSInputMask,
  },
  {
    fieldProps: {
      field: FormFieldName.Sex,
    },
    inputProps: {
      optionType: "button",
      buttonStyle: "solid",
      options: SEX_OPTIONS,
    },
    Component: ILSRadioGroup,
  },
  {
    fieldProps: {
      field: FormFieldName.Citizens,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.EmploymentType,
    },
    inputProps: {
      dictionary: Dictionaries.EmploymentType,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.WorkSchedule,
    },
    inputProps: {
      mode: "multiple",
      dictionary: Dictionaries.WorkSchedule,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.EducationLevel,
    },
    inputProps: {
      dictionary: Dictionaries.EducationLevel,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.OwnVehicleType,
    },
    inputProps: {
      dictionary: Dictionaries.OwnVehicleType,
    },
    Component: ILSCheckboxGroupApi,
  },
  {
    fieldProps: {
      field: FormFieldName.MedicalCertificate,
    },
    inputProps: {
      optionType: "button",
      buttonStyle: "solid",
      options: MEDICAL_CERTIFICATE_OPTIONS,
    },
    Component: ILSRadioGroup,
  },
] as FormItemsFields;

export const DRIVER_HAS_DRIVER_LICENSE_FIELD = [
  {
    fieldProps: {
      field: FormFieldName.HaveDriverLicense,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      label: FORM_LABEL.HaveDriverLicense,
    },
    Component: ILSCheckbox,
  },
] as FormItemsFields;

export const DRIVER_DRIVING_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.DriverLicense,
      required: true,
    },
    inputProps: { mask: DRIVER_LICENSE_MASK },
    Component: ILSInputMask,
  },
  {
    fieldProps: {
      field: FormFieldName.DriverLicenseDate,
    },
    inputProps: {
      mask: Date,
    },
    Component: ILSInputMask,
  },
] as FormItemsFields;

export const DRIVER_EXPERIENCE_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.VehicleType,
    },
    inputProps: { dictionary: Dictionaries.VehicleType, mode: "multiple" },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.ExperienceYears,
    },
    inputProps: { min: 0 },
    Component: ILSInputNumber,
  },
  {
    fieldProps: {
      field: FormFieldName.Organization,
    },
    inputProps: {},
    Component: ILSInput,
  },
] as FormItemsFields;

export const DRIVER_LICENSE_FIELD = {
  fieldProps: { field: FormFieldName.LicenseCategory },
  inputProps: {
    size: "default",
    mode: "multiple",
    dictionary: Dictionaries.LicenseCategory,
  },
  Component: ILSSelectApi,
} as FormItemsField;

export const DEFAULT_DRIVING_EXPERIENCE_FIELDS = [
  {
    [FormFieldName.VehicleType]: EMPTY_ARRAY_DATA,
    [FormFieldName.ExperienceYears]: null,
    [FormFieldName.Organization]: EMPTY_STRING,
  },
];
