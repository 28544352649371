/** Тип рейтинга */
export enum RatingContentType {
  EducationAndEmploymentModule = "EducationAndEmploymentModule",
  LabourSafety = "LabourSafety",
  Social = "Social",
  Religion = "Religion",
  HealthyLifeStyle = "HealthyLifeStyle",
  Patriotism = "Patriotism",
  Entertainment = "Entertainment",
  CorporateCulture = "CorporateCulture",
  RatingTotal = "RatingTotal",
}

/** Тип блока: отдельный рейтинг, либо итоговый рейтинг */
export enum RatingBlockType {
  Default = "Default",
  Total = "Total",
}

export type RatingWithForm = Exclude<RatingContentType, "RatingTotal">;

export const isRatingWithForm = (
  ratingBlock: RatingContentType
): ratingBlock is RatingWithForm => {
  return ratingBlock !== RatingContentType.RatingTotal;
};

/** Статус проверки результатов анкеты */
export enum ApplicationModerationStatus {
  Sent = "Sent",
  Confirm = "Confirm",
  Refuse = "Refuse",
}

/** Базовый тип рейтинга
 * пока в работе
 */
export type Rating = {
  value: number | undefined;
  status: ApplicationModerationStatus | null;
};

export type RatingBlocks = Partial<Record<RatingContentType, Rating>>;
