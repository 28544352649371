import { createSelector } from "reselect";
import { AuthModuleStore } from "@modules/auth/store";
import { User } from "@/common/types/models/user/user";

export const currentUserSelector = createSelector(
  (state: AuthModuleStore) => {
    return state.AuthModule.user;
  },
  (user: User) => user
);

export const currentUserIDSelector = createSelector(
  currentUserSelector,
  (user: User) => user.ID
);
