import { call, put, takeLatest } from "redux-saga/effects";
import { sendCodeActions } from "@modules/social-services/children/my-info/actions";
import { SendCodePayloadAction } from "@/common/api/types/code";
import { CODE_API } from "@/common/api/endpoints/code";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";

export function* sendCodeWorker(action: SendCodePayloadAction) {
  const { request, success, failure, fulfill } = sendCodeActions;

  try {
    const { Target } = action.payload;

    yield put(request());
    yield call(CODE_API.sendCode);

    yield put(success({ target: Target }));
    yield call(showSuccessNotification, "Код успешно отправлен");
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* sendCodeWatcher() {
  yield takeLatest(sendCodeActions.trigger, sendCodeWorker);
}
