import { FC } from "react";
import { Rating } from "@/common/types/models/rating";
import {
  NOT_SENT,
  APPLICATION_MODERATION_STATUS_TITLES,
} from "@/common/constants/config/rating";
import { ILSRating } from "@/common/ui-components/data-display/rating";
import { ILSDivider } from "@/common/components/layout/divider";

type IProps = {
  ratingValue: Rating | null;
  ratingTotalValue: number;
};

export const MyRatingSidebar: FC<IProps> = ({
  ratingValue,
  ratingTotalValue,
}) => {
  return (
    <div className="rating-sidebar hide-xlarge">
      {/* TODO: не указываем значение каждого блока. В разработке */}
      {/* <div className="rating-sidebar__detail-group">
        <p>Текущее значение</p>
        <ILSRating rating={ratingValue?.value} />
      </div>
      <ILSDivider className="my-rating-application__divider" /> */}

      <div className="rating-sidebar__detail-group">
        <p>Итоговый социальный рейтинг компании</p>
        <ILSRating rating={ratingTotalValue} />
      </div>

      <ILSDivider className="my-rating-application__divider" />
      <div className="rating-sidebar__detail-group">
        <p>Статус проверки</p>
        <p>
          {ratingValue?.status
            ? APPLICATION_MODERATION_STATUS_TITLES[ratingValue?.status]
            : NOT_SENT}
        </p>
      </div>
    </div>
  );
};
