export enum Errors {
  Error_404 = "Error_404",
  Error_400 = "Error_400",
  Error_500 = "Error_500",
  Error_Network = "Error_Network",
  Error_401 = "Error_401",
  Error_403 = "Error_403",
}

export const API_ERROR_MESSAGES = {
  [Errors.Error_400]: "Невозможно выполнить запрос",
  [Errors.Error_404]: "Такой страницы не существует",
  [Errors.Error_500]: "Ошибка сервера",
  [Errors.Error_Network]: "Ошибка сети",
  [Errors.Error_401]: "Вы не авторизованы",
  [Errors.Error_403]: "В доступе отказано",
};
