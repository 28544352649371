export const COLOR_PALETTE = {
  primary: "rgb(47, 51, 64)",
  whitePrimary: "#ffffff",
  accent: "rgb(210, 35, 40)",
  chipDefault: "#c1cada",
  sliderDefault: "#f50",
};

export const STATUS_PALLETE = {
  Draft: {
    color: "#d48806",
    backgroundColor: "#fffbe6",
  },
  InModeration: {
    color: "#0958d9",
    backgroundColor: "#e6f4ff",
  },
  Rejected: {
    color: "#fff1f0",
    backgroundColor: "#cf1322",
  },
  Published: {
    color: "#f6ffed",
    backgroundColor: "#389e0d",
  },
};
