import { ModulePath } from "@/common/types/core/routes";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { DataProcessingRulesContainer } from "@/modules/data-processing-rules/containers";

const module: IModuleModel = {
  title: ModuleTitle.dataProcessingRules,
  name: ModuleName.dataProcessingRules,
  path: ModulePath.dataProcessingRules,
  element: <DataProcessingRulesContainer />,
};

export const DataProcessingRulesModule = defineModule(module);
