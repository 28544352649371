import { RouteObject } from "react-router-dom";
// import { DefaultRedirectPage } from "../../pages/default-redirect-page";

/**
 * Преобразует конфигурацию маршрутов в объект, который используется в react-router
 * */
export const getRouterObjects = (
  routes: Record<string, RouteObject>
): RouteObject[] => {
  const routesArray = Object.values(routes);

  return routesArray.map((route) => {
    return {
      path: route.path,
      element: route.element,
      children: route.children,
    };
  });
};
