import { ReactNode } from "react";

export enum UserCardActions {
  Delete = "Delete",
  Edit = "Edit",
  Block = "Block",
}

export type UserCardActionsType = Record<
  UserCardActions,
  {
    title: string;
    icon: ReactNode;
    disabled?: boolean;
    onClick(userId: number): void;
  }
>;
