import { ADMIN_AVAILABLE_SERVICES } from "@/common/constants/config/header";
import { ILSButton } from "@/common/components/data-display/buttons";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { ILSLink } from "@/common/ui-components/general/typography/link";

export const ILSAvailableServices = () => {
  return (
    <div className="header-component__services">
      <ILSAllowedTo perform={AbacPermission.adminPanel}>
        {ADMIN_AVAILABLE_SERVICES.map(({ text, ICON, style, to }) => {
          return (
            <ILSLink to={to} key={text}>
              <ILSButton icon={<ICON style={style} />}>{text}</ILSButton>
            </ILSLink>
          );
        })}
      </ILSAllowedTo>
    </div>
  );
};
