import { takeLatest } from "redux-saga/effects";
import { ARTICLES_ACTIONS_NOTIFY_STATUSES } from "@modules/admin-panel/children/admin-articles/actions/statuses";
import { ArticlesActionsForNotifications } from "@modules/admin-panel/children/admin-articles/actions";
import { userNotificationHook } from "@/common/hooks/user-notification-hook";

const worker = userNotificationHook({
  defaultStatuses: ARTICLES_ACTIONS_NOTIFY_STATUSES,
  moduleKey: "ADMIN_ARTICLES",
});

export function* articlesNotifierWatcher() {
  // eslint-disable-next-line no-restricted-syntax
  for (const routine of ArticlesActionsForNotifications) {
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.SUCCESS, worker);
  }
}
