import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { MY_INFO_INITIAL_STATE } from "@modules/social-services/children/my-info/store";
import { getUsersActions } from "@modules/social-services/children/my-info/actions";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { IndexedCollection } from "@/common/types/general/common";
import { User } from "@/common/types/models/user/user";

export const getUsersReducer = createReducer(MY_INFO_INITIAL_STATE, {
  [getUsersActions.REQUEST]: onRequest,
  [getUsersActions.FAILURE]: onFailure,
  [getUsersActions.FULFILL]: onFulfill,
  [getUsersActions.SUCCESS]: (
    state,
    action: PayloadAction<{ users: IndexedCollection<User> }>
  ) => {
    const { users } = action.payload;
    state.users = users;
    return state;
  },
});
