import "./news-modal.scss";
import { FC } from "react";
import { Flex, Modal, Skeleton } from "antd";
import { EMPTY_STRING } from "@/common/constants/general";
import { ILSImage } from "@/common/components/data-display/image";
import { ILSChips } from "@/common/ui-components/data-display/chips";
import { Article } from "@/common/api/types/article";
import { useNewsModal } from "@/modules/start/hooks/use-news-modal";

type IProps = {
  currentArticle: Article | null;
  loading: boolean;
  isModalOpen: boolean;
  showModal: VoidFunction;
};

export const NewsModal: FC<IProps> = ({
  currentArticle,
  loading,
  isModalOpen,
  showModal,
}) => {
  const { chips, cover } = useNewsModal(currentArticle);

  return (
    <Modal
      footer={null}
      visible={isModalOpen}
      width={1024}
      className="news-modal"
      title={
        <Skeleton active loading={loading}>
          {currentArticle?.Title}
        </Skeleton>
      }
      onCancel={showModal}
      children={
        <Skeleton active loading={loading}>
          <Flex vertical gap="1em">
            <ILSImage alt="Изображение к статье" src={cover} />
            <Flex gap="1em">
              {chips.map((tag) => (
                <ILSChips key={tag} children={tag} />
              ))}
            </Flex>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: currentArticle?.Text || EMPTY_STRING,
              }}
            />
          </Flex>
        </Skeleton>
      }
    />
  );
};
