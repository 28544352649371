import { useAbac } from "react-abac";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useAuth } from "@/common/hooks/use-auth";
import { AbacPermission } from "@/common/types/core/abac";
import {
  DriverInfo,
  isDriverInfoBase,
} from "@/common/types/models/user/driver";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { RATING_BADGE_COLORS } from "@/common/constants/rating/color";
import { PHONE_IS_VERIFIED_SHORT } from "@/common/features/drivers-common/constants";
import { useDriverCardMembership } from "@/common/features/drivers-common/hooks/use-driver-card-membership";
import { getDriverAvatar } from "@/common/features/drivers-common/helpers/get-driver-avatar";
import { getDriverExperienceSubtitle } from "@/common/features/drivers-common/helpers/get-driver-experience-subtitle";
import { getUserFullName } from "@/common/utils/helpers/string/get-driver-full-name";

export const useDriverCardDetails = (driver: DriverInfo) => {
  const { authenticated } = useAuth();
  const { userHasPermissions } = useAbac();
  const adminPanel = userHasPermissions(AbacPermission.adminPanel);
  const isDriverBase = isDriverInfoBase(driver);
  const { titleBadge, mainSubtitle } = useDriverCardMembership(
    driver,
    adminPanel
  );

  const avatar = getDriverAvatar(driver, adminPanel);
  const statuses = [
    {
      backgroundColor: RATING_BADGE_COLORS.full,
      text: "Готов выйти завтра",
      hide: !driver.ReadyToStartTomorrow,
    },
  ];

  const title =
    authenticated && isDriverBase
      ? getUserFullName({
          surname: driver?.Surname,
          name: driver?.Name,
          patronymic: driver?.Patronymic,
        })
      : "ФИО скрыто";

  const info = {
    title,
    titleBadge,
    mainSubtitle,
    subtitle: getDriverExperienceSubtitle(driver),
  };

  const driverPhoneNumber = isDriverBase ? driver?.Phone : undefined;

  const redirectID = authenticated ? driver?.ID : null;

  const profile =
    isDriverBase && driver?.PhoneVerified
      ? {
          title: PHONE_IS_VERIFIED_SHORT,
          icon: <CheckCircleOutlined className="success-icon" />,
        }
      : EMPTY_OBJECT_DATA;

  return {
    avatar,
    statuses,
    info,
    driverPhoneNumber,
    profile,
    redirectID,
  };
};
