import "./index.scss";
import { FC } from "react";
import { StartPageMain } from "@/modules/start/containers/main/main";
import { StartPageLeftSider } from "@/modules/start/containers/siders/left-sider";
import { StartPageRightSider } from "@/modules/start/containers/siders/right-sider";
import { StartPageFooter } from "@/modules/start/containers/footer";

interface IProps {}

export const StartPage: FC<IProps> = () => {
  return (
    <div className="start-page">
      <div className="start-page__content">
        <StartPageLeftSider />
        <StartPageMain />
        <StartPageRightSider />
      </div>
      <StartPageFooter />
    </div>
  );
};
