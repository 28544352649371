import { isNil } from "lodash";
import { Driver } from "@/common/types/models/user/driver";

/**
 * Проверяем, ожидает ли водитель проверки на участие в организации "Водители России"
 * MembershipChecked: true - Участие подтверждено
 * MembershipChecked: false - Участие отклонено
 * MembershipChecked: null - Нет результата проверки
 */

type IProps = {
  publicOrganizationMembership: Driver["PublicOrganizationMembership"];
  membershipChecked: Driver["MembershipChecked"];
};

export const checkDriverWaitingMembership = ({
  publicOrganizationMembership,
  membershipChecked,
}: IProps): boolean => {
  /** водитель запросил статус участника */
  const requestedMembership = publicOrganizationMembership;
  /** администратор еще не проверил */
  const noMembershipCheckResult = isNil(membershipChecked);

  return Boolean(requestedMembership && noMembershipCheckResult);
};
