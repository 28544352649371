import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import {
  VerificationFormType,
  VerificationFormValues,
} from "@/modules/social-services/children/my-info/types/verification";
import { useAccount } from "@/common/hooks/use-account";
import { useCodeVerificationActions } from "@/modules/social-services/children/my-info/hooks/verification/use-code-verification-actions";
import {
  verificationFetchingSelector,
  verificationTargetSelector,
} from "@/modules/social-services/children/my-info/selectors/verification";

export const useMyInfoVerification = () => {
  const target = useSelector(verificationTargetSelector);
  const isFetching = useSelector(verificationFetchingSelector);

  const { user } = useAccount();
  const isPhoneVerified = user?.PhoneVerified;

  /**
   * если есть сохраненный телефон для отправки кода, то форма проверки кода,
   * либо форма отправки кода.
   */
  const currentFormType = target
    ? VerificationFormType.checkCode
    : VerificationFormType.getCode;

  const [formType, setFormType] =
    useState<VerificationFormType>(currentFormType);

  useEffect(() => {
    setFormType(
      target ? VerificationFormType.checkCode : VerificationFormType.getCode
    );
  }, [target]);

  const initialValues =
    formType === VerificationFormType.getCode
      ? {
          [FormFieldName.Phone]: user.Phone,
        }
      : EMPTY_OBJECT_DATA;

  const { handleSendCode, handleCheckCode } = useCodeVerificationActions();

  const handleSubmit = (formValues: VerificationFormValues) => {
    if (formType === VerificationFormType.getCode) {
      return handleSendCode({ target: formValues.Phone });
    }

    return handleCheckCode({
      code: formValues.VerificationCode,
    });
  };

  return { formType, initialValues, isPhoneVerified, isFetching, handleSubmit };
};
