import { getDurationString } from "@/common/utils/helpers/date-time/get-duration-string";

export const getDrivingExperienceString = (
  years?: number | null,
  months?: number | null
): string | null => {
  if (!years && !months) return "не указано";

  const yearsString = getDurationString("years", years);
  const monthsString = getDurationString("months", months);

  switch (true) {
    case Boolean(yearsString && monthsString):
      return `${yearsString} ${monthsString}`;
    case Boolean(!yearsString && monthsString):
      return monthsString;
    case yearsString && !monthsString:
      return yearsString;
    default:
      return null;
  }
};
