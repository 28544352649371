import { call, put, takeLatest } from "redux-saga/effects";
import { surveyRoutine } from "@/modules/start/actions";
import { SURVEY_API } from "@/common/api/endpoints/survey";
import { SurveyPayload } from "@/common/api/types/survey";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";

export function* surveyWorker(action: SurveyPayload) {
  const { request, success, failure, fulfill } = surveyRoutine;
  const { operation, body } = action.payload;

  try {
    yield put(request());
    yield call(SURVEY_API.surveyOperation, {
      operation,
      body,
    });
    yield put(success());
    yield call(
      showSuccessNotification,
      "Результаты опроса успешно отправлены!"
    );
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* surveyWatcher() {
  yield takeLatest(surveyRoutine.trigger, surveyWorker);
}
