import { compareAsString } from "@/common/helpers/compare-as-string";

export const replaceUpdatedItem = <
  T extends { ID: number | string },
  I extends { ID: number | string }
>(
  updatedItem: I,
  array: Array<T>
) => {
  return array.map((item) => {
    return compareAsString(item.ID, updatedItem.ID)
      ? { ...updatedItem }
      : item ?? [updatedItem];
  });
};
