import { getObjectEntriesWithType } from "@/common/helpers/get-object-entries-with-type";
import { RATING_PATHS } from "@/common/constants/config/rating";
import { RatingContentType } from "@/common/types/models/rating";

export const getRatingBlockContentType = (pathParams?: string) => {
  return (
    getObjectEntriesWithType<RatingContentType>(RATING_PATHS).find(
      (ratingBlock) => {
        return ratingBlock[1] === pathParams;
      }
    )?.[0] || null
  );
};
