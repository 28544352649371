import { FC } from "react";
import { UserOutlined } from "@ant-design/icons";
import { EMPTY_STRING } from "@/common/constants/general";
import { ZERO_PUBLISHED_VACANCIES } from "@/common/features/vacancy/constants";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { ILSTag } from "@/common/components/data-display/tag";
import { ILSText } from "@/common/ui-components/general/typography/text";
import { ILSAvatar } from "@/common/components/data-display/avatar";
import { ILSRating } from "@/common/ui-components/data-display/rating";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { useAdminPathname } from "@/common/hooks/router/use-admin-pathname";
import { getVacancyOwnerPath } from "@/common/features/vacancy/helpers/get-vacancy-owner-path";

type IProps = {
  owner?: Vacancy["Owner"];
  publishedVacancies: number | undefined;
};

export const VacancyOwner: FC<IProps> = ({ owner, publishedVacancies }) => {
  const ownerLogo = owner?.Avatar?.FileUrl || null;
  const isAdminPanel = useAdminPathname();
  const vacancyOwnerPath = getVacancyOwnerPath({
    ownerOrganizationID: owner?.OrganizationID,
    isAdminPanel,
  });

  return (
    <div className="vacancy__owner">
      <div className="vacancy__owner-content">
        <div className="vacancy__owner-row">
          <ILSLink
            to={vacancyOwnerPath}
            state={{ previousLocationPathname: window.location.pathname }}
            relative="path"
          >
            <ILSText className="vacancy__owner-name" ellipsis>
              {owner?.Name}
            </ILSText>
          </ILSLink>
          <ILSRating
            rating={owner?.SocialRating}
            className="vacancy__owner-rating"
          />
        </div>
        <p>Работодатель</p>
        {/* Функционал проверки пока не реализован */}
        {/* <div className="vacancy__owner-row">
          <p>Проверка: </p>
          <ILSTag color={owner?.IsVerified ? "green" : "red"}>
            {owner?.IsVerified ? "Компания проверена" : "Компания не проверена"}
          </ILSTag>
        </div> */}
        <div className="vacancy__owner-row">
          <p>Активные вакансии: </p>
          {/* TODO: количество опубликованных вакансий */}
          <ILSTag color="blue">
            {publishedVacancies || ZERO_PUBLISHED_VACANCIES}
          </ILSTag>
        </div>
      </div>
      <ILSAvatar
        icon={!ownerLogo ? <UserOutlined /> : EMPTY_STRING}
        src={ownerLogo}
        className="vacancy__owner-avatar"
      />
    </div>
  );
};
