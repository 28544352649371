import {
  DriverInfo,
  isDriverInfoBase,
} from "@/common/types/models/user/driver";
import { checkDriverWaitingMembership } from "@/common/features/drivers-common/helpers/check-driver-waiting-membership";
import { checkDriverMembership } from "@/common/features/drivers-common/helpers/check-driver-membership";
import { getDriverMembershipBadge } from "@/common/features/drivers-common/helpers/get-driver-membership-badge";
import { getDriverMembershipTitle } from "@/common/features/drivers-common/helpers/get-driver-membership-title";

export const useDriverCardMembership = (
  driver: DriverInfo,
  adminPanel: boolean
) => {
  const isDriverBase = isDriverInfoBase(driver);

  const publicOrganizationMembership = isDriverBase
    ? driver?.PublicOrganizationMembership
    : false;
  const membershipChecked = isDriverBase ? driver?.MembershipChecked : null;

  /** имеет ли подтвержденный статус участника */
  const hasConfirmedMembership = checkDriverMembership(driver);

  /** ожидает ли водитель проверку */
  const waitingMembershipCheck = checkDriverWaitingMembership({
    publicOrganizationMembership,
    membershipChecked,
  });

  const titleBadge = getDriverMembershipBadge({
    hasConfirmedMembership,
    waitingMembershipCheck,
    adminPanel,
  });

  const mainSubtitle = getDriverMembershipTitle({
    hasConfirmedMembership,
  });

  return { titleBadge, mainSubtitle };
};
