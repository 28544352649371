import { createSelector } from "reselect";
import { AuthModuleStore } from "@modules/auth/store";
import { RecoveryProcess } from "@/common/api/types/auth";

export const recoveryProcessSelector = createSelector(
  (state: AuthModuleStore) => {
    return state.AuthModule.recoveryProcess;
  },
  (recoveryProcess: RecoveryProcess | null) => recoveryProcess
);
