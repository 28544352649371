import { FC } from "react";
import { AbacPermission } from "@/common/types/core/abac";
import { ProtectedPage } from "@/pages/protected-page";
import { OrganizationInfoForm } from "@/modules/social-services/children/my-info/components/forms/organization";
import { useMyInfoOrganizationContainer } from "@/modules/social-services/children/my-info/hooks/containers/use-my-info-organization-container";
import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";

type IProps = {
  activeTab: MyInfoTabs;
};

export const MyInfoOrganizationFormContainer: FC<IProps> = ({ activeTab }) => {
  const {
    form,
    initialValues,
    fileList,
    organizationSocialRating,
    disabled,
    notTouched,
    enableSubmit,
    handleSubmit,
    handleCancelChange,
    handleAvatarChange,
    handleAvatarRemove,
  } = useMyInfoOrganizationContainer({ activeTab });

  return (
    <ProtectedPage perform={AbacPermission.organizationAndEmployee}>
      <section className="my-info">
        <OrganizationInfoForm
          form={form}
          initialValues={initialValues}
          fileList={fileList}
          organizationSocialRating={organizationSocialRating}
          disabled={disabled}
          notTouched={notTouched}
          enableSubmit={enableSubmit}
          handleSubmit={handleSubmit}
          handleCancelChange={handleCancelChange}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
      </section>
    </ProtectedPage>
  );
};
