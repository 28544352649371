import { isEmpty } from "lodash";
import { OrganizationInfo } from "@/common/types/models/account";

/**
 * Проверяем, является ли организация подтвержденным партнером организации "Водители России"
 */

export const checkOrganizationPartnership = (
  organization: Partial<OrganizationInfo> | undefined | null
): boolean => {
  if (!isEmpty(organization)) {
    return Boolean(
      organization?.PublicOrganizationPartnership &&
        organization?.PartnershipChecked
    );
  }

  return false;
};
