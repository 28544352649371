import { Row } from "antd";
import { useAdminDrivers } from "@/modules/admin-panel/children/admin-drivers/hooks/use-admin-drivers";
import { DriversSideBar } from "@/modules/admin-panel/children/admin-drivers/containers/sidebar";
import { AdminDriversContent } from "@/modules/admin-panel/children/admin-drivers/containers/content/content";

export const AdminDriversMain = () => {
  const { onClose, mode, showSidebar } = useAdminDrivers();

  return (
    <Row>
      <DriversSideBar onClose={onClose} mode={mode} />
      <AdminDriversContent showSidebar={showSidebar} />
    </Row>
  );
};
