import { useAccount } from "@/common/hooks/use-account";
import { checkDriverRequiredFields } from "@/common/features/drivers-common/helpers/check-driver-required-fields";
import { getDriverRequiredFieldsMessages } from "@/common/features/drivers-common/helpers/get-driver-required-fields-messages";

export const useDriverIncompleteProfile = () => {
  const { user } = useAccount();

  const { requiredDriverFieldsCondition } = checkDriverRequiredFields(user);

  /** 1. не заполнены обязательные поля */
  const hasBlankRequiredFields = !Object.values(
    requiredDriverFieldsCondition
  ).every(Boolean);

  const driverRequiredFieldsMessages = getDriverRequiredFieldsMessages(
    requiredDriverFieldsCondition
  );

  /** 2. не подтвержден номер телефона */
  const phoneIsVerified = user?.PhoneVerified;

  /** Профиль водителя не активен, если 1. не заполнены обязательные поля и 2. не подтвержден номер телефона */
  const driverProfileIsIncomplete = hasBlankRequiredFields || !phoneIsVerified;

  return {
    driverProfileIsIncomplete,
    driverRequiredFieldsMessages,
    hasBlankRequiredFields,
    phoneIsVerified,
  };
};
