import { FC } from "react";
import { useDriverCard } from "@modules/social-services/children/driver-search/hooks/use-driver-card";
import { ILSWideCard } from "@/common/ui-components/data-display/wide-card";
import { DriverInfo } from "@/common/types/models/user/driver";
import { ILSConfirmModal } from "@/common/components/custom/confirm-modal/confirm-modal";

interface IProps {
  driver: DriverInfo;
}

export const DriverCard: FC<IProps> = ({ driver }) => {
  const {
    info,
    statuses,
    actions,
    avatar,
    redirectID,
    profile,
    isModalOpen,
    showModal,
    onOkModalClick,
  } = useDriverCard(driver);

  return (
    <>
      <ILSWideCard
        avatar={avatar}
        statuses={statuses}
        info={info}
        actions={actions}
        redirectID={redirectID}
        profile={profile}
      />
      <ILSConfirmModal
        open={isModalOpen}
        onCancel={showModal}
        onOk={onOkModalClick}
        content="Для просмотра номера телефона требуется авторизация."
        okText="Перейти к авторизации"
      />
    </>
  );
};
