import { useState } from "react";
import { debounce } from "lodash";
import { useForm } from "antd/es/form/Form";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { ADMIN_DRIVERS_FILTERS_INITIAL_VALUES } from "@/common/features/drivers-common/constants/drivers-filters";
import { DriversFiltersRequestType } from "@/common/api/types/driver";
import { DriversFiltersFormValues } from "@/common/features/drivers-common/types/drivers-filters";
import { formValuesToDriversFilters } from "@/common/features/drivers-common/helpers/form-values-to-drivers-filters";

export const useAdminDriversFilters = () => {
  const [form] = useForm<DriversFiltersFormValues>();

  const [filters, setFilters] =
    useState<DriversFiltersRequestType>(EMPTY_OBJECT_DATA);

  const onValuesChange = debounce(
    (_: DriversFiltersFormValues, values: DriversFiltersFormValues) => {
      const formFilters = formValuesToDriversFilters(values);
      setFilters(formFilters);
    },
    500
  );

  const resetFilters = () => {
    setFilters(EMPTY_OBJECT_DATA);
    form.resetFields();
    form.setFieldsValue(ADMIN_DRIVERS_FILTERS_INITIAL_VALUES);
  };

  return { form, filters, onValuesChange, resetFilters };
};
