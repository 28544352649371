import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSSelect } from "@/common/ui-components/data-entry/select";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ORGANIZATION_TYPE_ID_OPTIONS } from "@/common/constants/config/options";
import { FORBIDDEN_KEYS } from "@/common/ui-components/data-entry/form/constants/forbidden-keys";
import { PHONE_MASK } from "@/common/constants/config/masks";
import { ILSInputMask } from "@/common/components/data-entry/masked-input";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const ORGANIZATION_GENERAL_DATA = [
  {
    fieldProps: {
      field: FormFieldName.OrganizationName,
      required: true,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.OrganizationTypeID,
    },
    inputProps: {
      options: ORGANIZATION_TYPE_ID_OPTIONS,
    },
    Component: ILSSelect,
  },
  {
    fieldProps: {
      field: FormFieldName.Phone,
    },
    inputProps: {
      mask: PHONE_MASK,
    },
    Component: ILSInputMask,
  },
  {
    fieldProps: {
      field: FormFieldName.INN,
    },
    inputProps: { forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.KPP,
    },
    inputProps: { forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.OGRN,
    },
    inputProps: { forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.OKPO,
    },
    inputProps: { forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.LegalAddress,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.ActualAddress,
    },
    Component: ILSInput,
  },
] as FormItemsFields;
