import { Transform } from "redux-persist/es/types";
import { createTransform } from "redux-persist";
import { TModulesObj, TPersistTransform } from "@/common/types/core/module";
import { flatModules } from "../modules/flat-modules";

/** Кастомизация сохранения и извлечения данных из персиста.
 *
 * @onPersist - метод преобразования во время процесса сериализации
 * @initialState - дефолтное состояние store
 *
 * Чтобы для модуля отрабатывала кастомная сериализация необходимо в defineModule задать persistTransform: TPersistTransform
 *
 * Пример,
 *
 * export const persistTransform: TPersistTransform<IPlanningByDateStore> = {
 *
 *  initialState,
 *
 *  onPersist: (inboundState: IPlanningByDateStore) => {
 *
 *     const savedState = { projectID: inboundState.project?.ID };
 *
 *     return savedState;
 *
 *   },
 *
 * };
 * */
/** Метод получение всех кастомных преобразований для persist из модулей проекта.
 * Возвращает массив transforms с преобразованиями, и массив blacklist
 * */
export const getModulesPersistTransform = (
  modules: TModulesObj,
  transforms: Array<Transform<any, any>> = [],
  blacklist: Array<string> = []
): { transforms: Array<Transform<any, any>>; blacklist: Array<string> } => {
  const flat = flatModules(modules);
  for (let i = 0; i < flat.length; i++) {
    if (Object.prototype.hasOwnProperty.call(flat, i)) {
      const { persist } = flat[i];

      if (persist !== undefined && persist !== null && [flat[i].MODULE!]) {
        // если исключен весь модуль - то не смотрим на transform callback
        if (typeof persist === "boolean") {
          if (!persist) blacklist.push(flat[i].MODULE!);
        } else {
          const moduleTransform = persist as TPersistTransform<any>;
          const persistTransform = createTransform(
            moduleTransform.onPersist || null,
            (outboundState: any) => {
              return { ...moduleTransform?.initialState, ...outboundState };
            },
            {
              whitelist: [flat[i].MODULE!],
            }
          );
          transforms.push(persistTransform);
        }
      }

      if (
        typeof flat[i].children === "object" &&
        Object.keys(flat[i].children!).length > 0
      ) {
        getModulesPersistTransform(flat[i].children!, transforms, blacklist);
      }
    }
  }
  return { transforms, blacklist };
};
