import "./sider-banner.scss";
import { FC, ReactNode } from "react";
import classNames from "classnames";

type IProps = {
  children: ReactNode;
  className?: string;
};

export const StartPageSiderBanner: FC<IProps> = ({ children, className }) => {
  return (
    <article className={classNames("sider-banner", className)}>
      {children}
    </article>
  );
};
