import { useDispatch } from "react-redux";
import { useModal } from "@/common/hooks/use-modal";
import { User } from "@/common/types/models/user/user";
import { UserCardActions } from "@/common/types/models/user/card-actions";
import { useUserCardActionType } from "@/common/hooks/user-card/use-user-card-action-type";
import { useUserCardActions } from "@/common/hooks/user-card/use-user-card-actions";
import {
  deleteUserActions,
  updateUserActions,
} from "@/modules/social-services/children/my-info/actions";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";

type IProps = {
  selectedUser: User | null;
  handleSelectUser: (userId: number) => void;
};

export const useMyInfoUsersActions = ({
  selectedUser,
  handleSelectUser,
}: IProps) => {
  const dispatch = useDispatch();

  const { isModalOpen, showModal } = useModal();

  const { actionType, handleChangeActionType, resetActionType } =
    useUserCardActionType();

  const onEdit = (userId: number) => {
    handleSelectUser(userId);
    handleChangeActionType(UserCardActions.Edit);
  };

  const onDelete = (userId: number) => {
    handleSelectUser(userId);
    showModal();
    handleChangeActionType(UserCardActions.Delete);
  };

  const onBlock = (userId: number) => {
    handleSelectUser(userId);
    showModal();
    handleChangeActionType(UserCardActions.Block);
  };

  const userCardActions = useUserCardActions({
    selectedUser,
    onEdit,
    onBlock,
    onDelete,
  });

  const onOk = () => {
    try {
      if (actionType === UserCardActions.Block) {
        dispatch(
          updateUserActions({
            body: {
              Blocked: !selectedUser?.Blocked,
            },
            userID: selectedUser?.ID,
          })
        );
      }
      if (actionType === UserCardActions.Delete) {
        dispatch(
          deleteUserActions({
            userID: selectedUser?.ID,
          })
        );
      }
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      showModal();
    }
  };

  const isBlocked = selectedUser?.Blocked;

  const modalProps = {
    content:
      actionType === UserCardActions.Delete
        ? "Вы уверены, что хотите удалить пользователя?"
        : `Вы уверены, что хотите ${
            isBlocked ? "разблокировать" : "заблокировать"
          } пользователя?`,
    onOk,
    open: isModalOpen,
    onCancel: showModal,
  };

  return {
    actionType,
    userCardActions,
    modalProps,
    resetActionType,
  };
};
