import { FC } from "react";
import { Empty } from "antd";
import { isEmpty } from "lodash";
import { ILSSpin } from "@/common/components/data-display/spin";
import { QuestionnaireResultType } from "@/common/api/types/questionnaire";
import { AdminQuestionnaireResultsCard } from "@/modules/admin-panel/children/admin-applications/components/result-card";

interface IProps {
  results: QuestionnaireResultType[];
  isFetching: boolean;
}

export const AdminQuestionnaireResultsList: FC<IProps> = ({
  results,
  isFetching,
}) => {
  return (
    <ILSSpin spinning={isFetching} tip="Загрузка" size="large">
      <ul className="admin-questionnaire__list">
        {isEmpty(results) ? (
          <Empty description="Нет результатов анкет" />
        ) : (
          results.map((result) => (
            <AdminQuestionnaireResultsCard result={result} key={result.ID} />
          ))
        )}
      </ul>
    </ILSSpin>
  );
};
