import { createReducer } from "@reduxjs/toolkit";
import {
  MY_INFO_INITIAL_STATE,
  MyInfoStore,
} from "@modules/social-services/children/my-info/store";
import { checkCodeActions } from "@modules/social-services/children/my-info/actions";
import { onFailure } from "@/common/helpers/reducers/on-failure";

export const checkCodeReducer = createReducer(MY_INFO_INITIAL_STATE, {
  [checkCodeActions.REQUEST]: (state: MyInfoStore) => {
    state.verification.isFetching = true;
    return state;
  },
  [checkCodeActions.FAILURE]: onFailure,
  [checkCodeActions.SUCCESS]: (state) => {
    state.verification.target = null;
    return state;
  },
  [checkCodeActions.FULFILL]: (state: MyInfoStore) => {
    state.verification.isFetching = false;
    return state;
  },
});
