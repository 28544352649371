import { FC } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { ArticleCrud } from "@modules/admin-panel/children/admin-articles/containers/content/article-crud";
import { AdminArticlesFilter } from "@modules/admin-panel/children/admin-articles/containers/filter/article-filter";
import { ArticleEditorModalFooter } from "@modules/admin-panel/children/admin-articles/components/article-editor-modal-footer";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { useCurrentArticle } from "@/common/hooks/articles/use-current-article";

interface IProps {
  isModalOpen: boolean;
  showArticleEditorModal: VoidFunction;
}

export const AdminArticlesToolbar: FC<IProps> = ({
  showArticleEditorModal,
  isModalOpen,
}) => {
  const { currentArticleActionTitle, resetArticle } = useCurrentArticle();

  const addArticle = () => {
    resetArticle();
    showArticleEditorModal();
  };

  return (
    <div>
      <ILSButton type="primary" onClick={addArticle} icon={<PlusOutlined />}>
        Добавить статью
      </ILSButton>

      <Modal
        width={1000}
        title={<ILSTitle>{currentArticleActionTitle}</ILSTitle>}
        open={isModalOpen}
        onOk={showArticleEditorModal}
        onCancel={showArticleEditorModal}
        footer={
          <ArticleEditorModalFooter
            showArticleEditorModal={showArticleEditorModal}
          />
        }
        centered
        destroyOnClose
        className="admin-articles-form-modal"
      >
        <ArticleCrud showArticleEditorModal={showArticleEditorModal} />
      </Modal>
      <AdminArticlesFilter />
    </div>
  );
};
