import { FC } from "react";
import { ADMIN_USER_FIELDS } from "@/modules/admin-panel/children/admin-organizations/children/organization/constants/user-form";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSDivider } from "@/common/components/layout/divider";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {};

export const AdminUserFormFields: FC<IProps> = () => {
  return (
    <div className="admin-organization-form__fields">
      <ILSTitle level={3}>Сотрудник</ILSTitle>
      <ILSDivider className="admin-organization-form__divider" />
      <div className="admin-organization-form__fields-group">
        {renderFormItems({ fields: ADMIN_USER_FIELDS })}
      </div>
    </div>
  );
};
