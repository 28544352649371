import { LaptopOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { VacancyContainer } from "./containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";

const module: IModuleModel = {
  title: ModuleTitle.vacancy,
  name: ModuleName.vacancy,
  path: ModulePath.vacancy,
  icon: <LaptopOutlined />,
  element: <VacancyContainer />,
  persist: false,
};

export const VacancyModule = defineModule(module);
