import { replaceWhitespace } from "@/common/utils/helpers/regexp/replace-whitespace";
import { getNumberValue } from "@/common/utils/helpers/number/get-number-value";

export const getUnmaskedValue = (
  value: string | null | undefined
): string | undefined => {
  return replaceWhitespace(value);
};

export const getUnmaskedNumberValue = (
  value: string | null | undefined
): number | undefined => {
  const noWhitespaceCharacters = replaceWhitespace(value);
  return getNumberValue(noWhitespaceCharacters);
};
