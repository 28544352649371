import { Empty } from "antd";
import { isEmpty } from "lodash";
import { FC } from "react";
import { useSelector } from "react-redux";
import { currentUserIDSelector } from "@modules/auth/selectors/current-user";
import { ILSSpin } from "@/common/components/data-display/spin";
import { User } from "@/common/types/models/user/user";
import { UserCardActionsType } from "@/common/types/models/user/card-actions";
import { UserCard } from "@/common/components/data-display/user-card";
import { compareAsString } from "@/common/helpers/compare-as-string";

type IProps = {
  users: User[];
  isFetching: boolean;
  userCardActions: UserCardActionsType;
};

export const MyInfoUsersList: FC<IProps> = ({
  users,
  isFetching,
  userCardActions,
}) => {
  const currentUserID = useSelector(currentUserIDSelector);

  return (
    <ILSSpin spinning={isFetching} tip="Загрузка" size="large">
      <ul className="my-info-users__list">
        {!isEmpty(users) ? (
          users?.map((user) => (
            <UserCard
              isCurrentUserCard={compareAsString(user.ID, currentUserID)}
              user={user}
              key={user.ID}
              userCardActions={userCardActions}
            />
          ))
        ) : (
          <Empty />
        )}
      </ul>
    </ILSSpin>
  );
};
