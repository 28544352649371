import { User } from "@/common/types/models/user/user";
import { DriverRequiredFields } from "@/common/features/drivers-common/types/driver-required-fields";

/** Функция возвращает объект с обязательными полями водителя:
 * ключ - обязательное поле;
 * значение - флаг заполнено поле или нет;
 */

export const checkDriverRequiredFields = (
  user: User
): { requiredDriverFieldsCondition: Record<DriverRequiredFields, boolean> } => {
  // - фамилия, мя
  const name = Boolean(user?.Driver?.Name && user?.Driver?.Surname);

  // - телефон
  const phone = Boolean(user?.Phone);

  // - если указано, что есть водительское удостоверение, то проверка на наличие номера водительского удостоверения
  const drivingLicense = user?.Driver?.HaveDriverLicense
    ? Boolean(user.Driver?.DriverLicense)
    : true;

  const requiredDriverFieldsCondition = {
    [DriverRequiredFields.name]: name,
    [DriverRequiredFields.phone]: phone,
    [DriverRequiredFields.drivingLicense]: drivingLicense,
  };

  return { requiredDriverFieldsCondition };
};
