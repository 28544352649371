import { FC } from "react";
import { Drawer } from "antd";
import { SideBarMode } from "@/modules/admin-panel/children/admin-drivers/types/general";
import { AdminEditDriverForm } from "@/modules/admin-panel/children/admin-drivers/containers/sidebar/edit-driver-form";

interface IProps {
  onClose(): void;
  mode: SideBarMode | boolean;
}

export const DriversSideBar: FC<IProps> = ({ onClose, mode }) => {
  return (
    <Drawer
      title={
        mode === SideBarMode.SearchDriver
          ? "Форма поиска водителей"
          : "Форма редактирования водителя"
      }
      size={mode === SideBarMode.SearchDriver ? "default" : "large"}
      onClose={onClose}
      placement={mode === SideBarMode.SearchDriver ? "left" : "right"}
      open={Boolean(mode)}
      destroyOnClose
    >
      <AdminEditDriverForm mode={mode} onClose={onClose} />
    </Drawer>
  );
};
