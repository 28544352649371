import { FC } from "react";
import { Button, Form } from "antd";
import Title from "antd/es/typography/Title";
import { LOGIN_FIELDS } from "@modules/auth/children/login/constants";
import { useLogin } from "@modules/auth/children/login/hooks/use-login";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { ModulePath } from "@/common/types/core/routes";
import { ILSLink } from "@/common/ui-components/general/typography/link";

export const Login: FC = () => {
  const { onFinish, loading } = useLogin();

  return (
    <Form
      name="auth-form_login"
      onFinish={onFinish}
      layout="vertical"
      className="auth-form auth-form_login"
    >
      <Title className="auth-title" children="Вход на сайт" />
      {renderFormItems({ fields: LOGIN_FIELDS })}
      <ILSLink
        to={`/${ModulePath.auth}/${ModulePath.recovery}`}
        relative="path"
      >
        Забыл пароль
      </ILSLink>
      <Button
        loading={loading}
        form="auth-form_login"
        size="large"
        type="primary"
        htmlType="submit"
        className="auth-form__button"
      >
        Войти
      </Button>
      <ILSLink
        to={`/${ModulePath.auth}/${ModulePath.register}`}
        relative="path"
      >
        Нет аккаунта? Зарегистрироваться
      </ILSLink>
    </Form>
  );
};
