import { FC } from "react";
import { MyInfoFormRating } from "@/modules/social-services/children/my-info/components/subforms/rating";

type IProps = {
  rating: number;
};

export const MyInfoOrganizationExtraDetails: FC<IProps> = ({ rating }) => {
  return (
    <div className="my-info__group">
      <MyInfoFormRating rating={rating} />
    </div>
  );
};
