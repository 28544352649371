import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSBreadcrumb } from "@/common/components/data-display/breadcrumb";
import { RATING_TITLES } from "@/common/constants/config/rating";
import {
  isRatingWithForm,
  RatingContentType,
} from "@/common/types/models/rating";
import { getRatingBreadcrumbs } from "@/modules/social-services/children/my-info/children/application/utils/get-rating-breadcrumbs";

type IProps = {
  ratingType: RatingContentType;
};

export const MyRatingBlockHeader: FC<IProps> = ({ ratingType }) => {
  const isWithForm = isRatingWithForm(ratingType);
  const items = getRatingBreadcrumbs(ratingType);
  const subtitle = isWithForm
    ? "Анкета социального портрета организации"
    : "Результаты всех блоков социального портрета";
  const description = isWithForm
    ? "Для повышения рейтинга Вашей организации, пожалуйста, заполните анкету и отправьте на модерацию."
    : "";

  return (
    <div className="my-rating-header">
      <ILSBreadcrumb items={items} />
      <ILSTitle level={1} className="my-rating-header__title">
        {RATING_TITLES[ratingType]}
      </ILSTitle>
      <ILSTitle level={3} className="my-rating-header__subtitle">
        {subtitle}
      </ILSTitle>
      <p className="my-rating-header__description hide-xlarge">{description}</p>
    </div>
  );
};
