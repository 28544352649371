import { FC } from "react";
import { EMPTY_STRING } from "@/common/constants/general";
import { ILSImage } from "@/common/components/data-display/image";

type IProps = {
  imageUrl: string;
  slideIndex: number;
  isLastSlide: boolean;
  restPhotosCount: string | null;
  handlePreviewImage: (slideIndex: number) => void;
  handleOpenModal: VoidFunction;
};

export const VacancyPreviewImage: FC<IProps> = ({
  imageUrl,
  slideIndex,
  isLastSlide,
  restPhotosCount,
  handlePreviewImage,
  handleOpenModal,
}) => {
  const onMouseEnterPreview = () => handlePreviewImage(slideIndex);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className="vacancy__vehicle-preview"
      onMouseEnter={onMouseEnterPreview}
      onClick={handleOpenModal}
    >
      <ILSImage src={imageUrl} preview={false} />
      <div className="vacancy__vehicle-preview-overlay">
        <p>{isLastSlide ? restPhotosCount : EMPTY_STRING}</p>
      </div>
    </div>
  );
};
