import "../index.scss";
import { FC } from "react";
import { AdminArticlesList } from "@modules/admin-panel/children/admin-articles/containers/content/admin-articles-list";
import { AdminArticlesToolbar } from "@modules/admin-panel/children/admin-articles/containers/toolbar";
import { useModal } from "@/common/hooks/use-modal";

interface IProps {}

export const AdminArticles: FC<IProps> = () => {
  const { isModalOpen, showModal } = useModal();

  return (
    <section className="admin-articles">
      <AdminArticlesToolbar
        showArticleEditorModal={showModal}
        isModalOpen={isModalOpen}
      />
      <AdminArticlesList showArticleEditorModal={showModal} />
    </section>
  );
};
