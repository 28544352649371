import { FC } from "react";
import { FormInstance } from "antd";
import { ILSButton } from "@/common/components/data-display/buttons";
import { DriversFiltersFormValues } from "@/common/features/drivers-common/types/drivers-filters";
import { RefDataType } from "@/common/features/dictionaries/types";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FORM_RULES } from "@/common/ui-components/data-entry/form/constants/rules";
import {
  DRIVERS_DRIVER_FILTERS_FIELDS,
  DRIVERS_MAIN_FILTERS_FIELDS,
  DRIVERS_SEARCH_FILTERS_INITIAL_VALUES,
} from "@/common/features/drivers-common/constants/drivers-filters";
import { SocialServicesFilters } from "@/modules/social-services/containers/filters";
import { Salary } from "@/common/components/data-entry/form-item/salary";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

interface IProps {
  form: FormInstance<DriversFiltersFormValues>;
  hide?: boolean;
  refData: RefDataType;
  disabled: boolean;
  resetFilters: VoidFunction;
  onValuesChange: (
    _: DriversFiltersFormValues,
    values: DriversFiltersFormValues
  ) => void;
}

export const DriverSearchFilters: FC<IProps> = ({
  form,
  hide = true,
  refData,
  disabled,
  resetFilters,
  onValuesChange,
}) => {
  return (
    <SocialServicesFilters
      form={form}
      hide={hide as boolean}
      onValuesChange={onValuesChange}
      initialValues={DRIVERS_SEARCH_FILTERS_INITIAL_VALUES}
    >
      <ILSButton
        onClick={resetFilters}
        danger
        style={{ marginBottom: "1em" }}
        disabled={disabled}
      >
        Сбросить фильтры
      </ILSButton>
      {renderFormItems({
        fields: DRIVERS_MAIN_FILTERS_FIELDS,
        refData,
        disabled,
      })}
      <Salary
        rules={FORM_RULES.DesiredSalary}
        label={FORM_LABEL.DesiredSalary}
        disabled={disabled}
      />
      {renderFormItems({
        fields: DRIVERS_DRIVER_FILTERS_FIELDS,
        refData,
        disabled,
      })}
    </SocialServicesFilters>
  );
};
