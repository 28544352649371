import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { Resources } from "@/modules/social-services/children/resources/containers";

const module: IModuleModel = {
  title: ModuleTitle.resources,
  name: ModuleName.resources,
  path: ModulePath.resources,
  icon: <ExclamationCircleOutlined />,
  element: <Resources />,
  persist: false,
};

export const ResourcesModule = defineModule(module);
