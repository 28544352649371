import { FC } from "react";
import { Form } from "antd";
import { SideBarMode } from "@/modules/admin-panel/children/admin-drivers/types/general";
import { useAdminEditDriver } from "@/modules/admin-panel/children/admin-drivers/hooks/use-admin-edit-driver";
import { ILSFormAvatar } from "@/common/components/custom/form-avatar";
import { ILSButton } from "@/common/components/data-display/buttons";
import { AdminEditDriverFormFields } from "@/modules/admin-panel/children/admin-drivers/components/form/admin-edit-driver-form-fields";

interface IProps {
  mode: SideBarMode | boolean;
  onClose(): void;
}

export const AdminEditDriverForm: FC<IProps> = ({ mode, onClose }) => {
  const {
    form,
    fileList,
    refData,
    phoneVerified,
    showReadyToStartTomorrow,
    showDriverLicenseField,
    handleAvatarChange,
    handleAvatarRemove,
    handleSubmit,
    handleCancelChange,
  } = useAdminEditDriver({ onClose });
  if (mode && mode !== SideBarMode.EditDriver) return null;

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        name="admin-edit-driver"
        className="admin-edit-driver"
        onFinish={handleSubmit}
      >
        <ILSFormAvatar
          fileList={fileList}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
        <AdminEditDriverFormFields
          showReadyToStartTomorrow={showReadyToStartTomorrow}
          showDriverLicenseField={showDriverLicenseField}
          phoneVerified={phoneVerified}
          refData={refData}
        />
        <div className="admin-edit-driver__buttons">
          <ILSButton onClick={handleCancelChange}>Отмена</ILSButton>
          <ILSButton type="primary" htmlType="submit">
            Сохранить
          </ILSButton>
        </div>
      </Form>
    </div>
  );
};
