import dayjs from "dayjs";
import { EMPTY_STRING } from "@/common/constants/general";
import { VacancyFormValues } from "@/common/features/vacancies-common/types/form";
// import { VacanciesRequestValues } from "@/common/features/vacancies-common/types/api";
import { DeletedImageRequest } from "@/common/types/models/image";
import { Currency } from "@/common/constants/general/currencies";
import { getCompanyHasVehicle } from "@/common/features/vacancies-common/helpers/get-company-has-vehicle";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";
import { getNumberPrice } from "@/common/utils/helpers/price/get-number-price";
import { getVacancyImages } from "@/common/features/vacancies-common/helpers/get-vacancy-images";

/** NOTE: валидация на бэке учитывает регистр.
 * Все поля ожидают в нижнем регистре, кроме категории прав и валюты;
 */

export const formValuesToVacancy = (
  formValues: VacancyFormValues,
  files: (string | DeletedImageRequest)[]
) => {
  return {
    Title: formValues.Title,
    Description: formValues.Description,
    Details: {
      Profession: formValues.Profession || EMPTY_STRING,
      LicenseCategory: formValues.LicenseCategory,
      DrivingExperience: {
        Year: formValues.DrivingExperience?.Years || null,
        Month: formValues.DrivingExperience?.Months || null,
      },
    },
    Vehicle: {
      Own: getCompanyHasVehicle(formValues?.CompanyHasVehicle),
      Brand: formValues?.Brand,
      Type: formValues.VehicleType,
      ProductionYear: formValues?.ProductionYear
        ? dayjs(formValues?.ProductionYear)?.year()
        : null,
      Profile: {
        MaxMass: formValues?.AllowedMaxMass || null,
        LoadCapacity: formValues?.LoadCapacity || null,
        WheelArrangement: formValues?.WheelArrangement,
        EngineType: formValues?.EngineType,
        EngineCapacity: formValues?.EngineCapacity || null,
        EngineVolume: formValues?.EngineVolume || null,
        EcologyClass: formValues?.EcologyClass,
        Gearbox: formValues?.Gearbox,
        CabinType: formValues?.CabinType,
        Condition: formValues?.VehicleCondition,
        Image: getVacancyImages(files, formValues?.VehiclePhotos),
        Video: formValues?.Video,
      },
    },
    RegistrationMethod: formValues.RegistrationMethod,
    Payment: {
      Price: {
        From: getNumberPrice(formValues?.Salary?.From) || 0,
        To: getNumberPrice(formValues?.Salary?.To) || 0,
        Unit: Currency.RUB,
      },
      Frequency: formValues?.PaymentFrequency,
      Term: formValues?.PaymentTerms,
      WorkSchedule: formValues?.WorkSchedule,
    },
    Info: {
      Address: formValues?.Address || EMPTY_STRING,
      Phone: getUnmaskedPhone(formValues?.VacancyPhone) || EMPTY_STRING,
    },
    Extra: formValues?.Extra,
    Comment: EMPTY_STRING,
    JobRegion: formValues.JobRegion,
  };
};
