import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { getDictionaryRoutine } from "@/common/features/dictionaries/actions";
import { Dictionaries, DictionaryType } from "@/common/types/models/dictionary";
import { DICTIONARIES_INITIAL_STATE } from "@/common/features/dictionaries/store";

export const getDictionaryReducer = createReducer(DICTIONARIES_INITIAL_STATE, {
  [getDictionaryRoutine.REQUEST]: onRequest,
  [getDictionaryRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      data: DictionaryType[];
      dictionary: Dictionaries;
    }>
  ) => {
    const { data, dictionary } = action.payload;

    if (!state.dictionaries) {
      state.dictionaries = {} as Record<Dictionaries, DictionaryType[]>;
    }

    state.dictionaries = {
      ...state.dictionaries,
      [dictionary]: data,
    };

    return state;
  },
  [getDictionaryRoutine.FAILURE]: onFailure,
  [getDictionaryRoutine.FULFILL]: onFulfill,
});
