import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSInputMask } from "@/common/components/data-entry/masked-input";
import { PHONE_MASK } from "@/common/constants/config/masks";
import { FORBIDDEN_KEYS } from "@/common/ui-components/data-entry/form/constants/forbidden-keys";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const ACCOUNT_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.Name,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.Surname,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.Patronymic,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.Email,
    },
    inputProps: {
      forbiddenKeys: FORBIDDEN_KEYS.SPACE,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.Phone,
    },
    inputProps: {
      mask: PHONE_MASK,
    },
    Component: ILSInputMask,
  },
  {
    fieldProps: {
      field: FormFieldName.WorkingPosition,
    },
    Component: ILSInput,
  },
] as FormItemsFields;
