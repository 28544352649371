import { NotFound } from "@/common/components/custom/not-found";
import { MainPageContainer } from "@/pages/main-page-container";

export const NotFoundPage = () => {
  return (
    <MainPageContainer>
      <NotFound />
    </MainPageContainer>
  );
};
