import { FC } from "react";
import { ILSBadgeRibbon } from "@/common/components/data-display/badge-ribbon";

type IProps = {
  id: number | null | undefined;
};

export const WideCardRibbon: FC<IProps> = ({ id }) => {
  if (!id) {
    return null;
  }

  return (
    <div className="ils-card__ribbon">
      <ILSBadgeRibbon text={`ID:${id}`} placement="start" />
    </div>
  );
};
