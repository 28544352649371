import { Outlet } from "react-router-dom";
import { useShowIndexModule } from "@/common/hooks/use-show-index-module";
import { ModulePath } from "@/common/types/core/routes";
import { NotFound } from "@/common/components/custom/not-found";

export const OrganizationsContainer = () => {
  const { showIndexModule } = useShowIndexModule(ModulePath.organization);

  if (showIndexModule) {
    return <NotFound />;
  }

  return <Outlet />;
};
