import { FC, RefObject } from "react";
import { Modal } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/es/carousel";
import { EMPTY_STRING } from "@/common/constants/general";
import { ILSCarousel } from "@/common/components/data-display/carousel";
import { ILSImage } from "@/common/components/data-display/image";
import { ILSButton } from "@/common/components/data-display/buttons";

type IProps = {
  vehiclePhotos?: {
    Count: number;
    Data: {
      FileUrl: string;
    }[];
  };
  modalCarouselRef?: RefObject<CarouselRef>;
  isModalOpen: boolean;
  showModal: VoidFunction;
};

export const VacancyVehiclePhotosModal: FC<IProps> = ({
  vehiclePhotos,
  modalCarouselRef,
  isModalOpen,
  showModal,
}) => {
  return (
    <Modal
      open={isModalOpen}
      onOk={showModal}
      onCancel={showModal}
      style={{ minWidth: "50%" }}
      okText={EMPTY_STRING}
      footer={null}
    >
      <div className="vacancy__vehicle-photos_modal">
        <ILSButton
          size="large"
          icon={<ArrowLeftOutlined />}
          className="vacancy__modal-arrow left"
          onClick={() => modalCarouselRef?.current?.prev()}
        />

        <ILSCarousel carouselRef={modalCarouselRef} dots={false}>
          {vehiclePhotos?.Data?.map((vehiclePhoto) => (
            <div
              key={vehiclePhoto.FileUrl}
              className="vacancy__vehicle-main-photo_modal"
            >
              <ILSImage src={vehiclePhoto.FileUrl} preview={false} />
            </div>
          ))}
        </ILSCarousel>

        <ILSButton
          size="large"
          icon={<ArrowRightOutlined />}
          className="vacancy__modal-arrow right"
          onClick={() => modalCarouselRef?.current?.next()}
        />
      </div>
    </Modal>
  );
};
