import { ILSDivider } from "@/common/components/layout/divider";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { StartPageFooterList } from "@/modules/start/components/footer/list";

export const StartPageFooterDesktop = () => {
  return (
    <div className="start-page__footer hide-medium">
      <div className="start-page__footer-content">
        <ILSTitle level={5} className="start-page__footer-title">
          Следите за новостями
        </ILSTitle>
        <ILSDivider className="start-page__footer-divider" type="vertical" />
        <StartPageFooterList />
      </div>
    </div>
  );
};
