import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { ADMIN_DRIVERS_INITIAL_STATE } from "@/modules/admin-panel/children/admin-drivers/store/store";
import { adminDriversSelectDriverRoutine } from "@/modules/admin-panel/children/admin-drivers/actions";

export const selectAdminDriverReducer = createReducer(
  ADMIN_DRIVERS_INITIAL_STATE,
  {
    [adminDriversSelectDriverRoutine.REQUEST]: onRequest,
    [adminDriversSelectDriverRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{
        driverID: number | null;
      }>
    ) => {
      const { driverID } = action.payload;
      state.selectedDriverID = driverID;
      return state;
    },
    [adminDriversSelectDriverRoutine.FAILURE]: onFailure,
    [adminDriversSelectDriverRoutine.FULFILL]: onFulfill,
  }
);
