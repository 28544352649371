import { Form } from "antd";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSSelect } from "@/common/ui-components/data-entry/select";

export const DriverAppliesToolbar = () => {
  return (
    <div className="driver-applies__toolbar">
      <Form layout="vertical">
        <ILSFormItem name="applies" label="Статус откликов">
          <ILSSelect
            defaultValue="all"
            options={[
              {
                value: "all",
                label: "Любые отклики",
              },
              {
                value: "seen",
                label: "Резюме просмотрено",
              },
              {
                value: "notSeen",
                label: "Резюме не просмотрено",
              },
              {
                value: "invitation",
                label: "Приглашение",
              },
              {
                value: "rejection",
                label: "Отказ",
              },
            ]}
          />
        </ILSFormItem>
      </Form>
    </div>
  );
};
