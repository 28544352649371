import { ModulePath } from "@/common/types/core/routes";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { UnderConstructionPage } from "@/modules/under-construction/containers";

const module: IModuleModel = {
  title: ModuleTitle.underConstruction,
  name: ModuleName.underConstruction,
  path: ModulePath.underConstruction,
  element: <UnderConstructionPage />,
};

export const UnderConstructionModule = defineModule(module);
