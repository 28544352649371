import { User } from "../models/user/user";
import { AccountRole } from "@/common/types/models/account";

export enum AbacPermission {
  auth = "auth",
  nonAuth = "nonAuth",
  authContent = "authContent",
  adminPanel = "adminPanel",
  exceptAdmin = "exceptAdmin",
  exceptDriver = "exceptDriver",
  driver = "driver",
  organizationAndEmployee = "organizationAndEmployee",
  organization = "organization",
  hasActionsWithVacancies = "hasActionsWithVacancies",
}

export type CheckAbacAccess = <P>(parameters: P, user: User) => boolean;
export type AbacRules = Record<AbacPermission, boolean | CheckAbacAccess>;
export type UserAbacRules = Record<AccountRole, AbacRules>;
