import { FC } from "react";
import { SideBarMode } from "@/modules/admin-panel/children/admin-drivers/types/general";
import { useAdminDriversList } from "@/modules/admin-panel/children/admin-drivers/hooks/use-admin-drivers-list";
import { AdminDriversToolbar } from "@/modules/admin-panel/children/admin-drivers/containers/content/toolbar";
import { AdminDriversFilters } from "@/modules/admin-panel/children/admin-drivers/components/form/admin-drivers-filters";
import { AdminDriversList } from "@/modules/admin-panel/children/admin-drivers/containers/content/list";

interface IProps {
  showSidebar: (mode: SideBarMode) => void;
}

export const AdminDriversContent: FC<IProps> = ({ showSidebar }) => {
  const {
    drivers,
    loading,
    form,
    refData,
    resetFilters,
    onValuesChange,
    onEditDriver,
  } = useAdminDriversList({
    showSidebar,
  });

  return (
    <div className="admin-drivers">
      <AdminDriversToolbar
        form={form}
        refData={refData}
        onValuesChange={onValuesChange}
        resetFilters={resetFilters}
      />
      <div className="admin-drivers__content">
        <AdminDriversFilters
          form={form}
          refData={refData}
          onValuesChange={onValuesChange}
          resetFilters={resetFilters}
        />
        <AdminDriversList
          drivers={drivers}
          loading={loading}
          onEditDriver={onEditDriver}
        />
      </div>
    </div>
  );
};
