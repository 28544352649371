import { FC } from "react";
import { Rating, RatingWithForm } from "@/common/types/models/rating";
import { MyRatingForm } from "@/modules/social-services/children/my-info/children/application/components/form/component";
import { MyRatingSidebar } from "@/modules/social-services/children/my-info/children/application/components/sidebar/sidebar";

type IProps = {
  ratingType: RatingWithForm;
  ratingValue: Rating | null;
  ratingTotalValue: number;
};

export const MyRatingWithForm: FC<IProps> = ({
  ratingType,
  ratingValue,
  ratingTotalValue,
}) => {
  return (
    <div className="my-rating-application__with-form">
      <MyRatingForm ratingType={ratingType} />
      <MyRatingSidebar
        ratingValue={ratingValue}
        ratingTotalValue={ratingTotalValue}
      />
    </div>
  );
};
