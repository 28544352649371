import moment from "moment";
import { EMPTY_STRING } from "@/common/constants/general";
import {
  FormatType,
  ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT,
  ILS_MAIN_INTERFFACE_TIME_FORMAT,
  ILS_MAIN_INTERFFACE_DATE_FORMAT,
} from "@/common/types/general/date-time";

export const timestampToDateTimeString = (
  ts?: number | null,
  type: FormatType = FormatType.Datetime
): string => {
  if (!ts) return EMPTY_STRING;
  const momentDate = moment.unix(ts);
  if (!momentDate.isValid()) return EMPTY_STRING;
  switch (type) {
    case FormatType.Datetime:
      return momentDate.utc(true).format(ILS_MAIN_INTERFFACE_DATE_TIME_FORMAT);
    case FormatType.Time:
      return momentDate.utc(true).format(ILS_MAIN_INTERFFACE_TIME_FORMAT);
    default:
      return momentDate.utc(true).format(ILS_MAIN_INTERFFACE_DATE_FORMAT);
  }
};
