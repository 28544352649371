import { FC } from "react";
import { ModulePath } from "@/common/types/core/routes";
import { DEFAULT_ZERO_RATING } from "@/common/constants/config/rating";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { ILSTag } from "@/common/components/data-display/tag";
import { ILSRating } from "@/common/ui-components/data-display/rating";
import { ILSBadgeRibbon } from "@/common/components/data-display/badge-ribbon";
import { timestampToDateTimeString } from "@/common/utils/helpers/date-time/timestamp-to-date-time-string";
import { ApplyStatusTitle } from "@/common/components/custom/apply-status";
import { ILSButton } from "@/common/components/data-display/buttons";
import { OrganizationApply } from "@/common/types/models/vacancies/apply";
import { FormatType } from "@/common/types/general/date-time";
import { ILSText } from "@/common/ui-components/general/typography/text";
import { getVacancyOwnerPath } from "@/common/features/vacancy/helpers/get-vacancy-owner-path";

type IProps = {
  apply: OrganizationApply;
  onApplyCancel: (applyId: number | undefined) => void;
};

export const DriverApplyCard: FC<IProps> = ({ apply, onApplyCancel }) => {
  const onClickApplyCancel = () => onApplyCancel(apply?.ID);

  const vacancyOwnerPath = getVacancyOwnerPath({
    ownerOrganizationID: apply?.Vacancy?.Owner?.OrganizationID,
  });

  return (
    <div className="driver-applies__card">
      <div className="driver-applies__card-badge">
        <ILSBadgeRibbon text={`ID:${apply.ID}`} placement="end" />
      </div>
      <p className="driver-applies__id hide-medium">{apply.ID}</p>
      <ApplyStatusTitle status={apply?.State?.Alias} />

      <div className="driver-applies__card-group">
        <div className="driver-applies__card-row">
          <ILSTag bordered={false}>№{apply?.Vacancy?.ID}</ILSTag>
          <ILSLink
            to={`/${ModulePath.socialServices}/${ModulePath.vacancies}/${apply?.Vacancy?.ID}`}
            relative="path"
          >
            <ILSText className="driver-applies__name" ellipsis>
              {apply?.Vacancy?.Title}
            </ILSText>
          </ILSLink>
        </div>

        <div className="driver-applies__card-row">
          <ILSLink to={vacancyOwnerPath} relative="path">
            <ILSText className="driver-applies__company" ellipsis>
              {apply?.Vacancy?.Owner?.Name}
            </ILSText>
          </ILSLink>
          <ILSRating
            rating={apply?.Vacancy?.Owner?.SocialRating || DEFAULT_ZERO_RATING}
            className="driver-applies__rating"
          />
        </div>
      </div>

      <div className="driver-applies__card-group driver-applies__card-group_center">
        <p>{timestampToDateTimeString(apply?.Created, FormatType.Date)}</p>
        <ILSButton onClick={onClickApplyCancel}>Отменить отклик</ILSButton>
      </div>
    </div>
  );
};
