import { head, isArray, isEmpty } from "lodash";
import { DEFAULT_ZERO_RATING } from "@/common/constants/config/rating";
import { User } from "@/common/types/models/user/user";

export const getWidgetRating = (user: User | undefined) => {
  if (
    isArray(user?.Account?.Organization) &&
    !isEmpty(user?.Account?.Organization)
  ) {
    return (
      head(user?.Account?.Organization)?.SocialRating || DEFAULT_ZERO_RATING
    );
  }

  return null;
};
