import { EMPTY_STRING } from "@/common/constants/general";
import { AuthAction } from ".";
import { Notify } from "@/common/types/notifications/enum";
import { ActionsNotifyStatuses } from "@/common/types/notifications/actions";

export type AuthActionsNotifyStatuses =
  ActionsNotifyStatuses<AuthAction.authUserSet>;

export const AUTH_ACTIONS_NOTIFY_STATUSES: AuthActionsNotifyStatuses = {
  [AuthAction.authUserSet]: {
    [Notify.Loading]: EMPTY_STRING,
    [Notify.Success]: EMPTY_STRING,
  },
};
