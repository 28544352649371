import { VacanciesTabStatus } from "@/common/types/models/vacancies/status";
import {
  VacanciesToolbarFilterValues,
  VacanciesSource,
} from "@/common/features/vacancies-common/types/filters";

/**
 *  Если таб "Все/Только мои" = Все, то показываем все опубликованные
 *  Если таб "Все/Только мои" = Только мои, передаем статус в параметр запроса,
 *  но если статус вакансии "Все", то не передаем статус в параметр.
 *  Для администратора не нужна проверка на Таб "Все/Только мои";
 */

export const getVacanciesStatusParameter = (
  vacancyFilters: VacanciesToolbarFilterValues,
  adminPanel: boolean = false
): string | undefined => {
  const isAllStatus =
    vacancyFilters?.VacanciesStatus === VacanciesTabStatus.All;

  if (adminPanel) {
    return !isAllStatus ? vacancyFilters.VacanciesStatus : undefined;
  }

  if (vacancyFilters?.VacanciesSource === VacanciesSource.All) {
    return VacanciesTabStatus.Published;
  }

  return vacancyFilters.VacanciesSource === VacanciesSource.Mine && !isAllStatus
    ? vacancyFilters.VacanciesStatus
    : undefined;
};
