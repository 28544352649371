import { UploadFile } from "antd";
import { UpdateRequestBody } from "@/common/api/types/api";
import { User } from "@/common/types/models/user/user";
import { DriverFormValues } from "@/common/features/drivers-common/types/driver-form";
import { DeletedImageRequest } from "@/common/types/models/image";
import { MembershipCheckStatus } from "@/common/types/models/user/driver";
import { drivingExperienceToValue } from "@/common/features/drivers-common/helpers/driving-experience-to-value";
import { getPayloadFromFormValues } from "@/common/ui-components/data-entry/form/helpers/get-payload-from-form-values";
import { getFormImage } from "@/common/helpers/get-account-avatar";
import { getNumberPrice } from "@/common/utils/helpers/price/get-number-price";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";
import { getUnixFromDateString } from "@/common/helpers/dates/get-unix-from-date-string";

type Props = {
  formValues: DriverFormValues;
  fileList: UploadFile[] | undefined;
  removedSavedFiles: DeletedImageRequest[];
  selectedCategories: number[];
  membershipChecked: number | null | undefined;
};

export const formValuesToDriverData = async ({
  formValues,
  fileList,
  removedSavedFiles,
  selectedCategories,
  membershipChecked,
}: Props) => {
  const Avatar = await getFormImage(fileList, removedSavedFiles);
  const DrivingExperience = drivingExperienceToValue(
    formValues?.DrivingExperience
  );

  const SalaryFrom = getNumberPrice(formValues?.Salary?.From) || null;
  const SalaryTo = getNumberPrice(formValues?.Salary?.To) || null;
  delete formValues.Salary;

  /** Если статус проверен и принят, и пользователь убирает значение "Участник", то сбрасываем проверку */
  const resetConfirmedCheck =
    membershipChecked === MembershipCheckStatus.Confirmed &&
    formValues?.PublicOrganizationMembership === false;

  /** Если статус проверен и отклонен, и пользователь вновь ставит чекбокс "Участник", то сбрасываем проверку */
  const resetCancelledCheck =
    membershipChecked === MembershipCheckStatus.Cancelled &&
    formValues?.PublicOrganizationMembership === true;

  const MembershipChecked =
    resetConfirmedCheck || resetCancelledCheck ? null : membershipChecked;

  const Email = formValues?.Email || null;
  delete formValues.Email;

  const Birthday = getUnixFromDateString(formValues.Birthday);
  const DriverLicenseDate = getUnixFromDateString(
    formValues?.DriverLicenseDate
  );

  const driverValues =
    getPayloadFromFormValues<
      UpdateRequestBody<Omit<User, "Avatar" | "Rating">>
    >(formValues);

  return {
    Driver: {
      ...driverValues,
      LicenseCategory: selectedCategories,
      DrivingExperience,
      SalaryFrom,
      SalaryTo,
      MembershipChecked,
      DriverLicenseDate,
      Birthday,
    },
    Avatar,
    Email,
    Phone: getUnmaskedPhone(formValues?.Phone),
  };
};
