import { useDispatch } from "react-redux";
import { CodeTargetType } from "@/common/api/types/code";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import {
  checkCodeActions,
  sendCodeActions,
} from "@/modules/social-services/children/my-info/actions";

export const useCodeVerificationActions = () => {
  const dispatch = useDispatch();

  const handleSendCode = ({ target }: { target: string | undefined }) => {
    try {
      dispatch(sendCodeActions({ Target: target, Type: CodeTargetType.sms }));
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  const handleCheckCode = ({ code }: { code: string | undefined }) => {
    try {
      if (code?.trim()) {
        dispatch(checkCodeActions({ Code: code }));
      } else {
        showUserDevErrors({
          e: "Необходимо ввести код подтверждения",
          message: "Необходимо ввести код подтверждения",
        });
      }
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  return { handleSendCode, handleCheckCode };
};
