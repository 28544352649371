import { FC } from "react";
import { Modal } from "antd";
import { VERIFICATION_MODAL_TITLE } from "@/modules/social-services/children/my-info/constants";
import { useMyInfoVerification } from "@/modules/social-services/children/my-info/hooks/verification/use-my-info-verification";
import { MyInfoVerificationForm } from "@/modules/social-services/children/my-info/components/forms/verification";
import { MyInfoVerificationSuccess } from "@/modules/social-services/children/my-info/components/verification/verification-success";
import { ILSSpin } from "@/common/components/data-display/spin";

interface IProps {
  isModalOpen: boolean;
  showModal: VoidFunction;
}

export const MyInfoVerificationModal: FC<IProps> = ({
  isModalOpen,
  showModal,
}) => {
  const { formType, initialValues, isPhoneVerified, isFetching, handleSubmit } =
    useMyInfoVerification();

  return (
    <Modal
      open={isModalOpen}
      onOk={showModal}
      onCancel={showModal}
      footer={null}
      centered
      title={VERIFICATION_MODAL_TITLE}
      destroyOnClose
    >
      <ILSSpin spinning={isFetching} tip="Загрузка" size="large">
        {!isPhoneVerified ? (
          <MyInfoVerificationForm
            formType={formType}
            initialValues={initialValues}
            handleSubmit={handleSubmit}
          />
        ) : (
          <MyInfoVerificationSuccess onClose={showModal} />
        )}
      </ILSSpin>
    </Modal>
  );
};
