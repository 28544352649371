import { ILSDivider } from "@/common/components/layout/divider";
import { MyInfoDriverAlert } from "@/modules/social-services/children/my-info/components/driver-alert";
import { MyInfoFormVerification } from "@/modules/social-services/children/my-info/components/verification/verification";
import { MyInfoProfileCompleteness } from "@/modules/social-services/children/my-info/components/profile-completeness";
import { useDriverIncompleteProfile } from "@/common/features/drivers-common/hooks/use-driver-incomplete-profile";

export const MyInfoDriverExtraDetails = () => {
  const {
    driverProfileIsIncomplete,
    driverRequiredFieldsMessages,
    phoneIsVerified,
    hasBlankRequiredFields,
  } = useDriverIncompleteProfile();

  return (
    <div className="my-info__group">
      <ILSDivider className="my-info__verification-form-divider" />
      <MyInfoDriverAlert
        driverProfileIsIncomplete={driverProfileIsIncomplete}
      />
      <ILSDivider className="my-info__verification-form-divider" />
      <MyInfoFormVerification phoneIsVerified={phoneIsVerified} />
      <MyInfoProfileCompleteness
        hasBlankRequiredFields={hasBlankRequiredFields}
        driverRequiredFieldsMessages={driverRequiredFieldsMessages}
      />
    </div>
  );
};
