export enum FileUploadExtensions {
  pdf = ".pdf",
  docx = ".docx",
  xls = ".xls",
  xlsx = ".xlsx",
  rar = ".rar",
  zip = ".zip",
  txt = ".txt",
  png = ".png",
  webp = ".webp",
  jpg = ".jpg",
  ini = ".ini",
  jpeg = ".jpeg",
  gif = ".gif",
  bmp = ".bmp",
  doc = ".doc",
  csv = ".csv",
  mp4 = ".mp4",
}
