import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSCardWithIcon } from "@/common/components/custom/card-with-icon";
import { SendQuestionType } from "@/common/api/types/send-question";
import { ContactsSendQuestionForm } from "@/modules/social-services/children/contacts/components/form";
import { useContactsMain } from "@/modules/social-services/children/contacts/hooks/use-contacts-main";

export const ContactsMain = () => {
  const { cardsContent } = useContactsMain();
  return (
    <div className="contacts__section">
      <div className="contacts__section-header">
        <ILSTitle
          children="Контакты для связи"
          className="contacts__section-title"
          ellipsis={false}
        />
      </div>
      <div className="contacts__section-content">
        <ul className="contacts__list">
          {cardsContent.map((cardContent) => (
            <ILSCardWithIcon {...cardContent} key={cardContent.title} />
          ))}
        </ul>
        <ContactsSendQuestionForm
          type={SendQuestionType.SupportQuestion}
          className="contacts__form_wide"
        />
      </div>
    </div>
  );
};
