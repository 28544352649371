import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { VacanciesTabStatus } from "@/common/types/models/vacancies/status";

export enum VacanciesSource {
  All = "All",
  Mine = "Mine",
}
/** Фильтры в Toolbar */
export type VacanciesToolbarFilterValues = {
  [FormFieldName.VacanciesSource]: VacanciesSource;
  [FormFieldName.VacanciesStatus]: VacanciesTabStatus;
};

/** Фильтры в Sidebar */
export type VacanciesSidebarFilterValues = {
  [FormFieldName.LicenseCategory]: number[];
  [FormFieldName.WorkSchedule]: number[];
  [FormFieldName.OrganizationIsVerified]: boolean;
  [FormFieldName.PaymentFrequency]: number | null;
  [FormFieldName.JobRegion]: number | null;
  [FormFieldName.Salary]: {
    [FormFieldName.From]: number | null;
    [FormFieldName.To]: number | null;
  };
};
