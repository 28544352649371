import { useDispatch, useSelector } from "react-redux";
import {
  articleInitialValuesSelector,
  isFetchingSelector,
} from "@modules/admin-panel/children/admin-articles/selectors/articles";
import { useArticleCrud } from "@modules/admin-panel/children/admin-articles/hooks/use-article-crud";
import { notification } from "antd";
import { head } from "lodash";
import { articlesRoutine } from "@modules/admin-panel/children/admin-articles/actions";
import { useEffect, useMemo } from "react";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { ARTICLE_FORM_FIELDS } from "@/modules/admin-panel/children/admin-articles/constants/form";
import {
  CreateArticle,
  CreateArticleFormValues,
} from "@/common/api/types/article";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { useRemoveFile } from "@/common/hooks/use-remove-file";
import { getFormImage } from "@/common/helpers/get-account-avatar";

export const useArticleActions = ({
  showArticleEditorModal,
}: {
  showArticleEditorModal: VoidFunction;
}) => {
  const { initialValues, initialArticleContent } = useSelector(
    articleInitialValuesSelector
  );
  const loading = useSelector(isFetchingSelector);
  const { articleContent, setContent } = useArticleCrud();
  const { removedSavedFiles, onRemoveFile } = useRemoveFile();
  const dispatch = useDispatch();

  useEffect(() => {
    setContent(initialArticleContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialArticleContent]);

  const createArticle = async (v: CreateArticleFormValues) => {
    const imageFile = head(v.Image)?.originFileObj;
    if (!imageFile) {
      notification.error({ message: "Загрузите изображение к статье" });
      return;
    }
    const Image = await getFormImage(v?.Image, EMPTY_ARRAY_DATA);
    const payload: CreateArticle = {
      body: {
        ...v,
        Image,
        Text: articleContent,
      },
      operation: OperationsEndpoint.Create,
      reset: showArticleEditorModal,
    };
    dispatch(articlesRoutine(payload));
  };

  const updateArticle = async (v: CreateArticleFormValues) => {
    const Image = await getFormImage(v?.Image, removedSavedFiles);
    const payload: any = {
      body: {
        ...v,
        Image,
        Text: articleContent,
      },
      id: initialValues?.ID,
      operation: OperationsEndpoint.Update,
      reset: showArticleEditorModal,
    };
    dispatch(articlesRoutine(payload));
  };

  const onFinishArticle = async (v: CreateArticleFormValues) => {
    if (!articleContent) {
      notification.error({ message: "У статьи должно быть содержание" });
      return;
    }
    if (initialValues?.ID) {
      updateArticle(v);
    } else {
      createArticle(v);
    }
  };

  const formItems = useMemo(() => {
    const options = initialValues?.Tags || [];

    return {
      fields: ARTICLE_FORM_FIELDS,
      refData: {
        [FormFieldName.Tags]: { options },
      },
    };
  }, [initialValues?.Tags]);

  return {
    initialValues,
    articleContent,
    onFinishArticle,
    setContent,
    loading,
    formItems,
    onRemoveFile,
  };
};
