import { FC } from "react";
import { FormInstance } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSPopover } from "@/common/components/data-display/popover";
import { RefDataType } from "@/common/features/dictionaries/types";
import { DriversFiltersFormValues } from "@/common/features/drivers-common/types/drivers-filters";
import { usePopover } from "@/common/hooks/use-popover";
import { AdminDriversFilters } from "@/modules/admin-panel/children/admin-drivers/components/form/admin-drivers-filters";
import { DriverSort } from "@/modules/admin-panel/children/admin-drivers/containers/content/driver-sort";

interface IProps {
  form: FormInstance<DriversFiltersFormValues>;
  refData: RefDataType;
  resetFilters: VoidFunction;
  onValuesChange: (
    _: DriversFiltersFormValues,
    values: DriversFiltersFormValues
  ) => void;
}

export const AdminDriversToolbar: FC<IProps> = ({
  form,
  refData,
  onValuesChange,
  resetFilters,
}) => {
  const { open, handleOpenChange } = usePopover();

  return (
    <div className="admin-drivers__toolbar">
      <ILSPopover
        trigger="click"
        content={
          <AdminDriversFilters
            form={form}
            hide={false}
            refData={refData}
            onValuesChange={onValuesChange}
            resetFilters={resetFilters}
          />
        }
        open={open}
        onOpenChange={handleOpenChange}
      >
        <ILSButton
          className="filters-button"
          children="Фильтры"
          type="primary"
          icon={<FilterOutlined />}
        />
      </ILSPopover>
      <DriverSort />
    </div>
  );
};
