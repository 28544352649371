import { takeLatest } from "redux-saga/effects";
import { userNotificationHook } from "@/common/hooks/user-notification-hook";
import { ModuleName } from "@/common/types/core/module";
import { CONTACTS_NOTIFY_STATUSES } from "@/modules/social-services/children/contacts/actions/statues";
import { ContactsActionsForNotifications } from "@/modules/social-services/children/contacts/actions";

const worker = userNotificationHook({
  defaultStatuses: CONTACTS_NOTIFY_STATUSES,
  moduleKey: ModuleName.contacts,
});

export function* contactsNotifierWatcher() {
  // eslint-disable-next-line no-restricted-syntax
  for (const routine of ContactsActionsForNotifications) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
  }
}
