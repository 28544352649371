import { ILSDivider } from "@/common/components/layout/divider";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import {
  PRESIDENT_NAME,
  PRESIDENT_HEADER_TITLE,
  PRESIDENT_BIOGRAPHY,
} from "@/modules/social-services/children/contacts/constants";
import { ContactsPresidentPhoto } from "@/modules/social-services/children/contacts/components/president-photo";

export const ContactsPresidentBiography = () => {
  return (
    <div className="contacts__president-biography">
      <div className="contacts__president-biography-header">
        <div>
          <ILSTitle level={3} className="contacts__president-biography-name">
            {PRESIDENT_NAME}
          </ILSTitle>
          <ILSTitle
            level={5}
            className="contacts__president-biography-subtitle"
            ellipsis={false}
          >
            {PRESIDENT_HEADER_TITLE}
          </ILSTitle>
        </div>
        <ContactsPresidentPhoto />
      </div>
      <ILSDivider />

      <div className="contacts__president-biography-text">
        {PRESIDENT_BIOGRAPHY.map((paragraph) => (
          <div key={paragraph.title}>
            <ILSTitle ellipsis={false} level={4}>
              {paragraph.title}
            </ILSTitle>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: paragraph.text,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
