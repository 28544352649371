import { AdminApplicationsModule } from "@modules/admin-panel/children/admin-applications";
import { AdminArticlesModule } from "@modules/admin-panel/children/admin-articles";
import { AdminDriversModule } from "@modules/admin-panel/children/admin-drivers";
import { AdminEmployeeModule } from "@modules/admin-panel/children/admin-employee";
import { AdminOrganizationsModule } from "@modules/admin-panel/children/admin-organizations";
import { AdminVacanciesModule } from "@modules/admin-panel/children/admin-vacancies";

export const ADMIN_PANEL_SERVICES_MODULE_CHILDREN = {
  AdminVacanciesModule,
  AdminDriversModule,
  AdminOrganizationsModule,
  AdminApplicationsModule,
  AdminArticlesModule,
  AdminEmployeeModule,
};
