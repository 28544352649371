import "../index.scss";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { ProtectedPage } from "@pages/protected-page";
import { AbacPermission } from "@/common/types/core/abac";

interface IProps {}

export const SocialServices: FC<IProps> = () => {
  return (
    <ProtectedPage perform={AbacPermission.exceptAdmin}>
      {/* <SocialServicesMenu /> */}
      <section className="social-services">
        <Outlet />
      </section>
    </ProtectedPage>
  );
};
