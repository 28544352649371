import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "@/common/hooks/use-auth";
import { useDriversOperations } from "@/common/features/drivers-common/hooks/use-drivers-operations";
import { useDriverSearchFilters } from "@/modules/social-services/children/driver-search/hooks/use-driver-search-filters";
import {
  driversSelector,
  driversFetchingSelector,
} from "@/common/features/drivers-common/selectors";
import { driverFiltersSelector } from "@/common/features/dictionaries/selectors";

export const useDriverList = () => {
  const { authenticated } = useAuth();
  const loading = useSelector(driversFetchingSelector);
  const drivers = useSelector(driversSelector);
  const { refData } = useSelector(driverFiltersSelector);

  const { form, filters, onValuesChange, resetFilters } =
    useDriverSearchFilters();
  const { handleGetDrivers } = useDriversOperations();

  useEffect(() => {
    handleGetDrivers(filters);
  }, [filters]);

  const disableFilters = !authenticated;

  return {
    form,
    drivers,
    loading,
    refData,
    disableFilters,
    resetFilters,
    onValuesChange,
  };
};
