import { EMPTY_STRING, NOT_SET } from "@/common/constants/general";

type IProps = {
  surname?: string | null;
  name?: string | null;
  patronymic?: string | null;
};

export const getUserFullName = ({ surname, name, patronymic }: IProps) => {
  if (!name) return NOT_SET;
  const optionalSurname = surname ? `${surname} ` : EMPTY_STRING;
  const optionalPatronymic = patronymic ? ` ${patronymic}` : EMPTY_STRING;

  return `${optionalSurname}${name}${optionalPatronymic}`;
};
