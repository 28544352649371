import reduceReducers from "reduce-reducers";
import { MY_INFO_INITIAL_STATE } from "@modules/social-services/children/my-info/store";
import { getUsersReducer } from "@modules/social-services/children/my-info/reducers/get-users";
import { sendCodeReducer } from "@/modules/social-services/children/my-info/reducers/send-code";
import { checkCodeReducer } from "@/modules/social-services/children/my-info/reducers/check-code";

export const myInfoRootReducer = reduceReducers(
  MY_INFO_INITIAL_STATE,
  getUsersReducer,
  sendCodeReducer,
  checkCodeReducer
);
