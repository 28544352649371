import { FC } from "react";
import { Form, FormInstance } from "antd";
import {
  MY_INFO_FORM_NAMES,
  MY_INFO_USER_FORM_SUBTITLE,
} from "@/modules/social-services/children/my-info/constants/form";
import { ILSButton } from "@/common/components/data-display/buttons";
import {
  IILSFormAvatarProps,
  ILSFormAvatar,
} from "@/common/components/custom/form-avatar";
import { MyInfoFormAccount } from "@/modules/social-services/children/my-info/components/subforms/account";
import {
  AccountFormValues,
  MyInfoUserFormMode,
} from "@/modules/social-services/children/my-info/types/form";

type IProps = IILSFormAvatarProps & {
  form: FormInstance<AccountFormValues>;
  disabled: boolean;
  enableSubmit: VoidFunction;
  handleSubmit: (values: AccountFormValues) => void;
  handleCancel: VoidFunction;
};

export const MyInfoUsersForm: FC<IProps> = ({
  form,
  fileList,
  disabled,
  enableSubmit,
  handleSubmit,
  handleCancel,
  handleAvatarChange,
  handleAvatarRemove,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      className="my-info-form"
      name={MY_INFO_FORM_NAMES.NEW_USER}
      onFinish={handleSubmit}
      onFieldsChange={enableSubmit}
      disabled={disabled}
    >
      <div className="my-info-form__column">
        <ILSFormAvatar
          fileList={fileList}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
      </div>

      <div className="my-info-form__column my-info-form__column_wide">
        <MyInfoFormAccount
          title={MY_INFO_USER_FORM_SUBTITLE?.[MyInfoUserFormMode.Edit]}
        />
        <div className="my-info-form__buttons">
          <ILSButton onClick={handleCancel}>Отмена</ILSButton>
          <ILSButton type="primary" htmlType="submit">
            Сохранить
          </ILSButton>
        </div>
      </div>
    </Form>
  );
};
