import { notification } from "antd";
import { EMPTY_STRING } from "@/common/constants/general";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { useVacancyOperation } from "@/common/features/vacancies-common/hooks/use-vacancy-operation";

// TODO: добавить все операции с вакансиями
export const useVacancyOperations = () => {
  const { onVacancyOperation } = useVacancyOperation();

  /**
   * Поменять статус вакансии
   * @param vacancyId
   * @param status
   * @param comment
   */
  const handleChangeVacancyStatus = (
    vacancyId?: number,
    status?: number,
    comment?: string
  ) => {
    if (vacancyId && status) {
      onVacancyOperation({
        operation: OperationsEndpoint.SetStatus,
        body: { Status: status, Comment: comment || EMPTY_STRING },
        id: vacancyId,
      });
    } else {
      notification.error({ message: "Ошибка обновления статуса вакансии" });
    }
  };

  /**
   * Удалить вакансию
   * @param vacancyId
   * @param onSuccess
   */
  const handleDelete = (
    vacancyId: number | undefined,
    onSuccess?: VoidFunction
  ) =>
    onVacancyOperation({
      operation: OperationsEndpoint.Delete,
      id: vacancyId,
      onSuccess,
    });

  /**
   * Отправить отклик на вакансию
   * @param vacancyId
   * @param onSuccess
   */
  const handleApply = (
    vacancyId: number | undefined,
    onSuccess?: VoidFunction
  ) =>
    onVacancyOperation({
      operation: OperationsEndpoint.Apply,
      id: vacancyId,
      onSuccess,
    });

  /**
   * Отменить отклик на вакансию
   * @param applyId
   * @param onSuccess
   * @param applyId
   * @param onSuccess
   */
  const handleApplyCancel = (
    applyId: number | undefined,
    onSuccess?: VoidFunction
  ) =>
    onVacancyOperation({
      operation: OperationsEndpoint.Cancel,
      id: applyId,
      onSuccess,
    });

  /**
   * Получить все отклики пользователя (работает только у водителей)
   */
  const handleGetDriverApplyList = () =>
    onVacancyOperation({
      operation: OperationsEndpoint.ApplyList,
    });

  /**
   * Получить отклики по id вакансии для организации
   * @param vacancyId
   */
  const handleGetVacancyApplyList = (vacancyId: number | undefined) =>
    onVacancyOperation({
      operation: OperationsEndpoint.ApplyList,
      id: vacancyId,
    });

  /**
   * организация приглашает водителя
   * @param applyId
   * @param vacancyIdForApply
   * @param onSuccess
   */
  const handleApplyConfirm = (
    applyId: number | undefined,
    vacancyIdForApply: number | undefined,
    onSuccess?: VoidFunction
  ) =>
    onVacancyOperation({
      operation: OperationsEndpoint.Confirm,
      id: applyId,
      vacancyIdForApply,
      onSuccess,
    });

  /**
   * организация отказала водителю
   * @param applyId
   * @param vacancyIdForApply
   * @param onSuccess
   */
  const handleApplyRefuse = (
    applyId: number | undefined,
    vacancyIdForApply: number | undefined,
    onSuccess?: VoidFunction
  ) =>
    onVacancyOperation({
      operation: OperationsEndpoint.Refuse,
      id: applyId,
      vacancyIdForApply,
      onSuccess,
    });

  return {
    handleChangeVacancyStatus,
    handleDelete,
    handleApply,
    handleApplyCancel,
    handleGetDriverApplyList,
    handleGetVacancyApplyList,
    handleApplyConfirm,
    handleApplyRefuse,
  };
};
