import { AdminApplications } from "@modules/admin-panel/children/admin-applications/containers";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";

const module: IModuleModel = {
  title: ModuleTitle.adminApplications,
  name: ModuleName.adminApplications,
  path: ModulePath.adminApplications,
  element: <AdminApplications />,
  persist: false,
};

export const AdminApplicationsModule = defineModule(module);
