import { FC } from "react";
import { Form, FormInstance } from "antd";
import { ILSButton } from "@/common/components/data-display/buttons";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import {
  IILSFormAvatarProps,
  ILSFormAvatar,
} from "@/common/components/custom/form-avatar";
import { AdminOrganizationFormValues } from "@/modules/admin-panel/children/admin-organizations/children/organization/types/organization-form";
// eslint-disable-next-line max-len
import { AdminOrganizationFormFields } from "@/modules/admin-panel/children/admin-organizations/children/organization/components/forms/organization-form-fields";

type IProps = IILSFormAvatarProps & {
  form: FormInstance<AdminOrganizationFormValues>;
  initialValues: AdminOrganizationFormValues | null;
  handleSubmit: (values: AdminOrganizationFormValues) => void;
  handleCancelChange: VoidFunction;
};

export const AdminOrganizationForm: FC<IProps> = ({
  form,
  initialValues,
  fileList,
  handleSubmit,
  handleCancelChange,
  handleAvatarChange,
  handleAvatarRemove,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      className="admin-organization-form"
      initialValues={initialValues || EMPTY_OBJECT_DATA}
      onFinish={handleSubmit}
    >
      <div className="admin-organization-form__column">
        <ILSFormAvatar
          fileList={fileList}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
      </div>
      <div className="admin-organization-form__column admin-organization-form__column_wide">
        <AdminOrganizationFormFields initialValues={initialValues} />
        <div className="admin-organization-form__buttons">
          <ILSButton onClick={handleCancelChange}>Отмена</ILSButton>
          <ILSButton type="primary" htmlType="submit">
            Сохранить
          </ILSButton>
        </div>
      </div>
    </Form>
  );
};
