import { isArray, isEmpty } from "lodash";
import { EMPTY_ARRAY_DATA, EMPTY_STRING } from "@/common/constants/general";
import { DEFAULT_DRIVING_EXPERIENCE_FIELDS } from "@/common/features/drivers-common/constants/driver-form";
import { DriverDrivingExperience } from "@/common/features/drivers-common/types/driver-form";
import { DriverWorkExperience } from "@/common/types/models/user/driver";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { getFormArrayIDValues } from "@/common/features/drivers-common/helpers/get-form-array-id-values";

export const drivingExperienceToFormValues = (
  userDrivingExperience?: DriverWorkExperience[]
): DriverDrivingExperience[] => {
  if (!userDrivingExperience || isEmpty(userDrivingExperience)) {
    return DEFAULT_DRIVING_EXPERIENCE_FIELDS;
  }

  return userDrivingExperience?.map((experienceItem) => {
    return {
      [FormFieldName.VehicleType]: isArray(experienceItem?.VehicleType)
        ? getFormArrayIDValues(experienceItem?.VehicleType)
        : EMPTY_ARRAY_DATA,

      [FormFieldName.ExperienceYears]: !Number.isNaN(
        experienceItem?.ExperienceYears
      )
        ? Number(experienceItem?.ExperienceYears)
        : null,

      [FormFieldName.Organization]:
        experienceItem?.Organization || EMPTY_STRING,
    };
  });
};
