import { FC } from "react";
import {
  RATING_TITLES,
  APPLICATION_MODERATION_STATUS_TITLES,
  NOT_SENT,
} from "@/common/constants/config/rating";
import {
  Rating,
  RatingContentType,
  isRatingWithForm,
} from "@/common/types/models/rating";

export type IProps = {
  ratingType: RatingContentType;
  ratingValues: Rating | undefined;
};

export const MyRatingTotalListItem: FC<IProps> = ({
  ratingType,
  ratingValues,
}) => {
  if (!isRatingWithForm(ratingType) || !ratingValues) {
    return null;
  }
  const moderationStatus = ratingValues.status
    ? APPLICATION_MODERATION_STATUS_TITLES[ratingValues.status]
    : NOT_SENT;

  return (
    <div className="rating-list__item">
      <p>{RATING_TITLES[ratingType]}</p>
      <p>{moderationStatus}</p>
    </div>
  );
};
