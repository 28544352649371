import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const SURVEY_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.ResidenceRegion,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.WorkingPosition,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.SalaryLevel,
    },
    Component: ILSInput,
  },
] as FormItemsFields;
