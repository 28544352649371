import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

type IProps = {
  form: FormInstance;
};

export const useShowEmployeeFields = ({ form }: IProps) => {
  const isEmployee = useWatch(FormFieldName.IsEmployee, form);

  return { showEmployeeFields: isEmployee };
};
