import { useNavigate } from "react-router-dom";
import { getPhoneHref } from "@/common/utils/helpers/string/get-phone-href";
import { useAuth } from "@/common/hooks/use-auth";
import { useModal } from "@/common/hooks/use-modal";
import { ModulePath } from "@/common/types/core/routes";
import { renderPhoneButtonContent } from "@/common/helpers/render-phone-button-content";

export const usePhoneButton = (phone: string | undefined | null) => {
  const navigate = useNavigate();
  const { authenticated } = useAuth();

  const buttonContent = renderPhoneButtonContent(phone, authenticated);
  const phoneHref = authenticated ? getPhoneHref(phone) : undefined;

  const { isModalOpen, showModal } = useModal();

  const onButtonClick = () => {
    if (!authenticated) {
      showModal();
    }
  };

  const onOkModalClick = () => {
    navigate(`/${ModulePath.auth}/${ModulePath.login}`);
  };

  return {
    buttonContent,
    phoneHref,
    isModalOpen,
    showModal,
    onOkModalClick,
    onButtonClick,
  };
};
