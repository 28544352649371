import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { QUESTIONNAIRE_STORE_INITIAL_STATE } from "@/common/features/questionnaire-common/store";
import {
  QuestionnaireOperation,
  QuestionnaireResultType,
} from "@/common/api/types/questionnaire";
import { questionnaireResultsRoutine } from "@/common/features/questionnaire-common/actions";
import { IndexedCollection } from "@/common/types/general/common";
import { compareAsString } from "@/common/helpers/compare-as-string";

export const questionnaireResultsReducer = createReducer(
  QUESTIONNAIRE_STORE_INITIAL_STATE,
  {
    [questionnaireResultsRoutine.REQUEST]: onRequest,
    [questionnaireResultsRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{
        operation: QuestionnaireOperation;
        data: IndexedCollection<QuestionnaireResultType>;
        resultID: number;
      }>
    ) => {
      const { operation, data, resultID } = action.payload;

      switch (operation) {
        case QuestionnaireOperation.getResults:
        case QuestionnaireOperation.saveResult:
          state.results = Object.values(data);
          break;

        case QuestionnaireOperation.deleteResult:
          state.results = state.results?.filter(
            (result) => !compareAsString(result?.ID, resultID)
          );
          break;

        default:
          break;
      }
      return state;
    },
    [questionnaireResultsRoutine.FAILURE]: onFailure,
    [questionnaireResultsRoutine.FULFILL]: onFulfill,
  }
);
