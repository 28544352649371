import { takeLatest } from "redux-saga/effects";
import { Notify } from "@/common/types/notifications/enum";
import { userNotificationHook } from "@/common/hooks/user-notification-hook";
import {
  VacanciesActions,
  VacanciesActionsForNotifications,
} from "@/common/features/vacancies-common/actions";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { EMPTY_STRING } from "@/common/constants/general";

export type VacanciesStatusesType = {
  [key in VacanciesActions]: {
    loading: Record<string, string> | string;
    success: Record<string, string> | string;
    successPlural?: string;
    error?: string;
  };
};

export const VACANCIES_STATUSES: VacanciesStatusesType = {
  [VacanciesActions.DICTIONARY_OPERATION]: {
    [Notify.Loading]: {
      [OperationsEndpoint.GetAll]: EMPTY_STRING,
      [OperationsEndpoint.Create]: "Вакансия создается",
      [OperationsEndpoint.Delete]: "Вакансия удаляется",
      [OperationsEndpoint.Update]: "Данные сохраняются",
      [OperationsEndpoint.SetStatus]: "Новый статус вакансии сохраняется",
      [OperationsEndpoint.ApplyList]: EMPTY_STRING,
      [OperationsEndpoint.Apply]: "Идёт сохранение отклика",
      [OperationsEndpoint.Cancel]: "Отмена отклика сохраняется",
      [OperationsEndpoint.Refuse]: "Отказ сохраняется",
      [OperationsEndpoint.Confirm]: "Приглашение сохраняется",
    },
    [Notify.Success]: {
      [OperationsEndpoint.GetAll]: EMPTY_STRING,
      [OperationsEndpoint.Create]: "Вакансия успешно создана",
      [OperationsEndpoint.Delete]: "Вакансия успешно удалена",
      [OperationsEndpoint.Update]: "Вакансия успешно сохранена",
      [OperationsEndpoint.SetStatus]: "Статус вакансии успешно изменен",
      [OperationsEndpoint.ApplyList]: EMPTY_STRING,
      [OperationsEndpoint.Apply]: "Отклик успешно создан",
      [OperationsEndpoint.Cancel]: "Отклик успешно отменен",
      [OperationsEndpoint.Refuse]: "Отказ успешно сохранен",
      [OperationsEndpoint.Confirm]: "Приглашение успешно сохранено",
    },
  },
  [VacanciesActions.CHANGE_FILTERS]: {
    loading: "Статус вакансии меняется",
    success: "Статус успешно изменен",
  },
};

const worker = userNotificationHook({
  defaultStatuses: VACANCIES_STATUSES,
  moduleKey: "VACANCIES",
});

export function* vacanciesNotifierWatcher() {
  // eslint-disable-next-line no-restricted-syntax
  for (const routine of VacanciesActionsForNotifications) {
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.SUCCESS, worker);
  }
}
