import { FC, ReactNode } from "react";
import { AllowedTo } from "react-abac";
import { ForbiddenPage } from "./forbidden-page";
import { AbacPermission } from "@/common/types/core/abac";

interface IProps {
  perform: AbacPermission | AbacPermission[];
  children: ReactNode;
}

export const ProtectedPage: FC<IProps> = ({ perform, children }) => {
  return (
    <AllowedTo<AbacPermission> perform={perform as never} no={ForbiddenPage}>
      {children}
    </AllowedTo>
  );
};
