import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSMembershipBadge } from "@/common/components/custom/membership-badge";
import {
  MEDICAL_CERTIFICATE_OPTIONS,
  SEX_OPTIONS,
} from "@/common/constants/config/options";
import { PUBLIC_ORGANIZATION_TITLE_FULL } from "@/common/features/drivers-common/constants";
import { Driver } from "@/common/types/models/user/driver";
import { FormatType } from "@/common/types/general/date-time";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { timestampToDateTimeString } from "@/common/utils/helpers/date-time/timestamp-to-date-time-string";
import { getUserFullName } from "@/common/utils/helpers/string/get-driver-full-name";
import { getOptionName } from "@/common/utils/helpers/components/select/get-option-name";
import { getDictionaryArrayName } from "@/common/helpers/get-dictionary-array-name";
import { checkDriverMembership } from "@/common/features/drivers-common/helpers/check-driver-membership";

type IProps = {
  driver: Driver | undefined | null;
};

export const DriverDetailsMainInfo: FC<IProps> = ({ driver }) => {
  const driverFullName = getUserFullName({
    surname: driver?.Surname,
    name: driver?.Name,
    patronymic: driver?.Patronymic,
  });
  const hasConfirmedMembership = checkDriverMembership(driver);

  return (
    <div>
      <div className="driver-details__header">
        <ILSTitle className="driver-details__title" ellipsis={{ rows: 2 }}>
          {driverFullName}
          {hasConfirmedMembership && (
            <ILSMembershipBadge className="driver-details__membership-badge" />
          )}
        </ILSTitle>
        {hasConfirmedMembership && (
          <ILSTitle level={4} className="driver-details__membership-title">
            {PUBLIC_ORGANIZATION_TITLE_FULL}
          </ILSTitle>
        )}
      </div>

      <section className="driver-details__section">
        <div className="driver-details__section-column">
          <ILSTitle level={2} className="driver-details__section-title">
            Основная информация о водителе
          </ILSTitle>

          <div className="driver-details__section-content">
            <DetailDescription
              caption="Пол"
              value={getOptionName(SEX_OPTIONS, driver?.Sex)}
            />
            <DetailDescription
              caption="Дата рождения"
              value={timestampToDateTimeString(
                driver?.Birthday,
                FormatType.Date
              )}
            />
            <DetailDescription caption="Гражданство" value={driver?.Citizens} />
            <DetailDescription
              caption="Города поиска работы"
              value={getDictionaryArrayName(driver?.JobSearchRegion)}
            />
            <DetailDescription
              caption="Тип занятости"
              value={driver?.EmploymentType?.Name}
            />
            <DetailDescription
              caption="График работы"
              value={getDictionaryArrayName(driver?.WorkSchedule)}
            />
            <DetailDescription
              caption="Образование"
              value={driver?.EducationLevel?.Name}
            />
            <DetailDescription
              caption="Свой транспорт"
              value={getDictionaryArrayName(driver?.OwnVehicleType)}
            />
            <DetailDescription
              caption="Мед. книжка"
              value={getOptionName(
                MEDICAL_CERTIFICATE_OPTIONS,
                driver?.MedicalCertificate
              )}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
