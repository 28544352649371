import { JoinChar } from "@/common/constants/general";
import { FileUploadExtensions } from "@/common/constants/files/extensions";

export const TWO_TENTH_POWER = 1024;

export const AVATAR_MAX_SIZE_TEN_MB = 10485760;

export const PHOTO_PERMITTED_EXTENSIONS = [
  FileUploadExtensions.png,
  FileUploadExtensions.jpeg,
  FileUploadExtensions.webp,
].join(JoinChar.Comma);

export const DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE = 20971520;

export const EXCEL_PERMITTED_EXTENSIONS = [
  FileUploadExtensions.xls,
  FileUploadExtensions.xlsx,
].join(JoinChar.Comma);
