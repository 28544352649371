import { FC } from "react";
import { isEmpty } from "lodash";
import { IWideCardProfileProps } from "@/common/ui-components/data-display/wide-card/profile";
import { ILSRating } from "@/common/ui-components/data-display/rating";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSImage } from "@/common/components/data-display/image";

interface IProps {
  ratings?: Array<number> | undefined;
  profile?: IWideCardProfileProps;
}

/**
 * Компонент с названием организации и рейтингом для мобильной версии.
 */
export const WideCardProfileRating: FC<IProps> = ({ ratings, profile }) => {
  if (isEmpty(profile)) {
    return null;
  }

  return (
    <div className="ils-card__profile-rating">
      <div className="ils-card__profile-rating-row">
        <ILSRating
          rating={ratings?.[0]}
          className="ils-card__profile-rating-value"
        />
        <ILSTitle level={5} ellipsis={{ rows: 2 }}>
          {profile?.title}
        </ILSTitle>
      </div>
      {profile?.img && (
        <ILSImage
          alt={profile?.title}
          src={profile?.img}
          className="ils-card__profile-img ils-card__profile-img_small"
        />
      )}
    </div>
  );
};
