import { Fragment } from "react";

/** Функция для обработки content с использованием &nbsp; */
export const renderContentWithNbsp = (content: string) => {
  const parts = content.split("&nbsp;");
  return parts.map((part, index, arr) => (
    <Fragment key={part}>
      {part}
      {index < arr.length - 1 && "\u00A0"}
    </Fragment>
  ));
};
