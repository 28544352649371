import { FC } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  IILSButtonProps,
  ILSButton,
} from "@/common/components/data-display/buttons";

interface IProps extends IILSButtonProps {
  className?: string;
}

export const NavigateBackButton: FC<IProps> = ({ className }) => {
  const navigate = useNavigate();
  const onClickGoBack = () => navigate(-1);

  return (
    <ILSButton
      shape="circle"
      icon={<ArrowLeftOutlined />}
      onClick={onClickGoBack}
      type="primary"
      className={classNames(className)}
    />
  );
};
