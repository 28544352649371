import { Flex } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { ILSButton } from "@/common/components/data-display/buttons";
import { useStartPageVacancies } from "@/modules/start/hooks/use-start-page-vacancies";

export const StartPageVacancies = () => {
  const { vacanciesPath } = useStartPageVacancies();

  return (
    <Flex align="center" className="start-page__vacancies">
      <ILSLink
        to={vacanciesPath}
        relative="path"
        className="start-page__vacancies-button"
      >
        <ILSButton size="large" type="primary">
          Перейти к вакансиям
          <ArrowRightOutlined />
        </ILSButton>
      </ILSLink>
    </Flex>
  );
};
