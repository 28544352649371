import { recoveryProcessActions } from "@modules/auth/actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { RecoveryProcess } from "@/common/api/types/auth";

export type RecoveryProcessAction = PayloadAction<{
  recoveryProcess: RecoveryProcess | null;
}>;

export function* recoveryProcessWorker(action: RecoveryProcessAction) {
  const { failure, fulfill, success } = recoveryProcessActions;

  try {
    yield put(success(action.payload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* recoveryProcessWatcher() {
  yield takeLatest(recoveryProcessActions.trigger, recoveryProcessWorker);
}
