import { FC } from "react";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { ILSDivider } from "@/common/components/layout/divider";
import { IILSButtonConfirmTooltipProps } from "@/common/components/data-display/buttons/confirm-tooltip";
import { VacancyOwner } from "@/common/features/vacancy/components/owner";
import { VacancyPhone } from "@/common/features/vacancy/components/phone";
import { VacancyPrices } from "@/common/features/vacancy/components/prices";
import { VacancyActionButtons } from "@/common/features/vacancy/components/action-buttons";
import { VacancySidebarStatus } from "@/common/features/vacancy/components/status";
import { VacancyFormModal } from "@/common/features/vacancies-common/components/form/modal";
import { VacancyFormType } from "@/common/features/vacancies-common/types/form";
import { VacancyCommentModal } from "@/common/features/vacancy/components/comment/modal";
import { VacancyCommentAlert } from "@/common/features/vacancy/components/comment/alert";
import { VacancyStatus } from "@/common/types/models/vacancies/status";
import { DriverAlertApplyModal } from "@/common/features/vacancies-common/components/driver-apply-alert-modal/driver-apply-alert-modal";

type IProps = {
  vacancy: Vacancy;
  adminActionButtons: IILSButtonConfirmTooltipProps[];
  userActionButtons: IILSButtonConfirmTooltipProps[];
  driverActionButtons: IILSButtonConfirmTooltipProps[];
  isVacancyFormModalOpen: boolean;
  setVacancyFormModalOpen: VoidFunction;
  isCommentModalOpen: boolean;
  setCommentModalOpen: VoidFunction;
  isDriverAlertApplyModalOpen: boolean;
  showDriverAlertApplyModal: VoidFunction;
};

export const VacancySidebar: FC<IProps> = ({
  vacancy,
  adminActionButtons,
  userActionButtons,
  driverActionButtons,
  isVacancyFormModalOpen,
  setVacancyFormModalOpen,
  isCommentModalOpen,
  setCommentModalOpen,
  isDriverAlertApplyModalOpen,
  showDriverAlertApplyModal,
}) => {
  return (
    <div className="vacancy__sidebar">
      <ILSAllowedTo
        perform={AbacPermission.hasActionsWithVacancies}
        data={vacancy}
      >
        <VacancyCommentAlert comment={vacancy?.Comment} />
        <VacancySidebarStatus
          status={vacancy?.VacancyStatus?.Alias as VacancyStatus}
        />
        <ILSDivider />
      </ILSAllowedTo>

      <VacancyPrices price={vacancy.Payment?.Price} />
      <ILSDivider />
      <VacancyPhone phone={vacancy.Info?.Phone} />
      <ILSDivider />
      <VacancyOwner
        owner={vacancy?.Owner}
        publishedVacancies={vacancy?.PublishedVacancies}
      />

      <ILSAllowedTo
        perform={AbacPermission.hasActionsWithVacancies}
        data={vacancy}
      >
        <VacancyActionButtons actionButtons={userActionButtons} />
        <VacancyFormModal
          type={VacancyFormType.Edit}
          vacancyId={vacancy?.ID}
          isModalOpen={isVacancyFormModalOpen}
          showModal={setVacancyFormModalOpen}
        />
      </ILSAllowedTo>

      <ILSAllowedTo perform={AbacPermission.adminPanel}>
        <VacancyActionButtons actionButtons={adminActionButtons} />
        <VacancyCommentModal
          vacancyId={vacancy?.ID}
          isModalOpen={isCommentModalOpen}
          showModal={setCommentModalOpen}
        />
      </ILSAllowedTo>

      <ILSAllowedTo perform={AbacPermission.driver}>
        <VacancyActionButtons actionButtons={driverActionButtons} />
        <DriverAlertApplyModal
          isModalOpen={isDriverAlertApplyModalOpen}
          showModal={showDriverAlertApplyModal}
        />
      </ILSAllowedTo>
    </div>
  );
};
