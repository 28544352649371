import { articleCurrentResetRoutine } from "@modules/admin-panel/children/admin-articles/actions";
import { useDispatch } from "react-redux";
import { emptyFunction } from "@/common/helpers/empty-function";

export const useCurrentArticleReset = (
  onReset: VoidFunction = emptyFunction
) => {
  const dispatch = useDispatch();

  const resetArticle = () => {
    onReset();
    dispatch(articleCurrentResetRoutine.success());
  };

  return { resetArticle };
};
