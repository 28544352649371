import { FC } from "react";
import { WideCardCover } from "@/common/ui-components/data-display/wide-card/cover";
import { WideCardAvatar } from "@/common/ui-components/data-display/wide-card/avatar";
import {
  IILSChipsProps,
  ILSChips,
} from "@/common/ui-components/data-display/chips";

interface IProps {
  cover: string | null | undefined;
  avatar: string | null | undefined;
  statuses:
    | Array<IILSChipsProps & { text: string; hide?: boolean }>
    | undefined;
}

export const WideCardImageStatuses: FC<IProps> = ({
  cover,
  avatar,
  statuses = [],
}) => {
  return (
    <div className="ils-card__cover">
      <WideCardCover cover={cover} />
      <WideCardAvatar avatar={avatar} />
      {statuses?.map(({ text, hide = false, ...props }, i) => {
        if (hide) return null;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ILSChips {...props} key={i}>
            {text}
          </ILSChips>
        );
      })}
    </div>
  );
};
