import { createReducer } from "@reduxjs/toolkit";
import { AUTH_INITIAL_STATE } from "@modules/auth/store";
import { logoutActions } from "@modules/auth/actions";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";

export const logoutReducer = createReducer(AUTH_INITIAL_STATE, {
  [logoutActions.REQUEST]: onRequest,
  [logoutActions.FAILURE]: onFailure,
  [logoutActions.FULFILL]: onFulfill,
});
