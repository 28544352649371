import { useStartPageContests } from "@/modules/start/hooks/use-contests";
import { StartPageContentsList } from "@/modules/start/components/contests/list";
import { StartPageContestModal } from "@/modules/start/components/contests/modal";
import { StartPageHeader } from "@/modules/start/components/header/start-page-header";

export const StartPageContests = () => {
  const { selectedContest, isModalOpen, showModal, onParticipateClick } =
    useStartPageContests();

  return (
    <div className="start-page__contests">
      <StartPageHeader className="start-page__contests-header">
        Конкурсы
      </StartPageHeader>
      <StartPageContentsList onParticipateClick={onParticipateClick} />
      <StartPageContestModal
        selectedContest={selectedContest}
        isModalOpen={isModalOpen}
        showModal={showModal}
      />
    </div>
  );
};
