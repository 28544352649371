import { getObjectKeysWithType } from "@/common/utils/helpers/types";
import {
  LogisticServicesType,
  LogisticServicesGroups,
} from "@/modules/social-services/children/resources/types/logistic-services";

export const LOGISTIC_SERVICE_FAST_DELIVERY_TITLE = "Быстрая доставка грузов";

export const SAAS_TMS_URL = "https://saas-tms.intelogis.ru/";
export const DL_URL_CRM = "https://intelogis-crm.bubbleapps.io/";
export const DL_URL_REGISTER_CARRIER_SIMPLE =
  "https://dl.intelogis.ru/register?alias=carrier_simple";

export const CargoOwnersServicesData: LogisticServicesType = [
  {
    title: LOGISTIC_SERVICE_FAST_DELIVERY_TITLE,
    icon: "/assets/icons/fast-delivery.svg",
    link: SAAS_TMS_URL,
  },
  {
    title: "Автоматическое планирование маршрутов",
    icon: "/assets/icons/route.svg",
    link: SAAS_TMS_URL,
  },
  {
    title: "Тендерная площадка",
    icon: "/assets/icons/tender.svg",
    link: SAAS_TMS_URL,
  },
  {
    title: "Транспортный мониторинг",
    icon: "/assets/icons/monitoring.svg",
    link: SAAS_TMS_URL,
  },
  {
    title: "Мобильное приложение для водителей",
    icon: "/assets/icons/phone-outlined.svg",
    link: SAAS_TMS_URL,
  },
];
export const TransportCompaniesServicesData: LogisticServicesType = [
  {
    title: "СRM для транспортных компаний",
    icon: "/assets/icons/crm.svg",
    link: DL_URL_CRM,
  },
  {
    title: "Мобильное приложение для водителей",
    icon: "/assets/icons/phone-outlined.svg",
    link: SAAS_TMS_URL,
  },
  {
    title: "Поиск водителей",
    icon: "/assets/icons/car.svg",
    link: SAAS_TMS_URL,
  },
  {
    title: "Транспортный мониторинг",
    icon: "/assets/icons/monitoring.svg",
    link: SAAS_TMS_URL,
  },
  {
    title: "Чат-бот для подбора возвраток по всей России",
    icon: "/assets/icons/telegram.svg",
    link: "",
  },
  {
    title: "Тендерная площадка",
    icon: "/assets/icons/tender.svg",
    link: SAAS_TMS_URL,
  },
];
export const ExpeditorCompaniesServicesData: LogisticServicesType = [
  {
    title: "СRM для экспедиторских компаний",
    icon: "/assets/icons/crm.svg",
    link: DL_URL_CRM,
  },
  {
    title: "Поиск и проверка перевозчиков",
    icon: "/assets/icons/carrier-rating.svg",
    link: DL_URL_CRM,
  },
  {
    title: "Чат-бот для подбора возвраток по всей России",
    icon: "/assets/icons/telegram.svg",
    link: "",
  },
  {
    title: "Мобильное приложение для водителей",
    icon: "/assets/icons/phone-outlined.svg",
    link: DL_URL_CRM,
  },
];
export const DriversOwnVehiclesServicesData: LogisticServicesType = [
  {
    title: "Поиск грузов",
    icon: "/assets/icons/add-order.svg",
    link: DL_URL_REGISTER_CARRIER_SIMPLE,
  },
  {
    title: "Чат-бот для подбора возвраток по всей России",
    icon: "/assets/icons/telegram.svg",
    link: "",
  },
];
export const DriversNoVehiclesServicesData: LogisticServicesType = [
  {
    title: "Поиск вакансий",
    icon: "/assets/icons/account.svg",
    link: "",
  },
];

export const logisticServicesTitles: Record<
  LogisticServicesGroups,
  { title: string; subtitle?: string }
> = {
  [LogisticServicesGroups.CargoOwners]: {
    title: "Для грузовладельцев",
  },
  [LogisticServicesGroups.TransportCompanies]: {
    title: "Для транспортных компаний",
  },
  [LogisticServicesGroups.ExpeditorCompanies]: {
    title: "Для экспедиторских компаний",
  },
  [LogisticServicesGroups.DriversOwnVehicles]: {
    title: "Для водителей",
    subtitle: "(с собственным транспортом)",
  },
  [LogisticServicesGroups.DriversNoVehicles]: {
    title: "Для водителей",
    subtitle: "(без транспорта)",
  },
};

export const logisticServicesContent: Record<
  LogisticServicesGroups,
  LogisticServicesType
> = {
  [LogisticServicesGroups.CargoOwners]: CargoOwnersServicesData,
  [LogisticServicesGroups.TransportCompanies]: TransportCompaniesServicesData,
  [LogisticServicesGroups.ExpeditorCompanies]: ExpeditorCompaniesServicesData,
  [LogisticServicesGroups.DriversOwnVehicles]: DriversOwnVehiclesServicesData,
  [LogisticServicesGroups.DriversNoVehicles]: DriversNoVehiclesServicesData,
};

/** список групп сервисов */
export const logisticServicesGroupList =
  getObjectKeysWithType<LogisticServicesGroups>(LogisticServicesGroups);
