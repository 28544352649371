import { call, put, takeEvery } from "redux-saga/effects";
import { QUESTIONNAIRE_API } from "@/common/api/endpoints/questionnaire";
import { questionnaireActionRoutine } from "@/common/features/questionnaire-common/actions";
import { QuestionnairePayload } from "@/common/features/questionnaire-common/types/store";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";

export function* QuestionnaireWorker(action: QuestionnairePayload) {
  const { request, success, failure, fulfill } = questionnaireActionRoutine;
  const { operation, body, successMessage } = action.payload;

  try {
    yield put(request());
    const { data } = yield call(QUESTIONNAIRE_API.questionnaireOperation, {
      operation,
      body,
    });

    yield put(success({ operation, data }));
    if (successMessage) {
      yield call(showSuccessNotification, successMessage);
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* questionnaireWatcher() {
  yield takeEvery(questionnaireActionRoutine.trigger, QuestionnaireWorker);
}
