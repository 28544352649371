import { FC } from "react";
import { ILSPhoneButton } from "@/common/components/custom/phone-button/phone-button";

type IProps = {
  phone?: string | null;
};

export const DriverDetailsPhone: FC<IProps> = ({ phone }) => {
  return <ILSPhoneButton phone={phone} className="driver-details__phone" />;
};
