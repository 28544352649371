import { FC } from "react";
import { Form } from "antd";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSDivider } from "@/common/components/layout/divider";
import { VERIFICATION_FORM_FIELDS } from "@/modules/social-services/children/my-info/constants/form-fields/verification";
import {
  VERIFICATION_FORM_TITLE,
  VERIFICATION_SUBMIT_BUTTON,
} from "@/modules/social-services/children/my-info/constants";
import {
  VerificationFormType,
  VerificationFormValues,
} from "@/modules/social-services/children/my-info/types/verification";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  formType: VerificationFormType;
  initialValues: VerificationFormValues | {};
  handleSubmit: (formValues: VerificationFormValues) => void;
};

export const MyInfoVerificationForm: FC<IProps> = ({
  formType,
  initialValues,
  handleSubmit,
}) => {
  return (
    <Form
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
      className="my-info__verification-form"
    >
      <p className="my-info__verification-form-subtitle">
        {VERIFICATION_FORM_TITLE[formType]}
      </p>
      <ILSDivider className="my-info__verification-form-divider" />
      {renderFormItems({ fields: VERIFICATION_FORM_FIELDS[formType] })}
      <ILSButton
        block
        type="primary"
        htmlType="submit"
        className="my-info__verification-form-btn"
      >
        {VERIFICATION_SUBMIT_BUTTON[formType]}
      </ILSButton>
    </Form>
  );
};
