import { call, put, takeLatest } from "redux-saga/effects";
import { sendQuestionRoutine } from "@/modules/social-services/children/contacts/actions";
import { ContactsSendQuestionPayload } from "@/common/api/types/send-question";
import { SEND_QUESTION_API } from "@/common/api/endpoints/send-question";

export function* SendQuestionWorker(action: ContactsSendQuestionPayload) {
  const { request, success, failure, fulfill } = sendQuestionRoutine;
  const { body, type } = action.payload;

  try {
    yield put(request());

    yield call(SEND_QUESTION_API.sendQuestionRequest, {
      body,
      type,
    });

    yield put(success());
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* sendQuestionWatcher() {
  yield takeLatest(sendQuestionRoutine.trigger, SendQuestionWorker);
}
