import { createReducer } from "@reduxjs/toolkit";
import { loginActions } from "@modules/auth/actions";
import { AUTH_INITIAL_STATE } from "@modules/auth/store";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";

export const loginReducer = createReducer(AUTH_INITIAL_STATE, {
  [loginActions.REQUEST]: onRequest,
  [loginActions.FAILURE]: onFailure,
  [loginActions.FULFILL]: onFulfill,
});
