import { PHONE_MASK } from "@/common/constants/config/masks";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSInputMask } from "@/common/components/data-entry/masked-input";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { VerificationFormType } from "@/modules/social-services/children/my-info/types/verification";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const VERIFICATION_PHONE_FIELD = [
  {
    fieldProps: {
      field: FormFieldName.Phone,
      help: "На указанный номер будет отправлено СМС с кодом подтверждения",
    },
    inputProps: {
      mask: PHONE_MASK,
      disabled: true,
      size: "large",
    },
    Component: ILSInputMask,
  },
] as FormItemsFields;

export const VERIFICATION_CODE_FIELD = [
  {
    fieldProps: {
      field: FormFieldName.VerificationCode,
      required: true,
    },
    inputProps: {
      size: "large",
    },
    Component: ILSInput,
  },
] as FormItemsFields;

export const VERIFICATION_FORM_FIELDS = {
  [VerificationFormType.getCode]: VERIFICATION_PHONE_FIELD,
  [VerificationFormType.checkCode]: VERIFICATION_CODE_FIELD,
};
