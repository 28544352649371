import { MY_RATING_MOCKS } from "@/common/constants/config/rating";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { useOrganizationAccount } from "@/common/hooks/use-organization-account";

export const useRatingBlocks = () => {
  const { organizationSocialRating } =
    useOrganizationAccount(EMPTY_OBJECT_DATA);
  const totalRating = organizationSocialRating;

  const ratingValues = MY_RATING_MOCKS;

  return { totalRating, ratingValues };
};
