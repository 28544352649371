import { UserCardActions } from "@/common/types/models/user/card-actions";
import { useAdminSelectUser } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/users/select-user";
import { useAdminGetUsers } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/users/get-users";
import { useAdminUsersActions } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/users/users-actions";
import { useAdminUserForm } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/users/edit-user-form";

export const useAdminOrganizationUsers = (
  organizationID: number | undefined
) => {
  /** список сотрудников */
  const { users, isFetching } = useAdminGetUsers(organizationID);

  /** выбранный сотрудник для редактирования / удаления / блокировки */
  const { selectedUser, handleSelectUser } = useAdminSelectUser();

  /** действия в карточке */
  const { userCardActions, actionType, modalProps, resetActionType } =
    useAdminUsersActions({ selectedUser, handleSelectUser });

  const handleResetActionAndUser = () => {
    handleSelectUser(null);
    resetActionType();
  };

  /** форма редактирования сотрудника */
  const {
    form,
    fileList,
    handleSubmit,
    handleCancel,
    handleAvatarChange,
    handleAvatarRemove,
  } = useAdminUserForm({ user: selectedUser, handleResetActionAndUser });

  const isEditForm = actionType === UserCardActions.Edit;

  return {
    users,
    isFetching,
    userCardActions,
    modalProps,
    isEditForm,
    form,
    fileList,
    handleSubmit,
    handleCancel,
    handleAvatarChange,
    handleAvatarRemove,
  };
};
