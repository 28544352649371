import { isArray, isNil } from "lodash";
import { DictionaryType } from "@/common/types/models/dictionary";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";

export const getDictionaryTypeIDs = (
  dictionaryValue: DictionaryType | DictionaryType[] | null | undefined
) => {
  if (isNil(dictionaryValue)) {
    return null;
  }

  if (isArray(dictionaryValue)) {
    return dictionaryValue.map((value) => value?.ID) || EMPTY_ARRAY_DATA;
  }

  return dictionaryValue?.ID || null;
};
