import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { ModulePath } from "@/common/types/core/routes";
import { defineModule } from "@/core/modules/define-module";
import { Register } from "@/modules/auth/children/register/containers";

const module: IModuleModel = {
  title: ModuleTitle.register,
  name: ModuleName.register,
  path: ModulePath.register,
  element: <Register />,
  persist: false,
  isIndex: true,
};

export const RegisterModule = defineModule(module);
