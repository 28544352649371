import { AdminVacancies } from "@modules/admin-panel/children/admin-vacancies/containers";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { VacancyModule } from "@/common/features/vacancy";

const module: IModuleModel = {
  title: ModuleTitle.adminVacancies,
  name: ModuleName.adminVacancies,
  children: {
    VacancyModule,
  },
  path: ModulePath.adminVacancies,
  element: <AdminVacancies />,
  persist: false,
};

export const AdminVacanciesModule = defineModule(module);
