import { useSubModuleAsMenu } from "@/common/components/navigation/menu/section-head-horizontal-menu/hooks/use-sub-module-as-menu";
import { ModulePath } from "@/common/types/core/routes";

export const useAdminPanel = () => {
  const { selectedKeys, onSelect } = useSubModuleAsMenu(
    ModulePath.adminVacancies
  );

  return { selectedKeys, onSelect };
};
