import { LaptopOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { Vacancies } from "./containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { VacancyModule } from "@/common/features/vacancy";

const module: IModuleModel = {
  title: ModuleTitle.vacancies,
  name: ModuleName.vacancies,
  path: ModulePath.vacancies,
  children: {
    VacancyModule,
  },
  icon: <LaptopOutlined />,
  element: <Vacancies />,
};

export const VacanciesModule = defineModule(module);
