import "./forbidden-page.scss";
import { FC } from "react";
import { StopOutlined } from "@ant-design/icons";
import { useAuth } from "@/common/hooks/use-auth";
import { UserRole } from "@/common/types/models/user/user";

interface IProps {}

export const ForbiddenPage: FC<IProps> = () => {
  const { userRole } = useAuth();

  return (
    <section className="forbidden-page">
      <StopOutlined
        style={{
          fontSize: "8em",
        }}
      />
      <h1>Извините, Вам не разрешен доступ к этой странице</h1>
      <p>
        Для Вашей роли не предусмотрен доступ в данный раздел. Для получения
        доступа
        {`${
          userRole?.includes(UserRole.guest)
            ? " необходимо зарегистрироваться"
            : " обратитесь в тех. поддержку"
        }`}
        .
      </p>
    </section>
  );
};
