// @ts-ignore
import storage from "redux-persist-indexeddb-storage";
import { PersistConfigModified } from "@/common/types/core/persist";
import { runMigration } from "./run-migration";

export type CoreState = Record<string, object>;

export enum StateKeyName {
  Settings = "Settings",
  AuthModule = "AuthModule",
  StopSagas = "StopSagas",
}

export const STORAGE_ROOT_KEY = "ILS_DRF";
export const STORAGE_ROOT_DB = "ILS_DB_DRF";

// Config can be mutated from outside
export const PERSIST_CONFIG: PersistConfigModified<CoreState> = {
  key: STORAGE_ROOT_KEY,
  storage: storage(STORAGE_ROOT_DB),
  multiKeys: true,
  // initial keys are static
  keys: [StateKeyName.Settings, StateKeyName.AuthModule],
  migrate: (state) => {
    return Promise.resolve(runMigration(state));
  },
};
