import { TModulesObj, TReducersObj } from "@/common/types/core/module";
import { getModuleReducers } from "./get-module-reducer";
import { createReducerManager } from "./create-reducer-manager";

export const createRootReducer = ({ modules }: { modules: TModulesObj }) => {
  const moduleReducers = getModuleReducers(modules);
  const reducers: TReducersObj = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in moduleReducers) {
    reducers[key] = moduleReducers[key];
  }

  return createReducerManager(reducers);
};
