import { ILSMembershipBadge } from "@/common/components/custom/membership-badge";
import { ILSDivider } from "@/common/components/layout/divider";
import { PUBLIC_ORGANIZATION_CHECK_REQUIRED } from "@/common/features/drivers-common/constants";
import {
  DRIVER_MEMBERSHIP_CHECK_FIELD,
  DRIVER_MEMBERSHIP_FIELD,
} from "@/common/features/drivers-common/constants/driver-form";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { useAdminEditMembership } from "@/modules/admin-panel/children/admin-drivers/hooks/use-admin-edit-membership";

export const AdminEditDriverMembershipSubform = () => {
  const { showWaitingCheckAlert } = useAdminEditMembership();

  return (
    <>
      <ILSDivider plain className="admin-edit-driver__divider">
        Участие в общественной организации &quot;Водители России&quot;
        <ILSMembershipBadge className="admin-edit-driver__membership-badge" />
      </ILSDivider>
      {renderFormItems({ fields: DRIVER_MEMBERSHIP_FIELD })}
      {showWaitingCheckAlert && (
        <p className="admin-edit-driver__membership-alert">
          {PUBLIC_ORGANIZATION_CHECK_REQUIRED}
        </p>
      )}
      {renderFormItems({
        fields: DRIVER_MEMBERSHIP_CHECK_FIELD,
      })}
    </>
  );
};
