import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  vacanciesFetchingSelector,
  vacanciesSelector,
} from "@/common/features/vacancies-common/selectors";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";
import { useVacancyOperation } from "@/common/features/vacancies-common/hooks/use-vacancy-operation";

/** Использовать для обращения к серверу!
 * Запрашивает вакансии с сервера
 *  возвращает:
 *  vacancies: либо полученные вакансии, либо пустой список
 *  loading: состояние выполнения запроса к серверу */

export const useGetVacancies = (filters?: VacanciesFilters) => {
  const { onVacancyOperation } = useVacancyOperation();
  const loading = useSelector(vacanciesFetchingSelector);
  // todo сейчас бекенд на данный запрос присылает всю информацию о вакансии,
  //  однако для первичного просмотра карточки много информации лишние
  //  (при открытии карточки - свой запрос)
  const vacancies = useSelector(vacanciesSelector);

  useEffect(() => {
    onVacancyOperation({ operation: OperationsEndpoint.GetAll, filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return { vacancies, loading };
};
