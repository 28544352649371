import { UserOutlined } from "@ant-design/icons";
import { myInfoRootReducer } from "@modules/social-services/children/my-info/reducers";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { MyInfo } from "./containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import * as myInfoRootSagas from "./sagas";
import { MyRatingApplicationModule } from "@/modules/social-services/children/my-info/children/application";

const module: IModuleModel = {
  title: ModuleTitle.myInfo,
  name: ModuleName.myInfo,
  path: ModulePath.myInfo,
  icon: <UserOutlined />,
  element: <MyInfo />,
  children: {
    MyRatingApplicationModule,
  },
  persist: false,
  reducer: myInfoRootReducer,
  sagas: getAllModuleSagas(myInfoRootSagas),
};

export const MyInfoModule = defineModule(module);
