import "../index.scss";
import { isNil } from "lodash";
import { NotFound } from "@/common/components/custom/not-found";
import { useMyVacancy } from "@/common/features/vacancy/hooks/use-my-vacancy";
import { VacancyContent } from "@/common/features/vacancy/containers/content";
import { VacancySidebar } from "@/common/features/vacancy/containers/sidebar";

export const VacancyContainer = () => {
  const {
    vacancy,
    adminActionButtons,
    userActionButtons,
    driverActionButtons,
    isVacancyFormModalOpen,
    setVacancyFormModalOpen,
    isCommentModalOpen,
    setCommentModalOpen,
    isDriverAlertApplyModalOpen,
    showDriverAlertApplyModal,
  } = useMyVacancy();

  if (isNil(vacancy)) {
    return <NotFound />;
  }

  return (
    <div className="vacancy">
      <VacancyContent vacancy={vacancy} />
      <VacancySidebar
        vacancy={vacancy}
        adminActionButtons={adminActionButtons}
        userActionButtons={userActionButtons}
        driverActionButtons={driverActionButtons}
        isVacancyFormModalOpen={isVacancyFormModalOpen}
        setVacancyFormModalOpen={setVacancyFormModalOpen}
        isCommentModalOpen={isCommentModalOpen}
        setCommentModalOpen={setCommentModalOpen}
        isDriverAlertApplyModalOpen={isDriverAlertApplyModalOpen}
        showDriverAlertApplyModal={showDriverAlertApplyModal}
      />
    </div>
  );
};
