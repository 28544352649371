import { noUnderScore } from "@/common/constants/regex";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

export const validatorMaskNumberCheck = () => ({
  validator: (instance: any, value: string) => {
    if (noUnderScore.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        `${
          FORM_LABEL[instance.field as FormFieldName]
        } может состоять только из цифр`
      )
    );
  },
});
