import { EMPTY_STRING } from "@/common/constants/general";
import { ModulePath } from "@/common/types/core/routes";

export const getDriverPath = ({
  driverID,
  isAdminPanel = false,
}: {
  driverID: number | undefined | null;
  isAdminPanel?: boolean;
}) => {
  if (!driverID) return EMPTY_STRING;

  if (!isAdminPanel) {
    return `/${ModulePath.socialServices}/${ModulePath.driverSearch}/${driverID}`;
  }

  return `/${ModulePath.adminPanel}/${ModulePath.adminDrivers}/${driverID}`;
};
