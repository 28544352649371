import { FC } from "react";
import { isEmpty } from "lodash";
import { Empty } from "antd";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { DriverWorkExperience } from "@/common/types/models/user/driver";
import { DriverDetailsExperienceItem } from "@/common/features/driver/components/sections/experience/item";

type IProps = {
  experience?: DriverWorkExperience[] | undefined;
};

export const DriverDetailsExperience: FC<IProps> = ({ experience }) => {
  return (
    <section className="driver-details__section">
      <div className="driver-details__section-column">
        <ILSTitle level={2} className="driver-details__section-title">
          Опыт
        </ILSTitle>

        <div className="driver-details__section-content_experience">
          {!isEmpty(experience) ? (
            experience?.map((experienceItem, experienceItemIndex) => (
              <DriverDetailsExperienceItem
                key={`${experienceItem.Organization}_${experienceItem?.ExperienceYears}`}
                experience={experienceItem}
                experienceNumber={experienceItemIndex + 1}
              />
            ))
          ) : (
            <Empty description="Опыт не указан" />
          )}
        </div>
      </div>
    </section>
  );
};
