import { AxiosResponse } from "axios";
import { EMPTY_STRING } from "@/common/constants/general";
import { ApiGet } from "@/common/api/helpers/get";
import { ApiPost } from "@/common/api/helpers/post";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import {
  DriverBaseRequest,
  DriversFiltersRequestType,
  DriverSearchResponse,
} from "@/common/api/types/driver";
import { getStringifierUrl } from "@/common/api/helpers/stringify-url";

type DriverEndpointType = {
  id?: number;
  filters?: DriversFiltersRequestType;
};

export const getDriverEndpoint = ({ id, filters }: DriverEndpointType) => {
  const endpoint = `/driver${id ? `/${id}` : EMPTY_STRING}`;
  return getStringifierUrl(endpoint, filters, { arrayFormat: "comma" });
};

export function driversOperation({
  operation,
  id,
  filters,
}: DriverBaseRequest): Promise<AxiosResponse<DriverSearchResponse>> {
  const driversEndpoint = getDriverEndpoint({
    id,
    filters,
  });

  switch (operation) {
    case OperationsEndpoint.GetAll:
    case OperationsEndpoint.Get:
      return ApiGet(driversEndpoint);

    default:
      return ApiPost(driversEndpoint);
  }
}

export const DRIVERS_API = {
  driversOperation,
};
