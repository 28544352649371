import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  OPERATION_APPLY_ENDPOINTS,
  VACANCIES_API,
} from "@/common/api/endpoints/vacancies";
import { vacanciesRoutine } from "@/common/features/vacancies-common/actions";
import { VacanciesPayload } from "@/common/features/vacancies-common/types/store";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";
import { vacanciesFiltersSelector } from "@/common/features/vacancies-common/selectors";
import { User, UserRole } from "@/common/types/models/user/user";
import { currentUserSelector } from "@/modules/auth/selectors/current-user";
import { needUpdateVacanciesList } from "@/common/features/vacancies-common/helpers/need-update-vacancies-list";

export function* VacanciesWorker(action: VacanciesPayload) {
  const { request, success, failure, fulfill } = vacanciesRoutine;
  const {
    operation,
    body,
    id,
    filters,
    customNotificationMessage,
    vacancyIdForApply,
  } = action.payload;

  try {
    yield put(request({ operation, customNotificationMessage }));
    const { data } = yield call(VACANCIES_API.vacanciesOperation, {
      operation,
      body,
      id,
      filters,
    });

    yield put(success({ operation, data, id, customNotificationMessage }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    if (needUpdateVacanciesList(operation)) {
      const currentFilters: VacanciesFilters | undefined = yield select(
        vacanciesFiltersSelector
      );
      yield put(
        vacanciesRoutine.trigger({
          operation: OperationsEndpoint.GetAll,
          customNotificationMessage,
          filters: currentFilters,
        })
      );
    }
    const applyEndpoints = OPERATION_APPLY_ENDPOINTS.includes(operation);

    if (applyEndpoints) {
      const user: User = yield select(currentUserSelector);
      const isDriver = user?.UserRoles?.some(
        (role) => role.Alias === UserRole.driver
      );
      yield put(
        vacanciesRoutine.trigger({
          operation: OperationsEndpoint.ApplyList,
          customNotificationMessage,
          id: !isDriver ? vacancyIdForApply : null,
        })
      );
    }

    yield put(fulfill({ operation, customNotificationMessage }));
  }
}

export function* vacanciesWatcher() {
  yield takeLatest(vacanciesRoutine.trigger, VacanciesWorker);
}
