import { MY_RATING_FIELDS } from "@/modules/social-services/children/my-info/children/application/constants";
import { RatingWithForm } from "@/common/types/models/rating";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  ratingType: RatingWithForm;
};

export const MyRatingFormFields = ({ ratingType }: IProps) => {
  return (
    <div className="rating-form__fields">
      {renderFormItems({
        fields: MY_RATING_FIELDS[ratingType],
        disabled: true,
      })}
    </div>
  );
};
