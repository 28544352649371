import { useSelector } from "react-redux";
import { adminOrganizationSelector } from "@/modules/admin-panel/children/admin-organizations/selectors/organizations";
import { useShowIndexModule } from "@/common/hooks/use-show-index-module";
import { ModulePath } from "@/common/types/core/routes";
import { getOrganizationBreadCrumbs } from "../helpers/get-organization-bread-crumbs";

export const useAdminOrganization = () => {
  const { dynamicElementID } = useShowIndexModule(ModulePath.adminOrganization);

  const organizationID = Number(dynamicElementID);
  const organization = useSelector(adminOrganizationSelector(organizationID));

  const breadCrumbItems = getOrganizationBreadCrumbs(organization);

  return { organization, breadCrumbItems };
};
