import "./index.scss";
import { FC, ReactNode } from "react";
import { Popover, PopoverProps } from "antd";
import classNames from "classnames";

type IProps = PopoverProps & {
  className?: string;
};

export const ILSPopover: FC<IProps> = ({ content, className, ...props }) => {
  return (
    <Popover
      {...props}
      content={
        <div className={classNames("popover-content", className)}>
          {content as ReactNode}
        </div>
      }
    />
  );
};
