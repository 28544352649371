import { head, isEmpty } from "lodash";
import { UploadFile } from "antd";
import {
  DeletedImageRequest,
  ImageBaseRequest,
} from "@/common/types/models/image";
import { getBase64 } from "@/common/utils/helpers/components/upload/get-base-64";
import { getDataStringFromBase64 } from "@/common/utils/helpers/regexp/get-data-string-from-base64";

/**
 *
 * Функция сохраняет данные изображения пользователя.
 * Если новое изображение = {Name: …, Data: ‘base64’}
 * Если нужно удалить существующее изображение = {DeleteID: 1}
 * Если существующее заменить на новое, то так же как с созданием {Name: …, Data: ‘base64’}
 */
export const getFormImage = async (
  fileList: UploadFile[] | undefined,
  removedSavedFiles: DeletedImageRequest[]
): Promise<ImageBaseRequest | undefined> => {
  if (!isEmpty(fileList)) {
    const imageFile = head(fileList)?.originFileObj;
    const avatarBase64 = imageFile ? await getBase64(imageFile) : null;

    if (avatarBase64) {
      return {
        Name: imageFile?.name || "avatar.png",
        Data: getDataStringFromBase64(avatarBase64),
      };
    }
    return undefined;
  }

  return !isEmpty(removedSavedFiles) ? head(removedSavedFiles) : undefined;
};
