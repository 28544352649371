import { PayloadAction } from "@reduxjs/toolkit";
import { authUserSetActions } from "@modules/auth/actions";
import { put, takeLatest } from "redux-saga/effects";
import { User } from "@/common/types/models/user/user";

export type AuthUserSetPayload = { user: User };
export type AuthUserSetAction = PayloadAction<AuthUserSetPayload>;

export function* authUserSetWorker(action: AuthUserSetAction) {
  const { success, failure, fulfill } = authUserSetActions;
  try {
    const { user } = action.payload;
    yield put(success({ user }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* authUserSetWatcher() {
  yield takeLatest(authUserSetActions.trigger, authUserSetWorker);
}
