import "./index.scss";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import {
  VACANCY_STATUS_TAG_COLORS,
  VACANCY_STATUS_TITLES,
} from "@/common/constants/statuses/vacancy-statuses";
import { VacancyStatus } from "@/common/types/models/vacancies/status";
import { ILSTag } from "@/common/components/data-display/tag";

export const VACANCY_STATUS_TAG_ICONS = {
  [VacancyStatus.Draft]: <PauseCircleOutlined />,
  [VacancyStatus.InModeration]: <ClockCircleOutlined />,
  [VacancyStatus.Rejected]: <CloseCircleOutlined />,
  [VacancyStatus.Published]: <CheckCircleOutlined />,
};

export const VacancyStatusTag = ({ status }: { status?: VacancyStatus }) => {
  if (!status) {
    return null;
  }

  return (
    <ILSTag
      className="vacancy-status-tag"
      color={VACANCY_STATUS_TAG_COLORS[status]}
      icon={VACANCY_STATUS_TAG_ICONS[status]}
    >
      {VACANCY_STATUS_TITLES[status]}
    </ILSTag>
  );
};
