import { isArray, isEmpty, isObject } from "lodash";
import { NotifyContent } from "@/common/types/notifications/actions";
import { Notify } from "@/common/types/notifications/enum";
import { ILSButton } from "../data-display/buttons";
import { isNotifyDetailedContent } from "@/common/utils/helpers/notifications/is-notify-detailed-content";

export const NotifyOuterContentComponent = ({
  content,
  type,
  showFull,
  displayFull,
}: {
  content: NotifyContent;
  type: Notify;
  showFull: boolean;
  displayFull: () => void;
}) => {
  const showButton = <ILSButton onClick={displayFull}>подробнее...</ILSButton>;

  if (isNotifyDetailedContent(content)) {
    return (
      <div className="content">
        {!showFull && (
          <div>
            <p>{content.shortContent}</p>
            {showButton}
          </div>
        )}
        {showFull && <div>{content.fullContent}</div>}
      </div>
    );
  }

  if (!isArray(content)) {
    return (
      <div className="content">
        <div>{content}</div>
      </div>
    );
  }

  if (content?.length === 1) {
    return (
      <div className="content">
        {content.map((item, index) => (
          <div key={String(index) + 1}>{item}</div>
        ))}
      </div>
    );
  }

  // NOTE ошибка приходящая с запросов
  if (type === Notify.Error) {
    return (
      <div className="content">
        {!showFull && (
          <div>
            <p>Произошла ошибка</p>
            {showButton}
          </div>
        )}
        {showFull &&
          content.map((item, index) => (
            <div key={String(index) + 1}>{item}</div>
          ))}
      </div>
    );
  }

  return (
    <div className="content">
      {!showFull && (
        <div>
          <p>{content?.[0]}</p>
          {showButton}
        </div>
      )}
      {showFull &&
        content.map((item, index) => <div key={String(index) + 1}>{item}</div>)}
    </div>
  );
};

/**
 * Создает элемент уведомления обрабатывая случаи когда контент является объектом либо массивом
 * @returns
 */
export const getOutputNotify = ({
  content,
  type,
  displayFull,
  showFull,
}: {
  content: NotifyContent;
  type: Notify;
  showFull: boolean;
  displayFull: () => void;
}) => {
  if (
    isEmpty(content) ||
    (isArray(content) && typeof content?.[0] !== "string") ||
    (isObject(content) && !isNotifyDetailedContent(content))
  ) {
    return null;
  }
  return (
    <NotifyOuterContentComponent
      content={content}
      type={type}
      showFull={showFull}
      displayFull={displayFull}
    />
  );
};
