import { useForm } from "antd/es/form/Form";
import { SurveyFormValues } from "@/modules/start/types/survey";
import { useSendSurvey } from "@/modules/start/hooks/use-send-survey";
import { showNotificationError } from "@/common/helpers/errors/show-notification-error";

export const useSurveyForm = () => {
  const [form] = useForm<SurveyFormValues>();
  const { handleSendSurvey } = useSendSurvey();

  const resetForm = () => {
    form.resetFields();
  };

  const handleSubmit = (formValues: SurveyFormValues) => {
    const emptyFields = Object.values(formValues).some(
      (value) => !value?.trim()
    );
    if (!emptyFields) {
      return handleSendSurvey(formValues, resetForm);
    }
    return showNotificationError("Пожалуйста, заполните все поля в опросе");
  };

  return { form, handleSubmit };
};
