import { JoinChar } from "@/common/constants/general";
import { RoutineActionOperation } from "@/common/types/notifications/enum";

export const splitActionType = (actionType: string) => {
  const actionTypeSplitted = actionType.split(JoinChar.ForwardSlash);
  const keySuffix = actionTypeSplitted?.[0];
  const actionTypeOperation = actionTypeSplitted?.[1];
  const actionTypeResult =
    RoutineActionOperation[actionTypeSplitted?.[2] as RoutineActionOperation];

  return {
    keySuffix,
    actionTypeOperation,
    actionTypeResult,
    actionTypeSplitted,
  };
};
