import "../index.scss";
import { LegalConsultationMain } from "@/modules/social-services/children/legal-consultation/components/legal-consultation-main";

export const LegalConsultationContainer = () => {
  return (
    <div className="legal-consultation">
      <LegalConsultationMain />
    </div>
  );
};
