import { Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ACTION_HEADER_ICONS_STYLE_CONFIG } from "@/common/constants/config/header";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSAuthMenu } from "@/common/components/layout/header/auth/auth-menu";
import { useHeaderAuth } from "@/common/hooks/header/use-header-auth";

export const ILSAuth = () => {
  const { open, handleOpenChange, onCloseMenu } = useHeaderAuth();

  return (
    <Popover
      placement="bottomLeft"
      content={<ILSAuthMenu onCloseMenu={onCloseMenu} />}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <ILSButton
        type="primary"
        size="large"
        className="header-component__auth-header-button"
        icon={<UserOutlined style={ACTION_HEADER_ICONS_STYLE_CONFIG} />}
      />
    </Popover>
  );
};
