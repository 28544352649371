import { useCallback, useState } from "react";

export const usePopover = () => {
  const [open, setOpen] = useState(false);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpenChange = useCallback((newOpen: boolean) => {
    setOpen(newOpen);
  }, []);

  return { open, hide, handleOpenChange };
};
