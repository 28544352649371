import { PUBLIC_ORGANIZATION_PARTNERSHIP_TITLE_ACCENT } from "@/common/features/drivers-common/constants";

type IProps = {
  hasConfirmedPartnership: boolean;
};

/**
 * функция проверяет, какой подзаголовок разместить
 * в карточке вакансии
 */
export const getOrganizationPartnershipTitle = ({
  hasConfirmedPartnership,
}: IProps) => {
  if (hasConfirmedPartnership) {
    return PUBLIC_ORGANIZATION_PARTNERSHIP_TITLE_ACCENT;
  }

  return null;
};
