import { FC } from "react";
import { StartPageSiderBanner } from "@/modules/start/components/sider-banners/sider-banner/sider-banner";

type IProps = {
  title: string;
};

export const SiderBannerEmpty: FC<IProps> = ({ title }) => {
  return (
    <StartPageSiderBanner>
      <div className="sider-banner_empty">
        <p>{title}</p>
      </div>
    </StartPageSiderBanner>
  );
};
