import { FC, PropsWithChildren } from "react";
import { AllowedTo } from "react-abac";
import { AllowedToProps } from "react-abac/dist/create";
import { AbacPermission } from "@/common/types/core/abac";

interface IProps extends PropsWithChildren, AllowedToProps<AbacPermission> {
  perform: AbacPermission | Array<AbacPermission>;
}

export const ILSAllowedTo: FC<IProps> = ({ perform, children, ...props }) => {
  return (
    <AllowedTo no={() => null} {...props} perform={perform as never}>
      {children}
    </AllowedTo>
  );
};
