import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { vacanciesSelector } from "@/common/features/vacancies-common/selectors";
import { findById } from "@/common/helpers/find-by-id";
import { useAccount } from "@/common/hooks/use-account";
import { useAdminVacancyActions } from "@/common/features/vacancy/hooks/use-admin-vacancy-actions";
import { useUserVacancyActions } from "@/common/features/vacancy/hooks/use-user-vacancy-actions";
import { useDriverVacancyActions } from "@/common/features/vacancy/hooks/use-driver-vacancy-actions";
import { useChangeVacanciesFilters } from "@/common/features/vacancies-common/hooks/use-vacancies-filters";
import { useOrganizationEmployeePermissions } from "@/common/hooks/permissions/use-organization-employee-permissions";

export const useMyVacancy = () => {
  const vacancies = useSelector(vacanciesSelector);
  const params = useParams();
  const vacancyID = params?.["vacancy-id"];
  // todo вакансию лучше запрашивать с бека,
  //  не брать из всего списка
  //  особенно когда список будет приходить с урезанными данными
  const selectedVacancy = vacancies.find(findById(vacancyID));

  const { adminActionButtons, isCommentModalOpen, setCommentModalOpen } =
    useAdminVacancyActions(selectedVacancy);
  const { userActionButtons, isVacancyFormModalOpen, setVacancyFormModalOpen } =
    useUserVacancyActions(selectedVacancy);
  const {
    driverActionButtons,
    isDriverAlertApplyModalOpen,
    showDriverAlertApplyModal,
  } = useDriverVacancyActions(selectedVacancy);

  const { resetFilters } = useChangeVacanciesFilters();
  const { account } = useAccount();
  const { isOrganizationEmployee } = useOrganizationEmployeePermissions();

  useEffect(() => {
    resetFilters({ isOrganizationEmployee, accountID: account?.ID });
  }, []);

  return {
    vacancy: selectedVacancy,
    adminActionButtons,
    userActionButtons,
    driverActionButtons,
    isVacancyFormModalOpen,
    setVacancyFormModalOpen,
    isCommentModalOpen,
    setCommentModalOpen,
    isDriverAlertApplyModalOpen,
    showDriverAlertApplyModal,
  };
};
