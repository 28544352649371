import { EMPTY_STRING } from "@/common/constants/general";

export const getNumberPrice = (price?: string | number | null) => {
  if (!price) {
    return 0;
  }

  const onlyNumerical = String(price).replace(/[^0-9]/g, EMPTY_STRING);
  return Number(onlyNumerical.replace(/\s/g, EMPTY_STRING));
};
