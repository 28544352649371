import { DriverInfo } from "@/common/types/models/user/driver";
import { useAuth } from "@/common/hooks/use-auth";
import { useDriverCardDetails } from "@/common/features/drivers-common/hooks/use-driver-card-details";
import { usePhoneButton } from "@/common/hooks/phone-button/use-phone-button";

export const useDriverCard = (driver: DriverInfo) => {
  const { avatar, statuses, info, driverPhoneNumber, redirectID, profile } =
    useDriverCardDetails(driver);
  const { authenticated } = useAuth();

  const {
    buttonContent,
    phoneHref,
    isModalOpen,
    showModal,
    onOkModalClick,
    onButtonClick,
  } = usePhoneButton(driverPhoneNumber);

  const actions = authenticated
    ? [
        {
          children: buttonContent,
          onClick: onButtonClick,
          href: phoneHref,
        },
      ]
    : [];

  return {
    statuses,
    actions,
    info,
    avatar,
    redirectID,
    profile,
    isModalOpen,
    showModal,
    onOkModalClick,
  };
};
