import { FC } from "react";
import { Empty } from "antd";
import { ILSDivider } from "@/common/components/layout/divider";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSFileCard } from "@/common/components/custom/file-card";
import { QuestionnaireType } from "@/common/api/types/questionnaire";
import { getOrganizationQuestionnaireText } from "@/modules/social-services/children/my-info/helpers/utils/get-organization-questionnaire-text";

type IProps = {
  template: QuestionnaireType | undefined;
};

export const MyInfoQuestionnaireTemplate: FC<IProps> = ({ template }) => {
  return (
    <div className="my-info__group" style={{ alignItems: "center" }}>
      <ILSTitle level={3}>Шаблон анкеты</ILSTitle>
      <ILSDivider className="my-info__group-divider" />
      <p>{getOrganizationQuestionnaireText(template)}</p>
      {template?.File ? (
        <ILSFileCard
          fileID={template?.File?.ID}
          fileName={template?.File?.OriginalName}
          fileUrl={template?.File?.FileUrl}
          disableDelete
          showDownloadButton={template?.Available}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />
      )}
    </div>
  );
};
