import { createSelector } from "reselect";
import {
  AdminArticlesModuleStore,
  AdminArticlesStore,
} from "@modules/admin-panel/children/admin-articles/store";
import { Articles } from "@/common/api/types/article";
import { SelectApiOption } from "@/common/types/general/common";
import { EMPTY_STRING } from "@/common/constants/general";
import { getImagesToForm } from "@/common/utils/helpers/components/avatar/get-images-to-form";

export const articlesSelector = createSelector(
  (state: AdminArticlesModuleStore) => {
    return state.AdminArticlesModule.articles;
  },
  (articles: Articles) => articles
);

export const currentArticleSelector = createSelector(
  (state: AdminArticlesModuleStore) => {
    return state.AdminArticlesModule.currentArticle;
  },
  (currentArticle: AdminArticlesStore["currentArticle"]) => currentArticle
);

export const articleInitialValuesSelector = createSelector(
  currentArticleSelector,
  (currentArticle: AdminArticlesStore["currentArticle"]) => {
    if (!currentArticle) {
      return { initialValues: undefined, initialArticleContent: EMPTY_STRING };
    }

    const initialValues = {
      ...currentArticle,
      Image: getImagesToForm(currentArticle?.Image),
      Tags: (currentArticle?.Tags || []) as unknown as SelectApiOption[],
    };

    const initialArticleContent = currentArticle?.Text || EMPTY_STRING;

    return { initialValues, initialArticleContent };
  }
);

export const isFetchingSelector = createSelector(
  (state: AdminArticlesModuleStore) => {
    return state.AdminArticlesModule.isFetching;
  },
  (isFetching: boolean) => isFetching
);
