import { FC } from "react";
import { Form, FormInstance } from "antd/lib";
import { ILSButton } from "@/common/components/data-display/buttons";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { MY_INFO_FORM_NAMES } from "@/modules/social-services/children/my-info/constants/form";
import {
  IILSFormAvatarProps,
  ILSFormAvatar,
} from "@/common/components/custom/form-avatar";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { AbacPermission } from "@/common/types/core/abac";
import { OrganizationFormValues } from "@/modules/social-services/children/my-info/types/form";
import { MyInfoFormOrganizationData } from "@/modules/social-services/children/my-info/components/subforms/organization-data";
import { MyInfoOrganizationExtraDetails } from "@/modules/social-services/children/my-info/components/forms/organization-extra-details";

type IProps = IILSFormAvatarProps & {
  form: FormInstance<OrganizationFormValues>;
  initialValues: OrganizationFormValues | null;
  organizationSocialRating: number;
  disabled: boolean;
  notTouched: boolean;
  enableSubmit: VoidFunction;
  handleSubmit: (values: OrganizationFormValues) => void;
  handleCancelChange: VoidFunction;
};

export const OrganizationInfoForm: FC<IProps> = ({
  form,
  initialValues,
  fileList,
  organizationSocialRating,
  disabled,
  notTouched,
  enableSubmit,
  handleSubmit,
  handleCancelChange,
  handleAvatarChange,
  handleAvatarRemove,
}) => {
  return (
    <Form
      disabled={notTouched}
      onFieldsChange={enableSubmit}
      form={form}
      name={MY_INFO_FORM_NAMES.ORGANIZATION}
      initialValues={initialValues || EMPTY_OBJECT_DATA}
      onFinish={handleSubmit}
      layout="vertical"
      className="my-info-form"
    >
      <div className="my-info-form__column">
        <ILSFormAvatar
          fileList={fileList}
          disabled={disabled}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
      </div>

      <div className="my-info-form__column my-info-form__column_wide">
        <div className="my-info-form__row">
          <div className="my-info-form__organization-fields">
            <MyInfoFormOrganizationData
              initialValues={initialValues}
              disabled={disabled}
            />
          </div>
          <MyInfoOrganizationExtraDetails rating={organizationSocialRating} />
        </div>
        <ILSAllowedTo perform={AbacPermission.organization}>
          <div className="my-info-form__buttons">
            <ILSButton onClick={handleCancelChange} disabled={notTouched}>
              Отмена
            </ILSButton>
            <ILSButton type="primary" htmlType="submit" disabled={notTouched}>
              Сохранить
            </ILSButton>
          </div>
        </ILSAllowedTo>
      </div>
    </Form>
  );
};
