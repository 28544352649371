import { NamePath } from "antd/es/form/interface";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { getNumberPrice } from "@/common/utils/helpers/price/get-number-price";

export const validatorCheckSalary = ({
  getFieldValue,
}: {
  getFieldValue: (name: NamePath) => string | undefined;
}) => ({
  validator: (_instance: any, _value: string) => {
    const fromFieldValue = getFieldValue([
      FormFieldName.Salary,
      FormFieldName.From,
    ]);

    const toFieldValue = getFieldValue([
      FormFieldName.Salary,
      FormFieldName.To,
    ]);

    const fromPrice = getNumberPrice(fromFieldValue);
    const toPrice = getNumberPrice(toFieldValue);

    if (fromPrice && toPrice && toPrice >= fromPrice) {
      return Promise.resolve();
    }

    if (
      (!fromPrice && toPrice) ||
      (fromPrice && !toPrice) ||
      (!fromPrice && !toPrice)
    ) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(
        "Укажите значение верхней границы заработной платы больше, чем нижней"
      )
    );
  },
});
