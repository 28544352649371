import { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { updateUserActions } from "@modules/social-services/children/my-info/actions";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { DriverFormValues } from "@/common/features/drivers-common/types/driver-form";
import { useFormAvatar } from "@/common/hooks/use-form-avatar";
import { useMyInfoCategories } from "@/modules/social-services/children/my-info/hooks/use-my-info-categories";
import { useDriverAccount } from "@/common/hooks/use-driver-account";
import { useShowReadyStartTomorrowField } from "@/common/features/drivers-common/hooks/use-show-ready-start-tomorrow-field";
import { useShowDriverLicenseField } from "@/common/hooks/form/use-show-driver-license-field";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { driverOptionsSelector } from "@/common/features/dictionaries/selectors";
import { driverToFormValues } from "@/common/features/drivers-common/helpers/driver-to-form-values";
import { formValuesToDriverData } from "@/modules/social-services/children/my-info/helpers/decorators/form-values-to-driver-data";

export const useMyInfoDriverContainer = () => {
  const { driverInfo } = useDriverAccount();
  const dispatch = useDispatch();
  const [form] = useForm<DriverFormValues>();
  const { refData } = useSelector(driverOptionsSelector);

  const initialValues = driverToFormValues({
    driverInfo,
  });

  const {
    fileList,
    removedSavedFiles,
    handleAvatarChange,
    handleAvatarRemove,
    handleAvatarReset,
  } = useFormAvatar({
    avatar: initialValues?.Avatar,
  });

  const { selectedCategories, handleCategoriesChange, handleCategoriesReset } =
    useMyInfoCategories({
      initialCategories: initialValues?.LicenseCategory,
    });

  const handleSubmit = async (formValues: DriverFormValues) => {
    try {
      const payload = await formValuesToDriverData({
        formValues,
        fileList,
        removedSavedFiles,
        selectedCategories,
        membershipChecked: initialValues?.MembershipChecked,
      });

      dispatch(
        updateUserActions({ body: payload, userID: driverInfo?.UserID })
      );
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  const handleSetInitialValues = () => {
    /** сбрасываем значения формы до начальных при клике на "Отмена" */
    form.resetFields();
    form.setFieldsValue(initialValues || EMPTY_OBJECT_DATA);

    /** категории и аватар сбрасываются через свой state */
    handleCategoriesReset();
    handleAvatarReset();
  };

  const handleCancelChange = () => handleSetInitialValues();

  useEffect(() => {
    handleSetInitialValues();
  }, [driverInfo]);

  const { showReadyToStartTomorrow } = useShowReadyStartTomorrowField({ form });
  const { showDriverLicenseField } = useShowDriverLicenseField({ form });
  const membershipChecked = driverInfo?.MembershipChecked;

  return {
    form,
    initialValues,
    selectedCategories,
    fileList,
    refData,
    showReadyToStartTomorrow,
    showDriverLicenseField,
    membershipChecked,
    handleCategoriesChange,
    handleSubmit,
    handleCancelChange,
    handleAvatarChange,
    handleAvatarRemove,
  };
};
