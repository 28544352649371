import { FC } from "react";
import { Form } from "antd";
import { AuthButton } from "@modules/auth/components/button";
import { changePasswordActions } from "@modules/auth/actions";
import { useDispatch } from "react-redux";
import { NamePath } from "antd/es/form/interface";
import { ILSInputPassword } from "@/common/ui-components/data-entry/input/password";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { ChangePasswordRequest } from "@/common/api/types/auth";

export const CHANGE_PASSWORD_FORM_NAME = "auth-form_password-change";
export const CHANGE_PASSWORD_CONTENT = "Сменить пароль";
export const CHANGE_PASSWORD_FORM_SETTINGS_DEPENDENCIES = [
  FormFieldName.NewPassword,
] as NamePath[];

export const CHANGE_PASSWORD_FORM_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.OldPassword,
    },

    Component: ILSInputPassword,
  },
  {
    fieldProps: {
      field: FormFieldName.NewPassword,
    },
    Component: ILSInputPassword,
  },
  {
    fieldProps: {
      dependencies: CHANGE_PASSWORD_FORM_SETTINGS_DEPENDENCIES,
      field: FormFieldName.ConfirmPassword,
    },
    Component: ILSInputPassword,
  },
] as FormItemsFields;

export type PassWordChange = {
  [FormFieldName.OldPassword]: string;
  [FormFieldName.NewPassword]: string;
  [FormFieldName.ConfirmPassword]: string;
};

type IProps = {
  onClose: VoidFunction;
};

export const usePasswordChange = ({ onClose }: IProps) => {
  const dispatch = useDispatch();

  const onPasswordChange = (values: PassWordChange) => {
    const payload: ChangePasswordRequest = {
      OldPassword: values.OldPassword,
      NewPassword: values.NewPassword,
    };
    dispatch(changePasswordActions(payload));
    onClose();
  };

  return { onPasswordChange };
};

export const ChangePassword: FC<IProps> = ({ onClose }) => {
  const { onPasswordChange } = usePasswordChange({ onClose });

  return (
    <Form
      name={CHANGE_PASSWORD_FORM_NAME}
      onFinish={onPasswordChange}
      layout="vertical"
    >
      {renderFormItems({ fields: CHANGE_PASSWORD_FORM_FIELDS })}
      <AuthButton form={CHANGE_PASSWORD_FORM_NAME}>
        {CHANGE_PASSWORD_CONTENT}
      </AuthButton>
    </Form>
  );
};
