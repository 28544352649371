import { ApiPost } from "@/common/api/helpers/post";
import { ApiGet } from "@/common/api/helpers/get";
import { EMPTY_OBJECT_DATA, EMPTY_STRING } from "@/common/constants/general";
import {
  GetUsersResponse,
  UserCreateRequest,
  UserUpdateBody,
  UsersFilters,
} from "@/common/api/types/user";
import { User } from "@/common/types/models/user/user";
import { ApiPatch } from "@/common/api/helpers/patch";
import { ApiDelete } from "@/common/api/helpers/delete";
import { ContentType } from "@/common/api/constants";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { getStringifierUrl } from "@/common/api/helpers/stringify-url";

type UserEndpointType = {
  method?: OperationsEndpoint;
  id?: number | undefined;
  filters?: UsersFilters;
};

export const getUserEndpoint = ({ method, id, filters }: UserEndpointType) => {
  const baseEndpoint = `/user`;

  const endpoint = `${baseEndpoint}${id ? `/${id}` : EMPTY_STRING}${
    method ? `/${method}` : EMPTY_STRING
  }`;

  return getStringifierUrl(endpoint, filters);
};

export const USER_API = {
  /** Возвращает список пользователей аккаунта
   Доступно для любого пользователя. */
  getUsers: ({ filters }: { filters?: UsersFilters }) => {
    return ApiGet<GetUsersResponse>(getUserEndpoint({ filters }));
  },

  /** Добавление пользователя к организации.
   Ожидает POST параметры пользователя.
   Доступно для пользователя с ролью администратор */
  create: (body: UserCreateRequest) => {
    return ApiPost<User>(getUserEndpoint(EMPTY_OBJECT_DATA), body);
  },

  update: ({
    body,
    userID,
    filters,
  }: {
    body?: UserUpdateBody;
    userID?: number;
    filters?: UsersFilters;
  }) => {
    return ApiPatch<User>(getUserEndpoint({ id: userID, filters }), body, {
      contentType: ContentType.JSON,
    });
  },

  delete: ({ userID }: { userID: number | undefined }) => {
    return ApiDelete<User>(getUserEndpoint({ id: userID }));
  },
};
