import { FC } from "react";
import { FormListFieldData, FormListOperation } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { DRIVER_EXPERIENCE_FIELDS } from "@/common/features/drivers-common/constants/driver-form";
import { RefDataType } from "@/common/features/dictionaries/types";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  refData: RefDataType;
};

export const DriverExperienceItem: FC<IProps> = ({
  subField,
  subOpt,
  refData,
}) => {
  const onClickDeleteExperience = () => subOpt.remove(subField.name);

  return (
    <div className="driver-experience-item">
      <div className="driver-experience-fields">
        {renderFormItems({
          fields: DRIVER_EXPERIENCE_FIELDS,
          fieldIndex: subField.name,
          refData,
        })}
      </div>
      <MinusCircleOutlined onClick={onClickDeleteExperience} />
    </div>
  );
};
