import { UploadFile } from "antd";
import {
  FileType,
  getBase64,
} from "@/common/utils/helpers/components/upload/get-base-64";

export const getFileToBase64WithoutOriginFileObj = (
  files: UploadFile[] | undefined
) => {
  const base64FilesPromise: Promise<string>[] = [];

  files?.forEach((file) => {
    if (file) {
      const image = getBase64(file as FileType);
      base64FilesPromise.push(image);
    }
  });

  return base64FilesPromise;
};
