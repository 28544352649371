import { useDispatch } from "react-redux";
import { SurveyRequestBody } from "@/common/api/types/survey";
import { surveyRoutine } from "@/modules/start/actions";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { OperationsEndpoint } from "@/common/api/constants/operations";

export const useSendSurvey = () => {
  const dispatch = useDispatch();

  const handleSendSurvey = (
    values: SurveyRequestBody,
    onFinally?: VoidFunction
  ) => {
    try {
      dispatch(
        surveyRoutine({ body: values, operation: OperationsEndpoint.Create })
      );
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      onFinally?.();
    }
  };

  return { handleSendSurvey };
};
