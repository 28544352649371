import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
// eslint-disable-next-line import/no-cycle
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import * as dictionariesRootSagas from "@/common/features/dictionaries/sagas";
import { dictionariesRootReducer } from "@/common/features/dictionaries/reducers";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

const module: IModuleModel = {
  title: ModuleTitle.dictionaries,
  name: ModuleName.dictionaries,
  path: ModulePath.dictionaries,
  reducer: dictionariesRootReducer,
  sagas: getAllModuleSagas(dictionariesRootSagas),
};

export const DictionariesModule = defineModule(module);
