import { Middleware, Reducer } from "redux";
import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { GetDefaultMiddlewareOptions } from "@/common/types/core/redux";

export const configureRootStore = (
  reducer: Reducer,
  middleware: Middleware[],
  options: GetDefaultMiddlewareOptions
): EnhancedStore => {
  return configureStore({
    reducer,
    // todo https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(options).concat(middleware),
    devTools: process.env.NODE_ENV !== "production",
  });
};
