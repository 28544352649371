import { createRoutine } from "redux-saga-routines";

export enum AdminOrganizationsActions {
  DICTIONARY_OPERATION = "ADMIN_ORGANIZATIONS/DICTIONARY_OPERATION",
  USERS_OPERATION = "ADMIN_ORGANIZATIONS/USERS_OPERATION",
}

export const adminOrganizationsRoutine = createRoutine(
  AdminOrganizationsActions.DICTIONARY_OPERATION
);
export const adminOrganizationsUsersRoutine = createRoutine(
  AdminOrganizationsActions.USERS_OPERATION
);

export const AdminOrganizationsActionsForNotifications = [
  adminOrganizationsRoutine,
];
