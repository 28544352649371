import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { AdminDrivers } from "./containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { DriverDetailsModule } from "@/common/features/driver";
import { adminDriversRootReducer } from "@/modules/admin-panel/children/admin-drivers/reducers";

const module: IModuleModel = {
  title: ModuleTitle.adminDrivers,
  name: ModuleName.adminDrivers,
  path: ModulePath.adminDrivers,
  element: <AdminDrivers />,
  children: {
    DriverDetailsModule,
  },
  reducer: adminDriversRootReducer,
};

export const AdminDriversModule = defineModule(module);
