import { FC } from "react";
import { ItemType } from "antd/es/menu/interface";
import { ILSMenu } from "@/common/ui-components/navigation/menu";
import { getMenuItem } from "@/common/ui-components/navigation/helpers/get-menu-item";
import { useIcons } from "@/common/hooks/use-icons";
import {
  ascSortKeys,
  SORT_ICON_STYLE,
  SortKeys,
  sortKeys,
  sortTexts,
} from "@/common/constants/config/sort";

type IProps = {
  activeSortKey: string | undefined;
  onSortClick: ({ key }: { key: string }) => void;
};

export const VacanciesSortMenu: FC<IProps> = ({
  activeSortKey,
  onSortClick,
}) => {
  const { SortDown, SortUp } = useIcons(SORT_ICON_STYLE);

  const menuItems: ItemType[] = sortKeys.map((key) => {
    const icon = ascSortKeys.includes(key) ? <SortUp /> : <SortDown />;
    const label = sortTexts[key];

    return getMenuItem(label, key, icon);
  });

  return (
    <ILSMenu
      defaultSelectedKeys={[activeSortKey || SortKeys.IDDesc]}
      className="vacancies-sort__menu"
      items={menuItems}
      onClick={onSortClick}
      mode="inline"
      theme="light"
    />
  );
};
