import "../index.scss";
import { ILSTabs, ILSTabPane } from "@/common/components/data-display/tabs";
import { ORGANIZATION_TAB_NAMES } from "@/modules/admin-panel/children/admin-organizations/children/organization/constants/tabs";
import { useAdminOrganization } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/use-admin-organization";
import { AdminOrganizationsTabs } from "@/modules/admin-panel/children/admin-organizations/children/organization/types/tabs";
import { AdminOrganizationFormContainer } from "@/modules/admin-panel/children/admin-organizations/children/organization/containers/organization";
import { AdminOrganizationUsers } from "@/modules/admin-panel/children/admin-organizations/children/organization/containers/users";
import { ILSBreadcrumb } from "@/common/ui-components/data-display/bread-crumbs";

export const AdminOrganizationContainer = () => {
  const { organization, breadCrumbItems } = useAdminOrganization();

  return (
    <div>
      <ILSBreadcrumb items={breadCrumbItems} />
      <ILSTabs type="line" tabPosition="top">
        <ILSTabPane
          tab={ORGANIZATION_TAB_NAMES[AdminOrganizationsTabs.Organization]}
          key={AdminOrganizationsTabs.Organization}
        >
          <AdminOrganizationFormContainer organization={organization} />
        </ILSTabPane>
        <ILSTabPane
          tab={ORGANIZATION_TAB_NAMES[AdminOrganizationsTabs.Employees]}
          key={AdminOrganizationsTabs.Employees}
        >
          <AdminOrganizationUsers organizationID={organization?.ID} />
        </ILSTabPane>
      </ILSTabs>
    </div>
  );
};
