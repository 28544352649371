import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { authUserSetActions } from "@modules/auth/actions";
import { getOrganizationActions } from "@modules/social-services/children/my-info/actions";
import { currentUserSelector } from "@modules/auth/selectors/current-user";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { ORGANIZATIONS_API } from "@/common/api/endpoints/organization";
import { OrganizationInfo } from "@/common/types/models/account";
import { User } from "@/common/types/models/user/user";

export type GetOrganizationPayload = {
  organizationID: number;
};
export type UpdateOrganizationAction = PayloadAction<GetOrganizationPayload>;

export function* getOrganizationWorker(action: UpdateOrganizationAction) {
  const { request, failure, fulfill } = getOrganizationActions;

  try {
    const { organizationID } = action.payload;

    yield put(request());
    const { data: organization }: { data: OrganizationInfo } = yield call(
      ORGANIZATIONS_API.organizationsOperations,
      {
        operation: OperationsEndpoint.Get,
        id: organizationID,
      }
    );
    const currentUser: User = yield select(currentUserSelector);
    const updatedUser = {
      ...currentUser,
      Account: {
        ...currentUser.Account,
        Organization: [organization],
      },
    };

    /** 1. Меняем данные организации пользователя в store */
    yield put(authUserSetActions.success({ user: updatedUser }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getOrganizationWatcher() {
  yield takeLatest(getOrganizationActions.trigger, getOrganizationWorker);
}
