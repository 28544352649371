import "./index.scss";
import { FC, ReactNode } from "react";
import { Rule } from "antd/es/form";
import { NamePath } from "rc-field-form/lib/interface";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { FormPlaceholder } from "@/common/ui-components/data-entry/form/constants/placeholder";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FORM_RULES } from "@/common/ui-components/data-entry/form/constants/rules";
import { FORM_SUFFIX } from "@/common/ui-components/data-entry/form/constants/suffix";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSText } from "@/common/ui-components/general/typography/text";
import { formatPriceInput } from "@/common/utils/helpers/price/format-price-input";

interface IProps {
  rules?: Rule[];
  label?: ReactNode;
  disabled?: boolean;
}

export const Salary: FC<IProps> = ({
  rules = FORM_RULES.Salary,
  label = FORM_LABEL.Salary,
  disabled = false,
}) => {
  return (
    <>
      <ILSText>{label}</ILSText>
      <div className="salary">
        <ILSFormItem
          name={[FormFieldName.Salary, FormFieldName.From] as NamePath}
          rules={rules}
          getValueFromEvent={formatPriceInput}
          dependencies={[[FormFieldName.Salary, FormFieldName.To]]}
        >
          <ILSInput
            name={[FormFieldName.Salary, FormFieldName.From] as NamePath}
            placeholder={FormPlaceholder.From}
            disabled={disabled}
            suffix={FORM_SUFFIX.RUB}
          />
        </ILSFormItem>
        <ILSFormItem
          name={[FormFieldName.Salary, FormFieldName.To] as NamePath}
          className="salary__to"
          rules={rules}
          getValueFromEvent={formatPriceInput}
          dependencies={[[FormFieldName.Salary, FormFieldName.From]]}
        >
          <ILSInput
            name={[FormFieldName.Salary, FormFieldName.To] as NamePath}
            placeholder={FormPlaceholder.To}
            disabled={disabled}
            suffix={FORM_SUFFIX.RUB}
          />
        </ILSFormItem>
      </div>
    </>
  );
};
