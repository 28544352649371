import "./index.scss";
import { FC } from "react";
import { NamePath } from "rc-field-form/lib/interface";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSInputNumber } from "@/common/ui-components/data-entry/input/number";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { FormPlaceholder } from "@/common/ui-components/data-entry/form/constants/placeholder";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FORM_RULES } from "@/common/ui-components/data-entry/form/constants/rules";

interface IProps {}

export const DrivingExperience: FC<IProps> = () => {
  return (
    <div className="driving-experience">
      <ILSFormItem
        name={
          [FormFieldName.DrivingExperience, FormFieldName.Years] as NamePath
        }
        label={FORM_LABEL.DrivingExperience}
        rules={FORM_RULES.DrivingExperience}
      >
        <ILSInputNumber
          placeholder={FormPlaceholder.Years}
          size="middle"
          min={0}
        />
      </ILSFormItem>
      <ILSFormItem
        name={
          [FormFieldName.DrivingExperience, FormFieldName.Months] as NamePath
        }
        label=" "
        className="driving-experience__months"
      >
        <ILSInputNumber
          name={
            [FormFieldName.DrivingExperience, FormFieldName.Months] as NamePath
          }
          placeholder={FormPlaceholder.Months}
          size="middle"
          min={0}
          max={11}
        />
      </ILSFormItem>
    </div>
  );
};
