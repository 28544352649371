import { useDispatch, useSelector } from "react-redux";
import { articlesRoutine } from "@modules/admin-panel/children/admin-articles/actions";
import {
  currentArticleSelector,
  isFetchingSelector,
} from "@modules/admin-panel/children/admin-articles/selectors/articles";
import { Article, ArticleBaseRequest } from "@/common/api/types/article";
import { OperationsEndpoint } from "@/common/api/constants/operations";

export const useGetArticle = () => {
  const currentArticle = useSelector(currentArticleSelector);
  const loading = useSelector(isFetchingSelector);

  const dispatch = useDispatch();

  const getArticle = (id: Article["ID"]) => {
    const payload: ArticleBaseRequest = {
      id,
      operation: OperationsEndpoint.Get,
    };
    dispatch(articlesRoutine(payload));
  };

  return { getArticle, currentArticle, loading };
};
