import { useParams } from "react-router-dom";
import { getRatingBlockContentType } from "@/modules/social-services/children/my-info/helpers/utils/get-rating-block-content-type";

export const useMyRating = () => {
  const params = useParams();
  const blockContent = getRatingBlockContentType(
    params?.["my-rating-application"]
  );

  return { showIndexModule: !blockContent };
};
