import { JoinChar } from "@/common/constants/general";
import { DEFAULT_APP_ERROR_MESSAGE } from "@/common/constants/notifications/config";
import { createArrayFromErrorObject } from "../array/create-array-from-error-object";
import { APIError } from "@/common/api/types/error";

export const getErrorMessage = ({ error }: APIError): string => {
  switch (typeof error) {
    case "object":
      return createArrayFromErrorObject(error).join(JoinChar.Semicolon);
    case "string":
    default:
      return error ?? DEFAULT_APP_ERROR_MESSAGE;
  }
};
