import { Outlet } from "react-router-dom";
import { AbacPermission } from "@/common/types/core/abac";
import { useMyRating } from "@/modules/social-services/children/my-info/hooks/rating/use-my-rating";
import { ProtectedPage } from "@/pages/protected-page";
import { MyRatingList } from "@/modules/social-services/children/my-info/components/rating/list";

export const MyRatingContainer = () => {
  const { showIndexModule } = useMyRating();

  return (
    <ProtectedPage perform={AbacPermission.organizationAndEmployee}>
      <section className="my-rating">
        <div className="my-rating__content">
          {showIndexModule ? <MyRatingList /> : <Outlet />}
        </div>
      </section>
    </ProtectedPage>
  );
};
