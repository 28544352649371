import { EMPTY_STRING } from "@/common/constants/general";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { PHONE_MASK } from "@/common/constants/config/masks";
import { FORBIDDEN_KEYS } from "@/common/ui-components/data-entry/form/constants/forbidden-keys";
import {
  ORGANIZATION_TYPE_ID_OPTIONS,
  PUBLIC_ORGANIZATION_PARTNERSHIP_CHECK_OPTIONS,
} from "@/common/constants/config/options";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSInputMask } from "@/common/components/data-entry/masked-input";
import { ILSInputNumber } from "@/common/ui-components/data-entry/input/number";
import { ILSRadioGroup } from "@/common/ui-components/data-entry/radio/group";
import { ILSSelect } from "@/common/ui-components/data-entry/select";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const ADMIN_ORGANIZATION_GENERAL_DATA = [
  {
    fieldProps: {
      field: FormFieldName.OrganizationName,
      required: true,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.OrganizationTypeID,
    },
    inputProps: {
      options: ORGANIZATION_TYPE_ID_OPTIONS,
    },
    Component: ILSSelect,
  },
  {
    fieldProps: {
      field: FormFieldName.Phone,
    },
    inputProps: {
      mask: PHONE_MASK,
    },
    Component: ILSInputMask,
  },
  {
    fieldProps: {
      field: FormFieldName.INN,
    },
    inputProps: { forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.KPP,
    },
    inputProps: { forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.OGRN,
    },
    inputProps: { forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.OKPO,
    },
    inputProps: { forbiddenKeys: FORBIDDEN_KEYS.EXCEPT_NUMBERS },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.LegalAddress,
    },
    Component: ILSInput,
  },
  {
    fieldProps: {
      field: FormFieldName.ActualAddress,
    },
    Component: ILSInput,
  },
] as FormItemsFields;

export const ADMIN_ORGANIZATION_PARTNERSHIP_FIELDS = [
  {
    fieldProps: {
      field: FormFieldName.PublicOrganizationPartnership,
      label: EMPTY_STRING,
      valuePropName: "checked",
      help: "Статус партнера доступен после проверки администратором",
    },
    inputProps: {
      label: FORM_LABEL[FormFieldName.PublicOrganizationPartnership],
    },
    Component: ILSCheckbox,
  },
  {
    fieldProps: {
      field: FormFieldName.PartnershipChecked,
    },
    inputProps: {
      optionType: "button",
      buttonStyle: "solid",
      options: PUBLIC_ORGANIZATION_PARTNERSHIP_CHECK_OPTIONS,
    },
    Component: ILSRadioGroup,
  },
  {
    fieldProps: { field: FormFieldName.SocialRating },
    inputProps: {
      min: 0,
      max: 100,
      defaultValue: 0,
    },
    Component: ILSInputNumber,
  },
] as FormItemsFields;
