import { createSelector } from "reselect";
import { MyInfoModuleStore } from "@modules/social-services/children/my-info/store";

export const verificationTargetSelector = createSelector(
  (state: MyInfoModuleStore) => {
    return state.MyInfoModule.verification.target;
  },
  (target: string | null) => target
);

export const verificationFetchingSelector = createSelector(
  (state: MyInfoModuleStore) => {
    return state.MyInfoModule.verification.isFetching;
  },
  (isFetching: boolean) => isFetching
);
