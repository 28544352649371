import { StartPageNewsContainer } from "@/modules/start/containers/news/news-container";
import { StartPageContests } from "@/modules/start/containers/contests/contests";
import { StartPageVacancies } from "@/modules/start/containers/vacancies/start-page-vacancies";

export const StartPageMain = () => {
  return (
    <div className="start-page__main">
      {/* NOTE: оставляем Carousel на случай, если необходимо будет вернуть
      компонент */}
      {/* <StartPageCarousel /> */}
      <StartPageVacancies />
      <StartPageContests />
      <StartPageNewsContainer />
    </div>
  );
};
