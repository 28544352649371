import { call, put, takeLatest } from "redux-saga/effects";
import { adminOrganizationsUsersRoutine } from "@/modules/admin-panel/children/admin-organizations/actions";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";
import { AdminOrganizationUsersPayload } from "@/modules/admin-panel/children/admin-organizations/types/store";
import { getUserApiEndpoint } from "@/modules/admin-panel/children/admin-organizations/children/organization/helpers/get-user-api-endpoint";

export function* AdminOrganizationUsersWorker(
  action: AdminOrganizationUsersPayload
) {
  const { request, success, failure, fulfill } = adminOrganizationsUsersRoutine;
  const { body, userID, operation, filters } = action.payload;

  try {
    yield put(request());
    const apiOperation = getUserApiEndpoint(operation);

    const { data } = yield call(apiOperation, {
      body,
      userID,
      filters,
    });

    yield put(success({ operation, data, userID }));
    yield call(showSuccessNotification, "Данные успешно сохранены");
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill({ operation }));
  }
}

export function* adminOrganizationUsersWatcher() {
  yield takeLatest(
    adminOrganizationsUsersRoutine.trigger,
    AdminOrganizationUsersWorker
  );
}
