import { call, put, takeLatest } from "redux-saga/effects";
import { getUsersActions } from "@modules/social-services/children/my-info/actions";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { USER_API } from "@/common/api/endpoints/user";
import { User } from "@/common/types/models/user/user";
import { IndexedCollection } from "@/common/types/general/common";

export function* getUsersWorker() {
  const { request, success, failure, fulfill } = getUsersActions;

  try {
    yield put(request());
    const { data: users }: { data: IndexedCollection<User> } = yield call(
      USER_API.getUsers,
      EMPTY_OBJECT_DATA
    );
    const payload: { users: IndexedCollection<User> } = { users };
    yield put(success(payload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* getUsersWatcher() {
  yield takeLatest(getUsersActions.trigger, getUsersWorker);
}
