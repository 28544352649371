import { AdminPanel } from "@modules/admin-panel/containers";
import { ADMIN_PANEL_SERVICES_MODULE_CHILDREN } from "@modules/admin-panel/constants/admin-panel";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { ModulePath } from "@/common/types/core/routes";
import { defineModule } from "@/core/modules/define-module";

const module: IModuleModel = {
  title: ModuleTitle.adminPanel,
  name: ModuleName.adminPanel,
  path: ModulePath.adminPanel,
  element: <AdminPanel />,
  children: ADMIN_PANEL_SERVICES_MODULE_CHILDREN,
  persist: false,
};

export const AdminPanelModule = defineModule(module);
