import { isUndefined, omitBy } from "lodash";
import {
  DEFAULT_API_OPTIONS,
  DEFAULT_CONTENT_TYPE,
} from "@/common/api/constants";
import { apiInstance } from "@/common/api/helpers/instance";

export const ApiPost = <T>(
  endpoint: string,
  body?: object,
  options: { useBearer?: boolean; contentType?: string } = DEFAULT_API_OPTIONS
): Promise<T> => {
  const { contentType } = options;

  return apiInstance()
    .post<T>(endpoint, omitBy(body, isUndefined), {
      headers: {
        "Content-Type": contentType || DEFAULT_CONTENT_TYPE,
      },
    })
    .then((r) => r.data);
};
