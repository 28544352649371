import { Select } from "antd";
import { FC } from "react";
import classNames from "classnames";
import { SelectProps } from "antd/es/select";

export interface IILSSelectProps extends SelectProps {
  className?: string;
}

export const ILSSelect: FC<IILSSelectProps> = ({ className, ...props }) => {
  return <Select {...props} className={classNames(className)} />;
};
