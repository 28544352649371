import { FC } from "react";
import { ClockCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { timestampToDateTimeString } from "@/common/utils/helpers/date-time/timestamp-to-date-time-string";
import { TimeStamp } from "@/common/types/general/date-time";

type IProps = {
  date: TimeStamp;
  views: number;
};

export const NewsCardFooter: FC<IProps> = ({ date, views }) => {
  return (
    <div className="news-card__footer">
      {date && (
        <div className="news-card__group">
          <ClockCircleOutlined />
          {timestampToDateTimeString(date)}
        </div>
      )}
      <div className="news-card__group">
        <EyeOutlined />
        {views}
      </div>
    </div>
  );
};
