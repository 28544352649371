import { FC } from "react";
import { Card } from "antd";
import { CardProps } from "antd/lib/card";
import { CardMetaProps } from "antd/lib/card/Meta";

const { Meta } = Card;

export const ILSCard: FC<CardProps> = (props) => {
  return <Card {...props} />;
};

export const ILSCardMeta: FC<CardMetaProps> = (props) => {
  return <Meta {...props} />;
};
