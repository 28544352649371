import dayjs from "dayjs";
import { isBoolean, isString } from "lodash";

export type PayloadFromFormValues = Record<string, string | number | undefined>;

export const getPayloadFromFormValues = <V extends Record<string, unknown>>(
  formValues: V
) => {
  const payload: PayloadFromFormValues = Object.entries(formValues).reduce(
    (acc, [key, value]) => {
      if (dayjs.isDayjs(value)) {
        acc[key] = dayjs(value).unix();
      } else if (isString(value)) {
        acc[key] = value.trim();
      } else if (isBoolean(value)) {
        acc[key] = Number(value);
      } else {
        acc[key] = value as string | number;
      }
      return acc;
    },
    {} as PayloadFromFormValues
  );

  return payload;
};
