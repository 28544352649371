import { RatingType } from "@/common/types/models/account";

export const getMeanAccountRating = (userRating?: RatingType): number => {
  if (!userRating) return 0;
  const rating = Object.values(userRating ?? {});
  const accountRating: number =
    Object.values(rating).reduce((acc, count) => acc + count, 0) /
    rating.length;

  return accountRating % 0 === accountRating
    ? accountRating
    : Number(accountRating.toFixed(2));
};
