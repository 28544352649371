import { FC } from "react";
import { ILSWideCard } from "@/common/ui-components/data-display/wide-card";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { VacancyFormType } from "@/common/features/vacancies-common/types/form";
import { VacancyFormModal } from "@/common/features/vacancies-common/components/form/modal";
import { useAdminVacancyCard } from "@/modules/admin-panel/children/admin-vacancies/hooks/use-admin-vacancy-card";

interface IProps {
  vacancy: Vacancy;
}

export const AdminVacancyCard: FC<IProps> = ({ vacancy }) => {
  const {
    actions,
    info,
    cover,
    profile,
    ratings,
    vacancyID,
    isModalOpen,
    statuses,
    showModal,
  } = useAdminVacancyCard(vacancy);

  return (
    <>
      <ILSWideCard
        actions={actions}
        info={info}
        cover={cover}
        profile={profile}
        ratings={ratings}
        statuses={statuses}
        redirectID={vacancyID}
      />
      <VacancyFormModal
        type={VacancyFormType.Edit}
        vacancyId={vacancyID}
        isModalOpen={isModalOpen}
        showModal={showModal}
      />
    </>
  );
};
