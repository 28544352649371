import { useState } from "react";
import { useModal } from "@/common/hooks/use-modal";
import { START_PAGE_CONTESTS } from "@/modules/start/constants/contests";

export const useStartPageContests = () => {
  const [selectedContestID, setSelectedContestID] = useState<number | null>(
    null
  );

  const { showModal, isModalOpen } = useModal();

  const onParticipateClick = (contestID: number) => () => {
    setSelectedContestID(contestID);
    showModal();
  };

  const selectedContest = START_PAGE_CONTESTS.find(
    (contest) => contest?.ID === selectedContestID
  );

  return { selectedContest, isModalOpen, showModal, onParticipateClick };
};
