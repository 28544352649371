import {
  checkPasswordRecoveryCodeActions,
  recoveryProcessActions,
  setUserOptionsActions,
} from "@modules/auth/actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { AUTH_API } from "@/common/api/endpoints/auth";
import { CheckPasswordRecoveryCodeRequest } from "@/common/api/types/auth";

export type CheckPasswordRecoveryCodeAction =
  PayloadAction<CheckPasswordRecoveryCodeRequest>;

export function* checkPasswordRecoveryCodeWorker(
  action: CheckPasswordRecoveryCodeAction
) {
  const { request, failure, fulfill } = checkPasswordRecoveryCodeActions;

  try {
    yield put(request());
    const { data } = yield call(
      AUTH_API.checkPasswordRecoveryCode,
      action.payload
    );
    yield put(recoveryProcessActions({ recoveryProcess: "password" }));
    yield put(setUserOptionsActions.success({ data }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* checkPasswordRecoveryCodeWatcher() {
  yield takeLatest(
    checkPasswordRecoveryCodeActions.trigger,
    checkPasswordRecoveryCodeWorker
  );
}
