import "./index.scss";
import { FC } from "react";
import classNames from "classnames";
import { VacancyApplyStatus } from "@/common/types/models/vacancies/apply";
import { VACANCY_APPLY_STATUS_TITLES } from "@/common/constants/statuses/apply-statuses";

export type IProps = {
  status?: VacancyApplyStatus;
};

export const ApplyStatusTitle: FC<IProps> = ({ status }) => {
  if (!status) return null;

  return (
    <p
      className={classNames("apply-status-title", {
        [status?.toLowerCase()]: status,
      })}
    >
      {VACANCY_APPLY_STATUS_TITLES[status]}
    </p>
  );
};
