import { getDriverPath } from "@/common/features/drivers-common/helpers/get-driver-path";
import { useAdminPathname } from "@/common/hooks/router/use-admin-pathname";
import {
  OrganizationApply,
  VacancyApplyStatus,
} from "@/common/types/models/vacancies/apply";
import { getUserFullName } from "@/common/utils/helpers/string/get-driver-full-name";

type IProps = {
  apply: OrganizationApply;
  onApplyConfirm: (applyId: number | undefined) => void;
  onApplyRefuse: (applyId: number | undefined) => void;
};

export const useApply = ({ apply, onApplyConfirm, onApplyRefuse }: IProps) => {
  const onApplyConfirmClick = () => onApplyConfirm(apply?.ID);
  const onApplyRefuseClick = () => onApplyRefuse(apply?.ID);
  const userAvatar = apply?.User?.Avatar?.FileUrl;
  const disableConfirm = apply?.State?.Alias === VacancyApplyStatus.Confirm;
  const disableRefuse = apply?.State?.Alias === VacancyApplyStatus.Refuse;
  const driverFullName = getUserFullName({
    surname: apply?.User?.Driver?.Surname,
    name: apply?.User?.Driver?.Name,
    patronymic: apply?.User?.Driver?.Patronymic,
  });

  const isAdminPanel = useAdminPathname();
  const driverPath = getDriverPath({
    driverID: apply?.User?.Driver?.ID,
    isAdminPanel,
  });

  return {
    userAvatar,
    disableConfirm,
    disableRefuse,
    driverFullName,
    driverPath,
    onApplyRefuseClick,
    onApplyConfirmClick,
  };
};
