import { StringifyOptions, stringifyUrl } from "query-string";

export const getStringifierUrl = (
  url: string,
  query?: Record<
    string,
    string | number | number[] | boolean | null | undefined
  >,
  options?: StringifyOptions | undefined
) => {
  return stringifyUrl({ url, query }, { skipNull: true, ...options });
};
