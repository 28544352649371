import { ApiGet } from "@/common/api/helpers/get";
import { ApiPost } from "@/common/api/helpers/post";
import { ApiPatch } from "@/common/api/helpers/patch";
import { ApiDelete } from "@/common/api/helpers/delete";
import { EMPTY_STRING } from "@/common/constants/general";
import {
  QuestionnaireOperation,
  QuestionnaireType,
} from "@/common/api/types/questionnaire";
import { QuestionnaireProps } from "@/common/features/questionnaire-common/types/store";
import { getQuestionnaireMethod } from "@/common/api/helpers/get-questionnaire-method";

export const OPERATION_ENDPOINTS_WITH_METHOD = [
  QuestionnaireOperation.save,
  QuestionnaireOperation.makeAvailable,
  QuestionnaireOperation.makeUnavailable,
  QuestionnaireOperation.saveResult,
];

export const OPERATION_RESULTS_ENDPOINTS = [
  QuestionnaireOperation.getResults,
  QuestionnaireOperation.deleteResult,
];

export const getQuestionnaireEndpoint = (
  method: QuestionnaireOperation,
  resultID?: number
) => {
  const endpoint = `/questionnaire`;
  const addMethodToEndpoint = getQuestionnaireMethod(method);

  return `${endpoint}${
    addMethodToEndpoint ? `/${addMethodToEndpoint}` : EMPTY_STRING
  }${resultID ? `/${resultID}` : EMPTY_STRING}`;
};

export function questionnaireOperation({
  operation,
  body,
  resultID,
}: QuestionnaireProps) {
  const questionnaireEndpoint = getQuestionnaireEndpoint(operation, resultID);

  switch (operation) {
    case QuestionnaireOperation.get: // получить шаблон анкеты
      return ApiGet<QuestionnaireType>(questionnaireEndpoint);
    case QuestionnaireOperation.save: // сохранить шаблон анкеты
      return ApiPost<QuestionnaireType>(questionnaireEndpoint, body);
    case QuestionnaireOperation.delete: // удалить шаблон анкеты
      return ApiDelete<QuestionnaireType>(questionnaireEndpoint);
    case QuestionnaireOperation.makeAvailable: // сделать шаблон анкеты доступным для организаций
      return ApiPatch(questionnaireEndpoint);
    case QuestionnaireOperation.makeUnavailable: // сделать шаблон анкеты НЕ доступным для организаций
      return ApiPatch(questionnaireEndpoint);
    case QuestionnaireOperation.saveResult: // сохранить результаты анкеты организации
      return ApiPost<QuestionnaireType>(questionnaireEndpoint, body);
    case QuestionnaireOperation.deleteResult: // удалить результаты анкеты организации
      return ApiDelete<QuestionnaireType>(questionnaireEndpoint);

    default:
      return ApiGet(questionnaireEndpoint);
  }
}

export const QUESTIONNAIRE_API = {
  questionnaireOperation,
};
