import { useEffect } from "react";
import { VacanciesDriverTabs } from "@/modules/social-services/children/vacancies/types/tabs";
import { useGetVacancies } from "@/common/features/vacancies-common/hooks/use-get-vacancies";
import { useChangeVacanciesFilters } from "@/common/features/vacancies-common/hooks/use-vacancies-filters";
import { useVacanciesSidebarForm } from "@/common/features/vacancies-common/hooks/use-vacancies-sidebar-form";
import { useVacanciesToolbarForm } from "@/common/features/vacancies-common/hooks/use-vacancies-toolbar-form";
import { useAccount } from "@/common/hooks/use-account";
import { useOrganizationEmployeePermissions } from "@/common/hooks/permissions/use-organization-employee-permissions";
import { useVacanciesSort } from "@/common/features/vacancies-common/hooks/use-vacancies-sort";

type IProps = {
  showIndexModule: boolean;
  vacanciesDriverTab?: VacanciesDriverTabs;
};

export const useVacancies = ({
  showIndexModule,
  vacanciesDriverTab,
}: IProps) => {
  const { account } = useAccount();
  const accountID = account?.ID;

  const { isOrganizationEmployee } = useOrganizationEmployeePermissions();

  const { filters, changeFilters, resetFilters } = useChangeVacanciesFilters();
  const { vacancies, loading } = useGetVacancies(filters);

  /** Фильтры в Toolbar */
  const {
    toolbarForm,
    showStatusFormItem,
    onToolbarFormChange,
    resetToolbarForm,
  } = useVacanciesToolbarForm({
    accountID,
    changeFilters,
  });

  /** Фильтры в Sidebar */
  const {
    sidebarForm,
    sidebarRefData,
    onSidebarFormChange,
    resetSidebarForm,
    onClickResetSidebarForm,
  } = useVacanciesSidebarForm({
    changeFilters,
  });

  /** Сортировка */
  const { activeSortKey, onSortClick } = useVacanciesSort({
    filters,
    changeFilters,
  });

  useEffect(() => {
    if (
      showIndexModule &&
      vacanciesDriverTab === VacanciesDriverTabs.Vacancies
    ) {
      resetToolbarForm();
      resetSidebarForm();
      resetFilters({ isOrganizationEmployee, accountID });
    }
  }, [showIndexModule, vacanciesDriverTab]);

  return {
    vacancies,
    loading,
    toolbarForm,
    sidebarForm,
    sidebarRefData,
    showStatusFormItem,
    activeSortKey,
    onToolbarFormChange,
    onSidebarFormChange,
    onClickResetSidebarForm,
    onSortClick,
  };
};
