import { call, put, select, takeLatest } from "redux-saga/effects";
import { checkCodeActions } from "@modules/social-services/children/my-info/actions";
import { CheckCodePayloadAction } from "@/common/api/types/code";
import { User } from "@/common/types/models/user/user";
import { CODE_API } from "@/common/api/endpoints/code";
import { showNotificationError } from "@/common/helpers/errors/show-notification-error";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";
import { authUserSetActions } from "@/modules/auth/actions";
import { currentUserSelector } from "@/modules/auth/selectors/current-user";

export function* checkCodeWorker(action: CheckCodePayloadAction) {
  const { request, success, failure, fulfill } = checkCodeActions;

  try {
    const { Code } = action.payload;
    yield put(request());

    yield call(CODE_API.getCodeCheck, {
      Code,
    });

    yield put(success());

    /** Обновляем данные текущего пользователя */
    const user: User = yield select(currentUserSelector);
    yield put(
      authUserSetActions.success({
        user: {
          ...user,
          PhoneVerified: true,
        },
      })
    );

    yield call(showSuccessNotification, "Номер телефона подтвержден!");
  } catch (error) {
    yield call(showNotificationError, "Неверный код.");
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* checkCodeWatcher() {
  yield takeLatest(checkCodeActions.trigger, checkCodeWorker);
}
