import { FC, PropsWithChildren } from "react";
import { Form, FormProps } from "antd";
import { FormInstance } from "antd/lib";
import classNames from "classnames";

interface IProps extends PropsWithChildren, Omit<FormProps, "children"> {
  /** Отвечает за адаптивность,
   * если передан false, добавится класс, отвечающий за сокрытие
   * компонента на medium/small экранах */
  hide?: boolean;
  className?: string;
  form?: FormInstance<any>;
}

export const SocialServicesFilters: FC<IProps> = ({
  hide,
  children,
  className,
  form,
  ...props
}) => {
  return (
    <Form
      {...props}
      form={form}
      layout="vertical"
      className={classNames("social-services__filters", className, {
        "hide-large": hide,
      })}
    >
      {children}
    </Form>
  );
};
