import { FC } from "react";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { MyInfoFormLicenseCategories } from "@/modules/social-services/children/my-info/components/license-categories";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import {
  DRIVER_DRIVING_FIELDS,
  DRIVER_HAS_DRIVER_LICENSE_FIELD,
} from "@/common/features/drivers-common/constants/driver-form";
import { RefDataType } from "@/common/features/dictionaries/types";

type IProps = {
  refData: RefDataType;
  selectedCategories: Array<number>;
  showDriverLicenseField: boolean;
  handleCategoriesChange: (category: number) => void;
};

export const MyInfoFormDriverData: FC<IProps> = ({
  refData,
  showDriverLicenseField,
  selectedCategories,
  handleCategoriesChange,
}) => {
  return (
    <div className="my-info__group">
      {renderFormItems({ fields: DRIVER_HAS_DRIVER_LICENSE_FIELD })}

      {showDriverLicenseField &&
        renderFormItems({ fields: DRIVER_DRIVING_FIELDS })}

      <MyInfoFormLicenseCategories
        categoriesOptions={refData.LicenseCategory?.options || EMPTY_ARRAY_DATA}
        selectedCategories={selectedCategories}
        handleCategoriesChange={handleCategoriesChange}
      />
    </div>
  );
};
