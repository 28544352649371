import { OperationsEndpoint } from "@/common/api/constants/operations";
import { USER_API } from "@/common/api/endpoints/user";

export const getUserApiEndpoint = (operation: OperationsEndpoint) => {
  switch (operation) {
    case OperationsEndpoint.GetAll:
      return USER_API.getUsers;
    case OperationsEndpoint.Update:
      return USER_API.update;
    case OperationsEndpoint.Delete:
      return USER_API.delete;
    default:
      return USER_API.getUsers;
  }
};
