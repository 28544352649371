import "./index.scss";
import { FC } from "react";
import classNames from "classnames";
import { Form, FormItemProps } from "antd";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FORM_RULES } from "@/common/ui-components/data-entry/form/constants/rules";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

const { Item } = Form;

export interface IILSFormItemProps extends FormItemProps {
  field?: FormFieldName;
}

export const ILSFormItem: FC<IILSFormItemProps> = ({
  field = FormFieldName.Default,
  className,
  name,
  ...props
}) => {
  return (
    <Item
      label={FORM_LABEL[field]}
      rules={FORM_RULES[field]}
      name={[name || field]}
      {...props}
      className={classNames(className, "ils-form-item")}
    />
  );
};
