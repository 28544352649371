import { isEmpty } from "lodash";
import { Driver, DriverInfo } from "@/common/types/models/user/driver";

/**
 * Проверяем, является ли водитель подтвержденным участником организации "Водители России"
 */

export const checkDriverMembership = (
  driver: Driver | DriverInfo | null | undefined
): boolean => {
  if (!isEmpty(driver) && "PublicOrganizationMembership" in driver) {
    return Boolean(
      driver?.PublicOrganizationMembership && driver?.MembershipChecked
    );
  }

  return false;
};
