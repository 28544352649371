import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { ORGANIZATIONS_FILTERS_INITIAL_VALUES } from "@/modules/admin-panel/children/admin-organizations/constants/organizations-filters";
import { AdminOrganizationsFiltersType } from "@/modules/admin-panel/children/admin-organizations/types/organizations-filters";
import { OrganizationsFiltersRequest } from "@/common/api/types/organization";
import { useAdminOrganizationsOperations } from "@/modules/admin-panel/children/admin-organizations/hooks/use-admin-organizations-operations";
import {
  adminOrganizationsFetchingSelector,
  adminOrganizationsSelector,
} from "@/modules/admin-panel/children/admin-organizations/selectors/organizations";

export const useAdminOrganizations = () => {
  const [filters, setFilters] =
    useState<OrganizationsFiltersRequest>(EMPTY_OBJECT_DATA);

  const [form] = useForm<AdminOrganizationsFiltersType>();
  const onValuesChange = (changedValues: AdminOrganizationsFiltersType) => {
    setFilters({
      ...changedValues,
      WaitingForPartnershipCheck: changedValues.WaitingForPartnershipCheck
        ? Number(changedValues.WaitingForPartnershipCheck)
        : null,
    });
  };

  const loading = useSelector(adminOrganizationsFetchingSelector);
  const organizations = useSelector(adminOrganizationsSelector);
  const { handleGetAllOrganizations } = useAdminOrganizationsOperations();

  const resetFilters = () => {
    setFilters(EMPTY_OBJECT_DATA);
    form.resetFields();
    form.setFieldsValue(ORGANIZATIONS_FILTERS_INITIAL_VALUES);
  };

  useEffect(() => {
    handleGetAllOrganizations({ filters });
  }, [filters]);

  return { organizations, loading, form, onValuesChange, resetFilters };
};
