import { Modal } from "antd";
import { useMyInfoAccountContainer } from "@/modules/social-services/children/my-info/hooks/containers/use-my-info-account-container";
import { AccountInfoForm } from "@/modules/social-services/children/my-info/components/forms/account";
import { ILSButton } from "@/common/components/data-display/buttons";
import { useModal } from "@/common/hooks/use-modal";

export const MyInfoAccountFormContainer = () => {
  const {
    form,
    initialValues,
    fileList,
    disabled,
    enableSubmit,
    handleSubmit,
    handleCancelChange,
    handleAvatarChange,
    handleAvatarRemove,
    handleDeleteUser,
  } = useMyInfoAccountContainer();
  const { showModal, isModalOpen } = useModal();

  return (
    <>
      <AccountInfoForm
        form={form}
        initialValues={initialValues}
        fileList={fileList}
        disabled={disabled}
        enableSubmit={enableSubmit}
        handleSubmit={handleSubmit}
        handleCancelChange={handleCancelChange}
        handleAvatarChange={handleAvatarChange}
        handleAvatarRemove={handleAvatarRemove}
      />
      <Modal
        visible={isModalOpen}
        width={1024}
        title="Удаление текущего аккаунта"
        onCancel={showModal}
        onOk={handleDeleteUser}
        children={<>Вы уверены, что хотите удалить аккаунт?</>}
      />
      <ILSButton onClick={showModal} danger>
        Удалить аккаунт
      </ILSButton>
    </>
  );
};
