import { FC } from "react";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { RefDataType } from "@/common/features/dictionaries/types";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import {
  DRIVER_AVATAR_JOB_SEARCH_FIELDS,
  DRIVER_READY_START_TOMORROW,
} from "@/common/features/drivers-common/constants/driver-form";
import { Salary } from "@/common/components/data-entry/form-item/salary";
import { DriverMembershipCheckbox } from "@/modules/social-services/children/my-info/components/forms/membership-checkbox";
import { FORM_RULES } from "@/common/ui-components/data-entry/form/constants/rules";

type IProps = {
  refData: RefDataType;
  showReadyToStartTomorrow: boolean;
  membershipChecked: boolean | null | undefined;
};

export const MyInfoFormJobData: FC<IProps> = ({
  refData,
  showReadyToStartTomorrow,
  membershipChecked,
}) => {
  return (
    <div className="my-info__group">
      {renderFormItems({ fields: DRIVER_AVATAR_JOB_SEARCH_FIELDS, refData })}
      {showReadyToStartTomorrow
        ? renderFormItems({ fields: DRIVER_READY_START_TOMORROW })
        : null}
      <Salary
        rules={FORM_RULES.DesiredSalary}
        label={FORM_LABEL.DesiredSalary}
      />
      <DriverMembershipCheckbox membershipChecked={membershipChecked} />
    </div>
  );
};
