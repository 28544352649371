import { FC } from "react";
import { ILSWideCard } from "@/common/ui-components/data-display/wide-card";
import { OrganizationInfo } from "@/common/types/models/account";
import { useAdminOrganizationCard } from "@/modules/admin-panel/children/admin-organizations/hooks/use-admin-organization-card";

type IProps = {
  organization: OrganizationInfo;
};

export const AdminOrganizationCard: FC<IProps> = ({ organization }) => {
  const { info, cover, ratings, redirectID } =
    useAdminOrganizationCard(organization);

  return (
    <ILSWideCard
      info={info}
      cover={cover}
      ratings={ratings}
      redirectID={redirectID}
    />
  );
};
