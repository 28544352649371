import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { REGISTER_FORM_NAME } from "@/modules/auth/children/register/constants/form";

export const useAuthButton = (formName: string | undefined) => {
  const form = useFormInstance();

  const consentedToDataProcessing = useWatch(
    FormFieldName.ConsentToDataProcessing,
    form
  );

  const disabled =
    formName === REGISTER_FORM_NAME ? !consentedToDataProcessing : false;
  return { disabled };
};
