import { createBrowserRouter } from "react-router-dom";
import { getRouterObjects } from "./get-router-objects";
import { App } from "@/App";
import { NotFoundPage } from "@/pages/not-found-page";
import { getRoutes } from "@/core/routes/get-routes";
import { TModulesObj } from "@/common/types/core/module";

export const getRouter = (modules: TModulesObj) => {
  const routes = getRoutes(modules);

  const appRoutes = [
    {
      path: "/",
      element: <App />,
      children: getRouterObjects(routes),
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ];

  return createBrowserRouter(appRoutes);
};
