import { FC } from "react";
import { ILSDivider } from "@/common/components/layout/divider";
import { DRIVER_PERSONAL_FIELDS } from "@/common/features/drivers-common/constants/driver-form";
import { RefDataType } from "@/common/features/dictionaries/types";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  refData: RefDataType;
};

export const AdminEditDriverPersonalSubform: FC<IProps> = ({ refData }) => {
  return (
    <>
      <ILSDivider plain className="admin-edit-driver__divider">
        Данные водителя
      </ILSDivider>
      {renderFormItems({
        fields: DRIVER_PERSONAL_FIELDS,
        refData,
      })}
    </>
  );
};
