import { notification } from "antd";

export const showUserDevErrors = ({
  e,
  message = "Что-то пошло не так...",
}: {
  e: unknown;
  message?: string;
}) => {
  console.error(e);

  notification.error({ message });
};
