import { useDispatch } from "react-redux";
import { useAbac } from "react-abac";
import { useNavigate } from "react-router-dom";
import { logoutActions } from "@modules/auth/actions";
import { useAccount } from "@/common/hooks/use-account";
import { usePopover } from "@/common/hooks/use-popover";
import { AbacPermission } from "@/common/types/core/abac";
import { getUserProfileDetails } from "@/common/helpers/get-user-profile-details";
import { ModulePath } from "@/common/types/core/routes";

export const useHeaderProfileMenu = () => {
  const dispatch = useDispatch();
  const { user } = useAccount();
  const { userHasPermissions } = useAbac();
  const organizationAndEmployee = userHasPermissions(
    AbacPermission.organizationAndEmployee
  );

  const userProfileDetails = getUserProfileDetails(
    user,
    organizationAndEmployee
  );

  const logout = () => {
    dispatch(logoutActions());
  };

  const { open, handleOpenChange } = usePopover();

  const onCloseMenu = () => handleOpenChange(false);

  const navigate = useNavigate();
  const onLogoutButtonClick = () => {
    logout();
    onCloseMenu();
    navigate(ModulePath.startPage);
  };

  const myInfoTitle = organizationAndEmployee
    ? "Моя организация"
    : "Мои данные";

  return {
    userProfileDetails,
    openMenu: open,
    myInfoTitle,
    handleOpenMenuChange: handleOpenChange,
    onLogoutButtonClick,
    onCloseMenu,
  };
};
