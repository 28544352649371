import { call, put, takeEvery } from "redux-saga/effects";
import { getDictionaryRoutine } from "@/common/features/dictionaries/actions";
import { GetDictionaryPayload } from "@/common/features/dictionaries/types";
import { DICTIONARY_API } from "@/common/api/endpoints/dictionary";

export function* DictionaryWorker(action: GetDictionaryPayload) {
  const { request, success, failure, fulfill } = getDictionaryRoutine;
  const dictionary = action.payload;

  try {
    yield put(request());
    const { data } = yield call(DICTIONARY_API.getDictionary, dictionary);

    yield put(success({ data, dictionary }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* dictionaryWatcher() {
  yield takeEvery(getDictionaryRoutine.trigger, DictionaryWorker);
}
