import { ApiGet } from "@/common/api/helpers/get";
import { GetDictionaryResponse } from "@/common/api/types/dictionary";
import { Dictionaries } from "@/common/types/models/dictionary";

export const getDictionaryEndpoint = (method?: string) => {
  const endpoint = `/dictionary`;
  if (!method) return endpoint;
  return `${endpoint}/${method}`;
};

export const DICTIONARY_API = {
  getDictionary: (dictionaryName?: Dictionaries) => {
    return ApiGet<GetDictionaryResponse>(getDictionaryEndpoint(dictionaryName));
  },
};
