import { FC } from "react";
import { Driver } from "@/common/types/models/user/driver";
import { formatPriceString } from "@/common/utils/helpers/price/format-price-string";
import { ILSTitle } from "@/common/ui-components/general/typography/title";

type IProps = {
  driver: Driver | undefined | null;
};

export const DriverDetailsSalary: FC<IProps> = ({ driver }) => {
  const salaryFrom = driver?.SalaryFrom;
  const salaryTo = driver?.SalaryTo;

  return (
    <div>
      <ILSTitle level={4} className="driver-details__salary-title">
        Желаемая зарплата:
      </ILSTitle>
      {salaryFrom || salaryTo ? (
        <div className="driver-details__salaries">
          <p className="driver-details__salary">
            от {formatPriceString(salaryFrom)} ₽
          </p>
          {salaryTo && (
            <p className="driver-details__salary">
              до {formatPriceString(salaryTo)} ₽
            </p>
          )}
        </div>
      ) : (
        <p className="driver-details__no-salary">Зарплата не указана</p>
      )}
    </div>
  );
};
