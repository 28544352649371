import { isEmpty } from "lodash";
import { createSelector } from "reselect";
import {
  DriversModuleStore,
  DriverStoreType,
} from "@/common/features/drivers-common/types/store";
import { Driver, DriverInfo } from "@/common/types/models/user/driver";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { IndexedArray } from "@/common/types/general/enum";
import { getObjectValuesWithType } from "@/common/utils/helpers/types";

export const driversSelector = createSelector(
  (state: DriversModuleStore) => {
    return state.DriversCommonModule?.drivers;
  },
  (drivers: IndexedArray<DriverStoreType>) =>
    getObjectValuesWithType<DriverInfo>(drivers) ||
    (EMPTY_ARRAY_DATA as DriverInfo[])
);

export const driverSelector = (driverID: string | number | undefined) =>
  createSelector(
    (state: DriversModuleStore) => state.DriversCommonModule?.drivers,
    (drivers: IndexedArray<DriverStoreType>) => {
      return driverID && !isEmpty(drivers)
        ? (drivers[driverID] as Driver)
        : null;
    }
  );

export const driversFetchingSelector = createSelector(
  (state: DriversModuleStore) => {
    return state.DriversCommonModule?.isFetching;
  },
  (isFetching: boolean) => isFetching
);
