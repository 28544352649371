export const DriverAppliesListHeader = () => {
  return (
    <div className="driver-applies__list-header hide-medium">
      <p>№</p>
      <p>Статус</p>
      <p>Вакансия</p>
      <p>Дата отклика</p>
    </div>
  );
};
