import { useState } from "react";
import { useOrganizationPermissions } from "@/common/hooks/use-organization-account";
import { useAuth } from "@/common/hooks/use-auth";
import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";
import { useCheckUser } from "@/common/hooks/use-check-user";

export const useMyInfoTabs = () => {
  const { authenticated } = useAuth();
  useCheckUser(authenticated);

  const { isOrganization } = useOrganizationPermissions();
  const [activeTab, setActiveTab] = useState<MyInfoTabs>(
    MyInfoTabs.MyOrganization
  );

  const handleChangeTab = (key: string) => setActiveTab(key as MyInfoTabs);

  return { isOrganization, activeTab, handleChangeTab };
};
