import { FC } from "react";
import { Flex } from "antd";
import { ILSButton } from "@/common/components/data-display/buttons";

interface IProps {
  showArticleEditorModal: VoidFunction;
}

export const ArticleEditorModalFooter: FC<IProps> = ({
  showArticleEditorModal,
}) => {
  return (
    <Flex gap={10}>
      <ILSButton type="primary" htmlType="submit" form="article">
        Сохранить
      </ILSButton>
      <ILSButton type="primary" onClick={showArticleEditorModal}>
        Отменить
      </ILSButton>
    </Flex>
  );
};
