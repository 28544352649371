import { useState, useEffect } from "react";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { PartnershipCheckStatus } from "@/common/types/models/account";
import { checkWaitingPartnership } from "@/common/features/organizations-common/helpers/check-waiting-partnership";

export const useAdminEditPartnership = () => {
  const form = useFormInstance();
  const publicOrganizationPartnership = useWatch(
    FormFieldName.PublicOrganizationPartnership,
    form
  );
  const partnershipChecked = useWatch(FormFieldName.PartnershipChecked, form);

  /** ожидает ли организация проверку */
  const waitingPartnershipCheck = checkWaitingPartnership({
    publicOrganizationPartnership,
    partnershipChecked,
  });

  const [showWaitingCheckAlert, setShowWaitingCheckAlert] = useState(
    waitingPartnershipCheck
  );

  useEffect(() => {
    /** Если администратор отклонил статус, то сбрасываем поле "Партнер" */
    if (
      publicOrganizationPartnership &&
      partnershipChecked === PartnershipCheckStatus.Cancelled
    ) {
      form.setFieldValue(FormFieldName.PublicOrganizationPartnership, false);
    }

    /** Если администратор принял статус, то устанавливаем поле "Партнер", если пустое */
    if (
      !publicOrganizationPartnership &&
      partnershipChecked === PartnershipCheckStatus.Confirmed
    ) {
      form.setFieldValue(FormFieldName.PublicOrganizationPartnership, true);
    }

    /** Если устанавливается чекбокс "Участник организации",
     * и нет проверки, показываем уведомление, что необходима проверка */
    setShowWaitingCheckAlert(
      checkWaitingPartnership({
        publicOrganizationPartnership,
        partnershipChecked,
      })
    );
  }, [partnershipChecked, publicOrganizationPartnership]);

  return { showWaitingCheckAlert };
};
