import { LaptopOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { AdminOrganizationContainer } from "@/modules/admin-panel/children/admin-organizations/children/organization/containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";

const module: IModuleModel = {
  title: ModuleTitle.adminOrganization,
  name: ModuleName.adminOrganization,
  path: ModulePath.adminOrganization,
  icon: <LaptopOutlined />,
  element: <AdminOrganizationContainer />,
  persist: false,
};

export const AdminOrganizationModule = defineModule(module);
