import { FC } from "react";
import { Modal } from "antd";
import { EMPTY_STRING } from "@/common/constants/general";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { StartPageContestType } from "@/modules/start/types";

type IProps = {
  selectedContest: StartPageContestType | undefined;
  isModalOpen: boolean;
  showModal: VoidFunction;
};

export const StartPageContestModal: FC<IProps> = ({
  selectedContest,
  isModalOpen,
  showModal,
}) => {
  return (
    <Modal
      footer={null}
      visible={isModalOpen}
      width={1024}
      className="start-page__contests-modal"
      title={
        <ILSTitle
          level={2}
          ellipsis={{ rows: 4 }}
          className="start-page__contests-modal-title"
        >
          {selectedContest?.title}
        </ILSTitle>
      }
      onCancel={showModal}
      children={
        <div className="start-page__contests-modal-content">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: selectedContest?.description || EMPTY_STRING,
            }}
          />
        </div>
      }
    />
  );
};
