import { AxiosResponse } from "axios";
import { ApiGet } from "@/common/api/helpers/get";
import { ApiPost } from "@/common/api/helpers/post";
import { EMPTY_STRING } from "@/common/constants/general";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import {
  VacanciesFilters,
  VacanciesResponse,
  VacancyAppliesResponse,
  VacancyBaseRequest,
  VacancyResponse,
} from "@/common/features/vacancies-common/types/api";
import { ApiPatch } from "@/common/api/helpers/patch";
import { ApiDelete } from "@/common/api/helpers/delete";
import { getStringifierUrl } from "@/common/api/helpers/stringify-url";

type VacanciesEndpointType = {
  id?: number;
  filters?: VacanciesFilters | undefined;
  method: OperationsEndpoint;
};

const OPERATION_ENDPOINTS_WITH_METHOD = [
  OperationsEndpoint.SetStatus,
  OperationsEndpoint.ApplyList,
  OperationsEndpoint.Apply,
  OperationsEndpoint.Cancel,
  OperationsEndpoint.Confirm,
  OperationsEndpoint.Refuse,
];

export const OPERATION_APPLY_ENDPOINTS = [
  OperationsEndpoint.Confirm,
  OperationsEndpoint.Cancel,
  OperationsEndpoint.Refuse,
];

export const OPERATION_APPLY_ENDPOINTS_WITH_LIST = [
  ...OPERATION_APPLY_ENDPOINTS,
  OperationsEndpoint.ApplyList,
];

export const getVacanciesEndpoint = ({
  id,
  filters,
  method,
}: VacanciesEndpointType) => {
  const addMethodToEndpoint = OPERATION_ENDPOINTS_WITH_METHOD.includes(method);
  const baseEndpoint = !OPERATION_APPLY_ENDPOINTS.includes(method)
    ? "vacancy"
    : "vacancyApply";

  const endpoint = `/${baseEndpoint}${id ? `/${id}` : EMPTY_STRING}${
    addMethodToEndpoint ? `/${method}` : EMPTY_STRING
  }`;

  return getStringifierUrl(endpoint, filters, { arrayFormat: "comma" });
};

export function vacanciesOperation({
  operation,
  body,
  id,
  filters,
}: VacancyBaseRequest): Promise<
  AxiosResponse<VacanciesResponse | VacancyResponse | VacancyAppliesResponse>
> {
  const vacancyEndpoint = getVacanciesEndpoint({
    id,
    filters,
    method: operation,
  });

  switch (operation) {
    case OperationsEndpoint.GetAll:
      return ApiGet(vacancyEndpoint);
    case OperationsEndpoint.Get:
      return ApiGet(vacancyEndpoint);
    case OperationsEndpoint.Create:
      return ApiPost(vacancyEndpoint, body);
    case OperationsEndpoint.Update:
      return ApiPatch(vacancyEndpoint, body);
    case OperationsEndpoint.Delete:
      return ApiDelete(vacancyEndpoint);
    case OperationsEndpoint.SetStatus:
      return ApiPost(vacancyEndpoint, body);
    case OperationsEndpoint.ApplyList: // получить список откликов по вакансии
      return ApiGet(vacancyEndpoint);
    case OperationsEndpoint.Apply: // водитель откликнулся на вакансию
      return ApiPost(vacancyEndpoint);
    case OperationsEndpoint.Cancel: // - водитель отказался от отклика на вакансию (статус записи в БД - удалена)
      return ApiPost(vacancyEndpoint);
    case OperationsEndpoint.Confirm: // - организация приглашает водителя
      return ApiPost(vacancyEndpoint);
    case OperationsEndpoint.Refuse: // - организация отказала водителю
      return ApiPost(vacancyEndpoint);
    default:
      return ApiPost(vacancyEndpoint);
  }
}

export const VACANCIES_API = {
  vacanciesOperation,
};
