import { FC } from "react";
import { Empty } from "antd";
import { isEmpty } from "lodash";
import { ILSSpin } from "@/common/components/data-display/spin";
import { OrganizationInfo } from "@/common/types/models/account";
import { AdminOrganizationCard } from "@/modules/admin-panel/children/admin-organizations/components/card/organization-card";

type IProps = {
  organizations: Array<OrganizationInfo>;
  loading: boolean;
};

export const AdminOrganizationsList: FC<IProps> = ({
  organizations,
  loading,
}) => {
  return (
    <ul className="admin-vacancies__list">
      <ILSSpin spinning={loading} tip="Загрузка" size="large">
        {isEmpty(organizations) ? (
          <Empty description="Нет активных вакансий" />
        ) : (
          organizations?.map((organization) => {
            return (
              <AdminOrganizationCard
                organization={organization}
                key={organization.ID}
              />
            );
          })
        )}
      </ILSSpin>
    </ul>
  );
};
