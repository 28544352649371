import { FC } from "react";
import { Flex, Modal } from "antd";
import { AddReviewForm } from "@modules/social-services/children/driver-search/containers/add-review-form";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { useModal } from "@/common/hooks/use-modal";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { AbacPermission } from "@/common/types/core/abac";
import { useIcons } from "@/common/hooks/use-icons";

interface IProps {}

export const DriverReview: FC<IProps> = () => {
  const { isModalOpen, showModal } = useModal();
  const { ConstructIcon } = useIcons({ width: "30", height: "30" });

  return (
    <ILSAllowedTo perform={AbacPermission.organizationAndEmployee}>
      <ILSButton
        type="primary"
        children="+ Добавить отзыв"
        onClick={showModal}
      />
      <Modal
        width="fit-content"
        open={isModalOpen}
        title={
          <ILSTitle level={5}>
            <Flex align="center" gap="1em">
              Раздел в разработке
              <ConstructIcon />
            </Flex>
          </ILSTitle>
        }
        // todo отключаем кнопки без API okText="Сохранить"
        okText="Выйти"
        onOk={showModal}
        onCancel={showModal}
        okButtonProps={{ disabled: true }}
      >
        <AddReviewForm />
      </Modal>
    </ILSAllowedTo>
  );
};
