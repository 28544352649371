import { Link } from "react-router-dom";
import { ILSImage } from "@/common/components/data-display/image";

export const ILSHeaderLogo = () => {
  return (
    <Link to="/" className="header-component__logo">
      <ILSImage
        width={180}
        height={56}
        preview={false}
        src={`${process.env.PUBLIC_URL}/assets/images/start-page/logo/logo.png`}
      />
    </Link>
  );
};
