import { FC } from "react";
import { Popconfirm, Tooltip } from "antd";
import {
  IILSButtonProps,
  ILSButton,
} from "@/common/components/data-display/buttons";

export type IILSButtonConfirmTooltipProps = IILSButtonProps & {
  confirmDescription?: string;
  onConfirm?: VoidFunction;
};

export const ILSButtonConfirmTooltip: FC<IILSButtonConfirmTooltipProps> = ({
  confirmDescription,
  onConfirm,
  onClick,
  ...props
}) => {
  if (confirmDescription && onConfirm) {
    return (
      <Popconfirm
        title="Внимание!"
        description={confirmDescription}
        okText="Да"
        cancelText="Нет"
        onConfirm={onConfirm}
      >
        <Tooltip title={props.title}>
          <ILSButton {...props} />
        </Tooltip>
      </Popconfirm>
    );
  }
  return <ILSButton {...props} onClick={onClick || onConfirm} />;
};
