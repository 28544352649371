import {
  VacanciesTabStatus,
  VacancyStatus,
} from "@/common/types/models/vacancies/status";
import { VacanciesSource } from "@/common/features/vacancies-common/types/filters";
import { getSelectOptions } from "@/common/utils/helpers/components/select/get-select-options";

export const VACANCY_STATUS_TITLES = {
  [VacancyStatus.Draft]: "Черновик",
  [VacancyStatus.InModeration]: "На модерации",
  [VacancyStatus.Rejected]: "Отклонена",
  [VacancyStatus.Published]: "Опубликована",
};

export const VACANCY_STATUS_TAG_COLORS = {
  [VacancyStatus.Draft]: "gold",
  [VacancyStatus.InModeration]: "blue",
  [VacancyStatus.Rejected]: "red",
  [VacancyStatus.Published]: "green",
};

/** Для табов и фильтров по вакансиям */
export const VACANCIES_TAB_STATUS_TITLES = {
  [VacanciesTabStatus.All]: "Все",
  [VacanciesTabStatus.Draft]: "Черновик",
  [VacanciesTabStatus.InModeration]: "На модерации",
  [VacanciesTabStatus.Rejected]: "Отклоненные",
  [VacanciesTabStatus.Published]: "Опубликованные",
};

export const VACANCIES_STATUS_OPTIONS = getSelectOptions(
  VACANCIES_TAB_STATUS_TITLES
);

export const VACANCIES_SOURCE_TITLES: Record<VacanciesSource, string> = {
  [VacanciesSource.All]: "Все",
  [VacanciesSource.Mine]: "Только мои",
};
export const VACANCIES_SOURCE_OPTIONS = getSelectOptions(
  VACANCIES_SOURCE_TITLES
);
