import "../index.scss";
import { Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import { FC } from "react";
import classNames from "classnames";

const { TextArea } = Input;

export interface IILSTextAreaProps extends TextAreaProps {
  className?: string;
}

export const ILSTextArea: FC<IILSTextAreaProps> = ({ className, ...props }) => {
  return (
    <TextArea
      {...props}
      className={classNames("ils-input ils-input_text-area", className)}
    />
  );
};
