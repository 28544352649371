import { FC } from "react";
import { Space } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { VERIFY_PHONE_BUTTON } from "@/modules/social-services/children/my-info/constants";
import {
  PHONE_IS_NOT_VERIFIED,
  PHONE_IS_VERIFIED,
} from "@/common/features/drivers-common/constants";
import { ILSButton } from "@/common/components/data-display/buttons";
import { useModal } from "@/common/hooks/use-modal";
import { MyInfoVerificationModal } from "@/modules/social-services/children/my-info/components/verification/modal";

type IProps = {
  phoneIsVerified?: boolean;
};

export const MyInfoFormVerification: FC<IProps> = ({ phoneIsVerified }) => {
  const { isModalOpen, showModal } = useModal();

  return (
    <div className="my-info__verification">
      {phoneIsVerified ? (
        <>
          <p className="my-info__profile-completeness-title">
            {PHONE_IS_VERIFIED}
          </p>
          <CheckCircleOutlined />
        </>
      ) : (
        <>
          <Space>
            <ExclamationCircleOutlined className="error" />
            <p className="my-info__profile-completeness-title">
              {PHONE_IS_NOT_VERIFIED}
            </p>
          </Space>
          <ILSButton type="primary" onClick={showModal}>
            {VERIFY_PHONE_BUTTON}
          </ILSButton>
        </>
      )}
      <MyInfoVerificationModal
        isModalOpen={isModalOpen}
        showModal={showModal}
      />
    </div>
  );
};
