import { isEmpty } from "lodash";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { DRIVER_REQUIRED_FIELDS_MESSAGES } from "@/common/features/drivers-common/constants";
import { DriverRequiredFields } from "@/common/features/drivers-common/types/driver-required-fields";
import { getObjectKeysWithType } from "@/common/utils/helpers/types";

/** Функция возвращает список названий обязательных полей, которые незаполненны в текущем аккаунте,
 * напр. ["имя, фамилия", "телефон", "водительское удостоверение"]
 * */

export const getDriverRequiredFieldsMessages = (
  requiredDriverFieldsCondition?: Record<DriverRequiredFields, boolean>
): string[] => {
  if (isEmpty(requiredDriverFieldsCondition)) {
    return EMPTY_ARRAY_DATA;
  }

  const profileIsIncomplete = !Object.values(
    requiredDriverFieldsCondition
  )?.every(Boolean);

  if (!profileIsIncomplete) {
    return EMPTY_ARRAY_DATA;
  }

  return getObjectKeysWithType<DriverRequiredFields>(
    requiredDriverFieldsCondition
  )?.reduce((res, field) => {
    if (!requiredDriverFieldsCondition[field]) {
      res.push(DRIVER_REQUIRED_FIELDS_MESSAGES[field]);
    }
    return res;
  }, [] as string[]);
};
