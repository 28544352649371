import "../index.scss";
import { FC } from "react";
import { ContactsMain } from "@/modules/social-services/children/contacts/containers/main";
import { ContactsPresident } from "@/modules/social-services/children/contacts/containers/president";

interface IProps {}

export const Contacts: FC<IProps> = () => {
  return (
    <div className="contacts">
      <ContactsMain />
      <ContactsPresident />
    </div>
  );
};
