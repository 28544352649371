import { UploadFile } from "antd";
import { DEFAULT_ORGANIZATION_TYPE_ID_VALUE } from "@/common/constants/config/options";
import { OrganizationFormValues } from "@/modules/social-services/children/my-info/types/form";
import { DeletedImageRequest } from "@/common/types/models/image";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";
import { getPartnershipCheckedValue } from "@/modules/social-services/children/my-info/helpers/utils/get-partnership-checked-value";
import { getFormImage } from "@/common/helpers/get-account-avatar";

type IProps = {
  formValues: OrganizationFormValues;
  partnershipChecked: number | null | undefined;
  fileList: UploadFile[] | undefined;
  removedSavedFiles: DeletedImageRequest[];
};

export const formValuesToOrganizationData = async ({
  formValues,
  partnershipChecked,
  fileList,
  removedSavedFiles,
}: IProps) => {
  const Avatar = await getFormImage(fileList, removedSavedFiles);

  const PartnershipChecked = getPartnershipCheckedValue(
    partnershipChecked,
    formValues?.PublicOrganizationPartnership
  );

  return {
    ...formValues,
    Name: formValues?.OrganizationName,
    OrganizationTypeID:
      formValues.OrganizationTypeID || DEFAULT_ORGANIZATION_TYPE_ID_VALUE,
    Phone: getUnmaskedPhone(formValues?.Phone),
    PartnershipChecked,
    Avatar,
  };
};
