import { ILSMembershipBadge } from "@/common/components/custom/membership-badge";

type IProps = {
  hasConfirmedPartnership: boolean;
};

/**
 * функция проверяет, какую иконку или компонент разместить
 * в заголовке карточки вакансии;
 */
export const getOrganizationPartnershipBadge = ({
  hasConfirmedPartnership,
}: IProps) => {
  if (hasConfirmedPartnership) {
    return <ILSMembershipBadge />;
  }

  return null;
};
