import moment from "moment";
import { DateFormat } from "@/common/types/general/date-time";

export const getDateStringFromUnix = (date: number | null | undefined) => {
  if (!date) {
    return null;
  }

  const unixDate = moment.unix(date);
  const isValid = moment(date).isValid();

  if (isValid && unixDate) {
    return unixDate.format(DateFormat.DDMMYYYYPeriod);
  }

  return null;
};
