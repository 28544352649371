import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { VacancyStatus } from "@/common/types/models/vacancies/status";
import { VacancyStatusTag } from "@/common/features/vacancies-common/components/status";

type IProps = {
  status: VacancyStatus | undefined;
};

export const VacancySidebarStatus: FC<IProps> = ({ status }) => {
  if (!status) {
    return null;
  }
  return (
    <div className="vacancy__status-component">
      <div className="vacancy__status-group">
        <ILSTitle level={4} className="vacancy__status-title">
          Статус:
        </ILSTitle>
        <VacancyStatusTag status={status} />
      </div>
    </div>
  );
};
