import { EnhancedStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { Router } from "@remix-run/router/dist/router";
import { getModules } from "./modules/get-modules";
import { getSagaInjector } from "./sagas/get-saga-injector";
import { Modules } from "@/modules";
import { sagaMiddleware } from "./sagas/saga–middleware";
import { createRootReducer } from "./reducer/create-root-reducer";
import { configureRootStore } from "./store";
import { getModulesPersistTransform } from "./persist/transforms";
import { createPersistedStore } from "./persist/create-persisted-store";
import { PERSIST_CONFIG } from "./persist/config";
import { getRouter } from "./routes/get-router";

export interface Registry {
  store: EnhancedStore;
  router: Router;
}

export const run = async () => {
  const modules = getModules(Modules);
  const sagaInjector = getSagaInjector();
  const reducerManager = createRootReducer({
    modules,
  });
  const middleware = [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      immutableCheck: false,
    }),
    sagaMiddleware,
  ];

  const { transforms, blacklist } = getModulesPersistTransform(modules);
  PERSIST_CONFIG.transforms = transforms;
  PERSIST_CONFIG.blacklist = blacklist;
  const { store } = createPersistedStore(
    reducerManager.reduce,
    (reducer, options) => configureRootStore(reducer, middleware, options),
    PERSIST_CONFIG
  );

  const router = getRouter(modules);

  const registry: Registry = {
    store,
    router,
  };

  sagaInjector.run();

  return registry;
};
