import { LogisticServicesLockBadge } from "../components/logistic-services/badges/logistic-services-lock-badge";
import { LogisticServicesLoginBadge } from "../components/logistic-services/badges/logistic-services-login-badge";
import { LogisticServicesTrafficLightBadge } from "../components/logistic-services/badges/logistic-services-traffic-light-badge";

export const renderLogisticServiceBadge = ({
  isActive,
  isFastDelivery,
}: {
  isActive: boolean;
  isFastDelivery: boolean;
}) => {
  switch (true) {
    case isFastDelivery:
      return <LogisticServicesTrafficLightBadge />;
    case isActive:
      return <LogisticServicesLoginBadge />;
    case !isActive:
      return <LogisticServicesLockBadge />;
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};
