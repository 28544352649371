import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { QuestionnaireStore } from "@/common/features/questionnaire-common/types/store";

export const QUESTIONNAIRE_STORE_INITIAL_STATE: QuestionnaireStore = {
  error: EMPTY_ARRAY_DATA,
  isFetching: false,
  results: [],
  questionnaire: {
    file: undefined,
    isFetching: false,
  },
};
