import { ShoppingOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { LegalConsultationContainer } from "./containers/legal-consultations-container";

const module: IModuleModel = {
  title: ModuleTitle.legalConsultation,
  name: ModuleName.legalConsultation,
  path: ModulePath.legalConsultation,
  icon: <ShoppingOutlined />,
  element: <LegalConsultationContainer />,
  persist: false,
};

export const LegalConsultationModule = defineModule(module);
