import { FC } from "react";
import { OrganizationInfo } from "@/common/types/models/account";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSPhoneButton } from "@/common/components/custom/phone-button/phone-button";

type IProps = {
  organization: OrganizationInfo | null;
};

export const OrganizationSidebar: FC<IProps> = ({ organization }) => {
  return (
    <div className="organization__sidebar">
      <ILSTitle level={2} className="organization__sidebar-title">
        Телефон организации
      </ILSTitle>
      <ILSPhoneButton
        phone={organization?.Phone}
        className="driver-details__phone"
      />
    </div>
  );
};
