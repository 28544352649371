import { PhoneOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { Contacts } from "@/modules/social-services/children/contacts/containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import * as contactsSagas from "@/modules/social-services/children/contacts/sagas";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

const module: IModuleModel = {
  title: ModuleTitle.contacts,
  name: ModuleName.contacts,
  path: ModulePath.contacts,
  icon: <PhoneOutlined />,
  element: <Contacts />,
  sagas: getAllModuleSagas(contactsSagas),
};

export const ContactsModule = defineModule(module);
