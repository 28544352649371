import { useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "@/modules/social-services/children/my-info/selectors/users";

/** выбранный сотрудник для редактирования / удаления / блокировки */

export const useMyInfoSelectUser = () => {
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const handleSelectUser = (userId: number | null) => setSelectedUserId(userId);
  const selectedUser = useSelector(userSelector(selectedUserId));

  return { selectedUser, handleSelectUser };
};
