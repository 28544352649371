import { EMPTY_STRING } from "@/common/constants/general";
import { DEFAULT_ZERO_RATING } from "@/common/constants/config/rating";
import { PUBLIC_ORGANIZATION_PARTNERSHIP_TITLE_ACCENT } from "@/common/features/drivers-common/constants";
import { OrganizationInfo } from "@/common/types/models/account";
import { checkOrganizationPartnership } from "@/common/features/organizations-common/helpers/check-organization-partnership";
import { checkWaitingPartnership } from "@/common/features/organizations-common/helpers/check-waiting-partnership";
import { getOrganizationPartnershipBadge } from "@/modules/admin-panel/children/admin-organizations/helpers/get-organization-partnership-badge";

export const useAdminOrganizationCard = (organization: OrganizationInfo) => {
  const hasConfirmedPartnership = checkOrganizationPartnership(organization);

  const waitingPartnershipCheck = checkWaitingPartnership({
    publicOrganizationPartnership: organization.PublicOrganizationPartnership,
    partnershipChecked: organization.PartnershipChecked,
  });

  const titleBadge = getOrganizationPartnershipBadge({
    hasConfirmedPartnership,
    waitingPartnershipCheck,
  });

  const mainSubtitle = hasConfirmedPartnership
    ? PUBLIC_ORGANIZATION_PARTNERSHIP_TITLE_ACCENT
    : null;

  const info = {
    title: organization.Name,
    subtitle: `ИНН: ${organization?.INN}`,
    titleBadge,
    mainSubtitle,
  };

  const cover = organization?.Avatar?.FileUrl || EMPTY_STRING;

  const socialRating = organization?.SocialRating || DEFAULT_ZERO_RATING;
  const ratings = [socialRating];

  const redirectID = organization?.ID;

  return { info, cover, ratings, redirectID };
};
