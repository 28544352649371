import { isArray, isNil } from "lodash";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { DictionaryType } from "@/common/types/models/dictionary";

export const normalizeLicenseCategories = (
  LicenseCategories?: Array<DictionaryType> | null
): Array<number> => {
  if (isNil(LicenseCategories)) return EMPTY_ARRAY_DATA;
  return isArray(LicenseCategories)
    ? LicenseCategories.map((category) => category.ID)
    : EMPTY_ARRAY_DATA;
};
