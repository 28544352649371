import { createRoutine } from "redux-saga-routines";

export enum VacanciesActions {
  DICTIONARY_OPERATION = "VACANCIES/DICTIONARY_OPERATION",
  CHANGE_FILTERS = "VACANCIES/CHANGE_FILTERS",
}

export const vacanciesRoutine = createRoutine(
  VacanciesActions.DICTIONARY_OPERATION
);
export const vacanciesChangeFiltersRoutine = createRoutine(
  VacanciesActions.CHANGE_FILTERS
);

export const VacanciesActionsForNotifications = [vacanciesRoutine];
