import { checkPassword } from "@/common/ui-components/data-entry/form/helpers/check-passwords";

export const validatorNewPassword = (_: any, value: string) => {
  return checkPassword(value)
    ? Promise.resolve()
    : Promise.reject(
        new Error(
          "Пароль должен быть не менее 8 символов содержать латинские буквы и цифры"
        )
      );
};
