import { FC } from "react";

import {
  IILSButtonConfirmTooltipProps,
  ILSButtonConfirmTooltip,
} from "@/common/components/data-display/buttons/confirm-tooltip";

interface IProps {
  actions?: IILSButtonConfirmTooltipProps[] | undefined;
}

export const WideCardActions: FC<IProps> = ({ actions = [] }) => {
  return (
    <div className="ils-card__actions">
      {actions?.map(({ hidden = false, ...props }, i) => {
        if (hidden) return null;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <ILSButtonConfirmTooltip {...props} key={i} />
        );
      })}
    </div>
  );
};
