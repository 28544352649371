import { FC } from "react";
import { Space } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ILSDivider } from "@/common/components/layout/divider";
import {
  PHONE_IS_NOT_VERIFIED,
  PHONE_IS_VERIFIED,
} from "@/common/features/drivers-common/constants";

type IProps = {
  phoneVerified: boolean;
};

export const AdminEditDriverPhoneVerified: FC<IProps> = ({ phoneVerified }) => {
  return (
    <>
      <ILSDivider plain className="admin-edit-driver__divider">
        Подтверждение номера телефона
      </ILSDivider>
      {phoneVerified ? (
        <Space className="admin-edit-driver__verification">
          <p>{PHONE_IS_VERIFIED}</p>
          <CheckCircleOutlined className="success-icon" />
        </Space>
      ) : (
        <Space className="admin-edit-driver__verification">
          <p>{PHONE_IS_NOT_VERIFIED}</p>
          <ExclamationCircleOutlined />
        </Space>
      )}
    </>
  );
};
