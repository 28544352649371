import { RatingContentType } from "@/common/types/models/rating";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";
import { ILSTextArea } from "@/common/ui-components/data-entry/input/text-area";

// TODO: если одинаковые будут поля, то эти константы можно переделать и сократить
export const EDUCATION_EMPLOYMENT_FIELDS = [
  {
    fieldProps: {
      field: "Общественные мероприятия по рекрутингу:",
      label: "Общественные мероприятия по рекрутингу:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Льготное обучение в автошколах:",
      label: "Льготное обучение в автошколах:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Льготы на обязательные курсы повышения квал-и:",
      label: "Льготы на обязательные курсы повышения квал-и:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Льготы на необязательные курсы повышения квал-и:",
      label: "Льготы на необязательные курсы повышения квал-и:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Адаптация, наставничество:",
      label: "Адаптация, наставничество:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Учебный центр, целевое обучения:",
      label: "Учебный центр, целевое обучения:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Карьерный рост:",
      label: "Карьерный рост:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
] as unknown as FormItemsFields;

export const LABOUR_SAFETY_FIELDS = [
  {
    fieldProps: {
      field: "Комфорт в рабочем пространстве:",
      label: "Комфорт в рабочем пространстве:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Режим труда/отдыха, СИЗ:",
      label: "Режим труда/отдыха, СИЗ:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Места соблюдения гигиены",
      label: "Места соблюдения гигиены",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Предрейсовые медосмотры:",
      label: "Предрейсовые медосмотры:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Канал оповещения сотрудников:",
      label: "Канал оповещения сотрудников:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Система наблюдения:",
      label: "Система наблюдения:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Система личной безопасности:",
      label: "Система личной безопасности:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Система помощи в аварийных ситуациях:",
      label: "Система помощи в аварийных ситуациях:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Комфортные командировочные условия:",
      label: "Комфортные командировочные условия:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Компенсация штрафов ППД:",
      label: "Компенсация штрафов ППД:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
] as unknown as FormItemsFields;

export const SOCIAL_FIELDS = [
  {
    fieldProps: {
      field: "Социальные выплаты льготным категориям сотрудников:",
      label: "Социальные выплаты льготным категориям сотрудников:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Выплаты сотрудникам в жизненных ситуациях:",
      label: "Выплаты сотрудникам в жизненных ситуациях:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Накопительные страховые счета:",
      label: "Накопительные страховые счета:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Льготные путевки:",
      label: "Льготные путевки:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Страхование жизни и здоровья:",
      label: "Страхование жизни и здоровья:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "ДМС:",
      label: "ДМС:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Программы поддержки семьи:",
      label: "Программы поддержки семьи:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Наличие профсоюза:",
      label: "Наличие профсоюза:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
] as unknown as FormItemsFields;

export const CORPORATE_CULTURE_FIELDS = [
  {
    fieldProps: {
      field: "Брендирование сотрудника+автомобиля:",
      label: "Брендирование сотрудника+автомобиля:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Каналы информирования сотрудников:",
      label: "Каналы информирования сотрудников:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Соц. группы для коммуникации сотрудников:",
      label: "Соц. группы для коммуникации сотрудников:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Корпоративные мероприятия (гос.праздники):",
      label: "Корпоративные мероприятия (гос.праздники):",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "||-|| (личные события сотрудников):",
      label: "||-|| (личные события сотрудников):",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Профильные мероприятия компании:",
      label: "Профильные мероприятия компании:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Корпоративные подарки детям сотрудников:",
      label: "Корпоративные подарки детям сотрудников:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Музей достижений компании:",
      label: "Музей достижений компании:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Доска почета, благодарности сотрудникам:",
      label: "Доска почета, благодарности сотрудникам:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Внутрикорпоративная соревновательность:",
      label: "Внутрикорпоративная соревновательность:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Социально-психологический климат:",
      label: "Социально-психологический климат:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
] as unknown as FormItemsFields;

export const ENTERTAINMENT_FIELDS = [
  {
    fieldProps: {
      field: "Семейные фестивали, экскурсии:",
      label: "Семейные фестивали, экскурсии:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Конкурсы детских рисунков, стихов:",
      label: "Конкурсы детских рисунков, стихов:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Создание медиа-материалов (BР) о профессии:",
      label: "Создание медиа-материалов (BР) о профессии:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Поддержка творческих хобби:",
      label: "Поддержка творческих хобби:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Поддержка спортивных команд:",
      label: "Поддержка спортивных команд:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Детские кружки, мастерские в компании:",
      label: "Детские кружки, мастерские в компании:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Дисконтная система:",
      label: "Дисконтная система:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
] as unknown as FormItemsFields;

export const HEALTHY_LIFESTYLE_FIELDS = [
  {
    fieldProps: {
      field: "Расширенные медосмотры:",
      label: "Расширенные медосмотры:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Содействие иммунопрофилактике:",
      label: "Содействие иммунопрофилактике:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Выдача индивидуального рациона питания:",
      label: "Выдача индивидуального рациона питания:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Спортивная зона, мероприятия:",
      label: "Спортивная зона, мероприятия:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Льготные абонементы в спорт-учреждения:",
      label: "Льготные абонементы в спорт-учреждения:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Компенсация лечения отдельных заболеваний:",
      label: "Компенсация лечения отдельных заболеваний:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
] as unknown as FormItemsFields;

export const PATRIOTISM_FIELDS = [
  {
    fieldProps: {
      field: "Участие в патриотических мероприятиях:",
      label: "Участие в патриотических мероприятиях:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Участие в автопробеге «Водители Победы»:",
      label: "Участие в автопробеге «Водители Победы»:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Выездные военно-патриотические мероприятия:",
      label: "Выездные военно-патриотические мероприятия:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Творческие конкурсы патриотической тематики:",
      label: "Творческие конкурсы патриотической тематики:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Доп.информация об исторических событиях:",
      label: "Доп.информация об исторических событиях:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
] as unknown as FormItemsFields;

export const RELIGION_FIELDS = [
  {
    fieldProps: {
      field: "Обеспечение реализации религиозных убеждений:",
      label: "Обеспечение реализации религиозных убеждений:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Обеспечение процесса освещения автомобилей:",
      label: "Обеспечение процесса освещения автомобилей:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Обеспечение мест для духовных обрядов:",
      label: "Обеспечение мест для духовных обрядов:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
  {
    fieldProps: {
      field: "Обеспечение встреч с духовниками:",
      label: "Обеспечение встреч с духовниками:",
    },
    inputProps: {},
    Component: ILSTextArea,
  },
] as unknown as FormItemsFields;

export const MY_RATING_FIELDS = {
  [RatingContentType.EducationAndEmploymentModule]: EDUCATION_EMPLOYMENT_FIELDS,
  [RatingContentType.LabourSafety]: LABOUR_SAFETY_FIELDS,
  [RatingContentType.Social]: SOCIAL_FIELDS,
  [RatingContentType.CorporateCulture]: CORPORATE_CULTURE_FIELDS,
  [RatingContentType.Entertainment]: ENTERTAINMENT_FIELDS,
  [RatingContentType.HealthyLifeStyle]: HEALTHY_LIFESTYLE_FIELDS,
  [RatingContentType.Patriotism]: PATRIOTISM_FIELDS,
  [RatingContentType.Religion]: RELIGION_FIELDS,
};
