import { getModuleSagas } from "./get-module-sagas";
import { sagaMiddleware } from "./saga–middleware";
import { Modules } from "@/modules";
import { createSagaInjector } from "./create-saga-injector";
import { TSagasObj } from "@/common/types/core/module";
import * as rootSagas from "@/core/sagas/root";

export const getSagaInjector = () => {
  const moduleSagas = getModuleSagas(Modules);
  const sagas: TSagasObj = {
    rootSagas: [],
  };

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in moduleSagas) {
    sagas[key] = moduleSagas[key];
  }

  sagas.rootSagas = Object.values(rootSagas).map((rootSaga) => rootSaga());

  return createSagaInjector(sagaMiddleware.run, sagas);
};
