import { useForm } from "antd/es/form/Form";
import { useDispatch } from "react-redux";
import { UpdateRequestBody } from "@/common/api/types/api";
import { User } from "@/common/types/models/user/user";
import {
  deleteUserActions,
  updateUserActions,
} from "@/modules/social-services/children/my-info/actions";
import { useAccount } from "@/common/hooks/use-account";
import { useFormAvatar } from "@/common/hooks/use-form-avatar";
import { useDisableSubmitBeforeTouched } from "@/common/ui-components/data-entry/form/hooks/use-disable-submit-before-touched";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { AccountFormValues } from "@/modules/social-services/children/my-info/types/form";
import { accountToFormValues } from "@/modules/social-services/children/my-info/helpers/decorators/account-to-form-values";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { getPayloadFromFormValues } from "@/common/ui-components/data-entry/form/helpers/get-payload-from-form-values";
import { getUnmaskedPhone } from "@/common/utils/helpers/string/get-unmasked-number";
import { getFormImage } from "@/common/helpers/get-account-avatar";

export const useMyInfoAccountContainer = () => {
  const { user } = useAccount();
  const dispatch = useDispatch();

  const [form] = useForm<AccountFormValues>();

  const initialValues = accountToFormValues({
    user,
  });
  const { disabled, enableSubmit } = useDisableSubmitBeforeTouched();

  const {
    fileList,
    removedSavedFiles,
    handleAvatarChange,
    handleAvatarRemove,
    handleAvatarReset,
  } = useFormAvatar({
    avatar: initialValues?.Avatar,
    enableSubmit,
  });

  const handleSubmit = async (formValues: AccountFormValues) => {
    try {
      const Avatar = await getFormImage(fileList, removedSavedFiles);

      const payload =
        getPayloadFromFormValues<UpdateRequestBody<Omit<User, "Avatar">>>(
          formValues
        );

      dispatch(
        updateUserActions({
          body: {
            ...payload,
            Phone: getUnmaskedPhone(payload?.Phone),
            Avatar,
          },
          userID: user?.ID,
        })
      );
    } catch (e) {
      showUserDevErrors({ e });
    }
    return formValues;
  };

  const handleCancelChange = () => {
    /** сбрасываем значения формы до начальных при клике на "Отмена" */
    form.resetFields();
    form.setFieldsValue(initialValues || EMPTY_OBJECT_DATA);

    /** аватар сбрасывается через свой state */
    handleAvatarReset();
  };

  const handleDeleteUser = () => {
    dispatch(deleteUserActions({ userID: user?.ID }));
  };

  return {
    form,
    initialValues,
    fileList,
    disabled,
    enableSubmit,
    handleSubmit,
    handleCancelChange,
    handleAvatarChange,
    handleAvatarRemove,
    handleDeleteUser,
  };
};
