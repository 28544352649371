import { ShoppingOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { Store } from "@/modules/social-services/children/store/containers";

const module: IModuleModel = {
  title: ModuleTitle.store,
  name: ModuleName.store,
  path: ModulePath.store,
  icon: <ShoppingOutlined />,
  element: <Store />,
  persist: false,
};

export const StoreModule = defineModule(module);
