import "../index.scss";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";

interface IProps {}

export const Auth: FC<IProps> = () => {
  return (
    <ILSAllowedTo perform={AbacPermission.nonAuth}>
      <section className="auth">
        <Outlet />
      </section>
    </ILSAllowedTo>
  );
};
