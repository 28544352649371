import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

export const FormPlaceholder: Record<string, string> = {
  login: "mail@mail.ru",
  password: "",
  /** Вакансия */
  [FormFieldName.Phone]: "Введите номер",
  [FormFieldName.Title]: "Введите название",
  [FormFieldName.Description]: "Введите описание",
  [FormFieldName.Profession]: "Введите название профессии",
  [FormFieldName.LicenseCategory]: "Укажите категорию прав",
  [FormFieldName.DrivingExperience]: "Укажите опыт работы",
  [FormFieldName.Years]: "кол-во лет",
  [FormFieldName.Months]: "кол-во месяцев",
  [FormFieldName.CompanyHasVehicle]: "",
  [FormFieldName.Brand]: "Введите марку ТС",
  [FormFieldName.ProductionYear]: "Введите год выпуска",
  [FormFieldName.AllowedMaxMass]: "Введите максимальную массу",
  [FormFieldName.LoadCapacity]: "Введите грузоподъемность",
  [FormFieldName.WheelArrangement]: "Выберите значение",
  [FormFieldName.EngineType]: "Выберите значение",
  [FormFieldName.EngineCapacity]: "Укажите мощность",
  [FormFieldName.EngineVolume]: "Укажите объем двигателя",
  [FormFieldName.EcologyClass]: "Выберите значение",
  [FormFieldName.Gearbox]: "Выберите значение",
  [FormFieldName.CabinType]: "Выберите значение",
  [FormFieldName.VehicleCondition]: "Укажите состояние ТС",
  [FormFieldName.PaymentTerms]: "Выберите условия",
  [FormFieldName.PaymentFrequency]: "Выберите значение",
  [FormFieldName.Address]: "Введите адрес",
  [FormFieldName.Extra]: "Выберите значение",
  [FormFieldName.From]: "от",
  [FormFieldName.To]: "до",
  [FormFieldName.VacancyPhone]: "Введите номер телефона",
  [FormFieldName.Video]: "Добавьте ссылку на видео",
  [FormFieldName.JobRegion]: "Выберите город",
  /** Водитель */
  [FormFieldName.Name]: "Введите имя",
  [FormFieldName.Surname]: "Введите фамилию",
  [FormFieldName.Patronymic]: "Введите отчество",
  [FormFieldName.Birthday]: "Введите дату рождения",
  [FormFieldName.Citizens]: "Введите значение",
  [FormFieldName.WorkSchedule]: "Выберите значение",
  [FormFieldName.EmploymentType]: "Выберите значение",
  [FormFieldName.EducationLevel]: "Выберите значение",
  [FormFieldName.OwnVehicleType]: "Выберите значение",
  [FormFieldName.DriverLicense]: "Введите номер",
  [FormFieldName.DriverLicenseDate]: "Выберите дату",
  [FormFieldName.VehicleType]: "Выберите тип ТС",
  [FormFieldName.ExperienceYears]: "Введите кол-во",
  [FormFieldName.Organization]: "Введите название организации",
  [FormFieldName.JobSearchStatus]: "Укажите текущий статус",
  [FormFieldName.JobSearchRegion]: "Выберите города",
  /** Организация */
  [FormFieldName.OrganizationName]: "Введите название организации",
  [FormFieldName.OrganizationTypeID]: "Выберите тип организации",
  [FormFieldName.INN]: "ИНН",
  [FormFieldName.KPP]: "КПП",
  [FormFieldName.OGRN]: "ОГРН",
  [FormFieldName.OKPO]: "ОКПО",
  [FormFieldName.LegalAddress]: "Введите юридический адрес",
  [FormFieldName.ActualAddress]: "Введите почтовый адрес",
  /** Учетная запись пользователя */
  [FormFieldName.FullName]: "Введите ФИО пользователя",
  [FormFieldName.Email]: "Введите адрес электронной почты",
  [FormFieldName.WorkingPosition]: "Укажите должность",
  /** Организация */
  [FormFieldName.ApplicationStatus]: "Выберите значение",

  /** Опрос */
  [FormFieldName.ResidenceRegion]: "Введите город",
  [FormFieldName.SalaryLevel]: "Укажите уровень заработной платы",

  /** Подтверждение номера телефона */
  [FormFieldName.VerificationCode]: "СМС код",
};
