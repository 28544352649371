import { FC, useEffect } from "react";
import { isEmpty } from "lodash";
import { RadioGroupProps } from "antd";
import { Dictionaries } from "@/common/types/models/dictionary";
import { ILSRadioGroup } from "@/common/ui-components/data-entry/radio/group";
import { useGetDictionary } from "@/common/features/dictionaries/hooks/use-get-dictionary";
import { SelectApiOption } from "@/common/types/general/common";

export interface ILSRadioGroupPropsApi extends RadioGroupProps {
  dictionary?: Dictionaries;
  options?: Array<SelectApiOption>;
}

export const ILSRadioGroupApi: FC<ILSRadioGroupPropsApi> = ({
  dictionary,
  options,
  ...props
}) => {
  const { handleGetDictionary } = useGetDictionary();

  useEffect(() => {
    handleGetDictionary({ dictionary });
  }, []);

  return <ILSRadioGroup options={options} {...props} />;
};
