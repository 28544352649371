import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { AdminOrganizations } from "@/modules/admin-panel/children/admin-organizations/containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { adminOrganizationsRootReducer } from "@/modules/admin-panel/children/admin-organizations/reducers";
import * as adminOrganizationsRootSagas from "@/modules/admin-panel/children/admin-organizations/sagas";
import { AdminOrganizationModule } from "@/modules/admin-panel/children/admin-organizations/children/organization";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

const module: IModuleModel = {
  title: ModuleTitle.adminOrganizations,
  name: ModuleName.adminOrganizations,
  path: ModulePath.adminOrganizations,
  children: { AdminOrganizationModule },
  element: <AdminOrganizations />,
  reducer: adminOrganizationsRootReducer,
  sagas: getAllModuleSagas(adminOrganizationsRootSagas),
};

export const AdminOrganizationsModule = defineModule(module);
