import { EMPTY_STRING } from "@/common/constants/general";
import {
  OPERATION_ENDPOINTS_WITH_METHOD,
  OPERATION_RESULTS_ENDPOINTS,
} from "@/common/api/endpoints/questionnaire";
import { QuestionnaireOperation } from "@/common/api/types/questionnaire";

export const getQuestionnaireMethod = (method: QuestionnaireOperation) => {
  if (OPERATION_RESULTS_ENDPOINTS?.includes(method)) {
    return "result";
  }
  if (OPERATION_ENDPOINTS_WITH_METHOD?.includes(method)) {
    return `${method}`;
  }

  return EMPTY_STRING;
};
