import { head } from "lodash";
import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { articlesRoutine } from "@modules/admin-panel/children/admin-articles/actions";
import { ADMIN_ARTICLES_INITIAL_STATE } from "@modules/admin-panel/children/admin-articles/store";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { Article, Articles } from "@/common/api/types/article";
import { replaceUpdatedItem } from "@/common/utils/helpers/array/replace-updated-item";

export const articlesReducer = createReducer(ADMIN_ARTICLES_INITIAL_STATE, {
  [articlesRoutine.SUCCESS]: (
    state,
    action: PayloadAction<{
      operation: OperationsEndpoint;
      data: Articles | Article;
      id?: number;
    }>
  ) => {
    const { operation, data } = action.payload;
    let article: Article | null = null;
    if (operation === OperationsEndpoint.Get) {
      article = head(data as Articles) || null;
    }

    switch (operation) {
      case OperationsEndpoint.GetAll:
        state.articles = data as Articles;
        break;
      case OperationsEndpoint.Get:
        if (article) {
          state.currentArticle = article;
          state.articles = replaceUpdatedItem(article, state.articles);
        }
        break;
      default:
        break;
    }
    return state;
  },
  [articlesRoutine.REQUEST]: onRequest,
  [articlesRoutine.FAILURE]: onFailure,
  [articlesRoutine.FULFILL]: onFulfill,
});
