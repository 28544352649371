import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  vacanciesFetchingSelector,
  appliesListSelector,
} from "@/common/features/vacancies-common/selectors";
import { useVacancyOperations } from "@/common/features/vacancies-common/hooks/use-vacancy-operations";

type IProps = {
  vacancyId: number | undefined;
};

export const useVacancyApplies = ({ vacancyId }: IProps) => {
  const appliesList = useSelector(appliesListSelector);
  const loading = useSelector(vacanciesFetchingSelector);

  const { handleGetVacancyApplyList, handleApplyConfirm, handleApplyRefuse } =
    useVacancyOperations();

  useEffect(() => {
    handleGetVacancyApplyList(vacancyId);
  }, []);

  const onApplyConfirm = (applyId: number | undefined) =>
    handleApplyConfirm(applyId, vacancyId);
  const onApplyRefuse = (applyId: number | undefined) =>
    handleApplyRefuse(applyId, vacancyId);

  return { appliesList, loading, onApplyConfirm, onApplyRefuse };
};
