import { ModulePath } from "@/common/types/core/routes";
import { JoinChar } from "@/common/constants/general";

export const getChildrenRoute = (
  pathname: string,
  defaultSubRoute: ModulePath
): ModulePath => {
  return (
    (pathname.split(JoinChar.ForwardSlash)[2] as ModulePath) || defaultSubRoute
  );
};
