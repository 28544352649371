export enum Currency {
  RUB = "RUB",
  USD = "USD",
  EUR = "EUR",
}

export enum CurrencySign {
  RUB = "₽",
  USD = "$",
  EUR = "€",
}

export const DEFAULT_CURRENCY_UNIT: Currency = Currency.RUB;

export const CURRENCY_OPTIONS = [
  { label: Currency.RUB, value: Currency.RUB },
  { label: Currency.USD, value: Currency.USD },
  { label: Currency.EUR, value: Currency.EUR },
];
