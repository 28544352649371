import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { authUserSetActions } from "@modules/auth/actions";
import {
  getUsersActions,
  updateUserActions,
} from "@modules/social-services/children/my-info/actions";
import { User, UserRole } from "@/common/types/models/user/user";
import { USER_API } from "@/common/api/endpoints/user";
import { DRIVERS_STATUSES } from "@/common/features/drivers-common/sagas/notifier";
import { UserBaseRequest } from "@/common/api/types/user";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";
import { driversRoutine } from "@/common/features/drivers-common/actions";
import { currentUserSelector } from "@/modules/auth/selectors/current-user";
import { compareAsString } from "@/common/helpers/compare-as-string";

export type UpdateUserPayload = UserBaseRequest;
export type UpdateUserAction = PayloadAction<UpdateUserPayload>;

export function* updateUserWorker(action: UpdateUserAction) {
  const { request, failure, fulfill } = updateUserActions;

  const { body, userID, adminPanel } = action.payload;
  try {
    yield put(request());
    const { data: user }: { data: User } = yield call(USER_API.update, {
      body,
      userID,
    });

    const currentUser: User = yield select(currentUserSelector);
    const isCurrentUser = compareAsString(currentUser.ID, userID);
    const isOrganization = currentUser?.UserRoles?.some(
      (role) => role.Alias === UserRole.administrator
    );

    /** Мои данные: "Учетная запись", "Редактирование пользователя", "Данные водителя" */
    if (!adminPanel) {
      if (isCurrentUser) {
        /** 1. Обновляем данные текущего пользователя */
        yield put(authUserSetActions.success({ user }));
      }
      if (isOrganization) {
        /** 2. Запрашиваем данные всех пользователей */
        yield put(getUsersActions.trigger());
      }
    } else {
      /** Административная панель: "Управление водителями" */
      yield put(
        driversRoutine.success({
          operation: OperationsEndpoint.Get,
          data: user,
          id: user?.Driver?.ID,
          customNotificationMessage: DRIVERS_STATUSES,
        })
      );
    }
    yield call(showSuccessNotification, "Данные успешно сохранены");
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* updateUserWatcher() {
  yield takeLatest(updateUserActions.trigger, updateUserWorker);
}
