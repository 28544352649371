import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import {
  Vacancy,
  VacancyVehiclePossession,
} from "@/common/types/models/vacancies/model";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { VehiclePhotos } from "@/common/features/vacancy/components/vehicle-photos";
import { getDictionaryTypeNames } from "@/common/features/vacancies-common/helpers/get-dictionary-type-names";
import { getVacancyVideoLink } from "@/common/features/vacancy/helpers/get-vacancy-video-link";

type IProps = {
  vacancy?: Vacancy;
};

export const VacancyVehicle: FC<IProps> = ({ vacancy }) => {
  if (vacancy?.Vehicle?.Own === VacancyVehiclePossession.NotProvided) {
    return null;
  }

  return (
    <section className="vacancy__section">
      <div className="vacancy__section-row">
        <div className="vacancy__section-column">
          <ILSTitle level={2} className="vacancy__section-title">
            Транспортное средство
          </ILSTitle>
          <div className="vacancy__section-content">
            <DetailDescription
              caption="Марка"
              value={getDictionaryTypeNames(vacancy?.Vehicle?.Brand)}
            />
            <DetailDescription
              caption="Тип ТС"
              value={getDictionaryTypeNames(vacancy?.Vehicle?.Type)}
            />
            <DetailDescription
              caption="Год выпуска"
              value={vacancy?.Vehicle?.ProductionYear}
            />
            <DetailDescription
              caption="Разрешенная максимальная масса"
              value={vacancy?.Vehicle?.Profile?.MaxMass}
              additional="кг."
            />
            <DetailDescription
              caption="Грузоподъемность"
              value={vacancy?.Vehicle?.Profile?.LoadCapacity}
              additional="кг."
            />
            <DetailDescription
              caption="Колесная формула"
              value={getDictionaryTypeNames(
                vacancy?.Vehicle?.Profile?.WheelArrangement
              )}
            />
            <DetailDescription
              caption="Тип двигателя"
              value={getDictionaryTypeNames(
                vacancy?.Vehicle?.Profile?.EngineType
              )}
            />
            <DetailDescription
              caption="Мощность"
              value={vacancy?.Vehicle?.Profile?.EngineCapacity}
              additional="л.с."
            />
            <DetailDescription
              caption="Объем двигателя"
              value={vacancy?.Vehicle?.Profile?.EngineVolume}
              additional="л."
            />
            <DetailDescription
              caption="Экологический класс"
              value={getDictionaryTypeNames(
                vacancy?.Vehicle?.Profile?.EcologyClass
              )}
            />
            <DetailDescription
              caption="Коробка передач"
              value={getDictionaryTypeNames(vacancy?.Vehicle?.Profile?.Gearbox)}
            />
            <DetailDescription
              caption="Тип кабины"
              value={getDictionaryTypeNames(
                vacancy?.Vehicle?.Profile?.CabinType
              )}
            />
            <DetailDescription
              caption="Состояние ТС"
              value={getDictionaryTypeNames(
                vacancy?.Vehicle?.Profile?.Condition
              )}
            />
            <DetailDescription
              caption="Видео"
              value={getVacancyVideoLink(vacancy?.Vehicle?.Profile?.Video)}
            />
          </div>
        </div>
        <VehiclePhotos vacancy={vacancy} />
      </div>
    </section>
  );
};
