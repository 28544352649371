import { FC } from "react";
import { Form, FormInstance } from "antd";
import classNames from "classnames";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import {
  VACANCIES_SIDEBAR_INITIAL_VALUES,
  VACANCY_SIDEBAR_FILTER_FIELDS,
} from "@/common/features/vacancies-common/constants/filters";
import { VacanciesSidebarFilterValues } from "@/common/features/vacancies-common/types/filters";
import { RefDataType } from "@/common/features/dictionaries/types";
import { ILSButton } from "@/common/components/data-display/buttons";
import { Salary } from "@/common/components/data-entry/form-item/salary";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

interface IProps {
  sidebarForm: FormInstance<VacanciesSidebarFilterValues>;
  sidebarRefData: RefDataType;
  onSidebarFormChange: (
    changedValues: VacanciesSidebarFilterValues,
    values: VacanciesSidebarFilterValues
  ) => void;
  onClickResetSidebarForm: VoidFunction;
  hideLarge?: boolean;
}

export const AdminVacanciesFilters: FC<IProps> = ({
  sidebarForm,
  sidebarRefData,
  onSidebarFormChange,
  onClickResetSidebarForm,
  hideLarge = true,
}) => {
  return (
    <Form
      form={sidebarForm}
      onValuesChange={onSidebarFormChange}
      initialValues={VACANCIES_SIDEBAR_INITIAL_VALUES}
      layout="vertical"
      className={classNames("admin-vacancies__filters", {
        "hide-large": hideLarge,
      })}
    >
      <ILSButton
        onClick={onClickResetSidebarForm}
        danger
        className="filters-reset-button"
      >
        Сбросить фильтры
      </ILSButton>
      {renderFormItems({
        fields: VACANCY_SIDEBAR_FILTER_FIELDS,
        refData: sidebarRefData,
      })}
      <Salary rules={EMPTY_ARRAY_DATA} disabled />
    </Form>
  );
};
