import { IFileType } from "@/common/types/models/file";
import { ImageType } from "@/common/types/models/image";

/* Тип шаблона анкеты администратора */
export type QuestionnaireType = {
  File: IFileType;
  Available: true | false;
};

/* Тип шаблона анкеты администратора для сохранения на сервере */
export type SaveQuestionnaireType = {
  Name: string | undefined;
  Data: string | undefined;
};

/* Тип результата заполнения анкеты организацией */
export type QuestionnaireResultType = {
  ID: number;
  File: IFileType;
  Organization: {
    ID: number;
    Name: string;
    INN: string;
    Avatar: ImageType;
  };
};

export const isQuestionnaireResult = (
  questionnaire: QuestionnaireType | QuestionnaireResultType
): questionnaire is QuestionnaireResultType => {
  return (questionnaire as QuestionnaireResultType)?.ID !== undefined;
};

export enum QuestionnaireOperation {
  get = "get",
  getResults = "getResults",
  save = "save",
  delete = "delete",
  makeAvailable = "makeAvailable",
  makeUnavailable = "makeUnavailable",
  saveResult = "saveResult",
  deleteResult = "deleteResult",
}

export enum QuestionnaireAvailabilityStatus {
  available = "available",
  unAvailable = "unAvailable",
}
