import { useMyInfoQuestionnaire } from "@/modules/social-services/children/my-info/hooks/questionnaire/use-my-info-questionnaire";
import { useMyInfoQuestionnaireResult } from "@/modules/social-services/children/my-info/hooks/questionnaire/use-my-info-questionnaire-result";

export const useMyInfoQuestionnaireContainer = () => {
  /** Получение шаблона анкеты */
  const { template, questionnaireIsFetching } = useMyInfoQuestionnaire();

  /** Управление результатами анкеты, получение, загрузка, удаление  */
  const {
    resultDocuments,
    resultIsFetching,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  } = useMyInfoQuestionnaireResult();

  const isFetching = questionnaireIsFetching || resultIsFetching;
  return {
    template,
    isFetching,
    resultDocuments,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  };
};
