import { FC } from "react";
import { ItemType } from "antd/es/menu/interface";
import { ILSMenu } from "@/common/ui-components/navigation/menu";

interface IProps {
  menuItems: ItemType[];
  onClick: VoidFunction;
}

export const ILSHeaderMenu: FC<IProps> = ({ menuItems, onClick }) => {
  return <ILSMenu items={menuItems} onClick={onClick} mode="inline" />;
};
