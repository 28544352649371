import moment from "moment";
import { noUnderScore } from "@/common/constants/regex";
import { DateFormat } from "@/common/types/general/date-time";

export const validatorCheckFutureDate = () => ({
  validator: (_instance: any, value: string) => {
    if (!value || !/\d/.test(value)) {
      return Promise.resolve();
    }

    const momentDate = moment(value, DateFormat.DDMMYYYYPeriod);

    if (noUnderScore.test(value) && moment(momentDate).isAfter()) {
      return Promise.reject(
        new Error("Значение даты не может быть позже текущего момента")
      );
    }

    return Promise.resolve();
  },
});
