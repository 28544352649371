import { FC } from "react";
import { EMPTY_STRING } from "@/common/constants/general";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { useMyInfoDriverMembership } from "@/modules/social-services/children/my-info/hooks/use-my-info-driver-membership";

export type IProps = {
  membershipChecked: boolean | null | undefined;
};

export const DriverMembershipCheckbox: FC<IProps> = ({ membershipChecked }) => {
  const { helpText } = useMyInfoDriverMembership({
    membershipChecked,
  });
  return (
    <ILSFormItem
      field={FormFieldName.PublicOrganizationMembership}
      label={EMPTY_STRING}
      valuePropName="checked"
      help={helpText}
    >
      <ILSCheckbox
        label={FORM_LABEL[FormFieldName.PublicOrganizationMembership]}
      />
    </ILSFormItem>
  );
};
