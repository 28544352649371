import { LaptopOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
// eslint-disable-next-line import/no-cycle
import { DriverDetailsContainer } from "./containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";

const module: IModuleModel = {
  title: ModuleTitle.driverDetails,
  name: ModuleName.driverDetails,
  path: ModulePath.driverDetails,
  icon: <LaptopOutlined />,
  element: <DriverDetailsContainer />,
  persist: false,
};

export const DriverDetailsModule = defineModule(module);
