import { UploadFile } from "antd";
import { Dayjs } from "dayjs";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

export enum VacancyFormType {
  Create = "Create",
  Edit = "Edit",
}

/** Вакансия - данные из формы */
export type VacancyFormValues = {
  [FormFieldName.Title]: string; // required
  [FormFieldName.Description]?: string;
  [FormFieldName.Profession]?: string;
  [FormFieldName.LicenseCategory]?: Array<string>;
  [FormFieldName.DrivingExperience]?: {
    [FormFieldName.Years]?: number;
    [FormFieldName.Months]?: number;
  };
  [FormFieldName.CompanyHasVehicle]?: boolean;
  [FormFieldName.Brand]?: string;
  [FormFieldName.VehicleType]?: number | null | undefined;
  [FormFieldName.ProductionYear]?: Dayjs | number;
  [FormFieldName.AllowedMaxMass]?: number;
  [FormFieldName.LoadCapacity]?: number;
  [FormFieldName.WheelArrangement]?: number | null | undefined;
  [FormFieldName.EngineType]?: number | null | undefined;
  [FormFieldName.EngineCapacity]?: number;
  [FormFieldName.EngineVolume]?: number;
  [FormFieldName.EcologyClass]?: number | null | undefined;
  [FormFieldName.Gearbox]?: number | null | undefined;
  [FormFieldName.CabinType]?: number | null | undefined;
  [FormFieldName.VehicleCondition]?: string;
  [FormFieldName.VehiclePhotos]?: UploadFile[];
  [FormFieldName.Video]?: string;
  [FormFieldName.WorkSchedule]?: Array<number>;
  [FormFieldName.RegistrationMethod]?: Array<number>;
  [FormFieldName.PaymentTerms]?: number | null | undefined;
  [FormFieldName.Salary]?: {
    // required
    [FormFieldName.From]?: string;
    [FormFieldName.To]?: string;
  };
  [FormFieldName.PaymentFrequency]?: number | null | undefined;
  [FormFieldName.Extra]?: Array<number>;
  [FormFieldName.Address]?: string;
  [FormFieldName.VacancyPhone]?: string;
  [FormFieldName.JobRegion]?: number | null;
};
