import { ContactsActions } from "@/modules/social-services/children/contacts/actions";
import { Notify } from "@/common/types/notifications/enum";
import { ActionsNotifyStatuses } from "@/common/types/notifications/actions";

export type ContactsActionsNotifyStatuses =
  ActionsNotifyStatuses<ContactsActions.sentQuestion>;

export const CONTACTS_NOTIFY_STATUSES: ContactsActionsNotifyStatuses = {
  [ContactsActions.sentQuestion]: {
    [Notify.Loading]: "Загрузка...",
    [Notify.Success]: "Ваш вопрос успешно отправлен!",
  },
};
