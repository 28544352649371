import { FC } from "react";
import {
  ADMIN_ORGANIZATION_GENERAL_DATA,
  ADMIN_ORGANIZATION_PARTNERSHIP_FIELDS,
} from "@/modules/admin-panel/children/admin-organizations/children/organization/constants/organization-form";
import { PUBLIC_ORGANIZATION_PARTNER_CHECK_REQUIRED } from "@/common/features/drivers-common/constants";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSDivider } from "@/common/components/layout/divider";
import { ILSInputCopy } from "@/common/ui-components/data-entry/input/copy";
import { AdminOrganizationFormValues } from "@/modules/admin-panel/children/admin-organizations/children/organization/types/organization-form";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { useAdminEditPartnership } from "@/modules/admin-panel/children/admin-organizations/children/organization/hooks/use-admin-edit-partnership";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";

type IProps = {
  initialValues: AdminOrganizationFormValues | null;
};

export const AdminOrganizationFormFields: FC<IProps> = ({ initialValues }) => {
  const { showWaitingCheckAlert } = useAdminEditPartnership();

  return (
    <div className="admin-organization-form__fields">
      <ILSTitle level={3}>Организация</ILSTitle>
      <ILSDivider className="admin-organization-form__divider" />
      <div className="admin-organization-form__fields-group">
        {renderFormItems({ fields: ADMIN_ORGANIZATION_GENERAL_DATA })}

        {initialValues?.AccountCode && (
          <ILSInputCopy
            field={FormFieldName.AccountCode}
            copyText={initialValues.AccountCode}
          />
        )}

        {showWaitingCheckAlert && (
          <p className="admin-organization-form__alert">
            {PUBLIC_ORGANIZATION_PARTNER_CHECK_REQUIRED}
          </p>
        )}
        {renderFormItems({ fields: ADMIN_ORGANIZATION_PARTNERSHIP_FIELDS })}
      </div>
    </div>
  );
};
