import "./index.scss";
import { FC } from "react";
import { Space } from "antd";
import { CheckCircleOutlined, UserOutlined } from "@ant-design/icons";
import { ILSAvatar } from "@/common/ui-components/data-display/avatar";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { ILSTag } from "@/common/components/data-display/tag";
import { ILSButton } from "@/common/components/data-display/buttons";
import { NOT_SET } from "@/common/constants/general";
import { OrganizationApply } from "@/common/types/models/vacancies/apply";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { ApplyStatusTitle } from "@/common/components/custom/apply-status";
import { FormatType } from "@/common/types/general/date-time";
import { getAgeFromTimestamp } from "@/common/utils/helpers/date-time/get-age-from-timestamp";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { timestampToDateTimeString } from "@/common/utils/helpers/date-time/timestamp-to-date-time-string";
import { getFormattedPhone } from "@/common/utils/helpers/string/get-formatted-phone";
import { useApply } from "@/modules/social-services/children/vacancies/hooks/use-apply";
import { getDictionaryTypeNames } from "@/common/features/vacancies-common/helpers/get-dictionary-type-names";

type IProps = {
  apply: OrganizationApply;
  onApplyConfirm: (applyId: number | undefined) => void;
  onApplyRefuse: (applyId: number | undefined) => void;
};

export const VacancyOrganizationApply: FC<IProps> = ({
  apply,
  onApplyConfirm,
  onApplyRefuse,
}) => {
  const {
    userAvatar,
    driverFullName,
    disableConfirm,
    disableRefuse,
    driverPath,
    onApplyRefuseClick,
    onApplyConfirmClick,
  } = useApply({ apply, onApplyConfirm, onApplyRefuse });

  return (
    <div className="organization-apply">
      <div className="organization-apply__header">
        <div className="organization-apply__header-left">
          <ILSAvatar
            size={60}
            src={userAvatar}
            icon={!userAvatar ? <UserOutlined /> : null}
            shape="square"
            className="organization-apply__avatar"
          />
          <div>
            <ILSLink
              to={driverPath}
              relative="path"
              className="organization-apply__name"
            >
              <div className="organization-apply__name-rating">
                <ILSTitle ellipsis={{ rows: 3 }} level={5}>
                  {driverFullName || NOT_SET}
                </ILSTitle>
              </div>
            </ILSLink>
            <p className="organization-apply__age">
              {getAgeFromTimestamp(apply?.User?.Driver?.Birthday) || NOT_SET}
            </p>
          </div>
        </div>

        <div className="organization-apply__header-right">
          <ILSButton size="middle" className="organization-apply__phone">
            {getFormattedPhone(apply?.User?.Phone) || NOT_SET}
          </ILSButton>
          <div className="organization-apply__header-right-group">
            <p className="organization-apply__applied">
              {`Отклик на вакансию от ${timestampToDateTimeString(
                apply?.Created,
                FormatType.Date
              )}`}
            </p>
            {Boolean(apply?.User?.Driver?.ReadyToStartTomorrow) && (
              <ILSTag color="green-inverse" style={{ marginRight: 0 }}>
                <Space>
                  <CheckCircleOutlined />
                  <p>Готов выйти завтра</p>
                </Space>
              </ILSTag>
            )}
          </div>
        </div>
      </div>

      <div className="organization-apply__content">
        <DetailDescription
          caption="Статус отклика"
          value={<ApplyStatusTitle status={apply?.State?.Alias} />}
          size="small"
        />
        {/* TODO: уточнить, какое значение указывать */}
        <DetailDescription
          caption="Опыт работы"
          value={apply?.User?.Driver?.FullExperienceYears}
          size="small"
        />
        <DetailDescription
          caption="Категории прав"
          value={getDictionaryTypeNames(apply?.User?.Driver?.LicenseCategory)}
          size="small"
        />
        {/* TODO: типов ТС нет в Driver на бэке */}
        {/* <DetailDescription
          caption="Типы ТС"
          value={apply?.User?.Driver?.VehicleType?.join(JoinChar.Comma)}
          size="small"
        /> */}
        <DetailDescription
          caption="Свой транспорт"
          value={getDictionaryTypeNames(apply?.User?.Driver?.OwnVehicleType)}
          size="small"
        />
      </div>

      <div className="organization-apply__actions">
        <div className="organization-apply__actions-left">
          <ILSButton
            type="primary"
            block
            onClick={onApplyConfirmClick}
            disabled={disableConfirm}
          >
            Пригласить
          </ILSButton>
          <ILSButton
            danger
            block
            onClick={onApplyRefuseClick}
            disabled={disableRefuse}
          >
            Отклонить
          </ILSButton>
        </div>
      </div>
    </div>
  );
};
