import { FC } from "react";
import { ILSPhoneButton } from "@/common/components/custom/phone-button/phone-button";

type IProps = {
  phone: string | undefined;
};

export const VacancyPhone: FC<IProps> = ({ phone }) => {
  return <ILSPhoneButton phone={phone} className="vacancy__phone" />;
};
