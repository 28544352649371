import "./index.scss";
import { FC } from "react";
import { ILSDivider } from "@/common/components/layout/divider";
import { DriverExperienceHeader } from "@/common/components/data-entry/driver-experience/header";
import { DriverExperienceList } from "@/common/components/data-entry/driver-experience/list";
import { RefDataType } from "@/common/features/dictionaries/types";

type IProps = {
  refData: RefDataType;
};

export const DriverExperience: FC<IProps> = ({ refData }) => {
  return (
    <div className="driver-experience">
      <p>Опыт</p>
      <ILSDivider className="driver-experience-divider" />
      <DriverExperienceHeader />
      <DriverExperienceList refData={refData} />
    </div>
  );
};
