import "./news-list.scss";
import { FC } from "react";
import { NewsCard } from "@/modules/start/components/news/card/news-card";
import { Article, Articles } from "@/common/api/types/article";
import { useGetArticle } from "@/common/hooks/articles/use-get-article";
import { useModal } from "@/common/hooks/use-modal";
import { NewsModal } from "@/modules/start/components/news/modal/news-modal";

interface IProps {
  articles: Articles;
}

export const NewsList: FC<IProps> = ({ articles }) => {
  const { getArticle, currentArticle, loading } = useGetArticle();
  const { showModal, isModalOpen } = useModal();

  const showArticle = (id: Article["ID"]) => {
    getArticle(id);
    showModal();
  };

  return (
    <>
      <ul className="news-list">
        {articles.map((article) => (
          <NewsCard
            article={article}
            key={article.ID}
            showArticle={showArticle}
          />
        ))}
      </ul>
      <NewsModal
        currentArticle={currentArticle}
        loading={loading}
        isModalOpen={isModalOpen}
        showModal={showModal}
      />
    </>
  );
};
