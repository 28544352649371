export type DictionaryType = {
  ID: number;
  Alias: string;
  Name: string;
};

export enum Dictionaries {
  LicenseCategory = "LicenseCategory",
  VehicleType = "VehicleType",
  VehicleBrand = "VehicleBrand",
  WheelArrangement = "WheelArrangement",
  EngineType = "EngineType",
  EcologyClass = "EcologyClass",
  Gearbox = "Gearbox",
  CabinType = "CabinType",
  VehicleCondition = "VehicleCondition",
  RegistrationMethod = "RegistrationMethod",
  PaymentFrequency = "PaymentFrequency",
  PaymentTerm = "PaymentTerm",
  WorkSchedule = "WorkSchedule",
  ExtraConditions = "ExtraConditions",
  VacancyStatus = "VacancyStatus",
  JobSearchStatus = "JobSearchStatus",
  EmploymentType = "EmploymentType",
  EducationLevel = "EducationLevel",
  OwnVehicleType = "OwnVehicleType",
  JobRegion = "JobRegion",
}
