import { FC } from "react";
import { ILSAlert } from "@/common/ui-components/data-display/alert";
import {
  DRIVER_SUCCESS_ALERT,
  DRIVER_INFO_ALERT,
} from "@/modules/social-services/children/my-info/constants";

type IProps = {
  driverProfileIsIncomplete: boolean;
};

export const MyInfoDriverAlert: FC<IProps> = ({
  driverProfileIsIncomplete,
}) => {
  const successAlert = !driverProfileIsIncomplete;

  return successAlert ? (
    <ILSAlert
      message={DRIVER_SUCCESS_ALERT.title}
      description={DRIVER_SUCCESS_ALERT.text}
      type="success"
      className="my-info__driver-alert"
      showIcon
    />
  ) : (
    <ILSAlert
      message={DRIVER_INFO_ALERT.title}
      description={DRIVER_INFO_ALERT.text}
      type="info"
      className="my-info__driver-alert"
      showIcon
    />
  );
};
