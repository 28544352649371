import { AbacPermission } from "@/common/types/core/abac";
import { ProtectedPage } from "@/pages/protected-page";
import { DriverInfoForm } from "@/modules/social-services/children/my-info/components/forms/driver";
import { useMyInfoDriverContainer } from "@/modules/social-services/children/my-info/hooks/containers/use-my-info-driver-container";

export const MyInfoDriverFormContainer = () => {
  const {
    form,
    initialValues,
    selectedCategories,
    fileList,
    refData,
    showReadyToStartTomorrow,
    showDriverLicenseField,
    membershipChecked,
    handleSubmit,
    handleCancelChange,
    handleCategoriesChange,
    handleAvatarChange,
    handleAvatarRemove,
  } = useMyInfoDriverContainer();

  return (
    <ProtectedPage perform={AbacPermission.driver}>
      <section className="my-info">
        <DriverInfoForm
          form={form}
          initialValues={initialValues}
          selectedCategories={selectedCategories}
          fileList={fileList}
          refData={refData}
          showReadyToStartTomorrow={showReadyToStartTomorrow}
          showDriverLicenseField={showDriverLicenseField}
          membershipChecked={membershipChecked}
          handleSubmit={handleSubmit}
          handleCancelChange={handleCancelChange}
          handleCategoriesChange={handleCategoriesChange}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
      </section>
    </ProtectedPage>
  );
};
