import {
  ExclamationCircleOutlined,
  FileDoneOutlined,
  LaptopOutlined,
  LoginOutlined,
  PhoneOutlined,
  ReconciliationOutlined,
  ShoppingOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { ModulePath } from "@/common/types/core/routes";
import { COLOR_PALETTE } from "@/styles/color-palette";
import { ModuleTitle } from "@/common/types/core/module";

export const SERVICE_ICON_STYLE_CONFIG = {
  fontSize: "24px",
};
export const GHOST_HEADER_ICONS_STYLE_CONFIG = {
  fontSize: "24px",
  color: COLOR_PALETTE.whitePrimary,
};

export const ACTION_HEADER_ICONS_STYLE_CONFIG = {
  fontSize: "24px",
  color: COLOR_PALETTE.whitePrimary,
};

export const ADMIN_AVAILABLE_SERVICES = [
  {
    text: "Панель администратора",
    style: SERVICE_ICON_STYLE_CONFIG,
    to: `${ModulePath.adminPanel}/${ModulePath.adminVacancies}`,
    ICON: ReconciliationOutlined,
  },
];

export const AUTH_HEADER_BUTTONS = [
  {
    text: "Вход",
    ICON: LoginOutlined,
    style: GHOST_HEADER_ICONS_STYLE_CONFIG,
    link: "auth/login",
  },
  {
    text: "Регистрация",
    ICON: UserAddOutlined,
    style: GHOST_HEADER_ICONS_STYLE_CONFIG,
    link: "auth/register",
  },
];

export const HEADER_MENU_VACANCY = {
  text: ModuleTitle.vacancies,
  ICON: LaptopOutlined,
  style: SERVICE_ICON_STYLE_CONFIG,
  to: `${ModulePath.socialServices}/${ModulePath.vacancies}`,
};

export const HEADER_MENU_DRIVER = {
  text: ModuleTitle.driverSearch,
  ICON: UserSwitchOutlined,
  style: SERVICE_ICON_STYLE_CONFIG,
  to: `${ModulePath.socialServices}/${ModulePath.driverSearch}`,
};

export const HEADER_MENU_DEFAULT_FIELDS = [
  {
    text: ModuleTitle.resources,
    ICON: ExclamationCircleOutlined,
    style: SERVICE_ICON_STYLE_CONFIG,
    to: `${ModulePath.socialServices}/${ModulePath.resources}`,
  },
  {
    text: ModuleTitle.projects,
    ICON: FileDoneOutlined,
    style: SERVICE_ICON_STYLE_CONFIG,
    to: `${ModulePath.socialServices}/${ModulePath.projects}`,
  },
  {
    text: ModuleTitle.store,
    ICON: ShoppingOutlined,
    style: SERVICE_ICON_STYLE_CONFIG,
    to: `${ModulePath.socialServices}/${ModulePath.store}`,
  },
  {
    text: ModuleTitle.contacts,
    ICON: PhoneOutlined,
    style: SERVICE_ICON_STYLE_CONFIG,
    to: `${ModulePath.socialServices}/${ModulePath.contacts}`,
  },
];

export const HEADER_MENU_FIELDS = [
  HEADER_MENU_VACANCY,
  HEADER_MENU_DRIVER,
  ...HEADER_MENU_DEFAULT_FIELDS,
];

export const HEADER_MENU_DRIVER_FIELDS = [
  HEADER_MENU_VACANCY,
  ...HEADER_MENU_DEFAULT_FIELDS,
];
