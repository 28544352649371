import { FC } from "react";
import { Space } from "antd";
import { UploadFile } from "antd/lib";
import { UploadOutlined } from "@ant-design/icons";
import { ILSCard } from "@/common/components/data-display/card";
import { RefDataType } from "@/common/features/dictionaries/types";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import {
  VACANCY_VEHICLE_CONDITIONS_FIELDS,
  VACANCY_VEHICLE_FIELDS,
  VACANCY_VEHICLE_VIDEO_FIELDS,
} from "@/common/features/vacancies-common/constants/form";
import { PHOTO_PERMITTED_EXTENSIONS } from "@/common/constants/files";
import { ILSText } from "@/common/ui-components/general/typography/text";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSUploadImage } from "@/common/ui-components/data-entry/upload/upload-image";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { normalizeFile } from "@/common/utils/helpers/components/upload/normalize-file";

type IProps = {
  refData: RefDataType;
  onRemoveFile: (file: UploadFile) => boolean;
};

export const VacanciesFormVehicleSubform: FC<IProps> = ({
  refData,
  onRemoveFile,
}) => {
  return (
    <div className="vacancies-form__content">
      <ILSCard
        title={
          <div className="vacancies-form__header">
            <ILSTitle level={4}>Технические характеристики</ILSTitle>
            <ILSText className="vacancies-form__header-subtitle">
              необязательные поля
            </ILSText>
          </div>
        }
        className="vacancies-form__card"
      >
        <div className="vacancies-form__card-body">
          {renderFormItems({ fields: VACANCY_VEHICLE_FIELDS, refData })}
        </div>
      </ILSCard>

      <ILSCard
        title="История эксплуатации и состояние"
        className="vacancies-form__card"
      >
        <div className="vacancies-form__card-body">
          {renderFormItems({
            fields: VACANCY_VEHICLE_CONDITIONS_FIELDS,
            refData,
          })}
          <ILSFormItem
            name={FormFieldName.VehiclePhotos}
            style={{ margin: 0 }}
            valuePropName="fileList"
            getValueFromEvent={normalizeFile}
          >
            <ILSUploadImage
              name={FormFieldName.VehiclePhotos}
              listType="picture-card"
              accept={PHOTO_PERMITTED_EXTENSIONS}
              className="ils-form__input"
              onRemove={onRemoveFile}
            >
              <Space direction="vertical">
                Загрузить
                <UploadOutlined />
              </Space>
            </ILSUploadImage>
          </ILSFormItem>
          {renderFormItems({
            fields: VACANCY_VEHICLE_VIDEO_FIELDS,
            refData,
          })}
        </div>
      </ILSCard>
    </div>
  );
};
