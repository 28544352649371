import "./under-construct.scss";
import { FC } from "react";
import { useIcons } from "@/common/hooks/use-icons";

interface IProps {}

export const ILSUnderConstruct: FC<IProps> = () => {
  const { ConstructIcon } = useIcons();

  return (
    <div className="ils-under-construct">
      <ConstructIcon />
      <h1>Модуль в разработке</h1>
    </div>
  );
};
