import { RatingContentType } from "@/common/types/models/rating";

export const RATING_BLOCKS_LIST = [
  RatingContentType.EducationAndEmploymentModule,
  RatingContentType.LabourSafety,
  RatingContentType.Social,
  RatingContentType.Religion,
  RatingContentType.RatingTotal,
  RatingContentType.HealthyLifeStyle,
  RatingContentType.Patriotism,
  RatingContentType.Entertainment,
  RatingContentType.CorporateCulture,
];
