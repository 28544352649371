import { SiderBannerProps } from "@/modules/start/types";

export const siderBannersWithImages: Array<SiderBannerProps> = [
  {
    title: "Всероссийский социальный автопроект",
    accentTitle: "«Новогодний грузовик» 2023-2024",
    pic: [
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/first/1.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/first/2.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/first/3.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/first/4.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/first/5.jpg`,
    ],
  },
  {
    title: "Профориентационная площадка",
    accentTitle: "«Водители России», март 2024, Московская область ",
    pic: [
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/second/1.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/second/2.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/second/3.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/second/4.jpg`,
      `${process.env.PUBLIC_URL}/assets/images/start-page/siders/second/5.jpg`,
    ],
    url: "https://driversrussia.ru/projects/voditeli-pobedy/",
  },
];

export const SIDER_BANNER_BIRTHDAYS_TITLE = "Блок дней рождений скрыт";
