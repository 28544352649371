import { FC } from "react";
import Title from "antd/es/typography/Title";
import { ProtectedPage } from "@pages/protected-page";
import { ILSUnderConstruct } from "@/common/ui-components/templates/under-construct/under-construct";
import { AbacPermission } from "@/common/types/core/abac";

interface IProps {}

export const Store: FC<IProps> = () => {
  return (
    <ProtectedPage perform={AbacPermission.authContent}>
      <Title children="Корпоративный магазин" />
      <ILSUnderConstruct />
    </ProtectedPage>
  );
};
