import { EMPTY_ARRAY_DATA, EMPTY_STRING } from "@/common/constants/general";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { ILSSelectApi } from "@/common/ui-components/data-entry/select/api";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { Dictionaries } from "@/common/types/models/dictionary";
import {
  VacanciesSidebarFilterValues,
  VacanciesToolbarFilterValues,
  VacanciesSource,
} from "@/common/features/vacancies-common/types/filters";
import { VacanciesTabStatus } from "@/common/types/models/vacancies/status";
import { filterOption } from "@/common/utils/helpers/components/select/filter-option";

export const VACANCY_SIDEBAR_FILTER_FIELDS = [
  {
    fieldProps: { field: FormFieldName.JobRegion },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.JobRegion,
      allowClear: true,
      showSearch: true,
      filterOption,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.LicenseCategory,
      label: "Открытые категории (ВУ) водительского удостоверения",
    },
    inputProps: {
      size: "default",
      mode: "multiple",
      dictionary: Dictionaries.LicenseCategory,
      allowClear: true,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.WorkSchedule,
    },
    inputProps: {
      dictionary: Dictionaries.WorkSchedule,
      showSearch: true,
      mode: "multiple",
      allowClear: true,
      filterOption,
    },
    Component: ILSSelectApi,
  },
  {
    fieldProps: {
      field: FormFieldName.OrganizationIsVerified,
      label: EMPTY_STRING,
      valuePropName: "checked",
    },
    inputProps: {
      size: "default",
      label: FORM_LABEL.OrganizationIsVerified,
      disabled: true,
    },
    Component: ILSCheckbox,
  },
  {
    fieldProps: { field: FormFieldName.PaymentFrequency },
    inputProps: {
      size: "default",
      dictionary: Dictionaries.PaymentFrequency,
      allowClear: true,
    },
    Component: ILSSelectApi,
  },
] as FormItemsFields;

/** Начальные значения для фильтров в Toolbar */
export const VACANCIES_TOOLBAR_INITIAL_VALUES: VacanciesToolbarFilterValues = {
  [FormFieldName.VacanciesSource]: VacanciesSource.Mine,
  [FormFieldName.VacanciesStatus]: VacanciesTabStatus.All,
};

/** Начальные значения для фильтров в Sidebar */
export const VACANCIES_SIDEBAR_INITIAL_VALUES: VacanciesSidebarFilterValues = {
  [FormFieldName.LicenseCategory]: EMPTY_ARRAY_DATA,
  [FormFieldName.WorkSchedule]: EMPTY_ARRAY_DATA,
  [FormFieldName.OrganizationIsVerified]: false,
  [FormFieldName.PaymentFrequency]: null,
  [FormFieldName.JobRegion]: null,
  [FormFieldName.Salary]: {
    [FormFieldName.From]: null,
    [FormFieldName.To]: null,
  },
};
