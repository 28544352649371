import "./index.scss";
import { FC, ReactNode } from "react";
import { ILSDivider } from "@/common/components/layout/divider";
import { ILSTypographyLink } from "@/common/components/data-display/link";

type IProps = {
  icon: ReactNode;
  title: string;
  value: string;
  href?: string;
};

export const ILSCardWithIcon: FC<IProps> = ({ icon, title, value, href }) => {
  return (
    <li className="card-with-icon">
      <div className="card-with-icon-icon">{icon}</div>
      <ILSDivider
        type="vertical"
        className="card-with-icon-divider hide-medium"
      />
      <div className="card-with-icon-content">
        <p className="card-with-icon-title">{title}</p>
        {href ? (
          <ILSTypographyLink
            className="card-with-icon-value"
            href={href}
            target="_blank"
          >
            {value}
          </ILSTypographyLink>
        ) : (
          <p className="card-with-icon-value">{value}</p>
        )}
      </div>
    </li>
  );
};
