import { FC } from "react";
import classNames from "classnames";
import {
  IILSButtonProps,
  ILSButton,
} from "@/common/components/data-display/buttons";
import { usePhoneButton } from "@/common/hooks/phone-button/use-phone-button";
import { ILSConfirmModal } from "@/common/components/custom/confirm-modal/confirm-modal";

type IProps = IILSButtonProps & {
  phone: string | undefined | null;
  className?: string;
};

export const ILSPhoneButton: FC<IProps> = ({ phone, className, ...props }) => {
  const {
    buttonContent,
    phoneHref,
    isModalOpen,
    showModal,
    onOkModalClick,
    onButtonClick,
  } = usePhoneButton(phone);

  return (
    <>
      <ILSButton
        size="large"
        type="primary"
        href={phoneHref}
        disabled={!phone}
        className={classNames(className)}
        onClick={onButtonClick}
        {...props}
      >
        {buttonContent}
      </ILSButton>
      <ILSConfirmModal
        open={isModalOpen}
        onCancel={showModal}
        onOk={onOkModalClick}
        content="Для просмотра номера телефона требуется авторизация."
        okText="Перейти к авторизации"
      />
    </>
  );
};
