import { isEmpty } from "lodash";
import { DriverDrivingExperience } from "@/common/features/drivers-common/types/driver-form";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";

export const drivingExperienceToValue = (
  drivingExperience: DriverDrivingExperience[]
) => {
  return drivingExperience.map((experience) => ({
    VehicleType: !isEmpty(experience?.VehicleType)
      ? experience?.VehicleType
      : EMPTY_ARRAY_DATA,
    ExperienceYears: experience?.ExperienceYears || null,
    Organization: experience?.Organization,
  }));
};
