import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { OrganizationInfo } from "@/common/types/models/account";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { OrganizationHeader } from "@/modules/social-services/children/organizations/children/components/organization-header";

type IProps = {
  organization: OrganizationInfo | null;
};

export const OrganizationMainInfo: FC<IProps> = ({ organization }) => {
  return (
    <div>
      <OrganizationHeader organization={organization} />

      <section className="organization__section">
        <div className="organization__section-column">
          <ILSTitle level={2} className="organization__section-title">
            Основная информация
          </ILSTitle>

          <div className="organization__section-content">
            <DetailDescription caption="ИНН" value={organization?.INN} />
            <DetailDescription caption="КПП" value={organization?.KPP} />
            <DetailDescription caption="ОГРН" value={organization?.OGRN} />
            <DetailDescription caption="ОКПО" value={organization?.OKPO} />
            <DetailDescription
              caption="Юридический адрес"
              value={organization?.LegalAddress}
            />
            <DetailDescription
              caption="Почтовый адрес"
              value={organization?.ActualAddress}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
