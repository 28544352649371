import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { SendQuestionType } from "@/common/api/types/send-question";
import { ContactsSendQuestionForm } from "@/modules/social-services/children/contacts/components/form";

export const LegalConsultationMain = () => {
  return (
    <div className="legal-consultation__main">
      <div className="legal-consultation__header">
        <ILSTitle
          children="Юридическая консультация"
          className="legal-consultation__title"
        />
      </div>
      <div className="legal-consultation__content">
        <ContactsSendQuestionForm
          type={SendQuestionType.LegalConsultation}
          className="contacts__form_large"
        />
      </div>
    </div>
  );
};
