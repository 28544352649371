import { isNil } from "lodash";

/**
 * Проверяем, ожидает ли организация проверки статуса партнера организации "Водители России"
 * PartnershipChecked: true - Статус партнера подтвержден
 * PartnershipChecked: false - Статус партнера отклонен
 * PartnershipChecked: null - Нет результата проверки
 */

type IProps = {
  publicOrganizationPartnership: boolean;
  partnershipChecked: number | boolean | null;
};

export const checkWaitingPartnership = ({
  publicOrganizationPartnership,
  partnershipChecked,
}: IProps): boolean => {
  /** организация запросила проверку статуса  */
  const requestedMembership = publicOrganizationPartnership;
  /** администратор еще не проверил */
  const noPartnershipCheckResult = isNil(partnershipChecked);

  return Boolean(requestedMembership && noPartnershipCheckResult);
};
