import { useAbac } from "react-abac";
import { AbacPermission } from "@/common/types/core/abac";

export const useOrganizationEmployeePermissions = () => {
  const { userHasPermissions } = useAbac();
  const isOrganizationEmployee = userHasPermissions(
    AbacPermission.organizationAndEmployee
  );
  return { isOrganizationEmployee };
};
