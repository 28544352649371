import { FC } from "react";
import { RefDataType } from "@/common/features/dictionaries/types";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { DRIVER_PERSONAL_FIELDS } from "@/common/features/drivers-common/constants/driver-form";

type IProps = {
  refData: RefDataType;
};

export const MyInfoFormPersonalData: FC<IProps> = ({ refData }) => {
  return (
    <div className="my-info__group">
      {renderFormItems({ fields: DRIVER_PERSONAL_FIELDS, refData })}
    </div>
  );
};
