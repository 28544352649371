import { UserSwitchOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { DriverSearch } from "@/modules/social-services/children/driver-search/containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { DriverDetailsModule } from "@/common/features/driver";

const module: IModuleModel = {
  title: ModuleTitle.driverSearch,
  name: ModuleName.driverSearch,
  path: ModulePath.driverSearch,
  children: {
    DriverDetailsModule,
  },
  icon: <UserSwitchOutlined />,
  element: <DriverSearch />,
  persist: false,
};

export const DriverSearchModule = defineModule(module);
