import { all } from "redux-saga/effects";
import { Task } from "redux-saga";
import { TSagasObj } from "@/common/types/core/module";

export const createSagaInjector = (runSaga: any, initialSagas: TSagasObj) => {
  const injectedSagas: Record<string, Task> = {};
  const initialKeys = Object.keys(initialSagas);
  const activeTasks: Record<string, Task> = {};

  return {
    run: () => {
      Object.entries(initialSagas).forEach(([key, sagas]) => {
        const saga = function* () {
          yield all(sagas);
        };

        const task = runSaga(saga);
        injectedSagas[key] = task;
        return task;
      });
    },
    injectSaga: (key: string, sagas: Generator[]) => {
      if (injectedSagas[key]) return injectedSagas[key];

      const saga = function* () {
        yield all(sagas);
      };

      const task = runSaga(saga);
      injectedSagas[key] = task;
      return task;
    },
    replaceSagas: (keys: string[]) => {
      if (!keys) return;

      const keysToRemove = Object.keys(injectedSagas).filter(
        (key) => ![...initialKeys, ...keys].includes(key)
      );

      keysToRemove.forEach((key) => {
        delete injectedSagas[key];
      });
    },
    // NOTE: Метод сохраняет рабочие таски саг
    setTask: (data: { taskName: string; task: Task }) => {
      const { taskName, task } = data ?? {};
      if (taskName && task) {
        activeTasks[taskName] = task;
      }
    },
    // NOTE: Метод получает рабочие таски саг
    getTask: (taskName?: string) => {
      if (taskName) {
        return {
          [taskName]: activeTasks[taskName],
        };
      }
      return activeTasks;
    },
    // NOTE: Метод удаляет таски саг
    removeTask: (taskName: string) => {
      delete activeTasks[taskName];
    },
  };
};
