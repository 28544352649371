import "./useful-link.scss";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { StartPageUsefulLinkProps } from "@/modules/start/types";
import { ILSImage } from "@/common/components/data-display/image";
import { USEFUL_LINK_PIC_SIZE } from "@/modules/start/constants/useful-links";
import { getAnchorBrowserContext } from "@/common/helpers/get-anchor-browser-context";

type IProps = StartPageUsefulLinkProps & {};

export const StartPageUsefulLink: FC<IProps> = ({ title, pic, url }) => {
  const anchorBrowserContext = getAnchorBrowserContext(url);

  return (
    <li className="useful-link">
      <NavLink to={url} target={anchorBrowserContext}>
        <div className="useful-link__group">
          <ILSImage
            src={pic}
            preview={false}
            width={USEFUL_LINK_PIC_SIZE}
            height={USEFUL_LINK_PIC_SIZE}
          />
          <p>{title}</p>
        </div>
      </NavLink>
    </li>
  );
};
