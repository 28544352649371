import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSCardWithIcon } from "@/common/components/custom/card-with-icon";
import { PROJECTS_CARDS_CONTENT } from "@/modules/social-services/children/projects/constants";

export const ProjectsMain = () => {
  return (
    <div className="projects__main">
      <div className="projects__header">
        <ILSTitle children="Проекты" className="projects__title" />
      </div>
      <ul className="projects__content">
        {PROJECTS_CARDS_CONTENT.map((cardContent) => (
          <ILSCardWithIcon {...cardContent} key={cardContent.title} />
        ))}
      </ul>
    </div>
  );
};
