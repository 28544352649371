import { isObject } from "lodash";
import { IndexedArray } from "@/common/types/general/enum";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";

export const createArrayFromErrorObject = <
  T extends Partial<Record<string, any> | string>
>(
  object?: IndexedArray<T> | Array<T> | null | (object & { message: string })
): Array<T> | [] => {
  if (!object) return EMPTY_ARRAY_DATA;
  if (!Array.isArray(object)) {
    const values = Object.values(object);
    if (values.some((value) => isObject(value))) {
      return values.reduce((result, value) => {
        if (isObject(value)) {
          return result as Array<T>;
        }
        return [...result, value];
      }, [] as any);
    }
    return Object.values(object) as Array<T>;
  }
  return Object.entries(object).some(
    ([, value]) => typeof value === "object" && value
  )
    ? object.reduce((result, elem) => {
        const error = Object.values(elem);

        if (Array.isArray(error)) {
          return [...result, ...error];
        }

        return result;
      }, [] as any)
    : object;
};
