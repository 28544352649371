import { head } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ModulePath } from "@/common/types/core/routes";
import { useRedirect } from "@/common/hooks/use-redirect";
import { getChildrenRoute } from "@/common/components/navigation/menu/section-head-horizontal-menu/helpers/get-children-route";

export const useSubModuleAsMenu = (defaultSubRoute: ModulePath) => {
  const { pathname } = useLocation();
  const submodule = getChildrenRoute(pathname, defaultSubRoute);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([submodule]);

  const onSelect = ({ key }: { key: string }) => {
    return setSelectedKeys([key]);
  };

  useEffect(() => {
    if (submodule !== head(selectedKeys)) {
      setSelectedKeys([submodule]);
    }
  }, [submodule]);

  useRedirect(submodule);
  return { selectedKeys, onSelect };
};
