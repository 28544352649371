import { call, put, takeLatest } from "redux-saga/effects";
import { omit } from "lodash";
import { ORGANIZATIONS_API } from "@/common/api/endpoints/organization";
import { AdminOrganizationsPayload } from "@/modules/admin-panel/children/admin-organizations/types/store";
import { adminOrganizationsRoutine } from "@/modules/admin-panel/children/admin-organizations/actions";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

export function* AdminOrganizationsWorker(action: AdminOrganizationsPayload) {
  const { request, success, failure, fulfill } = adminOrganizationsRoutine;
  const { operation, body, id, filters, customNotificationMessage } =
    action.payload;

  try {
    yield put(request({ operation, customNotificationMessage }));

    const { data } = yield call(ORGANIZATIONS_API.organizationsOperations, {
      operation,
      body: omit(body, [FormFieldName.AccountCode]),
      id,
      filters,
    });

    yield put(success({ operation, data, id, customNotificationMessage }));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill({ operation }));
  }
}

export function* adminOrganizationsWatcher() {
  yield takeLatest(adminOrganizationsRoutine.trigger, AdminOrganizationsWorker);
}
