import reduceReducers from "reduce-reducers";
import { QUESTIONNAIRE_STORE_INITIAL_STATE } from "@/common/features/questionnaire-common/store";
import { questionnaireReducer } from "@/common/features/questionnaire-common/reducers/questionnaire";
import { questionnaireResultsReducer } from "@/common/features/questionnaire-common/reducers/questionnaire-results";

export const questionnaireRootReducer = reduceReducers(
  QUESTIONNAIRE_STORE_INITIAL_STATE,
  questionnaireReducer,
  questionnaireResultsReducer
);
