import { FC } from "react";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { ModulePath } from "@/common/types/core/routes";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { ILSHeaderProfileRow } from "@/common/components/layout/header/profile/header-profile-row";
import { ChangePasswordModal } from "@/common/components/layout/header/profile/change-password-modal";

type IProps = {
  userProfileDetails: { caption: string; value: string | null | undefined }[];
  myInfoTitle: string;
  onLogout: VoidFunction;
  onCloseMenu: VoidFunction;
};

export const ILSHeaderProfileMenu: FC<IProps> = ({
  userProfileDetails,
  myInfoTitle,
  onLogout,
  onCloseMenu,
}) => {
  return (
    <div className="header-profile-menu">
      <ILSTitle level={5} className="header-profile-menu__title">
        Вы вошли как
      </ILSTitle>

      <ul className="header-profile-menu__details">
        {userProfileDetails.map((profileDetail) => (
          <ILSHeaderProfileRow {...profileDetail} key={profileDetail.caption} />
        ))}
      </ul>

      <div className="header-profile-menu__buttons">
        <ILSLink
          to={`/${ModulePath.socialServices}/${ModulePath.myInfo}`}
          relative="path"
        >
          <ILSButton onClick={onCloseMenu} icon={<UserOutlined />} block>
            {myInfoTitle}
          </ILSButton>
        </ILSLink>
        <ChangePasswordModal />
        <ILSButton
          onClick={onLogout}
          icon={<LogoutOutlined />}
          block
          type="primary"
        >
          Выйти
        </ILSButton>
      </div>
    </div>
  );
};
