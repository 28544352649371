import { Vacancy } from "@/common/types/models/vacancies/model";
import { User } from "@/common/types/models/user/user";
import { TimeStamp } from "@/common/types/general/date-time";

export enum VacancyApplyStatus {
  Sent = "Sent",
  Confirm = "Confirm",
  Refuse = "Refuse",
}
export type OrganizationApply = {
  ID: number;

  Vacancy?: {
    ID: Vacancy["ID"];
    Title: Vacancy["Title"];
    Owner: {
      Name: string;
      SocialRating: number | null;
      OrganizationID: number | null;
    };
  };
  User?: {
    ID: User["ID"];
    Phone: User["Phone"];
    Name: User["Name"];
    Surname: User["Surname"];
    Patronymic: User["Patronymic"];
    Avatar: User["Avatar"];
    Driver: User["Driver"];
  };

  Comment?: string;
  Created?: TimeStamp;
  Updated?: number;
  Deleted?: number;
  State?: {
    ID: number;
    Alias: VacancyApplyStatus;
    Name: string;
  };
};
