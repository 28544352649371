import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { User, UserRole } from "@/common/types/models/user/user";
import { getRolesName } from "@/common/utils/helpers/string/get-roles-name";
import { getFormattedPhone } from "@/common/utils/helpers/string/get-formatted-phone";
import { getOrganizationName } from "@/common/utils/helpers/string/get-organization-name";

export const getUserProfileDetails = (
  user: User,
  organizationAndEmployee: boolean
) => {
  const userPhone = getFormattedPhone(user.Phone);

  // NOTE: если роль Водитель, то показываем только роль водителя, пропускаем отображение названия "Администратор";
  const isDriver = user.UserRoles.some(
    (role) => role?.Alias === UserRole.driver
  );
  const userRolesName = getRolesName(user.UserRoles);
  const workingPosition = user.WorkingPosition;
  const organizationName = getOrganizationName(user.Account.Organization);

  const userDetails = [
    {
      caption: "Тел.",
      value: userPhone,
    },
    {
      caption: "Роль:",
      value: isDriver ? "Водитель" : userRolesName,
    },
  ];

  const organizationAndEmployeeDetails = organizationAndEmployee
    ? [
        {
          caption: "Должность:",
          value: workingPosition,
        },
        {
          caption: "Организация:",
          value: organizationName,
        },
      ]
    : EMPTY_ARRAY_DATA;

  return [...userDetails, ...organizationAndEmployeeDetails];
};
