import axios, { AxiosInstance, isAxiosError } from "axios";
import { handleApiError } from "@/common/api/helpers/error/handler";

export const apiInstance = () => {
  const AxiosConfig = {
    baseURL: process.env.REACT_APP_API_HOST,
    withCredentials: true,
  };

  const axiosInstance: AxiosInstance = axios.create(AxiosConfig as never);

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isAxiosError(error)) {
        handleApiError(error);
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
