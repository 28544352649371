import { createRoutine } from "redux-saga-routines";

export enum ArticlesActions {
  DICTIONARY_OPERATION = "ARTICLES/DICTIONARY_OPERATION",
  CHANGE_FILTERS = "ARTICLES/CHANGE_FILTERS",
  CURRENT_RESET = "ARTICLES/CURRENT/RESET",
}

export const articlesRoutine = createRoutine(
  ArticlesActions.DICTIONARY_OPERATION
);
export const articlesChangeFiltersRoutine = createRoutine(
  ArticlesActions.CHANGE_FILTERS
);
export const articleCurrentResetRoutine = createRoutine(
  ArticlesActions.CURRENT_RESET
);

export const ArticlesActionsForNotifications = [
  articlesRoutine,
  articlesChangeFiltersRoutine,
];
