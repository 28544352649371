import { InputProps } from "antd/es/input/Input";
import { FORBIDDEN_KEYS } from "@/common/ui-components/data-entry/form/constants/forbidden-keys";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSInput } from "@/common/ui-components/data-entry/input/text";
import { ILSInputPassword } from "@/common/ui-components/data-entry/input/password";
import { FormItemsFields } from "@/common/ui-components/data-entry/form-item/types";

export const inputProps: InputProps = {
  size: "large",
};

export const LOGIN_FIELDS = [
  {
    fieldProps: { field: FormFieldName.Login },
    inputProps: { ...inputProps, forbiddenKeys: FORBIDDEN_KEYS.SPACE },
    Component: ILSInput,
  },
  {
    fieldProps: { field: FormFieldName.Password },
    inputProps: { ...inputProps, forbiddenKeys: FORBIDDEN_KEYS.SPACE },
    Component: ILSInputPassword,
  },
] as FormItemsFields;
