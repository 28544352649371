import { MyInfoUserFormMode } from "@/modules/social-services/children/my-info/types/form";

export const MY_INFO_FORM_NAMES = {
  DRIVER: "my-info-form-driver",
  ORGANIZATION: "my-info-form-organization",
  ACCOUNT: "my-info-form-account",
  NEW_USER: "my-info-form-new-user",
};

export const MY_INFO_USER_FORM_SUBTITLE = {
  [MyInfoUserFormMode.Create]: "Создание нового пользователя",
  [MyInfoUserFormMode.Edit]: "Редактирование пользователя",
};
