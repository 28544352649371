import { QuestionnaireType } from "@/common/api/types/questionnaire";

export const getOrganizationQuestionnaireText = (
  template: QuestionnaireType | undefined
) => {
  if (!template) {
    return "Администратор сервиса еще не добавил шаблон";
  }

  return template.Available
    ? "Пожалуйста, заполните анкету для повышения Вашего социального рейтинга"
    : "Шаблон анкеты в данный момент недоступен";
};
