export const EMPTY_STRING = "";
export const EMPTY_ARRAY_DATA = [];
export const EMPTY_OBJECT_DATA = {};

export enum JoinChar {
  Semicolon = "; ",
  Dash = " – ",
  Comma = ", ",
  Space = " ",
  UnderCore = "_",
  ForwardSlash = "/",
}

export enum SplitChar {
  Semicolon = ";",
  Dash = "–",
  Comma = ",",
  Space = " ",
  UnderCore = "_",
  ForwardSlash = "/",
}

export const UPPER_ROUTE_LEVEL_PATH = "..";
export const DYNAMIC_ROUTE_CHAR = ":";

export const NOT_SET = "не указано";
export const YES_TEXT = "да";
export const NO_TEXT = "нет";
export const CONTACTS_NOT_SET = "Контакты не указаны";
