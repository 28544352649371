import { AUTH_ACTIONS_NOTIFY_STATUSES } from "@modules/auth/actions/statuses";
import { authActionsForNotifications } from "@modules/auth/actions";
import { takeLatest } from "redux-saga/effects";
import { userNotificationHook } from "@/common/hooks/user-notification-hook";
import { ModuleName } from "@/common/types/core/module";

const worker = userNotificationHook({
  defaultStatuses: AUTH_ACTIONS_NOTIFY_STATUSES,
  moduleKey: ModuleName.auth,
});

export function* authNotifierWatcher() {
  // eslint-disable-next-line no-restricted-syntax
  for (const routine of authActionsForNotifications) {
    yield takeLatest(routine.SUCCESS, worker);
    yield takeLatest(routine.REQUEST, worker);
  }
}
