import "../index.scss";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { AbacPermission } from "@/common/types/core/abac";
import { ProtectedPage } from "@/pages/protected-page";
import { ModulePath } from "@/common/types/core/routes";
import { useShowIndexModule } from "@/common/hooks/use-show-index-module";
import { DriverSearchMain } from "@/modules/social-services/children/driver-search/containers/main";

interface IProps {}

export const DriverSearch: FC<IProps> = () => {
  const { showIndexModule } = useShowIndexModule(ModulePath.driverDetails);

  return (
    <ProtectedPage perform={AbacPermission.exceptDriver}>
      {showIndexModule ? <DriverSearchMain /> : <Outlet />}
    </ProtectedPage>
  );
};
