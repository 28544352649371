import { call, put, takeLatest } from "redux-saga/effects";
import { articlesRoutine } from "@modules/admin-panel/children/admin-articles/actions";
import { ARTICLE_API } from "@/common/api/endpoints/articles";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { ArticlePayload } from "@/common/api/types/article";

const ARTICLES_OPERATIONS_WITHOUT_ALL_UPDATE = [
  OperationsEndpoint.GetAll,
  OperationsEndpoint.Get,
];

export function* articlesWorker(action: ArticlePayload) {
  const { request, success, failure, fulfill } = articlesRoutine;
  const { operation, body, id, filters, customNotificationMessage, reset } =
    action.payload;

  try {
    yield put(request({ operation, customNotificationMessage }));
    const { data } = yield call(ARTICLE_API.articlesOperation, {
      operation,
      body,
      id,
      filters,
    });

    yield put(success({ operation, data, id, customNotificationMessage }));
    reset?.();
    if (!ARTICLES_OPERATIONS_WITHOUT_ALL_UPDATE.includes(operation)) {
      // todo const currentFilters: ArticlesFilters | undefined = yield select(
      //   articlesFiltersSelector
      // );
      yield put(
        articlesRoutine.trigger({
          operation: OperationsEndpoint.GetAll,
          customNotificationMessage,
          // todo filters: currentFilters,
        })
      );
    }
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill({ operation, customNotificationMessage }));
  }
}

export function* articlesWatcher() {
  yield takeLatest(articlesRoutine.trigger, articlesWorker);
}
