import { OrganizationFormValues } from "@modules/social-services/children/my-info/types/form";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSDivider } from "@/common/components/layout/divider";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { ORGANIZATION_GENERAL_DATA } from "@/modules/social-services/children/my-info/constants/form-fields/organization";
import { ILSInputCopy } from "@/common/ui-components/data-entry/input/copy";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { MyInfoOrganizationPartnershipField } from "@/modules/social-services/children/my-info/components/forms/partnership-field";

export const MyInfoFormOrganizationData = ({
  disabled,
  initialValues,
}: {
  disabled: boolean;
  initialValues: OrganizationFormValues | null;
}) => {
  return (
    <div className="my-info__group">
      <ILSTitle level={3}>Организация</ILSTitle>
      <ILSDivider className="my-info__group-divider" />
      <div className="my-info-form__inputs-group">
        {renderFormItems({ fields: ORGANIZATION_GENERAL_DATA, disabled })}
        <ILSAllowedTo perform={AbacPermission.organization}>
          {initialValues?.AccountCode && (
            <ILSInputCopy
              field={FormFieldName.AccountCode}
              copyText={initialValues.AccountCode}
            />
          )}
          <MyInfoOrganizationPartnershipField initialValues={initialValues} />
        </ILSAllowedTo>
      </div>
    </div>
  );
};
