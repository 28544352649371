import { Driver, DriverInfo } from "@/common/types/models/user/driver";

/**
 * Аватар водителя недоступен:
 * - для неавторизованных пользователей;
 * - для авторизованных пользователей, если водитель указал не показывать аватар (!driver?.ShowAvatar);
 * Для администраторов показывать всегда.
 *
 */
export const getDriverAvatar = (
  driver: DriverInfo | Driver | null,
  adminPanel: boolean = false
) => {
  if (!driver || !("Avatar" in driver)) {
    return null;
  }

  const authorized = driver?.ShowAvatar || adminPanel;
  const avatarFileUrl = driver?.Avatar?.FileUrl;

  return authorized ? avatarFileUrl || null : null;
};
