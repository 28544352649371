import "./App.scss";
import { AbacProvider } from "react-abac";
import { Outlet } from "react-router-dom";
// import { Modal } from "antd";
// import { useEffect } from "react";
import { MainPageContainer } from "@/pages/main-page-container";
import { useAuth } from "@/common/hooks/use-auth";
import { User } from "@/common/types/models/user/user";
import { AbacPermission } from "@/common/types/core/abac";
import { ABAC_RULES } from "@/common/constants/config/abac-rules/rules";
import { AccountRole } from "@/common/types/models/account";
import { useCheckUser } from "@/common/hooks/use-check-user";

export const App = () => {
  const { user, authenticated, accountRole } = useAuth();
  useCheckUser(authenticated);

  // useEffect(() => {
  //   if (!authenticated) {
  //     Modal.info({
  //       title: "Информация",
  //       content: (
  //         <div>
  //           <p>
  //             Приложение использует Ваши пользовательские данные исключительно
  //             для корректной работы и аутентификации, включая доступ к камере.
  //           </p>
  //         </div>
  //       ),
  //       onOk() {},
  //     });
  //   }
  // }, []);

  return (
    <AbacProvider<AccountRole, AbacPermission, User>
      user={user as never}
      roles={accountRole as never}
      rules={ABAC_RULES as never}
    >
      <MainPageContainer>
        <Outlet />
      </MainPageContainer>
    </AbacProvider>
  );
};
