import { takeLatest } from "redux-saga/effects";
import { EMPTY_STRING } from "@/common/constants/general";
import { Notify } from "@/common/types/notifications/enum";
import { userNotificationHook } from "@/common/hooks/user-notification-hook";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import {
  DriversActions,
  DriversActionsForNotifications,
} from "@/common/features/drivers-common/actions";

export type DriversStatusesType = {
  [key in DriversActions]: {
    loading: Record<string, string> | string;
    success: Record<string, string> | string;
    successPlural?: string;
    error?: string;
  };
};

export const DRIVERS_STATUSES: DriversStatusesType = {
  [DriversActions.DICTIONARY_OPERATION]: {
    [Notify.Loading]: {
      [OperationsEndpoint.GetAll]: EMPTY_STRING,
      [OperationsEndpoint.Get]: EMPTY_STRING,
      [OperationsEndpoint.Update]: "Данные водителя обновляются",
    },
    [Notify.Success]: {
      [OperationsEndpoint.GetAll]: EMPTY_STRING,
      [OperationsEndpoint.Get]: EMPTY_STRING,
      [OperationsEndpoint.Update]: "Данные успешно сохранены",
    },
  },
};

const worker = userNotificationHook({
  defaultStatuses: DRIVERS_STATUSES,
  moduleKey: "DRIVERS",
});

export function* driversNotifierWatcher() {
  // eslint-disable-next-line no-restricted-syntax
  for (const routine of DriversActionsForNotifications) {
    yield takeLatest(routine.REQUEST, worker);
    yield takeLatest(routine.SUCCESS, worker);
  }
}
