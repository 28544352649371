import { FC } from "react";
import { Button, ButtonProps } from "antd";
import { getButtonIcon } from "@/common/utils/helpers/components/button/get-button-icon";

export interface IILSButtonProps extends Omit<ButtonProps, "icon"> {
  icon?: string | ButtonProps["icon"];
}

export const ILSButton: FC<IILSButtonProps> = ({
  children,
  icon,
  ...props
}) => {
  // TODO use icon
  const buttonIcon = getButtonIcon(icon);

  return (
    <Button {...props} icon={buttonIcon}>
      {children}
    </Button>
  );
};
