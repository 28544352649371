import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSDivider } from "@/common/components/layout/divider";
import { renderFormItems } from "@/common/ui-components/data-entry/form-item/helpers/render-form-items";
import { ACCOUNT_FIELDS } from "@/modules/social-services/children/my-info/constants/form-fields/account";

type IProps = {
  title?: string;
  disabled?: boolean;
};

export const MyInfoFormAccount: FC<IProps> = ({
  title = "Данные текущего пользователя",
  disabled,
}) => {
  return (
    <div className="my-info__group">
      <ILSTitle level={3}>{title}</ILSTitle>
      <ILSDivider className="my-info__group-divider" />
      {renderFormItems({ fields: ACCOUNT_FIELDS, disabled })}
    </div>
  );
};
