import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  questionnaireFetchingSelector,
  questionnaireSelector,
} from "@/common/features/questionnaire-common/selectors";
import { useQuestionnaireOperations } from "@/common/features/questionnaire-common/hooks/use-questionnaire-operations";
import { getQuestionnaireAvailabilityStatus } from "@/common/features/questionnaire-common/helpers/get-questionnaire-availability-status";
import { useAdminQuestionnaireStatusForm } from "@/modules/admin-panel/children/admin-applications/hooks/questionnaire/status-form";
import { useAdminQuestionnaireUpload } from "@/modules/admin-panel/children/admin-applications/hooks/questionnaire/questionnaire-upload";

export const useAdminQuestionnaire = () => {
  const questionnaire = useSelector(questionnaireSelector);
  const questionnaireIsFetching = useSelector(questionnaireFetchingSelector);
  const availabilityStatus = getQuestionnaireAvailabilityStatus(questionnaire);

  const { handleGetQuestionnaire } = useQuestionnaireOperations();

  useEffect(() => {
    if (!questionnaire?.File) {
      handleGetQuestionnaire();
    }
  }, []);

  /** управление загрузкой, удалением, скачиванием шаблона анкеты */
  const {
    documents,
    disableUploadDelete,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  } = useAdminQuestionnaireUpload({
    questionnaire,
    availabilityStatus,
  });

  /** управление сменой статуса доступности шаблона анкеты */
  const { form, onQuestionnaireAvailabilityChange } =
    useAdminQuestionnaireStatusForm({ availabilityStatus });

  return {
    documents,
    disableUploadDelete,
    availabilityStatus,
    form,
    questionnaireIsFetching,
    onQuestionnaireAvailabilityChange,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  };
};
