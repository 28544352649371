import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useIcons } from "@/common/hooks/use-icons";
import { ModulePath } from "@/common/types/core/routes";
import { ILSButton } from "@/common/components/data-display/buttons";

export const NotFound = () => {
  const { Error404 } = useIcons();

  const navigate = useNavigate();
  const onClickGoBack = () => navigate(-1);

  return (
    <div className="not-found">
      <div className="not-found__content">
        <Error404 />
        <h1>Страница не найдена</h1>
        <div className="not-found__buttons">
          <ILSButton danger onClick={onClickGoBack}>
            Назад
          </ILSButton>
          <ILSButton href={ModulePath.startPage} type="primary">
            На главную
          </ILSButton>
        </div>
      </div>
    </div>
  );
};
