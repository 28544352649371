import { FC } from "react";
import { ILSAlert } from "@/common/ui-components/data-display/alert";

type IProps = {
  comment: string | undefined;
};

export const VacancyCommentAlert: FC<IProps> = ({ comment }) => {
  if (!comment) return null;

  return (
    <ILSAlert
      type="error"
      message="Вакансия отклонена модератором"
      description={`Комментарий: ${comment}`}
      className="vacancy__comment-alert"
      showIcon
    />
  );
};
