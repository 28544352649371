import { FC, useRef } from "react";
import { CarouselRef } from "antd/es/carousel";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSCarousel } from "@/common/components/data-display/carousel";
import { ILSImage } from "@/common/components/data-display/image";

type IProps = {
  carouselPictures: string[];
};

export const SiderCarousel: FC<IProps> = ({ carouselPictures }) => {
  const carouselRef = useRef<CarouselRef>(null);

  return (
    <div className="sider-carousel">
      <ILSButton
        icon={<ArrowLeftOutlined />}
        className="sider-carousel__arrow left"
        onClick={() => carouselRef?.current?.prev()}
      />
      <ILSCarousel
        carouselRef={carouselRef}
        dots={false}
        swipe={false}
        draggable
      >
        {carouselPictures?.map((picture) => (
          <div key={picture} className="sider-carousel__picture">
            <ILSImage src={picture} />
          </div>
        ))}
      </ILSCarousel>
      <ILSButton
        icon={<ArrowRightOutlined />}
        className="sider-carousel__arrow right"
        onClick={() => carouselRef?.current?.next()}
      />
    </div>
  );
};
