import { ApiGet } from "@/common/api/helpers/get";
import { getStringifierUrl } from "@/common/api/helpers/stringify-url";
import { CodeCheck } from "@/common/api/types/code";

export const CODE_API = {
  sendCode: () => ApiGet("/code/send"),

  getCodeCheck: (query: CodeCheck) =>
    ApiGet(getStringifierUrl("/code/check", query)),
};
