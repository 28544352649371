import { useSelector } from "react-redux";
import {
  currentArticleSelector,
  isFetchingSelector,
} from "@modules/admin-panel/children/admin-articles/selectors/articles";
import { EMPTY_STRING } from "@/common/constants/general";
import { useCurrentArticleReset } from "@/common/hooks/articles/use-current-article-reset";

export const useCurrentArticle = () => {
  const { resetArticle } = useCurrentArticleReset();
  const currentArticle = useSelector(currentArticleSelector);
  const loading = useSelector(isFetchingSelector);
  const currentArticleActionTitle = currentArticle?.ID
    ? `Редактирование статьи ${currentArticle.Title || EMPTY_STRING} №${
        currentArticle.ID
      }`
    : "Добавление новой статьи";

  return {
    currentArticle,
    currentArticleActionTitle,
    loading,
    resetArticle,
  };
};
