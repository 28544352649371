import { UploadFile, UploadFileStatus } from "antd/es/upload/interface";
import { isArray, isEmpty, isNil } from "lodash";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { ImageType } from "@/common/types/models/image";

export const getImageToForm = (image: ImageType) => {
  return {
    uid: image.ID?.toString(),
    name: image.OriginalName,
    status: "done" as UploadFileStatus,
    url: image.FileUrl,
  };
};

/**
 * Функция преобразовывает файлы изображений c бэка в UploadFile для формы.
 * Принимает либо одно изображение, либо массив.
 * Возвращает массив UploadFile[]
 */
export const getImagesToForm = (
  images: ImageType | ImageType[] | undefined | null
): UploadFile[] | undefined => {
  if (isArray(images)) {
    if (isEmpty(images)) return EMPTY_ARRAY_DATA;

    return images?.map((file) => {
      return getImageToForm(file);
    });
  }

  return !isEmpty(images) ? [getImageToForm(images)] : undefined;
};
