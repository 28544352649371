import "./useful-links-list.scss";
import { ILSDivider } from "@/common/components/layout/divider";
import { StartPageSiderBanner } from "@/modules/start/components/sider-banners/sider-banner/sider-banner";
import { SiderBannerTitle } from "@/modules/start/components/sider-banners/sider-banner-title/sider-banner-title";
import { startPageUsefulLinks } from "@/modules/start/constants/useful-links";
import { StartPageUsefulLink } from "@/modules/start/components/useful-links/link/useful-link";

export const StartPageUsefulLinks = () => {
  return (
    <StartPageSiderBanner>
      <div className="useful-links">
        <SiderBannerTitle>Полезные ссылки</SiderBannerTitle>
        <ILSDivider className="useful-links__divider" />
        <ul className="useful-links__list">
          {startPageUsefulLinks.map((link) => (
            <StartPageUsefulLink {...link} key={link.title} />
          ))}
        </ul>
      </div>
    </StartPageSiderBanner>
  );
};
