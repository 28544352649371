import { UploadFile } from "antd";
import { MyInfoContentType } from "@/modules/social-services/children/my-info/types";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { DriverFormValues } from "@/common/features/drivers-common/types/driver-form";

/** Значения полей формы в зависимости от роли */
export type MyInfoFormValuesType = {
  [MyInfoContentType.Driver]: DriverFormValues;
  [MyInfoContentType.Organization]: OrganizationFormValues;
  [MyInfoContentType.Account]: AccountFormValues;
};

export type MyInfoFormDrivingExperience = {
  [FormFieldName.VehicleType]: Array<string>;
  [FormFieldName.ExperienceYears]: number | null;
  [FormFieldName.Organization]?: string;
};

/** Значения полей формы "Организация" */
export type OrganizationFormValues = {
  [FormFieldName.Avatar]: UploadFile[] | undefined;
  [FormFieldName.OrganizationName]: string;
  [FormFieldName.OrganizationTypeID]: number | null;
  [FormFieldName.Phone]: string;
  [FormFieldName.INN]: string;
  [FormFieldName.KPP]: string;
  [FormFieldName.OGRN]: string;
  [FormFieldName.OKPO]: string;
  [FormFieldName.LegalAddress]: string;
  [FormFieldName.ActualAddress]: string;
  [FormFieldName.AccountCode]: string;
  [FormFieldName.PublicOrganizationPartnership]: boolean;
  [FormFieldName.PartnershipChecked]: number | null;
};

/** Значения полей формы "Сотрудник" */
export type AccountFormValues = {
  [FormFieldName.Avatar]: UploadFile[] | undefined;
  [FormFieldName.Name]: string;
  [FormFieldName.Surname]: string;
  [FormFieldName.Patronymic]: string;
  [FormFieldName.Phone]: string;
  [FormFieldName.Email]: string;
  [FormFieldName.WorkingPosition]: string;
};

export enum MyInfoUserFormMode {
  Create = "Create",
  Edit = "Edit",
}
