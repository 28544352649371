import { PUBLIC_ORGANIZATION_TITLE_ACCENT } from "@/common/features/drivers-common/constants";

type IProps = {
  hasConfirmedMembership: boolean;
};

/**
 * функция проверяет, какой подзаголовок разместить
 * в карточке водителя
 */
export const getDriverMembershipTitle = ({
  hasConfirmedMembership,
}: IProps) => {
  if (hasConfirmedMembership) {
    return PUBLIC_ORGANIZATION_TITLE_ACCENT;
  }

  return null;
};
