import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authIsFetchingSelector } from "@modules/auth/selectors/auth-fetching";
import { loginActions } from "@modules/auth/actions";
import { LoginRequest } from "@/common/api/types/auth";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(authIsFetchingSelector);

  const onFinish = (values: LoginRequest) => {
    try {
      dispatch(
        loginActions({
          ...values,
          navigate,
        })
      );
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  return {
    onFinish,
    loading,
  };
};
