import { EMPTY_STRING } from "@/common/constants/general";
import { ALL_NEWS_ARTICLE_CATEGORY_TREE_DATA } from "@/modules/admin-panel/children/admin-articles/constants/form";
import { Article } from "@/common/api/types/article";
import { compareAsString } from "@/common/helpers/compare-as-string";

export const useNewsModal = (article: Article | null) => {
  const category =
    ALL_NEWS_ARTICLE_CATEGORY_TREE_DATA.find(({ value }) =>
      compareAsString(value, article?.CategoryID)
    )?.title || "Без категории";
  const chips: Array<string> = (article?.Tags ?? []).concat(category);
  const cover = article?.Image?.FileUrl || EMPTY_STRING;

  return { chips, cover };
};
