import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";

type IProps = {
  filters: VacanciesFilters | undefined;
  changeFilters: (currentFilters: VacanciesFilters | undefined) => void;
};

export const useVacanciesSort = ({ filters, changeFilters }: IProps) => {
  const activeSortKey = filters?.OrderBy;

  const onSortClick = ({ key }: { key: string }) => {
    changeFilters({ OrderBy: key });
  };

  return { activeSortKey, onSortClick };
};
