import { ADMIN_PANEL_SERVICES_MODULE_CHILDREN } from "@modules/admin-panel/constants/admin-panel";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { getMenuItem } from "@/common/ui-components/navigation/helpers/get-menu-item";

export const ADMIN_PANEL_MENU_ITEMS = Object.values(
  ADMIN_PANEL_SERVICES_MODULE_CHILDREN
).map((module) => {
  return getMenuItem(
    <ILSLink to={module.path}>{module.title}</ILSLink>,
    module.name
  );
});
