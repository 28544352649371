import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

type IProps = {
  form: FormInstance;
};

export const useShowDriverLicenseField = ({ form }: IProps) => {
  const haveDriverLicense = useWatch(FormFieldName.HaveDriverLicense, form);

  return { showDriverLicenseField: haveDriverLicense };
};
