import { FC } from "react";
import { Link } from "react-router-dom";
import { AUTH_HEADER_BUTTONS } from "@/common/constants/config/header";
import { ILSButton } from "@/common/components/data-display/buttons";

type IProps = {
  onCloseMenu: VoidFunction;
};

export const ILSAuthMenu: FC<IProps> = ({ onCloseMenu }) => {
  return (
    <div className="header-component__auth">
      {AUTH_HEADER_BUTTONS.map(({ text, ICON, style, link }) => {
        return (
          <Link key={link} to={link}>
            <ILSButton
              type="primary"
              icon={<ICON style={style} />}
              className="header-component__auth-button"
              onClick={onCloseMenu}
              block
            >
              {text}
            </ILSButton>
          </Link>
        );
      })}
    </div>
  );
};
