import { EMPTY_STRING } from "@/common/constants/general";
import { FORM_LABEL } from "@/common/ui-components/data-entry/form/constants/label";
import { FORM_RULES } from "@/common/ui-components/data-entry/form/constants/rules";
import { ILSCheckbox } from "@/common/ui-components/data-entry/checkbox";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ModulePath } from "@/common/types/core/routes";

export const ConsentToDataProcessingField = () => {
  return (
    <ILSFormItem
      name={FormFieldName.ConsentToDataProcessing}
      label={EMPTY_STRING}
      rules={FORM_RULES.DrivingExperience}
      valuePropName="checked"
      className="consent-to-data-processing"
    >
      <div className="consent-to-data-processing__checkbox">
        <ILSCheckbox label={FORM_LABEL.ConsentToDataProcessing} />
        <ILSLink
          to={`/${ModulePath.dataProcessingRules}`}
          relative="path"
          className="consent-to-data-processing__link"
          target="_blank"
        >
          обработку персональных данных
        </ILSLink>
      </div>
    </ILSFormItem>
  );
};
