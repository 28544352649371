import { ArticlesActions } from "@modules/admin-panel/children/admin-articles/actions";
import { EMPTY_STRING } from "@/common/constants/general";
import { Notify } from "@/common/types/notifications/enum";

export type ArticlesNotifyStatuses = {
  [key in ArticlesActions]: {
    loading: Record<string, string> | string;
    success: Record<string, string> | string;
    successPlural?: string;
    error?: string;
  };
};
export const ARTICLES_ACTIONS_NOTIFY_STATUSES: ArticlesNotifyStatuses = {
  [ArticlesActions.DICTIONARY_OPERATION]: {
    [Notify.Loading]: EMPTY_STRING,
    [Notify.Success]: EMPTY_STRING,
  },
  [ArticlesActions.CHANGE_FILTERS]: {
    [Notify.Loading]: EMPTY_STRING,
    [Notify.Success]: EMPTY_STRING,
  },
  [ArticlesActions.CURRENT_RESET]: {
    [Notify.Loading]: EMPTY_STRING,
    [Notify.Success]: EMPTY_STRING,
  },
};
