import "./index.scss";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useVacanciesModule } from "@/common/features/vacancies-common/hooks/use-vacancies-module";
import { useAdminVacancies } from "@/modules/admin-panel/children/admin-vacancies/hooks/use-admin-vacancies";
import { AdminVacanciesToolbar } from "@/modules/admin-panel/children/admin-vacancies/containers/toolbar";
import { AdminVacanciesFilters } from "@/modules/admin-panel/children/admin-vacancies/containers/filters";
import { AdminVacanciesList } from "@/modules/admin-panel/children/admin-vacancies/containers/list";

interface IProps {}

export const AdminVacancies: FC<IProps> = () => {
  const { showIndexModule } = useVacanciesModule();
  const {
    vacancies,
    loading,
    toolbarForm,
    sidebarForm,
    sidebarRefData,
    activeSortKey,
    onToolbarFormChange,
    onSidebarFormChange,
    onClickResetSidebarForm,
    onSortClick,
  } = useAdminVacancies(showIndexModule);

  if (showIndexModule) {
    return (
      <div className="admin-vacancies">
        <AdminVacanciesToolbar
          toolbarForm={toolbarForm}
          sidebarForm={sidebarForm}
          sidebarRefData={sidebarRefData}
          activeSortKey={activeSortKey}
          onToolbarFormChange={onToolbarFormChange}
          onSidebarFormChange={onSidebarFormChange}
          onClickResetSidebarForm={onClickResetSidebarForm}
          onSortClick={onSortClick}
        />
        <div className="admin-vacancies__content">
          <AdminVacanciesFilters
            sidebarForm={sidebarForm}
            sidebarRefData={sidebarRefData}
            onSidebarFormChange={onSidebarFormChange}
            onClickResetSidebarForm={onClickResetSidebarForm}
          />
          <AdminVacanciesList vacancies={vacancies} loading={loading} />
        </div>
      </div>
    );
  }

  return <Outlet />;
};
