import { head } from "lodash";
import { UploadFile } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuestionnaireResultsOperations } from "@/common/features/questionnaire-common/hooks/use-questionnaire-results-operations";
import { DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE } from "@/common/constants/files";
import {
  questionnaireResultsSelector,
  questionnaireResultsFetchingSelector,
} from "@/common/features/questionnaire-common/selectors";
import { getFileToBase64WithoutOriginFileObj } from "@/common/utils/helpers/components/upload/get-file-to-base-64";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { handleCheckSize } from "@/common/utils/helpers/components/image-crop";
import { getDataStringFromBase64 } from "@/common/utils/helpers/regexp/get-data-string-from-base64";
import { getQuestionnaireResultsUploadFiles } from "@/modules/admin-panel/children/admin-applications/helpers/get-questionnaire-results-upload-files";

export const useMyInfoQuestionnaireResult = () => {
  const results = useSelector(questionnaireResultsSelector);
  const resultDocuments = getQuestionnaireResultsUploadFiles(results);
  const isFetching = useSelector(questionnaireResultsFetchingSelector);

  const {
    handleGetQuestionnaireResults,
    handleSaveQuestionnaireResult,
    handleDeleteQuestionnaireResult,
  } = useQuestionnaireResultsOperations();

  useEffect(() => {
    handleGetQuestionnaireResults();
  }, []);

  const handleUploadDocuments = async (files?: Array<UploadFile>) => {
    const filesPromises = getFileToBase64WithoutOriginFileObj(files);
    try {
      Promise.all(filesPromises).then((base64files: string[]) => {
        const questionnaireFile = {
          Data: getDataStringFromBase64(head(base64files)),
          Name: head(files)?.name,
        };
        handleSaveQuestionnaireResult(questionnaireFile);
      });
    } catch (e) {
      showUserDevErrors({ e });
    }
  };

  const handleCheckBeforeUpload = (file?: UploadFile) => {
    if (file && handleCheckSize(file, DEFAULT_PERMITTED_UPLOAD_FILE_MAX_SIZE)) {
      handleUploadDocuments([file]);
    }
  };

  const handleDeleteDocument = (fileID: string | number | undefined) => {
    if (fileID) {
      handleDeleteQuestionnaireResult(Number(fileID));
    }
  };

  return {
    resultDocuments,
    resultIsFetching: isFetching,
    handleCheckBeforeUpload,
    handleDeleteDocument,
  };
};
