import { FC } from "react";
import { EditFilled } from "@ant-design/icons";
import { ILSButton } from "@/common/components/data-display/buttons";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { useModal } from "@/common/hooks/use-modal";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { VacancyFormType } from "@/common/features/vacancies-common/types/form";
import { VACANCIES_OPEN_FORM_BUTTON } from "@/common/features/vacancies-common/constants/form";
import { VacancyFormModal } from "@/common/features/vacancies-common/components/form/modal";

interface IProps {
  vacancy?: Vacancy;
  type: VacancyFormType;
}

export const VacancyFormModalButton: FC<IProps> = ({ vacancy, type }) => {
  const { isModalOpen, showModal } = useModal();

  const accessPermission =
    type === VacancyFormType.Create
      ? AbacPermission.organizationAndEmployee
      : AbacPermission.hasActionsWithVacancies;
  return (
    <ILSAllowedTo perform={accessPermission} data={vacancy}>
      <ILSButton
        onClick={showModal}
        children={VACANCIES_OPEN_FORM_BUTTON[type]}
        icon={type === VacancyFormType.Edit ? <EditFilled /> : undefined}
        block={type === VacancyFormType.Edit}
        type="primary"
      />
      <VacancyFormModal
        type={type}
        vacancyId={vacancy?.ID}
        isModalOpen={isModalOpen}
        showModal={showModal}
      />
    </ILSAllowedTo>
  );
};
