import { FC } from "react";
import { ChangePassword } from "@modules/auth/containers/change-password";
import { Modal } from "antd";
import { useModal } from "@/common/hooks/use-modal";
import { ILSButton } from "@/common/components/data-display/buttons";

export const ChangePasswordModal: FC = () => {
  const { showModal, isModalOpen } = useModal();

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={showModal}
        onCancel={showModal}
        footer={null}
        centered
        destroyOnClose
      >
        <ChangePassword onClose={showModal} />
      </Modal>
      <ILSButton onClick={showModal} block>
        Сменить пароль
      </ILSButton>
    </>
  );
};
