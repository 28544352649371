import { AxiosResponse } from "axios";
import { ApiPost } from "@/common/api/helpers/post";
import {
  SendQuestionBaseRequest,
  SendQuestionType,
} from "@/common/api/types/send-question";

export const getSendQuestionEndpoint = (type: SendQuestionType) => {
  return type === SendQuestionType.PresidentQuestion
    ? "/sendPresidentQuestion"
    : "/sendSupportQuestion";
};

export function sendQuestionRequest({
  type,
  body,
}: SendQuestionBaseRequest): Promise<AxiosResponse> {
  const sendQuestionEndpoint = getSendQuestionEndpoint(type);

  return ApiPost(sendQuestionEndpoint, body);
}

export const SEND_QUESTION_API = {
  sendQuestionRequest,
};
