import { FC } from "react";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { DetailDescription } from "@/common/components/custom/detail-description";
import { getDictionaryTypeNames } from "@/common/features/vacancies-common/helpers/get-dictionary-type-names";

type IProps = {
  vacancy?: Vacancy;
};

export const VacancyWorkingConditions: FC<IProps> = ({ vacancy }) => {
  return (
    <section className="vacancy__section">
      <div className="vacancy__section-column">
        <ILSTitle level={2} className="vacancy__section-title">
          Условия работы
        </ILSTitle>
        <div className="vacancy__section-content">
          <DetailDescription
            caption="График работы"
            value={getDictionaryTypeNames(vacancy?.Payment?.WorkSchedule)}
          />
          <DetailDescription
            caption="Способ оформления"
            value={getDictionaryTypeNames(vacancy?.RegistrationMethod)}
          />
          <DetailDescription
            caption="Условия оплаты"
            value={getDictionaryTypeNames(vacancy?.Payment?.Term)}
          />
          <DetailDescription
            caption="Частота выплат"
            value={getDictionaryTypeNames(vacancy?.Payment?.Frequency)}
          />
          <DetailDescription
            caption="Что получают работники"
            value={getDictionaryTypeNames(vacancy?.Extra)}
          />
          <DetailDescription
            caption="Место работы"
            value={vacancy?.Info?.Address}
          />
          <DetailDescription
            caption="Город"
            value={getDictionaryTypeNames(vacancy?.JobRegion)}
          />
        </div>
      </div>
    </section>
  );
};
