import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { StartPage } from "@/modules/start/containers";
import * as surveyRootSagas from "@/modules/start/sagas";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

const module: IModuleModel = {
  title: ModuleTitle.startPage,
  name: ModuleName.startPage,
  path: ModulePath.startPage,
  element: <StartPage />,
  persist: false,
  sagas: getAllModuleSagas(surveyRootSagas),
};

export const StartPageModule = defineModule(module);
