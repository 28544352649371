import { ForbiddenPage } from "@pages/forbidden-page";
import { MyInfoContentType } from "@/modules/social-services/children/my-info/types";
import { MyInfoDriverFormContainer } from "@/modules/social-services/children/my-info/containers/forms/driver";
import { MyInfoTabsContainer } from "@/modules/social-services/children/my-info/containers/tabs";

type IProps = {
  myInfoContentType: MyInfoContentType | null;
};

export const renderMyInfoContent = ({ myInfoContentType }: IProps) => {
  switch (myInfoContentType) {
    case MyInfoContentType.Driver:
      return <MyInfoDriverFormContainer />;

    case MyInfoContentType.Account:
    case MyInfoContentType.Organization:
      return <MyInfoTabsContainer />;

    default:
      return <ForbiddenPage />;
  }
};
