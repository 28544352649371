import { FC } from "react";
import { VacancyPrice } from "@/common/types/models/vacancies/model";
import { getFormattedPrice } from "@/common/utils/helpers/string/get-formatted-price";
import { CurrencySign } from "@/common/constants/general/currencies";

type IProps = {
  price?: VacancyPrice;
};

export const VacancyPrices: FC<IProps> = ({ price }) => {
  return price?.From || price?.To ? (
    <div className="vacancy__prices">
      <p className="vacancy__price">
        от {getFormattedPrice(price?.From)} {CurrencySign.RUB}
      </p>
      {price?.To && price?.To !== price?.From && (
        <p className="vacancy__price">
          до {getFormattedPrice(price?.To)} {CurrencySign.RUB}
        </p>
      )}
    </div>
  ) : (
    <p className="vacancy__price">Оплата не указана</p>
  );
};
