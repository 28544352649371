import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUsersActions } from "@modules/social-services/children/my-info/actions";
import {
  usersFetchingSelector,
  usersSelector,
} from "@modules/social-services/children/my-info/selectors/users";
import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";

export const useUsers = (activeTab: MyInfoTabs) => {
  const dispatch = useDispatch();
  const allUsers = useSelector(usersSelector);
  const isFetching = useSelector(usersFetchingSelector);

  useEffect(() => {
    if (activeTab === MyInfoTabs.Users) {
      dispatch(getUsersActions());
    }
  }, [activeTab]);

  return { allUsers, isFetching };
};
