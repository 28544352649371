import { FC } from "react";
import { PUBLIC_ORGANIZATION_PARTNER_TITLE_FULL } from "@/common/features/drivers-common/constants";
import { ILSTag } from "@/common/components/data-display/tag";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { ILSMembershipBadge } from "@/common/components/custom/membership-badge";
import { checkOrganizationPartnership } from "@/common/features/organizations-common/helpers/check-organization-partnership";

type IProps = {
  vacancy: Vacancy;
};

export const VacancyHeader: FC<IProps> = ({ vacancy }) => {
  const hasConfirmedPartnership = checkOrganizationPartnership(vacancy?.Owner);

  return (
    <div className="vacancy__header">
      <div className="vacancy__header-row">
        <ILSTitle className="vacancy__title">
          {vacancy?.Title}
          {hasConfirmedPartnership && (
            <ILSMembershipBadge className="vacancy__partnership-badge" />
          )}
        </ILSTitle>
        <ILSTag className="vacancy__ribbon">{`ID: ${vacancy?.ID}`}</ILSTag>
      </div>
      {hasConfirmedPartnership && (
        <ILSTitle ellipsis level={4} className="vacancy__partnership-title">
          {PUBLIC_ORGANIZATION_PARTNER_TITLE_FULL}
        </ILSTitle>
      )}
    </div>
  );
};
