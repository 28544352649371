import { FC } from "react";
import { Modal } from "antd";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { VacancyCommentForm } from "@/common/features/vacancy/components/comment/form";
import { useVacancyCommentForm } from "@/common/features/vacancy/hooks/use-vacancy-comment-form";

interface IProps {
  vacancyId: number | undefined;
  isModalOpen: boolean;
  showModal: VoidFunction;
}

export const VacancyCommentModal: FC<IProps> = ({
  vacancyId,
  isModalOpen,
  showModal,
}) => {
  const { onSubmit } = useVacancyCommentForm(vacancyId, showModal);

  return (
    <Modal
      open={isModalOpen}
      onOk={showModal}
      onCancel={showModal}
      footer={null}
      centered
      destroyOnClose
    >
      <ILSTitle level={4}>Комментарий для организации</ILSTitle>
      <p>
        Вы можете оставить комментарий, чтобы создатель вакансии узнал причину
        отказа и внес изменения в вакансию
      </p>
      <VacancyCommentForm onSubmit={onSubmit} />
    </Modal>
  );
};
