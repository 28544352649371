import { isEqual, pick } from "lodash";
import { User } from "@/common/types/models/user/user";
import { AccountRole, OrganizationInfo } from "@/common/types/models/account";
import {
  DRIVER_FIELDS,
  DRIVER_USER_FIELDS,
  ORGANIZATION_FIELDS,
  USER_FIELDS,
} from "@/common/constants/config/check-user-fields";

export const checkUserEqual = (newUser: User, currentUser: User) => {
  const isOrganization = newUser?.Account?.AccountRoles?.some(
    (accountRole) => accountRole?.Alias === AccountRole.organization
  );

  if (isOrganization) {
    const organizationNewFields = pick(
      (newUser?.Account?.Organization as OrganizationInfo[])?.[0],
      ORGANIZATION_FIELDS
    );
    const organizationOldFields = pick(
      (currentUser?.Account?.Organization as OrganizationInfo[])?.[0],
      ORGANIZATION_FIELDS
    );
    const organizationIsEqual = isEqual(
      organizationNewFields,
      organizationOldFields
    );

    const userOldFields = pick(newUser, USER_FIELDS);
    const userNewFields = pick(currentUser, USER_FIELDS);
    const userIsEqual = isEqual(userOldFields, userNewFields);

    return organizationIsEqual && userIsEqual;
  }

  const isDriver = newUser?.Account?.AccountRoles?.some(
    (accountRole) => accountRole?.Alias === AccountRole.driver
  );

  if (isDriver) {
    const newDriverFields = pick(newUser?.Driver, DRIVER_FIELDS);
    const oldDriverFields = pick(currentUser?.Driver, DRIVER_FIELDS);
    const driverIsEqual = isEqual(newDriverFields, oldDriverFields);

    const oldUserFields = pick(newUser, DRIVER_USER_FIELDS);
    const newUserFields = pick(currentUser, DRIVER_USER_FIELDS);
    const userIsEqual = isEqual(oldUserFields, newUserFields);

    return driverIsEqual && userIsEqual;
  }

  const isEmployee = newUser?.Account?.AccountRoles?.some(
    (accountRole) => accountRole?.Alias === AccountRole.employee
  );

  if (isEmployee) {
    const userOldFields = pick(newUser, USER_FIELDS);
    const userNewFields = pick(currentUser, USER_FIELDS);
    return isEqual(userOldFields, userNewFields);
  }

  // для администратора
  return true;
};
