import { PayloadAction } from "@reduxjs/toolkit";
import { call, takeLatest } from "redux-saga/effects";
import { INotifyPayload } from "@/common/types/notifications/actions";
import { userNotifyRoutine } from "@/core/actions/notification";
import { ILSUserNotifyClose } from "@/common/components/user-notify/user-notify-close";
import { ILSUserNotify } from "@/common/components/user-notify/user-notify";
import { NotifyDurationInSecond } from "@/common/types/notifications/enum";

function* notifyWorker(action: PayloadAction<INotifyPayload>) {
  const { type, status, icon, placement, key, duration } = action.payload;

  yield call(ILSUserNotify, {
    type,
    content: status,
    duration: duration || Number(NotifyDurationInSecond.Ten),
    key: key || Date.now(),
    icon,
    placement,
    showFull: false,
  } as any);
}

function* closeWorker(action: PayloadAction<{ key: string }>) {
  const { key } = action.payload;
  yield call(ILSUserNotifyClose, key);
}

/**
 * Watcher для отображения уведомлений
 */
export function* userNotifyWatcher() {
  yield takeLatest(userNotifyRoutine.trigger, notifyWorker);
}

/**
 * Watcher для закрытия уведомлений
 */
export function* userNotifyCloseWatcher() {
  yield takeLatest(userNotifyRoutine.FULFILL, closeWorker);
}
