import moment from "moment";
import { DateFormat } from "@/common/types/general/date-time";
import { noUnderScore } from "@/common/constants/regex";

export const getUnixFromDateString = (date: string | null | undefined) => {
  if (!date || !noUnderScore.test(date)) {
    return null;
  }

  const momentDate = moment(date, DateFormat.DDMMYYYYPeriod);

  if (!momentDate.isValid()) {
    return null;
  }

  return momentDate.unix();
};
