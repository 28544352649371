import { EMPTY_STRING } from "@/common/constants/general";
import {
  logisticServicesGroupList,
  logisticServicesTitles,
} from "@/modules/social-services/children/resources/constants/logistic-servises";
import { LogisticServicesColumn } from "@/modules/social-services/children/resources/components/logistic-services/column";

export const LogisticServicesList = () => {
  return (
    <div className="logistic-services__list">
      {logisticServicesGroupList.map((serviceGroup) => (
        <div
          key={logisticServicesTitles[serviceGroup].title}
          className="logistic-services__group"
        >
          <div className="logistic-services__group-title" key={serviceGroup}>
            <p>{logisticServicesTitles[serviceGroup].title}</p>
            <p className="subtitle">
              {logisticServicesTitles[serviceGroup]?.subtitle || EMPTY_STRING}
            </p>
          </div>
          <LogisticServicesColumn
            serviceGroup={serviceGroup}
            key={serviceGroup}
          />
        </div>
      ))}
    </div>
  );
};
