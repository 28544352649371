import { FileDoneOutlined } from "@ant-design/icons";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { Projects } from "@/modules/social-services/children/projects/containers";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";

const module: IModuleModel = {
  title: ModuleTitle.projects,
  name: ModuleName.projects,
  path: ModulePath.projects,
  icon: <FileDoneOutlined />,
  element: <Projects />,
  persist: false,
};

export const ProjectsModule = defineModule(module);
