import "./ils-file-dragger-upload.scss";
import { FC } from "react";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { UploadFile } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { ILSDragger } from "@/common/ui-components/data-entry/upload/dragger";
import { ILSFileCard } from "@/common/components/custom/file-card";
import { preventAntdRequest } from "@/common/utils/helpers/components/avatar/prevent-ant-request";

export type IILSFileDraggerUploadProps = {
  fieldName: FormFieldName;
  documents: UploadFile[] | undefined;
  disableUpload: boolean;
  disableDelete: boolean;
  showDeleteButton?: boolean;
  permittedExtensions: string;
  dropzoneTitle?: string;
  handleCheckBeforeUpload: (file?: UploadFile) => void;
  handleDeleteDocument: (ID: string | number | undefined) => void;
};

export const ILSFileDraggerUpload: FC<IILSFileDraggerUploadProps> = ({
  fieldName,
  documents,
  disableUpload,
  disableDelete,
  showDeleteButton,
  permittedExtensions,
  dropzoneTitle = "Загрузить",
  handleCheckBeforeUpload,
  handleDeleteDocument,
}) => {
  return (
    <div className="ils-file-dragger-upload">
      <ILSDragger
        name={fieldName}
        defaultFileList={documents}
        disabled={disableUpload}
        accept={permittedExtensions}
        beforeUpload={handleCheckBeforeUpload}
        customRequest={preventAntdRequest}
        multiple={false}
        showUploadList={false}
      >
        <div
          className={classNames("ils-file-dragger-upload__dropzone", {
            disabled: disableUpload,
          })}
        >
          <div className="ils-file-dragger-upload__dropzone-heading">
            <PaperClipOutlined />
            <span>{dropzoneTitle}</span>
          </div>
          <span>
            Допустимые форматы:
            <span>{permittedExtensions}</span>
          </span>
        </div>
      </ILSDragger>
      {!isEmpty(documents) &&
        documents?.map((document) => (
          <ILSFileCard
            fileID={document?.uid}
            fileName={document?.name}
            fileUrl={document?.url}
            showDeleteButton={showDeleteButton}
            disableDelete={disableDelete}
            handleDeleteDocument={handleDeleteDocument}
          />
        ))}
    </div>
  );
};
