import { compareAsString } from "@/common/helpers/compare-as-string";
import { SelectApiOption } from "@/common/types/general/common";

export const getOptionName = (
  options?: SelectApiOption[],
  ID?: number | null
): string | number | null => {
  return (
    options?.find((option) => {
      return compareAsString(option?.value, ID);
    })?.label || null
  );
};
