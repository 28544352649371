import { VacanciesTabStatus } from "@/common/types/models/vacancies/status";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";
import { SortKeys } from "@/common/constants/config/sort";

/**
 * Фильтры по-умолчанию для вакансий:
 * Для администратора: без фильтров по статусам и без AccountID;
 * Для организации и сотрудника: фильтр по AccountID (Таб - "Только мои"), без фильтра по статусам;
 * Для остальных: статус - "Опубликованные";
 */
export const getVacanciesDefaultFilters = ({
  isAdminPanel = false,
  isOrganizationEmployee,
  accountID,
}: {
  isAdminPanel: boolean;
  isOrganizationEmployee: boolean;
  accountID: number | null;
}): VacanciesFilters => {
  const defaultSort = { OrderBy: SortKeys.IDDesc };

  if (isAdminPanel) {
    return defaultSort;
  }

  if (isOrganizationEmployee) {
    return {
      AccountID: accountID,
      VacancyStatusID: undefined,
      ...defaultSort,
    };
  }

  return {
    VacancyStatusID: VacanciesTabStatus.Published,
    ...defaultSort,
  };
};
