import { isUndefined, omitBy } from "lodash";
import { apiInstance } from "@/common/api/helpers/instance";
import { APIOptions } from "@/common/api/types/api";
import {
  DEFAULT_API_OPTIONS,
  DEFAULT_CONTENT_TYPE,
} from "@/common/api/constants";

export const ApiPatch = <T>(
  endpoint: string,
  body?: object,
  options: APIOptions = DEFAULT_API_OPTIONS
): Promise<T> => {
  const { contentType } = options;
  return apiInstance()
    .patch<T>(endpoint, omitBy(body, isUndefined), {
      headers: {
        "Content-Type": contentType || DEFAULT_CONTENT_TYPE,
      },
    })
    .then((r) => r.data);
};
