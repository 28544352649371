import { FC } from "react";
import { ILSText } from "@/common/ui-components/general/typography/text";

type IProps = {
  caption: string;
  value: string | null | undefined;
};

export const ILSHeaderProfileRow: FC<IProps> = ({ caption, value }) => {
  return (
    <li className="header-profile-menu__row">
      <p>{caption}</p>
      <ILSText ellipsis>{value || "не указано"}</ILSText>
    </li>
  );
};
