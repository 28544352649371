import { Radio } from "antd";
import { noop } from "lodash";

export const ArticleSort = () => {
  return (
    <div className="admin-articles-sort">
      <div className="admin-articles-sort-title">Сортировать по:</div>
      <Radio.Group onChange={noop} defaultValue="a" buttonStyle="solid">
        <Radio.Button value="a">Дате</Radio.Button>
        <Radio.Button value="b">Алфавиту</Radio.Button>
        <Radio.Button value="c">Рейтингу</Radio.Button>
      </Radio.Group>
    </div>
  );
};
