import { FC, ReactNode } from "react";
import { StepsForm } from "@ant-design/pro-components";
import { StepFormProps } from "@ant-design/pro-form/es/layouts/StepsForm/StepForm";

interface IProps extends StepFormProps {
  children: ReactNode;
}

const { StepForm } = StepsForm;

export const ILSStepsFormStep: FC<IProps> = (props) => {
  return <StepForm {...props} />;
};
