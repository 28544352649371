import { FC } from "react";
import { Form } from "antd";
import Title from "antd/es/typography/Title";
import { AuthButton } from "@modules/auth/components/button";
import { useRegister } from "@modules/auth/children/register/hooks/use-register";
import { RegisterFormFields } from "./register-form-fields";
import {
  REGISTER_CONTENT,
  REGISTER_FORM_NAME,
  REGISTER_INITIAL_VALUES,
} from "../constants/form";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { ModulePath } from "@/common/types/core/routes";

interface IProps {}

export const Register: FC<IProps> = () => {
  const { onFinish, loading } = useRegister();

  return (
    <Form
      name={REGISTER_FORM_NAME}
      onFinish={onFinish}
      layout="vertical"
      className={`auth-form ${REGISTER_FORM_NAME}`}
      initialValues={REGISTER_INITIAL_VALUES}
    >
      <Title className="auth-title" children={REGISTER_CONTENT} />
      <RegisterFormFields />
      <AuthButton form={REGISTER_FORM_NAME} loading={loading}>
        {REGISTER_CONTENT}
      </AuthButton>
      <ILSLink to={`/${ModulePath.auth}/${ModulePath.login}`} relative="path">
        Уже зарегистрированы? Войти
      </ILSLink>
    </Form>
  );
};
