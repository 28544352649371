import { isNil } from "lodash";

type IProps = {
  partnershipChecked: number | null | undefined;
};

export const useMyOrganizationPartnershipField = ({
  partnershipChecked = null,
}: IProps) => {
  let helpText: string =
    "Соискатели увидят Ваш статус партнера после проверки администратором";

  if (!isNil(partnershipChecked)) {
    helpText = partnershipChecked
      ? "Статус партнера подтвержден администратором. Если убрать статус, результат проверки будет сброшен"
      : "Статус партнера отклонен, Вы можете отправить повторно на проверку";
  }

  return { helpText };
};
