import "./index.scss";
import { FC, RefAttributes } from "react";
import Title, { TitleProps } from "antd/es/typography/Title";
import classNames from "classnames";

interface IProps extends TitleProps, RefAttributes<HTMLElement> {}

export const ILSTitle: FC<IProps> = ({ className, ...props }) => {
  return (
    <Title ellipsis {...props} className={classNames("ils-title", className)} />
  );
};
