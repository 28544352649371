import { useDispatch } from "react-redux";
import { showUserDevErrors } from "@/common/helpers/errors/show-user-dev-errors";
import { SendQuestionType } from "@/common/api/types/send-question";
import { sendQuestionRoutine } from "@/modules/social-services/children/contacts/actions";

export const useSendQuestion = (type: SendQuestionType) => {
  const dispatch = useDispatch();

  const handleSendQuestion = (question: string, onFinally?: VoidFunction) => {
    try {
      dispatch(sendQuestionRoutine({ body: { Question: question }, type }));
    } catch (e) {
      showUserDevErrors({ e });
    } finally {
      onFinally?.();
    }
  };

  return { handleSendQuestion };
};
