import { FC } from "react";
import { Space, UploadFile } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { PHOTO_PERMITTED_EXTENSIONS } from "@/common/constants/files";
import { ILSFormItem } from "@/common/ui-components/data-entry/form-item";
import { ILSUploadImage } from "@/common/ui-components/data-entry/upload/upload-image";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { normalizeFile } from "@/common/utils/helpers/components/upload/normalize-file";

type IProps = {
  onRemoveFile: (file: UploadFile) => boolean;
};

export const ArticlePictureUpload: FC<IProps> = ({ onRemoveFile }) => {
  return (
    <ILSFormItem
      field={FormFieldName.Image}
      valuePropName="fileList"
      getValueFromEvent={normalizeFile}
    >
      <ILSUploadImage
        maxCount={1}
        name={FormFieldName.Image}
        listType="picture-card"
        accept={PHOTO_PERMITTED_EXTENSIONS}
        onRemove={onRemoveFile}
      >
        <Space direction="vertical">
          Загрузить картинку
          <UploadOutlined />
        </Space>
      </ILSUploadImage>
    </ILSFormItem>
  );
};
