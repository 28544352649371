import "moment/locale/ru";
import moment from "moment";
import { EMPTY_STRING } from "@/common/constants/general";

export const getDurationString = (unit: string, value?: number | null) => {
  if (!value) {
    return null;
  }

  const durationString = moment
    .duration({
      [unit]: value,
    })
    .locale("ru")
    .humanize();

  return `${value === 1 ? "1 " : EMPTY_STRING}${durationString}`;
};
