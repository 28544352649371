import { authRootReducer } from "@modules/auth/reducers";
import { RecoveryModule } from "@modules/auth/children/recovery";
import { AUTH_INITIAL_STATE, AuthStore } from "@modules/auth/store";
import { defineModule } from "@/core/modules/define-module";
import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
  TPersistTransform,
} from "@/common/types/core/module";
import { ModulePath } from "@/common/types/core/routes";
import { RegisterModule } from "@/modules/auth/children/register";
import { LoginModule } from "@/modules/auth/children/login";
import { Auth } from "./containers";
import * as authRootSagas from "./sagas";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

export const persistTransform: TPersistTransform<AuthStore> = {
  initialState: AUTH_INITIAL_STATE,
  onPersist: (inboundState: AuthStore) => {
    return {
      ...inboundState,
      recoveryProcess: inboundState?.recoveryProcess || "recovery",
    };
  },
};

const module: IModuleModel = {
  title: ModuleTitle.auth,
  name: ModuleName.auth,
  path: ModulePath.auth,
  element: <Auth />,
  children: { RegisterModule, LoginModule, RecoveryModule },
  reducer: authRootReducer,
  sagas: getAllModuleSagas(authRootSagas),
  persist: persistTransform,
};

export const AuthModule = defineModule(module);
