import { FC } from "react";
import { MY_INFO_TAB_NAMES } from "@/modules/social-services/children/my-info/constants";
import { ILSTabPane, ILSTabs } from "@/common/components/data-display/tabs";
import { MyInfoTabs } from "@/modules/social-services/children/my-info/types";
import { MyInfoUsersContainer } from "@/modules/social-services/children/my-info/containers/users/my-info-users-container";
import { MyInfoAccountFormContainer } from "@/modules/social-services/children/my-info/containers/forms/account";
import { MyInfoOrganizationFormContainer } from "@/modules/social-services/children/my-info/containers/forms/organization";
import { useMyInfoTabs } from "@/modules/social-services/children/my-info/hooks/use-my-info-tabs";
import { MyRatingContainer } from "@/modules/social-services/children/my-info/containers/rating/my-rating";
import { MyInfoQuestionnaireContainer } from "@/modules/social-services/children/my-info/containers/questionnaire/questionnaire-container";

type IProps = {};

export const MyInfoTabsContainer: FC<IProps> = () => {
  const { isOrganization, activeTab, handleChangeTab } = useMyInfoTabs();

  return (
    <ILSTabs
      type="line"
      tabPosition="top"
      className="my-info__tabs"
      activeKey={activeTab}
      onTabClick={handleChangeTab}
    >
      <ILSTabPane
        tab={MY_INFO_TAB_NAMES[MyInfoTabs.MyOrganization]}
        key={MyInfoTabs.MyOrganization}
      >
        <MyInfoOrganizationFormContainer activeTab={activeTab} />
      </ILSTabPane>
      <ILSTabPane
        tab={MY_INFO_TAB_NAMES[MyInfoTabs.Account]}
        key={MyInfoTabs.Account}
      >
        <MyInfoAccountFormContainer />
      </ILSTabPane>
      {isOrganization && (
        <>
          <ILSTabPane
            tab={MY_INFO_TAB_NAMES[MyInfoTabs.Users]}
            key={MyInfoTabs.Users}
          >
            <MyInfoUsersContainer activeTab={activeTab} />
          </ILSTabPane>
          <ILSTabPane
            tab={MY_INFO_TAB_NAMES[MyInfoTabs.MyRating]}
            key={MyInfoTabs.MyRating}
          >
            <MyRatingContainer />
          </ILSTabPane>
          <ILSTabPane
            tab={MY_INFO_TAB_NAMES[MyInfoTabs.Questionnaire]}
            key={MyInfoTabs.Questionnaire}
          >
            <MyInfoQuestionnaireContainer />
          </ILSTabPane>
        </>
      )}
    </ILSTabs>
  );
};
