import { useState } from "react";

export const useModal = (isOpen: boolean = false) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  const showModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    showModal,
    handleOpen,
    handleClose,
  };
};
