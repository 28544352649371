import { createSelector } from "reselect";
import { EMPTY_ARRAY_DATA } from "@/common/constants/general";
import { Vacancies } from "@/common/types/models/vacancies/model";
import { VacanciesModuleStore } from "@/common/features/vacancies-common/types/store";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";
import { OrganizationApply } from "@/common/types/models/vacancies/apply";

export const vacanciesSelector = createSelector(
  (state: VacanciesModuleStore) => {
    return state.VacanciesCommonModule?.vacancies;
  },
  (vacancies: Vacancies) => vacancies || ([] as Vacancies)
);

export const vacanciesFetchingSelector = createSelector(
  (state: VacanciesModuleStore) => {
    return state.VacanciesCommonModule?.isFetching;
  },
  (isFetching: boolean) => isFetching
);

export const vacanciesFiltersSelector = createSelector(
  (state: VacanciesModuleStore) => {
    return state.VacanciesCommonModule?.filters;
  },
  (filters: VacanciesFilters | undefined) => filters
);

export const appliesListSelector = createSelector(
  (state: VacanciesModuleStore) => state.VacanciesCommonModule?.appliesList,
  (appliesList: OrganizationApply[]) => {
    return appliesList || EMPTY_ARRAY_DATA;
  }
);
