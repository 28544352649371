import "../index.scss";
import { useDriverDetails } from "@/common/features/driver/hooks/use-driver-details";
import { DriverDetailsContent } from "@/common/features/driver/containers/content";
import { DriverDetailsSidebar } from "@/common/features/driver/containers/sidebar";

export const DriverDetailsContainer = () => {
  const { driver, driverAvatar } = useDriverDetails();

  return (
    <div className="driver-details">
      <DriverDetailsContent driver={driver} driverAvatar={driverAvatar} />
      <DriverDetailsSidebar driver={driver} />
    </div>
  );
};
