import {
  IModuleModel,
  ModuleName,
  ModuleTitle,
} from "@/common/types/core/module";
import { defineModule } from "@/core/modules/define-module";
import { ModulePath } from "@/common/types/core/routes";
import { driversRootReducer } from "@/common/features/drivers-common/reducers";
import * as driversSagas from "@/common/features/drivers-common/sagas";
import { DictionariesModule } from "@/common/features/dictionaries";
import { getAllModuleSagas } from "@/common/helpers/get-all-modules-sagas";

const module: IModuleModel = {
  title: ModuleTitle.driversCommon,
  name: ModuleName.driversCommon,
  path: ModulePath.driversCommon,
  children: { DictionariesModule },
  reducer: driversRootReducer,
  sagas: getAllModuleSagas(driversSagas),
};

export const DriversCommonModule = defineModule(module);
