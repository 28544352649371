import { createReducer } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { TModulesObj, TReducersObj } from "@/common/types/core/module";
import { flatModules } from "../modules/flat-modules";

export const getModuleReducers = (
  modules: TModulesObj,
  reducers: TReducersObj = {}
): TReducersObj => {
  const flat = flatModules(modules);

  for (let i = 0; i < flat.length; i++) {
    if (Object.prototype.hasOwnProperty.call(flat, i)) {
      const { reducer } = flat[i];

      if (typeof reducer === "function") {
        if (flat[i].MODULE) {
          const index = flat[i].MODULE;
          if (index) reducers[index] = reducer;
        }
      }

      if (!isEmpty(flat[i].children)) {
        getModuleReducers(flat[i].children!, reducers);
      }
    }
  }

  if (!isEmpty(reducers)) {
    reducers.default = createReducer(null, {});
  }

  return reducers;
};
