import "./index.scss";
import { FC } from "react";
import { isEmpty } from "lodash";
import { ILSCarousel } from "@/common/components/data-display/carousel";
import { ILSImage } from "@/common/components/data-display/image";
import { ILSButton } from "@/common/components/data-display/buttons";
import { Vacancy } from "@/common/types/models/vacancies/model";
import { useVehiclePhotos } from "@/common/features/vacancy/hooks/use-vehicle-photos";
import { VacancyPreviewImage } from "@/common/features/vacancy/components/vehicle-photos/preview-image";
import { VacancyVehiclePhotosModal } from "@/common/features/vacancy/components/vehicle-photos/modal";

type IProps = {
  vacancy?: Vacancy;
};

export const VehiclePhotos: FC<IProps> = ({ vacancy }) => {
  const vehiclePhotos = vacancy?.Vehicle?.Profile?.Image;

  const {
    isModalOpen,
    restPhotosCount,
    previewPhotos,
    carouselRef,
    modalCarouselRef,
    showModal,
    handleOpenModal,
    handlePreviewImage,
  } = useVehiclePhotos({ vehiclePhotos });

  if (isEmpty(vehiclePhotos?.Data)) {
    return null;
  }

  return (
    <>
      <div className="vacancy__vehicle-photos">
        <ILSCarousel carouselRef={carouselRef}>
          {previewPhotos?.map((vehiclePhoto) => (
            <div
              key={vehiclePhoto.FileUrl}
              className="vacancy__vehicle-main-photo"
            >
              <ILSImage
                src={vehiclePhoto.FileUrl}
                preview={false}
                onClick={handleOpenModal}
              />
            </div>
          ))}
        </ILSCarousel>
        <div className="vacancy__vehicle-previews-list hide-medium">
          {previewPhotos?.map((vehiclePhoto, slideIndex) => (
            <VacancyPreviewImage
              imageUrl={vehiclePhoto?.FileUrl}
              slideIndex={slideIndex}
              isLastSlide={slideIndex === previewPhotos.length - 1}
              restPhotosCount={restPhotosCount}
              handlePreviewImage={handlePreviewImage}
              handleOpenModal={handleOpenModal}
            />
          ))}
        </div>

        <ILSButton
          className="vacancy__vehicle-photos-button"
          onClick={handleOpenModal}
        >{`Еще фото (${vehiclePhotos?.Data?.length})`}</ILSButton>
      </div>
      <VacancyVehiclePhotosModal
        modalCarouselRef={modalCarouselRef}
        vehiclePhotos={vehiclePhotos}
        isModalOpen={isModalOpen}
        showModal={showModal}
      />
    </>
  );
};
