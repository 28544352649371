import { createRoutine } from "redux-saga-routines";

export enum QuestionnaireActions {
  QUESTIONNAIRE_ACTION = "QUESTIONNAIRE/QUESTIONNAIRE_ACTION",
  RESULTS_ACTION = "QUESTIONNAIRE/RESULTS_ACTION",
}

export const questionnaireActionRoutine = createRoutine(
  QuestionnaireActions.QUESTIONNAIRE_ACTION
);

export const questionnaireResultsRoutine = createRoutine(
  QuestionnaireActions.RESULTS_ACTION
);
