import "../index.scss";
import { FC, PropsWithChildren } from "react";
import classNames from "classnames";

interface IProps extends PropsWithChildren {
  className?: string;
}

export const SocialServicesToolbar: FC<IProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames("social-services__toolbar", className)}
    >
      {children}
    </div>
  );
};
