import { ItemType } from "antd/es/menu/interface";
import { ILSLink } from "@/common/ui-components/general/typography/link";
import { usePopover } from "@/common/hooks/use-popover";
import { useDriverPermissions } from "@/common/hooks/permissions/use-driver-permissions";
import {
  HEADER_MENU_DRIVER_FIELDS,
  HEADER_MENU_FIELDS,
} from "@/common/constants/config/header";
import { getMenuItem } from "@/common/ui-components/navigation/helpers/get-menu-item";

export const useHeaderBurgerMenu = () => {
  const { handleOpenChange, open } = usePopover();
  const { isDriver } = useDriverPermissions();

  const onClickClosePopover = () => handleOpenChange(false);
  const modules = isDriver ? HEADER_MENU_DRIVER_FIELDS : HEADER_MENU_FIELDS;

  const menuItems: ItemType[] = modules.map(({ text, ICON, to }) => {
    return getMenuItem(
      <ILSLink to={to} className="header-menu__item">
        {text}
      </ILSLink>,
      text,
      <ICON />
    );
  });

  return { open, menuItems, handleOpenChange, onClickClosePopover };
};
