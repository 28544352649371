import "./sider-banner-with-image.scss";
import { FC } from "react";
import { StartPageSiderBanner } from "@/modules/start/components/sider-banners/sider-banner/sider-banner";
import { SiderBannerProps } from "@/modules/start/types";
import { SiderCarousel } from "@/modules/start/components/sider-banners/sider-banner-with-image/carousel";

type IProps = SiderBannerProps & {};

export const StartPageSiderBannerWithImage: FC<IProps> = ({
  title,
  accentTitle,
  pic,
}) => {
  return (
    <StartPageSiderBanner>
      <div className="sider-banner-image">
        <div className="sider-banner-image__header">
          <p>{title}</p>
          {accentTitle && <p>{accentTitle}</p>}
        </div>

        <SiderCarousel carouselPictures={pic} />
      </div>
    </StartPageSiderBanner>
  );
};
