import { useState } from "react";
import { debounce } from "lodash";
import { useForm } from "antd/es/form/Form";
import { DriversFiltersRequestType } from "@/common/api/types/driver";
import { DriversFiltersFormValues } from "@/common/features/drivers-common/types/drivers-filters";
import { JobSearchStatusType } from "@/common/types/models/user/job-search-status";
import { formValuesToDriversFilters } from "@/common/features/drivers-common/helpers/form-values-to-drivers-filters";
import { DRIVERS_SEARCH_FILTERS_INITIAL_VALUES } from "@/common/features/drivers-common/constants/drivers-filters";

export const useDriverSearchFilters = () => {
  const [form] = useForm<DriversFiltersFormValues>();

  const [filters, setFilters] = useState<DriversFiltersRequestType>({
    JobSearchStatus: JobSearchStatusType.ActiveSearch,
  });

  const onValuesChange = debounce(
    (_: DriversFiltersFormValues, values: DriversFiltersFormValues) => {
      const formFilters = formValuesToDriversFilters(values);
      setFilters({
        ...formFilters,
        JobSearchStatus: JobSearchStatusType.ActiveSearch,
      });
    },
    500
  );

  const resetFilters = () => {
    setFilters({
      JobSearchStatus: JobSearchStatusType.ActiveSearch,
    });
    form.resetFields();
    form.setFieldsValue(DRIVERS_SEARCH_FILTERS_INITIAL_VALUES);
  };

  return {
    form,
    filters,
    onValuesChange,
    resetFilters,
  };
};
