import { FC } from "react";
import { Form, FormInstance } from "antd";
import {
  ILSFormAvatar,
  IILSFormAvatarProps,
} from "@/common/components/custom/form-avatar";
import { ILSButton } from "@/common/components/data-display/buttons";
import { AdminUserFormFields } from "@/modules/admin-panel/children/admin-organizations/children/organization/components/forms/user-form-fields";
import { AdminUserFormValues } from "@/modules/admin-panel/children/admin-organizations/children/organization/types/user-form";

type IProps = IILSFormAvatarProps & {
  form: FormInstance<AdminUserFormValues>;
  handleSubmit: (values: AdminUserFormValues) => void;
  handleCancel: VoidFunction;
};

export const AdminUserForm: FC<IProps> = ({
  form,
  fileList,
  handleSubmit,
  handleCancel,
  handleAvatarChange,
  handleAvatarRemove,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      className="admin-organization-form"
    >
      <div className="admin-organization-form__column">
        <ILSFormAvatar
          fileList={fileList}
          handleAvatarChange={handleAvatarChange}
          handleAvatarRemove={handleAvatarRemove}
        />
      </div>
      <div className="admin-organization-form__column admin-organization-form__column_wide">
        <AdminUserFormFields />
        <div className="admin-organization-form__buttons">
          <ILSButton onClick={handleCancel}>Отмена</ILSButton>
          <ILSButton type="primary" htmlType="submit">
            Сохранить
          </ILSButton>
        </div>
      </div>
    </Form>
  );
};
