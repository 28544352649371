import "./index.scss";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { DATA_PROCESSING_RULES } from "@/modules/data-processing-rules/constants";

export const DataProcessingRulesContainer = () => {
  return (
    <div className="data-processing-rules">
      <div className="data-processing-rules__content">
        <div className="data-processing-rules__header">
          <ILSTitle level={2}>
            Политика конфиденциальности персональных данных
          </ILSTitle>
        </div>
        <p className="data-processing-rules__text">{DATA_PROCESSING_RULES}</p>
      </div>
    </div>
  );
};
