import { replaceAllExceptNumbers } from "@/common/utils/helpers/regexp/replace-all-expect-numbers";
import { EMPTY_STRING } from "@/common/constants/general";

export const getUnmaskedPhone = (phoneNumber?: string | number): string => {
  if (!phoneNumber) {
    return EMPTY_STRING;
  }

  const stringNumber = String(phoneNumber);
  return replaceAllExceptNumbers(stringNumber);
};
