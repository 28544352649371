import { isEmpty } from "lodash";
import { ALL_STATUSES_VALUE } from "@/common/constants/config/options";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { DriversFiltersRequestType } from "@/common/api/types/driver";
import { DriversFiltersFormValues } from "@/common/features/drivers-common/types/drivers-filters";
import { JobSearchStatusType } from "@/common/types/models/user/job-search-status";
import { getObjectKeysWithType } from "@/common/utils/helpers/types";
import { getUnmaskedNumberValue } from "@/common/utils/helpers/components/input/get-unmasked-value";
import { DRIVING_EXPERIENCE_VALUES } from "@/common/features/drivers-common/constants/drivers-filters";

export const formValuesToDriversFilters = (
  formValues: DriversFiltersFormValues
): DriversFiltersRequestType => {
  return getObjectKeysWithType<FormFieldName>(formValues).reduce(
    (filters, formKey) => {
      const activateAgeField = formValues.ActivateAgeField;
      switch (formKey) {
        case FormFieldName.EmploymentType:
        case FormFieldName.Sex:
        case FormFieldName.EducationLevel:
          filters[formKey] = formValues?.[formKey] ?? null;
          return filters;

        case FormFieldName.JobSearchStatus:
          filters.JobSearchStatus =
            formValues?.[formKey] !== ALL_STATUSES_VALUE
              ? (formValues?.[formKey] as JobSearchStatusType)
              : null;
          return filters;

        case FormFieldName.Citizens:
          filters[formKey] = formValues?.[formKey] || null;
          return filters;

        case FormFieldName.WorkSchedule:
        case FormFieldName.LicenseCategory:
        case FormFieldName.OwnVehicleType:
        case FormFieldName.JobSearchRegion:
          filters[formKey] = !isEmpty(formValues?.[formKey])
            ? formValues?.[formKey]
            : null;
          return filters;

        case FormFieldName.DriverLicenseMoreThanThree:
        case FormFieldName.MedicalCertificate:
        case FormFieldName.ReadyToStartTomorrow:
        case FormFieldName.ReadyForRelocation:
        case FormFieldName.ReadyForTrips:
        case FormFieldName.WaitingForMembershipCheck:
          // Отправлять либо 1, либо null, то есть параметр не будет отправляться.
          // фильтровать по 0 (значение - "Не важно") не нужно.
          filters[formKey] = Number(formValues?.[formKey]) || null;
          return filters;

        case FormFieldName.DrivingExperience:
          if (
            formValues?.[formKey]?.[0] === DRIVING_EXPERIENCE_VALUES.MIN &&
            formValues?.[formKey]?.[1] === DRIVING_EXPERIENCE_VALUES.MAX
          ) {
            return filters;
          }
          filters.DrivingExperienceFrom = formValues?.[formKey]?.[0];
          filters.DrivingExperienceTo = formValues?.[formKey]?.[1];
          return filters;

        case FormFieldName.Age:
          filters.AgeFrom = activateAgeField
            ? formValues?.[formKey]?.[0]
            : null;
          filters.AgeTo = activateAgeField ? formValues?.[formKey]?.[1] : null;
          return filters;

        case FormFieldName.Salary:
          filters.SalaryFrom =
            getUnmaskedNumberValue(formValues?.[formKey]?.From) || null;
          filters.SalaryTo =
            getUnmaskedNumberValue(formValues?.[formKey]?.To) || null;
          return filters;

        default:
          return filters;
      }
    },
    {} as DriversFiltersRequestType
  );
};
