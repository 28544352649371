import "./news-card.scss";
import { FC } from "react";
import classNames from "classnames";
import { LikeOutlined } from "@ant-design/icons";
import { ILSCard, ILSCardMeta } from "@/common/components/data-display/card";
import { ILSTitle } from "@/common/ui-components/general/typography/title";
import { ILSButton } from "@/common/components/data-display/buttons";
import { ILSBadgeRibbon } from "@/common/components/data-display/badge-ribbon";
import { NewsCardCover } from "@/modules/start/components/news/card/news-card-cover";
import { NewsCardFooter } from "@/modules/start/components/news/card/news-card-footer";
import { NewsStatus } from "@/common/types/models/news";
import { Article } from "@/common/api/types/article";
import { useNewsCard } from "@/modules/start/hooks/use-news-card";

type IProps = {
  article: Article;
  showArticle(id: Article["ID"]): void;
};

export const NewsCard: FC<IProps> = ({ article, showArticle }) => {
  const {
    tagsText,
    cover,
    category,
    Title,
    Created,
    isLiked,
    onClickLike,
    onCardClick,
  } = useNewsCard({ article, showArticle });

  return (
    <li className="news-card">
      <ILSBadgeRibbon text={tagsText}>
        <ILSCard
          onClick={onCardClick}
          bordered
          cover={<NewsCardCover status={NewsStatus.Active} imageSrc={cover} />}
          size="small"
          hoverable
        >
          <div className="news-card__content">
            <div>
              <div className="news-card__type">
                <p>{category}</p>
              </div>
              <ILSCardMeta
                title={
                  <ILSTitle
                    level={5}
                    ellipsis={{ rows: 4 }}
                    className="news-card__title"
                  >
                    {Title}
                  </ILSTitle>
                }
              />
            </div>
            <div>
              <ILSButton
                icon={<LikeOutlined />}
                type="text"
                size="small"
                onClick={onClickLike}
                className={classNames("news-card__likes", { active: isLiked })}
              >
                <p>{article?.LikesCount}</p>
              </ILSButton>
              <NewsCardFooter date={Created} views={article.ViewCount} />
            </div>
          </div>
        </ILSCard>
      </ILSBadgeRibbon>
    </li>
  );
};
