import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import { onRequest } from "@/common/helpers/reducers/on-request";
import { onFailure } from "@/common/helpers/reducers/on-failure";
import { onFulfill } from "@/common/helpers/reducers/on-fulfill";
import { ADMIN_ORGANIZATIONS_INITIAL_STATE } from "@/modules/admin-panel/children/admin-organizations/store";
import { IndexedCollection } from "@/common/types/general/common";
import { OrganizationInfo } from "@/common/types/models/account";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { adminOrganizationsRoutine } from "@/modules/admin-panel/children/admin-organizations/actions";

export const adminOrganizationsReducer = createReducer(
  ADMIN_ORGANIZATIONS_INITIAL_STATE,
  {
    [adminOrganizationsRoutine.REQUEST]: onRequest,
    [adminOrganizationsRoutine.SUCCESS]: (
      state,
      action: PayloadAction<{
        operation: OperationsEndpoint;
        data: IndexedCollection<OrganizationInfo> | OrganizationInfo;
        id: number;
      }>
    ) => {
      const { operation, data, id } = action.payload;

      switch (operation) {
        case OperationsEndpoint.GetAll:
          state.organizations = data as IndexedCollection<OrganizationInfo>;
          break;

        case OperationsEndpoint.Get:
        case OperationsEndpoint.Update:
          if (id) {
            state.organizations[id] = data as OrganizationInfo;
          }
          break;

        default:
          break;
      }
      return state;
    },
    [adminOrganizationsRoutine.FAILURE]: onFailure,
    [adminOrganizationsRoutine.FULFILL]: onFulfill,
  }
);
