import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";

export const comparePasswords = ({
  getFieldValue,
}: {
  getFieldValue: (value: string) => string;
}) => ({
  /** Если вместо any поставить RuleObject { validator: ValidatorRule["validator"];}
   * то field отсутствует в типе, однако есть в объекте instance
   * */
  // eslint-disable-next-line
  validator: (instance: any, value: string) => {
    if (!value || getFieldValue(FormFieldName.NewPassword) === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Пароли не совпадают"));
  },
});
