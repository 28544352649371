import { changePasswordActions } from "@modules/auth/actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { AUTH_API } from "@/common/api/endpoints/auth";
import { ChangePasswordRequest } from "@/common/api/types/auth";
import { showSuccessNotification } from "@/common/helpers/notification/show-notification-success";

export type ChangePasswordAction = PayloadAction<ChangePasswordRequest>;

export function* changePasswordWorker(action: ChangePasswordAction) {
  const { request, failure, fulfill } = changePasswordActions;

  try {
    yield put(request());
    yield call(AUTH_API.changePassword, action.payload);
    yield call(showSuccessNotification, "Пароль изменен");
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* changePasswordWatcher() {
  yield takeLatest(changePasswordActions.trigger, changePasswordWorker);
}
