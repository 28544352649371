import { Rule } from "rc-field-form/lib/interface";
import { FormFieldName } from "./field-name";
import { validatorCheckInputHasOnlyNumber } from "@/common/ui-components/data-entry/form/validators/validator-check-input-has-only-number";
import { validatorMaskNumberCheck } from "@/common/ui-components/data-entry/form/validators/validator-check-mask-number";
import { validatorCheckSalary } from "@/common/ui-components/data-entry/form/validators/validator-check-salary";
import { validatorNewPassword } from "@/common/ui-components/data-entry/form/validators/validator-new-password";
import { validatorCheckFutureDate } from "@/common/ui-components/data-entry/form/validators/validator-check-future-date";
import { comparePasswords } from "@/common/ui-components/data-entry/form/validators/validator-compare-passwords";

export const REQUIRED_FORM_FIELD: Rule = {
  required: true,
  message: "Заполните обязательное поле",
};

export const REQUIRED_FORM_FIELD_WITH_WHITESPACE: Rule = {
  ...REQUIRED_FORM_FIELD,
  // todo this rule not working
  whitespace: true,
};

export const ONLY_REQUIRED_FORM_FIELD_RULE: Rule[] = [REQUIRED_FORM_FIELD];

export const ONLY_REQUIRED_FORM_FIELD_WITH_WHITESPACE: Rule[] = [
  REQUIRED_FORM_FIELD_WITH_WHITESPACE,
];

export const FORM_RULES: Record<FormFieldName, Rule[] | []> = {
  [FormFieldName.UserID]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.Code]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.Text]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.CategoryID]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.Image]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.Tags]: [],
  [FormFieldName.Default]: [],
  [FormFieldName.AccountName]: ONLY_REQUIRED_FORM_FIELD_WITH_WHITESPACE,
  [FormFieldName.AccountRole]: [],
  [FormFieldName.AccountCode]: [],
  [FormFieldName.UserRole]: [],
  [FormFieldName.Login]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.Phone]: [validatorMaskNumberCheck, REQUIRED_FORM_FIELD],
  [FormFieldName.Email]: [
    { type: "email", message: "Некорректное значение E-mail" },
    REQUIRED_FORM_FIELD,
  ],
  [FormFieldName.Name]: ONLY_REQUIRED_FORM_FIELD_WITH_WHITESPACE,
  [FormFieldName.Surname]: ONLY_REQUIRED_FORM_FIELD_WITH_WHITESPACE,
  [FormFieldName.Password]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.INN]: [
    REQUIRED_FORM_FIELD,
    validatorCheckInputHasOnlyNumber,
    {
      min: 10,
      max: 12,
      message: "ИНН может состоять только из 10 или 12 цифр",
    },
  ],
  [FormFieldName.FullName]: [],
  [FormFieldName.ConsentToDataProcessing]: ONLY_REQUIRED_FORM_FIELD_RULE,
  /** В нижней правой части оборотной стороны красным цветом выполнены четырехзначная цифровая серия и шестизначный номер удостоверения. */
  [FormFieldName.DriverLicense]: [
    validatorMaskNumberCheck,
    REQUIRED_FORM_FIELD,
    // если поле с маской, то пустые поля тоже считаются;
    {
      min: 12,
      max: 12,
      message:
        "Четырехзначная цифровая серия и шестизначный номер удостоверения (10 цифр)",
    },
  ],
  [FormFieldName.Rating]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.SocialRating]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.IsEmployee]: [],
  [FormFieldName.HaveDriverLicense]: [],
  [FormFieldName.Title]: [REQUIRED_FORM_FIELD_WITH_WHITESPACE],
  [FormFieldName.Description]: [],
  [FormFieldName.Profession]: [],
  [FormFieldName.DrivingExperience]: [],
  [FormFieldName.CompanyHasVehicle]: [],
  [FormFieldName.Brand]: [],
  [FormFieldName.ProductionYear]: [],
  [FormFieldName.AllowedMaxMass]: [],
  [FormFieldName.LoadCapacity]: [],
  [FormFieldName.WheelArrangement]: [],
  [FormFieldName.EngineType]: [],
  [FormFieldName.EngineCapacity]: [],
  [FormFieldName.EngineVolume]: [],
  [FormFieldName.EcologyClass]: [],
  [FormFieldName.Gearbox]: [],
  [FormFieldName.VehicleCondition]: [],
  [FormFieldName.VehiclePhotos]: [],
  [FormFieldName.Video]: [],
  [FormFieldName.WorkSchedule]: [],
  [FormFieldName.RegistrationMethod]: [],
  [FormFieldName.PaymentTerms]: [],
  [FormFieldName.PaymentFrequency]: [],
  [FormFieldName.Extra]: [],
  [FormFieldName.Address]: [],
  [FormFieldName.VacancyPhone]: [],
  [FormFieldName.CabinType]: [],
  [FormFieldName.Salary]: [validatorCheckSalary, REQUIRED_FORM_FIELD],
  [FormFieldName.DesiredSalary]: [validatorCheckSalary],
  [FormFieldName.From]: [],
  [FormFieldName.To]: [],
  [FormFieldName.JobRegion]: [],

  /** Водитель */
  [FormFieldName.Avatar]: [],
  [FormFieldName.Patronymic]: [],
  [FormFieldName.Birthday]: [validatorCheckFutureDate],
  [FormFieldName.Sex]: [],
  [FormFieldName.Citizens]: [],
  [FormFieldName.EmploymentType]: [],
  [FormFieldName.EducationLevel]: [],
  [FormFieldName.OwnVehicleType]: [],
  [FormFieldName.MedicalCertificate]: [],
  [FormFieldName.DriverLicenseDate]: [validatorCheckFutureDate],
  [FormFieldName.LicenseCategory]: [],
  [FormFieldName.VehicleType]: [],
  [FormFieldName.ExperienceYears]: [],
  [FormFieldName.Organization]: [],
  [FormFieldName.LicenseCategories]: [],
  [FormFieldName.JobSearchStatus]: [],
  [FormFieldName.ReadyToStartTomorrow]: [],
  [FormFieldName.DriverLicenseMoreThanThree]: [],
  [FormFieldName.Age]: [],
  [FormFieldName.ActivateAgeField]: [],
  [FormFieldName.ShowAvatar]: [],
  [FormFieldName.PublicOrganizationMembership]: [],
  [FormFieldName.MembershipChecked]: [],
  [FormFieldName.ReadyForTrips]: [],
  [FormFieldName.ReadyForRelocation]: [],
  [FormFieldName.JobSearchRegion]: [],
  [FormFieldName.WaitingForMembershipCheck]: [],

  /** Организация */
  [FormFieldName.OrganizationName]: ONLY_REQUIRED_FORM_FIELD_WITH_WHITESPACE,
  [FormFieldName.OrganizationTypeID]: [],
  [FormFieldName.KPP]: [
    {
      min: 9,
      max: 9,
      message: "КПП может состоять только из 9 цифр",
    },
  ],
  [FormFieldName.OGRN]: [
    {
      min: 13,
      max: 15,
      message: "ОРГН/ОГРНИП может состоять только из 13/15 цифр",
    },
  ],
  [FormFieldName.OKPO]: [
    {
      min: 8,
      max: 10,
      message: "ОКПО может состоять только из 8 или 10 цифр",
    },
  ],
  [FormFieldName.LegalAddress]: [],
  [FormFieldName.ActualAddress]: [],
  [FormFieldName.WorkingPosition]: [],
  [FormFieldName.Years]: [],
  [FormFieldName.Months]: [],
  [FormFieldName.VacanciesStatus]: [],
  [FormFieldName.VacanciesSource]: [],
  [FormFieldName.Comment]: [],
  [FormFieldName.OrganizationIsVerified]: [],
  [FormFieldName.PublicOrganizationPartnership]: [],
  [FormFieldName.PartnershipChecked]: [],
  [FormFieldName.WaitingForPartnershipCheck]: [],

  /** Анкеты */
  [FormFieldName.ApplicationStatus]: [],

  /** Опрос */
  [FormFieldName.ResidenceRegion]: [],
  [FormFieldName.SalaryLevel]: [],
  /** Задать вопрос - "Вопрос президенту", "Обращение в службу поддержки" */
  [FormFieldName.Question]: ONLY_REQUIRED_FORM_FIELD_RULE,
  /** Подтверждение номера телефона */
  [FormFieldName.VerificationCode]: ONLY_REQUIRED_FORM_FIELD_RULE,
  /** Анкета администратора */
  [FormFieldName.Questionnaire]: ONLY_REQUIRED_FORM_FIELD_RULE,
  [FormFieldName.QuestionnaireStatus]: [],

  [FormFieldName.NewPassword]: [
    { required: true, message: "Введите новый пароль" },
    { validator: validatorNewPassword },
  ],
  [FormFieldName.ConfirmPassword]: [
    { required: true, message: "Подтвердите новый пароль" },
    comparePasswords as Rule,
  ],
  [FormFieldName.OldPassword]: [
    { required: true, message: "Введите старый пароль" },
  ],
};
