export enum LogisticServicesGroups {
  CargoOwners = "CargoOwners",
  TransportCompanies = "TransportCompanies",
  ExpeditorCompanies = "ExpeditorCompanies",
  DriversOwnVehicles = "DriversOwnVehicles",
  DriversNoVehicles = "DriversNoVehicles",
}

export type LogisticServiceType = {
  title: string;
  icon: string;
  link: string;
};

export type LogisticServicesType = Array<LogisticServiceType>;
