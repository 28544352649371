import { FC } from "react";
import { Empty } from "antd";
import { isEmpty } from "lodash";
import { User } from "@/common/types/models/user/user";
import { UserCardActionsType } from "@/common/types/models/user/card-actions";
import { ILSSpin } from "@/common/components/data-display/spin";
import { UserCard } from "@/common/components/data-display/user-card";

type IProps = {
  users: User[];
  userCardActions: UserCardActionsType;
  isFetching: boolean;
};

export const AdminOrganizationUsersList: FC<IProps> = ({
  users,
  userCardActions,
  isFetching,
}) => {
  return (
    <ILSSpin spinning={isFetching} tip="Загрузка" size="large">
      <ul className="admin-organization-users__list">
        {!isEmpty(users) ? (
          users?.map((user) => (
            <UserCard
              isCurrentUserCard={false}
              user={user}
              key={user.ID}
              userCardActions={userCardActions}
            />
          ))
        ) : (
          <Empty />
        )}
      </ul>
    </ILSSpin>
  );
};
