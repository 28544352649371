import { ApiGet } from "@/common/api/helpers/get";
import {
  ArticleBaseRequest,
  ArticlesFilters,
  GetArticleResponse,
  GetArticlesResponse,
} from "../types/article";
import { ApiPost } from "@/common/api/helpers/post";
import { ApiDelete } from "@/common/api/helpers/delete";
import { OperationsEndpoint } from "@/common/api/constants/operations";
import { EMPTY_STRING } from "@/common/constants/general";
import { getStringifierUrl } from "@/common/api/helpers/stringify-url";
import { ApiPut } from "@/common/api/helpers/put";

export type EndpointType<F> = {
  id?: number;
  filters?: F | undefined;
  method: OperationsEndpoint;
};

const OperationEndpointsWithMethod = [
  OperationsEndpoint.Apply,
  OperationsEndpoint.SetStatus,
];

export const getArticleEndpoint = ({
  id,
  filters,
  method,
}: EndpointType<ArticlesFilters>) => {
  const addMethodToEndpoint = OperationEndpointsWithMethod.includes(method);

  const endpoint = `/article${id ? `/${id}` : EMPTY_STRING}${
    addMethodToEndpoint ? `/${method}` : EMPTY_STRING
  }`;
  // todo
  return getStringifierUrl(endpoint, filters as never);
};

export function articlesOperation({
  operation,
  body,
  id,
  filters,
}: ArticleBaseRequest): Promise<GetArticlesResponse | GetArticleResponse> {
  const endpoint = getArticleEndpoint({
    id,
    filters,
    method: operation,
  });

  switch (operation) {
    case OperationsEndpoint.GetAll:
      return ApiGet<GetArticlesResponse>(endpoint);
    case OperationsEndpoint.Get:
      return ApiGet<GetArticleResponse>(endpoint);
    case OperationsEndpoint.Create:
      return ApiPost<GetArticleResponse>(endpoint, body);
    case OperationsEndpoint.Update:
      return ApiPut<GetArticleResponse>(endpoint, body);
    case OperationsEndpoint.Delete:
      return ApiDelete<GetArticleResponse>(endpoint);
    case OperationsEndpoint.Apply:
      return ApiPost(endpoint);
    case OperationsEndpoint.SetStatus:
      return ApiPost(endpoint, body);
    default:
      return ApiPost(endpoint);
  }
}

export const ARTICLE_API = {
  articlesOperation,
};
