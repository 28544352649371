import { useForm } from "antd/es/form/Form";
import { EMPTY_OBJECT_DATA } from "@/common/constants/general";
import { VACANCIES_TOOLBAR_INITIAL_VALUES } from "@/common/features/vacancies-common/constants/filters";
import { FormFieldName } from "@/common/ui-components/data-entry/form/constants/field-name";
import { VacanciesFilters } from "@/common/features/vacancies-common/types/api";
import {
  VacanciesSource,
  VacanciesToolbarFilterValues,
} from "@/common/features/vacancies-common/types/filters";
import { getVacanciesStatusParameter } from "@/modules/social-services/children/vacancies/helpers/get-vacancies-status-parameter";

type IProps = {
  accountID?: number | null;
  isAdminPanel?: boolean;
  changeFilters: (currentFilters: VacanciesFilters | undefined) => void;
};

/**
 * Хук, управляющий формой с фильтрами в компоненте Sidebar
 * в разделе "Вакансии" и "Управление вакансиями"
 */

export const useVacanciesToolbarForm = ({
  accountID = null,
  isAdminPanel = false,
  changeFilters,
}: IProps) => {
  const [form] = useForm<VacanciesToolbarFilterValues>();

  const onToolbarFormChange = (
    _: VacanciesToolbarFilterValues,
    values: VacanciesToolbarFilterValues
  ) => {
    const AccountID = !isAdminPanel
      ? {
          AccountID:
            values.VacanciesSource === VacanciesSource.Mine
              ? accountID
              : undefined,
        }
      : EMPTY_OBJECT_DATA;

    changeFilters({
      ...AccountID,
      VacancyStatusID: getVacanciesStatusParameter(values, isAdminPanel),
    });
  };

  const resetToolbarForm = () => {
    form.resetFields();
    form.setFieldsValue(VACANCIES_TOOLBAR_INITIAL_VALUES);
  };

  const showStatusFormItem =
    form.getFieldValue(FormFieldName.VacanciesSource) === VacanciesSource.Mine;

  return {
    toolbarForm: form,
    onToolbarFormChange,
    resetToolbarForm,
    showStatusFormItem,
  };
};
