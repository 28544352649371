import "../index.scss";
import { Input } from "antd";
import { PasswordProps } from "antd/lib/input";
import { FC } from "react";
import classNames from "classnames";
import { IILSInputProps } from "@/common/ui-components/data-entry/input/text";

const { Password } = Input;

export interface IILSInputPasswordProps extends IILSInputProps, PasswordProps {}

export const ILSInputPassword: FC<IILSInputPasswordProps> = ({
  className,
  ...props
}) => {
  return (
    <Password
      {...props}
      className={classNames("ils-input ils-input_number", className)}
    />
  );
};
