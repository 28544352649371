import { FC } from "react";
import { ADMIN_PANEL_MENU_ITEMS } from "@modules/admin-panel/constants/menu";
import { useAdminPanel } from "@modules/admin-panel/hooks/use-admin-panel";
import { ILSSectionHeadHorizontalMenu } from "@/common/components/navigation/menu/section-head-horizontal-menu";

interface IProps {}

export const AdminPanelMenu: FC<IProps> = () => {
  const { selectedKeys, onSelect } = useAdminPanel();

  return (
    <ILSSectionHeadHorizontalMenu
      className="admin-panel__menu"
      selectedKeys={selectedKeys}
      onSelect={onSelect}
      items={ADMIN_PANEL_MENU_ITEMS}
    />
  );
};
