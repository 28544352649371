export enum SortKeys {
  IDAsc = "ID ASC",
  IDDesc = "ID DESC",
  PriceFromAsc = "PriceFrom ASC",
  PriceFromDesc = "PriceFrom DESC",
}

export const sortTexts = {
  [SortKeys.IDDesc]: "по дате по убыванию",
  [SortKeys.IDAsc]: "по дате по возрастанию",
  [SortKeys.PriceFromDesc]: "по зарплате по убыванию",
  [SortKeys.PriceFromAsc]: "по зарплате по возрастанию",
};

export const ascSortKeys = [SortKeys.IDAsc, SortKeys.PriceFromAsc];

export const sortKeys = [
  SortKeys.IDDesc,
  SortKeys.IDAsc,
  SortKeys.PriceFromDesc,
  SortKeys.PriceFromAsc,
];

export const SORT_ICON_STYLE = { width: "30px", height: "30px" };
