import "./styles.scss";

export const LogisticServicesTrafficLightBadge = () => {
  return (
    <div className="logistic-services__card-badge logistic-services__card-badge_traffic-light">
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/logistic-services/traffic-light.png`}
        alt="traffic-light"
      />
    </div>
  );
};
