import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { loginActions, registerActions } from "@modules/auth/actions";
import { NavigateFunction } from "react-router-dom";
import { LoginPayload } from "@modules/auth/sagas/login";
import { omit } from "lodash";
import { AUTH_API } from "@/common/api/endpoints/auth";
import { RegisterRequest } from "@/common/api/types/auth";

export type RegisterAction = PayloadAction<
  RegisterRequest & {
    navigate: NavigateFunction;
  }
>;

export function* registerWorker(action: RegisterAction) {
  const { request, failure, fulfill } = registerActions;
  const { navigate, Password, Email, Phone } = action.payload;

  try {
    yield put(request());
    const body = omit(action.payload, "navigate") as RegisterRequest;
    yield call(AUTH_API.register, body);
    const loginPayload: LoginPayload = {
      navigate,
      Password,
      Login: Email ?? Phone,
    };
    yield put(loginActions.trigger(loginPayload));
  } catch (error) {
    yield put(failure({ error }));
  } finally {
    yield put(fulfill());
  }
}

export function* registerWatcher() {
  yield takeLatest(registerActions.trigger, registerWorker);
}
