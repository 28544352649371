import { useParams } from "react-router-dom";
import { ModulePath } from "@/common/types/core/routes";
import { DYNAMIC_ROUTE_CHAR, EMPTY_STRING } from "@/common/constants/general";

export const useShowIndexModule = (dynamicPath: ModulePath) => {
  const params = useParams();
  const pathIsDynamic = dynamicPath?.startsWith(DYNAMIC_ROUTE_CHAR);

  const path =
    dynamicPath && pathIsDynamic ? dynamicPath.slice(1) : EMPTY_STRING;

  const dynamicElementID = params?.[path];

  return { showIndexModule: !dynamicElementID, dynamicElementID };
};
