import "./sider-banner-title.scss";
import { FC } from "react";

type IProps = {
  children: string;
};

export const SiderBannerTitle: FC<IProps> = ({ children }) => {
  return <h3 className="sider-banner-title">{children}</h3>;
};
