import { Tooltip } from "antd";
import { isNil } from "lodash";
import { ILSRating } from "@/common/ui-components/data-display/rating";
import { ILSAvatar } from "@/common/ui-components/data-display/avatar";
import { AbacPermission } from "@/common/types/core/abac";
import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { useHeaderWidget } from "@/common/hooks/header/use-header-widget";

export const ILSAccountWidget = () => {
  const {
    widgetUserName,
    widgetOrganizationName,
    widgetAvatar,
    widgetRating,
    widgetRatingTitle,
    widgetTooltipTitle,
  } = useHeaderWidget();

  return (
    <div className="header-component__company">
      <span className="header-component__login-as">Вы вошли как: </span>
      <div className="header-component__organization organization">
        <div className="organization__logo">
          <ILSAvatar shape="square" size={34} src={widgetAvatar} />
        </div>
        <Tooltip title={widgetTooltipTitle}>
          <div className="header-component__names">
            <p className="organization-user-name">{widgetUserName}</p>
            {widgetOrganizationName && (
              <p className="organization-name">{widgetOrganizationName}</p>
            )}
          </div>
        </Tooltip>

        {!isNil(widgetRating) && (
          <ILSAllowedTo
            perform={[AbacPermission.auth, AbacPermission.authContent]}
          >
            <div className="organization__social">{widgetRatingTitle}</div>
            <ILSRating className="organization__rating" rating={widgetRating} />
          </ILSAllowedTo>
        )}
      </div>
    </div>
  );
};
