import { ILSAllowedTo } from "@/common/ui-components/templates/allowed-to";
import { AbacPermission } from "@/common/types/core/abac";
import { StartPageSiderBanner } from "@/modules/start/components/sider-banners/sider-banner/sider-banner";
import { SiderBannerTitle } from "@/modules/start/components/sider-banners/sider-banner-title/sider-banner-title";
import { SiderBannerSurvey } from "@/modules/start/components/sider-banners/sider-banner-survey/sider-banner-survey";
import { useSurveyForm } from "@/modules/start/hooks/use-survey-form";

export const StartPageSurveyContainer = () => {
  const { form, handleSubmit } = useSurveyForm();

  return (
    <ILSAllowedTo perform={AbacPermission.auth}>
      <StartPageSiderBanner>
        <SiderBannerTitle>Опрос</SiderBannerTitle>
        <SiderBannerSurvey form={form} handleSubmit={handleSubmit} />
      </StartPageSiderBanner>
    </ILSAllowedTo>
  );
};
